// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { Link, useNavigate } from "react-router-dom";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import { MdAdd } from "react-icons/md";
import InputText from "../ReusableComponents/InputText";
import youtube from "../image/youtube.jpg";
import pdf from "../image/pdf.png";
import download from "../image/download.png";
import RadioGroup from "../ReusableComponents/RadioButton";
import TextArea from "../ReusableComponents/TextArea";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import toast from "react-hot-toast";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
const TableGrid = ({
  tableHeight,
  headerData,
  tableData,
  onEditGrid,
  onViewGrid,
  onHistoryGrid,
  onDeleteGrid,
  onBlockGrid,
  onAddressGrid,
  onServicesGrid,
  onNotificationSave,
  onNotificationDatas,
  onContraceptiveNotificationDatas,
  onReferralFollowUpNotificationDatas,
  bindReferralFollowUpRemarks,
  onTestResultTypeDD,
  onNotificationClear,
  onNotificationClearFalse,
  onReferralGrid,
  bindReferralDD,
  bindPACServicesDD,
  bindClientGenderDD,
  bindContraceptiveRemarks,
  bindDiscontinuedRemarks,
  onServicesProvide,
  onFPUserDD,
  onNoOfLivingChidrensDatas,
  currentPage,
  itemsPerPage,
  issusTableHeightDesign,
  viewBag,
  editBag,
  deleteBag,

  blockBag,
  serviceBag,
  onSchoolView,
  handlePrint,
  tableView,
  xScroll,
  tableScroll,
  onAdd,
  onDisabled,
}) => {
  let currentData = [];
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [NODtoEditPastEntries, setNODtoEditPastEntries] = useState("");
  // Contraceptive Renewal Supply Start
  const [remarks1Visibility, setRemarks1Visibility] = useState([]);
  const [contraceptiveRemarks, setContraceptiveRemarks] = useState([]);
  const [
    contraceptiveRemarksDiscontinued,
    setContraceptiveRemarksDiscontinued,
  ] = useState([]);
  // const [contraceptiveQuantity, setContraceptiveQuantity] = useState(
  //   Array(tableData.length).fill("")
  // );
  const [contraceptiveNextFollowUpDate, setContraceptiveNextFollowUpDate] =
    useState([]);

  const [nextFollowUpDate, setNextFollowUpDate] = useState([]);

  // Contraceptive Renewal Supply End

  // Referral Follow Up Start

  const [referralFollowUpRemarks, setReferralFollowUpRemarks] = useState([]);

  // Referral Follow Up End
  const [testResultVisibility, setTestResultVisibility] = useState([]);
  const [testResultRadioVisi, setTestResultRadioVisi] = useState([]);

  const [action, setAction] = useState([]);

  const [remarks, setRemarks] = useState(Array(tableData.length).fill(""));

  const [dateFollowedUp, setDateFollowedUp] = useState([]);
  const [notificationQuantity, setNotificationQuantity] = useState(
    Array(tableData.length).fill("")
  );

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState();
  if (currentPage) {
    if (Array.isArray(tableData)) {
      if (itemsPerPage === -1) {
        currentData = tableData; // No slicing, show all data
      } else {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        currentData = tableData.slice(startIndex, endIndex); // Pagination logic
      }
      // const startIndex = (currentPage - 1) * itemsPerPage;
      // const endIndex = startIndex + itemsPerPage;
      // currentData = tableData.slice(startIndex, endIndex);
    }
  } else {
    currentData = [tableData];
  }

  const navigate = useNavigate();
  const [viewsAll, setViewsAll] = useState(false);

  const [viewImage, setViewImage] = useState({ popup: false, url: "" });
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  // const [quantity, setQuantity] = useState();
  // State to track quantities for each row
  const [quantities, setQuantities] = useState(Array(tableData.length).fill(0));
  const handleVideoClick = (url) => {
    setViewImage({ popup: true, url: url });
  };
  // State to track available balances for each row
  const [availableBalances, setAvailableBalances] = useState(
    Array(tableData)?.map((row) => row.Available)
  );
  // State to track error messages for each row
  const [errorMessages, setErrorMessages] = useState(
    Array(tableData.length).fill("")
  );
  const systemSettingsData = async () => {
    const response = await BindAPI("SystemSettingsAPI/BindSystemSettings");
    const NODtoEditPastEntriesFromApi =
      response.data.Table[0].NODtoEditPastEntries;
    setNODtoEditPastEntries(NODtoEditPastEntriesFromApi);
  };
  const [clientNoOfLivingChildrenFields, setClientNoOfLivingChildrenFields] =
    useState({
      clientYears: Array(tableData.length).fill(""),
      clientMonths: Array(tableData.length).fill(""),
      clientDays: Array(tableData.length).fill(""),
      clientGender: Array(tableData.length).fill(0),
    });

  const showPopUp = (views, rowIndex) => {
    setViewsAll(views);
    setCurrentRowIndex(views ? rowIndex : null);
  };
  console.log("tableData", tableData);
  useEffect(() => {
    systemSettingsData();
  }, []);
  // useEffect(() => {
  //   if (tableData && Array.isArray(tableData)) {
  //     // Extract quantities from issueDetailsData and update quantities state
  //     const extractedQuantities = tableData.map((data) => data.Quantity);
  //     const extractedBalance = tableData.map((data) => data.Balance);

  //     setQuantities(extractedQuantities);
  //     setAvailableBalances(extractedBalance);
  //   }
  // }, [tableData]);

  // Function to handle quantity change for a specific row
  const handleQuantityChange = (rowIndex, value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) || value === "") {
      const updatedQuantities = [...issueNow];
      updatedQuantities[rowIndex] = isNaN(parsedValue) ? 0 : parsedValue;
      setIssueNow(updatedQuantities);
    }
  };

  const handleQuantityBlur = (rowIndex) => {
    debugger;
    const parsedQuantity = parseInt(issueNow[rowIndex], 10);
    const availableStockValue = availableStock[rowIndex];
    const indentPendingQuantity = tableData[rowIndex].IndentPendingQuantity;
    // Find the matching row with the same MstCommodityCategoryId and MstCommoditiesId
    const matchingRow = tableData.find(
      (row) =>
        row.MstCommodityCategoryId ===
          tableData[rowIndex].MstCommodityCategoryId &&
        row.MstCommoditiesId === tableData[rowIndex].MstCommoditiesId
    );

    // Get previous IssueNow from the matching row (if found)
    const previousIssueNow = matchingRow ? matchingRow.IssueNow : 0;
    // Check if the quantity is a valid number
    if (isNaN(parsedQuantity)) {
      setIssueNow((prev) => {
        const updatedQuantities = [...prev];
        updatedQuantities[rowIndex] = 0;
        return updatedQuantities;
      });

      setBalanceStock((prev) => {
        const updatedBalanceStock = [...prev];
        updatedBalanceStock[rowIndex] = 0;
        return updatedBalanceStock;
      });

      setErrorMessages((prev) => {
        const newErrorMessages = [...prev];
        newErrorMessages[rowIndex] = "Invalid input. Resetting value to 0.";
        return newErrorMessages;
      });

      return;
    }

    // Check if parsed quantity exceeds available stock or indent quantity
    if (
      parsedQuantity > availableStockValue ||
      parsedQuantity > indentPendingQuantity
    ) {
      setIssueNow((prev) => {
        const updatedQuantities = [...prev];
        updatedQuantities[rowIndex] = 0;
        return updatedQuantities;
      });

      setBalanceStock((prev) => {
        const updatedBalanceStock = [...prev];
        updatedBalanceStock[rowIndex] = availableStockValue;
        return updatedBalanceStock;
      });

      setErrorMessages((prev) => {
        const newErrorMessages = [...prev];
        newErrorMessages[
          rowIndex
        ] = `Entered quantity exceeds the allowed limits.\nAvailable Stock: ${availableStockValue}, Pending Quantity: ${indentPendingQuantity}`;
        return newErrorMessages;
      });

      return;
    }

    // Clear previous error message
    setErrorMessages((prev) => {
      const newErrorMessages = [...prev];
      newErrorMessages[rowIndex] = "";
      return newErrorMessages;
    });

    // Calculate the new quantity difference
    const quantityDifference = parsedQuantity + previousIssueNow;

    if (quantityDifference > indentPendingQuantity) {
      setIssueNow((prev) => {
        const updatedQuantities = [...prev];
        updatedQuantities[rowIndex] = 0;
        return updatedQuantities;
      });

      setBalanceStock((prev) => {
        const updatedBalanceStock = [...prev];
        updatedBalanceStock[rowIndex] = availableStockValue;
        return updatedBalanceStock;
      });

      setErrorMessages((prev) => {
        const newErrorMessages = [...prev];
        newErrorMessages[rowIndex] =
          "Quantity adjustment exceeds the pending quantity limit.";
        return newErrorMessages;
      });

      return;
    } else {
      setIssueNow((prev) => {
        const updatedQuantities = [...prev];
        updatedQuantities[rowIndex] = 0;
        return updatedQuantities;
      });

      setBalanceStock((prev) => {
        const updatedBalanceStock = [...prev];
        updatedBalanceStock[rowIndex] = availableStockValue;
        return updatedBalanceStock;
      });
      setErrorMessages((prev) => {
        const newErrorMessages = [...prev];
        newErrorMessages[rowIndex] = "";
        return newErrorMessages;
      });
    }

    // Proceed with valid updates
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex].IssueNow = parsedQuantity;
    updatedTableData[rowIndex].Quantity = parsedQuantity;
    updatedTableData[rowIndex].BalanceStock =
      availableStockValue - parsedQuantity;
    updatedTableData[rowIndex].MstCommodityCategoryId =
      tableData[rowIndex].MstCommodityCategoryId;
    updatedTableData[rowIndex].MstCommoditiesId =
      tableData[rowIndex].MstCommoditiesId;

    setBalanceStock((prev) => {
      const updatedBalanceStock = [...prev];
      updatedBalanceStock[rowIndex] = availableStockValue - parsedQuantity;
      return updatedBalanceStock;
    });

    // Pass updated data to the grid handler
    onReferralGrid(updatedTableData);
  };

  const [serviceReceived, setServiceReceived] = useState("");
  const [testResult, setTestResult] = useState([]);
  const [uom, setUOM] = useState([]);
  const [qty, setQTY] = useState([]);
  const [qtyEdit, setQTYEdit] = useState([]);
  const [charges, setCharges] = useState([]);
  const [FPUser, setFPUser] = useState("");
  // const [FPUser, setFPUser] = useState(tableData?.map(() => "1") ?? []);
  const [referral, setReferral] = useState("");
  const [pacServices, setPacServices] = useState("");
  const [MstBrandId, setMstBrandId] = useState("");
  const [currentStock, setCurrentStock] = useState([]);
  const [error, setError] = useState("");
  const [FPItemProvidedIdOptions, setFPItemProvidedIdOptions] = useState([]);
  const [issueBrandId, setIssueBrandId] = useState("");
  const [brandOptions, setBrandOptions] = useState([]);
  const [issueNow, setIssueNow] = useState("");
  const [balanceStock, setBalanceStock] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState("");
  const [availableStock, setAvailableStock] = useState([]);
  useEffect(() => {
    if (Array.isArray(tableData)) {
      setFPUser(tableData.map((item) => item.FPUser || "1"));
    } else {
      setFPUser([]); // Optional: handle cases where tableData is not an array
    }
  }, [tableData]);

  useEffect(() => {
    // Ensure that none of the dependencies are undefined before calling handleServicesProvideBlur
    if (
      issueBrandId !== undefined &&
      brandOptions !== undefined &&
      issueNow !== undefined &&
      balanceStock !== undefined &&
      batchNo !== undefined &&
      dateOfExpiry !== undefined
    ) {
      handleIssueBlur();
    }
  }, [
    issueBrandId,
    brandOptions,
    issueNow,
    balanceStock,
    batchNo,
    dateOfExpiry,
  ]);

  const handleIssueBlur = () => {
    if (typeof onReferralGrid == "function") {
      onReferralGrid(tableData);
    } else {
      console.log("onReferralGrid is not a function");
    }
  };
  useEffect(() => {
    // Ensure that none of the dependencies are undefined before calling handleServicesProvideBlur
    if (
      testResult !== undefined &&
      uom !== undefined &&
      qty !== undefined &&
      charges !== undefined &&
      FPUser !== undefined &&
      referral !== undefined &&
      pacServices !== undefined &&
      FPItemProvidedIdOptions.length !== 0 &&
      MstBrandId !== undefined
    ) {
      handleServicesProvideBlur();
    }
  }, [
    testResult,
    uom,
    qty,
    charges,
    FPUser,
    referral,
    pacServices,
    FPItemProvidedIdOptions,
    MstBrandId,
  ]);

  const handleServicesProvideBlur = () => {
    if (typeof onServicesProvide == "function") {
      onServicesProvide(tableData);
    } else {
      console.log("onServicesProvide is not a function");
    }
  };
  const handleRadioChange = (value, rowIndex) => {
    setFPUser((prevFPUser) => {
      const newFPUser = [...prevFPUser];
      newFPUser[rowIndex] = value;
      return newFPUser;
    });
    const currentItem = tableData[rowIndex];
    currentItem.FPUser = value;
  };

  const handleRadioChange1 = (value, rowIndex) => {
    setTestResult((prevResultType) => {
      const newResultType = [...prevResultType];
      newResultType[rowIndex] = value;
      return newResultType;
    });
    const currentItem = tableData[rowIndex];
    currentItem.TestResult = value;
    // handleServicesProvideBlur(rowIndex);
  };

  const handleRadioChangeServiceReceived = (value, rowIndex) => {
    setServiceReceived((prevServiceReceived) => {
      const newServiceReceived = [...prevServiceReceived];
      newServiceReceived[rowIndex] = value;
      return newServiceReceived;
    });

    handleReferralTrackingBlur(rowIndex);
  };

  useEffect(() => {
    handleSethandleClientNoOfLivingChildren();
  }, [clientNoOfLivingChildrenFields]);

  const handleClientNoOfLivingChildren = (field, value, rowIndex) => {
    setClientNoOfLivingChildrenFields((prevFields) => {
      const newFields = { ...prevFields };
      newFields[field][rowIndex] = value;
      return newFields;
    });
  };

  const handleSethandleClientNoOfLivingChildren = () => {
    const getTableDatas = tableData.length > 0 ? tableData : [];
    const setNoOfLivingChildrenTableDatas = getTableDatas.map(
      (data, index) => ({
        ...data,
        clientYears:
          clientNoOfLivingChildrenFields.clientYears[index] == "0"
            ? null
            : clientNoOfLivingChildrenFields.clientYears[index],
        clientMonths:
          clientNoOfLivingChildrenFields.clientMonths[index] == "0"
            ? null
            : clientNoOfLivingChildrenFields.clientMonths[index],
        clientDays:
          clientNoOfLivingChildrenFields.clientDays[index] == "0"
            ? null
            : clientNoOfLivingChildrenFields.clientDays[index],
        clientGender:
          clientNoOfLivingChildrenFields.clientGender[index] == "0"
            ? null
            : clientNoOfLivingChildrenFields.clientGender[index],
      })
    );
    console.log(
      "setNoOfLivingChildrenTableDatas",
      setNoOfLivingChildrenTableDatas
    );
    if (typeof onNoOfLivingChidrensDatas === "function") {
      onNoOfLivingChidrensDatas(setNoOfLivingChildrenTableDatas);
    } else {
      console.log("onNoOfLivingChidrensDatas is not a function");
    }
  };

  const handleDateFollowedUpChange = (rowIndex, value) => {
    setDateFollowedUp((prevDateFollowedUp) => {
      const updatedDateFollowedUp = [...prevDateFollowedUp];
      updatedDateFollowedUp[rowIndex] = value;
      return updatedDateFollowedUp;
    });
    handleReferralTrackingBlur(rowIndex);
  };

  const handleReferralTrackingBlur = (rowIndex) => {
    setServiceReceived((prevServiceReceived) => {
      setDateFollowedUp((prevDateFollowedUp) => {
        const updatedTableData = tableData
          .filter((item, index) => index == rowIndex)
          .map((data, index) => ({
            ...data,
            RowIndex: [rowIndex],
            ServiceReceived: prevServiceReceived[rowIndex],
            DateFollowedUp: prevDateFollowedUp[rowIndex],
          }));
        onNotificationDatas(updatedTableData);

        return prevDateFollowedUp;
      });
      return prevServiceReceived;
    });
  };

  useEffect(() => {
    if (onNotificationClear) {
      setServiceReceived(Array(tableData.length).fill(""));
      setDateFollowedUp(Array(tableData.length).fill(""));
      onNotificationClearFalse(true);
      setSubmitBtnDisabled(false);
    }
  }, [onNotificationClear ? onNotificationClear : null]);

  const handleReferralChange = (rowIndex, selectedValue) => {
    setReferral((prevReferral) => {
      const newReferral = [...prevReferral];
      newReferral[rowIndex] = selectedValue;
      return newReferral;
    });

    const currentItem = tableData[rowIndex];
    currentItem.ReferredTo = selectedValue;
  };

  const handlePacServicesChange = (rowIndex, selectedValue) => {
    setPacServices((prevPacServices) => {
      const newPacServices = [...prevPacServices];
      newPacServices[rowIndex] = selectedValue;
      return newPacServices;
    });

    const currentItem = tableData[rowIndex];
    currentItem.PACServices = selectedValue;
  };

  const handleBrandChange = async (rowIndex, selectedValue) => {
    setMstBrandId((prevBrand) => {
      const newBrand = [...prevBrand];
      newBrand[rowIndex] = selectedValue;
      return newBrand;
    });

    const currentItem = tableData[rowIndex];
    currentItem.MstBrandId = selectedValue;
    const data = {
      mstCommodityType: 1,
      mstCategoryId: tableData[rowIndex].MstCommodityCategoryId,
      mstCommodityId: tableData[rowIndex].FPItemProvidedId,
      mstBrandId: tableData[rowIndex].MstBrandId,
      mstOrganizationStructureId:
        tableData[rowIndex].MstOrganizationStructureId,
      mstUserId: tableData[rowIndex].MstUserId,
      mstRoleId: tableData[rowIndex].MstRoleId,
      mstBranchProjectId: tableData[rowIndex].MstBranchProjectId,
      mstSDPId: tableData[rowIndex].MstSDPId,
      mstAreaId: tableData[rowIndex].MstAreaId,
      mstORWId: tableData[rowIndex].MstORWId,
      servicesId: tableData[rowIndex].ServicesId
        ? tableData[rowIndex].ServicesId
        : "",
      flag: tableData[rowIndex].Flag ? tableData[rowIndex].Flag : 1,
    };
    try {
      // Make API call
      const response = await BindPostAPI(
        [data],
        "TXNServicesAPI/BindAvailableCurrentStock"
      );

      // Update `currentStock` based on API response
      if (response?.data?.Table?.length > 0) {
        const updatedCurrentStock = [...currentStock];
        updatedCurrentStock[rowIndex] =
          response.data.Table[0].BalanceQuantity ?? "";
        setCurrentStock(updatedCurrentStock);
      } else {
        console.log("No data available for current stock.");
      }

      console.log("current", currentStock);
    } catch (error) {
      console.error("Error fetching current stock:", error);
    }
  };
  const handleIssueBrandChange = async (rowIndex, selectedValue) => {
    debugger;
    setIssueBrandId((prevBrand) => {
      const newBrand = [...prevBrand];
      newBrand[rowIndex] = selectedValue;
      return newBrand;
    });

    const currentItem = tableData[rowIndex];
    currentItem.IssueBrandId = selectedValue;
    // const data = {
    //   mstCommodityType:
    //     parseInt(tableData[rowIndex].CommodityCategoryType, 10) || 0,
    //   mstCategoryId:
    //     parseInt(tableData[rowIndex].MstCommodityCategoryId, 10) || 0,
    //   mstCommodityId: parseInt(tableData[rowIndex].MstCommoditiesId, 10) || 0,
    //   mstBrandId: tableData[rowIndex].IssueBrandId,
    //   mstOrganizationStructureId:
    //     tableData[rowIndex].MstOrganizationStructureId,
    //   mstUserId: tableData[rowIndex].MstUserId,
    //   mstRoleId: tableData[rowIndex].MstRoleId,
    //   mstBranchProjectId: tableData[rowIndex].MstBranchProjectId,
    //   mstSDPId: tableData[rowIndex].MstSDPId ?? 0,
    //   mstAreaId: 0,
    //   mstORWId: tableData[rowIndex].MstORWId,
    //   servicesId: tableData[rowIndex].ServicesId
    //     ? tableData[rowIndex].ServicesId
    //     : "",
    //   flag: tableData[rowIndex].Flag ? tableData[rowIndex].Flag : 1,
    // };
    // try {
    //   // Make API call
    //   const response = await BindPostAPI(
    //     [data],
    //     "TXNServicesAPI/BindAvailableCurrentStock"
    //   );

    //   // Update `currentStock` based on API response
    //   const updatedCurrentStock = [...availableStock];
    //   if (response?.data?.Table?.length > 0) {
    //     updatedCurrentStock[rowIndex] =
    //       response.data.Table[0].BalanceQuantity ?? "";
    //       // tableData[rowIndex].AvailableStock = updatedCurrentStock[rowIndex];
    //   } else {
    //     // Set `currentStock` to 0 if no data is returned
    //     updatedCurrentStock[rowIndex] = 0;
    //     console.log("No data available for current stock. Setting to 0.");
    //   }
    //   setAvailableStock(updatedCurrentStock);
    //   const currentItem = tableData[rowIndex];
    //   currentItem.AvailableStock = updatedCurrentStock[rowIndex];
    //   console.log("current", availableStock);
    // } catch (error) {
    //   console.error("Error fetching current stock:", error);
    // }
  };
  const getCurrentDateTimeISO = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`; // Format: YYYY-MM-DDTHH:mm:ss
  };
  const handleIssueBatchNoChange = async (rowIndex, selectedValue) => {
    debugger;
    const currentItem = tableData[rowIndex];
    currentItem.BatchNo = selectedValue;
    const data = {
      mstCommodityType:
        parseInt(tableData[rowIndex].CommodityCategoryType, 10) || 0,
      mstCategoryId:
        parseInt(tableData[rowIndex].MstCommodityCategoryId, 10) || 0,
      mstCommodityId: parseInt(tableData[rowIndex].MstCommoditiesId, 10) || 0,
      mstBrandId: parseInt(tableData[rowIndex].IssueBrandId, 10) || 0,
      batchNo: tableData[rowIndex].BatchNo,
      mstOrganizationStructureId:
        tableData[rowIndex].MstOrganizationStructureId,
      mstUserId: tableData[rowIndex].MstUserId,
      mstRoleId: tableData[rowIndex].MstRoleId,
      mstBranchProjectId: tableData[rowIndex].MstBranchProjectId,
      mstSDPId: tableData[rowIndex].MstSDPId ?? 0,
      mstAreaId: 0,
      mstORWId: tableData[rowIndex].MstORWId,
      txnTypeId: tableData[rowIndex].ServicesId
        ? tableData[rowIndex].ServicesId
        : "",
      date: getCurrentDateTimeISO(),
      flag: tableData[rowIndex].Flag ? tableData[rowIndex].Flag : 1,
    };
    try {
      // Make API call
      const response = await BindPostAPI(
        [data],
        "InventoryIssuesAPI/BindAvailableCurrentStockBatchNoWise"
      );

      // Update `currentStock` based on API response
      const updatedCurrentStock = [...availableStock];
      const updatedExpDate = [...dateOfExpiry];
      if (response?.data?.Table?.length > 0) {
        updatedCurrentStock[rowIndex] =
          response.data.Table[0].BalanceBatchNoQuantity ?? "";
        const currentDate = response.data.Table[0].ExpiryDate;
        const formattedDate = currentDate ? currentDate.split("T")[0] : "";
        updatedExpDate[rowIndex] = formattedDate ?? "";
        // updatedExpDate[rowIndex] =
        // response.data.Table[0].ExpiryDate ?? "";
        // tableData[rowIndex].AvailableStock = updatedCurrentStock[rowIndex];
      } else {
        // Set `currentStock` to 0 if no data is returned
        updatedCurrentStock[rowIndex] = 0;
        console.log("No data available for current stock. Setting to 0.");
      }
      setAvailableStock(updatedCurrentStock);
      setDateOfExpiry(updatedExpDate);
      const currentItem = tableData[rowIndex];
      currentItem.AvailableStock = updatedCurrentStock[rowIndex];
      const currentExpDate = tableData[rowIndex];
      currentExpDate.DateOfExpiry = updatedExpDate[rowIndex];
      console.log("current", availableStock);
    } catch (error) {
      console.error("Error fetching current stock:", error);
    }
  };
  useEffect(() => {
    const serviceqtyEdit = [...qtyEdit];
    if (!Array.isArray(tableData)) return;
    tableData.forEach((item, rowIndex) => {
      if (item.MstBrandId !== undefined) {
        handleBrandChange(rowIndex, item.MstBrandId);
        serviceqtyEdit[rowIndex] = item?.Quantity ?? "";
      }
    });
    setQTYEdit(serviceqtyEdit);
  }, [tableData]);
  useEffect(() => {
    if (tableData.length > 0) {
      const serviceTestResult = [...testResult];
      const serviceUOM = [...uom];
      const serviceItem = [...qty];
      const serviceCharges = [...charges];
      const serviceFPUser = [...FPUser];
      const serviceReferredTo = [...referral];
      const servicePacServices = [...pacServices];
      const serviceFPItemProvidedIdOptions = [...FPItemProvidedIdOptions];
      const serviceMstBrandId = [...MstBrandId];
      const issueMstBrandId = [...issueBrandId];
      const issueBrandOptions = [...brandOptions];
      const issueIssueNow = [...issueNow];
      const issueBalanceStock = [...balanceStock];
      const issueBatchNo = [...batchNo];
      const issueDateOfExpiry = [...dateOfExpiry];
      const issueAvailableStock = [...availableStock];
      if (tableData.length > 0) {
        if (!Array.isArray(tableData)) return;
        tableData.forEach((data, index) => {
          // if (data?.TestResult !== undefined)
          //   serviceTestResult[index] = data.TestResult;

          // if (data?.UOM !== undefined) serviceUOM[index] = data.UOM;
          // if (data?.Quantity !== undefined) serviceItem[index] = data.Quantity;
          // if (data?.Charges !== undefined) serviceCharges[index] = data.Charges;

          serviceTestResult[index] = data?.TestResult ?? "";

          serviceUOM[index] = data?.UOM ?? "";

          serviceItem[index] = data?.Quantity ?? "";

          serviceCharges[index] = data?.Charges ?? "";

          if (data?.FPUser !== undefined) serviceFPUser[index] = data.FPUser;
          if (data?.ReferredTo !== undefined)
            serviceReferredTo[index] = data.ReferredTo;
          if (data?.PACServices !== undefined)
            servicePacServices[index] = data.PACServices;
          //  if (data?.MstBrandId !== undefined)
          serviceMstBrandId[index] = data.MstBrandId ?? "";
          serviceFPItemProvidedIdOptions[index] =
            data?.FPItemProvidedIdOptions ?? [];
          issueMstBrandId[index] = data.IssueBrandId ?? "";
          issueBrandOptions[index] = data?.brandOptions ?? [];
          issueIssueNow[index] = data.IssueNow ?? "";
          issueBalanceStock[index] = data.BalanceStock ?? "";
          issueBatchNo[index] = data.BatchNo ?? "";
          const currentDate = data.DateOfExpiry;
          const formattedDate = currentDate ? currentDate.split("T")[0] : "";
          issueDateOfExpiry[index] = formattedDate ?? "";
          issueAvailableStock[index] = data.AvailableStock ?? "";
          handleIssueBatchNoChange(index, data.BatchNo);
        });

        setTestResult(serviceTestResult);
        setUOM(serviceUOM);
        setQTY(serviceItem);
        setCharges(serviceCharges);
        setFPUser(serviceFPUser.join(""));
        setReferral(serviceReferredTo.join(""));
        setPacServices(servicePacServices.join(""));
        setFPItemProvidedIdOptions(serviceFPItemProvidedIdOptions);
        setMstBrandId(serviceMstBrandId);
        setIssueBrandId(issueMstBrandId);
        setBrandOptions(issueBrandOptions);
        setIssueNow(issueIssueNow);
        setBalanceStock(issueBalanceStock);
        setBatchNo(issueBatchNo);
        setDateOfExpiry(issueDateOfExpiry);
        setAvailableStock(issueAvailableStock);
      }
      const updatedFields = {
        clientYears: [],
        clientMonths: [],
        clientDays: [],
        clientGender: [],
      };

      tableData?.forEach((item) => {
        updatedFields.clientYears?.push(item.clientYears);
        updatedFields.clientMonths?.push(item.clientMonths);
        updatedFields.clientDays?.push(item.clientDays);
        updatedFields.clientGender?.push(item.clientGender);
      });

      setClientNoOfLivingChildrenFields(updatedFields);
    }
  }, [tableData]);

  const handleNotificationBlur = (rowIndex) => {
    setNextFollowUpDate((prevNextFollowUpDate) => {
      const updatedTableData = tableData
        .filter((item, index) => index == rowIndex)
        .map((data, index) => ({
          ...data,
          Action: action[rowIndex],
          RowIndex: [rowIndex],
          NotificationQuantity: notificationQuantity[rowIndex],
          Remarks: remarks[rowIndex],
          NextFollowUpDate: prevNextFollowUpDate[rowIndex],
        }));

      onNotificationDatas(updatedTableData);

      return prevNextFollowUpDate;
    });
  };
  const handleActionChange = (rowIndex, selectedValue) => {
    setAction((prevAction) => {
      const newAction = [...prevAction];
      newAction[rowIndex] = selectedValue;
      return newAction;
    });
    handleNotificationBlur(rowIndex);
  };

  const handleNextFollowUpDateChange = (rowIndex, value) => {
    setNextFollowUpDate((prevNextFollowUpDate) => {
      const updatedNextFollowUpDate = [...prevNextFollowUpDate];
      updatedNextFollowUpDate[rowIndex] = value;
      return updatedNextFollowUpDate;
    });
    handleNotificationBlur(rowIndex);
  };

  useEffect(() => {
    if (onNotificationClear) {
      setAction(Array(tableData.length).fill(""));
      setNextFollowUpDate(Array(tableData.length).fill(""));
      setRemarks(Array(tableData.length).fill(""));
      setNotificationQuantity(Array(tableData.length).fill(""));
      onNotificationClearFalse(true);
    }
  }, [onNotificationClear ? onNotificationClear : null]);

  const handleYears = (rowIndex, value) => {
    const parsedValue = parseInt(value, 10);
  };

  // Contraceptive Renewal Supply Start

  const handleContraceptiveNotification = (rowIndex) => {
    setContraceptiveRemarks((prevContraceptiveRemarks) => {
      setContraceptiveRemarksDiscontinued((prevContraceptiveRemarks1) => {
        setContraceptiveNextFollowUpDate((prevNextFollowUpDate) => {
          const updatedTableData = tableData
            .filter((item, index) => index == rowIndex)
            .map((data, index) => ({
              ...data,
              RowIndex: [rowIndex],
              // NotificationQuantity: contraceptiveQuantity[rowIndex],
              Remarks: prevContraceptiveRemarks[rowIndex],
              Remarks2: prevContraceptiveRemarks1[rowIndex],
              NextFollowUpDate: prevNextFollowUpDate[rowIndex],
            }));

          onContraceptiveNotificationDatas(updatedTableData);

          return prevNextFollowUpDate;
        });
        return prevContraceptiveRemarks1;
      });
      return prevContraceptiveRemarks;
    });
  };
  useEffect(() => {
    if (onNotificationClear) {
      setContraceptiveRemarks(Array(tableData.length).fill(""));
      setRemarks1Visibility(Array(tableData.length).fill(""));
      setContraceptiveRemarksDiscontinued(Array(tableData.length).fill(""));
      setContraceptiveNextFollowUpDate(Array(tableData.length).fill(""));
      // setContraceptiveQuantity(Array(tableData.length).fill(""));
      onNotificationClearFalse(true);
    }
  }, [onNotificationClear]);

  const handleContraceptiveRemarks = (rowIndex, selectedValue) => {
    setContraceptiveRemarks((prevContraceptiveRemarks) => {
      const newContraceptiveRemarks = [...prevContraceptiveRemarks];
      newContraceptiveRemarks[rowIndex] = selectedValue;
      return newContraceptiveRemarks;
    });
    handleRemarksChange(rowIndex, selectedValue);
    handleContraceptiveNotification(rowIndex);
  };

  const handleContraceptiveRemarks1 = (rowIndex, selectedValue) => {
    setContraceptiveRemarksDiscontinued((prevContraceptiveRemarks1) => {
      const newContraceptiveRemarks = [...prevContraceptiveRemarks1];
      newContraceptiveRemarks[rowIndex] = selectedValue;
      return newContraceptiveRemarks;
    });

    handleContraceptiveNotification(rowIndex);
  };
  const handleRemarksChange = (rowIndex, selectedValue) => {
    const updatedVisibility = [...remarks1Visibility];

    updatedVisibility[rowIndex] = selectedValue === "3";
    setRemarks1Visibility(updatedVisibility);
  };

  const contraceptiveNextFollowUpDateChange = (rowIndex, value) => {
    setContraceptiveNextFollowUpDate((prevFollowUpDate) => {
      const updatedFollowUpDate = [...prevFollowUpDate];
      updatedFollowUpDate[rowIndex] = value;
      return updatedFollowUpDate;
    });
    handleContraceptiveNotification(rowIndex);
  };

  // Contraceptive Renewal Supply End

  // Referral Follow Up Start

  const handleReferralFollowUpNotification = (rowIndex) => {
    setReferralFollowUpRemarks((prevRemarks) => {
      const updatedTableData = tableData
        .filter((item, index) => index == rowIndex)
        .map((data, index) => ({
          ...data,
          RowIndex: [rowIndex],
          Remarks: prevRemarks[rowIndex],
        }));

      onReferralFollowUpNotificationDatas(updatedTableData);
      console.log("updatedTableData", updatedTableData);
      return prevRemarks;
    });
  };

  const handleReferralFollowUpRemarks = (rowIndex, selectedValue) => {
    setReferralFollowUpRemarks((prevRemarks) => {
      const newRemarks = [...prevRemarks];
      newRemarks[rowIndex] = selectedValue;
      return newRemarks;
    });
    handleReferralFollowUpNotification(rowIndex);
  };

  useEffect(() => {
    if (onNotificationClear) {
      setReferralFollowUpRemarks(Array(tableData.length).fill(""));

      onNotificationClearFalse(true);
    }
  }, [onNotificationClear ? onNotificationClear : null]);

  const NotificationSavedisable = (rowIndex) => {
    setSubmitBtnDisabled(true);
    onNotificationSave(rowIndex);
  };

  return (
    <div className={mainTable.tableShadowWidth}>
      <div
        className={`${tableScroll ? tableScroll : mainTable.table}`}
        role="table"
      >
        <div className={`${xScroll ? xScroll : mainTable.xScroll}`}>
          <div className={mainTable.tableHeader} role="rowgroup">
            {headerData.map((item, itemIndex) => (
              <div className={item.width} role="columnheader">
                <label class="text-sm  font-medium">{item.title}</label>
              </div>
            ))}
          </div>

          <div
            className={`overflow-y-auto ${
              tableHeight ? tableHeight : "h-80 overflow-y-auto"
            } `}
          >
            {currentData.length > 0 ? (
              currentData.map((data, rowIndex) => (
                <div class={mainTable.tableRow}>
                  {headerData.map((keyData) => (
                    <div scope="cell" role="cell" className={keyData.width}>
                      {keyData.key === "address" ||
                      keyData.key === "details" ? (
                        <button
                          onClick={() =>
                            onAddressGrid(
                              data.oldIndex,
                              // startIndex +
                              //   rowIndex +
                              //   (currentPage - 1) * itemsPerPage,
                              1
                            )
                          }
                          class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                        >
                          <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                          <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                          <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                          <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                          <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                          <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                            View
                          </span>
                        </button>
                      ) : keyData.key === "action" ? (
                        <div>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44"
                            >
                              <ul
                                class=""
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                                {editBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        //                                     const SEODateParts = data.SEODate.split('-'); // Assuming format is DD-MM-YYYY
                                        // const todaydate = new Date(); // Today’s date

                                        // // Convert SEODate to a proper Date object
                                        // const SEODate = new Date(
                                        //   SEODateParts[2], // Year
                                        //   SEODateParts[1] - 1, // Month (0-based index)
                                        //   SEODateParts[0] // Day
                                        // );

                                        const SEODate = new Date(
                                          data.SEODate ||
                                            data.SPDateTime ||
                                            data.IndentDate ||
                                            data.EventDate ||
                                            data.OpeningDate
                                        ); // Create Date object directly from YYYY-MM-DD
                                        const todaydate = new Date();
                                        // Calculate the difference in days
                                        const timeDiff = todaydate - SEODate;
                                        const dayDiff =
                                          timeDiff / (1000 * 60 * 60 * 24);
                                        if (dayDiff > NODtoEditPastEntries) {
                                          showPopUp(false);
                                          toast.error(
                                            `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                            {
                                              position: "top-center",
                                              autoClose: 3000, // Duration in ms
                                            }
                                          );
                                        } else {
                                          onEditGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            2
                                          );
                                          showPopUp(false);
                                        }
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a>
                                    {/* <a
                                      onClick={() => {
                                        onEditGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          2
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a> */}
                                  </li>
                                )}
                                {deleteBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onDeleteGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          3
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                                {blockBag == 1 && (
                                  <li>
                                    {data.BlockedBy ? (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            5
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Unblock
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            4
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Block
                                      </a>
                                    )}
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.key === "IssueAction" ? (
                        <div className="flex">
                          <div className="flex flex-col gap-y-2">
                            <button
                              id="dropdownMenuIconButton"
                              data-dropdown-toggle="dropdownDots"
                              class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                              type="button"
                              className={mainTable.addButton}
                              onClick={() => onSchoolView(rowIndex)}
                            >
                              View
                            </button>
                          </div>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44"
                            >
                              <ul
                                class=""
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                                {editBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        const SEODateParts =
                                          data.IssueDate.split("-"); // Assuming format is DD-MM-YYYY
                                        const todaydate = new Date(); // Today’s date

                                        // Convert SEODate to a proper Date object
                                        const SEODate = new Date(
                                          SEODateParts[2], // Year
                                          SEODateParts[1] - 1, // Month (0-based index)
                                          SEODateParts[0] // Day
                                        );
                                        // const SEODate = new Date(data.IssueDate); // Create Date object directly from YYYY-MM-DD
                                        // const todaydate = new Date();
                                        // Calculate the difference in days
                                        const timeDiff = todaydate - SEODate;
                                        const dayDiff =
                                          timeDiff / (1000 * 60 * 60 * 24);
                                        if (dayDiff > NODtoEditPastEntries) {
                                          showPopUp(false);
                                          toast.error(
                                            `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                            {
                                              position: "top-center",
                                              autoClose: 3000, // Duration in ms
                                            }
                                          );
                                        } else {
                                          onEditGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            2
                                          );
                                          showPopUp(false);
                                        }
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a>
                                    {/* <a
                                      onClick={() => {
                                        onEditGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          2
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a> */}
                                  </li>
                                )}
                                {deleteBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onDeleteGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          // rowIndex +
                                          // (currentPage - 1) * itemsPerPage,
                                          3
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                                {blockBag == 1 && (
                                  <li>
                                    {data.BlockedBy ? (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            5
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Unblock
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            4
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Block
                                      </a>
                                    )}
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.key === "receiptAction" ? (
                        <div className="flex gap-2">
                          <button
                            onClick={() => onSchoolView(rowIndex)}
                            class="relative p-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          >
                            <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                            <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                            <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                            <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                            <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span>
                            <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                              Preview
                            </span>
                          </button>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44"
                            >
                              <ul
                                class=""
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                                {editBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        //                                     const SEODateParts = data.SEODate.split('-'); // Assuming format is DD-MM-YYYY
                                        // const todaydate = new Date(); // Today’s date

                                        // // Convert SEODate to a proper Date object
                                        // const SEODate = new Date(
                                        //   SEODateParts[2], // Year
                                        //   SEODateParts[1] - 1, // Month (0-based index)
                                        //   SEODateParts[0] // Day
                                        // );
                                        const SEODate = new Date(
                                          data.ReceiptDate ||
                                            data.ConsumptionDate
                                        ); // Create Date object directly from YYYY-MM-DD
                                        const todaydate = new Date();
                                        // Calculate the difference in days
                                        const timeDiff = todaydate - SEODate;
                                        const dayDiff =
                                          timeDiff / (1000 * 60 * 60 * 24);
                                        if (dayDiff > NODtoEditPastEntries) {
                                          showPopUp(false);
                                          toast.error(
                                            `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                            {
                                              position: "top-center",
                                              autoClose: 3000, // Duration in ms
                                            }
                                          );
                                        } else {
                                          onEditGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            2
                                          );
                                          showPopUp(false);
                                        }
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a>
                                    {/* <a
                                      onClick={() => {
                                        onEditGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          2
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a> */}
                                  </li>
                                )}
                                {deleteBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onDeleteGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          3
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                                {blockBag == 1 && (
                                  <li>
                                    {data.BlockedBy ? (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            5
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Unblock
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            4
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Block
                                      </a>
                                    )}
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.key === "schoolAction" ? (
                        <div className="flex gap-2">
                          <button
                            onClick={() => onSchoolView(rowIndex)}
                            class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          >
                            <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                            <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                            <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                            <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                            <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span>
                            <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                              Preview
                            </span>
                          </button>
                          {/* {parseInt(data["Sessions"].split(',').pop(), 10) < 4 && ( */}
                          <MdAdd
                            className={`w-6 h-6 mt-2 text-current hover:text-[#2d2d6e]${
                              parseInt(data["Sessions"].split(",").pop(), 10) >=
                              4
                                ? "text-gray-400 cursor-not-allowed"
                                : "hover:text-[#2d2d6e] cursor-pointer"
                            }`}
                            title={
                              parseInt(data["Sessions"].split(",").pop(), 10) >=
                              4
                                ? "Max sessions reached"
                                : ""
                            }
                            onClick={
                              parseInt(data["Sessions"].split(",").pop(), 10) >=
                              4
                                ? undefined
                                : () => onAdd(rowIndex)
                            } 
                          />
                          {/* )} */}
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44"
                            >
                              <ul
                                class=""
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                                {editBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        //                                     const SEODateParts = data.SEODate.split('-'); // Assuming format is DD-MM-YYYY
                                        // const todaydate = new Date(); // Today’s date

                                        // // Convert SEODate to a proper Date object
                                        // const SEODate = new Date(
                                        //   SEODateParts[2], // Year
                                        //   SEODateParts[1] - 1, // Month (0-based index)
                                        //   SEODateParts[0] // Day
                                        // );
                                        const SEODate = new Date(data.Date); // Create Date object directly from YYYY-MM-DD
                                        const todaydate = new Date();
                                        // Calculate the difference in days
                                        const timeDiff = todaydate - SEODate;
                                        const dayDiff =
                                          timeDiff / (1000 * 60 * 60 * 24);
                                        if (dayDiff > NODtoEditPastEntries) {
                                          showPopUp(false);
                                          toast.error(
                                            `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                            {
                                              position: "top-center",
                                              autoClose: 3000, // Duration in ms
                                            }
                                          );
                                        } else {
                                          onEditGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            2
                                          );
                                          showPopUp(false);
                                        }
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a>
                                    {/* <a
                                      onClick={() => {
                                        onEditGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          2
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a> */}
                                  </li>
                                )}
                                {/* {deleteBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onDeleteGrid(
                                        data.oldIndex,
                                          startIndex +
                                            rowIndex +
                                            (currentPage - 1) * itemsPerPage,
                                          3
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                                {blockBag == 1 && (
                                  <li>
                                    {data.BlockedBy ? (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                          data.oldIndex,
                                            startIndex +
                                              rowIndex +
                                              (currentPage - 1) * itemsPerPage,
                                            5
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Unblock
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                          data.oldIndex,
                                            startIndex +
                                              rowIndex +
                                              (currentPage - 1) * itemsPerPage,
                                            4
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Block
                                      </a>
                                    )}
                                  </li>
                                )} */}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.key === "action1" ? (
                        <div>
                          {serviceBag == 1 && data.BlockedBy == null && (
                            <button
                              id="dropdownMenuIconButton"
                              data-dropdown-toggle="dropdownDots"
                              class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                              type="button"
                              // onClick={() => showPopUp(!viewsAll, rowIndex)}
                              onClick={() =>
                                navigate("/services", {
                                  state: {
                                    ...data,
                                    isAdd: true,
                                    isData: currentData[rowIndex],
                                    isvisible3: false,
                                    menuId: 28,
                                  },
                                })
                              }
                            >
                              <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                viewBox="0 0 24 24"
                                class="w-6 h-6"
                              >
                                <path d="M3 15l5.12-5.12A3 3 0 0110.24 9H13a2 2 0 110 4h-2.5m4-.68l4.17-4.89a1.88 1.88 0 012.92 2.36l-4.2 5.94A3 3 0 0114.96 17H9.83a2 2 0 00-1.42.59L7 19M2 14l6 6" />
                              </svg>
                            </button>
                          )}
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>

                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44"
                            >
                              <ul
                                class="py-2 text-sm text-gray-700"
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                                {editBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        //                                     const SEODateParts = data.SEODate.split('-'); // Assuming format is DD-MM-YYYY
                                        // const todaydate = new Date(); // Today’s date

                                        // // Convert SEODate to a proper Date object
                                        // const SEODate = new Date(
                                        //   SEODateParts[2], // Year
                                        //   SEODateParts[1] - 1, // Month (0-based index)
                                        //   SEODateParts[0] // Day
                                        // );
                                        const SEODate = new Date(
                                          data.ServicesDate ||
                                            data.ClientRegisterDate
                                        ); // Create Date object directly from YYYY-MM-DD
                                        const todaydate = new Date();
                                        // Calculate the difference in days
                                        const timeDiff = todaydate - SEODate;
                                        const dayDiff =
                                          timeDiff / (1000 * 60 * 60 * 24);
                                        if (dayDiff > NODtoEditPastEntries) {
                                          showPopUp(false);
                                          toast.error(
                                            `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                            {
                                              position: "top-center",
                                              autoClose: 3000, // Duration in ms
                                            }
                                          );
                                        } else {
                                          onEditGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            2
                                          );
                                          showPopUp(false);
                                        }
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a>
                                    {/* <a
                                      onClick={() => {
                                        onEditGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage
                                          2
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Edit
                                    </a> */}
                                  </li>
                                )}
                                {deleteBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onDeleteGrid(
                                          data.oldIndex,
                                          // startIndex +
                                          //   rowIndex +
                                          //   (currentPage - 1) * itemsPerPage,
                                          3
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                )}
                                {blockBag == 1 && (
                                  <li>
                                    {data.BlockedBy ? (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            5
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Unblock
                                      </a>
                                    ) : (
                                      <a
                                        onClick={() => {
                                          onBlockGrid(
                                            data.oldIndex,
                                            // startIndex +
                                            //   rowIndex +
                                            //   (currentPage - 1) * itemsPerPage,
                                            4
                                          );
                                          showPopUp(false);
                                        }}
                                        class="block px-4 py-2 hover:bg-gray-100"
                                      >
                                        Block
                                      </a>
                                    )}
                                  </li>
                                )}
                                {/* <li>
                                  <Link
                                    to={"/services"}
                                    state={{
                                      ...data,
                                      isAdd: true,
                                      isvisible3: false,
                                    }}
                                    // onClick={() => onServiceProvideGrid(rowIndex)}
                                    class="block px-4 py-2 hover:bg-gray-100"
                                  >
                                    Provide Service
                                  </Link>
                                </li> */}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.key === "ServicesClientAction" ? (
                        <div>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() =>
                              navigate("/services", {
                                state: {
                                  ...data,
                                  isAdd: true,
                                  isvisible3: false,
                                  isData: currentData[rowIndex],
                                  menuId: 28,
                                },
                              })
                            }
                          >
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              class="w-6 h-6"
                            >
                              <path d="M3 15l5.12-5.12A3 3 0 0110.24 9H13a2 2 0 110 4h-2.5m4-.68l4.17-4.89a1.88 1.88 0 012.92 2.36l-4.2 5.94A3 3 0 0114.96 17H9.83a2 2 0 00-1.42.59L7 19M2 14l6 6" />
                            </svg>
                          </button>
                          {viewBag == 1 && (
                            <button
                              id="dropdownMenuIconButton"
                              data-dropdown-toggle="dropdownDots"
                              class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                              type="button"
                              onClick={() => {
                                onHistoryGrid(
                                  data.oldIndex,

                                  2
                                );
                              }}
                            >
                              <svg
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                viewBox="0 0 24 24"
                                class="w-6 h-6"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h5.697M18 14v4h4M18 11V7a2 2 0 00-2-2h-2" />
                                <path d="M10 3 H12 A2 2 0 0 1 14 5 V5 A2 2 0 0 1 12 7 H10 A2 2 0 0 1 8 5 V5 A2 2 0 0 1 10 3 z" />
                                <path d="M22 18 A4 4 0 0 1 18 22 A4 4 0 0 1 14 18 A4 4 0 0 1 22 18 z" />
                                <path d="M8 11h4M8 15h3" />
                              </svg>
                            </button>
                          )}
                        </div>
                      ) : keyData.key === "test" ? (
                        <div>
                          {(tableData[rowIndex].TestResultType == "1" ||
                            tableData[rowIndex].TestResultType == "2" ||
                            tableData[rowIndex].TestResultType == "3" ||
                            tableData[rowIndex].TestResultType == "6") && (
                            <input
                              type="text"
                              className={mainTable.input}
                              value={testResult[rowIndex]}
                              disabled={
                                tableData[rowIndex].TestResultType === "6" ||
                                onDisabled
                              }
                              placeholder={
                                tableData[rowIndex].TestResultType == "1"
                                  ? "Range"
                                  : tableData[rowIndex].TestResultType == "2"
                                  ? "Decimals"
                                  : tableData[rowIndex].TestResultType == "3"
                                  ? "Result"
                                  : ""
                              }
                              maxLength={50}
                              onChange={(e) => {
                                const inputText = e.target.value;

                                if (tableData[rowIndex].TestResultType == "1") {
                                  if (/^[0-9-]*$/.test(inputText)) {
                                    const updatedTestResult = [...testResult];
                                    updatedTestResult[rowIndex] = inputText;
                                    setTestResult(updatedTestResult);
                                    const currentItem = tableData[rowIndex];
                                    currentItem.TestResult = inputText;
                                  }
                                } else if (
                                  tableData[rowIndex].TestResultType == "2"
                                ) {
                                  if (/^[0-9.]*$/.test(inputText)) {
                                    const updatedTestResult = [...testResult];
                                    updatedTestResult[rowIndex] = inputText;
                                    setTestResult(updatedTestResult);
                                    const currentItem = tableData[rowIndex];
                                    currentItem.TestResult = inputText;
                                  }
                                } else if (
                                  tableData[rowIndex].TestResultType == "3"
                                ) {
                                  if (/^[A-Za-z0-9.-]*$/.test(inputText)) {
                                    const updatedTestResult = [...testResult];
                                    updatedTestResult[rowIndex] = inputText;
                                    setTestResult(updatedTestResult);
                                    const currentItem = tableData[rowIndex];
                                    currentItem.TestResult = inputText;
                                  }
                                } else if (
                                  tableData[rowIndex].TestResultType == "6"
                                ) {
                                  if (/^[A-Za-z0-9.-]*$/.test(inputText)) {
                                    const updatedTestResult = [...testResult];
                                    updatedTestResult[rowIndex] = inputText;
                                    setTestResult(updatedTestResult);
                                    const currentItem = tableData[rowIndex];
                                    currentItem.TestResult = inputText;
                                  }
                                } else {
                                  if (/^[A-Za-z0-9.-]*$/.test(inputText)) {
                                    const updatedTestResult = [...testResult];
                                    updatedTestResult[rowIndex] = inputText;
                                    setTestResult(updatedTestResult);
                                    const currentItem = tableData[rowIndex];
                                    currentItem.TestResult = inputText;
                                  }
                                }
                              }}
                              onKeyPress={(e) => {
                                if (tableData[rowIndex].TestResultType == "1") {
                                  if (!/^[0-9-]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                } else if (
                                  tableData[rowIndex].TestResultType == "2"
                                ) {
                                  if (!/^[0-9.]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                } else if (
                                  tableData[rowIndex].TestResultType == "3"
                                ) {
                                  if (!/^[A-Za-z0-9.-]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                } else {
                                  if (!/^[A-Za-z0-9.-]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }
                              }}
                            />
                          )}
                          {tableData[rowIndex].TestResultType == "4" && (
                            <RadioGroup
                              key={rowIndex}
                              setName={`testResultType-${rowIndex}`}
                              selectedOptions={testResult[rowIndex]}
                              classDiv="w-full"
                              options={onTestResultTypeDD}
                              controlFunc={(value) =>
                                handleRadioChange1(value, rowIndex)
                              }
                              disabled={onDisabled}
                              Class1="grid md:grid lg:grid-cols-2 items-center bg-gray-50 border border-gray-300 rounded-lg"
                            />
                          )}
                        </div>
                      ) : keyData.key === "qty" ? (
                        <div>
                          <input
                            type="text"
                            className={mainTable.input}
                            title={
                              currentStock[rowIndex]
                                ? `Available stock: ${
                                    currentStock[rowIndex] || 0
                                  }`
                                : ""
                            }
                            value={qty[rowIndex]}
                            maxLength={5}
                            disabled={
                              tableData[rowIndex].ItemsProvided === "2" ||
                              onDisabled
                            } // This will disable only when ItemsProvided is exactly "2"
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (
                                /^\d*\.?\d*$/.test(inputText) &&
                                inputText.length <= 5 &&
                                (inputText === "" ||
                                  parseFloat(inputText) <=
                                    (currentStock[rowIndex] || 0))
                              ) {
                                const updatedqty = [...qty]; // Create a copy of the array
                                updatedqty[rowIndex] = inputText; // Update the specific element
                                setQTY(updatedqty); // Set the updated array in the state
                                const currentItem = tableData[rowIndex];
                                currentItem.Quantity = inputText;
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/[\d.]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      ) : keyData.key === "brand" ? (
                        <div className="">
                          {/* <DropDown
                            key={rowIndex}
                            options={bindReferralDD}
                            placeholder={"Select"}
                            name={"referral"}
                            disabled={onDisabled}
                            selectedOption={
                              referral[rowIndex] ? referral[rowIndex] : ""
                            }
                            controlFunc={(e) =>
                              handleReferralChange(rowIndex, e.target.value)
                            }
                          /> */}
                          <AutoDropDown
                            title=""
                            options={FPItemProvidedIdOptions[rowIndex] ?? []}
                            name="mstBrand"
                            placeholder="Select"
                            disabled={onDisabled}
                            selectedOption={
                              FPItemProvidedIdOptions[rowIndex]?.find(
                                (x) => x.value == MstBrandId[rowIndex]
                              ) || null
                            }
                            Class="custom-class"
                            controlFunc={(value) => {
                              handleBrandChange(rowIndex, value.value);
                            }}
                          />
                        </div>
                      ) : keyData.key === "pacservices" ? (
                        <div className="">
                          <DropDown
                            key={rowIndex}
                            options={bindPACServicesDD}
                            placeholder={"Select"}
                            name={"pacServices"}
                            disabled={onDisabled}
                            selectedOption={
                              pacServices[rowIndex] ? pacServices[rowIndex] : ""
                            }
                            controlFunc={(e) =>
                              handlePacServicesChange(rowIndex, e.target.value)
                            }
                          />
                        </div>
                      ) : keyData.key === "issueBrand" ? (
                        <div className="">
                          <AutoDropDown
                            ClassLabel="block text-sm capitalize font-medium text-neutral-700"
                            title=""
                            options={brandOptions[rowIndex] ?? []}
                            name="mstBrand"
                            placeholder="Select"
                            disabled={onDisabled}
                            selectedOption={
                              brandOptions[rowIndex]?.find(
                                (x) => x.value == issueBrandId[rowIndex]
                              ) || null
                            }
                            Class="custom-class"
                            controlFunc={(value) => {
                              handleIssueBrandChange(rowIndex, value.value);
                            }}
                          />
                        </div>
                      ) : keyData.key === "BatchNo" ? (
                        <div className="">
                          <InputText
                            classLabel="block text-sm capitalize font-medium text-neutral-700"
                            type="text"
                            placeholder=""
                            content={batchNo[rowIndex]}
                            maxLength={30}
                            controlFunc={(e) => {
                              if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                const updatedBatchNo = [...batchNo];
                                updatedBatchNo[rowIndex] = e.target.value;
                                setBatchNo(updatedBatchNo);
                                const currentItem = tableData[rowIndex];
                                currentItem.BatchNo = e.target.value;
                              }
                            }}
                            controlFuncBlur={(e) => {
                              handleIssueBatchNoChange(
                                rowIndex,
                                e.target.value
                              );
                            }}
                          />
                          {errorMessages[rowIndex] && (
                            <span class="text-red-500">
                              {errorMessages[rowIndex]}
                            </span>
                          )}
                        </div>
                      ) : keyData.key === "ExpiryDate" ? (
                        <div className="">
                          <InputText
                            classLabel="block text-sm capitalize font-medium text-neutral-700"
                            inputType="date"
                            content={dateOfExpiry[rowIndex]}
                            // maxLength={5}
                            controlFunc={(e) => {
                              const updatedDateOfExpiry = [...dateOfExpiry];
                              updatedDateOfExpiry[rowIndex] = e.target.value;
                              setDateOfExpiry(updatedDateOfExpiry);
                              const currentItem = tableData[rowIndex];
                              currentItem.DateOfExpiry = e.target.value;
                            }}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      ) : keyData.key === "Available" ? (
                        <div className="">
                          <label>
                            {availableStock[rowIndex]
                              ? availableStock[rowIndex]
                              : ""}
                          </label>
                        </div>
                      ) : keyData.key === "uom" ? (
                        <div>
                          <input
                            type="text"
                            className={mainTable.input}
                            value={tableData[rowIndex].ResultUOM}
                            maxLength={50}
                            disabled={true}
                            onChange={(e) => {
                              const inputText = e.target.value;

                              if (/^[A-Za-z]*$/.test(inputText)) {
                                const updatedUOM = [...uom];
                                updatedUOM[rowIndex] = inputText;
                                setUOM(updatedUOM);
                              }
                            }}
                            onKeyPress={(e) => {
                              if (!/^[A-Za-z]*$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      ) : keyData.key === "servicesCharge" ? (
                        <input
                          type="text"
                          className={mainTable.input}
                          value={charges[rowIndex]}
                          maxLength={8}
                          disabled={onDisabled}
                          onChange={(e) => {
                            if (
                              /^\d{0,5}(\.\d{0,2})?$/.test(e.target.value) &&
                              e.target.value.length <= 8
                            ) {
                              const updatedCharges = [...charges];
                              updatedCharges[rowIndex] = e.target.value;
                              setCharges(updatedCharges);
                              const currentItem = tableData[rowIndex];
                              currentItem.Charges = e.target.value;
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/[\d.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      ) : keyData.key === "FPUser" ? (
                        <div className="flex gap-1">
                          <RadioGroup
                            key={rowIndex}
                            setName={`consultedDoctor-${rowIndex}`}
                            selectedOptions={FPUser[rowIndex] || "1"}
                            classDiv="w-full"
                            options={onFPUserDD}
                            controlFunc={(value) =>
                              handleRadioChange(value, rowIndex)
                            }
                            disabled={onDisabled}
                            Class1="grid md:grid lg:grid-cols-2 items-center bg-gray-50 border border-gray-300 rounded-lg"
                          />
                        </div>
                      ) : keyData.key === "referral" ? (
                        <div className="">
                          <DropDown
                            key={rowIndex}
                            options={bindReferralDD}
                            placeholder={"Select"}
                            name={"referral"}
                            disabled={onDisabled}
                            selectedOption={
                              referral[rowIndex] ? referral[rowIndex] : ""
                            }
                            controlFunc={(e) =>
                              handleReferralChange(rowIndex, e.target.value)
                            }
                          />
                        </div>
                      ) : keyData.key === "DoctorConsultationAction" ? (
                        <div>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            type="button"
                            onClick={() =>
                              navigate("/services", {
                                state: {
                                  ...data,
                                  isAdd: true,
                                  isvisible3: false,
                                  isData: currentData[rowIndex],
                                  menuId: 28,
                                },
                              })
                            }
                          >
                            <svg
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              class="w-6 h-6"
                            >
                              <path d="M3 15l5.12-5.12A3 3 0 0110.24 9H13a2 2 0 110 4h-2.5m4-.68l4.17-4.89a1.88 1.88 0 012.92 2.36l-4.2 5.94A3 3 0 0114.96 17H9.83a2 2 0 00-1.42.59L7 19M2 14l6 6" />
                            </svg>
                          </button>
                        </div>
                      ) : keyData.key === "appointmentsAction" ? (
                        <div>
                          <button
                            type="button"
                            className={mainTable.appointmentsButton}
                            onClick={() => {
                              window.open(data.MeetingLink, "_blank");
                              console.log(
                                "data[keyData.key]",
                                data.MeetingLink
                              );
                            }}
                          >
                            Consult
                          </button>
                        </div>
                      ) : keyData.key === "PendingIndentAction" ? (
                        <div>
                          <Link
                            to={"/inventoryissue"}
                            state={{
                              ...data,
                              isAdd: true,
                              isvisible3: false,
                              isData: currentData[rowIndex],
                              menuId: 41,
                            }}
                            // onClick={() => onServiceProvideGrid(rowIndex)}
                            className={mainTable.appointmentsButton}
                          >
                            Issue
                          </Link>
                        </div>
                      ) : keyData.title === "S.No." ? (
                        <div>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      ) : keyData.title === "S.No." && data.BlockedBy ? (
                        <div class={keyData.width1}>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      ) : keyData.key == "childrenOrder" ? (
                        <div>{rowIndex + 1}</div>
                      ) : keyData.key == "clientYears" ? (
                        <div class="w-14">
                          <input
                            type="text"
                            className={mainTable.input}
                            disabled={onDisabled}
                            maxLength={2}
                            value={
                              clientNoOfLivingChildrenFields.clientYears[
                                rowIndex
                              ]
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,2}$/.test(value)) {
                                handleClientNoOfLivingChildren(
                                  "clientYears",
                                  value,
                                  rowIndex
                                );
                              }
                            }}
                          />
                        </div>
                      ) : keyData.key == "clientMonths" ? (
                        <div class="w-14">
                          <input
                            type="text"
                            className={mainTable.input}
                            disabled={onDisabled}
                            value={
                              clientNoOfLivingChildrenFields.clientMonths[
                                rowIndex
                              ]
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,2}$/.test(value)) {
                                handleClientNoOfLivingChildren(
                                  "clientMonths",
                                  value,
                                  rowIndex
                                );
                              }
                            }}
                          />
                        </div>
                      ) : keyData.key == "clientDays" ? (
                        <div class="w-14">
                          <input
                            type="text"
                            className={mainTable.input}
                            disabled={onDisabled}
                            value={
                              clientNoOfLivingChildrenFields.clientDays[
                                rowIndex
                              ]
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,2}$/.test(value)) {
                                handleClientNoOfLivingChildren(
                                  "clientDays",
                                  value,
                                  rowIndex
                                );
                              }
                            }}
                          />
                        </div>
                      ) : keyData.key == "clientGender" ? (
                        <div className="w-full">
                          <DropDown
                            ClassLabel="block text-sm capitalize font-medium text-neutral-700"
                            options={bindClientGenderDD}
                            placeholder={"Select"}
                            name={"gender"}
                            disabled={onDisabled}
                            selectedOption={
                              clientNoOfLivingChildrenFields.clientGender[
                                rowIndex
                              ]
                            } // Assuming referral is a property in your data
                            controlFunc={(e) =>
                              handleClientNoOfLivingChildren(
                                "clientGender",
                                e.target.value,
                                rowIndex
                              )
                            }
                          />
                        </div>
                      ) : keyData.key === "ServicesAction" ? (
                        <div>
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class={"w-4 h-4"}
                            onClick={() => onDeleteGrid(rowIndex)}
                          >
                            <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                          </svg>
                        </div>
                      ) : keyData.key === "issueAction" ? (
                        <div className="flex items-center mt-2.5 space-x-2">
                          {/* Delete Icon */}
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => onDeleteGrid(rowIndex)}
                          >
                            <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                          </svg>
                          {/* Add Icon */}
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => onEditGrid(rowIndex)}
                          >
                            <path d="M10 2a1 1 0 011 1v6h6a1 1 0 110 2h-6v6a1 1 0 11-2 0v-6H3a1 1 0 110-2h6V3a1 1 0 011-1z" />
                          </svg>
                        </div>
                      ) : keyData.key === "detailsAction" ? (
                        <div>
                          <input
                            type="button"
                            className={mainTable.submitButton}
                            onClick={() => onServicesGrid(rowIndex)}
                            value="Show Details"
                          />
                        </div>
                      ) : // keyData.key === "Quantity" ? (
                      //   <div>
                      //     <InputText type="text" content={quantity} readOnly />
                      //   </div>
                      // ) :
                      keyData.key === "NotificationAction" ? (
                        <div className="">
                          <DropDown
                            key={rowIndex}
                            type="text"
                            placeholder="Select"
                            options={[]}
                            selectedOption={action[rowIndex]}
                            controlFunc={(e) =>
                              handleActionChange(rowIndex, e.target.value)
                            }
                          />
                        </div>
                      ) : keyData.key === "NotificationQuantity" ? (
                        <div className="">
                          <InputText
                            inputType="text"
                            maxLength={5}
                            content={notificationQuantity[rowIndex]}
                            controlFunc={(e) => {
                              const updatedQty = [...notificationQuantity];
                              updatedQty[rowIndex] = e.target.value;
                              setNotificationQuantity(updatedQty);
                              handleNotificationBlur(rowIndex, e.target.value);
                            }}
                          />
                        </div>
                      ) : keyData.key === "NotificationRemarks" ? (
                        <div className="">
                          <TextArea
                            row={1}
                            content={remarks[rowIndex]}
                            maxLength={500}
                            controlFunc={(e) => {
                              const updatedRemarks = [...remarks];
                              updatedRemarks[rowIndex] = e.target.value;
                              setRemarks(updatedRemarks);
                              handleNotificationBlur(rowIndex, e.target.value);
                            }}
                          />
                        </div>
                      ) : keyData.key === "NotificationDate" ? (
                        <div className="">
                          <InputText
                            key={rowIndex}
                            inputType="date"
                            content={nextFollowUpDate[rowIndex]}
                            controlFunc={(e) =>
                              handleNextFollowUpDateChange(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ) : keyData.key === "ServiceReceived" ? (
                        <div className="flex gap-1">
                          <RadioGroup
                            key={rowIndex}
                            classLabel="block text-sm capitalize font-medium text-neutral-700"
                            setName={`consultedDoctor-${rowIndex}`}
                            selectedOptions={serviceReceived[rowIndex]}
                            classDiv="w-full"
                            options={[
                              { value: 1, label: "Yes" },
                              { value: 2, label: "No" },
                            ]}
                            controlFunc={(value) =>
                              handleRadioChangeServiceReceived(value, rowIndex)
                            }
                            disabled={false}
                            Class1="grid md:grid-cols-2  justify-center items-center bg-gray-50 border border-gray-300 rounded-lg"
                          />
                        </div>
                      ) : keyData.key === "DateFollowedUp" ? (
                        <div className="">
                          <InputText
                            key={rowIndex}
                            classLabel="block text-sm capitalize font-medium text-neutral-700"
                            inputType="date"
                            content={dateFollowedUp[rowIndex]}
                            controlFunc={(e) =>
                              handleDateFollowedUpChange(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ) : keyData.key === "ReferralTrackingSave" ? (
                        <div>
                          <button
                            type="button"
                            className={mainTable.appointmentsButton}
                            onClick={() => NotificationSavedisable(rowIndex)}
                            disabled={submitBtnDisabled ? true : false}
                          >
                            Save
                          </button>
                        </div>
                      ) : // <div className="">
                      //   <svg
                      //     viewBox="0 0 24 24"
                      //     fill="currentColor"
                      //     height="3em"
                      //     width="3em"
                      //     onClick={() => NotificationSavedisable(rowIndex)}
                      //     style={{
                      //       cursor: submitBtnDisabled
                      //         ? "not-allowed"
                      //         : "pointer",
                      //       opacity: submitBtnDisabled ? 0.5 : 1,
                      //       pointerEvents: submitBtnDisabled
                      //         ? "none"
                      //         : "auto",
                      //     }}
                      //   >
                      //     <path fill="none" d="M0 0h24v24H0z" />
                      //     <path d="M4 3h14l2.707 2.707a1 1 0 01.293.707V20a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z" />
                      //   </svg>
                      // </div>
                      keyData.key === "ContraceptiveRemarks" ? (
                        <div className={mainTable.grid2}>
                          <DropDown
                            key={rowIndex}
                            type="text"
                            placeholder="Select"
                            options={bindContraceptiveRemarks}
                            selectedOption={contraceptiveRemarks[rowIndex]}
                            controlFunc={(e) =>
                              handleContraceptiveRemarks(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                          {remarks1Visibility[rowIndex] && (
                            <DropDown
                              key={rowIndex}
                              type="text"
                              placeholder="Select"
                              options={bindDiscontinuedRemarks}
                              selectedOption={
                                contraceptiveRemarksDiscontinued[rowIndex]
                              }
                              controlFunc={(e) =>
                                handleContraceptiveRemarks1(
                                  rowIndex,
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </div>
                      ) : //  : keyData.key === "ContraceptiveQuantity" ? (
                      //   <div className="">
                      //     <InputText
                      //       inputType="text"
                      //       maxLength={5}
                      //       content={contraceptiveQuantity[rowIndex]}
                      //       controlFunc={(e) => {
                      //         const updatedQty = [...contraceptiveQuantity];
                      //         updatedQty[rowIndex] = e.target.value;
                      //         setContraceptiveQuantity(updatedQty);
                      //         handleContraceptiveNotification(
                      //           rowIndex,
                      //           e.target.value
                      //         );
                      //       }}
                      //     />
                      //   </div>
                      // )
                      keyData.key === "ContraceptiveNextFollowUpDate" ? (
                        <div className="">
                          <InputText
                            key={rowIndex}
                            inputType="date"
                            content={contraceptiveNextFollowUpDate[rowIndex]}
                            controlFunc={(e) =>
                              contraceptiveNextFollowUpDateChange(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ) : keyData.key === "ReferralFollowUpRemarks" ? (
                        <div className="">
                          <DropDown
                            key={rowIndex}
                            type="text"
                            placeholder="Select"
                            options={bindReferralFollowUpRemarks}
                            selectedOption={referralFollowUpRemarks[rowIndex]}
                            controlFunc={(e) =>
                              handleReferralFollowUpRemarks(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ) : keyData.key === "NotificationSave" ? (
                        <div className="">
                          <svg
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            height="3em"
                            width="3em"
                            onClick={() => NotificationSavedisable(rowIndex)}
                            style={{
                              cursor: submitBtnDisabled
                                ? "not-allowed"
                                : "pointer",
                              opacity: submitBtnDisabled ? 0.5 : 1,
                              pointerEvents: submitBtnDisabled
                                ? "none"
                                : "auto",
                            }}
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M4 3h14l2.707 2.707a1 1 0 01.293.707V20a1 1 0 01-1 1H4a1 1 0 01-1-1V4a1 1 0 011-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z" />
                          </svg>
                        </div>
                      ) : keyData.key === "healthcareImage" ||
                        keyData.key === "userImage" ||
                        keyData.key === "Photos" ||
                        keyData.key === "ChooseFile" ||
                        keyData.key === "ResourcesPhoto" || // Will be used in functionality
                        keyData.key === "HealthCareProviderPhoto" ||
                        keyData.key === "SupplierLogo" ||
                        keyData.key === "UserProfileImg" ? ( // Will be used in functionality
                        <img
                          class={``}
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={data[keyData.key] ? data[keyData.key] : download}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      ) : keyData.key === "IssueQuantity" ? (
                        <div>
                          <input
                            type="text"
                            className={mainTable.input}
                            value={issueNow[rowIndex]}
                            onChange={(e) =>
                              handleQuantityChange(rowIndex, e.target.value)
                            }
                            // onBlur={(event) => handleBlur(rowIndex, event)}
                            onBlur={() => handleQuantityBlur(rowIndex)}
                          />
                          {/* {errorMessages[rowIndex] && (
                            <span class="text-red-500">{errorMessages}</span>
                          )} */}
                        </div>
                      ) : keyData.key === "Balance" ? (
                        <div>
                          <input
                            type="text"
                            className={mainTable.input}
                            value={balanceStock[rowIndex]}
                            readOnly
                          />
                        </div>
                      ) : data[keyData.key1] === 1 ? (
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={data[keyData.key]}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      ) : data[keyData.key1] === 2 &&
                        data[keyData.key]?.startsWith(
                          "https://www.youtube.com/embed/"
                        ) ? (
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={youtube}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      ) : data[keyData.key1] === 3 &&
                        data[keyData.key]?.endsWith(".pdf") ? (
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={pdf}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      ) : //  : data[keyData.key1] === 2 &&
                      //   data[keyData.key]?.startsWith(
                      //     "https://www.youtube.com/embed/"
                      //   ) ? (
                      //     <iframe
                      //     title="Video"
                      //     className="rounded-full w-12 h-12"
                      //     src={data[keyData.key]}
                      //     allowFullScreen
                      //     onClick={(e) => {
                      //       e.preventDefault(); // Prevent the default behavior of the click event
                      //       handleVideoClick(data[keyData.key]); // Open the popup instead
                      //     }}
                      //   ></iframe>
                      // )
                      data.BlockedBy ? (
                        <div className={keyData.width1}>
                          <div
                            class="truncate relative text-red-600"
                            title={data[keyData.key]}
                          >
                            {data[keyData.key]}
                          </div>
                          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"></div>
                        </div>
                      ) : keyData.key == "ClientId" ? (
                        <>
                          <div>{data?.ClientId}</div>
                          {data?.ClientExistingClientId && (
                            <div className="">
                              {data?.ClientExistingClientId}
                            </div>
                          )}
                        </>
                      ) : keyData.key == "ServicesId" ? (
                        <>
                          <div>{data?.ClientId}</div>
                          {data?.ServicesId && (
                            <div className="">{data?.ServicesId}</div>
                          )}
                        </>
                      ) : (
                        <div class="relative">
                          <div
                            class="truncate relative"
                            title={data[keyData.key]}
                          >
                            {data[keyData.key]}
                          </div>
                          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            {/* <div class="bg-white text-black rounded p-1">
                              ...
                            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div class="text-center text-red-500 mt-4">No record found</div>
            )}
          </div>
        </div>
      </div>

      <div className={mainTable.tableMobile} role="table">
        {/* <div className={`${tableView ? "hidden" : mainTable.tableMobile}`} role="table"> */}
        <div
          className={`w-full ${
            tableView ? "" : "overflow-y-auto"
          } lg:h-96 h-full`}
        >
          {currentData.length > 0 &&
            currentData.map((data, rowIndex) => (
              <div scope="col" class={mainTable.mobileTable}>
                {headerData.map((keyData) => (
                  <div scope="cell" class={mainTable.tableData} role="cell">
                    {keyData.key === "address" ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div className="flex basis-1/2">
                          <button
                            onClick={() =>
                              onAddressGrid(
                                data.oldIndex,
                                // startIndex +
                                //   rowIndex +
                                //   (currentPage - 1) * itemsPerPage,
                                1
                              )
                            }
                            class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          >
                            <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                            <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                              View
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : keyData.key === "action1" ? (
                      <div class="flex flex-col gap-2">
                        <button
                          onClick={() =>
                            navigate("/services", {
                              state: {
                                ...data,
                                isAdd: true,
                                isData: currentData[rowIndex],
                                isvisible3: false,
                                menuId: 28,
                              },
                            })
                          }
                          // class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          {/* <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span>
                          <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease"> */}
                          <span>Provide Services</span>
                        </button>
                        <div className="flex gap-1 justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                          {editBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() => {
                                const SEODate = new Date(
                                  data.ServicesDate || data.ClientRegisterDate
                                ); // Create Date object directly from YYYY-MM-DD
                                const todaydate = new Date();
                                // Calculate the difference in days
                                const timeDiff = todaydate - SEODate;
                                const dayDiff =
                                  timeDiff / (1000 * 60 * 60 * 24);
                                if (dayDiff > NODtoEditPastEntries) {
                                  showPopUp(false);
                                  toast.error(
                                    `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                    {
                                      position: "top-center",
                                      autoClose: 3000, // Duration in ms
                                    }
                                  );
                                } else {
                                  onEditGrid(
                                    data.oldIndex,
                                    // startIndex +
                                    //   rowIndex +
                                    //   (currentPage - 1) * itemsPerPage,
                                    2
                                  );
                                  showPopUp(false);
                                }
                              }}
                              //   onEditGrid(
                              //     data.oldIndex,
                              //     // startIndex +
                              //     //   rowIndex +
                              //     //   (currentPage - 1) * itemsPerPage,
                              //     2
                              //   )
                              // }
                            >
                              Edit
                            </button>
                          )}
                          {deleteBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onDeleteGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  3
                                )
                              }
                            >
                              Delete
                            </button>
                          )}
                          {blockBag === 1 && (
                            <div className="w-full">
                              {data.BlockedBy ? (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      5
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      4
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : keyData.key === "action" ? (
                      <div class={mainTable.tableData}>
                        <div className="md:block hidden">
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="flex gap-1 justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                          {editBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() => {
                                const SEODate = new Date(
                                  data.SEODate ||
                                    data.SPDateTime ||
                                    data.IndentDate ||
                                    data.OpeningDate
                                ); // Create Date object directly from YYYY-MM-DD
                                const todaydate = new Date();
                                // Calculate the difference in days
                                const timeDiff = todaydate - SEODate;
                                const dayDiff =
                                  timeDiff / (1000 * 60 * 60 * 24);
                                if (dayDiff > NODtoEditPastEntries) {
                                  showPopUp(false);
                                  toast.error(
                                    `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                    {
                                      position: "top-center",
                                      autoClose: 3000, // Duration in ms
                                    }
                                  );
                                } else {
                                  onEditGrid(
                                    data.oldIndex,
                                    // startIndex +
                                    //   rowIndex +
                                    //   (currentPage - 1) * itemsPerPage,
                                    2
                                  );
                                  showPopUp(false);
                                }
                              }}
                              // onEditGrid(
                              //   data.oldIndex,
                              //   // startIndex +
                              //   //   rowIndex +
                              //   //   (currentPage - 1) * itemsPerPage,
                              //   2
                              // )
                              // }
                            >
                              Edit
                            </button>
                          )}
                          {deleteBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onDeleteGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  3
                                )
                              }
                            >
                              Delete
                            </button>
                          )}
                          {blockBag === 1 && (
                            <div className="w-full">
                              {data.BlockedBy ? (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      5
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      4
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : keyData.key === "receiptAction" ? (
                      <div className="flex flex-col gap-1">
                        <button
                          onClick={() => onSchoolView(rowIndex)}
                          class="relative p-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                        >
                          {/* <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span> */}
                          {/* <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease"> */}
                          <span>Preview</span>
                        </button>
                        <div className="flex gap-1 justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                          {editBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() => {
                                const SEODate = new Date(
                                  data.ReceiptDate || data.ConsumptionDate
                                ); // Create Date object directly from YYYY-MM-DD
                                const todaydate = new Date();
                                // Calculate the difference in days
                                const timeDiff = todaydate - SEODate;
                                const dayDiff =
                                  timeDiff / (1000 * 60 * 60 * 24);
                                if (dayDiff > NODtoEditPastEntries) {
                                  showPopUp(false);
                                  toast.error(
                                    `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                    {
                                      position: "top-center",
                                      autoClose: 3000, // Duration in ms
                                    }
                                  );
                                } else {
                                  onEditGrid(
                                    data.oldIndex,
                                    // startIndex +
                                    //   rowIndex +
                                    //   (currentPage - 1) * itemsPerPage,
                                    2
                                  );
                                  showPopUp(false);
                                }
                              }}
                              //   onEditGrid(
                              //     data.oldIndex,
                              //     // startIndex +
                              //     //   rowIndex +
                              //     //   (currentPage - 1) * itemsPerPage,
                              //     2
                              //   )
                              // }
                            >
                              Edit
                            </button>
                          )}
                          {deleteBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onDeleteGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  3
                                )
                              }
                            >
                              Delete
                            </button>
                          )}
                          {blockBag === 1 && (
                            <div className="w-full">
                              {data.BlockedBy ? (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      5
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      4
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : keyData.key === "schoolAction" ? (
                      <div className="flex flex-col gap-1">
                        <div className="flex gap-1 justify-center">
                          <button
                            onClick={() => onSchoolView(rowIndex)}
                            class="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                          >
                            <span>Preview</span>
                          </button>
                          <button
                            onClick={() => onAdd(rowIndex)}
                            class="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                          >
                            <span>Add</span>
                          </button>
                        </div>
                        <div className="flex gap-1 justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                          {editBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() => {
                                const SEODate = new Date(data.Date); // Create Date object directly from YYYY-MM-DD
                                const todaydate = new Date();
                                // Calculate the difference in days
                                const timeDiff = todaydate - SEODate;
                                const dayDiff =
                                  timeDiff / (1000 * 60 * 60 * 24);
                                if (dayDiff > NODtoEditPastEntries) {
                                  showPopUp(false);
                                  toast.error(
                                    `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                    {
                                      position: "top-center",
                                      autoClose: 3000, // Duration in ms
                                    }
                                  );
                                } else {
                                  onEditGrid(
                                    data.oldIndex,
                                    // startIndex +
                                    //   rowIndex +
                                    //   (currentPage - 1) * itemsPerPage,
                                    2
                                  );
                                  showPopUp(false);
                                }
                              }}
                              //   onEditGrid(
                              //     data.oldIndex,
                              //     // startIndex +
                              //     //   rowIndex +
                              //     //   (currentPage - 1) * itemsPerPage,
                              //     2
                              //   )
                              // }
                            >
                              Edit
                            </button>
                          )}
                          {/* {deleteBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onDeleteGrid(
                                data.oldIndex,
                                  startIndex +
                                    rowIndex +
                                    (currentPage - 1) * itemsPerPage,
                                  3
                                )
                              }
                            >
                              Delete
                            </button>
                          )}
                          {blockBag === 1 && (
                            <div className="w-full">
                              {data.BlockedBy ? (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                    data.oldIndex,
                                      startIndex +
                                        rowIndex +
                                        (currentPage - 1) * itemsPerPage,
                                      5
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                    data.oldIndex,
                                      startIndex +
                                        rowIndex +
                                        (currentPage - 1) * itemsPerPage,
                                      4
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          )} */}
                        </div>
                      </div>
                    ) : keyData.key === "appointmentsAction" ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <button
                          type="button"
                          className={mainTable.appointmentsButton}
                          onClick={() => {
                            window.open(data.MeetingLink, "_blank");
                            console.log("data[keyData.key]", data.MeetingLink);
                          }}
                        >
                          Consult
                        </button>
                      </div>
                    ) : keyData.key === "DoctorConsultationAction" ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <button
                          type="button"
                          className={mainTable.appointmentsButton}
                          onClick={() =>
                            navigate("/services", {
                              state: {
                                ...data,
                                isAdd: true,
                                isvisible3: false,
                                isData: currentData[rowIndex],
                                menuId: 28,
                              },
                            })
                          }
                        >
                          Services
                        </button>
                      </div>
                    ) : keyData.key === "IssueAction" ? (
                      <div className="flex flex-col gap-1">
                        <div className="flex flex-row gap-1">
                          <button
                            onClick={() => onSchoolView(rowIndex)}
                            class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                          >
                            {/* <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span>
                          <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease"> */}
                            <span>View Issue Details</span>
                          </button>
                          {/* <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                            type="button"
                            onClick={() => handlePrint()}
                          >
                            Print Issue Details
                          </button> */}
                        </div>
                        <div className="md:hidden block flex gap-1 justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  // rowIndex +
                                  // (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                          {editBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() => {
                                const SEODateParts = data.IssueDate.split("-"); // Assuming format is DD-MM-YYYY
                                const todaydate = new Date(); // Today’s date

                                // Convert SEODate to a proper Date object
                                const SEODate = new Date(
                                  SEODateParts[2], // Year
                                  SEODateParts[1] - 1, // Month (0-based index)
                                  SEODateParts[0] // Day
                                );
                                // const SEODate = new Date(data.IssueDate); // Create Date object directly from YYYY-MM-DD
                                // const todaydate = new Date();
                                // Calculate the difference in days
                                const timeDiff = todaydate - SEODate;
                                const dayDiff =
                                  timeDiff / (1000 * 60 * 60 * 24);
                                if (dayDiff > NODtoEditPastEntries) {
                                  showPopUp(false);
                                  toast.error(
                                    `You can only edit entries within the past ${NODtoEditPastEntries} days.`,
                                    {
                                      position: "top-center",
                                      autoClose: 3000, // Duration in ms
                                    }
                                  );
                                } else {
                                  onEditGrid(
                                    data.oldIndex,
                                    // startIndex +
                                    //   rowIndex +
                                    //   (currentPage - 1) * itemsPerPage,
                                    2
                                  );
                                  showPopUp(false);
                                }
                              }}
                              //   onEditGrid(
                              //     data.oldIndex,
                              //     // startIndex +
                              //     //   rowIndex +
                              //     //   (currentPage - 1) * itemsPerPage,
                              //     2
                              //   )
                              // }
                            >
                              Edit
                            </button>
                          )}
                          {deleteBag === 1 && (
                            <button
                              className="bg-[#2d2d6e] w-full text-white p-1 rounded-lg"
                              onClick={() =>
                                onDeleteGrid(
                                  data.oldIndex,
                                  // startIndex +
                                  //   rowIndex +
                                  //   (currentPage - 1) * itemsPerPage,
                                  3
                                )
                              }
                            >
                              Delete
                            </button>
                          )}
                          {blockBag === 1 && (
                            <div className="w-full">
                              {data.BlockedBy ? (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      5
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Unblock
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    onBlockGrid(
                                      data.oldIndex,
                                      // startIndex +
                                      //   rowIndex +
                                      //   (currentPage - 1) * itemsPerPage,
                                      4
                                    );
                                    showPopUp(false);
                                  }}
                                  className="bg-[#2d2d6e] w-full text-white p-2 rounded-lg"
                                >
                                  Block
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : keyData.key === "ServicesClientAction" ? (
                      <div>
                        <button
                          onClick={() =>
                            navigate("/services", {
                              state: {
                                ...data,
                                isAdd: true,
                                isData: currentData[rowIndex],
                                isvisible3: false,
                                menuId: 28,
                              },
                            })
                          }
                          // class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          {/* <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 bg-[#2d2d6e] group-hover:w-full ease"></span>
                          <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-[#2d2d6e] group-hover:h-full ease"></span>
                          <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-[#2d2d6e] opacity-0 group-hover:opacity-100"></span>
                          <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease"> */}
                          <span>Provide Services</span>
                        </button>
                        <button
                          onClick={() => {
                            onHistoryGrid(
                              data.oldIndex,

                              2
                            );
                          }}
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          <span>History</span>
                        </button>
                      </div>
                    ) : keyData.key === "ReferralTrackingSave" ? (
                      <div>
                        <button
                          onClick={() => NotificationSavedisable(rowIndex)}
                          disabled={submitBtnDisabled}
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          <span>Save</span>
                        </button>
                      </div>
                    ) : keyData.key === "healthcareImage" ||
                      keyData.key === "userImage" ||
                      keyData.key === "Photos" ||
                      keyData.key === "ChooseFile" ||
                      keyData.key === "ResourcesPhoto" || // Will be used in functionality
                      keyData.key === "HealthCareProviderPhoto" ||
                      keyData.key === "SupplierLogo" ||
                      keyData.key === "UserProfileImg" ? (
                      <div className="flex justify-center ">
                        <input
                          type="image"
                          src={data[keyData.key]}
                          alt="Image"
                          className="rounded-full  w-20 md:w-12 md:h-12"
                        />
                      </div>
                    ) : data[keyData.key1] === 1 ? (
                      <div className="flex justify-center ">
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={data[keyData.key]}
                          alt="Image"
                          className="rounded-full w-12 h-12  "
                          width={keyData.width}
                        />
                      </div>
                    ) : data[keyData.key1] === 2 &&
                      data[keyData.key]?.startsWith(
                        "https://www.youtube.com/embed/"
                      ) ? (
                      <div className="flex justify-center ">
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={youtube}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      </div>
                    ) : data[keyData.key1] === 3 &&
                      data[keyData.key]?.endsWith(".pdf") ? (
                      <div className="flex justify-center ">
                        <img
                          onClick={() => {
                            setViewImage(() => {
                              return { popup: true, url: data[keyData.key] };
                            });
                          }}
                          type="image"
                          src={pdf}
                          alt="Image"
                          className="rounded-full w-12 h-12"
                          width={keyData.width}
                        />
                      </div>
                    ) : keyData.title === "S.No." ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      </div>
                    ) : keyData.key === "IssueQuantity" ? (
                      <div className="grid grid-cols-2">
                        <div className="font-bold">{keyData.title}</div>
                        <input
                          type="text"
                          className={mainTable.input}
                          value={quantities[rowIndex]}
                          onChange={(e) =>
                            handleQuantityChange(rowIndex, e.target.value)
                          }
                          // onBlur={(event) => handleBlur(rowIndex, event)}
                          onBlur={() => handleQuantityBlur(rowIndex)}
                        />
                        {errorMessages[rowIndex] && (
                          <span class="text-red-500">{errorMessages}</span>
                        )}
                      </div>
                    ) : keyData.key === "Balance" ? (
                      <div className="grid grid-cols-2">
                        <div className=" font-bold ">{keyData.title}</div>
                        <input
                          type="text"
                          className={mainTable.input}
                          value={availableBalances[rowIndex]}
                          readOnly
                        />
                      </div>
                    ) : keyData.key === "test" ? (
                      <div>
                        <label className={mainTable.label}>Test Result</label>
                        {(tableData[rowIndex].TestResultType == "1" ||
                          tableData[rowIndex].TestResultType == "2" ||
                          tableData[rowIndex].TestResultType == "3") && (
                          <input
                            type="text"
                            className={mainTable.input}
                            value={testResult[rowIndex]}
                            disabled={
                              tableData[rowIndex].TestResultType === "6" ||
                              onDisabled
                            }
                            placeholder={
                              tableData[rowIndex].TestResultType == "1"
                                ? "1-10"
                                : tableData[rowIndex].TestResultType == "2"
                                ? "00.00"
                                : tableData[rowIndex].TestResultType == "3"
                                ? "Text"
                                : ""
                            }
                            maxLength={50}
                            onChange={(e) => {
                              const inputText = e.target.value;

                              if (tableData[rowIndex].TestResultType == "1") {
                                if (/^[0-9-]*$/.test(inputText)) {
                                  const updatedTestResult = [...testResult];
                                  updatedTestResult[rowIndex] = inputText;
                                  setTestResult(updatedTestResult);
                                  const currentItem = tableData[rowIndex];
                                  currentItem.TestResult = inputText;
                                }
                              } else if (
                                tableData[rowIndex].TestResultType == "2"
                              ) {
                                if (/^[0-9.]*$/.test(inputText)) {
                                  const updatedTestResult = [...testResult];
                                  updatedTestResult[rowIndex] = inputText;
                                  setTestResult(updatedTestResult);
                                  const currentItem = tableData[rowIndex];
                                  currentItem.TestResult = inputText;
                                }
                              } else if (
                                tableData[rowIndex].TestResultType == "3"
                              ) {
                                if (/^[A-Za-z0-9.-]*$/.test(inputText)) {
                                  const updatedTestResult = [...testResult];
                                  updatedTestResult[rowIndex] = inputText;
                                  setTestResult(updatedTestResult);
                                  const currentItem = tableData[rowIndex];
                                  currentItem.TestResult = inputText;
                                }
                              } else {
                                if (/^[A-Za-z0-9.-]*$/.test(inputText)) {
                                  const updatedTestResult = [...testResult];
                                  updatedTestResult[rowIndex] = inputText;
                                  setTestResult(updatedTestResult);
                                  const currentItem = tableData[rowIndex];
                                  currentItem.TestResult = inputText;
                                }
                              }
                            }}
                            onKeyPress={(e) => {
                              if (tableData[rowIndex].TestResultType == "1") {
                                if (!/^[0-9-]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              } else if (
                                tableData[rowIndex].TestResultType == "2"
                              ) {
                                if (!/^[0-9.]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              } else if (
                                tableData[rowIndex].TestResultType == "3"
                              ) {
                                if (!/^[A-Za-z0-9.-]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              } else {
                                if (!/^[A-Za-z0-9.-]*$/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }
                            }}
                          />
                        )}
                        {tableData[rowIndex].TestResultType == "4" && (
                          <RadioGroup
                            key={rowIndex}
                            setName={`testResultType1-${rowIndex}`}
                            selectedOptions={testResult[rowIndex]}
                            classDiv="w-full"
                            options={onTestResultTypeDD}
                            controlFunc={(value) =>
                              handleRadioChange1(value, rowIndex)
                            }
                            disabled={onDisabled}
                            Class1="grid md:grid lg:grid-cols-2 items-center bg-gray-50 border border-gray-300 rounded-lg"
                          />
                        )}
                      </div>
                    ) : keyData.key === "qty" ? (
                      <div>
                        <label className={mainTable.label}>Item</label>
                        <input
                          type="text"
                          className={mainTable.input}
                          title={
                            currentStock[rowIndex]
                              ? `Available stock: ${
                                  currentStock[rowIndex] || 0
                                }`
                              : ""
                          }
                          value={qty[rowIndex]}
                          disabled={
                            tableData[rowIndex].ItemsProvided === "2" ||
                            onDisabled
                          }
                          maxLength={5}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (
                              /^\d*\.?\d*$/.test(inputText) &&
                              inputText.length <= 5 &&
                              (inputText === "" ||
                                parseFloat(inputText) <=
                                  (currentStock[rowIndex] || 0))
                            ) {
                              const updatedqty = [...qty]; // Create a copy of the array
                              updatedqty[rowIndex] = inputText; // Update the specific element
                              setQTY(updatedqty); // Set the updated array in the state
                              const currentItem = tableData[rowIndex];
                              currentItem.Quantity = inputText;
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/[\d.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    ) : keyData.key === "uom" ? (
                      <div>
                        <label className={mainTable.label}>UOM</label>
                        <input
                          type="text"
                          className={mainTable.input}
                          value={tableData[rowIndex].ResultUOM}
                          maxLength={50}
                          disabled={true}
                          onChange={(e) => {
                            const inputText = e.target.value;

                            if (/^[A-Za-z]*$/.test(inputText)) {
                              const updatedUOM = [...uom];
                              updatedUOM[rowIndex] = inputText;
                              setUOM(updatedUOM);
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/^[A-Za-z]*$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    ) : keyData.key === "servicesCharge" ? (
                      <div className={mainTable.inputContainer}>
                        <label className={mainTable.label}>Charges</label>
                        <input
                          type="text"
                          className={mainTable.input}
                          value={charges[rowIndex]}
                          disabled={onDisabled}
                          maxLength={7}
                          onChange={(e) => {
                            if (
                              /^\d*\.?\d*$/.test(e.target.value) &&
                              e.target.value.length <= 7
                            ) {
                              const updatedCharges = [...charges];
                              updatedCharges[rowIndex] = e.target.value;
                              setCharges(updatedCharges);
                              const currentItem = tableData[rowIndex];
                              currentItem.Charges = e.target.value;
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/[\d.]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    ) : keyData.key === "FPUser" ? (
                      <div className="flex gap-1">
                        <RadioGroup
                          title="F.P. User"
                          key={rowIndex}
                          setName={`consultedDoctor1-${rowIndex}`}
                          selectedOptions={FPUser[rowIndex] || "1"}
                          classDiv="w-full"
                          options={onFPUserDD}
                          controlFunc={(value) =>
                            handleRadioChange(value, rowIndex)
                          }
                          disabled={onDisabled}
                          Class1="grid md:grid lg:grid-cols-2 items-center bg-gray-50 border border-gray-300 rounded-lg"
                        />
                      </div>
                    ) : keyData.key === "ContraceptiveNextFollowUpDate" ? (
                      <div className="">
                        <InputText
                          title="Next Follow-up"
                          key={rowIndex}
                          inputType="date"
                          content={contraceptiveNextFollowUpDate[rowIndex]}
                          controlFunc={(e) =>
                            contraceptiveNextFollowUpDateChange(
                              rowIndex,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    ) : keyData.key === "ReferralFollowUpRemarks" ? (
                      <div className="">
                        <DropDown
                          title="Remarks"
                          key={rowIndex}
                          type="text"
                          placeholder="Select"
                          options={bindReferralFollowUpRemarks}
                          selectedOption={referralFollowUpRemarks[rowIndex]}
                          controlFunc={(e) =>
                            handleReferralFollowUpRemarks(
                              rowIndex,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    ) : keyData.key === "ContraceptiveRemarks" ? (
                      <div className={mainTable.grid2}>
                        <DropDown
                          title="Remarks"
                          key={rowIndex}
                          type="text"
                          placeholder="Select"
                          options={bindContraceptiveRemarks}
                          selectedOption={contraceptiveRemarks[rowIndex]}
                          controlFunc={(e) =>
                            handleContraceptiveRemarks(rowIndex, e.target.value)
                          }
                        />
                        {remarks1Visibility[rowIndex] && (
                          <DropDown
                            key={rowIndex}
                            type="text"
                            placeholder="Select"
                            options={bindDiscontinuedRemarks}
                            selectedOption={
                              contraceptiveRemarksDiscontinued[rowIndex]
                            }
                            controlFunc={(e) =>
                              handleContraceptiveRemarks1(
                                rowIndex,
                                e.target.value
                              )
                            }
                          />
                        )}
                      </div>
                    ) : keyData.key === "referral" ? (
                      <div className="">
                        <label className={mainTable.label}>Referred To</label>
                        <DropDown
                          key={rowIndex}
                          options={bindReferralDD}
                          disabled={onDisabled}
                          placeholder={"Select"}
                          name={"referral"}
                          selectedOption={referral[rowIndex]}
                          controlFunc={(e) =>
                            handleReferralChange(rowIndex, e.target.value)
                          }
                        />
                      </div>
                    ) : keyData.key === "brand" ? (
                      <div className="">
                        <label className={mainTable.label}>Brand</label>
                        {/* <DropDown
                          key={rowIndex}
                          options={bindReferralDD}
                          placeholder={"Select"}
                          name={"referral"}
                          disabled={onDisabled}
                          selectedOption={
                            referral[rowIndex] ? referral[rowIndex] : ""
                          }
                          controlFunc={(e) =>
                            handleReferralChange(rowIndex, e.target.value)
                          }
                        /> */}
                        <AutoDropDown
                          title=""
                          options={FPItemProvidedIdOptions[rowIndex] ?? []}
                          name="mstBrand"
                          placeholder="Select"
                          disabled={onDisabled}
                          selectedOption={
                            FPItemProvidedIdOptions[rowIndex]?.find(
                              (x) => x.value == MstBrandId[rowIndex]
                            ) || null
                          }
                          Class="custom-class"
                          controlFunc={(value) => {
                            handleBrandChange(rowIndex, value.value);
                          }}
                        />
                      </div>
                    ) : keyData.key === "pacservices" ? (
                      <div className="">
                        <label className={mainTable.label}>PAC Services</label>
                        <DropDown
                          key={rowIndex}
                          options={bindPACServicesDD}
                          placeholder={"Select"}
                          name={"pacServices"}
                          disabled={onDisabled}
                          selectedOption={
                            pacServices[rowIndex] ? pacServices[rowIndex] : ""
                          }
                          controlFunc={(e) =>
                            handlePacServicesChange(rowIndex, e.target.value)
                          }
                        />
                      </div>
                    ) : keyData.key === "ServicesAction" ? (
                      <div>
                        <button
                          onClick={() => onDeleteGrid(rowIndex)}
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          <span>Delete</span>
                        </button>
                      </div>
                    ) : keyData.key === "detailsAction" ? (
                      <div class="flex">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <input
                          type="button"
                          className={mainTable.submitButton}
                          onClick={() => onServicesGrid(rowIndex)}
                          value="Show Details"
                        />
                      </div>
                    ) : // keyData.key === "Quantity" ? (
                    //   <div>
                    //     <InputText type="text" content={quantity} readOnly />
                    //   </div>
                    // ) :
                    keyData.key === "NotificationAction" ? (
                      <div className="">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <DropDown
                          key={rowIndex}
                          type="text"
                          placeholder="Select"
                          options={[]}
                          selectedOption={action[rowIndex]}
                          controlFunc={(e) =>
                            handleActionChange(rowIndex, e.target.value)
                          }
                        />
                      </div>
                    ) : //  : keyData.key === "NotificationFallowUpBy" ? (
                    //   <div className="">
                    //     <InputText
                    //       inputType="text"
                    //       content={followUpBy[rowIndex]}
                    //       controlFunc={(e) => {
                    //         const updatedFollowUpBy = [...followUpBy];
                    //         updatedFollowUpBy[rowIndex] = e.target.value;
                    //         setFollowUpBy(updatedFollowUpBy);
                    //         handleNotificationBlur(rowIndex, e.target.value);
                    //       }}
                    //     />
                    //   </div>
                    // )
                    keyData.key === "NotificationQuantity" ? (
                      <div className="">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <InputText
                          inputType="text"
                          maxLength={5}
                          content={notificationQuantity[rowIndex]}
                          controlFunc={(e) => {
                            const updatedQty = [...notificationQuantity];
                            updatedQty[rowIndex] = e.target.value;
                            setNotificationQuantity(updatedQty);
                            handleNotificationBlur(rowIndex, e.target.value);
                          }}
                        />
                      </div>
                    ) : keyData.key === "NotificationRemarks" ? (
                      <div className="">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <TextArea
                          row={1}
                          maxLength={500}
                          content={remarks[rowIndex]}
                          controlFunc={(e) => {
                            const updatedRemarks = [...remarks];
                            updatedRemarks[rowIndex] = e.target.value;
                            setRemarks(updatedRemarks);
                            handleNotificationBlur(rowIndex, e.target.value);
                          }}
                        />
                      </div>
                    ) : keyData.key === "NotificationDate" ? (
                      <div className="">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <InputText
                          key={rowIndex}
                          inputType="date"
                          content={nextFollowUpDate[rowIndex]}
                          controlFunc={(e) =>
                            handleNextFollowUpDateChange(
                              rowIndex,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    ) : keyData.key === "DateFollowedUp" ? (
                      <div className="">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <InputText
                          key={rowIndex}
                          inputType="date"
                          content={dateFollowedUp[rowIndex]}
                          controlFunc={(e) =>
                            handleDateFollowedUpChange(rowIndex, e.target.value)
                          }
                        />
                      </div>
                    ) : keyData.key === "ServiceReceived" ? (
                      <div className="flex gap-1">
                        <RadioGroup
                          title="Service received"
                          key={rowIndex}
                          setName={`consultedDoctor1-${rowIndex}`}
                          selectedOptions={serviceReceived[rowIndex]}
                          classDiv="w-full"
                          options={[
                            { value: 1, label: "Yes" },
                            { value: 2, label: "No" },
                          ]}
                          controlFunc={(value) =>
                            handleRadioChangeServiceReceived(value, rowIndex)
                          }
                          disabled={false}
                          Class1="grid-cols flex justify-center md:grid lg:grid-cols-2 items-center bg-gray-50 border border-gray-300 rounded-lg"
                        />
                      </div>
                    ) : keyData.key === "issueAction" ? (
                      <div className="md:hidden flex justify-center">
                        <input
                          type="button"
                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                          onClick={() => onEditGrid(rowIndex)}
                          value="Add"
                        />
                        <input
                          type="button"
                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                          onClick={() => onDeleteGrid(rowIndex)}
                          value="Delete"
                        />
                      </div>
                    ) : keyData.key === "issueBrand" ? (
                      <div className="">
                        <AutoDropDown
                          // ClassLabel="block text-sm capitalize font-medium text-neutral-700"
                          title="Brand"
                          options={brandOptions[rowIndex] ?? []}
                          name="mstBrand"
                          placeholder="Select"
                          disabled={onDisabled}
                          selectedOption={
                            brandOptions[rowIndex]?.find(
                              (x) => x.value == issueBrandId[rowIndex]
                            ) || null
                          }
                          Class="custom-class"
                          controlFunc={(value) => {
                            handleIssueBrandChange(rowIndex, value.value);
                          }}
                        />
                      </div>
                    ) : keyData.key === "BatchNo" ? (
                      <div className="">
                        <InputText
                          // classLabel="block text-sm capitalize font-medium text-neutral-700"
                          type="text"
                          title="Batch"
                          placeholder=""
                          content={batchNo[rowIndex]}
                          maxLength={30}
                          controlFunc={(e) => {
                            if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                              const updatedBatchNo = [...batchNo];
                              updatedBatchNo[rowIndex] = e.target.value;
                              setBatchNo(updatedBatchNo);
                              const currentItem = tableData[rowIndex];
                              currentItem.BatchNo = e.target.value;
                            }
                          }}
                          controlFuncBlur={(e) => {
                            handleIssueBatchNoChange(rowIndex, e.target.value);
                          }}
                        />
                        {errorMessages[rowIndex] && (
                          <span class="text-red-500">
                            {errorMessages[rowIndex]}
                          </span>
                        )}
                      </div>
                    ) : keyData.key === "ExpiryDate" ? (
                      <div className="">
                        <InputText
                          // classLabel="block text-sm capitalize font-medium text-neutral-700"
                          inputType="date"
                          title="Exp.Dt"
                          content={dateOfExpiry[rowIndex]}
                          // maxLength={5}
                          controlFunc={(e) => {
                            const updatedDateOfExpiry = [...dateOfExpiry];
                            updatedDateOfExpiry[rowIndex] = e.target.value;
                            setDateOfExpiry(updatedDateOfExpiry);
                            const currentItem = tableData[rowIndex];
                            currentItem.DateOfExpiry = e.target.value;
                          }}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                    ) : keyData.key === "Available" ? (
                      <div className="grid grid-cols-2">
                        <div className="font-bold">{keyData.title}</div>
                        <label>
                          {availableStock[rowIndex]
                            ? availableStock[rowIndex]
                            : ""}
                        </label>
                      </div>
                    ) : keyData.key === "NotificationSave" ? (
                      <div>
                        <button
                          disabled={submitBtnDisabled}
                          onClick={() => NotificationSavedisable(rowIndex)}
                          class="bg-[#2d2d6e] text-white  w-full mt-3 text-center p-2 rounded-lg"
                        >
                          <span>Save</span>
                        </button>
                      </div>
                    ) : keyData.key === "Phone" && data.ClientPhoneNo == "" ? (
                      <div className="flex basis-1/2">{"-"}</div>
                    ) : keyData.key === "PendingIndentAction" ? (
                      <div className="flex justify-center">
                        <Link
                          to={"/inventoryissue"}
                          state={{
                            ...data,
                            isAdd: true,
                            isvisible3: false,
                            isData: tableData[rowIndex],
                            menuId: 41,
                          }}
                          // onClick={() => onServiceProvideGrid(rowIndex)}
                          className={mainTable.appointmentsButton}
                        >
                          Issue
                        </Link>
                      </div>
                    ) : data.BlockedBy ? (
                      <div className={keyData.width1}>
                        <div className="flex border p-1 ">
                          <div className="flex basis-1/2 font-bold ">
                            {keyData.title}
                          </div>
                          <div className="flex basis-1/2 truncate relative text-red-600">
                            {data[keyData.key]}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div className="flex basis-1/2">
                          {data[keyData.key]}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <MainPopup
        popUpOpen={viewImage.popup}
        setpopUpOpen={() => setViewImage({ popup: false, url: "" })}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setViewImage({ popup: false, url: "" })}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div className="flex justify-center p-12 items-center">
            {viewImage?.url ? (
              viewImage.url.startsWith("https://www.youtube.com/embed/") ? (
                <iframe
                  title="Video"
                  src={viewImage.url}
                  allowFullScreen
                  className="h-96 w-96"
                ></iframe>
              ) : viewImage.url.endsWith(".pdf") ? (
                <a
                  href={viewImage.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={pdf} // You can replace this with a custom PDF icon
                    alt="PDF"
                    className="w-20 h-20"
                  />
                  <p>View PDF</p>
                </a>
              ) : (
                <img src={viewImage.url} className="h-96 w-96" />
              )
            ) : (
              <p>No image or video available</p>
            )}
          </div>
        </div>
      </MainPopup>
    </div>
  );
};

export default TableGrid;
