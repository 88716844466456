// Code review - In Progress
// Documentation - In Progress
import axios from "axios";
var token = localStorage.getItem("jwtToken");
console.log("Token:", token);

const IUDAPI = async (payload, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,
    payload,
    { headers }
  );
  return response;
};

const BindAPI = async (apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.get(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,    
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,

    { headers }
  );
  return response;
};

const BindLocalAPI = async (apiCall) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.get(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`, 
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,   
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    //  `http://localhost:5154/${apiCall}`,
    { headers }
  );
  return response;
};

const GetLocalAPI = async (payload, apiCall) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`, 
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,   
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/FPAIOutReachAPI/${apiCall}`,
    payload,

    { headers }
  );
  return response;
};

const BindPostAPI = async (Binddata, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const response = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,   
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,
    Binddata,
    { headers }
  );
  return response;
};

const ImageAPI = async (imagePayload, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  const Imageresponse = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,
  //  `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,
    imagePayload,
    { headers }
  );

  return Imageresponse;
};

const LanguageTranslatorAPI = async (text, targetLangKey) => {
  const response = await axios.post(
    "https://translation.googleapis.com/language/translate/v2",
    {},
    {
      params: {
        q: text,
        target: targetLangKey,
        // key: "AIzaSyALNc7fCM4YKalUq0pYhbDW9D7dzDbLVDE",
        key: "AIzaSyBdriIoRfP0cFJI4M6ESIw4GyN82gCRqZQ",
      },
    }
  );
  return response;
};

const authenticationAPI = async (payload, apiCall) => {
  const response = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,    
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`,
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,

    payload
  );
  return response;
};

const parameterPostAPI = async (apiCall) => {
  const response = await axios.post(
    // `http://fpai-outreach-uat.xlicon.com/fpai-outreach-uat-api/${apiCall}`,  
    // `http://fpai-outreach-test.xlicon.com/fpai-outreach-test-api/${apiCall}`, 
    // `http://fpai-outreach-dev.xlicon.com/fpai-outreach-dev-api/${apiCall}`,
    `https://fpai-outreach.org/fpai-outreach-api/${apiCall}`,
    // `http://localhost:5154/${apiCall}`,
  );
  return response;
};

export {
  IUDAPI,
  BindAPI,
  BindLocalAPI,
  GetLocalAPI,
  ImageAPI,
  LanguageTranslatorAPI,
  BindPostAPI,
  authenticationAPI,
  parameterPostAPI,
};
