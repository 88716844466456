// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import ServicesSubCategory from "../popup-containers/ServicesSubCategory";
import { servicesSubCategoryHeaders } from "../components/HeaderAttribute";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const ServicesSubCategoryMaster = () => {
  const location = useLocation();
  //All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // Services Sub-Category Master insert and edit useState hooks handling .
  const [mstServiceSubCategoryId, setMstServiceSubCategoryId] = useState("");

  const [servicesFields, setServicesFields] = useState({
    category: "",
    subCategory: "",
  });

  const [servicesFieldsError, setServicesFieldsError] = useState({
    categoryError: "",
    subCategoryError: "",
  });

  // Bind dropdown value and bind data value useState hooks handling.
  const [bindServicesSubCategory, setBindServicesSubCategory] = useState([]);
  const [bindCategoryValues, setBindCategoryValues] = useState([]);
  const [serviceSubCategoryData, setServiceSubCategoryData] = useState("");
  const [popUpData, setPopUpData] = useState({});
  const [editMode, setEditMode] = useState([]);

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [rowIndex, setRowIndex] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [popUpOpen, setpopUpOpen] = useState(false);

  // Language translate useState hooks handling.
  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  // This function is helping for Language translation .
  useEffect(() => {
    const getLanguageCode = async () => {
      const response = await BindAPI("LanguageAPI/GetLanguage");
      setLanguageCodekey(response.data.Table);
    };
    getLanguageCode();
  }, []);

  useEffect(() => {
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // This function is helped to the viwe, delete, block and unblock
  // input value via rowindex passing for Services Sub-Category .
  const handleServicesSubCategory = async (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    //Sub-Category DropDown Value Start
    const categoryRowData = bindServicesSubCategory[rowIndex];
    const categoryInfo = bindCategoryValues.find(
      (L) => L.value === categoryRowData.MstServicesCategoryId
    );
    const ResultCategory = {
      value: categoryRowData.MstServicesCategoryId,
      label: categoryInfo ? categoryInfo.label : "DefaultLocationLabel",
    };
    //setCategory(ResultCategory);
    setPopUpData(ResultCategory);
    //Sub-Category DropDown Value Start

    setServiceSubCategoryData(bindServicesSubCategory[rowIndex]);
    setMstServiceSubCategoryId(
      bindServicesSubCategory[rowIndex].MstServicesSubCategoryId
    );
  };

  // This is Services  Sub-Category grid page add button visible handling functionality .
  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = async () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    servicesCategoryData();
    setSelectedRowIndex(null);
    setEditMode(null);
    setSubmitBtnDisabled(false);
    // setUserFields({});
    setServicesFields({
      firstName: "",
      middleName: "",
    });

    //clear Error
    setServicesFieldsError({
      firstNameError: "",
      lastNameError: "",
    });
  };

  // This function is helped to edit the input value via rowindex passing and
  // helping to visible handling for Services Sub-Category.
  const handleEdit = async (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    setServicesFields({
      ...servicesFields,
      category: bindServicesSubCategory[rowIndex].MstServicesCategoryId,
      subCategory: bindServicesSubCategory[rowIndex].ServicesSubCategory_en,
    });

    setMstServiceSubCategoryId(
      bindServicesSubCategory[rowIndex].MstServicesSubCategoryId
    );
  };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the location input
      if (servicesFields.category == "") {
        errors.category = "Select Category*";
      }

      // Validation logic for the userId input
      if (!servicesFields.subCategory?.trim()) {
        errors.subCategory = "Enter Sub-Category";
      }
    }

    setServicesFieldsError({
      ...servicesFieldsError,
      categoryError: errors.category || "",
      subCategoryError: errors.subCategory || "",
    });

    return errors; // Return the errors object containing validation errors (if any)
  };

  // response for those all and passing the rowindex for Services Sub-Category.
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    const firstNameValidation = validate(flag);

    if (Object.keys(firstNameValidation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(firstNameValidation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1 || flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            servicesFields.subCategory,
            i
          );
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const data = {
          mstServicesSubCategoryId: mstServiceSubCategoryId
            ? mstServiceSubCategoryId
            : 0,
          mstServicesCategoryId: servicesFields.category,
          servicesSubCategoryEn: servicesFields.subCategory,
          servicesSubCategoryTa: translatedTexts[0],
          servicesSubCategoryHi: translatedTexts[1],
          servicesSubCategoryMr: translatedTexts[2],
          servicesSubCategoryGu: translatedTexts[3],
          servicesSubCategoryKn: translatedTexts[4],
          servicesSubCategoryMl: translatedTexts[5],
          servicesSubCategoryTe: translatedTexts[6],
          servicesSubCategoryBn: translatedTexts[7],
          servicesSubCategoryOr: translatedTexts[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "ServicesSubCategoryAPI/IUDServicesSubCategory"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Services Sub-Category added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Services Sub-Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Services Sub-Category updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Services Sub-Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        const data = {
          mstServicesSubCategoryId:
            bindServicesSubCategory[items].MstServicesSubCategoryId,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "ServicesSubCategoryAPI/IUDServicesSubCategory"
        );
        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success(
              "Services Sub-Category data deleted successfully 🗑️",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to delete Services Sub-Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success(
              "Services Sub-Category data blocked successfully 🚫",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to block Services Sub-Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success(
              "Services Sub-Category data unblocked successfully ✅",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to unblock Services Sub-Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  // This hook function is helped to bind dropdown value and bind all data
  //from Services Sub-Category master table.
  useEffect(() => {
    servicesCategoryData();
  }, []);
  const servicesCategoryData = async () => {
    const response = await BindAPI(
      "ServicesSubCategoryAPI/BindServicesSubCategory"
    );
    setBindServicesSubCategory(response.data.Table);
  };

  //Sub-Category DropDown Value bind in master table.
  useEffect(() => {
    const bindServicesCategoryDrpData = async () => {
      const bindCategoryDataDrp = await BindAPI(
        "ServicesCategoryAPI/BindServicesCategory"
      );
      const bindCategory = bindCategoryDataDrp?.data?.Table?.filter(
        (x) => x.BlockedBy === null
      );

      // Check if bindCategoryDataDrp is an array before filtering
      const categoryDD = bindCategory.map((item) => ({
        value: item.MstServicesCategoryId,
        label: item.ServicesCategory_en,
      }));
      setBindCategoryValues(categoryDD);
    };
    bindServicesCategoryDrpData();
  }, []);

  const handleUserFieldsChange = (value, name) => {
    setServicesFields({
      ...servicesFields,
      [name]: value,
    });
  };
  useEffect(() => {
    if (location.pathname == "/servicessubcategory") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      servicesCategoryData();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Services Sub-Category Master"
          header={servicesSubCategoryHeaders}
          records={bindServicesSubCategory}
          handleEdit={handleEdit}
          handleAction={handleServicesSubCategory}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Services Sub-Category Master"
          />

          <div class={mainTable.firstMainAddScreen}>
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Category"
                        options={bindCategoryValues}
                        placeholder="Select"
                        name="category"
                        selectedOption={bindCategoryValues.find(
                          (x) => x.value == servicesFields.category
                        )}
                        controlFunc={(value, e) => {
                          setServicesFieldsError({
                            ...servicesFieldsError,
                            categoryError: value ? "" : "Select Category",
                          });
                          handleUserFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setServicesFieldsError({
                            ...servicesFieldsError,
                            servicesFieldsError: servicesFields.category
                              ? ""
                              : "Select Category",
                          });
                        }}
                        Class="custom-class"
                        tabIndex="1"
                        errorMessage={servicesFieldsError.categoryError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title={"Sub-Category"}
                        name={"subCategory"}
                        content={servicesFields.subCategory}
                        controlFunc={(e) =>
                          setServicesFields({
                            ...servicesFields,
                            subCategory: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setServicesFieldsError((servicesFieldsError) => ({
                            ...servicesFieldsError,
                            subCategoryError:
                              servicesFields.subCategory.length > 0
                                ? ""
                                : "Enter Sub-Category",
                          }));
                        }}
                        errorMessage={servicesFieldsError.subCategoryError}
                      />
                    </div>
                    <Buttons
                      addScreenTitle={addScreenTitle}
                      updateScreenTitle={updateScreenTitle}
                      handleSubmit={handleSubmit}
                      cancel={cancel}
                      disabledBtn={submitBtnDisabled ? true : false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleServicesSubCategory}
      >
        <div>
          <PopUpScreenTitle
            title="Services Sub-Category Master"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <ServicesSubCategory
            data={serviceSubCategoryData}
            data1={popUpData}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default ServicesSubCategoryMaster;
