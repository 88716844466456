import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { mainTable } from "../css/Common";
import { useUserCapability } from "./ConstantReducerProvider";
import { authenticationAPI } from "../services/APIConnection";

const SideMenu = ({ pageName }) => {
  const [sidebarVisibility, setSidebarVisibility] = useState(true);
  const { userProfileState, dispatch } = useUserCapability();
  const [submenuOpen, setSubmenuOpen] = useState({});
  const [subMenuPath, setSubMenuPath] = useState([]);
  const [currentMainMenu, setCurrentMainMenu] = useState("");
  const [currentSubmenu, setCurrentSubmenu] = useState("");
  const [toggleSubMenuIcon, setToggleSubMenuIcon] = useState(true);
  const [removeDashboardIcon, setRemoveDashboardIcon] = useState(false);

  const userVsCapability = userProfileState.userVsCapability;

  const sideBarMenu = {
    sideMenuDiv: ` hidden lg:block `,
    mobileSideMenuDiv: `z-10 2xl:z-0  ${
      sidebarVisibility ? "max-lg:block" : "max-lg:hidden"
    }`,
    sideMenuWidth: `relative left-0 z-10 ${
      sidebarVisibility ? "block max-lg:absolute max-lg:w-full" : "hidden"
    } lg:block w-full lg:w-fit  h-full transition-transform -translate-x-full translate-x-0  `,

    sideBarButton: `absolute z-10 inline-flex block items-center p-2 py-3 ml-3  text-sm text-gray-500 rounded-lg hover:bg-gray-100 block focus:outline-none focus:ring-2 focus:ring-gray-200 
    ${sidebarVisibility ? "hidden" : "block"} `,
    sideMenu: `flex items-center p-2 z-40 text-base font-normal text-black hover:bg-red-400 rounded-lg `,
    sideMenuLaptop: `flex items-center p-2 z-40 text-base font-normal text-black hover:bg-red-400 rounded-lg `,
    sideMenuName: `flex-1 ml-3 text-white whitespace-nowrap 
    ${sidebarVisibility ? "max-lg:block" : "max-lg:hidden"}`,
    sideMenuNameLaptop: `flex-1  ml-3 text-white whitespace-nowrap 
    ${sidebarVisibility ? "block" : "hidden"}`,

    overflow: "h-full px-3 py-4 overflow-y-auto bg-[#2d2d6e] ",
    toggleSubMenuIconCss: `${
      toggleSubMenuIcon ? "flex justify-end" : "text-gray-500 ml-2"
    }`,
  };
  // Set to true to open the Inventory submenu

  const [submenuIcon, setSubMenuIcon] = useState(false);

  // useEffect(() => {
  //   if (Array.isArray(userVsCapability) && userVsCapability.length > 0) {
  //     setUserCapabilitySideMenu(userVsCapability);
  //   }
  // }, []);

  const location = useLocation();

  const isActive = (to) => {
    // Check if the current location matches the 'to' prop or if the submenu is open.
    return location.pathname === to;
  };

  // const SideMenuVisibleLaptop = () => {
  //   let status = !sidebarVisibility;
  //   setSidebarVisibility(status);
  //   // setSubMenuVisibility(status);
  //   setOrganizationSubMenu(false);
  //   setSubMenuStates(false);
  //   setUserSubMenu(false);
  //   setServicesSubMenu(false);
  //   setCSESubMenu(false);
  //   setTransactionCseSubmenu(false);
  //   setAwarnessSubmenu(false);
  //   setInventorySubMenuOpen(false);
  //   setCommoditiesSubMenu(false);
  // };

  const filteredAdminMenuItems = userVsCapability.filter(
    (item) => item.MstMenuHeadingId === 2
  );

  const filteredTranscationMenuItems = userVsCapability.filter(
    (item) => item.MstMenuHeadingId === 3
  );

  const filteredReportsMenuItems = userVsCapability.filter(
    (item) => item.MstMenuHeadingId === 4
  );

  if (filteredAdminMenuItems.length > 0) {
    const menuDictionary = {};

    // Iterate through filteredMenuItems and organize them into the dictionary
    filteredAdminMenuItems.forEach((item) => {
      if (!menuDictionary[item.MenuName]) {
        menuDictionary[item.MenuName] = {
          title: item.MenuName,
          to: item.MainMenuId ? null : item.MenuPath,
          key: item.MenuName,
          menuId: item.MstMenuId,
          mainMenuId: item.MainMenuId ? item.MainMenuId : null,
          icon: (
            <svg
              viewBox="0 0 20 20"
              className="w-6 h-6"
              fill="currentColor"
              dangerouslySetInnerHTML={{ __html: item.MenuIcon }}
            />
          ),
          subMenu: [],
        };
      }

      // Add submenu items based on Main Menu ID
      const subMenuItems = userVsCapability
        .filter(
          (subItem) =>
            subItem.MenuName === item.MenuName &&
            subItem.MainMenuId &&
            subItem.MstMenuHeadingId == 2
        )
        .map((subItem) => ({
          title: subItem.SubMenu,
          to: subItem.MenuPath,
          menuId: subItem.MstMenuId,
          mainMenuId: subItem.MainMenuId,
        }));

      // Check if the submenu item already exists, and add it if not
      subMenuItems.forEach((subItem) => {
        const subMenuItemExists = menuDictionary[item.MenuName].subMenu.some(
          (existingSubMenu) => existingSubMenu.title === subItem.title
        );

        if (!subMenuItemExists) {
          menuDictionary[item.MenuName].subMenu.push(subItem);
        }
      });
    });

    // Convert the dictionary values to an array of items
    var administratorDynamicSideMenu = Object.values(menuDictionary);
  } else {
  }

  if (filteredTranscationMenuItems.length > 0) {
    const menuDictionary = {};

    // Iterate through filteredMenuItems and organize them into the dictionary
    filteredTranscationMenuItems.forEach((item) => {
      if (!menuDictionary[item.MenuName]) {
        menuDictionary[item.MenuName] = {
          title: item.MenuName,
          to: item.MainMenuId ? null : item.MenuPath,
          key: item.MenuName,
          menuId: item.MstMenuId,
          mainMenuId: item.MainMenuId ? item.MainMenuId : null,
          icon: (
            <svg
              viewBox="0 0 20 20"
              className="w-6 h-6"
              fill="currentColor"
              dangerouslySetInnerHTML={{ __html: item.MenuIcon }}
            />
          ),
          subMenu: [],
        };
      }

      // Add submenu items based on Main Menu ID
      const subMenuItems = userVsCapability
        .filter(
          (subItem) =>
            subItem.MenuName === item.MenuName &&
            subItem.MainMenuId &&
            subItem.MstMenuHeadingId == 3
        )
        .map((subItem) => ({
          title: subItem.SubMenu,
          to: subItem.MenuPath,
          menuId: subItem.MstMenuId,
          mainMenuId: subItem.MainMenuId,
        }));

      // Check if the submenu item already exists, and add it if not
      subMenuItems.forEach((subItem) => {
        const subMenuItemExists = menuDictionary[item.MenuName].subMenu.some(
          (existingSubMenu) => existingSubMenu.title === subItem.title
        );

        if (!subMenuItemExists) {
          menuDictionary[item.MenuName].subMenu.push(subItem);
        }
      });
    });

    // Convert the dictionary values to an array of items
    var transactionDynamicSideMenu = Object.values(menuDictionary);
  } else {
  }

  if (filteredReportsMenuItems.length > 0) {
    const menuDictionary = {};

    // Iterate through filteredMenuItems and organize them into the dictionary
    filteredReportsMenuItems.forEach((item) => {
      if (!menuDictionary[item.MenuName]) {
        menuDictionary[item.MenuName] = {
          title: item.MenuName,
          to: item.MainMenuId ? null : item.MenuPath,
          key: item.MenuName,
          menuId: item.MstMenuId,
          mainMenuId: item.MainMenuId ? item.MainMenuId : null,
          icon: (
            <svg
              viewBox="0 0 20 20"
              className="w-6 h-6"
              fill="currentColor"
              dangerouslySetInnerHTML={{ __html: item.MenuIcon }}
            />
          ),
          subMenu: [],
        };
      }

      // Add submenu items based on Main Menu ID
      const subMenuItems = userVsCapability
        .filter(
          (subItem) =>
            subItem.MenuName === item.MenuName &&
            subItem.MainMenuId &&
            subItem.MstMenuHeadingId == 4
        )
        .map((subItem) => ({
          title: subItem.SubMenu,
          to: subItem.MenuPath,
          menuId: subItem.MstMenuId,
          mainMenuId: subItem.MainMenuId,
        }));

      // Check if the submenu item already exists, and add it if not
      subMenuItems.forEach((subItem) => {
        const subMenuItemExists = menuDictionary[item.MenuName].subMenu.some(
          (existingSubMenu) => existingSubMenu.title === subItem.title
        );

        if (!subMenuItemExists) {
          menuDictionary[item.MenuName].subMenu.push(subItem);
        }
      });
    });

    // Convert the dictionary values to an array of items
    var reportsDynamicSideMenu = Object.values(menuDictionary);
  } else {
  }

  const SideMenuVisibleLaptop = () => {
    let status = !sidebarVisibility;
    setSidebarVisibility(status);
    setToggleSubMenuIcon(status);
  };

  const toggleSubmenu = (menuKey, subMenu) => {
    setSubmenuOpen((prev) => ({ ...prev, [menuKey]: !prev[menuKey] }));
  };

  const currentSubMenu = (currentKey, subMenuPath) => {
    setCurrentMainMenu(currentKey);
    setCurrentSubmenu(subMenuPath);

    setSubmenuOpen((prev) => ({ ...prev, [currentKey]: true }));
  };

  // useEffect(() => {
  //   if (location.pathname && administratorDynamicSideMenu) {
  //     let adminMenuSubmenuList = [];
  //     administratorDynamicSideMenu.forEach((item) => {
  //       if (item.subMenu.length > 0) {
  //         adminMenuSubmenuList = adminMenuSubmenuList.concat(item.subMenu);
  //       }
  //     });

  //     let submenuFilter = adminMenuSubmenuList.find(
  //       (item) => item.to === location.pathname
  //     );

  //     if (submenuFilter) {
  //       let mainMenuFilter = administratorDynamicSideMenu.find((item) => {
  //         if (item.mainMenuId === submenuFilter.mainMenuId) {
  //           return item;
  //         }
  //       });

  //       if (mainMenuFilter && mainMenuFilter.key) {
  //         setSubmenuOpen((prev) => ({ ...prev, [mainMenuFilter.key]: true }));
  //       }
  //     }
  //   }
  // }, [location.pathname, administratorDynamicSideMenu]);

  // useEffect(() => {
  //   if (location.pathname) {
  //     let transactionMenuSubmenuList = [];
  //     transactionDynamicSideMenu &&
  //       transactionDynamicSideMenu.map((i) => {
  //         // adminMenuSubmenuList.push(i.subMenu > 0 ? i.subMenu : [])
  //         if (i.subMenu.length > 0) {
  //           transactionMenuSubmenuList = transactionMenuSubmenuList.concat(
  //             i.subMenu
  //           );
  //           // adminMenuSubmenuList.push(i.subMenu);
  //         }
  //       });

  //     let submenuFilter = transactionMenuSubmenuList.find(
  //       (item) => item.to === location.pathname
  //     );

  //     if (submenuFilter) {
  //       let mainMenuFilter = transactionDynamicSideMenu.find((item) => {
  //         if (item.mainMenuId === submenuFilter.mainMenuId) {
  //           return item;
  //         }
  //       });

  //       if (mainMenuFilter && mainMenuFilter.key) {
  //         setSubmenuOpen((prev) => ({ ...prev, [mainMenuFilter.key]: true }));
  //       }
  //     }
  //   }
  // }, [location.pathname,transactionDynamicSideMenu]);

  // useEffect(() => {
  //   if (location.pathname) {
  //     let reportsMenuSubmenuList = [];
  //     reportsDynamicSideMenu &&
  //       reportsDynamicSideMenu.map((i) => {
  //         // adminMenuSubmenuList.push(i.subMenu > 0 ? i.subMenu : [])
  //         if (i.subMenu.length > 0) {
  //           reportsMenuSubmenuList = reportsMenuSubmenuList.concat(i.subMenu);
  //           // adminMenuSubmenuList.push(i.subMenu);
  //         }
  //       });

  //     let submenuFilter = reportsMenuSubmenuList.find(
  //       (item) => item.to === location.pathname
  //     );

  //     if (submenuFilter) {
  //       let mainMenuFilter = reportsDynamicSideMenu.find((item) => {
  //         if (item.mainMenuId === submenuFilter.mainMenuId) {
  //           return item;
  //         }
  //       });

  //       if (mainMenuFilter && mainMenuFilter.key) {
  //         setSubmenuOpen((prev) => ({ ...prev, [mainMenuFilter.key]: true }));
  //       }
  //     }
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    const handleMenuUpdate = (menuList) => {
      let menuSubmenuList = [];
  
      // Gather all submenu items
      menuList.forEach((item) => {
        if (item.subMenu.length > 0) {
          menuSubmenuList = menuSubmenuList.concat(item.subMenu);
        }
      });
  
      // Find the submenu that matches the current pathname
      const submenuFilter = menuSubmenuList.find(
        (item) => item.to === location.pathname
      );
  
      if (submenuFilter) {
        // Find the main menu item that contains the matching submenu
        const mainMenuFilter = menuList.find((item) => 
          item.mainMenuId === submenuFilter.mainMenuId
        );
  
        if (mainMenuFilter && mainMenuFilter.key) {
          setSubmenuOpen((prev) => {
            // Only update state if the main menu is not already open
            if (!prev[mainMenuFilter.key]) {
              return { ...prev, [mainMenuFilter.key]: true };
            }
            return prev;
          });
        }
      }
    };
  
    // Ensure that `location.pathname` is valid
    if (location.pathname) {
      if (administratorDynamicSideMenu && administratorDynamicSideMenu.length > 0) {
        handleMenuUpdate(administratorDynamicSideMenu);
      }
  
      if (transactionDynamicSideMenu && transactionDynamicSideMenu.length > 0) {
        handleMenuUpdate(transactionDynamicSideMenu);
      }
  
      if (reportsDynamicSideMenu && reportsDynamicSideMenu.length > 0) {
        handleMenuUpdate(reportsDynamicSideMenu);
      }
    }
  }, [administratorDynamicSideMenu, transactionDynamicSideMenu, reportsDynamicSideMenu, location.pathname]);
  

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setSidebarVisibility(false);
      setRemoveDashboardIcon(true);
    }
  }, []);

  return (
    <div
      // style={{ height: sideMenuHeight }}
      className={`${removeDashboardIcon ? `hidden` : sideBarMenu.sideMenuDiv}`}
    >
      <aside
        id="default-sidebar"
        className={sideBarMenu.sideMenuWidth}
        aria-label="Sidebar"
      >
        <div class={sideBarMenu.overflow}>
          <span className="text-white  flex justify-end">
            {sidebarVisibility ? (
              <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                onClick={() => SideMenuVisibleLaptop()}
                class={`${removeDashboardIcon ? `hidden` : "w-6 h-6 mb-4"}`}
              >
                <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm3.5 7.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z" />
              </svg>
            ) : (
              <svg
                fill="currentColor"
                viewBox="0 0 16 16"
                onClick={() => SideMenuVisibleLaptop()}
                class={`${removeDashboardIcon ? `hidden` : "w-6 h-6 mb-4"}`}
              >
                <path d="M8 0a8 8 0 110 16A8 8 0 018 0zM4.5 7.5a.5.5 0 000 1h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5z" />
              </svg>
            )}
          </span>
          <div className="overflow-y-auto scrollbar-custom h-[calc(100vh-8rem)]">
          <ul class="space-y-2">
            {(pageName === "2"
              ? administratorDynamicSideMenu
                ? administratorDynamicSideMenu
                : []
              : pageName === "3"
              ? transactionDynamicSideMenu
                ? transactionDynamicSideMenu
                : []
              : pageName === "4"
              ? reportsDynamicSideMenu
                ? reportsDynamicSideMenu
                : []
              : []
            ).map((items, index) => (
              <li key={index}>
                <NavLink
                  to={items.to}
                  class={sideBarMenu.sideMenu}
                  state={{
                    isAdd: items.isAdd ? items.isAdd : undefined,
                    menuId: items.menuId ? items.menuId : undefined,
                  }}
                  className={`flex items-center p-2 text-base font-normal ${
                    isActive(items.to)
                      ? "text-white bg-red-400"
                      : "text-gray-300 hover:bg-gray-700"
                  } rounded-lg `}
                  onClick={() => toggleSubmenu(items.key, items.subMenu)}
                >
                  {items.icon}
                  <span class={sideBarMenu.sideMenuNameLaptop}>
                    {items.title}
                  </span>

                  {items.subMenu.length > 0 && (
                    <span
                      className={`${
                        toggleSubMenuIcon
                          ? sideBarMenu.toggleSubMenuIconCss
                          : "hidden"
                      }`}
                    >
                      {submenuOpen[items.key] ? (
                        <svg
                          viewBox="0 0 464 1000"
                          s
                          fill="currentColor"
                          class="w-6 h-6 text-white"
                        >
                          <path d="M62 620c-17.333 14.667-34 14.667-50 0-16-16-16-32.667 0-50l196-190c17.333-17.333 33.333-17.333 48 0l196 190c16 17.333 16 34 0 50-16 14.667-32.667 14.667-50 0L232 462 62 620" />
                        </svg>
                      ) : (
                        <svg
                          viewBox="0 0 466 1000"
                          fill="currentColor"
                          class="w-6 h-6 text-white"
                        >
                          <path d="M405 380c14.667-17.333 30.667-17.333 48 0 17.333 14.667 17.333 30.667 0 48L257 620c-14.667 14.667-30.667 14.667-48 0L13 428c-17.333-17.333-17.333-33.333 0-48 16-16 32.667-16 50 0l170 156 172-156" />
                        </svg>
                      )}
                    </span>
                  )}
                </NavLink>
                {items.subMenu && submenuOpen[items.key] && (
                  <ul>
                    {items.subMenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={sideBarMenu.sideMenuNameLaptop}
                      >
                        <NavLink
                          to={subItem.to}
                          onClick={() => currentSubMenu(items.key, subItem.to)}
                          state={{
                            menuId: subItem.menuId ? subItem.menuId : undefined,
                          }}
                          class={sideBarMenu.sideMenu}
                          className={`flex items-center p-2 ml-5 text-base font-normal ${
                            isActive(subItem.to)
                              ? "text-white bg-red-400"
                              : "text-gray-300 hover:bg-gray-700"
                          } rounded-lg `}
                        >
                          <span class={sideBarMenu.sideMenuNameLaptop}>
                            {subItem.title}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default React.memo(SideMenu);
