// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";

const StudentPopupMaster = ({ data, data1 }) => {
  
  const [genderRadio, setGenderRadio] = useState([
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Transmen" },
    { value: "4", label: "Transwomen" },
    { value: "5", label: "Not Binary" },
  ]);

  return (
    <div class="w-full">
      <div class="text-xl mx-5 font-medium text-neutral-700 ">
        {"Student Master - View"}
      </div>
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form class={mainTable.formForPopup}>
              <div className={mainTable.grid3}>
                <AutoDropDown
                  title="School Name"
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={data1[0]?.[0]}
                  Class="custom-class"
                  disabled={true}
                />
                <InputText
                  inputType="text"
                  title="Student Name"
                  name="studentName"
                  content={data.StudentName}
                />
                <InputText
                  inputType="text"
                  title="Student ID."
                  name="studentID"
                  content={data.StudentId}
                />
                <InputText
                  inputType="text"
                  title="Class"
                  name="studentClass"
                  content={data.StudentClass}
                />
                <InputText
                  inputType="text"
                  title="Division"
                  name="division"
                  content={data.StudentDivision}
                />
                <InputText
                  inputType="text"
                  title="Age"
                  name="age"
                  content={data.StudentAge}
                />
                <div className="col-span-2">
                  <CheckboxOrRadioGroup
                    title="Gender"
                    type={"radio"}
                    setName={"gender"}
                    selectedOptions={[data1[1]]}
                    options={genderRadio}
                    disabled="disabled"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
 export default StudentPopupMaster;
