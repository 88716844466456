import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const Commoditiespopup = ({ data, data2 }) => {
  
  const selectedOption = data2[2];
  const [textFiledName, setTextFiledName] = useState("");

  const [visibleMode, setVisibleMode] = useState(false);
  const [itemCategoryShow, setItemCategoryShow] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.MstCommodityTypeId == 1) {
        setTextFiledName("Contraceptive Items");
        setVisibleMode(false);
        setItemCategoryShow(true);
      } else if (data.MstCommodityTypeId == 2) {
        setTextFiledName("Medicines Name");
        setVisibleMode(true);
        setItemCategoryShow(false);
      } else if (data.MstCommodityTypeId == 3) {
        setTextFiledName("Social Marketing Name");
        setVisibleMode(false);
        setItemCategoryShow(false);
      } else if (data.MstCommodityTypeId == 4) {
        setTextFiledName("Consumables Name");
        setVisibleMode(false);
        setItemCategoryShow(false);
      }
    } else {
      return;
    }
  }, [data]);
  return (
    <div class="w-full ">
      <div className={mainTable.addShadowScreen}>
        <div className={mainTable.mainContentScreen}>
          <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit">
            <div className={mainTable.grid3}>
              <AutoDropDown
                title={`${data.CommodityType_en} Categories`}
                classStar={"text-red-500"}
                placeholder="Select"
                options={[]}
                Class="p-0"
                name="Commodity"
                selectedOption={data2[0]}
                disabled={true}
              />
              <InputText
                inputType="text"
                title={textFiledName}
                classStar={"text-red-500"}
                placeholder="Commodity Name"
                name="productName"
                content={data.CommodityName_en}
                disabled={true}
              />

              {visibleMode && (
                <AutoDropDown
                  title="Route"
                  classStar={"text-red-500"}
                  placeholder="Select"
                  options={[]}
                  Class="p-0"
                  name="mode"
                  selectedOption={selectedOption}
                  disabled={true}
                />
              )}

              <InputText
                inputType="text"
                title="Description"
                placeholder="Description"
                name="description"
                content={data && data.Description_en ? data.Description_en : ""}
                disabled={true}
              />
              <AutoDropDown
                title="Unit Of Measure"
                placeholder="Select"
                options={[]}
                classStar={"text-red-500"}
                Class="p-0"
                name="Commodity"
                selectedOption={data2[1]}
                disabled={true}
              />
              {itemCategoryShow && (
                <InputText
                  inputType="text"
                  title="Item Category"
                  placeholder="Item Category"
                  name="itemCategory"
                  content={data && data.ItemCategory ? data.ItemCategory : ""}
                  disabled={true}
                />
              )}

              {/* <div className="col-span-2">
                <div className="grid gap-4 md:grid-cols-3 sm:col-span-1">
                  <InputText
                    inputType="text"
                    title=" Opening Quantity"
                    placeholder="10"
                    name="openingQuantity"
                    content={data.OpeningQty}
                  />
                  <InputText
                    inputType="date"
                    title="Opening Date"
                    name="openingDate"
                    content={data2[2]}
                  />
                  <AutoDropDown
                    title="Unit Of Measure"
                    placeholder="Select"
                    options={[]}
                    Class="p-0"
                    name="Commodity"
                    selectedOption={data2[1]}
                  />
                </div>
              </div>
              <InputText
                inputType="text"
                title="Charges"
                placeholder="0"
                name="Charges"
                content={data.Charge}
              /> */}
              <div className="col-span-3">
                <div class={mainTable.labelBold}>Re-Order Level</div>
                <hr class={mainTable.horizontalLine}></hr>
              </div>
              <InputText
                inputType="text"
                title=" SDP Level"
                placeholder="100"
                classStar={"text-red-500"}
                name="sdpLevel"
                content={data.CommoditySDPLevel}
                disabled={true}
              />
              <InputText
                inputType="text"
                title=" Outreach level"
                placeholder="200"
                classStar={"text-red-500"}
                name="outreachLevel"
                content={data.CommodityOutreachLevel}
                disabled={true}
              />
              <InputText
                inputType="text"
                title=" Branch level"
                placeholder="300"
                classStar={"text-red-500"}
                name="branchLevel"
                content={data.CommodityBranchLevel}
                disabled={true}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Commoditiespopup;
