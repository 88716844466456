// import React, { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import { mainTable } from "../css/Common";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

// const MultiSelect = (props) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredOptions, setFilteredOptions] = useState([]);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [selectedOptionsEdit, setSelectedOptionsEdit] = useState([]);
//   const [localErrorMessage, setLocalErrorMessage] = useState(
//     props.errorMessage
//   );
//   const [showOptions, setShowOptions] = useState(false);

//   const componentRef = useRef(null);

//   useEffect(() => {
//     props?.filteredOptions?.length > 0 &&
//       setFilteredOptions(
//         props.filteredOptions.filter(
//           (option) =>
//             option.label &&
//             option.label.toLowerCase().includes(searchTerm.toLowerCase())
//         )
//       );
//   }, [props.filteredOptions, searchTerm]);

//   useEffect(() => {
//     if (props.selectedOptions) {
//       setSelectedOptions(props.selectedOptions);
//     }
//   }, [JSON.stringify(props.selectedOptions)]);

//   useEffect(() => {
//     document.addEventListener("click", handleOutsideClick);
//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, []);

//   const handleOutsideClick = (e) => {
//     if (componentRef.current && !componentRef.current.contains(e.target)) {
//       setShowOptions(false);
//       setLocalErrorMessage("");
//     }
//   };

//   const toggleOption = (option) => {
//     if (props.disabled) {
//       return; // If disabled, do nothing
//     }
//     const updatedOptions = selectedOptions.includes(option.value)
//       ? selectedOptions.filter((item) => item !== option.value)
//       : [...selectedOptions, option.value];
//     console.log(updatedOptions, selectedOptions);
//     setSelectedOptions(updatedOptions);
//     setLocalErrorMessage("");
//     props.onChange(updatedOptions);
//   };

//   const handleInputClick = () => {
//     setShowOptions((prevShowOptions) => !prevShowOptions);
//   };

//   return (
//     <div
//       ref={componentRef}
//       className={props.classDiv ? props.classDiv : "relative inline-block"}
//       style={{ position: "relative" }}
//     >
//       {props.label && (
//         <label
//           className={props.classLabel ? props.classLabel : mainTable.label}
//         >
//           {props.label}
//           <span className={props.classStar ? props.classStar : "hidden"}>
//             *
//           </span>
//         </label>
//       )}
//       <div className={props.selectedOptionsClass}></div>

//       <input
//         type="text"
//         name={props.name}
//         placeholder={props.placeholder}
//         errorMessage={localErrorMessage}
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         onClick={handleInputClick}
//         onBlur={props.controlFuncBlur} // Add onBlur event handler here
//         className={`flex items-center cursor-default p-2 w-full flex-wrap justify-between min-h-38px relative transition-all duration-100 bg-white border border-gray-300 rounded-md box-border`}
//       />
//       {localErrorMessage && (
//         <span class="text-red-500">{localErrorMessage}</span>
//       )}
//       <FontAwesomeIcon
//         icon={faCaretDown}
//         className="absolute right-4 top-2/3 transform -translate-y-1/2 cursor-pointer block"
//         onClick={handleInputClick}
//       />
//       {showOptions && (
//         <div
//           className={props.optionsListClass}
//           style={{
//             position: "absolute",
//             display: "block",
//             top: "100%",
//             left: 0,
//             padding: "3px",
//             zIndex: 999,
//             maxHeight: "150px", // Set the max height of the options list
//             overflowY: "auto", // Add scroll bar if needed
//             backgroundColor: "#ffffff", // Set a background color for the options container
//             border: "1px solid #ccc", // Optional: Add a border for visual separation
//             boxShadow: "0px 0px 5px rgba(0,0,0,0.1)", // Optional: Add a shadow for visual depth
//             width: "100%", // Set the width to 100%
//           }}
//         >
//           {filteredOptions && filteredOptions.length > 0 ? (
//             filteredOptions.map((option) => (
//               <div
//                 key={option.value}
//                 className={
//                   props.classFilterLabel
//                     ? props.classFilterLabel
//                     : mainTable.inputRadioDiv
//                 }
//                 onClick={() => toggleOption(option)}
//               >
//                 <input
//                   type="checkbox"
//                   className={
//                     props.class ? props.class : mainTable.inputCheckBox
//                   }
//                   checked={selectedOptions.includes(option.value)}
//                   onChange={() => toggleOption(option)}
//                   onBlur={props.controlFuncBlur}
//                   disabled={props.disabled}
//                 />
//                 {option.label}
//               </div>
//             ))
//           ) : (
//             <div class="flex justify-center py-2 rounded-md text-gray-400">
//               No options
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// MultiSelect.propTypes = {
//   name: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   filteredOptions: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.string,
//       label: PropTypes.string,
//     })
//   ).isRequired,
//   selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
//   onChange: PropTypes.func.isRequired,
//   controlFuncBlur: PropTypes.func.isRequired,
//   className: PropTypes.string,
//   selectedOptionsClass: PropTypes.string,
//   selectedOptionClass: PropTypes.string,
//   placeholder: PropTypes.string,
//   searchInputClass: PropTypes.string,
//   optionsListClass: PropTypes.string,
//   optionClass: PropTypes.string,
//   disabled: PropTypes.bool,
//   errorMessage: PropTypes.string,
// };

// export default MultiSelect;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const MultiSelect = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [localErrorMessage, setLocalErrorMessage] = useState(
    props.errorMessage
  );
  const [showOptions, setShowOptions] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(
      props.filteredOptions?.filter(
        (option) =>
          option.label &&
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [props.filteredOptions, searchTerm]);

  useEffect(() => {
    if (props.selectedOptions) {
      setSelectedOptions(props.selectedOptions);
    }
  }, [JSON.stringify(props.selectedOptions)]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e) => {
    if (componentRef.current && !componentRef.current.contains(e.target)) {
      setShowOptions(false);
      setLocalErrorMessage("");
    }
  };

  const toggleOption = (option) => {
    if (props.disabled) {
      return; // If disabled, do nothing
    }
    const updatedOptions = selectedOptions.includes(option.value)
      ? selectedOptions.filter((item) => item !== option.value)
      : [...selectedOptions, option.value];
    setSelectedOptions(updatedOptions);
    setLocalErrorMessage("");
    props.onChange(updatedOptions);
  };

  const handleInputClick = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const toggleSelectAll = () => {
    if (props.disabled) {
      return; // If disabled, do nothing
    }
    const allSelected =
      selectedOptions?.length == props.filteredOptions?.length;
    const updatedOptions = allSelected
      ? []
      : props.filteredOptions.map((option) => option.value);
    setSelectedOptions(updatedOptions);
    setLocalErrorMessage("");
    props.onChange(updatedOptions);
  };

  const isAllSelected =
    selectedOptions?.length == props.filteredOptions?.length;

  return (
    <div
      ref={componentRef}
      className={props.classDiv ? props.classDiv : "relative inline-block"}
      style={{ position: "relative" }}
    >
      {props.label && (
        <label
          className={props.classLabel ? props.classLabel : mainTable.label}
        >
          {props.label}
          <span className={props.classStar ? props.classStar : "hidden"}>
            *
          </span>
        </label>
      )}
      <div className={props.selectedOptionsClass}></div>

      <input
        type="text"
        name={props.name}
        placeholder={props.placeholder}
        errorMessage={localErrorMessage}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={handleInputClick}
        onBlur={props.controlFuncBlur} // Add onBlur event handler here
        className={`flex items-center cursor-default p-2 w-full flex-wrap justify-between min-h-38px relative transition-all duration-100 bg-white border border-gray-300 rounded-md box-border`}
      />
      {localErrorMessage && (
        <span className="text-red-500">{localErrorMessage}</span>
      )}
      <FontAwesomeIcon
        icon={faCaretDown}
        className="absolute right-4 top-2/3 transform -translate-y-1/2 cursor-pointer block"
        onClick={handleInputClick}
      />
      {showOptions && (
        <div
          className={props.optionsListClass}
          style={{
            position: "absolute",
            display: "block",
            top: "100%",
            left: 0,
            padding: "3px",
            zIndex: 999,
            maxHeight: "150px", // Set the max height of the options list
            overflowY: "auto", // Add scroll bar if needed
            backgroundColor: "#ffffff", // Set a background color for the options container
            border: "1px solid #ccc", // Optional: Add a border for visual separation
            boxShadow: "0px 0px 5px rgba(0,0,0,0.1)", // Optional: Add a shadow for visual depth
            width: "100%", // Set the width to 100%
          }}
        >
          <div
            className={
              props.classFilterLabel
                ? props.classFilterLabel
                : mainTable.inputRadioDiv
            }
          >
            <input
              type="checkbox"
              className={props.class ? props.class : mainTable.inputCheckBox}
              checked={isAllSelected}
              onChange={toggleSelectAll}
              onBlur={props.controlFuncBlur}
              disabled={props.disabled}
            />
            Select All
          </div>
          {filteredOptions && filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={
                  props.classFilterLabel
                    ? props.classFilterLabel
                    : mainTable.inputRadioDiv
                }
                onClick={() => toggleOption(option)}
              >
                <input
                  type="checkbox"
                  className={
                    props.class ? props.class : mainTable.inputCheckBox
                  }
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => toggleOption(option)}
                  onBlur={props.controlFuncBlur}
                  disabled={props.disabled}
                />
                {option.label}
              </div>
            ))
          ) : (
            <div className="flex justify-center py-2 rounded-md text-gray-400">
              No options
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  filteredOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedOptionsClass: PropTypes.string,
  selectedOptionClass: PropTypes.string,
  placeholder: PropTypes.string,
  searchInputClass: PropTypes.string,
  optionsListClass: PropTypes.string,
  optionClass: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default MultiSelect;
