import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import {
  ConsumptionViewHeaders,
  consumptionHeaders,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import GridPage from "../components/GridPage";
import ConsumptionPopup from "../popup-containers/ConsumptionPopup";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import UsePrevious from "../Hook/UsePrevious";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { useLocation } from "react-router-dom";
import DropDown from "../ReusableComponents/DropDown";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function Consumption() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const [bindConsumptionDatas, setBindConsumptionDatas] = useState([]);
  const [bindCategoryDD, setBindCategoryDD] = useState([]);
  const [bindCommoditiesDD, setBindCommoditiesDD] = useState([]);
  const [bindBranchDD, setBindBranchDD] = useState([]);
  const [bindSdpDD, setBindSdpDD] = useState([]);
  const [bindSdpPopUpDD, setBindSdpPopUpDD] = useState([]);
  const [bindSdpDatas, setBindSdpDatas] = useState([]);
  const [bindNameDD, setBindNameDD] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [consumptionChildPopUpDatas, setConsumptionChildPopUpDatas] = useState(
    {}
  );
  const [bindConsumptionChildDatas, setBindConsumptionChildDatas] = useState(
    []
  );
  const [consumptionError, setConsumptionError] = useState("");
  const [commoditiesDetails, setCommoditiesDetails] = useState("");
  const [bindFieldWorkerName, setBindFieldWorkerName] = useState([]);
  const [bindOutReachWorker, setBindOutReachWorker] = useState([]);

  const [consumptionFileds, setConsumptionFileds] = useState({
    commodities: "",
    consumptionNo: "INCON",
    consumptionDate: "",
    branch: "",
    sdp: "",
    branchId: "",
    sdpId: "",
    sessionDate: "",
    sessionPlace: "",
    reason: "",
    area: "",
    mstORWId: "",
  });

  const [consumptionFiledsError, setConsumptionFiledsError] = useState({
    commoditiesError: "",
    consumptionNoError: "",
    branchError: "",
  });

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewAction, setViewAction] = useState(false);
  const [visibleSdp, setVisibleSdp] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [editMode, setEditMode] = useState([]);
  const [sdp, setSdp] = useState([]);
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const [bindAreaDatas, setBindAreaDatas] = useState([]);

  const [consumptionPopUpDatas, setConsumptionPopUpDatas] = useState({});
  const [dropdownPopUpDatas, setDropdownPopUpDatas] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");
  const [editDate, setEditDate] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [editTxnConsumptionId, setEditTxnConsumptionId] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [tableData, setTableData] = useState([
    {
      commodities: "",
      category: "",
      name: "",
      brand: "",
      unitOfMeasure: "",
      batchNo: "",
      dateOfExpiry: "",
      demonstration: null,
      damagedAdjustments: null,
      expired: null,
      total: null,
      categoryList: [],
      nameList: [],
      brandList: [],
      currentStock: "",
      currentStockNew: "",
    },
  ]);

  // Inventory Consumption previousTableData and CurrentTableData compare functionality .
  const previousTableData = UsePrevious(tableData);

  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] };
    newRow.commodities = "";
    newRow.category = "";
    newRow.name = "";
    newRow.brand = "";
    newRow.unitOfMeasure = "";
    newRow.batchNo = "";
    newRow.dateOfExpiry = "";
    newRow.demonstration = "";
    newRow.damagedAdjustments = "";
    newRow.expired = "";
    newRow.total = "";
    newRow.categoryList = [];
    newRow.nameList = [];
    newRow.brandList = [];
    newRow.currentStock = "";
    newRow.currentStockNew = "";

    setTableData((prevData) => [...prevData, newRow]);
  };

  // Inventory Consumption Child Table Row Delete Functionaity .

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          commodities: "",
          category: "",
          name: "",
          brand: "",
          unitOfMeasure: "",
          batchNo: "",
          dateOfExpiry: "",
          demonstration: null,
          damagedAdjustments: null,
          expired: null,
          total: null,
          categoryList: [],
          nameList: [],
          brandList: [],
          currentStock: "",
          currentStockNew: "",
        };

        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setCommoditiesDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  // Inventory Consumption Child Table Fields Input Change Handling .
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";

    // Update the state with the new value
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };

      // Ensure side effect is based on the latest updatedData
      convertArrayToJSONString(updatedData);

      return updatedData;
    });
  };

  const userProfileDatas = userProfileState.userProfile;

  // const getBranch = (userProfileDatas) => {
  //   if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
  //     setConsumptionFileds({
  //       ...consumptionFileds,
  //       branch: userProfileDatas[0].MstLocationId,
  //     });
  //     console.log("branch", userProfileDatas[0].MstLocationId);
  //     // setLocation(userProfileDatas[0].MstLocationId);
  //   } else {
  //     // handle else case if needed
  //   }
  // }
  const getBranch = (userProfileDatas) => {
    console.log("userProfileDatas", userProfileDatas);
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setConsumptionFileds({
        ...consumptionFileds,
        branch: userProfileDatas[0].LocationName_en,
        branchId: userProfileDatas[0].MstLocationId,
      });
      if (userProfileDatas.length === 1) {
        setVisibleSdp(true);
        // If userProfileDatas has only one element
        setConsumptionFileds({
          ...consumptionFileds,
          branch: userProfileDatas[0].LocationName_en,
          sdp: userProfileDatas[0].SDP_en,
          branchId: userProfileDatas[0].MstLocationId,
          sdpId: userProfileDatas[0].MstSDPId,
        });
      } else {
        const newSdp = userProfileDatas.map((userProfile) => ({
          value: userProfile.MstSDPId,
          label: userProfile.SDP_en,
        }));
        setSdp(newSdp);
        console.log("newSdp", newSdp);
      }
    } else {
      // handle else case if needed
    }
  };

  useEffect(() => {
    setBindOutReachWorker([]);
    const sdpSelectedValue = bindFieldWorkerName.find(
      (item) => item.MstSDPId == consumptionFileds.sdpId // Replace with the desired MstStateId
    );

    if (sdpSelectedValue) {
      const sdpLabels = bindFieldWorkerName
        .filter((item) => item.MstSDPId === sdpSelectedValue.MstSDPId)
        .map((item) => ({
          MstUserId: item.MstUserId,
          FirstName_en: item.FirstName_en,
        }));

      const sdpSelected = sdpLabels.map((AR) => ({
        value: AR.MstUserId, // Replace with the appropriate value
        label: AR.FirstName_en,
      }));

      setBindOutReachWorker(sdpSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [consumptionFileds.sdpId ? consumptionFileds.sdpId : null]);

  useEffect(() => {
    if (addScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, []);

  useEffect(() => {
    BindConsumptionData(userProfileDatas);
    ConsumptionDropDownDatas();
  }, [userProfileDatas]);

  // const BindConsumptionData = async () => {
  //   const bindConsumptionAllMaster = await BindAPI(
  //     "ConsumptionTransAPI/BindConsumption"
  //   );
  //   setBindConsumptionDatas(bindConsumptionAllMaster.data.Table);
  //   setBindConsumptionChildDatas(bindConsumptionAllMaster.data.Table1);
  // };
  const BindConsumptionData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "ConsumptionTransAPI/BindConsumption"
      );
      setBindConsumptionDatas(response.data.Table);
      setBindConsumptionChildDatas(response.data.Table1);
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      setConsumptionFileds({
        ...consumptionFileds,
        mstORWId:userProfileDatas[0].MstRoleId === 7
        ? userProfileDatas[0].MstUserId
        : null,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);
  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setViewAction(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setEditDate("");
    getBranch(userProfileDatas);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];

    setConsumptionFileds((prevFields) => ({
      ...prevFields,
      consumptionDate: formattedDate,
    }));
  };
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);

    clear();

    // Ensure you pass a default value like an empty array or object if necessary
    setConsumptionChildPopUpDatas([]);

    if (userProfileDatas) {
      BindConsumptionData(userProfileDatas);
    }

    setCommoditiesDetails(""); // Make sure this is expected to be a string
    setBindSdpDD([]); // Ensure this is an array
  };

  const clear = () => {
    setConsumptionError("");

    setConsumptionFileds({
      commodities: "",
      consumptionNo: "INCON",
      consumptionDate: new Date(),
      branch: "",
      sdp: "",
      sessionDate: "",
      sessionPlace: "",
      reason: "",
      area: "",
      mstORWId: "",
    });

    setConsumptionFiledsError({
      commoditiesError: "",
      consumptionNoError: "",
      branchError: "",
    });
    setTableData([
      {
        category: "",
        name: "",
        brand: "",
        unitOfMeasure: "",
        batchNo: "",
        dateOfExpiry: "",
        demonstration: "",
        damagedAdjustments: "",
        expired: "",
        total: "",
        categoryList: [],
        nameList: [],
        brandList: [],
        currentStock: "",
        currentStockNew: "",
      },
    ]);
  };
  const appendDataToCommoditiesTable = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);
    const formattedDataArray = newDataArray.map((data) => {
      const formattedDateOfExpiry = data.DateOfExpiry;
      const formattedDate1 = formattedDateOfExpiry.split("T")[0];
      return {
        category: data.MstCategoryId,
        name: data.MstNameId,
        brand: data.MstBrandId,
        unitOfMeasure: data.UOM,
        batchNo: data.BatchNo,
        dateOfExpiry: formattedDate1,
        demonstration: data.Demonstration,
        damagedAdjustments: data.DamagedAdjustments,
        expired: data.Expired,
        total: data.Total,
      };
    });

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };
  const HandleDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setConsumptionPopUpDatas(bindConsumptionDatas[rowIndex]);

    const commoditiesDetailData = Object.values(
      bindConsumptionChildDatas
    ).filter(
      (item) =>
        item.TxnConsumptionId ===
        bindConsumptionDatas[rowIndex].TxnConsumptionId
    );
    setConsumptionChildPopUpDatas(commoditiesDetailData);

    const commoditiesRowData = bindConsumptionDatas[rowIndex];
    const commoditiesInfo = bindCommoditiesDD.find(
      (L) => L.value == commoditiesRowData.MstCommoditiesId
    );
    const Commodities = {
      value: commoditiesRowData.MstCommoditiesId,
      label: commoditiesInfo ? commoditiesInfo.label : "Unknown",
    };

    const ConsumptionDateFormat =
      bindConsumptionDatas[rowIndex].ConsumptionDate;
    const ConsumptionDate = ConsumptionDateFormat.split("T")[0];

    const branchRowData = bindConsumptionDatas[rowIndex];
    const branchInfo = bindBranchDD.find(
      (L) => L.value == branchRowData.MstBranchId
    );
    const Branch = {
      value: branchRowData.MstBranchId,
      label: branchInfo ? branchInfo.label : "Unknown",
    };

    const SDPRowData = bindConsumptionDatas[rowIndex];
    const SDPInfo = bindSdpPopUpDD.find((L) => L.value == SDPRowData.MstSDPId);
    const SDP = {
      value: SDPRowData.MstSDPId,
      label: SDPInfo ? SDPInfo.label : "Select",
    };

    const SessionDateFormat = bindConsumptionDatas[rowIndex].SessionDate;
    const SessionDate = SessionDateFormat
      ? SessionDateFormat.split("T")[0]
      : "";

    const ArearowData = bindConsumptionDatas[rowIndex];
    const ArearowDataInfo = bindAreaDatas.find(
      (AT) => AT.MstAreaId === ArearowData.MstAreaId
    );
    const ResultArea = {
      value: ArearowData.MstAreaId,
      label: ArearowDataInfo ? ArearowDataInfo.AreaName_en : "",
    };

    const outReachRowData = bindConsumptionDatas[rowIndex];
    const outReachInfo = bindFieldWorkerName.find(
      (L) => L.MstUserId == outReachRowData.MstORWId
    );
    const ResultoutReach = {
      value: outReachRowData.MstORWId,
      label: outReachInfo ? outReachInfo.FirstName_en : "Select",
    };

    const DropdownDatas = [
      Commodities,
      ConsumptionDate,
      Branch,
      SDP,
      SessionDate,
      ResultArea,
      ResultoutReach,
    ];

    setDropdownPopUpDatas(DropdownDatas);
  };

  const handleEdit = async (rowIndex) => {
    getBranch(userProfileDatas);
    setEditMode(true);
    setVisibleAdd(true);
    setViewAction(false);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    const BindTxnConsumptionId =
      bindConsumptionDatas[rowIndex].TxnConsumptionId;
    setEditDate(bindConsumptionDatas[rowIndex].ConsumptionDate);
    setEditTxnConsumptionId(BindTxnConsumptionId);
    const rowData = bindConsumptionDatas[rowIndex];
    const currentDate = bindConsumptionDatas[rowIndex].ConsumptionDate;
    const formattedDate = currentDate.split("T")[0];
    const currentDate1 = bindConsumptionDatas[rowIndex].SessionDate;
    const formattedDate1 = currentDate1 ? currentDate1.split("T")[0] : null;
    setConsumptionFileds({
      ...consumptionFileds,
      commodities: rowData.MstCommoditiesId,
      consumptionNo: rowData.ConsumptionNo,
      consumptionDate: formattedDate,
      branch: rowData.LocationName_en,
      branchId: rowData.MstBranchId,
      sdp: userProfileDatas.length === 1 ? rowData.SDP_en : rowData.MstSDPId,
      sdpId: rowData.MstSDPId,
      area: rowData.MstAreaId,

      mstORWId: rowData.MstORWId,
      sessionDate: formattedDate1,
      sessionPlace: rowData.SessionPlace_en,
      reason: rowData.Reason_en,
    });
    setBindConsumptionDatas((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        consumptionDate: formattedDate,
        sessionDate: formattedDate1,
      };
      return updatedData;
    });
    const commoditiesDetailData = Object.values(
      bindConsumptionChildDatas
    ).filter((item) => {
      return String(item.TxnConsumptionId) === String(BindTxnConsumptionId);
    });
    if (commoditiesDetailData.length === 0) {
      setTableData([
        {
          commodities: "",
          category: "",
          name: "",
          brand: "",
          unitOfMeasure: "",
          batchNo: "",
          dateOfExpiry: "",
          demonstration: null,
          damagedAdjustments: null,
          expired: null,
          total: null,
          categoryList: [],
          nameList: [],
          brandList: [],
          currentStock: "",
          currentStockNew: "",
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToCommoditiesTable(JSON.stringify(commoditiesDetailData));
    }
    // appendDataToCommoditiesTable(JSON.stringify(commoditiesDetailData));
  };

  const validate = (flag) => {
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (consumptionFileds.commodities == "") {
        errors.commodities = "Select Commodities";
      }

      if (consumptionFileds.branch == "") {
        errors.branch = "Select Branch";
      }
    }

    setConsumptionFiledsError({
      ...consumptionFiledsError,
      commoditiesError: errors.commodities || "",
      branchError: errors.branch || "",
    });

    return errors;
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    var createdBy = sessionStorage.getItem("createdBy");
    if (flag == 1 || flag == 2) {
      const firstNameValidation = validate(flag);

      if (Object.keys(firstNameValidation).length !== 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }

      if (commoditiesDetails.trim() == "") {
        setConsumptionError("Fill the above table");
        setSubmitBtnDisabled(false);
        setIsOpen(true);
        return; // Stop further execution
      }

      const languageKeys = [
        "ta", // Tamil
        "hi", // Hindi
        "mr", // Marathi
        "gu", // Gujarati
        "kn", // Kannada
        "ml", // Malayalam
        "te", // Telugu
        "bn", // Bengali
        "or", // Oriya
      ];

      const translateTexts = async (text) => {
        return Promise.all(
          languageKeys.map(async (lang) => {
            const translateText = await LanguageTranslatorAPI(text, lang);
            return translateText.data.data.translations[0].translatedText;
          })
        );
      };

      const translatedReason = await translateTexts(consumptionFileds.reason);
      const translatedSessionPlace = await translateTexts(
        consumptionFileds.sessionPlace
      );

      let consumptionData;
      try {
        consumptionData = JSON.parse(commoditiesDetails);
      } catch (error) {
        console.error("Error parsing receipt data:", error);
        setConsumptionError("Error parsing receipt data");
        return; // Stop further execution
      }

      if (!Array.isArray(consumptionData)) {
        console.error("Consumption data is not an array");
        setConsumptionError("Consumption data is not an array");
        return; // Stop further execution
      }

      const consumptionErrors = consumptionData.map((consumption, index) => {
        if (!consumption)
          return `Consumption at index ${index + 1} is missing.`;
        if (!consumption.category)
          return `Consumption at index ${index + 1} is missing Category.`;
        if (!consumption.name)
          return `Consumption at row ${index + 1} is missing Name.`;
        if (!consumption.brand)
          return `Consumption at row ${index + 1} is missing Brand.`;
        if (!consumption.unitOfMeasure)
          return `Consumption at row ${index + 1} is missing unit Of Measure.`;
        if (!consumption.batchNo)
          return `Receipt at row ${index + 1} is missing Batch No.`;
        if (!consumption.dateOfExpiry)
          return `Receipt at row ${index + 1} is missing Date Of Expiry.`;
        if (
          consumption.total > consumption.currentStock ||
          consumption.total === undefined ||
          consumption.total === null ||
          consumption.total === 0
        )
          return `Error: Consumption at row ${
            index + 1
          } is invalid. Total cannot be zero or exceed the current stock (${
            consumption.currentStock
          }).`;
        return null;
      });

      const filteredErrors = consumptionErrors.filter(
        (error) => error !== null
      );

      if (filteredErrors.length > 0) {
        setConsumptionError(filteredErrors.join("; "));
        setSubmitBtnDisabled(false);
        return; // Stop further execution
      } else {
        setConsumptionError(""); // No errors found
      }
      if (Object.keys(firstNameValidation).length === 0) {
        setSubmitBtnDisabled(true);
      } else {
        setSubmitBtnDisabled(false);
      }
      if (Object.keys(firstNameValidation).length === 0) {
        setSubmitBtnDisabled(true);
        const data = {
          txnConsumptionId: editTxnConsumptionId ? editTxnConsumptionId : 0,
          mstCommoditiesId: consumptionFileds.commodities,
          consumptionNo: consumptionFileds.consumptionNo,
          consumptionDate:
            flag == 1 ? consumptionFileds.consumptionDate : editDate,
          mstBranchId: consumptionFileds.branchId,
          mstSDPId: visibleSdp
            ? consumptionFileds.sdpId
            : consumptionFileds.sdp,
          mstAreaId:
            consumptionFileds.area !== "" ? consumptionFileds.area : null,
          mstORWId:
            consumptionFileds.mstORWId !== ""
              ? consumptionFileds.mstORWId
              : null,
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstRoleId: userProfileDatas[0].MstRoleId,
          sessionDate:
            consumptionFileds.sessionDate &&
            consumptionFileds.sessionDate.trim() !== ""
              ? new Date(consumptionFileds.sessionDate)
              : null,
          sessionPlaceEn: consumptionFileds.sessionPlace,
          sessionPlaceTa: translatedSessionPlace[0],
          sessionPlaceHi: translatedSessionPlace[1],
          sessionPlaceMr: translatedSessionPlace[2],
          sessionPlaceGu: translatedSessionPlace[3],
          sessionPlaceKn: translatedSessionPlace[4],
          sessionPlaceMl: translatedSessionPlace[5],
          sessionPlaceTe: translatedSessionPlace[6],
          sessionPlaceBn: translatedSessionPlace[7],
          sessionPlaceOr: translatedSessionPlace[8],
          consumptionChildBulkUpload: commoditiesDetails,
          reasonEn: consumptionFileds.reason,
          reasonTa: translatedReason[0],
          reasonHi: translatedReason[1],
          reasonMr: translatedReason[2],
          reasonGu: translatedReason[3],
          reasonKn: translatedReason[4],
          reasonMl: translatedReason[5],
          reasonTe: translatedReason[6],
          reasonBn: translatedReason[7],
          reasonOr: translatedReason[8],
          createdBy: createdBy,
          flag: flag,
        };
        try {
          const response = await IUDAPI(
            [data],
            "ConsumptionTransAPI/IUDConsumption"
          );

          if (flag == 1) {
            if (response.data.Table[0].Result === "S") {
              toast.success("Consumption added successfully 👍", {
                duration: 5000,
                position: "top-center",
              });
              setSubmitVisibleButton(true);
              setConsumptionFileds({
                ...consumptionFileds,
                consumptionNo: response.data.Table1[0].ConsumptionNo,
              });
              setTimeout(cancel, 10000);
            } else {
              toast.error("Failed to add Consumption data 👎", {
                duration: 2000,
                position: "top-center",
              });
              setTimeout(cancel, 2000);
            }
          } else if (flag == 2) {
            if (response.data.Table[0].Result === "M") {
              toast.success("Consumption updated successfully 👍", {
                duration: 5000,
                position: "top-center",
              });
              setTimeout(cancel, 5000);
            } else {
              toast.error("Failed to update Consumption data 👎", {
                duration: 2000,
                position: "top-center",
              });
              setTimeout(cancel, 2000);
            }
          }
        } catch (error) {
          console.error("Error in IUDAPI request:", error);
          toast.error("An error occurred while processing the request 👎", {
            duration: 2000,
            position: "top-center",
          });
        }
      }
    } else {
      const data = {
        txnConsumptionId: bindConsumptionDatas[items].TxnConsumptionId,
        createdBy: 1,
        flag: flag,
      };
      try {
        const response = await IUDAPI(
          [data],
          "ConsumptionTransAPI/IUDConsumption"
        );

        if (flag == 3 && response.data.Table[0].Result === "D") {
          toast.success("Consumption data deleted successfully 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
        } else if (flag == 4 && response.data.Table[0].Result === "B") {
          toast.success("Consumption data blocked successfully 🚫", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
        } else if (flag == 5 && response.data.Table[0].Result === "UB") {
          toast.success("Consumption data unblocked successfully ✅", {
            duration: 5000,
            position: "top-center",
          });
          cancel();
        } else {
          toast.error("Failed to process Consumption data 👎", {
            duration: 2000,
            position: "top-center",
          });
        }
      } catch (error) {
        console.error("Error in IUDAPI request:", error);
        toast.error("An error occurred while processing the request 👎", {
          duration: 2000,
          position: "top-center",
        });
      }
    }
  };

  const handleConsumptionFileds = (value, name) => {
    setConsumptionFileds({
      ...consumptionFileds,
      [name]: value,
    });
  };

  const headerData = [
    {
      title: "Category",
      key: "consumptionCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "consumptionName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "consumptionBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch No.",
      key: "BatchNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date Of Expiry",
      key: "DateOfExpiry",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Demo.",
      key: "consumptionDemo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Damaged/Adjustments",
      key: "consumptionWaste",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Exp.",
      key: "ConsumptionExp",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    // {
    //   title: "Assoc. Clinic",
    //   key: "ConsumptionAssocClinic",
    //   width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    // },
    {
      title: "Total",
      key: "ConsumptionTotal",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  // useEffect(() => {
  //   const updatedData = tableData.map((row) => {
  //     const totalAge = ["demonstration", "damagedAdjustments", "expired"]
  //       .map((key) => parseFloat(row[key] || 0)) // Parse the value to float, default to 0 if undefined
  //       .reduce((acc, value) => acc + value, 0);
  //     return {
  //       ...row,
  //       total: totalAge,
  //     };
  //   });

  //   setTableData(updatedData); // Update the state with the new data
  //   convertArrayToJSONString(updatedData);
  // }, [tableData]);
  const getCurrentDateTimeISO = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`; // Format: YYYY-MM-DDTHH:mm:ss
  };
  useEffect(() => {
    // Only run the effect when `consumptionFileds.commodities` is available
    if (consumptionFileds.commodities) {
      const getFilteredCategory = bindCategoryDD
        .filter(
          (item) => item.MstCommodityTypeId == consumptionFileds.commodities
        )
        .map((items) => ({
          value: items.MstCommodityCategoryId,
          label: items.CommodityCategory_en,
        }));

      // Update the categoryList for each row in `tableData`
      if (Array.isArray(tableData)) {
        tableData.forEach((row) => {
          row.categoryList = getFilteredCategory;
        });
      }

      console.log("Updated category list", consumptionFileds.categoryList);
    }

    // Async function to update the table data
    const updateTableData = async () => {
      // Make sure `previousTableData` and `tableData` are different before updating
      if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
        const currentTableData = [...tableData];

        // Using a regular `for` loop to handle async operations inside it
        for (let row of currentTableData) {
          if (row?.category) {
            const selectedCommoditiesCategory = bindNameDD
              .filter((item) => item.MstCommodityCategoryId == row.category)
              .map((item) => ({
                value: item.MstCommoditiesId,
                label: item.CommodityName_en,
                uom: item.CommodityUOM,
              }));
            row.nameList = selectedCommoditiesCategory;

            const selectedCommodity = selectedCommoditiesCategory.find(
              (item) => item.value === row.name
            );
            row.unitOfMeasure = selectedCommodity ? selectedCommodity.uom : ""; // Reset if no selected commodity
          }

          if (row?.name) {
            const selectedBrandCategory = bindBrandDD
              .filter((item) => item.MstCommoditiesId == row.name)
              .map((items) => ({
                value: items.MstBrandId,
                label: items.Brand_en,
              }));
            row.brandList = selectedBrandCategory;
          }

          if (row?.batchNo) {
            debugger;
            const data = {
              mstCommodityType: consumptionFileds.commodities,
              mstCategoryId: row.category,
              mstCommodityId: row.name,
              mstBrandId: row.brand,
              batchNo: row.batchNo,
              mstOrganizationStructureId:
                userProfileDatas[0]?.MstOrganizationId,
              mstUserId: userProfileDatas[0]?.MstUserId,
              mstRoleId: userProfileDatas[0]?.MstRoleId,
              mstBranchProjectId: userProfileDatas[0]?.MstLocationId,
              mstSDPId: visibleSdp
                ? consumptionFileds.sdpId
                : consumptionFileds.sdp,
              mstAreaId:
                consumptionFileds.area !== "" ? consumptionFileds.area : null,
              mstORWId:
                userProfileDatas[0]?.MstRoleId === 7
                  ? userProfileDatas[0]?.MstUserId
                  : 0,
              txnTypeId: editDate ? consumptionFileds.consumptionNo : "",
              date: editDate ? editDate : getCurrentDateTimeISO(),
              flag: editDate ? 2 : 1,
            };

            try {
              // Make the API call
              const response = await BindPostAPI(
                [data],
                "InventoryIssuesAPI/BindAvailableCurrentStockBatchNoWise"
              );

              if (response?.data?.Table?.length > 0) {
                // Update the current stock based on API response
                row.currentStock =
                  response.data.Table[0]?.BalanceBatchNoQuantity ?? "";
                row.currentStockNew =
                  response.data.Table[0]?.BalanceBatchNoQuantity ?? "";
                const currentDate = response.data.Table[0].ExpiryDate;
                const formattedDate = currentDate
                  ? currentDate.split("T")[0]
                  : "";
                row.dateOfExpiry = formattedDate ?? "";
                console.log("Balance Batch Number Quantity:", row.currentStock);
              } else {
                console.warn("No data available for current stock.");
                row.currentStock = "";
              }
            } catch (error) {
              // Handle API errors
              console.error("Error fetching current stock:", error);
              row.currentStock = "";
            }
          }
          if (row?.demonstration || row?.damagedAdjustments || row?.expired) {
            const demonstration = parseFloat(row.demonstration) || 0;
            const damagedAdjustments = parseFloat(row.damagedAdjustments) || 0;
            const expired = parseFloat(row.expired) || 0;

            // Calculate the total
            let calculatedTotal = demonstration + damagedAdjustments + expired;
            row.total = calculatedTotal ? calculatedTotal : 0;
            row.currentStockNew =
              parseFloat(row.currentStock) - calculatedTotal;
            // Enforce the dynamic limit based on row.currentStock
            // const limit = parseFloat(row.currentStock) || 0; // Default to 0 if currentStock is undefined
            // row.total = calculatedTotal > limit ? limit : calculatedTotal; // Restrict total to the limit
          }
        }

        setTableData(currentTableData); // Update state after processing the rows
        convertArrayToJSONString(currentTableData);
      }
    };

    // Call the updateTableData function to update `tableData`
    updateTableData();
  }, [consumptionFileds.commodities, tableData]); // Dependencies: only trigger when these change

  const handleIssueBatchNoChange = async (rowIndex, selectedValue) => {
    debugger;
    // Copy the current table data for immutability
    const updatedTableData = [...tableData];

    // Access the specific row based on rowIndex
    const row = { ...updatedTableData[rowIndex] };

    // Update the batch number with the selected value
    row.batchNo = selectedValue;

    // Prepare the data payload for API call
    const data = {
      mstCommodityType: consumptionFileds.commodities,
      mstCategoryId: row.category,
      mstCommodityId: row.name,
      mstBrandId: row.brand,
      batchNo: row.batchNo,
      mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
      mstUserId: userProfileDatas[0]?.MstUserId,
      mstRoleId: userProfileDatas[0]?.MstRoleId,
      mstBranchProjectId: userProfileDatas[0]?.MstLocationId,
      mstSDPId: visibleSdp ? consumptionFileds.sdpId : consumptionFileds.sdp,
      mstAreaId: consumptionFileds.area !== "" ? consumptionFileds.area : null,
      mstORWId:
        userProfileDatas[0]?.MstRoleId === 7
          ? userProfileDatas[0]?.MstUserId
          : 0,
      txnTypeId: editDate ? consumptionFileds.consumptionNo : "",
      date: editDate ? editDate : getCurrentDateTimeISO(),
      flag: editDate ? 2 : 1,
    };

    try {
      // Make the API call
      const response = await BindPostAPI(
        [data],
        "InventoryIssuesAPI/BindAvailableCurrentStockBatchNoWise"
      );

      if (response?.data?.Table?.length > 0) {
        // Update the current stock based on API response
        row.currentStock = response.data.Table[0]?.BalanceBatchNoQuantity ?? "";
        row.currentStockNew =
          response.data.Table[0]?.BalanceBatchNoQuantity ?? "";
        const currentDate = response.data.Table[0].ExpiryDate;
        const formattedDate = currentDate ? currentDate.split("T")[0] : "";
        row.dateOfExpiry = formattedDate ?? "";
        console.log("Balance Batch Number Quantity:", row.currentStock);
      } else {
        console.warn("No data available for current stock.");
        row.currentStock = "";
      }
    } catch (error) {
      // Handle API errors
      console.error("Error fetching current stock:", error);
      row.currentStock = "";
    }

    // Update the row in the table data
    updatedTableData[rowIndex] = row;

    // Set the updated table data back to the state
    setTableData(updatedTableData);
    convertArrayToJSONString(updatedTableData);
  };
  const ConsumptionDropDownDatas = async () => {
    try {
      // Fetch all dropdown data concurrently
      const [
        locationResponse,
        sdpResponse,
        categoryResponse,
        nameResponse,
        brandResponse,
        categoryTypeResponse,
        areaResponse,
        orsNameResponse,
      ] = await Promise.all([
        BindAPI("LocationAPI/GetLocation"),
        BindAPI("SDPAPI/GetSDP"),
        BindAPI("CommoditiesAPI/GetCommoditiesCategory"),
        BindAPI("CommoditiesAPI/BindCommodities"),
        BindAPI("BrandAPI/BindBrand"),
        BindAPI("InventoryReceiptAPI/BindCommodityType"),
        BindAPI("AreaAPI/GetArea"),
        BindAPI("InventoryReceiptAPI/BindORSName"),
      ]);
  
      // Map data for dropdowns
      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));
  
      const sdpData = sdpResponse.data.Table.map((item) => ({
        value: item.MstSDPId,
        label: item.SDP_en,
      }));
  
      const categoryTypeData = categoryTypeResponse.data.Table.map((item) => ({
        value: item.MstCommodityTypeId,
        label: item.CommodityType_en,
      }));
  
      // Set data to state
      setBindBranchDD(locationData);
      setBindSdpPopUpDD(sdpData);
      setBindSdpDatas(sdpResponse.data.Table); // Keep original data for other uses
      setBindCategoryDD(categoryResponse.data.Table);
      setBindNameDD(nameResponse.data.Table);
      setBindBrandDD(brandResponse.data.Table);
      setBindAreaDatas(areaResponse.data.Table);
      setBindFieldWorkerName(orsNameResponse.data.Table);
      setBindCommoditiesDD(categoryTypeData);
    } catch (error) {
      console.error("Error fetching consumption dropdown data:", error);
    }
  };
  // const ConsumptionDropDownDatas = async () => {
  //   try {
  //     const locationResponse = await BindAPI("LocationAPI/GetLocation");
  //     const sdpResponse = await BindAPI("SDPAPI/GetSDP");
  //     setBindSdpDatas(sdpResponse.data.Table);
  //     const categoryResponse = await BindAPI(
  //       "CommoditiesAPI/GetCommoditiesCategory"
  //     );
  //     setBindCategoryDD(categoryResponse.data.Table);
  //     const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
  //     setBindNameDD(nameResponse.data.Table);
  //     const brandResponse = await BindAPI("BrandAPI/BindBrand");
  //     setBindBrandDD(brandResponse.data.Table);
  //     const categoryTypeResponse = await BindAPI(
  //       "InventoryReceiptAPI/BindCommodityType"
  //     );
  //     const areaResponse = await BindAPI("AreaAPI/GetArea");
  //     const orsNameResponse = await BindAPI("InventoryReceiptAPI/BindORSName");

  //     setBindAreaDatas(areaResponse.data.Table);
  //     const locationData = locationResponse.data.Table.map((item) => ({
  //       value: item.MstLocationId,
  //       label: item.LocationName_en,
  //     }));

  //     const sdpData = sdpResponse.data.Table.map((item) => ({
  //       value: item.MstSDPId,
  //       label: item.SDP_en,
  //     }));
  //     const categoryTypeData = categoryTypeResponse.data.Table.map((item) => ({
  //       value: item.MstCommodityTypeId,
  //       label: item.CommodityType_en,
  //     }));

  //     setBindFieldWorkerName(orsNameResponse.data.Table);
  //     setBindCommoditiesDD(categoryTypeData);
  //     setBindBranchDD(locationData);
  //     setBindSdpPopUpDD(sdpData);
  //   } catch (error) {}
  // };

  useEffect(() => {
    const sdpSelectedValue = bindSdpDatas.find(
      (item) => item.MstLocationId == consumptionFileds.branch
    );
    if (sdpSelectedValue) {
      const sdpLables = bindSdpDatas
        .filter((item) => item.MstLocationId == sdpSelectedValue.MstLocationId)
        .map((item) => ({
          MstSDPId: item.MstSDPId,
          SDP_en: item.SDP_en,
        }));
      const sdpSelected = sdpLables.map((SD) => ({
        value: SD.MstSDPId,
        label: SD.SDP_en,
      }));
      setBindSdpDD(sdpSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [consumptionFileds.branch]);

  const handleReceiptView = (rowIndex) => {
    const filterSessionId = bindConsumptionChildDatas.filter(
      (item) =>
        item.TxnConsumptionId ===
        bindConsumptionDatas[rowIndex].TxnConsumptionId
    );
    setConsumptionFileds({
      ...consumptionFileds,
      consumptionNo: bindConsumptionDatas[rowIndex].ConsumptionNo,
      commodities: bindConsumptionDatas[rowIndex].CommodityType_en,
    });
    setViewAction(true);
    setStudentData(filterSessionId);
  };

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = bindAreaDatas.find(
      (item) => item.MstSDPId == consumptionFileds.sdpId // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = bindAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));

      setBindAreaDD(areaSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [consumptionFileds.sdpId ? consumptionFileds.sdpId : null]);

  useEffect(() => {
    if (location.pathname == "/consumption") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      setViewAction(false);
      // BindConsumptionData(userProfileDatas);
      cancel();
    }
  }, [location, userProfileDatas]);
  const handleSdpDropdownChange = (e) => {
    setConsumptionFileds({
      ...consumptionFileds,
      sdp: e.target.value,
    });
  };
  console.log("mari11", tableData);
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Consumption"
          header={consumptionHeaders}
          records={bindConsumptionDatas}
          handleEdit={handleEdit}
          handleAction={HandleDetails}
          showAdd={showadd}
          handleSchoolView={handleReceiptView}
          blockView={true}
        />
      )}
      {viewAction && (
        <GridPage
          title={`Consumption > ${consumptionFileds.consumptionNo} > ${consumptionFileds.commodities}  `}
          header={ConsumptionViewHeaders}
          records={studentData}
          tableHeight="h-56"
          addView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Consumption"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form>
                  <div className={mainTable.grid5}>
                    <div>
                      <AutoDropDown
                        Class="p-0"
                        title="Commodities"
                        classStar="text-red-500"
                        options={bindCommoditiesDD}
                        placeholder="Select"
                        name="commodities"
                        selectedOption={bindCommoditiesDD.find(
                          (x) => x.value == consumptionFileds.commodities
                        )}
                        controlFunc={(value, e) => {
                          handleConsumptionFileds(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setConsumptionFiledsError({
                            ...consumptionFiledsError,
                            commoditiesError: consumptionFileds.commodities
                              ? ""
                              : `Select Commodities`,
                          });
                        }}
                        errorMessage={consumptionFiledsError.commoditiesError}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Consumption No."
                        name="ConsumptionNo"
                        maxlength={30}
                        disabled={true}
                        content={consumptionFileds.consumptionNo}
                        classStar="text-red-500"
                        placeholder="100"
                        controlFunc={(e) => {
                          setConsumptionFileds({
                            ...consumptionFileds,
                            consumptionNo: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="date"
                        title="Consumption Date"
                        name="consumptionDate"
                        classStar="text-red-500"
                        content={consumptionFileds.consumptionDate}
                        controlFunc={(e) => {
                          setConsumptionFileds({
                            ...consumptionFileds,
                            consumptionDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {/* <div>
                      <AutoDropDown
                        Class="p-0"
                        title="Branch"
                        classStar="text-red-500"
                        options={bindBranchDD}
                        placeholder="Select"
                        name="branch"
                        selectedOption={bindBranchDD.find(
                          (x) => x.value == consumptionFileds.branch
                        )}
                        controlFunc={(value, e) => {
                          handleConsumptionFileds(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setConsumptionFiledsError({
                            ...consumptionFiledsError,
                            branchError: consumptionFileds.branch
                              ? ""
                              : `Select Branch`,
                          });
                        }}
                        errorMessage={consumptionFiledsError.branchError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        Class="p-0"
                        title="S.D.P."
                        options={bindSdpDD}
                        name="sdp"
                        placeholder="Select"
                        selectedOption={bindSdpDD.find(
                          (x) => x.value == consumptionFileds.sdp
                        )}
                        controlFunc={(value, e) => {
                          handleConsumptionFileds(value.value, e.name);
                        }}
                      />
                    </div> */}
                    <InputText
                      inputType="text"
                      title="Branch"
                      name="branch"
                      disabled={true}
                      placeholder="Branch"
                      content={consumptionFileds.branch}
                      controlFunc={(e) =>
                        setConsumptionFileds({
                          ...consumptionFileds,
                          branch: e.target.value,
                        })
                      }
                    />
                    {visibleSdp && (
                      <InputText
                        inputType="text"
                        title="S.D.P."
                        name="sdp"
                        content={consumptionFileds.sdp}
                        placeholder=""
                        disabled={true}
                        controlFunc={(e) =>
                          setConsumptionFileds({
                            ...consumptionFileds,
                            sdp: e.target.value,
                          })
                        }
                      />
                    )}
                    {!visibleSdp && (
                      <DropDown
                        title="S.D.P."
                        options={sdp}
                        placeholder="Select"
                        name="sdp"
                        selectedOption={consumptionFileds.sdp}
                        controlFunc={handleSdpDropdownChange}
                      />
                    )}
                    <AutoDropDown
                      title="Area Name"
                      options={bindAreaDD}
                      name="area"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={
                        consumptionFileds.area
                          ? bindAreaDD.find(
                              (x) => x.value == consumptionFileds.area
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        setConsumptionFileds({
                          ...consumptionFileds,
                          area: value.value,
                        });
                      }}
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Outreach staff"
                      options={bindOutReachWorker}
                      name="mstOrwId"
                      placeholder="Select"
                      Class="custom-class"
                      selectedOption={
                        consumptionFileds.mstORWId
                          ? bindOutReachWorker.find(
                              (x) => x.value == consumptionFileds.mstORWId
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        setConsumptionFileds({
                          ...consumptionFileds,
                          mstORWId: value.value,
                        });
                      }}
                      disabled={true}
                    />
                    <div>
                      <InputText
                        inputType="date"
                        title="Session Date"
                        name="sessionDate"
                        content={consumptionFileds.sessionDate}
                        controlFunc={(e) => {
                          setConsumptionFileds({
                            ...consumptionFileds,
                            sessionDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Session Place"
                        name="sessionPlace"
                        maxlength={200}
                        content={consumptionFileds.sessionPlace}
                        placeholder="Session Place"
                        controlFunc={(e) => {
                          setConsumptionFileds({
                            ...consumptionFileds,
                            sessionPlace: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="overflow-y-none w-screen hidden md:block">
                      <table className="border border-gray-300  w-full">
                        <thead>
                          <tr>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                              Category
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                              Name
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                              Brand
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              U.O.M.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40">
                              Batch No.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                              Date Of Expiry
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                              Demo.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                              Damaged/Adjustments
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                              Exp.
                            </th>
                            {/* <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                              Assoc. Clinic
                            </th> */}
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                              Total
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-16">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={row.categoryList || []}
                                  placeholder="Select"
                                  name="Category"
                                  Class="p-0"
                                  selectedOption={
                                    row.categoryList && row.category
                                      ? row.categoryList.find(
                                          (x) => x.value === row.category
                                        )
                                      : null
                                  }
                                  controlFunc={(e) => {
                                    row.name = "";
                                    row.brand = "";
                                    handleInputChange(e, index, "category");
                                  }}
                                />
                              </td>
                              <td
                                key={index}
                                className="border border-gray-300 p-2"
                              >
                                <AutoDropDown
                                  options={row.nameList || []}
                                  name="name"
                                  placeholder="Select"
                                  selectedOption={
                                    row.nameList && row.name
                                      ? row.nameList.find(
                                          (x) => x.value === row.name
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    row.brand = "";
                                    handleInputChange(e, index, "name");
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <AutoDropDown
                                  options={row.brandList || []}
                                  name="brand"
                                  placeholder="Select"
                                  selectedOption={
                                    row.brandList && row.brand
                                      ? row.brandList.find(
                                          (x) => x.value === row.brand
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "brand");
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  content={row.unitOfMeasure}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "unitOfMeasure"
                                    );
                                  }}
                                  disabled="disabled"
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.batchNo}
                                  maxLength={30}
                                  controlFunc={(e) => {
                                    if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "batchNo"
                                      );
                                    }
                                  }}
                                  // controlFuncBlur={(e) => {
                                  //   handleIssueBatchNoChange(
                                  //     index,
                                  //     e.target.value
                                  //   );
                                  // }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <InputText
                                  inputType="date"
                                  content={row.dateOfExpiry}
                                  maxLength={5}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "dateOfExpiry"
                                    );
                                  }}
                                  min={new Date().toISOString().split("T")[0]}
                                />
                              </td>
                              <td className="border border-gray-300 p-2">
                                <div
                                  title={`Available Stock: ${
                                    row.currentStockNew || 0
                                  }`}
                                >
                                  <InputText
                                    type="text"
                                    content={row.demonstration}
                                    maxLength={5}
                                    controlFunc={(e) => {
                                      if (/^[0-9]*$/.test(e.target.value)) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "demonstration"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </td>

                              <td className="border border-gray-300 p-2">
                                <div
                                  title={`Available Stock: ${
                                    row.currentStockNew || 0
                                  }`}
                                >
                                  <InputText
                                    type="text"
                                    content={row.damagedAdjustments}
                                    maxLength={5}
                                    controlFunc={(e) => {
                                      if (/^[0-9]*$/.test(e.target.value)) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "damagedAdjustments"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="border border-gray-300 p-2">
                                <div
                                  title={`Available Stock: ${
                                    row.currentStockNew || 0
                                  }`}
                                >
                                  <InputText
                                    type="text"
                                    content={row.expired}
                                    maxLength={5}
                                    controlFunc={(e) => {
                                      if (/^[0-9]*$/.test(e.target.value)) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "expired"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              {/* <td className="border border-gray-300 p-2">
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.givenToAssociatedClinics}
                                  maxLength={5}
                                  controlFunc={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "givenToAssociatedClinics"
                                      );
                                    }
                                  }}
                                />
                              </td> */}
                              <td className="border border-gray-300 p-2">
                                <div
                                  title={`Available Stock: ${
                                    row.currentStock || 0
                                  }`}
                                >
                                  <InputText
                                    type="text"
                                    content={row.total}
                                    disabled={true}
                                    maxLength={5}
                                    controlFunc={(e) => {
                                      if (
                                        /^[0-9]*$/.test(e.target.value) &&
                                        (e.target.value === "" ||
                                          parseFloat(e.target.value) <=
                                            (row.currentStock || 0))
                                      ) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "total"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="border border-gray-300">
                                <div className="flex gap-4 align-baseline items-center justify-center">
                                  <svg
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    class="w-6 h-6 text-black cursor-pointer"
                                    onClick={() => handleDeleteRow(index)}
                                  >
                                    <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                  </svg>
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="currentColor"
                                    onClick={handleAddRow}
                                    class="w-6 h-6 text-black cursor-pointer"
                                  >
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                  </svg>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    {consumptionError && (
                      <div className="text-red-500">{consumptionError}</div>
                    )}
                  </div>

                  <div className={mainTable.tableMobile} role="table">
                    <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                      {tableData.length > 0 &&
                        tableData.map((row, index) => (
                          <div scope="col" class={mainTable.mobileTable}>
                            {headerData.map((keyData) => (
                              <div
                                scope="cell"
                                class={mainTable.tableData}
                                role="cell"
                              >
                                {keyData.key === "Action" ? (
                                  <div class={mainTable.tableData}>
                                    <div className="md:block hidden">
                                      <button
                                        id="dropdownMenuIconButton"
                                        data-dropdown-toggle="dropdownDots"
                                        class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                        type="button"
                                      >
                                        <svg
                                          class="w-6 h-6"
                                          aria-hidden="true"
                                          fill="currentColor"
                                          viewBox="0 0 20 20"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                    <div className="md:hidden flex justify-center">
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={handleAddRow}
                                        value="Add"
                                      />
                                      <input
                                        type="button"
                                        className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                        onClick={() => handleDeleteRow(index)}
                                        value="Delete"
                                      />
                                    </div>
                                  </div>
                                ) : keyData.key === "consumptionCategory" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.categoryList || []}
                                      placeholder="Select"
                                      name="Category"
                                      Class="p-0"
                                      selectedOption={
                                        row.categoryList && row.category
                                          ? row.categoryList.find(
                                              (x) => x.value === row.category
                                            )
                                          : null
                                      }
                                      controlFunc={(e) => {
                                        row.name = "";
                                        row.brand = "";
                                        handleInputChange(e, index, "category");
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "consumptionName" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.nameList || []}
                                      name="name"
                                      placeholder="Select"
                                      selectedOption={
                                        row.nameList && row.name
                                          ? row.nameList.find(
                                              (x) => x.value === row.name
                                            )
                                          : null
                                      }
                                      Class="p-0"
                                      controlFunc={(e) => {
                                        row.brand = "";
                                        handleInputChange(e, index, "name");
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "consumptionBrand" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full font-bold">
                                      {keyData.title}
                                    </div>
                                    <AutoDropDown
                                      options={row.brandList || []}
                                      name="brand"
                                      placeholder="Select"
                                      selectedOption={
                                        row.brandList && row.brand
                                          ? row.brandList.find(
                                              (x) => x.value === row.brand
                                            )
                                          : null
                                      }
                                      Class="p-0"
                                      controlFunc={(e) => {
                                        handleInputChange(e, index, "brand");
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "commoditiesUOM" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.unitOfMeasure}
                                      disabled="disabled"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "unitOfMeasure"
                                        );
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "BatchNumber" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.batchNo}
                                      maxLength={30}
                                      controlFunc={(e) => {
                                        if (
                                          /^[a-zA-Z0-9]*$/.test(e.target.value)
                                        ) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "batchNo"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "DateOfExpiry" ? (
                                  <div className=" border p-1">
                                    <div className="flex items-center w-full whitespace-nowrap font-bold">
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      inputType="date"
                                      content={row.dateOfExpiry}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "dateOfExpiry"
                                        );
                                      }}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </div>
                                ) : keyData.key === "consumptionDemo" ? (
                                  <div className=" border p-1">
                                    <div
                                      className="flex items-center w-full whitespace-nowrap font-bold"
                                      title={`Available Stock: ${
                                        row.currentStockNew || 0
                                      }`}
                                    >
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.demonstration}
                                      maxLength={5}
                                      controlFunc={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "demonstration"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "consumptionWaste" ? (
                                  <div className=" border p-1">
                                    <div
                                      className="flex items-center w-full whitespace-nowrap font-bold"
                                      title={`Available Stock: ${
                                        row.currentStockNew || 0
                                      }`}
                                    >
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.damagedAdjustments}
                                      maxLength={5}
                                      controlFunc={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "damagedAdjustments"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : keyData.key === "ConsumptionExp" ? (
                                  <div className=" border p-1">
                                    <div
                                      className="flex items-center w-full whitespace-nowrap font-bold"
                                      title={`Available Stock: ${
                                        row.currentStockNew || 0
                                      }`}
                                    >
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.expired}
                                      maxLength={5}
                                      controlFunc={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "expired"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : //  : keyData.key === "ConsumptionAssocClinic" ? (
                                //   <div className=" border p-1">
                                //     <div className="flex items-center w-full whitespace-nowrap font-bold">
                                //       {keyData.title}
                                //     </div>
                                //     <InputText
                                //       type="text"
                                //       placeholder="100"
                                //       content={row.givenToAssociatedClinics}
                                //       maxLength={5}
                                //       controlFunc={(e) => {
                                //         if (/^[0-9]*$/.test(e.target.value)) {
                                //           handleInputChange(
                                //             e.target,
                                //             index,
                                //             "givenToAssociatedClinics"
                                //           );
                                //         }
                                //       }}
                                //     />
                                //   </div>
                                // )
                                keyData.key === "ConsumptionTotal" ? (
                                  <div className=" border p-1">
                                    <div
                                      className="flex items-center w-full whitespace-nowrap font-bold"
                                      title={`Available Stock: ${
                                        row.currentStockNew || 0
                                      }`}
                                    >
                                      {keyData.title}
                                    </div>
                                    <InputText
                                      type="text"
                                      content={row.total}
                                      maxLength={5}
                                      controlFunc={(e) => {
                                        if (/^[0-9]*$/.test(e.target.value)) {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "total"
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="flex border p-1">
                                    <div className="flex basis-1/2 font-bold">
                                      {keyData.title}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="md:hidden">
                    {consumptionError && (
                      <div className="text-red-500">{consumptionError}</div>
                    )}
                  </div>
                  <TextArea
                    inputType="text"
                    title="Reason"
                    name="reason"
                    rows="4"
                    maxlength={500}
                    content={consumptionFileds.reason}
                    placeholder="Enter Reason"
                    controlFunc={(e) => {
                      setConsumptionFileds({
                        ...consumptionFileds,
                        reason: e.target.value,
                      });
                    }}
                  />
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      Consumption No. :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {consumptionFileds.consumptionNo}
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleDetails}
      >
        <div>
          <PopUpScreenTitle
            title="Consumption"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <ConsumptionPopup
            data={consumptionPopUpDatas}
            data1={dropdownPopUpDatas}
            data2={consumptionChildPopUpDatas}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default Consumption;
