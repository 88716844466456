// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import { servicesMasterHeaders } from "../components/HeaderAttribute";
import Services from "../popup-containers/Services";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Checkbox from "../ReusableComponents/Checkbox";
import RadioGroup from "../ReusableComponents/RadioButton";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const ServicesMaster = () => {
  const location = useLocation();
  //All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // Services Master insert and edit useState hooks handling .
  const [mstServiceId, setMstServiceId] = useState("");

  const [servicesMasterFields, setServicesMasterFields] = useState({
    category: "",
    subCategory: "",
    services: "",
    itemsProvided: "",
    testResultType: "",
    serviceProvision: "",
    resultUom: "",
    renewalSupply: "",
    charges: "",
    PACServices: "",
    cyp: "",
    FPItemProvided: "",
    FPItemProvidedValue: "",
    FPItemProvidedMstCatId: "",
    CYPConversionFactor: "",
    FPDropout: "",
    pac: "",
    abortionClients: "",
  });

  const [servicesMasterFieldsError, setServicesMasterFieldsError] = useState({
    categoryError: "",
    subCategoryError: "",
    servicesError: "",
    // resultUomError: "",
    chargesError: "",
  });

  // Bind dropdown,Radio button value and bind data value useState hooks handling.
  const [bindSubCategoryDD, setBindSubCategoryDD] = useState([]);
  const [bindSubCategoryDD1, setBindSubCategoryDD1] = useState([]);
  const [bindCategoryDD, setBindCategoryDD] = useState([]);
  const [bindServices, setBindServices] = useState([]);
  const [popupDatas, setPopupDatas] = useState([]);
  const [testResultTypeDD, setTestResultTypeDD] = useState([]);
  const [testPopup, setTestPopup] = useState({});
  const [servicesData, setServicesData] = useState("");
  const [itemsprovidedDD, setItemsProvidedDD] = useState([
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ]);
  const [renewalSupplyDD, setRenewalSupplyDD] = useState([
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ]);
  const [serviceProvisionDD, setServiceProvisionDD] = useState([]);
  const [bindTestResultUOM, setBindTestResultUOM] = useState([]);

  const [bindPACServices, setBindPACServices] = useState([]);
  const [bindFPDropout, setBindFPDropout] = useState([]);
  const [bindCYP, setBindCYP] = useState([]);
  const [bindFPItemProvided, setBindFPItemProvided] = useState([]);
  const [bindPAC, setBindPAC] = useState([]);
  const [bindAbortionClients, setBindAbortionClients] = useState([]);

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [rowIndex, setRowIndex] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [getSubCategory, setGetSubCategory] = useState([]);
  const [editMode, setEditMode] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const handleTestResultChange = (updatedOptions) => {
    setServicesMasterFields({
      ...servicesMasterFields,
      testResultType: updatedOptions,
    });
  };
  const handleRenewalSupplyChange = (updatedOptions) => {
    setServicesMasterFields({
      ...servicesMasterFields,
      renewalSupply: updatedOptions,
    });
  };

  const handleItemsProvidedChange = (updatedOptions) => {
    setServicesMasterFields({
      ...servicesMasterFields,
      itemsProvided: updatedOptions,
    });
  };
  const serviceProvisionhandleCheckboxChange = (updatedOptions) => {
    setServicesMasterFields({
      ...servicesMasterFields,
      serviceProvision: updatedOptions,
    });
  };

  // This function is helped to edit the dropdown value via rowindex passing for Resources.
  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
    }
  };
  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // This is Services grid page add button visible handling functionality .
  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setServicesMasterFields({
      category: "",
      subCategory: "",
      services: "",
      itemsProvided: 2,
      testResultType: 3,
      serviceProvision: "",
      resultUom: "",
      renewalSupply: 2,
      charges: "",
    });
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    servicesCategoryData();
    setEditMode(false);
    setRowIndex("");
    setBindSubCategoryDD([]);
    setServicesMasterFields({
      category: "",
      subCategory: "",
      services: "",
      itemsProvided: "",
      testResultType: "",
      serviceProvision: "",
      resultUom: "",
      renewalSupply: "",
      charges: "",
      PACServices: "",
      cyp: "",
      FPItemProvided: "",
      FPItemProvidedValue: "",
      FPItemProvidedMstCatId: "",
      CYPConversionFactor: "",
      FPDropout: "",
      pac: "",
      abortionClients: "",
    });

    setServicesMasterFieldsError({
      categoryError: "",
      subCategoryError: "",
      servicesError: "",
      // resultUomError: "",
      chargesError: "",
    });
  };

  // This function is helped to edit the input value via rowindex passing and
  // helping to visible handling for Services .
  const handleEdit = (rowIndex) => {
    debugger;
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    const ServiceProvision = bindServices[rowIndex].ServiceProvision;
    let ServiceProvisionString;
    if (ServiceProvision) {
      ServiceProvisionString = ServiceProvision.split(",").map((value) =>
        parseInt(value.trim(), 10)
      );
    } else {
      ServiceProvisionString = []; // Or any default value you prefer
    }

    setServicesMasterFields({
      ...servicesMasterFields,
      category: bindServices[rowIndex].MstServicesCategoryId,
      subCategory: bindServices[rowIndex].MstServicesSubCategoryId,
      services: bindServices[rowIndex].Services_en,
      itemsProvided: bindServices[rowIndex].ItemsProvided,
      testResultType: bindServices[rowIndex].TestResultType,
      serviceProvision: ServiceProvisionString,
      resultUom: bindServices[rowIndex].ResultUOM,
      renewalSupply: bindServices[rowIndex].RenewalSupply,
      charges: bindServices[rowIndex].Charges,
      FPItemProvidedMstCatId:bindServices[rowIndex].MstCommodityCategoryId,
      FPItemProvidedValue:bindServices[rowIndex].FPItemProvidedId,
      // PACServices: bindServices[rowIndex].PACServices,
      cyp: bindServices[rowIndex].CYP,
      FPItemProvided: bindServices[rowIndex].FPItemProvided_en,
      CYPConversionFactor: bindServices[rowIndex].CYPConversionFactor,
      FPDropout: bindServices[rowIndex].FPDropout,
      pac: bindServices[rowIndex].PAC,
      abortionClients: bindServices[rowIndex].AbortionClients_en,
    });
    setMstServiceId(bindServices[rowIndex].MstServicesId);
  };

  // This function is helped to the viwe, delete, block and unblock
  // input value via rowindex passing for Services .
  const handleServices = async (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setServicesMasterFields({
      ...servicesMasterFields,
      category: bindServices[rowIndex].MstServicesCategoryId,
    });
    const TestResultType = bindServices[rowIndex].TestResultType
      ? bindServices[rowIndex].TestResultType
      : "";

    const ServiceProvision = bindServices[rowIndex].ServiceProvision;
    const ServiceProvisionString = ServiceProvision.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );

    //Category DropDown Value Start
    const categoryRowData = bindServices[rowIndex];
    const categoryInfo = bindCategoryDD.find(
      (L) => L.value === categoryRowData.MstServicesCategoryId
    );
    const ResultCategory = {
      value: categoryRowData.MstServicesCategoryId,
      label: categoryInfo ? categoryInfo.label : "Unknown",
    };

    //Category DropDown Value End
    //Sub-Category DropDown Value Start
    const subCategoryRowData = bindServices[rowIndex];
    const subCategoryInfo = bindSubCategoryDD1.find(
      (item) => item.value === subCategoryRowData.MstServicesSubCategoryId
    );
    const ResultSubCategory = {
      value: subCategoryRowData.MstServicesSubCategoryId,
      label: subCategoryInfo ? subCategoryInfo.label : "Unknown",
    };
    //Sub-Category DropDown Value End
    const PopupData = [
      TestResultType,
      ResultCategory,
      ResultSubCategory,
      ServiceProvisionString,
    ];
    const PopupData1 = [testResultTypeDD, serviceProvisionDD];
    setPopupDatas(PopupData);
    setTestPopup(PopupData1);
    setServicesData(bindServices[rowIndex]);
  };
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (servicesMasterFields.category == "") {
        errors.category = "Select Category";
      }
      if (servicesMasterFields.subCategory == "") {
        errors.subCategory = "Select Sub-Category";
      }
      if (servicesMasterFields.services.trim() === "") {
        errors.services = "Enter Services";
      }
      // if (servicesMasterFields.resultUom.trim() === "") {
      //   errors.resultUom = "Enter Result UOM";
      // }

      if (servicesMasterFields.charges === "") {
        errors.charges = "Enter Charges";
      } else if (!/^\d*\.?\d*$/.test(servicesMasterFields.charges)) {
        errors.charges = "Only Decimal or Integer Values Allowed";
      }
    }

    setServicesMasterFieldsError({
      ...servicesMasterFieldsError,
      categoryError: errors.category || "",
      subCategoryError: errors.subCategory || "",
      servicesError: errors.services || "",
      // resultUomError: errors.resultUom || "",
      chargesError: errors.charges || "",
    });

    return errors;
  };
  // This function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API
  // response for those all and passing the rowindex for Services .
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    const firstNameValidation = validate(flag);

    if (Object.keys(firstNameValidation).length !== 0) {
      setIsOpen(true);
    }

    if (Object.keys(firstNameValidation).length === 0) {
      setIsOpen(false);
      if (flag == 1 || flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            servicesMasterFields.services,
            i
          );
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const FPItemProvidedTexts = [];
        if (servicesMasterFields.FPItemProvided) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(
              servicesMasterFields.FPItemProvided,
              i
            );
            FPItemProvidedTexts.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }
        const AbortionClientsTexts = [];
        if (servicesMasterFields.abortionClients) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(
              servicesMasterFields.abortionClients,
              i
            );
            AbortionClientsTexts.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }
        // const resultserviceprovision = serviceProvision.join(",");
        var resultserviceProvision;
        if (Array.isArray(servicesMasterFields.serviceProvision)) {
          resultserviceProvision =
            servicesMasterFields.serviceProvision.join(",");
        } else {
          // If serviceProvision is not an array, insert an empty string
          resultserviceProvision = "";
        }

        const data = {
          mstServicesId: mstServiceId ? mstServiceId : 0,
          mstServicesCategoryId: servicesMasterFields.category,
          mstServicesSubCategoryId: servicesMasterFields.subCategory,
          servicesEn: servicesMasterFields.services,
          servicesTa: translatedTexts[0],
          servicesHi: translatedTexts[1],
          servicesMr: translatedTexts[2],
          servicesGu: translatedTexts[3],
          servicesKn: translatedTexts[4],
          servicesMl: translatedTexts[5],
          servicesTe: translatedTexts[6],
          servicesBn: translatedTexts[7],
          servicesOr: translatedTexts[8],
          itemsProvided: servicesMasterFields.itemsProvided,
          testResultType: servicesMasterFields.testResultType,
          serviceProvision: resultserviceProvision,
          resultUOM: servicesMasterFields.resultUom,
          renewalSupply: servicesMasterFields.renewalSupply,
          charges: servicesMasterFields.charges,
          // pacServices: servicesMasterFields.PACServices,
          fpDropout: servicesMasterFields.FPDropout,
          cyp: servicesMasterFields.cyp,
          fpItemProvidedId: servicesMasterFields.FPItemProvidedValue ? servicesMasterFields.FPItemProvidedValue : null,
          mstCommodityCategoryId: servicesMasterFields.FPItemProvidedMstCatId ? servicesMasterFields.FPItemProvidedMstCatId : null,
          fpItemProvidedEn: servicesMasterFields.FPItemProvided,
          fpItemProvidedTa: FPItemProvidedTexts[0],
          fpItemProvidedHi: FPItemProvidedTexts[1],
          fpItemProvidedMr: FPItemProvidedTexts[2],
          fpItemProvidedGu: FPItemProvidedTexts[3],
          fpItemProvidedKn: FPItemProvidedTexts[4],
          fpItemProvidedMl: FPItemProvidedTexts[5],
          fpItemProvidedTe: FPItemProvidedTexts[6],
          fpItemProvidedBn: FPItemProvidedTexts[7],
          fpItemProvidedOr: FPItemProvidedTexts[8],
          cypConversionFactor: servicesMasterFields.CYPConversionFactor,
          pac: servicesMasterFields.pac,
          abortionClientsEn: servicesMasterFields.abortionClients,
          abortionClientsTa: AbortionClientsTexts[0],
          abortionClientsHi: AbortionClientsTexts[1],
          abortionClientsMr: AbortionClientsTexts[2],
          abortionClientsGu: AbortionClientsTexts[3],
          abortionClientsKn: AbortionClientsTexts[4],
          abortionClientsMl: AbortionClientsTexts[5],
          abortionClientsTe: AbortionClientsTexts[6],
          abortionClientsBn: AbortionClientsTexts[7],
          abortionClientsOr: AbortionClientsTexts[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "ServicesMasterAPI/IUDServices");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Services added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Services updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        const data = {
          mstServicesId: bindServices[items].MstServicesId,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "ServicesMasterAPI/IUDServices");
        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success(
              "Services data deleted successfully 🗑️",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to delete Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success(
              "Services data blocked successfully 🚫",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to block Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success(
              "Services data unblocked successfully ✅",
              5000,
              cancel()
            );
            cancel();
          } else {
            toast.error("Failed to unblock Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  // This hook function is helped to bind dropdown value and bind all data
  //from Services master table.
  useEffect(() => {
    servicesCategoryData();
    bindDrpRadioData();
  }, []);
  const servicesCategoryData = async () => {
    const response = await BindAPI("ServicesMasterAPI/BindServices");
    setBindServices(response.data.Table);
  };

  useEffect(() => {
    const bindSubCategoryDatas = async () => {
      const response = await BindAPI(
        "ServicesSubCategoryAPI/BindServicesSubCategory"
      );
      setGetSubCategory(response.data.Table);
      const subCategoryData = response.data.Table.map((m) => ({
        value: m.MstServicesSubCategoryId,
        label: m.ServicesSubCategory_en,
      }));
      setBindSubCategoryDD1(subCategoryData);
    };
    bindSubCategoryDatas();
  }, []);

  useEffect(() => {
    const subCategorySelectedValue = getSubCategory.find(
      (item) => item.MstServicesCategoryId == servicesMasterFields.category
    );
    if (subCategorySelectedValue) {
      const subCategoryLabels = getSubCategory
        .filter(
          (item) =>
            item.MstServicesCategoryId ===
            subCategorySelectedValue.MstServicesCategoryId
        )
        .map((item) => ({
          MstServicesSubCategoryId: item.MstServicesSubCategoryId,
          ServicesSubCategory_en: item.ServicesSubCategory_en,
        }));
      const subCategorySelected = subCategoryLabels.map((LC) => ({
        value: LC.MstServicesSubCategoryId, // Replace with the appropriate value
        label: LC.ServicesSubCategory_en,
      }));
      setBindSubCategoryDD(subCategorySelected);
    } else {
    }
  }, [servicesMasterFields.category]);

  //Category DropDown Value bind in master table.
  const bindDrpRadioData = async () => {
    const bindCategoryDataDrp = await BindAPI(
      "ServicesCategoryAPI/BindServicesCategory"
    );
    const categoryDD =
      bindCategoryDataDrp.data.Table.length > 0 &&
      bindCategoryDataDrp.data.Table.map((m) => ({
        value: m.MstServicesCategoryId,
        label: m.ServicesCategory_en,
      }));
    setBindCategoryDD(categoryDD);

    //Result Type Radio button Value bind in master table.
    const bindResultTypeDataRadio = await BindAPI(
      "ServicesMasterAPI/BindResultType"
    );
    const ResultTypeRadioDD =
      bindResultTypeDataRadio.data.Table.length > 0 &&
      bindResultTypeDataRadio.data.Table.map((m) => ({
        value: m.MstResultType,
        label: m.ResultType,
      }));
    setTestResultTypeDD(ResultTypeRadioDD);

    //Result Type Radio button Value bind in master table.
    const bindServiceProvision = await BindAPI(
      "ServicesMasterAPI/BindServiceProvision"
    );
    const ServiceProvisionDD =
      bindServiceProvision.data.Table.length > 0 &&
      bindServiceProvision.data.Table.map((m) => ({
        value: m.MstServiceProvisionId,
        label: m.ServiceProvision,
      }));
    setServiceProvisionDD(ServiceProvisionDD);

    const bindServicesDropDownsData = await BindAPI(
      "ServicesMasterAPI/BindServicesDropDowns"
    );
    const bindTestResultUOMDD =
      bindServicesDropDownsData.data.Table.length > 0 &&
      bindServicesDropDownsData.data.Table.map((m) => ({
        value: m.MstTestResultUoMId,
        label: m.TestResultUoM,
      }));
    setBindTestResultUOM(bindTestResultUOMDD);

    const bindPACServicesDD =
      bindServicesDropDownsData.data.Table1.length > 0 &&
      bindServicesDropDownsData.data.Table1.map((m) => ({
        value: m.MstPACServicesId,
        label: m.PACServices,
      }));
    setBindPACServices(bindPACServicesDD);

    const bindFPDropout =
      bindServicesDropDownsData.data.Table2.length > 0 &&
      bindServicesDropDownsData.data.Table2.map((m) => ({
        value: m.MstFPDropoutId,
        label: m.FPDropout,
      }));
    setBindFPDropout(bindFPDropout);

    const bindCYP =
      bindServicesDropDownsData.data.Table3.length > 0 &&
      bindServicesDropDownsData.data.Table3.map((m) => ({
        value: m.MstCYPId,
        label: m.CYP,
      }));
    setBindCYP(bindCYP);

    const bindFPItemProvided =
      bindServicesDropDownsData.data.Table4.length > 0 &&
      bindServicesDropDownsData.data.Table4.map((m) => ({
        value: m.MstCommoditiesId,
        label: m.CommodityName_en,
        MstCatId: m.MstCommodityCategoryId,
      }));
    setBindFPItemProvided(bindFPItemProvided);

    const bindPAC =
      bindServicesDropDownsData.data.Table5.length > 0 &&
      bindServicesDropDownsData.data.Table5.map((m) => ({
        value: m.MstPACId,
        label: m.PAC,
      }));
    setBindPAC(bindPAC);

    const bindAbortionClients =
      bindServicesDropDownsData.data.Table6.length > 0 &&
      bindServicesDropDownsData.data.Table6.map((m) => ({
        value: m.MstAbortionClientsId,
        label: m.AbortionClients_en,
      }));
    setBindAbortionClients(bindAbortionClients);
  };
  const handleUserFieldsChange = (value, name) => {
    setServicesMasterFields({
      ...servicesMasterFields,
      [name]: value,
    });
  };
  useEffect(() => {
    if (location.pathname == "/servicesmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      servicesCategoryData();
      cancel();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Services Master"
          header={servicesMasterHeaders}
          records={bindServices}
          handleEdit={handleEdit}
          handleAction={handleServices}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Services Master "
          />
          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form className={mainTable.formForClientMaster}>
                <div className={mainTable.grid3}>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title="Category"
                      options={bindCategoryDD}
                      placeholder="Select"
                      name="category"
                      selectedOption={bindCategoryDD.find(
                        (x) => x.value == servicesMasterFields.category
                      )}
                      controlFunc={(value, e) => {
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          subCategory: "",
                        });
                        handleUserFieldsChange(value.value, e.name);
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          category: value.value,
                          subCategory: "",
                        });
                      }}
                      controlFuncBlur={() => {
                        // setServicesMasterFields({
                        //   ...servicesMasterFields,
                        //   subCategory: "",
                        // });
                        setServicesMasterFieldsError({
                          ...servicesMasterFieldsError,
                          categoryError: servicesMasterFields.category
                            ? ""
                            : "Select Category",
                        });
                      }}
                      Class="custom-class"
                      tabIndex="1"
                      errorMessage={servicesMasterFieldsError.categoryError}
                    />
                  </div>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title="Sub-Category"
                      options={bindSubCategoryDD}
                      placeholder="Select"
                      name="subCategory"
                      selectedOption={
                        servicesMasterFields.subCategory
                          ? bindSubCategoryDD.find(
                              (x) => x.value == servicesMasterFields.subCategory
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          subCategory: "",
                        });
                        handleUserFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setServicesMasterFieldsError({
                          ...servicesMasterFieldsError,
                          subCategoryError: servicesMasterFields.subCategory
                            ? ""
                            : "Select Sub-Category",
                        });
                      }}
                      Class="custom-class"
                      tabIndex="1"
                      errorMessage={servicesMasterFieldsError.subCategoryError}
                    />
                  </div>
                  <div>
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Services"}
                      name={"Services"}
                      content={servicesMasterFields.services}
                      controlFunc={(e) =>
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          services: e.target.value,
                        })
                      }
                      controlFuncBlur={(e) => {
                        setServicesMasterFieldsError(
                          (servicesMasterFieldsError) => ({
                            ...servicesMasterFieldsError,
                            servicesError:
                              servicesMasterFields.services.length > 0
                                ? ""
                                : " Enter Services",
                          })
                        );
                      }}
                      errorMessage={servicesMasterFieldsError.servicesError}
                    />
                  </div>
                  <div>
                    <RadioGroup
                      classStar="text-red-500"
                      title="Items Provided"
                      type="radio"
                      setName="itemsProvided"
                      selectedOptions={servicesMasterFields.itemsProvided}
                      options={itemsprovidedDD}
                      controlFunc={handleItemsProvidedChange}
                      disabled={false}
                    />
                  </div>
                  <div className="col-span-2">
                    <RadioGroup
                      classStar="text-red-500"
                      title="Test Result Type ?"
                      type="radio"
                      setName="testResultType"
                      selectedOptions={servicesMasterFields.testResultType}
                      options={testResultTypeDD}
                      controlFunc={handleTestResultChange}
                      disabled={false}
                    />
                  </div>
                  <div className="col-span-3">
                    <Checkbox
                      title="Service Provision"
                      type="Checkbox"
                      setName="serviceProvision"
                      selectedOptions={servicesMasterFields.serviceProvision}
                      options={serviceProvisionDD}
                      controlFunc={serviceProvisionhandleCheckboxChange}
                      disabled={false}
                      tabIndex="0"
                    />
                  </div>
                  <div>
                    <AutoDropDown
                      title="Result UOM"
                      options={bindTestResultUOM}
                      name="areaName"
                      placeholder="Select"
                      selectedOption={bindTestResultUOM.find(
                        (x) => x.label == servicesMasterFields.resultUom
                      )}
                      Class="custom-class"
                      // classStar="text-red-500"
                      controlFunc={(value) => {
                        console.log("value", value);
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          resultUom: value.label,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <RadioGroup
                      classStar="text-red-500"
                      title="Renewal Supply"
                      type="radio"
                      setName="renewalSupply"
                      selectedOptions={servicesMasterFields.renewalSupply}
                      options={renewalSupplyDD}
                      controlFunc={handleRenewalSupplyChange}
                      disabled={false}
                    />
                  </div>
                  <div>
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Charges"}
                      name={"charges"}
                      maxLength={8}
                      content={servicesMasterFields.charges}
                      controlFunc={(e) => {
                        const value = e.target.value;
                        // Regular expression to match decimal numbers
                        const decimalPattern = /^\d{0,5}(\.\d{0,2})?$/;
                        if (decimalPattern.test(value)) {
                          setServicesMasterFields({
                            ...servicesMasterFields,
                            charges: value,
                          });
                        }
                      }}
                      controlFuncBlur={(e) => {
                        setServicesMasterFieldsError(
                          (servicesMasterFieldsError) => ({
                            ...servicesMasterFieldsError,
                            chargesError:
                              servicesMasterFields.charges.length > 0
                                ? /^\d*\.?\d*$/.test(
                                    servicesMasterFields.charges
                                  )
                                  ? ""
                                  : "Only Decimal or Integer Values Allowed"
                                : "Enter Charges",
                          })
                        );
                      }}
                      errorMessage={servicesMasterFieldsError.chargesError}
                    />
                  </div>
                  {/* <AutoDropDown
                    title="PAC -Services"
                    options={bindPACServices}
                    name="areaName"
                    placeholder="Select"
                    selectedOption={bindPACServices.find(
                      (x) => x.label == servicesMasterFields.PACServices
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        PACServices: value.label,
                      });
                    }}
                  /> */}

                  <AutoDropDown
                    title="PAC"
                    options={bindPAC}
                    name="PAC"
                    placeholder="Select"
                    selectedOption={bindPAC.find(
                      (x) => x.label == servicesMasterFields.pac
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        pac: value.label,
                      });
                    }}
                  />

                  <AutoDropDown
                    title="FP Dropout"
                    options={bindFPDropout}
                    name="areaName"
                    placeholder="Select"
                    selectedOption={bindFPDropout.find(
                      (x) => x.label == servicesMasterFields.FPDropout
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        FPDropout: value.label,
                      });
                    }}
                  />

                  <AutoDropDown
                    title="CYP"
                    options={bindCYP}
                    name="areaName"
                    placeholder="Select"
                    selectedOption={bindCYP.find(
                      (x) => x.label == servicesMasterFields.cyp
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        cyp: value.label,
                      });
                    }}
                  />
                  <InputText
                    inputType={"number"}
                    title={"CYP Conversion Factor"}
                    name={"CYPConversionFactor"}
                    maxLength={10}
                    content={servicesMasterFields.CYPConversionFactor}
                    controlFunc={(e) => {
                      const value = e.target.value;
                      // Regular expression to match decimal numbers
                      const decimalPattern = /^\d*\.?\d*$/;
                      if (decimalPattern.test(value)) {
                        // Update state only if it's a valid decimal
                        setServicesMasterFields({
                          ...servicesMasterFields,
                          CYPConversionFactor: value,
                        });
                      }
                    }}
                  />
                  <AutoDropDown
                    title="FP Item Provided"
                    options={bindFPItemProvided}
                    name="FPItemProvided"
                    placeholder="Select"
                    selectedOption={bindFPItemProvided.find(
                      (x) => x.label == servicesMasterFields.FPItemProvided
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        FPItemProvided: value.label,
                        FPItemProvidedValue: value.value,
                        FPItemProvidedMstCatId: value.MstCatId,
                      });
                    }}
                  />

                  <AutoDropDown
                    title="Abortion Clients"
                    options={bindAbortionClients}
                    name="abortionClients"
                    placeholder="Select"
                    selectedOption={bindAbortionClients.find(
                      (x) => x.label == servicesMasterFields.abortionClients
                    )}
                    Class="custom-class"
                    controlFunc={(value) => {
                      setServicesMasterFields({
                        ...servicesMasterFields,
                        abortionClients: value.label,
                      });
                    }}
                  />
                </div>
              </form>
              <Buttons
                addScreenTitle={addScreenTitle}
                updateScreenTitle={updateScreenTitle}
                handleSubmit={handleSubmit}
                cancel={cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleServices}
      >
        <div>
          <PopUpScreenTitle
            title="Services Master"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <Services data={servicesData} data1={popupDatas} data2={testPopup} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default ServicesMaster;
