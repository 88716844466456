
import React, { useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/Table";
import { SRHCommoditiesMasterHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import UserDetails from "../popup-containers/UserDetails";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";

function SRHCommodities() {
  const [bindCategoryDD, setBindCategoryDD] = useState([
    { value: "1", label: "Education Meterial" },
    { value: "2", label: "Capsules" },
    { value: "3", label: "Drops" },
    { value: "4", label: "Inhalers" },
  ]);
  const [bindServiceDD, setBindServiceDD] = useState([
    { value: "1", label: "Online" },
    { value: "2", label: "Offline" },
  ]);

  const [bindSRHCommoditiesDatas, setBindSRHCommoditiesDatas] = useState([
    {
      sno: 1,
      category: "Education Meterial",
      serviceList: "Online",
      commodities: "cotton",
      description: " A collective term to include pharmaceuticals, consumable medical supplies, and durable medical equipment.",
      unitOfMeasure: "mg",
    },
    {
      sno: 2,
      category: "Capsules",
      serviceList: "Offline",
      commodities: "sugar",
      description: "A collective term to include pharmaceuticals, consumable medical supplies, and durable medical equipment.",
      unitOfMeasure: "Kg",
    },
    {
      sno: 3,
      category: "Drops",
      serviceList: "Online",
      commodities: "gas",
      description: "A collective term to include pharmaceuticals, consumable medical supplies, and durable medical equipment.",
      unitOfMeasure: "g",
      
    },
    {
      sno: 4,
      category: "Inhalers",
      serviceList: "Offline",
      commodities: "oil ",
      description: "A collective term to include pharmaceuticals, consumable medical supplies, and durable medical equipment.",
      unitOfMeasure: "Mg",
     
    },
    {
      sno: 5,
      category: "Education Meterial",
      serviceList: "Online",
      commodities: "cocoa ",
      description: "A collective term to include pharmaceuticals, consumable medical supplies, and durable medical equipment.",
      unitOfMeasure: "Ml",
      
    },
  ]);
  const [bindUnitOfMeasure, setBindUnitOfMeasure] = useState([
    { value: "1", label: "mg" },
    { value: "2", label: "ml" },
    { value: "3", label: "g" },
  ]);

  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [updateButton, setUpdateButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const [editMode, setEditMode] = useState([]);

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [serviceList, setServiceList] = useState("");
  const [commodities, setCommodities] = useState("");
  const [description, setDescription] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("");

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setCurrentPage(1);
    setResult(10);

    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };
  const HandleAddress = (rowIndex) => {};
  const handleEdit = async (items) => {
    setEditMode(true);
    const data = [{}];
    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
  };

  function handleDropDown(e) {}

  const handleSubmit = async (items, flag) => {};

  const HandleUserDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 2) {
      setViewDetails(true);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 4) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    setCurrentData(bindSRHCommoditiesDatas[rowIndex]);
  };

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`SRH Commodities`}</div>
          </div>

          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="5" selected>
                    5
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={SRHCommoditiesMasterHeaders}
            tableData={bindSRHCommoditiesDatas}
            onEditGrid={handleEdit}
            onDeleteGrid={HandleUserDetails}
            onBlockGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
            onAddressGrid={HandleAddress}
          />

          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"SRH Commodities - Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"SRH Commodities - Edit"}
              </div>
            )}

            {viewScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"SRH Commodities - View"}
              </div>
            )}

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <DropDown
                      title="Category"
                      options={bindCategoryDD}
                      placeholder="Select"
                      name="category"
                      selectedOptions={category}
                      controlFunc={(e) => setCategory(e.target.value)}
                    />
                    <DropDown
                      title="Service List"
                      options={bindServiceDD}
                      placeholder="Select"
                      name="serviceList"
                      selectedOptions={serviceList}
                      controlFunc={(e) => setServiceList(e.target.value)}
                    />
                    <InputText
                      inputType="text"
                      title="Commodities"
                      placeholder="Commodities"
                      name="commodities"
                      content={commodities}
                      controlFunc={(e) => setCommodities(e.target.value)}
                    />
                    <InputText
                      inputType="text"
                      title="Description"
                      placeholder="Description"
                      name="description"
                      content={description}
                      controlFunc={(e) => setDescription(e.target.value)}
                    />
                    <DropDown
                      title="Unit Of Measure"
                      options={bindUnitOfMeasure}
                      placeholder="Select"
                      name="unitOfMeasure"
                      content={unitOfMeasure}
                      controlFunc={(e) => setUnitOfMeasure(e.target.value)}
                    />
                  </div>
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>

      <MainPopup
        open={viewDetails}
        closeOnDocumentClick={false}
        onClose={() => setViewDetails(false)}
      >
        <div className="modal-reset">
          <button className="close" onClick={() => setViewDetails(false)}>
            &times;
          </button>
          <div className="modal-content">
            <UserDetails data={currentData} />
          </div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setViewDetails(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
}

export default SRHCommodities;