// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import { areaMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import MainPopup from "../popup-containers/MainPopup";
import AreaPopupmaster from "../popup-containers/AreaPopupmaster";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const AreaMaster = () => {
  const location1 = useLocation();
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [bindAreaMasterDatas, setbindAreaMasterDatas] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [areaName, setAreaName] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [sdp, setSDP] = useState("");
  const [mstAreaId, setMstAreaId] = useState("");
  const createdBy = sessionStorage.getItem("createdBy");

  const { userProfileState } = useUserCapability();
  const userVsCapability = userProfileState.userVsCapability;

  const [stateError, setStateError] = useState(""); // Will be Used In Functionality
  const [locationError, setLocationError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [areaNameError, setAreaNameError] = useState("");
  const [areaCodeError, setAreaCodeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [visibleAddScreen, setvisibleAddScreen] = useState(false);
  const [visibleEditScreen, setvisibleEditScreen] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // View details start

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [AreaMasterData, setAreaMasterData] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  // view details end

  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [bindState, setBindStatesDD] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);

  const [bindSDP, setBindSDP] = useState([]);

  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);

  const [popupdata1, setPopupdata1] = useState({});

  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setvisibleAddScreen(true); // Add Screen title & button
    setvisibleEditScreen(false); // Update Screen title & button
  };

  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
      dropdownstate(selectedRowIndex);
      dropdownlocation(selectedRowIndex);
      dropdownsdp(selectedRowIndex);
    }
  };

  const dropdownstate = (rowIndex) => {
    try {
      const StaterowData = bindAreaMasterDatas[rowIndex];
      const StateInfo = bindState.find(
        (ST) => ST.value === StaterowData.MstStateId
      );

      const resultState = {
        value: StaterowData.MstStateId,
        label: StateInfo ? StateInfo.label : "DefaultStateLabel",
      };

      setState(resultState);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownsdp = (rowIndex) => {
    try {
      const SDProwData = bindAreaMasterDatas[rowIndex];
      const SDPInfo = getSDPDatas.find(
        (SD) => SD.MstSDPId === SDProwData.MstSDPId
      );
      const ResultSDP = {
        value: SDProwData.MstSDPId,
        label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
      };
      setSDP(ResultSDP);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownlocation = (rowIndex) => {
    try {
      const locationrowData = bindAreaMasterDatas[rowIndex];
      const LocationInfo = getLocationDatas.find(
        (L) => L.MstLocationId === locationrowData.MstLocationId
      );
      const ResultLocation = {
        value: locationrowData.MstLocationId,
        label: LocationInfo
          ? LocationInfo.LocationName_en
          : "DefaultStateLabel",
      };
      setLocation(ResultLocation);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  const handleEdit = (rowIndex) => {
    // Edit Functionality

    setEditMode(true);
    setSelectedRowIndex(rowIndex);

    setVisibleAdd(true);
    setVisibleTable(false);
    setvisibleAddScreen(false);
    setvisibleEditScreen(true);

    setSDP(bindAreaMasterDatas[rowIndex].SdpName);
    setAreaName(bindAreaMasterDatas[rowIndex].AreaName_en);
    setAreaCode(bindAreaMasterDatas[rowIndex].AreaCode);
    setCountry(bindAreaMasterDatas[rowIndex].CountryName);
    setMstAreaId(bindAreaMasterDatas[rowIndex].MstAreaId);
    setEmail(bindAreaMasterDatas[rowIndex].AreaEmail);
    setState(bindAreaMasterDatas[rowIndex].MstStateId);
    setLocation(bindAreaMasterDatas[rowIndex].MstLocationId);
    setSDP(bindAreaMasterDatas[rowIndex].MstSDPId);
  };

  const handleArea = (rowIndex, flag) => {
    // Setting visibility in popup container
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setAreaMasterData(bindAreaMasterDatas[rowIndex]);

    const StaterowData = bindAreaMasterDatas[rowIndex];
    const StateInfo = bindState.find(
      (ST) => ST.value === StaterowData.MstStateId
    );

    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };

    const locationrowData = bindAreaMasterDatas[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const SDProwData = bindAreaMasterDatas[rowIndex];
    const SDPInfo = getSDPDatas.find(
      (SD) => SD.MstSDPId === SDProwData.MstSDPId
    );
    const ResultSDP = {
      value: SDProwData.MstSDPId,
      label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
    };

    const AutoDropdownPopupdata = [resultState, ResultLocation, ResultSDP];
    setPopupdata1(AutoDropdownPopupdata);
  };

  useEffect(() => {
    // Language Translator
    const getLanguageCode = async () => {
      const response = await BindAPI("LanguageAPI/GetLanguage");
      setLanguageCodekey(response.data.Table);
    };
    getLanguageCode();
  }, []);

  useEffect(() => {
    // Language Translator
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  const handleSubmit = async (items, flag) => {
    // Submit functionality
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let locationValidate = "";
      let sdpValidate = "";
      let areaNameValidate = "";
      let areaCodeValidate = "";
      // let emailValidate = "";

      stateValidate = state ? "" : "Select State Name";
      setStateError(stateValidate);

      locationValidate = location ? "" : "Select Branch/Project Name";
      setLocationError(locationValidate);

      sdpValidate = sdp ? "" : "Select S.D.P Name";
      setSDPError(sdpValidate);

      areaNameValidate =
        areaName.length > 0
          ? /^[A-Za-z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(areaName)
            ? !editMode &&
              bindAreaMasterDatas && // Ensure bindAreaMasterDatas is not null
              bindAreaMasterDatas.find(
                (x) =>
                  x.AreaName_en &&
                  x.AreaName_en.toLowerCase() == areaName.toLowerCase()
              )
              ? "Area Name already available"
              : ""
            : "Only Alphabet and space allowed"
          : "Enter Area Name";

      areaCodeValidate =
        areaCode.length > 0
          ? /^[A-Z]{3}$/.test(areaCode)
            ? !editMode &&
              bindAreaMasterDatas && // Ensure bindAreaMasterDatas is not null
              bindAreaMasterDatas.find(
                (x) =>
                  x.AreaCode &&
                  x.AreaCode.toLowerCase() == areaCode.toLowerCase()
              )
              ? "Area Code already available"
              : ""
            : "Only Three Alphabet allowed"
          : "Enter Area Code";

      setAreaNameError(areaNameValidate);
      setAreaCodeError(areaCodeValidate);

      // emailValidate =
      //   email.length > 0
      //     ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)
      //       ? !editMode &&
      //         bindAreaMasterDatas && // Ensure bindAreaMasterDatas is not null
      //         bindAreaMasterDatas.find(
      //           (x) =>
      //             x.AreaEmail &&
      //             x.AreaEmail.toLowerCase() == email.toLowerCase()
      //         )
      //         ? "Area email already available"
      //         : ""
      //       : "Enter Valid Email Id"
      //     : "Enter Email Id";

      // setEmailError(emailValidate);
      // Validation end

      if (
        stateValidate ||
        locationValidate ||
        sdpValidate ||
        areaNameValidate ||
        areaCodeValidate
      ) {
        setIsOpen(true);
      }

      if (
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !areaNameValidate &&
        !areaCodeValidate
      ) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTexts = []; // Create an array to store translations

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(areaName, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        setIsOpen(false);
        const data = [
          {
            mstAreaId: mstAreaId ? mstAreaId : 0,
            areaCode: areaCode,
            mstCountryId: 1,
            mstStateId: state.value,
            mstLocationId: location.value,
            mstSDPId: sdp.value,
            areaNameEn: areaName,
            areaNameTa: translatedTexts[0],
            areaNameHi: translatedTexts[1],
            areaNameMr: translatedTexts[2],
            areaNameGu: translatedTexts[3],
            areaNameKn: translatedTexts[4],
            areaNameMl: translatedTexts[5],
            areaNameTe: translatedTexts[6],
            areaNameBn: translatedTexts[7],
            areaNameOr: translatedTexts[8],
            areaEmail: email,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "AreaAPI/IUDArea");
        if (flag == 1) {
          // Submit

          if (response.data.Table[0].Result === "S") {
            toast.success("Area data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Area data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("Area data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Area data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = [
        {
          mstAreaId: bindAreaMasterDatas[rowIndex].MstAreaId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "AreaAPI/IUDArea");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("Area data deleted successfully 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Area data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("Area data blocked successfully 🚫", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Area data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("Area data unblocked successfully ✅", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Area data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const areaMasterData = async () => {
    const response = await BindAPI("AreaAPI/BindArea");
    setbindAreaMasterDatas(response.data.Table);
  };

  const cancel = () => {
    setEditMode(false);
    setSelectedRowIndex(null);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setvisibleAddScreen(false);
    setvisibleEditScreen(false);
    setSubmitBtnDisabled(false);
    // Clear Area data
    setAreaName("");
    setAreaCode("");
    setState("");
    setLocation("");
    setSDP("");
    setAreaNameError("");
    setAreaCodeError("");
    setStateError("");
    setLocationError("");
    setEmailError("");
    setEmail("");
    setSDPError("");
    areaMasterData();
  };

  useEffect(() => {
    bindAreaMasterDatas && areaMasterData();
  }, []);

  useEffect(() => {
    const BindAllmasterData = async () => {
      const bindReferredBydatadrp = await BindAPI(
        "ClientAPI/BindClientAllMasterDatas"
      );
      const filteredData = bindReferredBydatadrp.data.Table7;
      const filteredWithBlockedByNull = filteredData.filter(
        (item) => item.BlockedBy === null
      );

      binddrpstatedata(filteredWithBlockedByNull);
    };
    BindAllmasterData();
  }, []);

  const binddrpstatedata = async (resultdata) => {
    try {
      if (resultdata.length > 0) {
        const stateDD = resultdata.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindStatesDD(stateDD);
      } else {
        setBindStatesDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      const filteredData = locationResponse.data.Table;
      const filteredWithBlockedByNull = filteredData.filter(
        (item) => item.BlockedBy === null
      );
      setGetLocationDatas(filteredWithBlockedByNull);
    };

    const getSelectedSDP = async () => {
      const sdpResponse = await BindAPI("SDPAPI/GetSDP");
      const filteredData = sdpResponse.data.Table;
      const filteredWithBlockedByNull = filteredData.filter(
        (item) => item.BlockedBy === null
      );
      console.log("filteredWithBlockedByNull", filteredWithBlockedByNull);
      setGetSDPDatas(filteredWithBlockedByNull);
    };

    getSelectedLocation();
    getSelectedSDP();
  }, []);

  useEffect(() => {
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDD(locationSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [state]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == location.value
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId,
        label: SD.SDP_en,
      }));
      setBindSDP(sdpSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [location]);
  useEffect(() => {
    if (location1.pathname == "/areamaster") {
      setVisibleTable(true);
      setVisibleAdd(false);
      areaMasterData();
    }
  }, [location1]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={"Area Master"}
          header={areaMasterHeader}
          records={bindAreaMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleArea}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class=" border-black  ">
            <AddScreenTitle
              addScreenTitle={visibleAddScreen}
              updateScreenTitle={visibleEditScreen}
              title="Area Master"
            />
          </div>

          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form className={mainTable.formForClientMaster}>
                <div className={mainTable.grid3}>
                  <DropDown
                    classStar="text-red-500"
                    classDiv="hidden"
                    title="Country"
                    options={[]}
                    placeholder="Select"
                    name="country"
                    selectedOption={country}
                    controlFunc={(e) => setCountry(e.target.value)}
                  />
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindState}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      controlFunc={(value) => {
                        setLocation("");
                        setSDP("");
                        setState(value);
                        setStateError(value ? "" : "Select State Name");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select State Name");
                      }}
                      Class="custom-class"
                      tabIndex="1"
                    />
                    {stateError && (
                      <span class="text-red-500">{stateError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title={"Branch/Project"}
                      options={bindLocation}
                      placeholder={"Select"}
                      name={"location"}
                      selectedOption={location}
                      controlFunc={(value) => {
                        setLocation(value);
                        setLocationError(
                          value ? "" : "Select Branch/Project Name"
                        );
                        setSDP("");
                      }}
                      controlFuncBlur={() => {
                        setLocationError(
                          location ? "" : "Select Branch/Project Name"
                        );
                      }}
                      Class="custom-class"
                      tabIndex="1"
                    />
                    {locationError && (
                      <span class="text-red-500">{locationError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title={"S.D.P."}
                      options={bindSDP}
                      placeholder={"Select"}
                      name={"sdp"}
                      selectedOption={sdp}
                      controlFunc={(value) => {
                        setSDP(value);
                        setSDPError(value ? "" : "Select S.D.P Name");
                      }}
                      controlFuncBlur={() => {
                        setSDPError(sdp ? "" : "Select S.D.P Name");
                      }}
                      Class="custom-class"
                      tabIndex="1"
                    />
                    {sdpError && <span class="text-red-500">{sdpError}</span>}
                  </div>
                  <div>
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Area"}
                      name={"area"}
                      maxLength={100}
                      content={areaName}
                      controlFunc={(e) => setAreaName(e.target.value)}
                      controlFuncBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setAreaName(trimmedValue);
                        setAreaNameError(
                          trimmedValue.length > 0
                            ? /^[A-Za-z !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(
                              trimmedValue
                              )
                              ? !editMode &&
                                bindAreaMasterDatas.find(
                                  (x) =>
                                    x.AreaName_en.toLowerCase() ==
                                  trimmedValue.toLowerCase()
                                )
                                ? "Area Name already available"
                                : ""
                              : "Only Alphabet and space allowed"
                            : "Enter Area Name"
                        );
                      }}
                    />
                    {areaNameError && (
                      <span class="text-red-500">{areaNameError}</span>
                    )}
                  </div>
                  <div>
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Area Code"}
                      name={"area"}
                      maxLength={3}
                      content={areaCode}
                      controlFunc={(e) => setAreaCode(e.target.value)}
                      disabled={editMode ? editMode : false}
                      controlFuncBlur={(e) => {
                        setAreaCode(e.target.value);
                        setAreaCodeError(
                          areaCode.length > 0
                            ? /^[A-Z]{3}$/.test(areaCode)
                              ? !editMode &&
                                bindAreaMasterDatas && // Ensure bindAreaMasterDatas is not null
                                bindAreaMasterDatas.find(
                                  (x) =>
                                    x.AreaCode &&
                                    x.AreaCode.toLowerCase() ===
                                      areaCode.toLowerCase()
                                )
                                ? "Area Code already available"
                                : ""
                              : "Should be uppercase"
                            : "Enter Area Code"
                        );
                      }}
                    />
                    {areaCodeError && (
                      <span class="text-red-500">{areaCodeError}</span>
                    )}
                  </div>
                  <div>
                    <InputText
                      // classStar="text-red-500"
                      inputType={"text"}
                      title={"Email Id"}
                      name={"area"}
                      content={email}
                      controlFunc={(e) => setEmail(e.target.value)}
                      // controlFuncBlur={(e) => {
                      //   setEmail(e.target.value);
                      //   setEmailError(
                      //     email.length > 0
                      //       ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                      //           email
                      //         )
                      //         ? !editMode &&
                      //           bindAreaMasterDatas.find(
                      //             (x) =>
                      //               x.AreaEmail.toLowerCase() ==
                      //               email.toLowerCase()
                      //           )
                      //           ? "Area email already available"
                      //           : ""
                      //         : "Enter Valid Email Id"
                      //       : "Enter Email Id"
                      //   );
                      // }}
                    />
                    {emailError && (
                      <span class="text-red-500">{emailError}</span>
                    )}
                  </div>
                </div>
              </form>

              <Buttons
                addScreenTitle={visibleAddScreen}
                updateScreenTitle={visibleEditScreen}
                handleSubmit={handleSubmit}
                cancel={cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleArea}
      >
        <div>
          <PopUpScreenTitle
            title="Area Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <AreaPopupmaster data={AreaMasterData} data1={popupdata1} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};
export default AreaMaster;
