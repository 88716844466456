// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import GridPage from "../components/GridPage";
import { useLocation } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import { doctorAppoinmentsHeaders } from "../components/HeaderAttribute";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";

const DoctorAppointments = () => {
  const location1 = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  //All visible functionality useState hooks handling.

  const [visibleTable, setVisibleTable] = useState(true);

  useEffect(() => {
    BindGridData();
    if (location1.pathname == "/doctorappointments") {
      setVisibleTable(true);
    }
  }, [location1]);

  const BindGridData = async () => {
    if (userProfileDatas && userProfileDatas[0]) {
      const payload = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        payload,
        "DoctorConsultation/BindDoctorConsultation"
      );
      setBindAppoinmentsDatas(response.data.Table);
    } else {
      return;
    }
  };

  const [bindAppointmentsDatas, setBindAppoinmentsDatas] = useState([
    // {
    //   sno: 16,
    //   clientId: "MH-MUM-SAT-MAN-2023-0000143",
    //   clientName: "Dharmender Kumar",
    //   clientMotherName: "Bushra Patiya",
    //   Gender: "Male / 26",
    //   clientPhone: 8963254853,
    //   Doctor: "RHFPC - Nariman Point",
    //   DateTime: " 01-11-2023 10.00 AM",
    //   address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    // },
    // {
    //   sno: 11,
    //   clientId: "OR-BHU-SAC-SHJ-2023-0000174",
    //   clientName: "Vikrant Patel",
    //   clientMotherName: "Harpreet Kaul",
    //   Gender: "Male / 23",
    //   clientPhone: 6352638456,
    //   Doctor: "Satellite clinic - Navi Mumbai",
    //   DateTime: "28-10-2023 11.00 PM",
    //   address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    // },
    // {
    //   sno: 12,
    //   clientId: "UP-AGR-ASC-HIG-2023-0000883",
    //   clientName: "Vishwa Sharma",
    //   clientMotherName: "Divya Rani",
    //   Gender: "Male / 24",
    //   clientPhone: 8563214578,
    //   Doctor: "UFWC - Nariman Point",
    //   DateTime:"27-10-2023 10.00 AM",
    //   address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    // },
    // {
    //   sno: 20,
    //   clientId: "JK-SRI-DHI-GAW-2023-0000941",
    //   clientName: "Sunil Kumar",
    //   clientMotherName: "Manpreet Kumari",
    //   Gender: "Male / 21",
    //   Doctor: "Special project unit - Solapur",
    //   clientPhone: 7356894256,
    //   DateTime: "25-10-2023 10.00 AM",
    //   address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    // },
    // {
    //   sno: 25,
    //   clientId: "TN-CHE-SAT-AKM-2023-0000729",
    //   clientName: "Lakshmi Suryakumar",
    //   clientMotherName: "Rajkumari Suryakumar",
    //   Gender: "Female / 26",
    //   clientPhone: 9635268742,
    //   Doctor: "RHFPC - Pune",
    //   DateTime: "25-10-2023 3.00 PM",
    //   address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    // },
  ]);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title={"Doctor Consultation"}
          xScroll="overflow-x-auto max-md:w-fit w-full"
        tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
          header={doctorAppoinmentsHeaders}
          records={bindAppointmentsDatas}
          addView={true}
        />
      )}
    </MainLayout>
  );
};

export default DoctorAppointments;
