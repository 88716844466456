import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import TableGrid from "../components/Table";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindLocalAPI, GetLocalAPI, IUDAPI } from "../services/APIServices";
import Checkbox from "../ReusableComponents/Checkbox";
import { ReportInschoolSession } from "../components/HeaderAttribute";
import GridPage from "../components/GridPage";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const ReportOutschoolSession = () => {
    const [showTable, setShowTable] = useState(false);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const [bindYearDD, setBindYearDD] = useState([]);
    const [youthId, setYouthId] = useState("");
    const [cseClass, setCseClass] = useState("");
    const [bindClassDD, setBindClassDD] = useState([]);
    const [division, setDivision] = useState("");
    const [bindDivisionDD, setBindDivisionDD] = useState([]);
    const [bindTopics, setBindTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState("");

    const [bindYearDetails, setYearDetails] = useState([]);
    const [ClassDetails, setClassDetails] = useState([]);
    const [bindDivisionDetails, setDivisionDetails] = useState([]);
    const [bindReportInschoolSeassion, setBindReportInschoolSeassion] = useState([]);

    // useEffect(() => {
    //   const today = new Date();
    //   const currentMonth = today.getMonth();
    //   const currentYear = today.getFullYear();

    //   // Set fromDate to the first day of the current month and year
    //   const fromDate = new Date(currentYear, currentMonth, 2);

    //   // Set toDate to the current date
    //   const toDate = today;

    //   // Optionally, you can format the dates if needed
    //   const formattedFromDate = fromDate.toISOString().split('T')[0];
    //   const formattedToDate = toDate.toISOString().split('T')[0];

    //   // Now, you can use formattedFromDate and formattedToDate in your state or elsewhere
    //   console.log("fromDate:", formattedFromDate);
    //   console.log("toDate:", formattedToDate);

    //   // Set the state
    //   setFromDate(formattedFromDate);
    //   setToDate(formattedToDate);
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            binddrpTopicsdata();
        };

        fetchData();
    }, []);

    const GotableShow = async () => {
        debugger
        const txnInSchoolSessionId = bindDivisionDetails[0].TxnInSchoolSessionId || null;
        const ReportData = [
            {
                Year: year, 
                Fromdate: fromDate,
                ToDate: toDate

            },
        ];
        const response = await GetLocalAPI(ReportData, "ReportInSchoolGet");

        console.log("response", response.data.Table);
        setBindReportInschoolSeassion(response.data.Table);
        setShowTable(true);

    };

    const binddrpTopicsdata = async () => {
        try {
            const bindTopicsdatadrp = await BindAPI("BindCSEAllMasterDatas");

            if (bindTopicsdatadrp.data.Table.length > 0) {
                const Topicsdatadrp = bindTopicsdatadrp.data.Table.map((m) => ({
                    value: m.MstCSEModuleID,
                    label: m.CSEModule,
                }));
                setBindTopics(Topicsdatadrp);
            } else {
                setBindTopics([]);
            }
        } catch (error) { }
    };

    useEffect(() => {
        const generateYearOptions = () => {
            
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear; year >= 1900; year--) {
                years.push({ value: year, label: year.toString() });
            }
            return years;
        };
        setBindYearDD(generateYearOptions());
    }, []);

    const [year, setYear] = useState(new Date().getFullYear());

    // Bind Drpdown value
    useEffect(() => {
        BindYouthDetails();
    }, [year]);


    const BindYouthDetails = async () => {

        try {
            const bindSchooldatadrp = await BindLocalAPI("ReportCSEBindOutSchoolSession");

            if (bindSchooldatadrp.data.Table.length > 0) {

                const filteredData = bindSchooldatadrp.data.Table.filter(item => {
                    const itemYear = item.YDate;
                    return itemYear === year;
                });

                setYearDetails(filteredData);

                console.log("filteredData", filteredData);

                const schoolNames = filteredData.map((m) => ({
                    value: m.MstYouthGroupId,
                    label: m.YouthGroupName,
                }));

                console.log("schoolNames", schoolNames);
                setBindYouthDD(schoolNames);
            } else {
                console.log("No data available");
                setBindYouthDD([]);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const handleYouthNameChange = (DivisionName) => {
        

        setDivision(DivisionName);

        const filteredData = bindYearDetails.filter(item => {
            const YearID = item.MstYouthGroupId;
            return YearID === youthId.value;
        });
        console.log("ClassDetails", filteredData);

        const topics1 = filteredData.map(item => item.Topics.split(',')).flat();
        console.log("Topics1", topics1);
        const topics = topics1.map((value) => parseInt(value.trim(), 10));
        console.log("Topics", topics);
        setSelectedTopics(topics);
    };

    const [bindYouthDD, setBindYouthDD] = useState([]);







    const tableHeader = [
        "Sr. No.",
        "School ID No.",
        "Name of Student",
        "Student Roll/ ID No.",
        "Class",
        "Division",
        "Age (In Years)",
        "Gender (M/ F/ O)",
        "1: Sexual health and well being, human rights",
        "2: Gender, gender-based violence",
        "3: Diversity, Sexuality, Pleasure",
        "4: Interpersonal relationships",
        "5: Communicating and Decision making skills",
        "6: The Body, Puberty and Reproduction",
        "7: Sexual and Reproductive Health",
        "8: All Topic Covered",
        "Count Topic",
    ];
    // search box function
    const [searchInput, setSearchInput] = useState("");
    const [searchData, setFilteredData] = useState(bindReportInschoolSeassion);
    const handleSearchInputChange = (e) => {
        // Search in Grid
        const inputValue = e.target.value;
        setSearchInput(inputValue);
        handleSearch(inputValue);
    };

    const handleSearch = (input) => {
        const filtered = bindReportInschoolSeassion.filter((item) => {
            const providerData = `${item.SchoolId}${item.StudentName}`;
            return providerData.toLowerCase().includes(input.toLowerCase());
        });
        setFilteredData(filtered);
    };
    const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const handleDropDown = (event) => {
        // To set Number of records per page
        const selectedValue = parseInt(event.target.value); // Convert to integer
        setItemsPerPage(selectedValue);
        setCurrentPage(1); // Reset to first page when items per page changes
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handleExport = () => {
        // Assuming bindReportInschoolSeassion is your data array
        const dataToExport = bindReportInschoolSeassion.map((rowData) => ({
            ...rowData,
            // Format the Student Roll/ ID No.
            StudentRoll: `${new Date(rowData.Date).getFullYear()}/${rowData.Sno}`,
        }));

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);

        // Create a workbook with the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Save the workbook to a file
        XLSX.writeFile(wb, 'CSEInSchoolSessionReport.xlsx');
    };


    // ... Your existing code ...

    const downloadPDF = () => {
        const doc = new jsPDF();

        // Define the columns for the table from the tableHeader array
        const columns = [
            "Sr. No.",
            "School ID",
            "Name of Student",
            "Student Roll/ID No.",
            "Class",
            "Division",
            "Age (In Years)",
            "Gender (M/F/O)",
            "1: Sexual health and well-being, human rights",
            "2: Gender, gender-based violence",
            "3: Diversity, Sexuality, Pleasure",
            "4: Interpersonal relationships",
            "5: Communicating and Decision making skills",
            "6: The Body, Puberty, and Reproduction",
            "7: Sexual and Reproductive Health",
            "8: All Topics Covered",
            "Count Topic",
        ];

        // Determine the rows based on whether there is a search input or not
        const rows = searchInput.trim() === "" ? bindReportInschoolSeassion : searchData;

        // Add table header
        doc.autoTable({
            head: [columns],
            startY: 30,
            styles: {
                fontSize: 8, fillColor: "#2d2d6e",
                textColor: [255, 255, 255], lineColor: "black", // Border color
                lineWidth: 0.5,
            },
            // columnStyles: {
            //   // Adjust the column widths as needed
            //   0: { columnWidth: 10 },
            //   1: { columnWidth: 20 },
            //   2: { columnWidth: 40 },
            //   3: { columnWidth: 30 },
            //   4: { columnWidth: 15 },
            //   5: { columnWidth: 15 },
            //   6: { columnWidth: 20 },
            //   7: { columnWidth: 20 },
            //   8: { columnWidth: 40 },
            //   9: { columnWidth: 20 },
            //   10: { columnWidth: 15 },
            //   11: { columnWidth: 15 },
            //   12: { columnWidth: 20 },
            //   13: { columnWidth: 20 },
            //   14: { columnWidth: 20 },
            //   15: { columnWidth: 20 },
            //   16: { columnWidth: 20 },
            //   17: { columnWidth: 20 },
            // },
        });

        // Set the position for the next section
        // const nextSectionY = doc.autoTable.previous.finalY + 10;

        const nextSectionY = doc.autoTable.previous.finalY;

        // Add text or other content between sections
        // doc.text("Additional Section", 20, nextSectionY);

        // Set the position for the next section
        // const nextSectionY2 = nextSectionY + 20;

        // Add the rows to the first table immediately below the header
        doc.autoTable({
            body: rows.map((rowData, index) => {
                const topicsArray = rowData.Topics ? rowData.Topics.split(',') : [];
                const countTopic = topicsArray.filter(topic => topic.trim() !== '').length;
                const AllCover = 1;

                return [
                    rowData.Sno,
                    rowData.SchoolId,
                    rowData.StudentName,
                    rowData.SessionNumber,
                    rowData.Class,
                    rowData.Division,
                    rowData.Age,
                    rowData.Gender,
                    ...[1, 2, 3, 4, 5, 6, 7].map((topicNumber) =>
                        topicsArray && topicsArray.includes(topicNumber.toString()) ? rowData.Date : '-'
                    ),
                    AllCover,
                    countTopic,
                ];
            }),
            // startY: nextSectionY2,
            styles: {
                fontSize: 8, lineColor: "black", // Border color
                lineWidth: 0.5,
            },
            startY: nextSectionY,

        });

        // Save or display the document
        doc.save('CSEInSchoolSessionReport.pdf');


    };


    const handleTopicsChange = (value) => {
        setSelectedTopics(value);
    };

    return (
        <MainLayout pageName="3">
            <div class="relative mt-6 bg-white rounded-lg shadow-2xl shadow-gray-400 ">
                <div class="px-16 mt-12 py-4">
                    <div className={mainTable.headerTitle}>
                        <div className="my-2 mx-12 lg:mx-0">{`CSE Report`}</div>
                    </div>
                    <div className={mainTable.grid4}>
                        <DropDown
                            title="Year"
                            classStar="text-red-500"
                            options={bindYearDD}
                            placeholder="Select"
                            name="Year"
                            selectedOption={year}
                            controlFunc={(e) => {
                                setYear(e.target.value);
                            }}
                        />
                        <AutoDropDown
                            classDiv=""
                            Class="Custom-class"
                            title={`Youth Name`}
                            options={bindYouthDD}
                            classStar="text-red-500"
                            placeholder="Select"
                            name=""
                            selectedOption={youthId}
                            controlFunc={(value) => {
                                setYouthId(value); // Update the selected School Name
                                handleYouthNameChange(value); // Call your custom function
                            }}
                        />

                        <InputText
                            inputType="date"
                            title="From"
                            name="fromDate"
                            content={fromDate}
                            controlFunc={(e) => setFromDate(e.target.value)}
                        />
                        <InputText
                            inputType="date"
                            title="To"
                            name="toDate"
                            content={toDate}
                            controlFunc={(e) => setToDate(e.target.value)}
                        />
                        <Checkbox
                            classDiv="col-span-4 mb-2"
                            title="Topics"
                            classStar="text-red-500"
                            type={"checkbox"}
                            setName={"module"}
                            selectedOptions={selectedTopics}
                            options={bindTopics}
                            controlFunc={handleTopicsChange}
                            disabled={false}
                            tabIndex="0"
                        />
                        <div class="flex justify-start items-center mt-6">
                            <button
                                type="button"
                                onClick={() => GotableShow()}
                                class="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] "
                            >
                                GO
                            </button>
                        </div>
                    </div>
                </div>
                {showTable && (
                    <div className=" ">
                        <div className="relative mt-6 md:hidden">
                            <div className={mainTable.searchIconPosition}>
                                <svg
                                    className={mainTable.searchIconSvg}
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                className={mainTable.searchInputBox}
                                placeholder="Search for items"
                                value={searchInput}
                                onChange={handleSearchInputChange}
                            />
                        </div>

                        <div className="justify-between flex items-center">
                            <div className={mainTable.searchAdd}>
                                <div className="mt-1 gap-2 flex justify-between items-baseline">
                                    <label for="pageSize" className="hidden md:block">
                                        Rows Per Page:
                                    </label>
                                    <select
                                        className={mainTable.dropDownRecords}
                                        name="pageSize"
                                        onChange={handleDropDown}
                                    >
                                        <option value="1">1</option>
                                        <option value="5">5</option>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50" selected>
                                            50
                                        </option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div>
                                    <label for="table-search" className="sr-only">
                                        Search
                                    </label>
                                </div>
                                <div className="hidden md:block relative mt-1">
                                    <div className={mainTable.searchIconPosition}>
                                        <svg
                                            className={mainTable.searchIconSvg}
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        className={mainTable.searchInputBox}
                                        placeholder="Search for items"
                                        value={searchInput}
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                            </div>
                            <div className="flex self-end">
                                <button
                                    type="button"
                                    className={mainTable.addButton}
                                    onClick={downloadPDF}
                                >
                                    <svg
                                        width="25"
                                        height="20"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                                            fill="white"
                                        />
                                    </svg>
                                    PDF
                                </button>
                                <button
                                    type="button"
                                    className={mainTable.addButton}
                                    onClick={handleExport} // Assuming you have a function named handleExport for exporting
                                >
                                    <svg
                                        width="25"
                                        height="20"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                                            fill="white"
                                        />
                                    </svg>
                                    Excel
                                </button>
                            </div>
                        </div>
                        <div className="overflow-auto">
                            <table id="table-to-export" style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr>
                                        {tableHeader.map((header, index) => (
                                            <th key={index} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', backgroundColor: "#2d2d6e", color: "white" }}>
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchInput.trim() === "" ? (
                                        // Render the original dataset when there's no search query
                                        bindReportInschoolSeassion.slice(startIndex, endIndex).map((rowData, index) => {
                                            const topicsArray = rowData.Topics ? rowData.Topics.split(',') : [];
                                            const countTopic = topicsArray.filter(topic => topic.trim() !== '').length;
                                            const AllCover = 1;
                                            const sno = index + 1;
                                            return (
                                                <tr key={index} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                                                    <td>{sno}</td>
                                                    <td>{rowData.SchoolId}</td>
                                                    <td>{rowData.StudentName}</td>
                                                    <td>{rowData.SessionNumber}</td>
                                                    <td>{rowData.Class}</td>
                                                    <td>{rowData.Division}</td>
                                                    <td>{rowData.Age}</td>
                                                    <td>{rowData.Gender}</td>
                                                    {[1, 2, 3, 4, 5, 6, 7].map((topicNumber) => (
                                                        <td key={topicNumber}>
                                                            {topicsArray && topicsArray.includes(topicNumber.toString()) ? rowData.Date : '-'}
                                                        </td>
                                                    ))}
                                                    {/* {[1, 2, 3, 4, 5, 6, 7].map((topicNumber) => (
                        <td key={topicNumber}>
                          {topicsArray && topicsArray.includes && topicsArray.includes(topicNumber.toString()) ? 1 : '-'}
  
                        </td>
                      ))} */}
                                                    <td>{AllCover}</td>
                                                    <td>{countTopic}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (

                                        searchData.slice(startIndex, endIndex).map((rowData, index) => {
                                            const topicsArray = rowData.Topics ? rowData.Topics.split(',') : [];
                                            const countTopic = topicsArray.filter(topic => topic.trim() !== '').length;
                                            const AllCover = 1;
                                            const sno = index + 1;
                                            return (
                                                <tr key={index} style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                                                    <td>{sno}</td>
                                                    <td>{rowData.SchoolId}</td>
                                                    <td>{rowData.StudentName}</td>
                                                    <td>{rowData.SessionNumber}</td>
                                                    <td>{rowData.Class}</td>
                                                    <td>{rowData.Division}</td>
                                                    <td>{rowData.Age}</td>
                                                    <td>{rowData.Gender}</td>
                                                    {[1, 2, 3, 4, 5, 6, 7].map((topicNumber) => (
                                                        <td key={topicNumber}>
                                                            {topicsArray && topicsArray.includes(topicNumber.toString()) ? rowData.Date : '-'}
                                                        </td>
                                                    ))}
                                                    {/* {[1, 2, 3, 4, 5, 6, 7].map((topicNumber) => (
                        <td key={topicNumber}>
                          {topicsArray && topicsArray.includes && topicsArray.includes(topicNumber.toString()) ? 1 : '-'}
  
                        </td>
                      ))} */}
                                                    <td>{AllCover}</td>
                                                    <td>{countTopic}</td>
                                                </tr>
                                            );
                                        })
                                    )}

                                </tbody>
                            </table>
                        </div>
                        {/* Pagination controls */}
                        <div className="flex justify-between items-center mt-4">
                            <div>Showing {startIndex + 1} to {Math.min(endIndex, bindReportInschoolSeassion.length)} of {bindReportInschoolSeassion.length} entries</div>
                            <div className="flex space-x-2">
                                <button className={mainTable.submitButton} onClick={() => setCurrentPage(Math.max(1, currentPage - 1))} disabled={currentPage === 1}>Previous</button>
                                <span>{currentPage}</span>
                                <button className={mainTable.cancelButton} onClick={() => setCurrentPage(Math.min(Math.ceil(bindReportInschoolSeassion.length / itemsPerPage), currentPage + 1))} disabled={endIndex >= bindReportInschoolSeassion.length}>Next</button>
                            </div>
                        </div>
                        {/* <div className={mainTable.submitCancelMainDiv}>
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={mainTable.submitButton}
              >
                Previous Page
              </button>
              <label>{currentPage}</label>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  currentPage === bindReportInschoolSeassion.length / itemsPerPage
                }
                className={mainTable.cancelButton}
              >
                Next Page
              </button>
            </div> */}
                    </div>
                    // <TableGrid
                    //   className={mainTable.table}
                    // headerData={ReportInschoolSession}
                    // tableData={
                    //   // searchData.length > 0 ? searchData :
                    //   bindReportInschoolSeassion
                    // }

                    // />
                    //   <GridPage 
                    //   header={ReportInschoolSession}
                    //   records={bindReportInschoolSeassion}
                    //   // handleEdit={handleEdit}
                    //   // handleAction={handleClientDetails}
                    //   // showAdd={showadd}
                    // />
                    // <table className="border-2 mx-auto border-black">
                    //   <thead className="border border-black">
                    //     <tr>
                    //       <th rowSpan={4} className="border border-black">
                    //         Modules
                    //       </th>
                    //       <th colSpan={18} className="border border-black">
                    //         Number of Boys and Girls
                    //       </th>
                    //       <th rowSpan={4} className="border border-black">
                    //         Total
                    //       </th>
                    //     </tr>
                    //     <tr>
                    //       <th colSpan={9} className="border border-black">
                    //         In School / College
                    //       </th>
                    //       <th colSpan={9} className="border border-black">
                    //         Out School / College
                    //       </th>
                    //     </tr>
                    //     <tr>
                    //       <th colSpan={3} className="border border-black">
                    //         10 - 14 years
                    //       </th>
                    //       <th colSpan={3} className="border border-black">
                    //         15 - 19 years
                    //       </th>
                    //       <th colSpan={3} className="border border-black">
                    //         20 - 24 years
                    //       </th>

                    //       <th colSpan={3} className="border border-black">
                    //         10 - 14 years
                    //       </th>
                    //       <th colSpan={3} className="border border-black">
                    //         15 - 19 years
                    //       </th>
                    //       <th colSpan={3} className="border border-black">
                    //         20 - 24 years
                    //       </th>
                    //     </tr>
                    //     <tr>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //       <th className="border border-black">Boys</th>
                    //       <th className="border border-black">Girls</th>
                    //       <th className="border border-black">Others</th>
                    //     </tr>
                    //   </thead>
                    //   <tbody>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Sexual health and well being, human rights
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">932</td>
                    //       <td className="border border-black">956</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">63</td>
                    //       <td className="border border-black">67</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">16</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4717</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Gender, gender based violence
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">932</td>
                    //       <td className="border border-black">956</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">63</td>
                    //       <td className="border border-black">67</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">16</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4717</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Diversity, Sexuality, Pleasure{" "}
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1331</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">847</td>
                    //       <td className="border border-black">810</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">20</td>
                    //       <td className="border border-black">11</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">16</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4355</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Interpersonal relationships{" "}
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">932</td>
                    //       <td className="border border-black">956</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">63</td>
                    //       <td className="border border-black">67</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">5</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">45</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">13</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4693</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Communicating and Decision making skills{" "}
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">847</td>
                    //       <td className="border border-black">810</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">20</td>
                    //       <td className="border border-black">11</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">16</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4387</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         The Body, Puberty and Reproduction
                    //       </td>
                    //       <td className="border border-black">1261</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">932</td>
                    //       <td className="border border-black">956</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">63</td>
                    //       <td className="border border-black">67</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">16</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4717</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         Sexual and Reproductive Health{" "}
                    //       </td>
                    //       <td className="border border-black">1249</td>
                    //       <td className="border border-black">1351</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">908</td>
                    //       <td className="border border-black">941</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">43</td>
                    //       <td className="border border-black">56</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">18</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">53</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">116</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4615</td>
                    //     </tr>
                    //     <tr>
                    //       <td className="border border-black">
                    //         CSE Covered: ALL Topics completed [1-7]
                    //       </td>
                    //       <td className="border border-black">1249</td>
                    //       <td className="border border-black">1331</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">823</td>
                    //       <td className="border border-black">795</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">20</td>
                    //       <td className="border border-black">11</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">5</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">45</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">13</td>
                    //       <td className="border border-black">0</td>
                    //       <td className="border border-black">4261</td>
                    //     </tr>
                    //   </tbody>
                    // </table>
                    // <div class="relative w-full mx-auto mt-4">
                    //   {/* <div class={mainTable.headerTitle}>
                    //       <div class="my-2 ml-7">Domestic P.O.</div>
                    //     </div> */}
                    //   <div class="mx-8">
                    //     <div className="md:justify-between md:flex  md:items-center">
                    //       <div class={mainTable.searchAdd}>
                    //         <div className="mt-1 gap-2 flex justify-between items-baseline">
                    //           <label for="pageSize" class="hidden md:block">
                    //             Rows Per Page:
                    //           </label>
                    //           <select
                    //             class={mainTable.dropDownRecords}
                    //             name="pageSize"
                    //             id="pageSize"
                    //           >
                    //             <option value="2">2</option>
                    //             <option value="10" selected>
                    //               10
                    //             </option>
                    //             <option value="20">20</option>
                    //             <option value="50">50</option>
                    //             <option value="100">100</option>
                    //           </select>
                    //         </div>
                    //         <div>
                    //           <label for="table-search" class="sr-only">
                    //             Search
                    //           </label>
                    //         </div>
                    //         <div class="relative mt-1">
                    //           <div class={mainTable.searchIconPosition}>
                    //             <svg
                    //               class={mainTable.searchIconSvg}
                    //               aria-hidden="true"
                    //               fill="currentColor"
                    //               viewBox="0 0 20 20"
                    //               xmlns="http://www.w3.org/2000/svg"
                    //             >
                    //               <path
                    //                 fill-rule="evenodd"
                    //                 d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    //                 clip-rule="evenodd"
                    //               ></path>
                    //             </svg>
                    //           </div>
                    //           <input
                    //             type="text"
                    //             id="table-search"
                    //             class={mainTable.searchInputBox}
                    //             placeholder="Search for items"
                    //           />
                    //         </div>
                    //       </div>
                    //       <div class="flex justify-end items-center">
                    //         <button
                    //           type="button"
                    //           class="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]"
                    //         >
                    //           Export
                    //         </button>
                    //       </div>
                    //     </div>
                    //     <div class={mainTable.tableShadowWidth}>
                    //       <TableGrid
                    //         className={mainTable.Table}
                    //         // headerData={IssueToProdHeader}
                    //         // tableData={bindUserMasterDatas}
                    //         // onAddressGrid={HandleDetails}
                    //       />
                    //     </div>
                    //     <div className={mainTable.submitCancelMainDiv}>
                    //       <button
                    //         // disabled={currentPage1 === 1}
                    //         // onClick={() => setCurrentPage(currentPage1 - 1)}
                    //         type="button"
                    //         className={mainTable.submitButton}
                    //       >
                    //         Previous
                    //       </button>
                    //       <label>1</label>
                    //       <button
                    //         // disabled={currentPage1 === totalPages / result}
                    //         // onClick={() => setCurrentPage(currentPage1 + 1)}
                    //         type="button"
                    //         className={mainTable.cancelButton}
                    //       >
                    //         Next
                    //       </button>
                    //     </div>
                    //   </div>
                    // </div>
                )}
            </div>
        </MainLayout>
    );
};

export default ReportOutschoolSession;
