import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";

const UserCapabilityTable = ({
  menuData,
  handleChange,
  handleRoleChange,
  handleUserCapability,
}) => {
  console.log("menuData", menuData);
  const [bindRoleDD, setBindRoleDD] = useState([]);
  const [role, setRole] = useState("");
  const [tableVisibility, setTableVisibility] = useState(false);
  const [roleVsCapabilityOptions, setRoleVsCapabilityOptions] = useState([]);
  const [controlUserCapabilityData, setControlUserCapabilityData] = useState(
    []
  );

  const handleCheckboxChange = (isChecked, key, name, index, subIndex) => {
    console.log("list Name", isChecked, key, name, index, subIndex);
    const updatedMenuData = { ...menuData };

    if (name === "ALL") {
      Object.keys(updatedMenuData).forEach((menuKey) => {
        updatedMenuData[menuKey].forEach((menu) => {
          console.log("menuAlldata", menu);
          if (!menu.MainMenuId) {
            Object.keys(menu.checked).forEach((element) => {
              console.log("element", element);
              menu.checked[element] = isChecked;
              if (isChecked && typeof menu.checked[element] === "boolean") {
                menu.checked[element] = {
                  title: isChecked,
                  add: isChecked,
                  view: isChecked,
                  edit: isChecked,
                  delete: isChecked,
                  inActive: isChecked,
                  service: isChecked,
                };
              }
              // } else if (
              //   !isChecked &&
              //   typeof menu.checked[element] === "object"
              // ) {
              //   menu.checked[element] = isChecked;
              // }
            });
          }

          if (menu.SubMenu && menu.SubMenu.length > 0) {
            menu.SubMenu.forEach((submenu) => {
              Object.keys(submenu.checked).forEach((element) => {
                console.log("SubMenuElement", element);
                submenu.checked[element] = isChecked;
              });
              console.log("subMenu", submenu);
            });
          }
        });
        console.log("updatedMenuDataAll", updatedMenuData);
        // allCheck({ ...updatedMenuData });

        allCheck({ ...updatedMenuData }, (isCheck) => {
          console.log("isCheckData", isCheck);

          if (isCheck) {
            console.log("All checkboxes are checked!");
            // Perform actions when all checkboxes are checked
          } else {
            console.log("Not all checkboxes are checked.");
            // Perform actions when not all checkboxes are checked
          }
        });
      });
    } else {
      if (name === "title") {
        if (
          updatedMenuData[key][index].checked &&
          Array.isArray(updatedMenuData[key][index].checked)
        ) {
          updatedMenuData[key][index].checked.forEach((item) => {
            if (typeof item === "object") {
              Object.keys(item).forEach((e) => {
                if (typeof item[e] === "boolean") {
                  item[e] = isChecked;
                }
              });
            }
            console.log("updatedMenuData5555", updatedMenuData);
          });
        } else {
          if (updatedMenuData[key][index].SubMenu[subIndex]) {
            let SubMenuClicked = updatedMenuData[key][index].SubMenu[subIndex];
            console.log("SubMenuClicked", SubMenuClicked);

            for (let i in SubMenuClicked.checked) {
              console.log("Submenkey", i);

              SubMenuClicked.checked[i] = isChecked;
            }
          }
        }
        allCheck({ ...updatedMenuData }, (isCheck) => {
          console.log("isCheckData", isCheck);

          if (isCheck) {
            console.log("All checkboxes are checked!");
            // Perform actions when all checkboxes are checked
          } else {
            console.log("Not all checkboxes are checked.");
            // Perform actions when not all checkboxes are checked
          }
        });
      } else {
        if (updatedMenuData[key][index].SubMenu[subIndex]) {
          const subMenu = updatedMenuData[key][index].SubMenu[subIndex];

          if (updatedMenuData[key][index].SubMenu[subIndex]) {
            const subMenu = updatedMenuData[key][index].SubMenu[subIndex];
            console.log("SubmenuID", subMenu.checked);
            if (typeof subMenu.checked === "object") {
              Object.keys(subMenu.checked).forEach((key) => {
                if (key === name) {
                  subMenu.checked[key] = isChecked;
                }
              });

              // Apply the condition for the SubMenu items
              const subMenuChecked = subMenu.checked;
              if (
                subMenuChecked.add &&
                subMenuChecked.view &&
                subMenuChecked.edit &&
                subMenuChecked.delete &&
                subMenuChecked.inActive
                // subMenuChecked.service
              ) {
                subMenuChecked.title = isChecked;
              } else {
                subMenuChecked.title = false;
              }
            }
          }
        } else {
          updatedMenuData[key][index].checked.forEach((item, itemIndex) => {
            if (typeof item === "object") {
              Object.keys(item).forEach((key) => {
                if (key === name) {
                  item[key] = isChecked;
                }
              });
            } else {
              updatedMenuData[key][index].checked[itemIndex] = {
                [name]: isChecked,
              };
            }

            if (
              updatedMenuData[key][index].checked[0].add &&
              updatedMenuData[key][index].checked[0].view &&
              updatedMenuData[key][index].checked[0].edit &&
              updatedMenuData[key][index].checked[0].delete &&
              updatedMenuData[key][index].checked[0].inActive &&
              updatedMenuData[key][index].checked[0].service
            ) {
              updatedMenuData[key][index].checked[0].title = isChecked;
            } else {
              updatedMenuData[key][index].checked[0].title = false;
            }
          });
        }
      }
    }

    console.log("updatedMenuData", updatedMenuData);
    handleChange(updatedMenuData);
  };

  const allCheck = (data, callback) => {
    console.log("menuDataAll", data);
    let isCheck = true;

    Object.keys(data).forEach((key) => {
      if (data[key]) {
        data[key].forEach((e) => {
          if (e && e.checked) {
            e.checked.forEach((l) => {
              if (l) {
                Object.keys(l).forEach((item) => {
                  if (l[item] === false) {
                    isCheck = false;
                  }
                });
              }
            });
            console.log("HHHH", isCheck);
          }

          // Add a check for SubMenu here
          if (e.SubMenu && e.SubMenu.length > 0) {
            e.SubMenu.forEach((k) => {
              console.log("SubAll", k);
              let subClickedAll = k;
              console.log("subClickedAll", k.checked);

              if (
                k.checked &&
                Object.values(k.checked).some((value) => value === false)
              ) {
                isCheck = false;
              }
            });
          }
        });
      }
    });

    console.log("KKKK", isCheck);
    callback(isCheck);
    return isCheck;
  };
  const isAllCheck = allCheck({ ...menuData }, (isCheck) => {
    console.log("isCheckData", isCheck);
    // Use isCheck value here or perform further actions
  });
  console.log("isAllCheaked", isAllCheck);

  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    const convertedMenuData = [];

    Object.keys(menuData).forEach((key) => {
      menuData[key].forEach((item) => {
        // Process main menu if MstMenuId is not null
        if (item.MstMenuId !== null) {
          convertedMenuData.push({
            MstRoleId: handleRoleChange,
            MstMenuHeadingId: item.MstMenuHeadingId,
            MstMenuId: item.MstMenuId,
            ViewPermission: item.checked[0].view ? 1 : 0,
            AddPermission: item.checked[0].add ? 1 : 0,
            ModifyPermission: item.checked[0].edit ? 1 : 0,
            DeletePermission: item.checked[0].delete ? 1 : 0,
            InActivePermission: item.checked[0].inActive ? 1 : 0,
            ProvideServicePermission: item.checked[0].service ? 1 : 0,
            CreatedBy: createdBy,
          });
        }

        // Process submenus if available
        if (item.SubMenu && item.SubMenu.length > 0) {
          item.SubMenu.forEach((submenu) => {
            // Process submenu if MstMenuId is not null
            if (submenu.MstMenuId !== null) {
              convertedMenuData.push({
                MstRoleId: handleRoleChange,
                MstMenuHeadingId: item.MstMenuHeadingId,
                MstMenuId: submenu.MstMenuId,
                ViewPermission: submenu.checked.view ? 1 : 0,
                AddPermission: submenu.checked.add ? 1 : 0,
                ModifyPermission: submenu.checked.edit ? 1 : 0,
                DeletePermission: submenu.checked.delete ? 1 : 0,
                InActivePermission: submenu.checked.inActive ? 1 : 0,
                ProvideServicePermission: submenu.checked.service ? 1 : 0,
                CreatedBy: createdBy,
              });
            }
          });
        }
      });
      handleUserCapability(convertedMenuData);
    });
  }, [
    menuData ? menuData : undefined,
    handleRoleChange ? handleRoleChange : undefined,
    handleUserCapability ? handleUserCapability : undefined,
  ]);

  // useEffect(() => {
  //   // Check if menuData has data
  //   var createdBy = sessionStorage.getItem("createdBy");
  //   if (Object.keys(menuData).length > 0) {
  //     // Convert menuData to the desired format
  //     const convertedMenuData = Object.keys(menuData).flatMap((key) => {
  //       return menuData[key].map((item) => ({
  //         MstRoleId: handleRoleChange,
  //         MstMenuHeadingId: item.MstMenuHeadingId,
  //         MstMenuId: item.MstMenuId,
  //         ViewPermission: item.checked.view ? 1 : 0,
  //         AddPermission: item.checked.add ? 1 : 0,
  //         ModifyPermission: item.checked.edit ? 1 : 0,
  //         DeletePermission: item.checked.delete ? 1 : 0,
  //         InActivePermission: item.checked.inActive ? 1 : 0,
  //         ProvideServicePermission: item.checked.service ? 1 : 0,
  //         CreatedBy: createdBy,
  //       }));
  //     });

  //     // Call handleUserCapability with the updated data
  //     handleUserCapability(convertedMenuData);
  //   }
  // }, [menuData, handleRoleChange, handleUserCapability]);

  useEffect(() => {
    if (handleRoleChange) {
      setTableVisibility(true);
    } else {
      setTableVisibility(false);
    }
  }, [handleRoleChange ? handleRoleChange : null]);

  return (
    <div className="relative w-full">
      <div className="mx-0">
        <div className={mainTable.tableShadowWidth}>
          {tableVisibility && (
            <div className={mainTable.tableShadowWidth}>
              <div className={mainTable.userCapabilityTable} role="table">
                <div className="overflow-x-auto w-full">
                  <div
                    className={mainTable.userCapabilityHeader}
                    role="rowgroup"
                  >
                    <div className="md:w-64  px-2 py-3">
                      <label class="text-sm flex gap-4 font-medium">
                        <input
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.checked,
                              "",
                              "ALL"
                            )
                          }
                          checked={isAllCheck}
                          data-type={isAllCheck}
                          type="checkbox"
                        />
                        Select All Menus
                      </label>
                    </div>
                    <div className="md:w-96 whitespace-pre-line 2xl:flex-1 px-2 py-3">
                      <label class="text-sm font-medium">Rights</label>
                    </div>
                  </div>

                  <div className="overflow-y-auto h-96">
                    {Object.keys(menuData).map((key, i) => (
                      <div className="bg-stone-400" key={i}>
                        <div className="font-bold text-white p-1 ml-1">
                          {key}
                        </div>
                        <div>
                          {menuData[key].map((ele, index) => (
                            <div
                              class={`${
                                !(ele.SubMenu && ele.SubMenu.length > 0)
                                  ? mainTable.roleVsCapabiltyCss
                                  : mainTable.userCapabilityRowSubMenu
                              }`}
                            >
                              {ele.MainMenuId ? (
                                <div
                                  className="w-full  bg-[#2d2d6e] font-bold text-white p-1 ml-1"
                                  onChange={(event) =>
                                    handleCheckboxChange(
                                      event.target.checked,
                                      key,
                                      "title",
                                      index
                                    )
                                  }
                                >
                                  <label class="flex gap-2">
                                    {ele.MenuName}
                                  </label>
                                </div>
                              ) : (
                                <div
                                // class={`${
                                //   !(ele.SubMenu && ele.SubMenu.length > 0)
                                //     ? mainTable.roleVsCapabiltyCss
                                //     : mainTable.userCapabilityRowSubMenu
                                // }`}
                                >
                                  <div className="md:w-64 px-2 py-3">
                                    <label class="flex whitespace-break-spaces gap-2">
                                      <input
                                        type="checkbox"
                                        data-type={
                                          ele.checked[0].title +
                                          "_" +
                                          ele.MenuName
                                        }
                                        checked={ele.checked[0].title}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "title",
                                            index
                                          )
                                        }
                                      />
                                      {ele.MenuName}
                                    </label>
                                  </div>
                                </div>
                              )}

                              {!ele.MainMenuId && (
                                <div className="md:w-96 whitespace-pre-line 2xl:flex-1 px-2 py-3 flex-col md:flex md:flex-row gap-2">
                                  {ele?.Add == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].add}
                                        data-type={ele.checked[0].add}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "add",
                                            index
                                          )
                                        }
                                      />
                                      Add
                                    </label>
                                  )}
                                  {ele?.View == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].view}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "view",
                                            index
                                          )
                                        }
                                      />
                                      View
                                    </label>
                                  )}
                                  {ele?.Edit == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].edit}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "edit",
                                            index
                                          )
                                        }
                                      />
                                      Edit
                                    </label>
                                  )}
                                  {ele?.Delete == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].delete}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "delete",
                                            index
                                          )
                                        }
                                      />
                                      Delete
                                    </label>
                                  )}
                                  {ele?.Inactive == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].inActive}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "inActive",
                                            index
                                          )
                                        }
                                      />
                                      Inactive
                                    </label>
                                  )}
                                  {ele?.Service == 1 && (
                                    <label className="flex gap-1 mx-2">
                                      <input
                                        type="checkbox"
                                        checked={ele?.checked[0].service}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event.target.checked,
                                            key,
                                            "service",
                                            index
                                          )
                                        }
                                      />
                                      Service
                                    </label>
                                  )}
                                </div>
                              )}
                              {ele.SubMenu && ele.SubMenu.length > 0 && (
                                <div className="w-full">
                                  {ele.SubMenu.map((subItem, subIndex) => (
                                    <div
                                      className={mainTable.roleVsCapabiltyCss}
                                      key={subIndex}
                                    >
                                      <div className="md:w-64 md:flex block flex-row px-2 py-3">
                                        <label className="flex whitespace-break-spaces gap-2">
                                          <input
                                            type="checkbox"
                                            checked={subItem.checked.title}
                                            data-type={
                                              subItem.checked.title +
                                              "_" +
                                              subItem.SubMenu
                                            }
                                            onChange={(event) =>
                                              handleCheckboxChange(
                                                event.target.checked,
                                                key,
                                                "title",
                                                index,
                                                subIndex
                                              )
                                            }
                                          />
                                          {subItem.SubMenu}
                                        </label>
                                      </div>
                                      <div className="md:w-96 whitespace-pre-line 2xl:flex-1 px-2 py-3 flex-col md:flex md:flex-row gap-2">
                                        {ele?.Add == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              data-type={subItem.checked.add}
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "add",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.Add == 1
                                                  ? subItem.checked.add
                                                  : false
                                              }
                                            />
                                            Add
                                          </label>
                                        )}
                                        {ele?.View == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "view",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.View == 1
                                                  ? subItem.checked.view
                                                  : false
                                              }
                                            />
                                            View
                                          </label>
                                        )}
                                        {ele?.Edit == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "edit",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.Edit == 1
                                                  ? subItem.checked.edit
                                                  : false
                                              }
                                            />
                                            Edit
                                          </label>
                                        )}
                                        {ele?.Delete == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "delete",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.Delete == 1
                                                  ? subItem.checked.delete
                                                  : false
                                              }
                                            />
                                            Delete
                                          </label>
                                        )}
                                        {ele?.Inactive == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "inActive",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.Inactive == 1
                                                  ? subItem.checked.inActive
                                                  : false
                                              }
                                            />
                                            Inactive
                                          </label>
                                        )}
                                        {ele?.Service == 1 && (
                                          <label class="flex gap-1 mx-2">
                                            <input
                                              type="checkbox"
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event.target.checked,
                                                  key,
                                                  "service",
                                                  index,
                                                  subIndex
                                                )
                                              }
                                              checked={
                                                ele?.Service == 1
                                                  ? subItem.checked.service
                                                  : false
                                              }
                                            />
                                            Service
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCapabilityTable;
