// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { useState } from "react";
import { stateMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import Statepopup from "../popup-containers/Statepopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const State = () => {
  const location = useLocation();
  const [bindStateMasterDatas, setbindStateMasterDatas] = useState([]);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibleSubmit, setVisibleSubmit] = useState(false);
  const [visibleAddScreenTitle, setVisibleAddScreenTitle] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  // View details start

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [stateData, setstateData] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // view details end

  const [bindCountriesDD, setBindCountriesDD] = useState([]);
  const [mstStateId, setMstStateId] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [stateError, setStateError] = useState("");
  const [stateCodeError, setStateCodeError] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // search end
  const showAdd = () => {
    setVisibleAdd(true);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setVisibleTable(false);
    setVisibleSubmit(true);
    setVisibleUpdate(false);
    setVisibleAddScreenTitle(true);
  };

  const cancel = () => {
    getStateTableDatas();
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    setState("");
    setStateCode("");
    setStateError("");
    setStateCodeError("");
  };

  useEffect(() => {
    bindStateMasterDatas && getStateTableDatas();
  }, []);

  useEffect(() => {
    // Getting language codes
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  useEffect(() => {
    getStateTableDatas();
  }, []);
  // Getting State Dropdown values
  const getStateTableDatas = async () => {
    const response = await BindAPI("StateAPI/BindState");
    setbindStateMasterDatas(response.data.Table);
  };
  useEffect(() => {
    // Getting Country Dropdown values
    const getCountryTableDatas = async () => {
      const response = await BindAPI("CountryAPI/BindCountry");
      setBindCountriesDD(response.data.Table);
    };
    getCountryTableDatas();
  }, []);

  const handleEdit = (rowIndex) => {
    // Edit functionality
    setAddScreenTitle(false);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setVisibleSubmit(false);
    setVisibleUpdate(true);
    setVisibleAddScreenTitle(false);

    setMstStateId(bindStateMasterDatas[rowIndex].MstStateId);
    setState(bindStateMasterDatas[rowIndex].StateName_en);
    setStateCode(bindStateMasterDatas[rowIndex].StateCode);
  };

  const handleState = (rowIndex, flag) => {
    // Setting visibility in popup container
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setstateData(bindStateMasterDatas[rowIndex]);
  };

  const handleOpen = (isOpen) => {
    // Popup open
    setpopUpOpen(isOpen);
  };

  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    // Submit functionality
    setSubmitBtnDisabled(true);
   
    // Validation end
    if (flag == 1 || flag == 2) {
       // validation start
    let stateValidate = "";
    let stateCodeValidate = "";
    stateValidate =
      state.length > 0
        ? /^[A-Za-z ]+$/.test(state)
          ? !visibleUpdate &&
            bindStateMasterDatas.find(
              (x) => x.StateName_en.toLowerCase() == state.toLowerCase()
            )
            ? "State Name already available"
            : ""
          : "Only Alphabet and space allowed"
        : "Enter State";

    stateCodeValidate =
      stateCode.length > 0
        ? /^[A-Z]{2}$/.test(stateCode)
          ? !visibleUpdate &&
            bindStateMasterDatas.find(
              (x) => x.StateCode.toLowerCase() == stateCode.toLowerCase()
            )
            ? "State code already available"
            : ""
          : "Enter 2 characters and should be uppercase"
        : "Enter State Code";
    setStateError(stateValidate);
    setStateCodeError(stateCodeValidate);

    if(stateValidate || stateCodeValidate ){
      setIsOpen(true);
    }

      if (!stateValidate && !stateCodeValidate) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = []; // Create an array to store translations
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(state, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const statePayload = [
          {
            stateCode: stateCode,
            mstStateId: mstStateId ? mstStateId : 0,
            mstCountryId: 1,
            stateNameEn: state,
            stateNameTa: translatedTexts[0],
            stateNameHi: translatedTexts[1],
            stateNameMr: translatedTexts[2],
            stateNameGu: translatedTexts[3],
            stateNameKn: translatedTexts[4],
            stateNameMl: translatedTexts[5],
            stateNameTe: translatedTexts[6],
            stateNameBn: translatedTexts[7],
            stateNameOr: translatedTexts[8],
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(statePayload, "StateAPI/IUDState");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].result === "S") {
            toast.success("State data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add State data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update

          if (response.data.Table[0].result === "M") {
            toast.success("State data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update State data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }else{
        setSubmitBtnDisabled(false);
      }
    } else {
      const statePayload = [
        {
          MstCountryId: 1,
          mstStateId: bindStateMasterDatas[items].MstStateId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(statePayload, "StateAPI/IUDState");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].result === "D") {
          toast.success("State data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete State data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].result === "B") {
          toast.success("State data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block State data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].result === "UB") {
          toast.success("State data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock State data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location.pathname == "/statemaster") {
      // setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      getStateTableDatas();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={"State Master"}
          header={stateMasterHeader}
          records={bindStateMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleState}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full">
          <div class="border-black">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="State Master"
            />
          </div>

          <div class="mx-auto  md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6">
                    <DropDown
                      classDiv="hidden"
                      classStar="text-red-500"
                      title={"Country"}
                      options={[]}
                      placeholder={"Select"}
                      name={"country"}
                      selectedOption={country}
                      controlFunc={(e) => setCountry(e.target.value)}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"State"}
                      name={"state"}
                      content={state}
                      controlFunc={(e) => {
                        setState(e.target.value)
                      }}
                      controlFuncBlur={(e) => {
                        const trimmedValue = e.target.value.trim();
                        setState(trimmedValue);
                        setStateError(
                          trimmedValue.length > 0
                            ? /^[A-Za-z ]+$/.test(trimmedValue)
                              ? !visibleUpdate &&
                                bindStateMasterDatas.find(
                                  (x) =>
                                    x.StateName_en.toLowerCase() ==
                                  trimmedValue.toLowerCase()
                                )
                                ? "State Name already available"
                                : ""
                              : "Only Alphabet and space allowed"
                            : "Enter State"
                        );
                      }}
                      errorMessage={stateError}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"State Code"}
                      name={"state"}
                      content={stateCode}
                      disabled={visibleUpdate ? visibleUpdate : false}
                      controlFunc={(e) => setStateCode(e.target.value)}
                      controlFuncBlur={(e) => {
                        setStateCodeError(
                          stateCode.length > 0
                            ? /^[A-Z]{2}$/.test(stateCode)
                              ? !visibleUpdate &&
                                bindStateMasterDatas.find(
                                  (x) =>
                                    x.StateCode.toLowerCase() ==
                                    stateCode.toLowerCase()
                                )
                                ? "State code already available"
                                : ""
                              : "Enter minimum 2 charactor and should be uppercase"
                            : "Enter State Code"
                        );
                      }}
                      errorMessage={stateCodeError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={visibleSubmit}
                    updateScreenTitle={visibleUpdate}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
 {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleState}
      >
        <div>
          <PopUpScreenTitle
            title="State Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <Statepopup data={stateData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default State;
