// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import download from "../image/download.png";
import MultiSelect from "../ReusableComponents/MultiSelect";

const UserDetails = ({ data, data2, data3 }) => {
  const [sdp, setSdp] = useState([]);
  const [sdpDropDown, setSdpDropDown] = useState([]);
  useEffect(() => {
    bindUserSdp(data3);
  }, [data3]);

  const bindUserSdp = (sdp) => {
    try {
      if (sdp && sdp.length > 0) {
        const sdpData = sdp.map((item) => ({
          value: item.MstSDPId,
          label: item.SDP_en,
        }));
        setSdpDropDown(sdpData);
      } else {
        setSdpDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  // useEffect(() => {
  //   if (data2 && data2.length > 4) {
  //     let sdpDatas = data2[4];
  //     sdpDatas && setSdp(sdpDatas.split(",").map((item) => parseInt(item)));
  //   }
  // }, [data2]);
  const [bindOrganizationStructure, setBindOrganizationStructure] = useState([
    { value: 1, label: "Head Quarters" },
    { value: 2, label: "Branch/Project" },
    { value: 3, label: "S.D.P." },
  ]);

  return (
    <div class="w-full">
      <div>
        <div className={mainTable.mainContentScreen}>
          <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
            <div className={mainTable.grid3}>
              <InputText
                inputType={"text"}
                title={"First Name"}
                name={"firstName"}
                content={data.FirstName_en}
                classStar={"text-red-500"}
                disabled={true}
              />

              <InputText
                inputType={"text"}
                title={"Middle Name"}
                name={"middleName"}
                content={data.MiddleName_en}
                disabled={true}
              />
              <InputText
                inputType={"text"}
                title={"Last Name"}
                name={"lastName"}
                content={data.LastName_en}
                classStar={"text-red-500"}
                disabled={true}
              />

              <InputText
                inputType={"email"}
                title={"Email"}
                name={"email"}
                placeholder={"abc@gmail.com"}
                content={data.UserEmail}
                classStar={"text-red-500"}
                disabled={true}
              />

              <InputText
                inputType={"text"}
                title={"Mobile Number"}
                name={"phone"}
                placeholder={"9347583939"}
                content={data.UserMobile}
                classStar={"text-red-500"}
                disabled={true}
              />
              <AutoDropDown
                title="Designation"
                options={[]}
                placeholder="Select"
                name="state"
                selectedOption={data2[0]}
                Class="custom-class"
                //disabled="false"
                tabIndex="1"
                classStar={"text-red-500"}
                disabled={true}
              />
              <AutoDropDown
                title="Role/Groups"
                options={[]}
                placeholder="Select"
                name="state"
                selectedOption={data2[1]}
                Class="custom-class"
                //disabled="false"
                tabIndex="1"
                classStar={"text-red-500"}
                disabled={true}
              />
              <DropDown
                title="Organization Structure"
                options={bindOrganizationStructure}
                placeholder="Select"
                classStar="text-red-500"
                name="organizationStructure"
                selectedOption={data.MstOrganizationId}
                disabled={true}
              />
              <DropDown
                classDiv="hidden"
                title="Country"
                options={[]}
                placeholder="Select"
                name="country"
                selectedOption={"country"}
              />
              {(data.MstOrganizationId == 2 || data.MstOrganizationId == 3) && (
                <AutoDropDown
                  title="State"
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={data2[2]}
                  Class="custom-class"
                  //disabled="false"
                  tabIndex="1"
                  classStar={"text-red-500"}
                  disabled={true}
                />
              )}
              {(data.MstOrganizationId == 2 || data.MstOrganizationId == 3) && (
                <AutoDropDown
                  title="Branch/Project"
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={data2[3]}
                  Class="custom-class"
                  //disabled="false"
                  tabIndex="1"
                  classStar={"text-red-500"}
                  disabled={true}
                />
              )}
              {data.MstOrganizationId == 3 && (
                <div class="flex flex-col">
                  <MultiSelect
                    label="S.D.P."
                    classStar={"text-red-400"}
                    filteredOptions={sdpDropDown}
                    classFilterLabel="p-1 flex gap-1"
                    placeholder={sdp ? "More S.D.P. Selected" : "Select..."}
                    class="custom-class"
                    name="topicsNames"
                    selectedOptions={data2[4]}
                    disabled={true}
                  />
                </div>
              )}
              {/* <InputText
                inputType="text"
                title="User Id"
                placeholder="MUM-PO-00001"
                name="userId"
                content={data.UserId}
              /> */}

              <div className="flex">
                {data && data.UserProfileImg != null && data.UserProfileImg != "" && (
                  <div>
                    <img
                      src={data ? data?.UserProfileImg : download}
                      alt="Image"
                      className="rounded-full w-20 h-20"
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
