import React from "react";

const PopUpScreenTitle = ({title,setpopUpOpen,viewDetails,deleteDetails,blockDetails,unBlockDetails}) => {
  return (
    <div>
      <button className="w-full">
        <span
          className="text-white flex justify-end"
          onClick={() => setpopUpOpen(false)}
          class="text-2xl p-0 m-o block float-right mr-3"
        >
          &times;
        </span>
      </button>
      <div class="text-xl font-medium text-neutral-700 mx-5">
        {viewDetails && `${title} - View`}
        {deleteDetails && `${title} - Delete`}
        {blockDetails && `${title} - Block`}
        {unBlockDetails && `${title} - Unblock`}
      </div>
    </div>
  );
};

export default PopUpScreenTitle;
