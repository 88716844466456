// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import {
  projectMasterHeaders,
  servicesCategoryHeaders,
} from "../components/HeaderAttribute";
import ServiceCategory from "../popup-containers/ServiceCategory";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import ProjectPopup from "../popup-containers/ProjectPopup";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const ProjectMaster = () => {
  const location = useLocation();
  // All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  // Services Category Master insert and edit useState hooks handling .
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [bindBranchProject, setBindBranchProject] = useState([]);
  const [branchProject, setBranchProject] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [Project, setProject] = useState("");
  const [mstProjectId, setMstProjectId] = useState("");
  const [projectError, setProjectError] = useState("");
  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  // All bind data value useState hooks handling.
  const [bindProjectDatas, setBindProjectDatas] = useState([]);
  const [ProjectData, setProjectData] = useState("");

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  // Language translate useState hooks handling.
  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  // This function is helping for Language translation .
  useEffect(() => {
    const getLanguageCode = async () => {
      const response = await BindAPI("LanguageAPI/GetLanguage");
      setLanguageCodekey(response.data.Table);
    };
    getLanguageCode();
  }, []);

  useEffect(() => {
    const BindAllmasterData = async () => {
      const bindReferredBydatadrp = await BindAPI("LocationAPI/GetLocation");
      const filteredData = bindReferredBydatadrp.data.Table;
      const filteredWithBlockedByNull = filteredData.filter(
        (item) => item.BlockedBy === null
      );

      bindBranchProjectData(filteredWithBlockedByNull);
    };
    BindAllmasterData();
  }, []);

  const bindBranchProjectData = async (resultdata) => {
    try {
      if (resultdata.length > 0) {
        const branchProjectDD = resultdata.map((m) => ({
          value: m.MstLocationId,
          label: m.LocationName_en,
        }));
        setBindBranchProject(branchProjectDD);
      } else {
        setBindBranchProject([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  useEffect(() => {
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // This function is helped to the viwe, delete, block and unblock
  // input value via rowindex passing for Services Category .
  const handleServiceCategory = (rowIndex, flag) => {
    debugger;
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const projectData = bindProjectDatas[rowIndex];
    //Location DropDown Value Start
    const locationDropDownValue = bindBranchProject.find(
      (item) => item.value === projectData.MstBranchProjectId
    );
    const editLocationDropDownValue = {
      value: projectData.MstBranchProjectId,
      label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
    };
    const autoDropDownValues = [editLocationDropDownValue];
    setPopUpDropDownData(autoDropDownValues);
    setProjectData(bindProjectDatas[rowIndex]);
  };

  // This function is helped to edit the input value via rowindex passing and helping
  // to visible handling for Services Category.
  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setProject(bindProjectDatas[rowIndex].ProjectName_en);
    setBranchProject(bindProjectDatas[rowIndex].MstBranchProjectId);
    setMstProjectId(bindProjectDatas[rowIndex].MstProjectId);
  };

  // This is Services Category grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setProject("");
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setSubmitBtnDisabled(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setProjectError("");
    setProject("");
    setBranchProjectError("");
    setBranchProject("");
    fetchProjectData();
  };

  // This function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API
  // response for those all and passing the rowindex for Services Category.
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    debugger;
    if (flag == 1 || flag == 2) {
      // validation start
      let projectValidate = "";
      projectValidate = Project.length > 0 ? "" : "Enter Project";
      setProjectError(projectValidate);

      let branchProjectValidate = "";
      branchProjectValidate = branchProject ? "" : "Enter Branch/Project Name";
      setBranchProjectError(branchProjectValidate);

      // Validation end

      if (projectValidate || branchProjectValidate) {
        setIsOpen(true);
      }

      if (!projectValidate && !branchProjectValidate) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(Project, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const projectPayload = {
          mstProjectId: mstProjectId ? mstProjectId : 0,
          mstBranchProjectId: branchProject,
          projectEn: Project,
          projectTa: translatedTexts[0],
          projectHi: translatedTexts[1],
          projectMr: translatedTexts[2],
          projectGu: translatedTexts[3],
          projectMl: translatedTexts[4],
          projectTe: translatedTexts[5],
          projectKn: translatedTexts[6],
          projectBn: translatedTexts[7],
          projectOr: translatedTexts[8],
          createdBy: parseInt(createdBy),
          flag: flag,
        };
        const response = await IUDAPI(projectPayload, "ProjectAPI/IUDProject");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Project added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Project data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            // alert("Not Insert Services Category ");
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Project updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Project data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            // alert("Not Update Services Category ");
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const projectPayload = {
        mstProjectId: bindProjectDatas[items].MstProjectId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI(projectPayload, "ProjectAPI/IUDProject");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success("Project data deleted successfully 🗑️", 5000, cancel());
          cancel();
        } else {
          toast.error("Failed to delete Project data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not Delete Services Category ");
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success("Project data blocked successfully 🚫", 5000, cancel());
          cancel();
        } else {
          toast.error("Failed to block Project data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not Block Services Category ");
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Project data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Project data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not UnBlock Services Category ");
        }
      }
    }
  };

  // This hook function is helped to bind all data from Services Category master table.
  useEffect(() => {
    fetchProjectData();
  }, []);

  const fetchProjectData = async () => {
    const response = await BindAPI("ProjectAPI/BindProject");
    setBindProjectDatas(response.data.Table);
  };

  useEffect(() => {
    if (location.pathname == "/project") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      fetchProjectData();
    }
  }, [location]);

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Project Master"
          header={projectMasterHeaders}
          records={bindProjectDatas}
          handleEdit={handleEdit}
          handleAction={handleServiceCategory}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Project Master"
          />
          <div class={mainTable.firstMainAddScreen}>
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title={"Branch/Project"}
                        options={bindBranchProject}
                        placeholder={"Select"}
                        name={"location"}
                        selectedOption={
                          location
                            ? bindBranchProject.find(
                                (x) => x.value == branchProject
                              )
                            : ""
                        }
                        controlFunc={(value) => {
                          setBranchProject(value.value);
                          setBranchProjectError(
                            value ? "" : "Select Branch/Project Name"
                          );
                          setProject("");
                        }}
                        controlFuncBlur={() => {
                          setBranchProjectError(
                            branchProject ? "" : "Select Branch/Project Name"
                          );
                        }}
                        Class="custom-class"
                        tabIndex="1"
                      />
                      {branchProjectError && (
                        <span class="text-red-500">{branchProjectError}</span>
                      )}
                    </div>
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Project"}
                      name={"Project"}
                      content={Project}
                      controlFunc={(e) => {
                        // setProject(e.target.value)
                        const value = e.target.value;
                        setProject(value);
                        setProjectError(
                          value.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(value)
                              ? ""  // No error if the input is valid
                              : ""
                            : "Enter project"  // Error message if the input is empty
                        );
                      }}
                      controlFuncBlur={(e) => {
                        // setProject(e.target.value);
                        // setProjectError(
                        //   Project.length > 0 ? "" : "Enter project"
                        // );
                        const trimmedValue = e.target.value.trim();
                        setProject(trimmedValue);
                        setProjectError(
                          trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter project"  // Error message if the input is empty
                        );
                      }}
                    />
                    {projectError && (
                      <span class="text-red-500">{projectError}</span>
                    )}
                    <Buttons
                      addScreenTitle={addScreenTitle}
                      updateScreenTitle={updateScreenTitle}
                      handleSubmit={handleSubmit}
                      cancel={cancel}
                      disabledBtn={submitBtnDisabled ? true : false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleServiceCategory}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Project Master"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          {/* <ServiceCategory data={ProjectData} /> */}
          <ProjectPopup data={ProjectData} data1={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default ProjectMaster;
