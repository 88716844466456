// Code review - In Progress
// Documentation - In Progress
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/Table";
import { AwarenessHeader, ServicesName } from "../components/HeaderAttribute";

import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { IUDAwarnessAPI } from "../services/AwarnessAPIServices";

function Awarness() {
  useEffect(() => {
    getCurrentDate();
    const sortedStates = [...bindState].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindState(sortedStates);
    const sortedLocation = [...bindLocation].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindCity(sortedLocation);
    const sortedArea = [...bindArea].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindLocation(sortedArea);
  }, []);

  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);

  const [updateButton, setUpdateButton] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [editMode, setEditMode] = useState([]); //Will be used in Functionality

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);
  const [type, setType] = useState("");

  const [bindProductDatas, setBindProductDatas] = useState([
    {
      sno: 1,
      AwarenessDate: " 10-01-2023 ",
      AwarenessType: "Interpersonal Contacts",
      AwarenessCategory: "Capsules",
      AwarenessTopics: "Contraceptive services.",
      AwarenessRemarks:
        "The key to growth is the introduction of higher dimensions of consciousness into our awareness.",
    },
    {
      sno: 2,
      AwarenessDate: " 12-03-2023 ",
      AwarenessType: "Group Discussion",
      AwarenessCategory: "Capsules",
      AwarenessTopics: "Pregnancy testing and counseling.",
      AwarenessRemarks:
        "The first step toward change is awareness. The second step is acceptance — Nathaniel Branden",
    },
    {
      sno: 3,
      AwarenessDate: " 11-04-2023 ",
      AwarenessType: "General / Mass Meetings",
      AwarenessCategory: "Capsules",
      AwarenessTopics: "Basic infertility services.",
      AwarenessRemarks:
        " The more solitary, the more friendless, the more unsustained I am, the more I will respect myself.",
    },
    {
      sno: 4,
      AwarenessDate: " 15-06-2023 ",
      AwarenessType: "Mass Media",
      AwarenessCategory: "Capsules",
      AwarenessTopics: "Sexually transmitted infection and HIV services",
      AwarenessRemarks: "The first step toward change is awareness.",
    },
    {
      sno: 5,
      AwarenessDate: " 22-09-2023 ",
      AwarenessType: "Panadol",
      AwarenessCategory: "Capsules",
      AwarenessTopics: "Reduce pregnancies in adolescents",
      AwarenessRemarks:
        "Every human has four endowments - self awareness, conscience, independent will and creative imagination.",
    },
  ]);
  const [bindCategoryDD, setBindCategoryDD] = useState([
    { value: "1", label: "Capsules" },
    { value: "1", label: "Drops" },
    { value: "1", label: "Inhalers" },
  ]);
  const [bindUnitOfMeasure, setBindUnitOfMeasure] = useState([
    { value: "1", label: "mg" },
    { value: "2", label: "ml" },
    { value: "3", label: "g" },
  ]);
  const [genderRadio, setGenderRadio1] = useState([
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
    { value: "3", label: "Trans Woman" },
    { value: "3", label: "Trans Man" },
    { value: "3", label: "Non-binary" },
  ]);
  const [bindSdp, setBindSDP] = useState([
    { value: "1", label: "Static Clinic" },
    { value: "2", label: "Satellite clinic" },
    { value: "3", label: "UFWC" },
    { value: "4", label: "Outreach" },
    { value: "5", label: "CBPs" },
    { value: "6", label: "Associated Clinics" },
    { value: "7", label: "DHI" },
  ]);
  const [bindtopics, setBindTopics] = useState([
    { value: "1", label: "Art and culture" },
    { value: "2", label: "Geography and places" },
    { value: "3", label: "Health and fitness" },
    { value: "4", label: "History and events" },
  ]);
  const [bindreferredto, setBindreferredto] = useState(ServicesName);

  const [bindreferelservice, setBindreferelservice] = useState([
    { value: "1", label: "Static Clinic" },
    { value: "2", label: "Sattelite Clinic" },
    { value: "3", label: "UFWC" },
    { value: "4", label: "Private Facility" },
    { value: "5", label: "Govt Facility" },
  ]);
  const [bindCountry, setBindCountry] = useState([
    { value: "1", label: "America" },
    { value: "2", label: "India" },
    { value: "3", label: "Korea" },
  ]);
  const [bindState, setBindState] = useState([
    { value: "1", label: "Arunachal Pradesh" },
    { value: "2", label: "Andhra Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Delhi" },
    { value: "7", label: "Haryana" },
    { value: "8", label: "Himachal Pradesh" },
    { value: "9", label: "Nagaland" },
    { value: "10", label: "Karnataka" },
    { value: "11", label: "Kerala" },
    { value: "12", label: "Rajasthan" },
    { value: "13", label: "Odisha" },
    { value: "14", label: "Goa" },
    { value: "15", label: "Gujarat" },
    { value: "16", label: "Jammu & Kashmir" },
    { value: "17", label: "West Bengal" },
    { value: "18", label: "Uttarakhand" },
    { value: "19", label: "Uttar Pradesh" },
    { value: "20", label: "Maharashtra" },
    { value: "21", label: "Meghalaya" },
    { value: "22", label: "Manipur" },
    { value: "23", label: "Madhya Pradesh" },
    { value: "24", label: "Mizoram" },
    { value: "25", label: "Jharkhand" },
    { value: "26", label: "Punjab" },
    { value: "27", label: "Telangana" },
    { value: "28", label: "Tripura" },
    { value: "29", label: "Tamil Nadu" },
  ]);
  const [bindLocation, setBindCity] = useState([
    { value: "1", label: "Ahmednagar" },
    { value: "2", label: "Amravati" },
    { value: "3", label: "Aurangabad" },
    { value: "4", label: "Beed" },
    { value: "5", label: "Buldhana" },
    { value: "6", label: "Chandrapur" },
    { value: "7", label: "Dhule" },
    { value: "8", label: "Jalgaon" },
    { value: "9", label: "Jalna" },
    { value: "10", label: "Nanded" },
    { value: "11", label: "Gondia" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Sindhudurg" },
    { value: "14", label: "Pune" },
    { value: "15", label: "Thane" },
    { value: "16", label: "Nashik" },
    { value: "17", label: "Nagpur" },
    { value: "18", label: "Osmanabad" },
    { value: "19", label: "Kolhapur" },
    { value: "20", label: "Latur" },
    { value: "21", label: "Mumbai Suburban District" },
    { value: "22", label: "Mumbai" },
    { value: "23", label: "Ratnagiri" },
  ]);
  const [bindArea, setBindLocation] = useState([
    { value: "1", label: "Achalpur" },
    { value: "2", label: "Ambarnath" },
    { value: "3", label: "Amravati" },
    { value: "4", label: "Barshi" },
    { value: "5", label: "Badlapur" },
    { value: "6", label: "Bhusawal" },
    { value: "7", label: "Buldhana" },
    { value: "8", label: "Jalna" },
    { value: "9", label: "Gondia" },
    { value: "10", label: "Sangli" },
    { value: "11", label: "Dhule" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Hinganghat" },
    { value: "14", label: "Kalyan-Dombivli" },
    { value: "15", label: "Navi Mumbai" },
    { value: "16", label: "PCMC, Pune" },
    { value: "17", label: "Aurangabad" },
    { value: "18", label: "Malegaon" },
    { value: "19", label: "Panvel" },
    { value: "20", label: "Vasai-Virar City MC" },
    { value: "21", label: "Washim" },
    { value: "22", label: "Osmanabad" },
    { value: "23", label: "Nandurbar" },
    { value: "24", label: "Solapur" },
    { value: "25", label: "Wardha" },
    { value: "26", label: "Udgir" },
    { value: "27", label: "Yavatmal" },
    { value: "28", label: "Yavatmal" },
    { value: "29", label: "Nariman Point" },
  ]);

  // const [genderRadio2, setGenderRadio2] = useState([
  //   { value: "1", label: "Male" },
  //   { value: "2", label: "Female" },
  //   { value: "3", label: "Others" },
  // ]);
  // const [genderRadio3, setGenderRadio3] = useState([
  //   { value: "1", label: "Male" },
  //   { value: "2", label: "Female" },
  //   { value: "3", label: "Others" },
  // ]);
  // const [genderRadio4, setGenderRadio4] = useState([
  //   { value: "1", label: "Male" },
  //   { value: "2", label: "Female" },
  //   { value: "3", label: "Others" },
  // ]);

  const [sdp, setSdp] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [location, setLocation] = useState("");
  const [fileUploaded, setFileUploaded] = useState("");
  

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [searchValue, setSearchValue] = useState(0);
  const [participentName1, setParticipentName1] = useState("");
  const [participentAge1, setParticipentAge1] = useState("");
  const [participentGender1, setParticipentGender1] = useState("");
  const [participentName2, setParticipentName2] = useState("");
  const [participentAge2, setParticipentAge2] = useState("");
  const [participentGender2, setParticipentGender2] = useState("");
  const [participentName3, setParticipentName3] = useState("");
  const [participentAge3, setParticipentAge3] = useState("");
  const [participentGender3, setParticipentGender3] = useState("");
  const [participentName4, setParticipentName4] = useState("");
  const [participentAge4, setParticipentAge4] = useState("");
  const [participentGender4, setParticipentGender4] = useState("");
  const [participentreferredto1, setParticipentReferredto1] = useState("");
  const [participentreferredto2, setParticipentReferredto2] = useState("");
  const [participentreferredto3, setParticipentReferredto3] = useState("");
  const [participentreferredto4, setParticipentReferredto4] = useState("");
  const [participentreferelservice1, setParticipentReferelservice1] = useState("");
  const [participentreferelservice2, setParticipentReferelservice2] = useState("");
  const [participentreferelservice3, setParticipentReferelservice3] = useState("");
  const [participentreferelservice4, setParticipentReferelservice4] = useState("");
  const [category, setCategory] = useState("");
  const [topics, setTopics] = useState("");
  const [place, setPlace] = useState("");
  const [noOfParticipants, setNoOfParticipants] = useState("");
  const [openingDate, setOpeningDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState("interpersonalContacts");

  const getCurrentDate = () => {
    
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, "0");
    setOpeningDate(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const HandleAddress = (rowIndex) => { };
  const handleEdit = async (items) => { };

  function handleDropDown(e) { }

  const handleSubmit = async (items, flag) => {
    

    let awarenessData = [];

    if (flag === 1) {
      awarenessData = [
        {
          ICTopics: topics,
          ICDate: openingDate,
          ICState: state,
          ICLocation: location,
          ICSDP: sdp,
          ICArea: area,
          ICPlace: place,
          NoOfParticipants:noOfParticipants,
          FileUpload:fileUploaded,
          ICParticipantName1: participentName1,
          ICAge1: participentAge1,
          ICGender1: participentGender1,
          ICReferelService1: participentreferelservice1,
          ICReferredTo1: participentreferredto1,
          ICParticipantName2: participentName2,
          ICAge2: participentAge2,
          ICGender2: participentGender2,
          ICReferelService2: participentreferelservice2,
          ICReferredTo2: participentreferredto2,
          ICParticipantName3: participentName3,
          ICAge3: participentAge3,
          ICGender3: participentGender3,
          ICReferelService3: participentreferelservice3,
          ICReferredTo3: participentreferredto3,
          ICParticipantName4: participentName4,
          ICAge4: participentAge4,
          ICGender4: participentGender4,
          ICReferelService4: participentreferelservice4,
          ICReferredTo4: participentreferredto4,
          ICDescription: remarks
        },
      ];
    }
    else if (flag === 2) {
      awarenessData = [
        {
          ICTopics: topics
        },
      ];
    }
    else {
      awarenessData = [
        {
          ICInterpersonalContacts:1,
          ICTopics: topics,
          ICDate: openingDate,
          ICState: state,
          ICLocation: location,
          ICSDP: sdp,
          ICArea: area,
          ICPlace: place,
          ICParticipantName1: participentName1,
          ICAge1: participentAge1,
          ICGender1: participentGender1,
          ICReferelService1: participentreferelservice1,
          ICReferredTo1: participentreferredto1,
          ICParticipantName2: participentName2,
          ICAge2: participentAge2,
          ICGender2: participentGender2,
          ICReferelService2: participentreferelservice2,
          ICReferredTo2: participentreferredto2,
          ICParticipantName3: participentName3,
          ICAge3: participentAge3,
          ICGender3: participentGender3,
          ICReferelService3: participentreferelservice3,
          ICReferredTo3: participentreferredto3,
          ICParticipantName4: participentName4,
          ICAge4: participentAge4,
          ICGender4: participentGender4,
          ICReferelService4: participentreferelservice4,
          ICReferredTo4: participentreferredto4,
          ICDescription: remarks
        },
      ];
    };

   const response ="s";
   //await IUDAwarnessAPI(awarenessData);
    if (response.data.Table[0].result === "S") {

      alert("Inserted Data");
      cancel();

    } else if (response.data.Table[0].result === "M") {
      alert("Modified Data Successfully");
      cancel();
    }
    else if (response.data.Table[0].result === "D") {
      alert("Deleted Data Successfully");
      cancel();
    }
    else if (response.data.Table[0].result === "B") {
      alert("Blocked Data Successfully");
      cancel();
    }
    else if (response.data.Table[0].result === "UB") {
      alert("Un-Blocked Data Successfully");
      cancel();
    }
    else {
      alert("Not Insterted");
      cancel();
    }



  };

  const HandleUserDetails = (rowIndex, flag) => { };

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Awareness`}</div>
          </div>

          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="10" selected>
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={AwarenessHeader}
            tableData={bindProductDatas}
            onEditGrid={handleEdit}
            onDeleteGrid={HandleUserDetails}
            onBlocKGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
            onAddressGrid={HandleAddress}
          />

          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Awareness - Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Awareness - Edit"}
              </div>
            )}

            {viewScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Awareness - View"}
              </div>
            )}

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div>
                    {/* <label
                      for="Pan"
                      className={mainTable.labelBold}
                      class={mainTable.label}
                    >
                      Interpersonal Contacts  - Group Discussion - Mass Meetings - Mass Media
                    </label> */}
                    <div class="flex items-center bg-gray-50   pl-4 border border-gray-300 rounded-lg ">
                      <input
                        id="bordered-radio-1"
                        name="Interpersonal Contacts"
                        value="interpersonalContacts"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  "
                        checked={selectedOption === "interpersonalContacts"}
                        onChange={handleOptionChange}
                      />
                      <label
                        for="bordered-radio-1"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 "
                      >
                        Interpersonal Contacts (1 To 4 )
                      </label>
                      <input
                        id="bordered-radio-2"
                        name="Group Discussion"
                        value="groupDiscussion"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  "
                        checked={selectedOption === "groupDiscussion"}
                        onChange={handleOptionChange}
                      />
                      <label
                        for="bordered-radio-2"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 "
                      >
                        Group Discussion (4 To 5)
                      </label>
                      <input
                        id="bordered-radio-1"
                        name="Mass Meetings"
                        value="massMeetings"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  "
                        checked={selectedOption === "massMeetings"}
                        onChange={handleOptionChange}
                      />
                      <label
                        for="bordered-radio-1"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 "
                      >
                        General / Mass Meetings (20 +)
                      </label>
                      <input
                        id="bordered-radio-1"
                        name="Mass Media"
                        value="massMedia"
                        type="radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  "
                        checked={selectedOption === "massMedia"}
                        onChange={handleOptionChange}
                      />
                      <label
                        for="bordered-radio-1"
                        class="w-full py-2.5 ml-2 text-sm font-medium text-gray-900 "
                      >
                        Mass Media
                      </label>
                    </div>
                  </div>
                  {selectedOption === "interpersonalContacts" && (
                    <div>
                      <div class={mainTable.labelBold1}>
                        Interpersonal Contacts
                      </div>
                      <hr class={mainTable.horizontalLine}></hr>
                      <div className={mainTable.grid3}>
                        {/* <DropDown
                          title="Category"
                          options={bindCategoryDD}
                          placeholder="Select"
                          name="Category"
                          selectedOptions={category}
                          controlFunc={(e) => setCategory(e.target.value)}
                        /> */}
                        <DropDown
                          title="Topics"
                          options={bindtopics}
                          placeholder="Select"
                          name="Topics"
                          content={topics}
                          controlFunc={(value) => setTopics(value)}
                        />

                        <InputText
                          inputType="date"
                          title=" Date"
                          name="openingDate"
                          content={openingDate}
                          controlFunc={(value) => setOpeningDate(value)}
                        />
                        <DropDown
                          classDiv="hidden"
                          title="Country"
                          options={bindCountry}
                          placeholder="Select"
                          name="country"
                          value={country}
                          onChange={(value) => setCountry(value)}
                        />
                        <AutoDropDown
                          title="State"
                          options={bindState}
                          placeholder="Select"
                          name="state"
                          selectedOption={state}
                          controlFunc={(value) => {
                            setState(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Location"
                          options={bindLocation}
                          placeholder="Select"
                          name="state"
                          selectedOption={location}
                          controlFunc={(value) => {
                            setLocation(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="S.D.P."
                          options={bindSdp}
                          placeholder="Select"
                          name="state"
                          selectedOption={sdp}
                          controlFunc={(value) => {
                            setSdp(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Area"
                          options={bindArea}
                          placeholder="Select"
                          name="state"
                          selectedOption={area}
                          controlFunc={(value) => {
                            setArea(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <InputText
                          title="Place"
                          type="Place"
                          name="Place"
                          placeholder="Place"
                          content={place}
                          controlFunc={(e) => setPlace(e.target.value)}
                        />
                      </div>
                      <div className="lg:col-span-4 sm:col-span-1">
                        <div className={mainTable.grid5}>
                          <th>Participant Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Referel Service</th>
                          <th>Referred To</th>
                        </div>
                      </div>
                      <div className={mainTable.grid5}>
                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName1}
                          controlFunc={(e) =>
                            setParticipentName1(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge1}
                          controlFunc={(e) =>
                            setParticipentAge1(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender1}
                          controlFunc={(e) => setParticipentGender1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto1}
                          controlFunc={(e) => setParticipentReferredto1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice1}
                          controlFunc={(e) => setParticipentReferelservice1(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName2}
                          controlFunc={(e) =>
                            setParticipentName2(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge2}
                          controlFunc={(e) =>
                            setParticipentAge2(e.target.value)
                          }
                        />

                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender2}
                          controlFunc={(e) => setParticipentGender2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto2}
                          controlFunc={(e) => setParticipentReferredto2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice2}
                          controlFunc={(e) => setParticipentReferelservice2(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName3}
                          controlFunc={(e) =>
                            setParticipentName3(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge3}
                          controlFunc={(e) =>
                            setParticipentAge3(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender3}
                          controlFunc={(e) => setParticipentGender3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto3}
                          controlFunc={(e) => setParticipentReferredto3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice3}
                          controlFunc={(e) => setParticipentReferelservice3(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName4}
                          controlFunc={(e) =>
                            setParticipentName4(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge4}
                          controlFunc={(e) =>
                            setParticipentAge4(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender4}
                          controlFunc={(e) => setParticipentGender4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto4}
                          controlFunc={(e) => setParticipentReferredto4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice4}
                          controlFunc={(e) => setParticipentReferelservice4(e.target.value)}
                        />

                        <div class="lg:col-span-3 sm:col-span-1">
                          <TextArea
                            inputType="text"
                            title="Description"
                            name="description"
                            rows="4"
                            content={remarks}
                            placeholder="Description"
                            controlFunc={(e) => setRemarks(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedOption === "groupDiscussion" && (
                    <div>
                      <div class={mainTable.labelBold1}>Group Discussion</div>
                      <hr class={mainTable.horizontalLine}></hr>
                      <div className={mainTable.grid3}>
                        {/* <DropDown
                          title="Category"
                          options={bindCategoryDD}
                          placeholder="Select"
                          name="Category"
                          selectedOptions={category}
                          controlFunc={(e) => setCategory(e.target.value)}
                        /> */}

                        <DropDown
                          title="Topics"
                          options={bindtopics}
                          placeholder="Select"
                          name="Topics"
                          content={topics}
                          controlFunc={(e) => setTopics(e.target.value)}
                        />
                        <InputText
                          inputType="date"
                          title="Date"
                          name="openingDate"
                          content={openingDate}
                          controlFunc={(e) => setOpeningDate(e.target.value)}
                        />
                        <InputText
                          inputType="text"
                          title="No.Of.Participants"
                          name="pincode"
                          placeholder="No.Of.Participants"
                          content={noOfParticipants}
                          controlFunc={(e) => setNoOfParticipants(e.target.value)}
                        />

                        <DropDown
                          classDiv="hidden"
                          title="Country"
                          options={bindCountry}
                          placeholder="Select"
                          name="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <AutoDropDown
                          title="State"
                          options={bindState}
                          placeholder="Select"
                          name="state"
                          selectedOption={state}
                          controlFunc={(value) => {
                            setState(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Location"
                          options={bindLocation}
                          placeholder="Select"
                          name="state"
                          selectedOption={location}
                          controlFunc={(value) => {
                            setLocation(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="S.D.P."
                          options={bindSdp}
                          placeholder="Select"
                          name="state"
                          selectedOption={sdp}
                          controlFunc={(value) => {
                            setSdp(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Area"
                          options={bindArea}
                          placeholder="Select"
                          name="state"
                          selectedOption={area}
                          controlFunc={(value) => {
                            setArea(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <InputText
                          title="Place"
                          type="Place"
                          name="Place"
                          placeholder="Place"
                          content={place}
                          controlFunc={(e) => setPlace(e.target.value)}
                        />
                      </div>
                      <div className={mainTable.grid3}>
                        <div>
                          <label htmlFor="date1" className={mainTable.label}>
                            File Upload
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="file"
                            id="file"
                            className={mainTable.input2}
                            handleImageUpload={(e) => setFileUploaded(e.target.files[0])}
                          />
                        </div>
                        <div className="flex">
                          <div className="mt-7 mr-7">
                            <button
                              type="button"
                              className={mainTable.submitButton}
                            >
                              UPLOAD
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-4 sm:col-span-1">
                        <div className={mainTable.grid5}>
                          <th>Participant Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Referel Service</th>
                          <th>Referred To</th>
                        </div>
                      </div>
                      <div className={mainTable.grid5}>
                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName1}
                          controlFunc={(e) =>
                            setParticipentName1(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge1}
                          controlFunc={(e) =>
                            setParticipentAge1(e.target.value)
                          }
                        />

                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender1}
                          controlFunc={(e) => setParticipentGender1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto1}
                          controlFunc={(e) => setParticipentReferredto1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice1}
                          controlFunc={(e) => setParticipentReferelservice1(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName2}
                          controlFunc={(e) =>
                            setParticipentName2(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge2}
                          controlFunc={(e) =>
                            setParticipentAge2(e.target.value)
                          }
                        />

                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender2}
                          controlFunc={(e) => setParticipentGender2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto2}
                          controlFunc={(e) => setParticipentReferredto2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice2}
                          controlFunc={(e) => setParticipentReferelservice2(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName3}
                          controlFunc={(e) =>
                            setParticipentName3(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge3}
                          controlFunc={(e) =>
                            setParticipentAge3(e.target.value)
                          }
                        />

                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender3}
                          controlFunc={(e) => setParticipentGender3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto3}
                          controlFunc={(e) => setParticipentReferredto3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice3}
                          controlFunc={(e) => setParticipentReferelservice3(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName4}
                          controlFunc={(e) =>
                            setParticipentName4(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge4}
                          controlFunc={(e) =>
                            setParticipentAge4(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender4}
                          controlFunc={(e) => setParticipentGender4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto4}
                          controlFunc={(e) => setParticipentReferredto4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice4}
                          controlFunc={(e) => setParticipentReferelservice4(e.target.value)}
                        />


                        <div class="lg:col-span-3 sm:col-span-1">
                          <TextArea
                            inputType="text"
                            title="Description"
                            name="Description"
                            rows="4"
                            content={remarks}
                            placeholder="Description"
                            controlFunc={(e) => setRemarks(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedOption === "massMeetings" && (
                    <div>
                      <div class={mainTable.labelBold1}>
                        General / Mass Meetings
                      </div>
                      <hr class={mainTable.horizontalLine}></hr>
                      <div className={mainTable.grid3}>
                        {/* <DropDown
                          title="Category"
                          options={bindCategoryDD}
                          placeholder="Select"
                          name="Category"
                          selectedOptions={category}
                          controlFunc={(e) => setCategory(e.target.value)}
                        /> */}

                        <DropDown
                          title="Topics"
                          options={bindtopics}
                          placeholder="Select"
                          name="Topics"
                          content={topics}
                          controlFunc={(e) => setTopics(e.target.value)}
                        />
                        <InputText
                          inputType="date"
                          title=" Date"
                          name="openingDate"
                          content={openingDate}
                          controlFunc={(e) => setOpeningDate(e.target.value)}
                        />
                        <InputText
                          inputType="text"
                          title="No.Of.Participants"
                          name="pincode"
                          placeholder="No.Of.Participants"
                          content={noOfParticipants}
                          controlFunc={(e) => setNoOfParticipants(e.target.value)}
                        />
                        <DropDown
                          classDiv="hidden"
                          title="Country"
                          options={bindCountry}
                          placeholder="Select"
                          name="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <AutoDropDown
                          title="State"
                          options={bindState}
                          placeholder="Select"
                          name="state"
                          selectedOption={state}
                          controlFunc={(value) => {
                            setState(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Location"
                          options={bindLocation}
                          placeholder="Select"
                          name="state"
                          selectedOption={location}
                          controlFunc={(value) => {
                            setLocation(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="S.D.P."
                          options={bindSdp}
                          placeholder="Select"
                          name="state"
                          selectedOption={sdp}
                          controlFunc={(value) => {
                            setSdp(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Area"
                          options={bindArea}
                          placeholder="Select"
                          name="state"
                          selectedOption={area}
                          controlFunc={(value) => {
                            setArea(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <InputText
                          title="Place"
                          type="Place"
                          name="Place"
                          placeholder="Place"
                          content={place}
                          controlFunc={(e) => setPlace(e.target.value)}
                        />
                      </div>
                      <div className={mainTable.grid3}>
                        <div>
                          <label htmlFor="date1" className={mainTable.label}>
                            File Upload
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="file"
                            id="file"
                            className={mainTable.input2}
                          />
                        </div>
                        <div className="flex">
                          <div className="mt-7 mr-7">
                            <button
                              type="button"
                              className={mainTable.submitButton}
                            >
                              UPLOAD
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-4 sm:col-span-1">
                        <div className={mainTable.grid5}>
                          <th>Participant Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Referel Service</th>
                          <th>Referred To</th>
                        </div>
                      </div>
                      <div className={mainTable.grid5}>
                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName1}
                          controlFunc={(e) =>
                            setParticipentName1(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge1}
                          controlFunc={(e) =>
                            setParticipentAge1(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender1}
                          controlFunc={(e) => setParticipentGender1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto1}
                          controlFunc={(e) => setParticipentReferredto1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice1}
                          controlFunc={(e) => setParticipentReferelservice1(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName2}
                          controlFunc={(e) =>
                            setParticipentName2(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge2}
                          controlFunc={(e) =>
                            setParticipentAge2(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender2}
                          controlFunc={(e) => setParticipentGender2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto2}
                          controlFunc={(e) => setParticipentReferredto2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice2}
                          controlFunc={(e) => setParticipentReferelservice2(e.target.value)}
                        />


                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName3}
                          controlFunc={(e) =>
                            setParticipentName3(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge3}
                          controlFunc={(e) =>
                            setParticipentAge3(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender3}
                          controlFunc={(e) => setParticipentGender3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto3}
                          controlFunc={(e) => setParticipentReferredto3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice3}
                          controlFunc={(e) => setParticipentReferelservice3(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName4}
                          controlFunc={(e) =>
                            setParticipentName4(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge4}
                          controlFunc={(e) =>
                            setParticipentAge4(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender4}
                          controlFunc={(e) => setParticipentGender4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto4}
                          controlFunc={(e) => setParticipentReferredto4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice4}
                          controlFunc={(e) => setParticipentReferelservice4(e.target.value)}
                        />

                        <div class="lg:col-span-3 sm:col-span-1">
                          <TextArea
                            inputType="text"
                            title="Description"
                            name="Description"
                            rows="4"
                            content={remarks}
                            placeholder="Description"
                            controlFunc={(e) => setRemarks(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedOption === "massMedia" && (
                    <div>
                      <div class={mainTable.labelBold1}>Mass Media</div>
                      <hr class={mainTable.horizontalLine}></hr>
                      <div className={mainTable.grid3}>
                        {/* <DropDown
                          title="Category"
                          options={bindCategoryDD}
                          placeholder="Select"
                          name="Category"
                          selectedOptions={category}
                          controlFunc={(e) => setCategory(e.target.value)}
                        /> */}

                        <DropDown
                          title="Topics"
                          options={bindtopics}
                          placeholder="Select"
                          name="Topics"
                          content={topics}
                          controlFunc={(e) => setTopics(e.target.value)}
                        />
                        <InputText
                          inputType="date"
                          title=" Date"
                          name="openingDate"
                          content={openingDate}
                          controlFunc={(e) => setOpeningDate(e.target.value)}
                        />
                        <InputText
                          inputType="text"
                          title="No.Of.Participants"
                          name="pincode"
                          placeholder="No.Of.Participants"
                          content={noOfParticipants}
                          controlFunc={(e) => setNoOfParticipants(e.target.value)}
                        />

                        <DropDown
                          classDiv="hidden"
                          title="Country"
                          options={bindCountry}
                          placeholder="Select"
                          name="country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                        <AutoDropDown
                          title="State"
                          options={bindState}
                          placeholder="Select"
                          name="state"
                          selectedOption={state}
                          controlFunc={(value) => {
                            setState(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Location"
                          options={bindLocation}
                          placeholder="Select"
                          name="state"
                          selectedOption={location}
                          controlFunc={(value) => {
                            setLocation(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="S.D.P."
                          options={bindSdp}
                          placeholder="Select"
                          name="state"
                          selectedOption={sdp}
                          controlFunc={(value) => {
                            setSdp(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <AutoDropDown
                          title="Area"
                          options={bindArea}
                          placeholder="Select"
                          name="state"
                          selectedOption={area}
                          controlFunc={(value) => {
                            setArea(value);
                          }}
                          Class="custom-class"
                          disabled="false"
                          tabIndex="1"
                        />
                        <InputText
                          type="Place"
                          title="Place"
                          placeholder="Place"
                          name="Place"
                          value={"Place"}
                          content={place}
                          controlFunc={(e) => setPlace(e.target.value)}
                        />
                      </div>
                      <div className={mainTable.grid3}>
                        <div>
                          <label htmlFor="date1" className={mainTable.label}>
                            File Upload
                            <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="file"
                            id="file"
                            className={mainTable.input2}
                          />
                        </div>
                        <div className="flex">
                          <div className="mt-7 mr-7">
                            <button
                              type="button"
                              className={mainTable.submitButton}
                            >
                              UPLOAD
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="lg:col-span-4 sm:col-span-1">
                        <div className={mainTable.grid5}>
                          <th>Participant Name</th>
                          <th>Age</th>
                          <th>Gender</th>
                          <th>Referel Service</th>
                          <th>Referred To</th>
                        </div>
                      </div>
                      <div className={mainTable.grid5}>
                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName1}
                          controlFunc={(e) =>
                            setParticipentName1(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge1}
                          controlFunc={(e) =>
                            setParticipentAge1(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender1}
                          controlFunc={(e) => setParticipentGender1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto1}
                          controlFunc={(e) => setParticipentReferredto1(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice1}
                          controlFunc={(e) => setParticipentReferelservice1(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName2}
                          controlFunc={(e) =>
                            setParticipentName2(e.target.value)
                          }
                        />
                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge2}
                          controlFunc={(e) =>
                            setParticipentAge2(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender2}
                          controlFunc={(e) => setParticipentGender2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto2}
                          controlFunc={(e) => setParticipentReferredto2(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice2}
                          controlFunc={(e) => setParticipentReferelservice2(e.target.value)}
                        />


                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName3}
                          controlFunc={(e) =>
                            setParticipentName3(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge3}
                          controlFunc={(e) =>
                            setParticipentAge3(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender3}
                          controlFunc={(e) => setParticipentGender3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto3}
                          controlFunc={(e) => setParticipentReferredto3(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice3}
                          controlFunc={(e) => setParticipentReferelservice3(e.target.value)}
                        />

                        <InputText
                          type="text"
                          name="participentName"
                          placeholder="Participant Name"
                          content={participentName4}
                          controlFunc={(e) =>
                            setParticipentName4(e.target.value)
                          }
                        />

                        <InputText
                          type="text"
                          name="age"
                          placeholder="Participant Age"
                          content={participentAge4}
                          controlFunc={(e) =>
                            setParticipentAge4(e.target.value)
                          }
                        />
                        <DropDown
                          options={genderRadio}
                          placeholder="Select"
                          name="type"
                          value={type}
                          selectedOption={participentGender4}
                          controlFunc={(e) => setParticipentGender4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferredto}
                          placeholder="Select"
                          name="ReferredTo"
                          selectedOption={participentreferredto4}
                          controlFunc={(e) => setParticipentReferredto4(e.target.value)}
                        />
                        <DropDown
                          options={bindreferelservice}
                          placeholder="Select"
                          name="Referel Service"
                          selectedOption={participentreferelservice4}
                          controlFunc={(e) => setParticipentReferelservice4(e.target.value)}
                        />

                        <div class="lg:col-span-3 sm:col-span-1">
                          <TextArea
                            inputType="text"
                            title="Description"
                            name="Description"
                            rows="4"
                            content={remarks}
                            placeholder="Description"
                            controlFunc={(e) => setRemarks(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
      <MainPopup
        open={viewDetails}
        closeOnDocumentClick={false}
        onClose={() => setViewDetails(false)}
      >
        <div className="modal-reset">
          <button className="close" onClick={() => setViewDetails(false)}>
            &times;
          </button>
          <div className="modal-content">
          </div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
}

export default Awarness;
