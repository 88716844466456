import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import GridPage from "../components/GridPage";
import { ReferralTrackingHeaders } from "../components/HeaderAttribute";
import { BindPostAPI, IUDAPI } from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";

const ReferralTracking = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const location = useLocation();
  const [referralTrackingDatas, setReferralTrackingDatas] = useState([]);
  const [date, setDate] = useState("");
  const [beneficiaryNameEn, setBeneficiaryNameEn] = useState("");
  const [beneficiaryNameTa, setBeneficiaryNameTa] = useState("");
  const [beneficiaryNameHi, setBeneficiaryNameHi] = useState("");
  const [beneficiaryNameMr, setBeneficiaryNameMr] = useState("");
  const [beneficiaryNameGu, setBeneficiaryNameGu] = useState("");
  const [beneficiaryNameKn, setBeneficiaryNameKn] = useState("");
  const [beneficiaryNameMl, setBeneficiaryNameMl] = useState("");
  const [beneficiaryNameTe, setBeneficiaryNameTe] = useState("");
  const [beneficiaryNameBn, setBeneficiaryNameBn] = useState("");
  const [beneficiaryNameOr, setBeneficiaryNameOr] = useState("");
  const [referralService, setReferralService] = useState("");
  const [rowIndexData, setRowIndexData] = useState("");
  const [referralTrackingId, setReferralTrackingId] = useState("");

  const [clearReferralTrackingValues, setClearReferralTrackingValues] =
    useState(false);

  const [referredTo, setReferredTo] = useState("");
  const [serviceReceived, setServiceReceived] = useState("");
  const [serviceReceivedFrror, setServiceReceivedError] = useState("");
  const [followUpBy, setFollowUpBy] = useState("");
  const [dateFollowedUp, setDateFollowedUp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const handleClearReferralTrackingValues = (data) => {
    data && setClearReferralTrackingValues(false);
  };

  const cancel = () => {
    setClearReferralTrackingValues(true);
    setRowIndexData("");
    setServiceReceivedError("");
    setDate("");
    setFollowUpBy("");
    setDateFollowedUp(undefined);
    setServiceReceived("");
    setReferralTrackingId("");
    setReferredTo("");
    setMobileNumber("");
    setReferralService("");
    referralTrackingData(userProfileDatas);
  };

  const referralTrackingData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const Binddata = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [Binddata],
        "IECAPI/BindReferralTracking"
      );

      const filteredData = response.data.Table;
      setReferralTrackingDatas(filteredData);
    }
  };

  useEffect(() => {
    referralTrackingData(userProfileDatas);
  }, []);
  const handleNotificationData = (updatedData) => {
    updatedData.forEach((item) => {
      setRowIndexData(item.RowIndex);
      setDate(item.Date);
      setBeneficiaryNameEn(item.BeneficiaryNameEn);
      setBeneficiaryNameTa(item.BeneficiaryNameTa);
      setBeneficiaryNameHi(item.BeneficiaryNameHi);
      setBeneficiaryNameMr(item.BeneficiaryNameMr);
      setBeneficiaryNameGu(item.BeneficiaryNameGu);
      setBeneficiaryNameKn(item.BeneficiaryNameKn);
      setBeneficiaryNameMl(item.BeneficiaryNameMl);
      setBeneficiaryNameTe(item.BeneficiaryNameTe);
      setBeneficiaryNameBn(item.BeneficiaryNameBn);
      setBeneficiaryNameOr(item.BeneficiaryNameOr);
      setReferralService(item.ReferralService);
      setReferredTo(item.ReferredTo);
      setServiceReceived(item.ServiceReceived);
      setFollowUpBy(item.FollowUpBy);
      setDateFollowedUp(item.DateFollowedUp);
      setMobileNumber(item.MobileNo);
      setReferralTrackingId(item.TxnReferralTrackingId);
    });
  };
  const HandleSubmit = async (rowIndex) => {
    debugger;
    const errorServiceReceived = serviceReceived
    ? ""
    : "Select service received";
    const errorFollowedupBy = dateFollowedUp
    ? ""
    : "Select date followedUp";
    setServiceReceivedError(errorServiceReceived);
    if(errorServiceReceived !==""){
      toast.error("Enter session received", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }
    if(errorFollowedupBy !==""){
      toast.error("Select date followed up", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }
    if(errorServiceReceived ===""){
    if (rowIndex === rowIndexData[0]) {
      const data = {
        txnReferralTrackingId: referralTrackingId,
        date: date,
        beneficiaryNameEn: beneficiaryNameEn,
        beneficiaryNameTa: beneficiaryNameTa,
        beneficiaryNameHi: beneficiaryNameHi,
        beneficiaryNameMr: beneficiaryNameMr,
        beneficiaryNameGu: beneficiaryNameGu,
        beneficiaryNameKn: beneficiaryNameKn,
        beneficiaryNameMl: beneficiaryNameMl,
        beneficiaryNameTe: beneficiaryNameTe,
        beneficiaryNameBn: beneficiaryNameBn,
        beneficiaryNameOr: beneficiaryNameOr,
        mobileNo: mobileNumber,
        referralService: referralService,
        referredTo: referredTo,
        serviceReceived: serviceReceived,
        followUpBy: followUpBy,
        dateFollowedUp: dateFollowedUp ? dateFollowedUp : null,
        createdBy: sessionStorage.getItem("createdBy"),
        flag: 1,
      };

      const response = await IUDAPI([data], "IECAPI/IUDReferralTracking");

      if (response.data.Table[0].Result === "M") {
        toast.success("NextFollowUp data Insert successfully 👍", {
          duration: 3000,
          position: "top-center",
        });
        setTimeout(cancel, 3000);
        referralTrackingData(userProfileDatas);
        // setClearReferralTrackingValues(true);
      } else {
        toast.error("NextFollowUp data Insert Failed 👎", {
          duration: 3000,
          position: "top-center",
        });
        setTimeout(cancel, 3000);
        referralTrackingData(userProfileDatas);
      }
    } else {
      toast.error(
        "NextFollowUp data Insert Failed 👎. Please Fill One Row only",
        {
          duration: 3000,
          position: "top-center",
        }
      );
      setTimeout(cancel, 3000);
    }
  }
  };
  useEffect(() => {
    if (location.pathname == "/referraltracking") {
      referralTrackingData(userProfileDatas);
    }
  }, [location]);
  return (
    <MainLayout pageName="3">
      <GridPage
        title="Referral Tracking"
        blockView={false}
        addView={true}
        tableHeight='h-72'
        xScroll="overflow-x-auto max-md:w-fit w-full"
        tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
        header={ReferralTrackingHeaders}
        records={referralTrackingDatas}
        onNotificationDatas={handleNotificationData}
        onNotificationClear={clearReferralTrackingValues}
        onNotificationClearFalse={handleClearReferralTrackingValues}
        onNotificationSave={HandleSubmit}
      />
    </MainLayout>
  );
};

export default ReferralTracking;
