// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import TableGrid from "../components/Table";
import MainPopup from "../popup-containers/MainPopup";
import Country from "../popup-containers/Country";
import { spServiceSessionMasterHeaders } from "../components/HeaderAttribute";
import DropDown from "../ReusableComponents/DropDown";
import { CanceledError } from "axios";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import SPServiceSessionPopup from "../popup-containers/SPServicesSessionPopup";
import {
  BindSPServicesAPI,
  SPServicesMasterInsertAPI,
  getAreaApi,
  getLocationApi,
  getSdpApi,
} from "../services/SPServicesAPI";
import { BindAllMasterData } from "../services/SPServicesAPI";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, IUDAPI } from "../services/APIConnection";
const SPServicesSession = () => {
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [venue, setVenue] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [description, setDescription] = useState("");
  const [spServiceTopic, setSpServiceTopic] = useState("");
  const [hcpName, setHCPName] = useState("");

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [updateButton, setUpadateButtoon] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);

  const [bindSPServices, setBindSPServices] = useState([]);
  const [bindState, setBindStatesDD] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindHcpName, setBindHcpName] = useState([]);
  const [bindSPSTopics, setBindSPSTopics] = useState([]);
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [sdp, setSdp] = useState("");
  const [location, setLocation] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [sPServices, setSPServicesData] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [editMstSPServicesId, setEditMstSPServicesId] = useState("");
  const [spServiceData2, setSPServiceData2] = useState({});

  const [dateTimeError, setDateTimeError] = useState("");
  const [venueError, setVenueError] = useState("");
  const [spServiceTopicError, setSPServiceTopicError] = useState("");
  const [hcpNameError, setHCPNameError] = useState("");
  const [stateError, setStateError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [sdpError, setSdpError] = useState("");
  const [areaError, setAreaError] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const handleDropDown = (event) => {
    // To set Number of records per page
    const selectedValue = parseInt(event.target.value); // Convert to integer
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  useEffect(() => {
  
    BindClientGridData();
  }, []);
  const BindClientGridData = async () => {
    
    const response = await BindAPI("SPServiceTransAPI/BindSPServicesAPI");
    setBindSPServices(response.data.Table);
  };
  useEffect(() => {
    const fetchData = async () => {
      binddrpstatedata();
      //  binddrpLocationdata();
      binddrpSDPdata();
      binddrpAreadata();
      binddrpResourcedata();
      binddrpTopicsdata();
    };

    fetchData();
  }, []);

  const binddrpstatedata = async () => {
    try {
      const bindStatedatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");

      if (bindStatedatadrp.data.Table.length > 0) {
        const stateDD = bindStatedatadrp.data.Table.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindStatesDD(stateDD);
      } else {
        setBindStatesDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpSDPdata = async () => {
    try {
      const bindSDPdatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");

      if (bindSDPdatadrp.data.Table3.length > 0) {
        const SDPdatadrp = bindSDPdatadrp.data.Table3.map((m) => ({
          value: m.MstSDPId,
          label: m.SDP_en,
        }));
        setBindSDP(SDPdatadrp);
      } else {
        setBindSDP([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpAreadata = async () => {
    try {
      const bindAreadatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");

      if (bindAreadatadrp.data.Table2.length > 0) {
        const Areadatadrp = bindAreadatadrp.data.Table2.map((m) => ({
          value: m.MstAreaId,
          label: m.AreaName_en,
        }));
        setBindAreaDD(Areadatadrp);
      } else {
        setBindAreaDD([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpResourcedata = async () => {
    
    try {
      const bindResorcedatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");

      if (bindResorcedatadrp.data.Table7.length > 0) {
        const Resourcedatadrp = bindResorcedatadrp.data.Table7.map((m) => ({
          value: m.MstHealthCareProviderId,
          label: m.HealthcareProviderName_en,
        }));
        setBindHcpName(Resourcedatadrp);
      } else {
        setBindHcpName([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpTopicsdata = async () => {
    
    try {
      const bindTopicsdatadrp = await BindAPI("MasterDataAPI/BindAllMasterData");

      if (bindTopicsdatadrp.data.Table8.length > 0) {
        const Topicsdatadrp = bindTopicsdatadrp.data.Table8.map((m) => ({
          value: m.MstSpServicesTopicsId,
          label: m.SPServicesName,
        }));
        setBindSPSTopics(Topicsdatadrp);
      } else {
        setBindSPSTopics([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    const getSelectedArea = async () => {
      const locationResponse = await BindAPI("AreaAPI/GetArea");
      setGetAreaDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    getSelectedArea();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDD(locationSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == location.value // Replace with the desired MstStateId
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setBindSDP(sdpSelected);
    } else {
    }
  }, [location]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == sdp.value // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));
      setBindAreaDD(areaSelected);
    } else {
    }
  }, [sdp]);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    // setPopUpOpenCountry(false);
  };
  const HandleBindSPService = (rowIndex, flag) => {
    
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setSPServicesData(bindSPServices[rowIndex]);

    var SPDateTime = bindSPServices[rowIndex].DateTime;
    const SP_DateTime = SPDateTime.replace("T", " ");

    const StaterowData = bindSPServices[rowIndex];
    const StateInfo = bindState.find(
      (ST) => ST.value === StaterowData.MstStateId
    );

    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };

    const locationrowData = bindSPServices[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const SDProwData = bindSPServices[rowIndex];
    const SDPInfo = getSDPDatas.find(
      (SD) => SD.MstSDPId === SDProwData.MstSDPId
    );
    const ResultSDP = {
      value: SDProwData.MstSDPId,
      label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
    };

    const ArearowData = bindSPServices[rowIndex];
    const ArearowDataInfo = getAreaDatas.find(
      (AT) => AT.MstAreaId === ArearowData.MstAreaId
    );
    const ResultArea = {
      value: ArearowData.MstAreaId,
      label: ArearowDataInfo
        ? ArearowDataInfo.AreaName_en
        : "DefaultStateLabel",
    };

    const TopicsrowData = bindSPServices[rowIndex];
    const TopicsInfo = bindSPSTopics.find(
      (ST) => ST.value === TopicsrowData.SPServiceTopic
    );

    const resultTopics = {
      value: TopicsrowData.SPServiceTopic,
      label: TopicsInfo ? TopicsInfo.label : "",
    };

    const hcpNamerowData = bindSPServices[rowIndex];
    const HCPNameInfo = bindHcpName.find(
      (ST) => ST.value === hcpNamerowData.HcpName
    );

    const resultHCPName = {
      value: hcpNamerowData.HcpName,
      label: HCPNameInfo ? HCPNameInfo.label : "",
    };

    const SPServiceData2 = [
      SP_DateTime,
      resultTopics,
      resultHCPName,
      resultState,
      ResultLocation,
      ResultSDP,
      ResultArea,
    ];
    setSPServiceData2(SPServiceData2);
  };

  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
      dropdownstate(selectedRowIndex);
      dropdownsdp(selectedRowIndex);
      dropdownlocation(selectedRowIndex);
      dropdownArea(selectedRowIndex);
      dropdownHCPName(selectedRowIndex);
      dropdownType(selectedRowIndex);
    }
  };

  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  const dropdownsdp = (rowIndex) => {
    try {
      const SDProwData = bindSPServices[rowIndex];
      const SDPInfo = getSDPDatas.find(
        (SD) => SD.MstSDPId === SDProwData.MstSDPId
      );
      const ResultSDP = {
        value: SDProwData.MstSDPId,
        label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
      };
      setSdp(ResultSDP);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownlocation = (rowIndex) => {
    try {
      const locationrowData = bindSPServices[rowIndex];
      const LocationInfo = getLocationDatas.find(
        (L) => L.MstLocationId === locationrowData.MstLocationId
      );
      const ResultLocation = {
        value: locationrowData.MstLocationId,
        label: LocationInfo
          ? LocationInfo.LocationName_en
          : "DefaultStateLabel",
      };
      setLocation(ResultLocation);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownArea = (rowIndex) => {
    try {
      const ArearowData = bindSPServices[rowIndex];
      const ArearowDataInfo = getAreaDatas.find(
        (AT) => AT.MstAreaId === ArearowData.MstAreaId
      );
      const ResultArea = {
        value: ArearowData.MstAreaId,
        label: ArearowDataInfo
          ? ArearowDataInfo.AreaName_en
          : "DefaultStateLabel",
      };
      setArea(ResultArea);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownstate = (rowIndex) => {
    
    try {
      const StaterowData = bindSPServices[rowIndex];
      const StateInfo = bindState.find(
        (ST) => ST.value === StaterowData.MstStateId
      );

      const resultState = {
        value: StaterowData.MstStateId,
        label: StateInfo ? StateInfo.label : "DefaultStateLabel",
      };

      setState(resultState);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownType = (rowIndex) => {
    
    try {
      const TopicsrowData = bindSPServices[rowIndex];
      const TopicsInfo = bindSPSTopics.find(
        (ST) => ST.value === TopicsrowData.SPServiceTopic
      );

      const resultTopics = {
        value: TopicsrowData.SPServiceTopic,
        label: TopicsInfo ? TopicsInfo.label : "",
      };

      setSpServiceTopic(resultTopics);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownHCPName = (rowIndex) => {
    
    try {
      const hcpNamerowData = bindSPServices[rowIndex];
      const HCPNameInfo = bindHcpName.find(
        (ST) => ST.value === hcpNamerowData.HcpName
      );

      const resultHCPName = {
        value: hcpNamerowData.HcpName,
        label: HCPNameInfo ? HCPNameInfo.label : "",
      };

      setHCPName(resultHCPName);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const handleEdit = (rowIndex) => {
    
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setEditMstSPServicesId(bindSPServices[rowIndex].MstSPServicesId);
    var SPDateTime = bindSPServices[rowIndex].DateTime;
    const SP_DateTime = SPDateTime.replace("T", " ");
    setDateTime(SP_DateTime);
    setVenue(bindSPServices[rowIndex].Venue);

    setDescription(bindSPServices[rowIndex].Description);
  };

  const handleAddress = (rowIndex) => {};

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setUpadateButtoon(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    clear();
    BindClientGridData();
  };

  const clear = () => {
    setVenue("");
    setDescription("");
    setSpServiceTopic("");
    setHCPName("");
    setState("");
    setArea("");
    setSdp("");
    setLocation("");

    setDateTimeError("");
    setVenueError("");
    setSPServiceTopicError("");
    setHCPNameError("");
    setStateError("");
    setLocationError("");
    setSdpError("");
    setAreaError("");
  };
  const handleSubmit = async (items, flag) => {
    
    if (flag == 1 || flag == 2) {
      // validation start
      const dateTimeValidation = dateTime ? "" : "Enter DOB";
      setDateTimeError(dateTimeValidation);
      const venueValidation = venue ? "" : "Enter Venue";
      setVenueError(venueValidation);
      const spServiceTopicValidation = spServiceTopic
        ? ""
        : "Select a SP Service Session Topics";
      setSPServiceTopicError(spServiceTopicValidation);

      const hcpNameValidation = hcpName
        ? ""
        : "Select a Healhcare Provider Name";
      setHCPNameError(hcpNameValidation);

      const stateValidate = state ? "" : "Select a State";
      setStateError(stateValidate);

      const locationValidate = location ? "" : "Select a Location";
      setLocationError(locationValidate);

      const sdpValidate = sdp ? "" : "Select a SDP";
      setSdpError(sdpValidate);

      const areaValidate = area ? "" : "Select a Area";
      setAreaError(areaValidate);
      if (
        !dateTimeValidation &&
        !venueValidation &&
        !spServiceTopicValidation &&
        !hcpNameValidation &&
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !areaValidate
      ) {
        const data = {
          mstSPServicesId: editMstSPServicesId ? editMstSPServicesId : 0,
          dateTime: dateTime,
          venue: venue,
          spServiceTopic: spServiceTopic.value,
          hcpName: hcpName.value,
          mstStateId: state.value,
          mstLocationId: location.value,
          mstSDPId: sdp.value,
          mstAreaId: area.value,
          description: description,
          createdBy: 1,
          flag: flag,
        };
        const response = await IUDAPI(data, "SPServiceTransAPI/IUDSPServices");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Special Services Session added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Special Services Session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Special Services Session updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Special Services Session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      const data = {
        mstSPServicesId: bindSPServices[items].MstSPServicesId,
        createdBy: 1,
        flag: flag,
      };
      const response = await IUDAPI(data, "SPServiceTransAPI/IUDSPServices");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Special Services Session data deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Special Services Session data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Special Services Session data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setDateTime(formattedDateTime);
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindSPServices);
  const handleSearchInputChange = (e) => {
    // Search in Grid
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  const handleSearch = (input) => {
    const filtered = bindSPServices.filter((item) => {
      const providerData = `${item.SPServicesName} ${item.HealthcareProviderName_en}`;
      return providerData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };

  return (
    <MainLayout pageName="2">
     
      {visibleTable && (
        <div className="">
          <div className={mainTable.headerTitle}>
            <div
              className={mainTable.gridTableHeader}
            >{`Special Services Session Master`}</div>
          </div>
          <div className="relative mt-1 md:hidden">
            <div className={mainTable.searchIconPosition}>
              <svg
                className={mainTable.searchIconSvg}
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className={mainTable.searchInputBox}
              placeholder="Search for items"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="md:mt-1 mt-0 gap-2 flex items-center justify-between md:items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50" selected>
                    50
                  </option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="hidden md:block relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={spServiceSessionMasterHeaders}
            tableData={searchData.length > 0 ? searchData : bindSPServices}
            onEditGrid={handleEdit}
            onDeleteGrid={HandleBindSPService}
            onBlockGrid={HandleBindSPService}
            onViewGrid={HandleBindSPService}
            onAddressGrid={handleAddress}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />

          <div className={mainTable.submitCancelMainDiv}>
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={mainTable.submitButton}
            >
              Previous Page
            </button>
            <label>{currentPage}</label>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === bindSPServices.length / itemsPerPage}
              className={mainTable.cancelButton}
            >
              Next Page
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class=" border-black  ">
            <div class="text-black text-2xl font-bold my-2">
              {addScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {`Special Services Session Master - Add`}
                </div>
              )}

              {updateScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {`Special Services Session Master - Edit`}
                </div>
              )}
            </div>
          </div>

          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form class="space-y-6" action="#">
                <div className={mainTable.grid3}>
                  <div>
                    <InputText
                      inputType="datetime-local"
                      title="Date & Time"
                      name="dateTime"
                      classStar={"text-red-500"}
                      content={dateTime}
                      controlFunc={(e) => setDateTime(e.target.value)}
                      controlFuncBlur={(e) => {
                        setDateTime(e.target.value);
                        setDateTimeError(
                          dateTime ? "" : "Please Select Date & Time"
                        );
                      }}
                    />
                    {dateTimeError && (
                      <span class="text-red-500">{dateTimeError}</span>
                    )}
                  </div>

                  <div>
                    <AutoDropDown
                      title="Special Service Session Topic"
                      classStar={"text-red-500"}
                      options={bindSPSTopics}
                      placeholder="Select"
                      name="spServiceTopic"
                      selectedOption={spServiceTopic}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSpServiceTopic(value);
                        setSPServiceTopicError(
                          value ? "" : "Select a SP Service Session Topic"
                        );
                      }}
                      controlFuncBlur={() => {
                        setSPServiceTopicError(
                          spServiceTopic
                            ? ""
                            : "Select a SP Service Session Topic"
                        );
                      }}
                    />
                    {spServiceTopicError && (
                      <span className="text-red-500">
                        {spServiceTopicError}
                      </span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Healhcare Provider Name"
                      classStar={"text-red-500"}
                      options={bindHcpName}
                      placeholder="Select"
                      name="hcpName"
                      selectedOption={hcpName}
                      AutoDropDown
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setHCPName(value);
                        setHCPNameError(
                          value ? "" : "Select a Healhcare Provider Name"
                        );
                      }}
                      controlFuncBlur={() => {
                        setHCPNameError(
                          hcpName ? "" : "Select a Healhcare Provider Name"
                        );
                      }}
                    />
                    {hcpNameError && (
                      <span className="text-red-500">{hcpNameError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="State"
                      classStar={"text-red-500"}
                      options={bindState}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setState(value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select a State");
                      }}
                    />
                    {stateError && (
                      <span className="text-red-500">{stateError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Location"
                      classStar={"text-red-500"}
                      options={bindLocation}
                      placeholder="Select"
                      name="state"
                      selectedOption={location}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setLocation(value);
                        setLocationError(value ? "" : "Select a Location");
                      }}
                      controlFuncBlur={() => {
                        setLocationError(location ? "" : "Select a Location");
                      }}
                    />
                    {locationError && (
                      <span className="text-red-500">{locationError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="S.D.P."
                      options={bindSDP}
                      placeholder="Select"
                      classStar={"text-red-500"}
                      name="state"
                      selectedOption={sdp}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSdp(value);
                        setSdpError(value ? "" : "Select a SDP");
                      }}
                      controlFuncBlur={() => {
                        setSdpError(sdp ? "" : "Select a SDP");
                      }}
                    />
                    {sdpError && (
                      <span className="text-red-500">{sdpError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Area"
                      options={bindArea}
                      placeholder="Select"
                      name="state"
                      classStar={"text-red-500"}
                      selectedOption={area}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setArea(value);
                        setAreaError(value ? "" : "Select a Area");
                      }}
                      controlFuncBlur={() => {
                        setAreaError(area ? "" : "Select a Area");
                      }}
                    />
                    {areaError && (
                      <span className="text-red-500">{areaError}</span>
                    )}
                  </div>
                  <div>
                    <InputText
                      inputType="text"
                      title="Venue"
                      name="venue"
                      content={venue}
                      classStar={"text-red-500"}
                      controlFunc={(e) => setVenue(e.target.value)}
                      controlFuncBlur={(e) => {
                        setVenue(e.target.value);
                        setVenueError(venue ? "" : "Enter the Venue");
                      }}
                    />
                    {venueError && (
                      <span class="text-red-500">{venueError}</span>
                    )}
                  </div>
                  <div className="lg:col-span-3 sm:col-span-1">
                    <TextArea
                      inputType="text"
                      title="Description"
                      name="Description"
                      content={description}
                      controlFunc={(e) => setDescription(e.target.value)}
                      rows={4}
                    />
                  </div>
                </div>
              </form>
              <div className={mainTable.submitCancelMainDiv}>
                {addScreenTitle && (
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.submitButton}
                    onClick={() => handleSubmit(0, 1)}
                  >
                    SUBMIT
                  </button>
                )}
                {updateScreenTitle && (
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="submitButton"
                    className={mainTable.submitButton}
                    onClick={() => handleSubmit(0, 2)}
                  >
                    UPDATE
                  </button>
                )}
                <button
                  data-modal-hide="bottom-right-modal"
                  type="button"
                  className={mainTable.cancelButton}
                  onClick={cancel}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleBindSPService}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div class="text-xl font-medium text-neutral-700 mx-5 ">
            {viewDetails && `Special Services Session Master - View`}
            {deleteDetails && `Special Services Session Master - Delete`}
            {blockDetails && `Special Services Session Master - Block`}
            {unBlockDetails && `Special Services Session Master - Unblock`}
          </div>
          <SPServiceSessionPopup data={sPServices} data1={spServiceData2} />
        </div>
        <div class={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            class={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
};

export default SPServicesSession;
