import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { iecReferralsReportHeader } from "../components/HeaderAttribute";
// import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";

const IECReferralsReports = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  const [branchProject, setBranchProject] = useState("");
  const [sdp, setSDP] = useState("");
  const [area, setArea] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [branchName, setBranchName] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindReportIECRerralsData, setBindReportIECRerralsData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);
    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);
    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchProjectArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "IECAPI/ReportIECReferrals"
      );

      console.log("response", response.data.Table);
      setBindReportIECRerralsData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };

    exportData(
      iecReferralsReportHeader.map((column) => column.title),
      bindReportIECRerralsData,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "IEC - Referrals",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true, // Enable text wrapping for headers
          },
        },
      })),
    ];

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Date,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.IECNo,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },       
        {
          v: rowData.District_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: rowData.Block_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.City_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Venue,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.BeneficiaryNameEn,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.MstAgeId || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: rowData.Gender_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ReferredBy,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ReferredTo_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: rowData.ServiceReceived,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.DateFollowedUp,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
      ];
    });

    // Combine all rows
    // const wsData = [titleRow, headerRow, ...dataRows];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow,
      ...dataRows,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Set custom column widths
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 15 }, // Branch
      { width: 15 }, // IPC  No

      { width: 15 }, // Gender
      { width: 25 }, // Topics1
      { width: 30 }, // Topics2
      { width: 15 }, // Topics3
      { width: 20 }, // Topics4
      { width: 15 }, // Topics5
      { width: 10 }, // Topics6
      { width: 15 }, // Topics7
      { width: 15 }, // Topics8
      { width: 20 }, // Topics9
      { width: 20 }, // Topics10
      { width: 15 }, // Topics1
      { width: 15 }, // Topics2
      { width: 30 }, // Topics3
    ];

    // // Border style for all cells
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // // Apply styles
    // // Title row
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create and save workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "IEC - ReferralsReport.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF("landscape"); // Use landscape orientation for more horizontal space
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure CSEInSchoolReportHeader is an array of objects with a title property
    const headers = iecReferralsReportHeader.map((column) => column.title);

    // Map bindReportInschoolSData to the appropriate format for autoTable
    const data = bindReportIECRerralsData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.IECNo,
        rowData.Date,
        rowData.SDP_en,
        rowData.AreaName_en,
        rowData.District_en,
        rowData.Block_en,
        rowData.City_en,
        rowData.Venue,
        rowData.BeneficiaryNameEn,
        rowData.MstAgeId,
        rowData.Gender_en,
        rowData.ReferredBy,
        rowData.ReferredTo_en,
        rowData.ServiceReceived,
        rowData.DateFollowedUp,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Auto adjust width
        7: { cellWidth: "auto" }, // Auto adjust width
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: 15 }, // Age
        11: { cellWidth: 15 }, // Gender
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("IEC - ReferralsReport.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setSDP(updatedOptions);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
    console.log("sdpNamesList", sdpNamesList);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  useEffect(() => {
    if (branchProject) {
      try {
        const getSelectedSDP = async () => {
          const locationResponse = await BindAPI("SDPAPI/GetSDP");
          setGetSDPDatas(locationResponse.data.Table);

          if (userProfileDatas && userProfileDatas[0]) {
            const sdpLabels = locationResponse.data.Table.filter((item) =>
              branchProject.includes(parseInt(item.MstLocationId))
            ).map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

            const sdpSelected = sdpLabels.map((SD) => ({
              value: SD.MstSDPId,
              label: SD.SDP_en,
            }));

            if (
              userProfileDatas[0].MstOrganizationId == 1 ||
              userProfileDatas[0].MstOrganizationId == 2
            ) {
              setBindSDP(sdpSelected);
            } else {
              const allSDPIds = userProfileDatas[0]?.AllSDP;
              const sdpId = userProfileDatas[0]?.MstSDPId;
              if (allSDPIds && Array.isArray(allSDPIds)) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  allSDPIds.includes(sdp.value)
                );
                setBindSDP(filteredSdpSelected);
              }
              if (sdpId) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  Array.isArray(sdpId)
                    ? sdpId.includes(sdp.value)
                    : sdpId === sdp.value
                );

                setBindSDP(filteredSdpSelected);
              }
            }
          }
        };

        getSelectedSDP();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [
    branchProject ? branchProject : undefined,
    userProfileDatas ? userProfileDatas : undefined,
  ]);

  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`IEC - Referrals`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindReportIECRerralsData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={iecReferralsReportHeader}
                records={bindReportIECRerralsData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default IECReferralsReports;
