import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { clientsByAgeGenderDonorSRHHeader } from "../components/HeaderAttribute";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";
import InputText from "../ReusableComponents/InputText";

const ClientsByAgeGenderDonorSRHReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchName, setBranchName] = useState("");
  const [branchProject, setBranchProject] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [
    bindClientsByAgeGenderDonorSRHData,
    setBindClientsByAgeGenderDonorSRHData,
  ] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);
    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchProjectArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "TXNServicesAPI/ReportClientsByAgeGenderDonorSRH"
      );

      console.log("response", response.data.Table);
      setBindClientsByAgeGenderDonorSRHData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };

    exportData(
      clientsByAgeGenderDonorSRHHeader.map((column) => column.title),
      bindClientsByAgeGenderDonorSRHData,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    // Title row configuration
    const titleRow = [
      {
        v: "Clients by age_gender - Donor-SRH",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },

      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    // const headerRow = columns.map((column) => ({
    //   v: column,
    //   s: {
    //     fill: {
    //       fgColor: { rgb: "2D2D6E" }, // Background color (dark blue)
    //     },
    //     font: {
    //       bold: true,
    //       color: { rgb: "FFFFFF" }, // Font color (white)
    //     },
    //   },
    // }));
    // Add "Row Total" to headers
    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];
    const columnTotals = {
      FemaleAge0to9: 0,
      MaleAge0to9: 0,
      OtherAge0to9: 0,
      FemaleAge10to14: 0,
      MaleAge10to14: 0,
      OtherAge10to14: 0,
      FemaleAge15to18: 0,
      MaleAge15to18: 0,
      OtherAge15to18: 0,
      FemaleAge19yrs: 0,
      MaleAge19yrs: 0,
      OtherAge19yrs: 0,
      FemaleAge20to24: 0,
      MaleAge20to24: 0,
      OtherAge20to24: 0,
      FemaleAge25to29: 0,
      MaleAge25to29: 0,
      OtherAge25to29: 0,
      FemaleAge30to34: 0,
      MaleAge30to34: 0,
      OtherAge30to34: 0,
      FemaleAge35to39: 0,
      MaleAge35to39: 0,
      OtherAge35to39: 0,
      FemaleAge40to44: 0,
      MaleAge40to44: 0,
      OtherAge40to44: 0,
      FemaleAge45to49: 0,
      MaleAge45to49: 0,
      OtherAge45to49: 0,
      FemaleAge50to59: 0,
      MaleAge50to59: 0,
      OtherAge50to59: 0,
      FemaleAge60andAbove: 0,
      MaleAge60andAbove: 0,
      OtherAge60andAbove: 0,
    };
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};
      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.FemaleAge0to9 || 0) +
        Number(rowData.MaleAge0to9 || 0) +
        Number(rowData.OtherAge0to9 || 0) +
        Number(rowData.FemaleAge10to14 || 0) +
        Number(rowData.MaleAge10to14 || 0) +
        Number(rowData.OtherAge10to14 || 0) +
        Number(rowData.FemaleAge15to18 || 0) +
        Number(rowData.MaleAge15to18 || 0) +
        Number(rowData.OtherAge15to18 || 0) +
        Number(rowData.FemaleAge19yrs || 0) +
        Number(rowData.MaleAge19yrs || 0) +
        Number(rowData.OtherAge19yrs || 0) +
        Number(rowData.FemaleAge20to24 || 0) +
        Number(rowData.MaleAge20to24 || 0) +
        Number(rowData.OtherAge20to24 || 0) +
        Number(rowData.FemaleAge25to29 || 0) +
        Number(rowData.MaleAge25to29 || 0) +
        Number(rowData.OtherAge25to29 || 0) +
        Number(rowData.FemaleAge30to34 || 0) +
        Number(rowData.MaleAge30to34 || 0) +
        Number(rowData.OtherAge30to34 || 0) +
        Number(rowData.FemaleAge35to39 || 0) +
        Number(rowData.MaleAge35to39 || 0) +
        Number(rowData.OtherAge35to39 || 0) +
        Number(rowData.FemaleAge40to44 || 0) +
        Number(rowData.MaleAge40to44 || 0) +
        Number(rowData.OtherAge40to44 || 0) +
        Number(rowData.FemaleAge45to49 || 0) +
        Number(rowData.MaleAge45to49 || 0) +
        Number(rowData.OtherAge45to49 || 0) +
        Number(rowData.FemaleAge50to59 || 0) +
        Number(rowData.MaleAge50to59 || 0) +
        Number(rowData.OtherAge50to59 || 0) +
        Number(rowData.FemaleAge60andAbove || 0) +
        Number(rowData.MaleAge60andAbove || 0) +
        Number(rowData.OtherAge60andAbove || 0);

      columnTotals.FemaleAge0to9 += Number(rowData.FemaleAge0to9 || 0);
      columnTotals.MaleAge0to9 += Number(rowData.MaleAge0to9 || 0);
      columnTotals.OtherAge0to9 += Number(rowData.OtherAge0to9 || 0);
      columnTotals.FemaleAge10to14 += Number(rowData.FemaleAge10to14 || 0);
      columnTotals.MaleAge10to14 += Number(rowData.MaleAge10to14 || 0);
      columnTotals.OtherAge10to14 += Number(rowData.OtherAge10to14 || 0);
      columnTotals.FemaleAge15to18 += Number(rowData.FemaleAge15to18 || 0);
      columnTotals.MaleAge15to18 += Number(rowData.MaleAge15to18 || 0);
      columnTotals.OtherAge15to18 += Number(rowData.OtherAge15to18 || 0);
      columnTotals.FemaleAge19yrs += Number(rowData.FemaleAge19yrs || 0);
      columnTotals.MaleAge19yrs += Number(rowData.MaleAge19yrs || 0);
      columnTotals.OtherAge19yrs += Number(rowData.OtherAge19yrs || 0);
      columnTotals.FemaleAge20to24 += Number(rowData.FemaleAge20to24 || 0);
      columnTotals.MaleAge20to24 += Number(rowData.MaleAge20to24 || 0);
      columnTotals.OtherAge20to24 += Number(rowData.OtherAge20to24 || 0);
      columnTotals.FemaleAge25to29 += Number(rowData.FemaleAge25to29 || 0);
      columnTotals.MaleAge25to29 += Number(rowData.MaleAge25to29 || 0);
      columnTotals.OtherAge25to29 += Number(rowData.OtherAge25to29 || 0);
      columnTotals.FemaleAge30to34 += Number(rowData.FemaleAge30to34 || 0);
      columnTotals.MaleAge30to34 += Number(rowData.MaleAge30to34 || 0);
      columnTotals.OtherAge30to34 += Number(rowData.OtherAge30to34 || 0);
      columnTotals.FemaleAge35to39 += Number(rowData.FemaleAge35to39 || 0);
      columnTotals.MaleAge35to39 += Number(rowData.MaleAge35to39 || 0);
      columnTotals.OtherAge35to39 += Number(rowData.OtherAge35to39 || 0);
      columnTotals.FemaleAge40to44 += Number(rowData.FemaleAge40to44 || 0);
      columnTotals.MaleAge40to44 += Number(rowData.MaleAge40to44 || 0);
      columnTotals.OtherAge40to44 += Number(rowData.OtherAge40to44 || 0);
      columnTotals.FemaleAge45to49 += Number(rowData.FemaleAge45to49 || 0);
      columnTotals.MaleAge45to49 += Number(rowData.MaleAge45to49 || 0);
      columnTotals.OtherAge45to49 += Number(rowData.OtherAge45to49 || 0);
      columnTotals.FemaleAge50to59 += Number(rowData.FemaleAge50to59 || 0);
      columnTotals.MaleAge50to59 += Number(rowData.MaleAge50to59 || 0);
      columnTotals.OtherAge50to59 += Number(rowData.OtherAge50to59 || 0);
      columnTotals.FemaleAge60andAbove += Number(
        rowData.FemaleAge60andAbove || 0
      );
      columnTotals.MaleAge60andAbove += Number(rowData.MaleAge60andAbove || 0);
      columnTotals.OtherAge60andAbove += Number(
        rowData.OtherAge60andAbove || 0
      );
      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Area,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Dataname,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.FemaleAge0to9 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge0to9 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge0to9 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge10to14 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge10to14 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge10to14 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge15to18 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge15to18 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge15to18 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge19yrs || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge19yrs || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge19yrs || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge20to24 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge20to24 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge20to24 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge25to29 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge25to29 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge25to29 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge30to34 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge30to34 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge30to34 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge35to39 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge35to39 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge35to39 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge40to44 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge40to44 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge40to44 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge45to49 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge45to49 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge45to49 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge50to59 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge50to59 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge50to59 || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.FemaleAge60andAbove || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.MaleAge60andAbove || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OtherAge60andAbove || 0),
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });
    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      {
        v: columnTotals.FemaleAge0to9,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge0to9,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge0to9,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge10to14,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge10to14,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge10to14,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge15to18,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge15to18,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge15to18,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge19yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge19yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge19yrs,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge20to24,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge20to24,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge20to24,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge25to29,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge25to29,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge25to29,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge30to34,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge30to34,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge30to34,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge35to39,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge35to39,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge35to39,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge40to44,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge40to44,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge40to44,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge45to49,
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge45to49,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge45to49,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge50to59,
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge50to59,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge50to59,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.FemaleAge60andAbove,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.MaleAge60andAbove,
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals.OtherAge60andAbove,
        t: "n",
        s: totalsRowStyle,
      },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // const wsData = [headerRow, ...dataRows];
    // const ws = XLSX.utils.aoa_to_sheet(wsData);

    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
    //   ws[cellAddress].s = cell.s;
    // });

    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 1,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = { v: cell.v, s: cell.s };
    //   });
    // });
    // Set custom column widths
    // ws["!cols"] = [
    //   { width: 5 }, // Index
    //   { width: 38 }, // Dataname
    //   { width: 14 }, // Constant width
    //   { width: 14 }, // Constant width
    //   { width: 14 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 },
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 15 }, // Constant width
    //   { width: 24 }, // Constant width
    //   { width: 24 }, // Constant width
    //   { width: 24 }, // Constant width
    //   { width: 24 }, // Constant width
    //   { width: 15 }, // Row Total
    // ];

    // // Border style for all cells
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 2,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    // Modified column widths
    ws["!cols"] = [
      { width: 5 }, // Index
      { width: 30 }, // branch
      { width: 38 }, // Dataname
      ...columns.slice(2).map(() => ({ width: 15 })), // Data columns
      { width: 15 }, // Total column
    ];

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "Clients by age_gender - Donor-SRH.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    const headers = clientsByAgeGenderDonorSRHHeader.map(
      (column) => column.title
    );

    const data = bindClientsByAgeGenderDonorSRHData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.Dataname,
        rowData.FemaleAge0to9,
        rowData.MaleAge0to9,
        rowData.OtherAge0to9,
        rowData.FemaleAge10to14,
        rowData.MaleAge10to14,
        rowData.OtherAge10to14,
        rowData.FemaleAge15to18,
        rowData.MaleAge15to18,
        rowData.OtherAge15to18,
        rowData.FemaleAge19yrs,
        rowData.MaleAge19yrs,
        rowData.OtherAge19yrs,
        rowData.FemaleAge20to24,
        rowData.MaleAge20to24,
        rowData.OtherAge20to24,
        rowData.FemaleAge25to29,
        rowData.MaleAge25to29,
        rowData.OtherAge25to29,
        rowData.FemaleAge30to34,
        rowData.MaleAge30to34,
        rowData.OtherAge30to34,
        rowData.FemaleAge35to39,
        rowData.MaleAge35to39,
        rowData.OtherAge35to39,
        rowData.FemaleAge40to44,
        rowData.MaleAge40to44,
        rowData.OtherAge40to44,
        rowData.FemaleAge45to49,
        rowData.MaleAge45to49,
        rowData.OtherAge45to49,
        rowData.FemaleAge50to59,
        rowData.MaleAge50to59,
        rowData.OtherAge50to59,
        rowData.FemaleAge60andAbove,
        rowData.MaleAge60andAbove,
        rowData.OtherAge60andAbove,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Auto adjust width
        7: { cellWidth: "auto" }, // Auto adjust width
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Age
        11: { cellWidth: "auto" }, // Gender
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Index column
        17: { cellWidth: "auto" }, // Auto adjust width
        18: { cellWidth: "auto" }, // Auto adjust width
        19: { cellWidth: "auto" }, // Auto adjust width
        20: { cellWidth: "auto" }, // Auto adjust width
        21: { cellWidth: "auto" }, // Auto adjust width
        22: { cellWidth: "auto" }, // Auto adjust width
        23: { cellWidth: "auto" }, // Auto adjust width
        24: { cellWidth: "auto" }, // Auto adjust width
        25: { cellWidth: "auto" }, // Auto adjust width
        26: { cellWidth: "auto" }, // Age
        27: { cellWidth: "auto" }, // Gender
        28: { cellWidth: "auto" }, // Auto adjust width
        29: { cellWidth: "auto" }, // Auto adjust width
        30: { cellWidth: "auto" }, // Auto adjust width
        31: { cellWidth: "auto" }, // Auto adjust width
        32: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("Clients by age_gender - Donor-SRH.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setSDP(updatedOptions);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  useEffect(() => {
    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    getSelectedSDP();
  }, []);

  useEffect(() => {
    const getSelectedSDP = async (userProfileDatas) => {
      try {
        const locationResponse = await BindAPI("SDPAPI/GetSDP");
        const locationData = locationResponse.data.Table;
        setGetSDPDatas(locationData);

        if (userProfileDatas && userProfileDatas[0]) {
          const sdpLabels = locationData
            .filter((item) =>
              branchProject.includes(parseInt(item.MstLocationId))
            )
            .map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

          const sdpSelected = sdpLabels.map((SD) => ({
            value: SD.MstSDPId,
            label: SD.SDP_en,
          }));

          let filteredSdpSelected = [];

          if (
            userProfileDatas[0].MstOrganizationId === 1 ||
            userProfileDatas[0].MstOrganizationId === 2
          ) {
            filteredSdpSelected = sdpSelected;
          } else {
            const allSDPIds = userProfileDatas[0]?.AllSDP;
            const sdpId = userProfileDatas[0]?.MstSDPId;

            if (allSDPIds && Array.isArray(allSDPIds)) {
              filteredSdpSelected = sdpSelected.filter((sdp) =>
                allSDPIds.includes(sdp.value)
              );
            }
            if (sdpId) {
              console.warn("allSDPIds is undefined or not an array");
              filteredSdpSelected = sdpSelected.filter((sdp) =>
                Array.isArray(sdpId)
                  ? sdpId.includes(sdp.value)
                  : sdpId === sdp.value
              );
            }
          }

          setBindSDP(filteredSdpSelected);
        }
      } catch (e) {
        console.log(`Error fetching data: ${e}`);
      }
    };

    if (branchProject && userProfileDatas) {
      getSelectedSDP(userProfileDatas);
    }
  }, [branchProject, userProfileDatas]);

  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);
  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Clients by age_gender - Donor-SRH`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  //   class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindClientsByAgeGenderDonorSRHData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={clientsByAgeGenderDonorSRHHeader}
                records={bindClientsByAgeGenderDonorSRHData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ClientsByAgeGenderDonorSRHReport;
