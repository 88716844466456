import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import { issueDetailsPopUpHeader } from "../components/HeaderAttribute";
import TableGrid from "../components/Table";
import moment from "moment";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import DropDown from "../ReusableComponents/DropDown";

const InventoryIssuePopup = ({ data, data1, data3 }) => {
  debugger;
  console.log("data", data);
  console.log("data1", data1);
  console.log("data2", data3);
  const [visibleIssueType1, setVisibleIssueType1] = useState(false);
  const [visibleIssueType2, setVisibleIssueType2] = useState(false);
  const [bindReceivedFromDD, setBindReceivedFromDD] = useState([
    { value: 1, label: "CBP" },
    { value: 2, label: "Associated clinics" },
  ]);
  useEffect(() => {
    if (data?.IssueType == 1) {
      setVisibleIssueType1(true);
      setVisibleIssueType2(false);
    } else if (data?.IssueType == 2) {
      setVisibleIssueType1(false);
      setVisibleIssueType2(true);
    } else {
      setVisibleIssueType1(true);
      setVisibleIssueType2(true);
    }
  }, [data]);

  const date = new Date(data?.IssueDate);
  return (
    <div class="w-full">
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form className={mainTable.formForClientMaster}>
              <div className={mainTable.grid4}>
                <InputText
                  classStar="text-red-500"
                  inputType="text"
                  title="Issue No."
                  name="issueNo"
                  content={data?.IssueNo}
                  disabled={true}
                />

                <InputText
                  classStar="text-red-500"
                  inputType="date"
                  title="issue Date"
                  name="issueDate"
                  content={data3[0]}
                  disabled={true}
                  // content={moment(data?.IssueDate).format("YYYY-MM-DD")}
                />

                <InputText
                  classStar="text-red-500"
                  inputType="text"
                  title="Branch"
                  name="branch"
                  content={data?.BranchProject_en}
                  placeholder=""
                  disabled={true}
                />

                <InputText
                  inputType="text"
                  title="S.D.P."
                  name="sdp"
                  content={data?.SDP_en}
                  placeholder=""
                  disabled={true}
                />

                <InputText
                  inputType="text"
                  title="Area"
                  name="areaName"
                  content={data?.UserAreaName_en}
                  disabled={true}
                />
                <InputText
                  inputType="text"
                  title="Outreach staff"
                  name="MstORWId"
                  content={data1[0]?.FirstName_en}
                  disabled={true}
                />
                {visibleIssueType1 && (
                  <InputText
                    classStar="text-red-500"
                    inputType="text"
                    title="Issue To"
                    name="receivedQuantity"
                    content={data?.ReceivedFrom_en}
                    disabled={true}
                  />
                )}
                {visibleIssueType2 && (
                  <DropDown
                    title="Issued To"
                    options={bindReceivedFromDD}
                    placeholder="Select"
                    name="productReceivedFrom"
                    selectedOption={data?.IssueTo}
                    disabled={true}
                  />
                )}
                {visibleIssueType1 && (
                  <InputText
                    classStar="text-red-500"
                    inputType="text"
                    title="Receiver"
                    name="receiver"
                    content={data?.LocationName_en}
                    disabled={true}
                  />
                )}
                <InputText
                  inputType="text"
                  title="Area"
                  name="areaName"
                  content={data?.AreaName_en}
                  disabled={true}
                />
                {visibleIssueType2 && (
                  <InputText
                    classStar="text-red-500"
                    inputType="text"
                    title={"Issued Through"}
                    name="receiver"
                    content={data?.IssuedThrough}
                    placeholder={"Issued Through"}
                    disabled={true}
                  />
                )}
              </div>
              <TableGrid
                headerData={issueDetailsPopUpHeader}
                tableData={data1}
                itemsPerPage={100}
                currentPage={1}
                xScroll="overflow-x-auto max-md:w-fit w-full"
              />
              <TextArea
                inputType="text"
                title="Remarks"
                name="remarks"
                rows="4"
                content={data?.IssueRemarks_en}
                disabled={true}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryIssuePopup;
