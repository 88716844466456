// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import TableGrid from "../components/Table";
import MainPopup from "../popup-containers/MainPopup";
import { spServiceSessionMasterHeaders } from "../components/HeaderAttribute";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import SPServiceSessionPopup from "../popup-containers/SPServicesSessionPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import PopUpButtons from "../components/PopUpButtons";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const SplServicesTrans = () => {
  const location1 = useLocation();
  //User Capability State data start
  const { userProfileState } = useUserCapability();
  const userVsCapability = userProfileState.userVsCapability;
  const userProfileDatas = userProfileState.userProfile;
  const navigate = useNavigate();
  const [viewBag, setViewBag] = useState(0);
  const [addBag, setAddBag] = useState(0);
  const [editBag, setEditBag] = useState(0);
  const [deleteBag, setDeleteBag] = useState(0);
  const [blockBag, setBlockBag] = useState(0);
  //User Capability State data end;
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [bindSPServices, setBindSPServices] = useState([]);
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [place, setPlace] = useState("");
  const [remarks, setRemarks] = useState("");
  const [spServiceType, setSpServiceType] = useState("");
  const [clientType, setClientType] = useState("");
  const [hcpName, setHCPName] = useState("");
  const [male, setMale] = useState("");
  const [female, setFemale] = useState("");
  const [other, setOther] = useState("");
  const [total, setTotal] = useState("");
  const [updateButton, setUpdateButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [bindState, setBindStatesDD] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  // const [state, setState] = useState(bindState[19]);
  // const [area, setArea] = useState(bindArea[28]);
  // const [sdp, setSdp] = useState(bindSDP[4]);
  // const [location, setLocation] = useState(bindLocation[21]);
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [sdp, setSdp] = useState("");
  const [location, setLocation] = useState("");
  const [mstOrganizationId, setMstOrganizationId] = useState("");
  const [dateTimeError, setDateTimeError] = useState("");
  const [venueError, setVenueError] = useState("");
  const [spServiceTopicError, setSPServiceTopicError] = useState("");
  const [hcpNameError, setHCPNameError] = useState("");
  const [stateError, setStateError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [sdpError, setSdpError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [totalError, setTotalError] = useState("");

  const [dateError, setDateError] = useState("");
  const [placeError, setPlaceError] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [blockError, setBlockError] = useState("");
  const [cityVillageError, setCityVillageError] = useState("");
  const [spServiceTypeError, setSpServiceTypeError] = useState("");
  const [clientTypeError, setClientTypeError] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [editMstSPServicesId, setEditMstSPServicesId] = useState("");
  const [spServiceData2, setSPServiceData2] = useState({});

  const [spServices, setSPServicesData] = useState("");

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [bindHcpName, setBindHcpName] = useState([]);
  const [bindSPSType, setBindSPSType] = useState([]);
  const [bindClientType, setBindClientType] = useState([]);
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [cityVillage, setCityVillage] = useState("");
  const [bindBlock, setBindBlock] = useState([]);
  const [getBlockDatas, setGetBlockDatas] = useState([]);
  const [getCityDatas, setGetCityDatas] = useState([]);
  const [bindCityVillage, setBindCityVillage] = useState([]);

  const [bindDistrict, setBindDistrict] = useState([]);
  const [getDistrictDatas, setGetDistrictDatas] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    BindClientGridData(userProfileDatas);
  }, []);
  // const BindClientGridData = async () => {
  //   const response = await BindAPI("SPServiceTransAPI/BindSPServicesAPI");
  //   setBindSPServices(response.data.Table);
  // };
  const BindClientGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const Binddata = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [Binddata],
        "SPServiceTransAPI/BindSPServicesAPI"
      );
      setBindSPServices(response.data.Table);
    }
  };
  useEffect(() => {
    if (location1.pathname == "/spserviceevent") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      BindClientGridData(userProfileDatas);
      cancel();
    }
  }, [location1, userProfileDatas]);
  useEffect(() => {
    const getSelectedMasterDatas = async () => {
      const locationResponse = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );
      setGetLocationDatas(locationResponse.data.Table1);
      setGetAreaDatas(locationResponse.data.Table2);
      setGetSDPDatas(locationResponse.data.Table3);
    };

    getSelectedMasterDatas();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDD(locationSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [state]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == location // Replace with the desired MstStateId
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setBindSDP(sdpSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [location]);
  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    const getSelectedArea = async () => {
      const locationResponse = await BindAPI("AreaAPI/GetArea");
      setGetAreaDatas(locationResponse.data.Table);
    };

    const getSelectedDistrict = async () => {
      const locationResponse = await BindAPI("IECAPI/BindDistrict");
      setGetDistrictDatas(locationResponse.data.Table);
    };

    const getSelectedBlock = async () => {
      const locationResponse = await BindAPI("IECAPI/BindBlock");
      setGetBlockDatas(locationResponse.data.Table);
    };

    const getSelectedCity = async () => {
      const locationResponse = await BindAPI("IECAPI/BindCityVillage");
      setGetCityDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    getSelectedArea();
    getSelectedDistrict();
    getSelectedBlock();
    getSelectedCity();
  }, []);
  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == sdp // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));

      setBindAreaDD(areaSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [sdp]);

  useEffect(() => {
    const fetchData = async () => {
      binddrpstatedata();
      //binddrpHCPdata();
      binddrpTypedata();
      binddrpClientTypedata();
      binddrpDistrict();
      binddrpBlock();
      binddrpCityVillage();
      bindHealthCaredata();
    };

    fetchData();
  }, []);

  const bindHealthCaredata = async () => {
    try {
      const bindHealthCaredatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      if (bindHealthCaredatadrp.data.Table10.length > 0) {
        const Typedatadrp = bindHealthCaredatadrp.data.Table10.map((m) => ({
          value: m.MstHealthCareProviderId,
          label: m.HealthcareProviderName_en,
        }));
        setBindHcpName(Typedatadrp);
      } else {
        setBindHcpName([]);
      }
    } catch (error) {}
  };

  const binddrpstatedata = async () => {
    try {
      const bindStatedatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      if (bindStatedatadrp.data.Table.length > 0) {
        const stateDD = bindStatedatadrp.data.Table.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindStatesDD(stateDD);
      } else {
        setBindStatesDD([]);
      }
    } catch (error) {}
  };

  const binddrpTypedata = async () => {
    try {
      const bindTypedatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      if (bindTypedatadrp.data.Table5.length > 0) {
        const Typedatadrp = bindTypedatadrp.data.Table5.map((m) => ({
          value: m.MstSpServicesTopicsId,
          label: m.SPServicesName_en,
        }));
        setBindSPSType(Typedatadrp);
      } else {
        setBindSPSType([]);
      }
    } catch (error) {}
  };

  const binddrpClientTypedata = async () => {
    try {
      const bindClientTypedatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      if (bindClientTypedatadrp.data.Table6.length > 0) {
        const ClientTypedatadrp = bindClientTypedatadrp.data.Table6.map(
          (m) => ({
            value: m.MstSPClientTypeId,
            label: m.ClientType_en,
          })
        );
        setBindClientType(ClientTypedatadrp);
      } else {
        setBindClientType([]);
      }
    } catch (error) {}
  };
  const binddrpDistrict = async () => {
    try {
      const bindDistrictdatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      setGetDistrictDatas(bindDistrictdatadrp.data.Table7);
    } catch (error) {}
  };

  const binddrpBlock = async () => {
    try {
      const bindBlockdatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      setGetBlockDatas(bindBlockdatadrp.data.Table8);
    } catch (error) {}
  };

  const binddrpCityVillage = async () => {
    try {
      const bindCityVillagedatadrp = await BindAPI(
        "SPServiceTransAPI/BindAllMasterSPServicesAPI"
      );

      setGetCityDatas(bindCityVillagedatadrp.data.Table9);
    } catch (error) {}
  };

  const dropdownTopics = (rowIndex) => {
    try {
      const TopicsrowData = bindSPServices[rowIndex];
      const TopicsInfo = bindSPSType.find(
        (ST) => ST.value === TopicsrowData.SPServiceTopic
      );

      const resultTopics = {
        value: TopicsrowData.SPServiceTopic,
        label: TopicsInfo ? TopicsInfo.label : "",
      };

      setSpServiceType(resultTopics);
    } catch (error) {}
  };

  const handleDropDown = (event) => {
    // To set Number of records per page
    const selectedValue = parseInt(event.target.value); // Convert to integer
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    // setPopUpOpenCountry(false);
  };
  const HandleBindSPService = (rowIndex, flag) => {
    debugger;
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setSPServicesData(bindSPServices[rowIndex]);
    const ESPID = bindSPServices[rowIndex].MstSPServicesId;
    setEditMstSPServicesId(ESPID);
    let SPDate = "";
    var SDate = bindSPServices[rowIndex]?.SPDateTime; // Add nullish coalescing operator
    if (SDate !== null && SDate !== undefined) {
      SPDate = SDate.split("T")[0];
    } else {
      SPDate = "";
    }
    const StateRowData = bindSPServices[rowIndex];
    const StateInfo = bindState.find(
      (SD) => SD.value === StateRowData.MstStateId
    );
    const setStateData = {
      value: StateRowData.MstStateId,
      label: StateInfo ? StateInfo.label : "Unknown",
    };
    setState(setStateData.value);
    const locationRowData = bindSPServices[rowIndex];
    const locationInfo = getLocationDatas.find(
      (SD) => SD.MstLocationId === locationRowData.MstLocationId
    );
    const setLocationData = {
      value: locationRowData.MstLocationId,
      label: locationInfo ? locationInfo.LocationName_en : "Unknown",
    };
    setLocation(setLocationData.value);
    const sdpRowData = bindSPServices[rowIndex];
    const sdpInfo = getSDPDatas.find(
      (SD) => SD.MstSDPId === sdpRowData.MstSDPId
    );
    const setSdpData = {
      value: sdpRowData.MstSDPId,
      label: sdpInfo ? sdpInfo.SDP_en : "Unknown",
    };
    setSdp(setSdpData.value);
    const areaRowData = bindSPServices[rowIndex];
    const areaInfo = getAreaDatas.find(
      (SD) => SD.MstAreaId === areaRowData.MstAreaId
    );
    const setAreaData = {
      value: areaRowData.MstAreaId,
      label: areaInfo ? areaInfo.AreaName_en : "Unknown",
    };
    setArea(setAreaData.value);
    const districtRowData = bindSPServices[rowIndex];
    const districtInfo = getDistrictDatas.find(
      (SD) => SD.MstDistrictId === districtRowData.DistrictId
    );
    const setDistrictData = {
      value: districtRowData.DistrictId,
      label: districtInfo ? districtInfo.District_en : "Unknown",
    };
    setDistrict(setDistrictData.value);
    const blockRowData = bindSPServices[rowIndex];
    const blockInfo = getBlockDatas.find(
      (SD) => SD.MstBlockId === blockRowData.BlockId
    );
    const setBlockData = {
      value: blockRowData.BlockId,
      label: blockInfo ? blockInfo.Block_en : "Unknown",
    };
    setBlock(setBlockData.value);
    const cityVillageRowData = bindSPServices[rowIndex];
    const cityVillageInfo = getCityDatas.find(
      (SD) => SD.MstCityId === cityVillageRowData.CityVillageId
    );
    const setCityVillageData = {
      value: cityVillageRowData.CityVillageId,
      label: cityVillageInfo ? cityVillageInfo.City_en : "Unknown",
    };
    setCityVillage(setCityVillageData.value);
    const setSpServiceType = {
      value: bindSPServices[rowIndex].SPServiceType,
      label: bindSPServices[rowIndex].SPServicesName_en,
    };
    const setClientType = {
      value: bindSPServices[rowIndex].MstSPClientTypeId,
      label: bindSPServices[rowIndex].ClientType_en,
    };

    const SPServiceData2 = [
      SPDate,
      setStateData,
      setLocationData,
      setSdpData,
      setAreaData,
      setDistrictData,
      setBlockData,
      setCityVillageData,
      setSpServiceType,
      setClientType,
    ];
    setSPServiceData2(SPServiceData2);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setUpdateButton(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setMstOrganizationId(userProfileDatas[0].MstOrganizationId);
    const stateLable = bindState.find(
      (ST) => ST.value === userProfileDatas[0].MstStateId
    );
    // const stateValue = {
    //   value: userProfileDatas[0].MstStateId,
    //   label: stateLable ? stateLable.label : "Select",
    // };
    setState(userProfileDatas[0].MstStateId ?? 0);

    const locationLable = getLocationDatas.find(
      (L) => L.MstLocationId === userProfileDatas[0].MstLocationId
    );
    // const locationValue = {
    //   value: userProfileDatas[0].MstLocationId,
    //   label: locationLable ? locationLable.LocationName_en : "Select",
    // };
    setLocation(userProfileDatas[0].MstLocationId ?? 0);

    const SDPInLable = getSDPDatas.find(
      (SD) => SD.MstSDPId === userProfileDatas[0].MstSDPId
    );
    // const SDPValue = {
    //   value: userProfileDatas[0].MstSDPId,
    //   label: SDPInLable ? SDPInLable.SDP_en : "Select",
    // };
    setSdp(userProfileDatas[0].MstSDPId ?? 0);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    clear();
    setSubmitBtnDisabled(false);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    BindClientGridData(userProfileDatas);
  };

  const clear = () => {
    //setDate("");
    setMstOrganizationId("");
    setVenue("");
    setRemarks("");
    setSpServiceType("");
    setHCPName("");
    setState("");
    setArea("");
    setSdp("");
    setLocation("");
    setClientType("");
    setMale("");
    setFemale("");
    setOther("");
    setTotal("");
    setDateTimeError("");
    setVenueError("");
    setSPServiceTopicError("");
    setHCPNameError("");
    setStateError("");
    setLocationError("");
    setSdpError("");
    setAreaError("");
    setTotalError("");
    setPlace("");
    setDistrict("");
    setBlock("");
    setCityVillage("");
    setDateError("");
    setPlaceError("");
    setDistrictError("");
    setBlockError("");
    setCityVillageError("");
    setSpServiceTypeError("");
    setClientTypeError("");
    setMale();
    setFemale();
    setOther();
    setTotal();
  };
  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    if (createdBy == null || createdBy == "") {
      navigate("/");
    }
    if (Array.isArray(userVsCapability) && userVsCapability.length > 0) {
      let menuFilter = userVsCapability.filter(
        (menu) => menu.MstMenuId == "26"
      );
      setViewBag(menuFilter[0]?.ViewPermission);
      setAddBag(menuFilter[0]?.AddPermission);
      setEditBag(menuFilter[0]?.ModifyPermission);
      setDeleteBag(menuFilter[0]?.DeletePermission);
      setBlockBag(menuFilter[0]?.BlockPermission);
    }
  }, [navigate, userVsCapability]);
  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    const ESPID = bindSPServices[rowIndex].MstSPServicesId;
    setEditMstSPServicesId(ESPID);
    var SDate = bindSPServices[rowIndex]?.SPDateTime; // Add nullish coalescing operator
    if (SDate !== null && SDate !== undefined) {
      const SPDate = SDate.split("T")[0];
      setDate(SPDate);
    } else {
      setDate("");
    }
    setState(bindSPServices[rowIndex].MstStateId);
    setLocation(bindSPServices[rowIndex].MstLocationId);
    setSdp(bindSPServices[rowIndex].MstSDPId);
    setArea(bindSPServices[rowIndex].MstAreaId);
    setDistrict(bindSPServices[rowIndex].DistrictId);
    setBlock(bindSPServices[rowIndex].BlockId);
    setCityVillage(bindSPServices[rowIndex].CityVillageId);
    // setState({
    //   value: bindSPServices[rowIndex].MstStateId,
    //   label: bindSPServices[rowIndex].StateName_en,
    // });
    // setLocation({
    //   value: bindSPServices[rowIndex].MstLocationId,
    //   label: bindSPServices[rowIndex].LocationName_en,
    // });
    // setSdp({
    //   value: bindSPServices[rowIndex].MstSDPId,
    //   label: bindSPServices[rowIndex].SDP_en,
    // });
    // setArea({
    //   value: bindSPServices[rowIndex].MstAreaId,
    //   label: bindSPServices[rowIndex].AreaName_en,
    // });

    // setDistrict({
    //   value: bindSPServices[rowIndex].DistrictId,
    //   label: bindSPServices[rowIndex].District_en,
    // });
    // setBlock({
    //   value: bindSPServices[rowIndex].BlockId,
    //   label: bindSPServices[rowIndex].Block_en,
    // });
    // setCityVillage({
    //   value: bindSPServices[rowIndex].CityVillageId,
    //   label: bindSPServices[rowIndex].City_en,
    // });
    setMale(bindSPServices[rowIndex].Male);
    setFemale(bindSPServices[rowIndex].Female);
    setOther(bindSPServices[rowIndex].Other);
    setTotal(bindSPServices[rowIndex].Total);
    setHCPName(bindSPServices[rowIndex].MstHealthCareProviderId);
    setSpServiceType(bindSPServices[rowIndex].SPServiceType);
    setClientType(bindSPServices[rowIndex].MstSPClientTypeId);
    setVenue(bindSPServices[rowIndex].Venue_en);
    setPlace(bindSPServices[rowIndex].Place_en);

    setRemarks(bindSPServices[rowIndex].Remarks_en);
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    const createdBy = sessionStorage.getItem("createdBy");
    if (flag == 1 || flag == 2) {
      // validation start
      const dateValidation = date ? "" : "Enter DOB";
      setDateError(dateValidation);

      const stateValidate = state ? "" : "Enter State";
      setStateError(stateValidate);

      const locationValidate = location ? "" : "Enter Branch/Project";
      setLocationError(locationValidate);

      const sdpValidate = sdp ? "" : "Select a SDP";
      setSdpError(sdpValidate);

      const areaValidate = area ? "" : "Select a Area";
      setAreaError(areaValidate);

      const venueValidation = venue.trim() ? "" : "Enter Venue";
      setVenueError(venueValidation);

      const districtValidate = district ? "" : "Select a District";
      setDistrictError(districtValidate);

      const blockValidate = block ? "" : "Select a Block";
      setBlockError(blockValidate);

      const totalValidate = total ? "" : "Enter No. of Beneficiaries";
      setTotalError(totalValidate);

      const cityVillageValidate = cityVillage ? "" : "Select a City/Village";
      setCityVillageError(cityVillageValidate);

      const hcpNameValidation = hcpName
        ? ""
        : "Select a Healhcare Provider Name";

      setHCPNameError(hcpNameValidation);

      const spServiceTypeValidation = spServiceType
        ? ""
        : "Select a Special Service Session Type";
      setSpServiceTypeError(spServiceTypeValidation);

      const clientTypeValidation = clientType ? "" : "Select a Client Type";
      setClientTypeError(clientTypeValidation);

      // for (let i of languageKeys) {
      //   const translateText = await LanguageTranslatorAPI(hcpName, i);
      //   translatedhpNameTexts.push(
      //     translateText.data.data.translations[0].translatedText
      //   );
      // }

      if (
        dateValidation ||
        stateValidate ||
        locationValidate ||
        sdpValidate ||
        areaValidate ||
        venueValidation ||
        districtValidate ||
        blockValidate ||
        cityVillageValidate ||
        hcpNameValidation ||
        spServiceTypeValidation ||
        clientTypeValidation ||
        totalValidate
      ) {
        setIsOpen(true);
      }

      if (
        !dateValidation &&
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !areaValidate &&
        !venueValidation &&
        !districtValidate &&
        !blockValidate &&
        !cityVillageValidate &&
        !hcpNameValidation &&
        !spServiceTypeValidation &&
        !clientTypeValidation &&
        !totalValidate
      ) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedvenueTexts = [];
        const translatedhpNameTexts = [];
        const translatedremarksTexts = [];

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(venue, i);
          translatedvenueTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(remarks, i);
          translatedremarksTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        console.log("district", district);
        const data = {
          mstSPServicesId: editMstSPServicesId ? editMstSPServicesId : 0,
          date: date,

          mstStateId: state,
          mstLocationId: location,
          mstSDPId: sdp,
          mstAreaId: area,
          districtId: district,
          blockId: block,
          cityVillageId: cityVillage,
          venue_en: venue,
          venue_ta: venue,
          venue_hi: venue,
          venue_mr: venue,
          venue_gu: venue,
          venue_ml: venue,
          venue_te: venue,
          venue_kn: venue,
          venue_bn: venue,
          venue_or: venue,
          venue_ta: translatedvenueTexts[0],
          venue_hi: translatedvenueTexts[1],
          venue_mr: translatedvenueTexts[2],
          venue_gu: translatedvenueTexts[3],
          venue_ml: translatedvenueTexts[4],
          venue_te: translatedvenueTexts[5],
          venue_kn: translatedvenueTexts[6],
          venue_bn: translatedvenueTexts[7],
          venue_or: translatedvenueTexts[8],
          mstHealthCareProviderId: hcpName,
          // hcpName_ta: translatedhpNameTexts[0],
          // hcpName_hi: translatedhpNameTexts[1],
          // hcpName_mr: translatedhpNameTexts[2],
          // hcpName_gu: translatedhpNameTexts[3],
          // hcpName_ml: translatedhpNameTexts[4],
          // hcpName_te: translatedhpNameTexts[5],
          // hcpName_kn: translatedhpNameTexts[6],
          // hcpName_bn: translatedhpNameTexts[7],
          // hcpName_or: translatedhpNameTexts[8],
          spServiceType: spServiceType,
          clientType: clientType,
          male: male ? male : 0,
          female: female ? female : 0,
          other: other ? other : 0,
          total: total ? total : 0,
          remarks_en: remarks,
          remarks_ta: translatedremarksTexts[0],
          remarks_hi: translatedremarksTexts[1],
          remarks_mr: translatedremarksTexts[2],
          remarks_gu: translatedremarksTexts[3],
          remarks_ml: translatedremarksTexts[4],
          remarks_te: translatedremarksTexts[5],
          remarks_kn: translatedremarksTexts[6],
          remarks_bn: translatedremarksTexts[7],
          remarks_or: translatedremarksTexts[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "SPServiceTransAPI/IUDSPServices"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Special Services Session added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Special Services Session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Special Services Session updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Special Services Session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        mstSPServicesId: bindSPServices[items].MstSPServicesId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "SPServiceTransAPI/IUDSPServices");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Special Services Session data deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Special Services Session data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Special Services Session data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Special Services Session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setDate(formattedDateTime);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setDate(formattedDate);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const totalChilds =
      (parseInt(male, 10) || 0) +
      (parseInt(female, 10) || 0) +
      (parseInt(other, 10) || 0);
    setTotal(totalChilds > 0 ? totalChilds : ""); // Set total only if greater than 0
  }, [male, female, other]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getDistrictDatas.find(
      (item) => item.MstStateId == state // Replace with the desired MstStateId
    );
    setBindDistrict([]);
    if (stateSelectedValue) {
      const districtLabels = getDistrictDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstDistrictId: item.MstDistrictId,
          District_en: item.District_en,
        }));

      const districtSelected = districtLabels.map((LC) => ({
        value: LC.MstDistrictId, // Replace with the appropriate value
        label: LC.District_en,
      }));
      setBindDistrict(districtSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    debugger;
    const BlockSelectedValue = getBlockDatas.find(
      (item) => item.MstDistrictId == district // Replace with the desired MstStateId
    );
    setBindBlock([]);
    if (BlockSelectedValue) {
      const blockLabels = getBlockDatas
        .filter(
          (item) => item.MstDistrictId === BlockSelectedValue.MstDistrictId
        )
        .map((item) => ({
          MstBlockId: item.MstBlockId,
          Block_en: item.Block_en,
        }));

      const blockSelected = blockLabels.map((SD) => ({
        value: SD.MstBlockId, // Replace with the appropriate value
        label: SD.Block_en,
      }));
      setBindBlock(blockSelected);
    } else {
    }
  }, [district]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const citySelectedValue = getCityDatas.find(
      (item) => item.MstBlockId == block // Replace with the desired MstStateId
    );
    setBindCityVillage([]);
    if (citySelectedValue) {
      const cityLabels = getCityDatas
        .filter((item) => item.MstBlockId === citySelectedValue.MstBlockId)
        .map((item) => ({
          MstCityId: item.MstCityId,
          City_en: item.City_en,
        }));

      const citySelected = cityLabels.map((AR) => ({
        value: AR.MstCityId, // Replace with the appropriate value
        label: AR.City_en,
      }));
      setBindCityVillage(citySelected);
    } else {
      setBindCityVillage([]);
    }
  }, [block]);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title={`Service Session`}
          header={spServiceSessionMasterHeaders}
          records={bindSPServices}
          handleEdit={handleEdit}
          handleAction={HandleBindSPService}
          showAdd={showadd}
          blockView={true}
        />
      )}

      {visibleAdd && (
        <div class="w-full ">
          <div class=" border-black  ">
            <div class="text-black text-2xl font-bold my-2">
              <h3 class="mb-4 text-xl font-medium text-gray-900 ">
                {addScreenTitle && (
                  <div class={mainTable.addScreenTitle}>
                    {`Service Session - Add`}
                  </div>
                )}

                {updateScreenTitle && (
                  <div class={mainTable.addScreenTitle}>
                    {`Service Session - Edit`}
                  </div>
                )}
              </h3>
            </div>
          </div>

          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form class="space-y-6" action="#">
                <div className={mainTable.grid3}>
                  <div>
                    <InputText
                      inputType="date"
                      title="Date"
                      name="date"
                      classStar={"text-red-500"}
                      content={date}
                      controlFunc={(e) => {
                        setDate(e.target.value);
                      }}
                      controlFuncBlur={(e) => {
                        setDate(e.target.value);
                        setDateError(date ? "" : "Please Select Date");
                      }}
                    />
                    {dateError && <span class="text-red-500">{dateError}</span>}
                  </div>

                  <div>
                    <AutoDropDown
                      title="State"
                      options={bindState}
                      classStar="text-red-500"
                      Class="p-0"
                      placeholder="Select"
                      name="state"
                      selectedOption={bindState.find((x) => x.value == state)}
                      disabled={mstOrganizationId == 1 ? false : true}
                      controlFunc={(value) => {
                        setLocation("");
                        setSdp("");
                        setArea("");
                        setDistrict("");
                        setBlock("");
                        setCityVillage("");
                        setState(value.value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select a State");
                      }}
                    />
                    {stateError && (
                      <span className="text-red-500">{stateError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Branch/Project"
                      options={bindLocation}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="state"
                      selectedOption={bindLocation.find(
                        (x) => x.value == location
                      )}
                      disabled={mstOrganizationId == 1 ? false : true}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSdp("");
                        setArea("");
                        setLocation(value.value);
                        setLocationError(
                          value ? "" : "Select a Branch/Project"
                        );
                      }}
                      controlFuncBlur={() => {
                        setLocationError(
                          location ? "" : "Select a Branch/Project"
                        );
                      }}
                    />
                    {locationError && (
                      <span className="text-red-500">{locationError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title="S.D.P"
                      options={bindSDP}
                      placeholder="Select"
                      name="S.D.P"
                      selectedOption={sdp ? bindSDP.find((x) => x.value == sdp) : ""}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setArea("");
                        setSdp(value.value);
                        setSdpError(value ? "" : "Select a SDP");
                      }}
                      controlFuncBlur={() => {
                        setSdpError(sdp ? "" : "Select a SDP");
                      }}
                    />
                    {sdpError && (
                      <span className="text-red-500">{sdpError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Area"
                      options={bindArea}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="state"
                      selectedOption={area ? bindArea.find((x) => x.value == area) : ""}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setArea(value.value);
                        setAreaError(value ? "" : "Select a Area");
                      }}
                      controlFuncBlur={() => {
                        setAreaError(area ? "" : "Select a Area");
                      }}
                    />
                    {areaError && (
                      <span className="text-red-500">{areaError}</span>
                    )}
                  </div>

                  <div>
                    <InputText
                      inputType="text"
                      title="Venue"
                      name="venue"
                      placeholder={"xxxxx"}
                      maxLength={100}
                      content={venue}
                      controlFunc={(e) => {
                        const value = e.target.value;
                        setVenue(value);
                        setVenueError(
                          value.trim().length > 0
                            ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(value.trim())
                              ? ""
                              : "Only alphabet and single space between words allowed"
                            : "Enter Venue"
                        );
                      }}
                      classStar={"text-red-500"}
                      controlFuncBlur={(e) => {
                        const trimmedVenue = e.target.value.trim();
                        setVenue(trimmedVenue);
                        setVenueError(
                          trimmedVenue.length > 0
                            ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(trimmedVenue) // No leading, trailing, or consecutive spaces
                              ? ""
                              : "Only alphabet and single space between words allowed"
                            : "Enter Venue"
                        );
                      }}
                    />
                    {venueError && (
                      <span class="text-red-500">{venueError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="District"
                      classStar="text-red-500"
                      options={bindDistrict}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindDistrict.find(
                        (x) => x.value == district
                      )}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBlock("");
                        setCityVillage("");
                        setDistrict(value.value);
                        setDistrictError(value ? "" : "Select a district");
                      }}
                      controlFuncBlur={() => {
                        setDistrictError(district ? "" : "Select a district");
                      }}
                    />
                    {districtError && (
                      <span className="text-red-500">{districtError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Block"
                      classStar="text-red-500"
                      options={bindBlock}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindBlock.find((x) => x.value == block)}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setCityVillage("");
                        setBlock(value.value);
                        setBlockError(value ? "" : "Select a block");
                      }}
                      controlFuncBlur={() => {
                        setBlockError(block ? "" : "Select a block");
                      }}
                    />
                    {blockError && (
                      <span className="text-red-500">{blockError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="City / Village"
                      classStar="text-red-500"
                      options={bindCityVillage}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindCityVillage.find(
                        (x) => x.value == cityVillage
                      )}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setCityVillage(value.value);
                        setCityVillageError(
                          value ? "" : "Select a City Village"
                        );
                      }}
                      controlFuncBlur={() => {
                        setCityVillageError(
                          cityVillage ? "" : "Select a City Village"
                        );
                      }}
                    />
                    {cityVillageError && (
                      <span className="text-red-500">{cityVillageError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Healthcare Provider Name"
                      classStar="text-red-500"
                      options={bindHcpName}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindHcpName.find(
                        (x) => x.value == hcpName
                      )}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setHCPName(value.value);
                        setHCPNameError(
                          value ? "" : "Select a Healhcare Provider Name"
                        );
                      }}
                      controlFuncBlur={() => {
                        setHCPNameError(
                          hcpName ? "" : "Select a Healhcare Provider Name"
                        );
                      }}
                    />

                    {hcpNameError && (
                      <span class="text-red-500">{hcpNameError}</span>
                    )}
                  </div>
                  {/* <AutoDropDown
                    title="Healhcare Provider Name"
                    classStar={"text-red-500"}
                    options={bindHcpName}
                    placeholder="Select"
                    name="hcpName"
                    selectedOption={hcpName}
                    controlFunc={(value) => {
                      setHCPName(value);
                    }}
                    AutoDropDown
                    Class="custom-class"
                    disabled={false}
                    tabIndex="1"
                  /> */}
                  <div>
                    <AutoDropDown
                      title="Special Service Session Type"
                      classStar={"text-red-500"}
                      options={bindSPSType}
                      placeholder="Select"
                      name="spServiceType"
                      selectedOption={bindSPSType.find(
                        (x) => x.value == spServiceType
                      )}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSpServiceType(value.value);
                        setSpServiceTypeError(
                          value ? "" : "Select a Special Service Session Type"
                        );
                      }}
                      controlFuncBlur={() => {
                        setSpServiceTypeError(
                          spServiceType
                            ? ""
                            : "Select a Special Service Session Type"
                        );
                      }}
                    />
                    {spServiceTypeError && (
                      <span className="text-red-500">{spServiceTypeError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Client Type"
                      classStar={"text-red-500"}
                      options={bindClientType}
                      placeholder="Select"
                      name="clientType"
                      selectedOption={bindClientType.find(
                        (x) => x.value == clientType
                      )}
                      Class="custom-class"
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(value) => {
                        setClientType(value.value);
                        setClientTypeError(value ? "" : "Select a Client Type");
                      }}
                      controlFuncBlur={() => {
                        setClientTypeError(
                          clientType ? "" : "Select a Client Type"
                        );
                      }}
                    />
                    {clientTypeError && (
                      <span className="text-red-500">{clientTypeError}</span>
                    )}
                  </div>
                  <div className="col-span-1">
                    <label for="client_name" class={mainTable.label}>
                      No. of Beneficiaries
                      <span className="text-red-500">*</span>
                    </label>
                    <div className={mainTable.inputGenderClient}>
                      <InputText
                        inputType={"text"}
                        ClassDiv="grid gap-2 grid-cols-2"
                        ClassLabel={mainTable.labelBaseline}
                        Class={mainTable.smallInput}
                        title={"Male"}
                        maxLength={5}
                        name={"male"}
                        content={male}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          const parsedValue = parseInt(value, 10);
                          setMale(isNaN(parsedValue) ? 0 : parsedValue);
                        }}
                      />
                      <InputText
                        inputType={"text"}
                        maxLength={5}
                        ClassDiv="grid gap-2 grid-cols-2"
                        ClassLabel={mainTable.labelBaseline}
                        Class={mainTable.smallInput}
                        title={"Female"}
                        name={"female"}
                        content={female}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          const parsedValue = parseInt(value, 10);
                          setFemale(isNaN(parsedValue) ? 0 : parsedValue);
                        }}
                      />
                      <InputText
                        inputType={"text"}
                        maxLength={5}
                        ClassDiv="grid gap-2 grid-cols-2"
                        ClassLabel={mainTable.labelBaseline}
                        Class={mainTable.smallInput}
                        title={"Other"}
                        name={"other"}
                        content={other}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          const parsedValue = parseInt(value, 10);
                          setOther(isNaN(parsedValue) ? 0 : parsedValue);
                        }}
                      />
                      <InputText
                        inputType={"text"}
                        ClassDiv="grid gap-2 grid-cols-2"
                        ClassLabel={mainTable.labelBaseline}
                        Class={mainTable.smallInput}
                        title={"total"}
                        maxLength={6}
                        name={"total"}
                        content={total}
                        readOnly={true}
                        controlFunc={() => {
                          const totalChilds = male + female + other;
                          setTotal(totalChilds);
                        }}
                      />
                    </div>
                    {totalError && (
                      <span class="text-red-500">{totalError}</span>
                    )}
                  </div>
                  <AutoDropDown
                    title="State"
                    classStar={"text-red-500"}
                    classDiv="hidden"
                    options={bindState}
                    placeholder="Select"
                    name="state"
                    selectedOption={state}
                    controlFunc={(value) => {
                      setState(value);
                    }}
                    Class="custom-class"
                    disabled="false"
                    tabIndex="1"
                  />
                  <AutoDropDown
                    title="Branch/Project"
                    classStar={"text-red-500"}
                    classDiv="hidden"
                    options={bindLocation}
                    placeholder="Select"
                    name="state"
                    selectedOption={location}
                    controlFunc={(value) => {
                      setLocation(value);
                    }}
                    Class="custom-class"
                    disabled="false"
                    tabIndex="1"
                  />

                  <div className="lg:col-span-3 sm:col-span-1">
                    <TextArea
                      inputType="text"
                      title="Remarks"
                      name="remarks"
                      content={remarks}
                      maxLength={500}
                      controlFunc={(e) => setRemarks(e.target.value)}
                      rows={4}
                    />
                  </div>
                </div>
              </form>
              <Buttons
                addScreenTitle={addScreenTitle}
                updateScreenTitle={updateScreenTitle}
                handleSubmit={handleSubmit}
                cancel={cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleBindSPService}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div class="text-xl font-medium text-neutral-700 mx-5 ">
            {viewDetails && `Service Session - View`}
            {deleteDetails && `Service Session - Delete`}
            {blockDetails && `Service Session - Block`}
            {unBlockDetails && `Service Session - Unblock`}
          </div>
          <SPServiceSessionPopup data={spServices} data1={spServiceData2} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default SplServicesTrans;
