// Code review - In Progress
// Documentation - In Progress
import React from "react";
import InputText from "../ReusableComponents/InputText";

const ServiceCategory = ({ data }) => {
  return (
    <div class="w-full ">
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <InputText
                  inputType={"text"}
                  classStar="text-red-500"
                  title={"Category"}
                  name={"Category"}
                  disabled="disabled"
                  content={data.ServicesCategory_en}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCategory;
