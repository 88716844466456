// import React, { useState, useEffect } from 'react';
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"

// const YearDropdown = ({ onYearChange }) => {
//   const [years, setYears] = useState([]);
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

//   useEffect(() => {
//     const currentYear = new Date().getFullYear();
//     const startYear = 2023;
//     const yearRange = Array.from(
//       { length: currentYear - startYear + 1 },
//       (_, index) => (startYear + index).toString()
//     );
//     setYears(yearRange);
//   }, []);

//   const handleYearChange = (year) => {
//     setSelectedYear(year);
//     if (onYearChange) {
//       onYearChange(year);
//     }
//   };

//   return (
//     <div className="w-40">
//       <Select value={selectedYear} onValueChange={handleYearChange}>
//         <SelectTrigger>
//           <SelectValue placeholder="Select year" />
//         </SelectTrigger>
//         <SelectContent>
//           {years.map((year) => (
//             <SelectItem key={year} value={year}>
//               {year}
//             </SelectItem>
//           ))}
//         </SelectContent>
//       </Select>
//     </div>
//   );
// };

// export default YearDropdown;
import React, { useState, useEffect } from 'react';

const YearDropdown = ({ onYearChange }) => {
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const yearRange = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => (startYear + index).toString()
    );
    setYears(yearRange);
  }, []);

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    if (onYearChange) {
      onYearChange(year);
    }
  };

  return (
    <div style={{ width: '100px' }}>
      <select 
        value={selectedYear} 
        onChange={handleYearChange}
        style={{
          width: '100%',
          padding: '8px',
          fontSize: '16px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: '#fff'
        }}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default YearDropdown;