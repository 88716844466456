// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import {
  districtMasterHeader,
  sdpMasterHeader,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import DistrictPopup from "../popup-containers/DistrictPopup";
import MainPopup from "../popup-containers/MainPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const DistrictMaster = () => {
  const location1 = useLocation();
  //All bind dropdown value and bind data value useState hooks handling.

  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindDistrictMasterDatas, setBindDistrictMasterDatas] = useState([]);

  //All visible functionality useState hooks handling.
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // SDP Master insert and edit useState hooks handling .
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [district, setDistrict] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [mstDistrictId, setMSTDistrictId] = useState("");

  const createdBy = sessionStorage.getItem("createdBy");

  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [districtMasterData, setDistrictMasterData] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    DistrictMasterData();
    setState("");
    setDistrict("");
    setDistrictError("");
    setStateError("");
  };

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        setBindStateDropDown(statesData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    dropDownDatas();
    DistrictMasterData();
  }, []);
  const DistrictMasterData = async () => {
    const response = await BindAPI("DistrictAPI/BindDistrictMaster");
    setBindDistrictMasterDatas(response.data.Table);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setState(bindDistrictMasterDatas[rowIndex].MstStateId);
    setDistrict(bindDistrictMasterDatas[rowIndex].District_en);
    setMSTDistrictId(bindDistrictMasterDatas[rowIndex].MstDistrictId);
  };

  const handleDistrict = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setDistrictMasterData(bindDistrictMasterDatas[rowIndex]);
    const DistrictData = bindDistrictMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === DistrictData.MstStateId
    );
    const editStateDropDownValue = {
      value: DistrictData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };
    //State DropDown Value End

    const autoDropDownValues = [editStateDropDownValue];
    setPopUpDropDownData(autoDropDownValues);
  };

  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let districtValidate = "";

      districtValidate = district ? "" : "Enter District";

      stateValidate = state ? "" : "Select a State";
      setStateError(stateValidate);
      setDistrictError(districtValidate);
      if (stateValidate || districtValidate) {
        setSubmitBtnDisabled(false);
        setIsOpen(true);
      }
      // Validation end
    
      if (!stateValidate && !districtValidate) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(district, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const data = [
          {
            mstDistrictId: mstDistrictId ? mstDistrictId : 0,
            mstCountryId: 1,
            mstStateId: state,
            district_en: district,
            district_ta: translatedTexts[0],
            district_hi: translatedTexts[1],
            district_mr: translatedTexts[2],
            district_gu: translatedTexts[3],
            district_kn: translatedTexts[4],
            district_ml: translatedTexts[5],
            district_te: translatedTexts[6],
            district_bn: translatedTexts[7],
            district_or: translatedTexts[8],
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "DistrictAPI/IUDDistrict");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("District data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add District data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("District data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update District data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      const data = [
        {
          mstDistrictId: bindDistrictMasterDatas[items].MstDistrictId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "DistrictAPI/IUDDistrict");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("District data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete District data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("District data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block District data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("District data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock District data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location1.pathname == "/districtmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
    }
  }, [location1]);

  return (
    <MainLayout pageName="2">
     

      {visibleTable && (
        <GridPage
          title={"District Master"}
          header={districtMasterHeader}
          records={bindDistrictMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleDistrict}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class="border-black">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="District Master"
            />
          </div>
          <div class="mx-auto md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title={"Country"}
                      options={[]}
                      selectedOption={country}
                      placeholder={"Select"}
                      name={"country"}
                      controlFunc={(value) => {
                        setCountry(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindStateDropDown}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDown.find(
                        (x) => x.value == state
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setState(value.value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select a State");
                      }}
                      errorMessage={stateError}
                    />

                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title="District"
                      name={"district"}
                      maxLength={100}
                      content={district}
                      controlFunc={(e) => {
                        // setDistrict(e.target.value)
                        const value = e.target.value;
                        setDistrict(value);
                        setDistrictError(
                          value.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(value)
                              ? ""  // No error if the input is valid
                              : ""
                            : "Enter District"  // Error message if the input is empty
                        );
                      }}
                      controlFuncBlur={(e) => {
                        // setDistrict(e.target.value);
                        // setDistrictError(
                        //   district.length > 0 ? "" : " Enter District"
                        // );
                        const trimmedValue = e.target.value.trim();
                        setDistrict(trimmedValue);
                        setDistrictError(
                          trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter District"  // Error message if the input is empty
                        );
                      }}
                      errorMessage={districtError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
       {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleDistrict}
      >
        <div>
          <PopUpScreenTitle
            title="District Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <DistrictPopup data={districtMasterData} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default DistrictMaster;
