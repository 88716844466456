// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import { MdCancel } from "react-icons/md";
import InputText from "../ReusableComponents/InputText";
import { useUserCapability } from "./ConstantReducerProvider";
const ReportTableGrid = ({
  tableHeight,
  headerData,
  tableData,
  onViewGrid,
  currentPage,
  itemsPerPage,
  viewBag,
  isGenderBased,
  xScroll,
}) => {
  let currentData = [];
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState();

  if (Array.isArray(tableData)) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    currentData = tableData.slice(startIndex, endIndex);
  }
console.log("tabledata :",currentData);
  const [viewsAll, setViewsAll] = useState(false);

  const [viewImage, setViewImage] = useState({ popup: false, url: "" });
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const showPopUp = (views, rowIndex) => {
    setViewsAll(views);
    setCurrentRowIndex(views ? rowIndex : null);
  };
  const groupedHeaders = {};
  const nonGroupedHeaders = [];
  let totalParticipantsHeader = null;

  let closingBalanceHeader = null;
  let CumulativeHeader = null;
  let trainingHeader = null;
  let trainingschoolsHeader = null;
  let trainingYouthHeader = null;
  let trainingRemarksHeader = null;
  let total = null;
  // Group headers by the 'group' property and identify the 'Total no. of Participants' header
  headerData.forEach((item) => {
    if (item.group) {
      if (!groupedHeaders[item.group]) {
        groupedHeaders[item.group] = [];
      }
      groupedHeaders[item.group].push(item);
    } else if (item.key === "NoOfPaticipantsTotal") {
      totalParticipantsHeader = item;
    } else if (item.key === "Balance") {
      closingBalanceHeader = item;
    } else if (item.key === "Cumulative") {
      CumulativeHeader = item;
    } else if (item.key === "SchoolsEnrolled") {
      trainingHeader = item;
    } else if (item.key === "SchoolsCompleted") {
      trainingschoolsHeader = item;
    } else if (item.key === "YouthGroupsFormedTotal") {
      trainingYouthHeader = item;
    } else if (item.key === "Remarks") {
      trainingRemarksHeader = item;
    } else if (item.key === "Total") {
      total = item;
    }else {
      nonGroupedHeaders.push(item);
    }
  });
  const [service, setService] = useState(false);
  return (
    <div className={`${mainTable.tableShadowWidth}`}>
      <div className={mainTable.table} role="table">
        <div className={`${xScroll ? xScroll : "overflow-x-auto w-fit"}`}>
          {/* <div className="overflow-x-auto w-full"> */}
          {isGenderBased ? (
            <div className={mainTable.tableHeader} role="rowgroup">
              <div className="flex">
                <div className="w-12 px-2 py-3 border border-gray-300">
                  <label className="text-sm font-medium">S.No.</label>
                </div>
                <div className="w-44 px-2 py-3 border border-gray-300">
                  <label className="text-sm font-medium">
                    Contraception Methods
                  </label>
                </div>
                <div className="flex flex-col border-b-2 border-gray-300">
                  <div className="text-center py-1 bg-[#2d2d6e]">
                    <label className="text-sm font-medium">
                      Reasons for Drop Outs
                    </label>
                  </div>
                  <div className="flex">
                    <div className="w-56 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">
                        Complication / Side-effects
                      </label>
                    </div>
                    <div className="w-56 px-2 py-3 border border-gray-300">
                      <label className="text-sm whitespace-pre-line font-medium">
                        Failure (Method /User)/ Confirmed Pregnancy
                      </label>
                    </div>
                    <div className="w-56 px-2 py-3 border border-gray-300">
                      <label className="text-sm whitespace-pre-line font-medium">
                        Personal Reasons-Fears, Difficult to Use, Objection
                      </label>
                    </div>
                    <div className="w-56 px-2 py-3 border border-gray-300">
                      <label className="text-sm whitespace-pre-line font-medium">
                        Supplies related Reason-Difficult to Get Another Source
                      </label>
                    </div>
                    <div className="w-56 px-2 py-3 border border-gray-300">
                      <label className="text-sm whitespace-pre-line font-medium">
                        Planned Pregnancy / Want child
                      </label>
                    </div>
                    <div className="w-36 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">
                        Lost to Follow-up
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col border-b-2 border-gray-300">
                  <div className="text-center py-1 bg-[#2d2d6e]">
                    <label className="text-sm font-medium">
                      Switched Method
                    </label>
                  </div>
                  <div className="flex">
                    <div className="w-36 px-2 py-5 pb-6 border border-gray-300">
                      <label className="text-sm font-medium">
                        Sterilisation (M+F)
                      </label>
                    </div>
                    <div className="w-36 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">IUD</label>
                    </div>
                    <div className="w-24 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">LNG-IUS</label>
                    </div>
                    <div className="w-24 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">Implants</label>
                    </div>
                    <div className="w-24 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">Oral Pill</label>
                    </div>
                    <div className="w-36 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">
                        Condom (M+F)
                      </label>
                    </div>
                    <div className="w-36 px-2 py-3 border border-gray-300">
                      <label className="text-sm font-medium">Injectables</label>
                    </div>
                  </div>
                </div>
                <div className="w-24 px-2 py-3 border-l border-gray-300">
                  <label className="text-sm font-medium">Total</label>
                </div>
              </div>
            </div>
          ) : (
            <div className={mainTable.tableHeader} role="rowgroup">
              <div className="flex">
                {nonGroupedHeaders.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className={item.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">{item.title}</label>
                  </div>
                ))}
              </div>
              {Object.keys(groupedHeaders).map((groupName, groupIndex) => (
                <div key={groupIndex} className="flex flex-col">
                  <div className="flex justify-center">
                    <div className="text-sm font-medium">{groupName}</div>
                  </div>
                  <div className="flex">
                    {groupedHeaders[groupName].map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className={item.width}
                        role="columnheader"
                      >
                        <label className="text-sm font-medium">
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {totalParticipantsHeader && (
                <div className="flex">
                  <div
                    className={totalParticipantsHeader.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {totalParticipantsHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {closingBalanceHeader && (
                <div className="flex">
                  <div
                    className={closingBalanceHeader.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {closingBalanceHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {CumulativeHeader && (
                <div className="flex">
                  <div className={CumulativeHeader.width} role="columnheader">
                    <label className="text-sm font-medium">
                      {CumulativeHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {trainingHeader && (
                <div className="flex">
                  <div className={trainingHeader.width} role="columnheader">
                    <label className="text-sm font-medium">
                      {trainingHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {trainingschoolsHeader && (
                <div className="flex">
                  <div
                    className={trainingschoolsHeader.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {trainingschoolsHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {trainingYouthHeader && (
                <div className="flex">
                  <div
                    className={trainingYouthHeader.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {trainingYouthHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {trainingRemarksHeader && (
                <div className="flex">
                  <div
                    className={trainingRemarksHeader.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {trainingRemarksHeader.title}
                    </label>
                  </div>
                </div>
              )}
              {total && (
                <div className="flex">
                  <div
                    className={total.width}
                    role="columnheader"
                  >
                    <label className="text-sm font-medium">
                      {total.title}
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}

          <div
            className={`overflow-y-auto ${tableHeight ? tableHeight : "h-96"} `}
          >
            {currentData.length > 0 &&
              currentData.map((data, rowIndex) => (
                <div class={mainTable.tableRow}>
                  {headerData.map((keyData) => (
                    <div scope="cell" role="cell" className={keyData.width}>
                      {keyData.key === "action" ? (
                        <div>
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none "
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                          {viewsAll && currentRowIndex == rowIndex && (
                            <div
                              id="dropdownDots"
                              class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44 "
                            >
                              <ul
                                class=""
                                aria-labelledby="dropdownMenuIconButton"
                              >
                                {viewBag == 1 && (
                                  <li>
                                    <a
                                      onClick={() => {
                                        onViewGrid(
                                          startIndex +
                                            rowIndex +
                                            (currentPage - 1) * itemsPerPage,
                                          1
                                        );
                                        showPopUp(false);
                                      }}
                                      class="block px-4 py-2 hover:bg-gray-100 "
                                    >
                                      View
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : keyData.title === "S.No." ? (
                        <div>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      ) : keyData.title === "S.No." && data.BlockedBy ? (
                        <div class={keyData.width1}>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      ) : keyData.key === "RowNumber" ? (
                        <div title={data[keyData.key]}>{data[keyData.key] != "" ? data[keyData.key] : ""}</div>
                      ) : (
                        <div class="relative">
                          <div
                            class="truncate relative"
                            title={data[keyData.key]}
                          >
                            {data[keyData.key]}
                          </div>
                          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            {/* <div class="bg-white text-black rounded p-1">
                              ...
                            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className={mainTable.tableMobile} role="table">
        <div className="w-full md:overflow-y-auto lg:h-96 h-full">
          {currentData.length > 0 &&
            currentData.map((data, rowIndex) => (
              <div scope="col" class={mainTable.mobileTable}>
                {headerData.map((keyData) => (
                  <div scope="cell" class={mainTable.tableData} role="cell">
                    {keyData.key === "action" || keyData.key === "action1" ? (
                      <div class={mainTable.tableData}>
                        <div className="md:block hidden">
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none "
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="md:hidden block flex justify-center">
                          {viewBag === 1 && (
                            <button
                              className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                              onClick={() =>
                                onViewGrid(
                                  startIndex +
                                    rowIndex +
                                    (currentPage - 1) * itemsPerPage,
                                  1
                                )
                              }
                            >
                              View
                            </button>
                          )}
                        </div>
                      </div>
                    ) : keyData.title === "S.No." ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div>
                          {startIndex +
                            rowIndex +
                            1 +
                            (currentPage - 1) * itemsPerPage}
                        </div>
                      </div>
                    ) : (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div className="flex basis-1/2">
                          {data[keyData.key]}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <MainPopup
        popUpOpen={viewImage.popup}
        setpopUpOpen={() => setViewImage({ popup: false, url: "" })}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setViewImage({ popup: false, url: "" })}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div className="flex justify-center p-12 items-center">
            <img src={viewImage.url} class={"w-96 h-96"} />
          </div>
        </div>
      </MainPopup>
    </div>
  );
};

export default ReportTableGrid;
