import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/Table";
import {
  ServicesAddHeader,
  ServicesHeaders,
  ServicesHeadersData,
  SpecialServiceSessionHeader,
  spServiceSessionMasterHeaders,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import UserDetails from "../popup-containers/UserDetails";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Select from "react-select";
import { HandleProvideService } from "./ServiceProvideGrid";
import { Link, useLocation } from "react-router-dom";
import { MdCancel, MdOutlineTimer3Select } from "react-icons/md";
import MultiSelect from "../ReusableComponents/MultiSelect";
import AddServices from "../popup-containers/AddServices";
import IssueOfCommodity from "../popup-containers/IssueOfCommodity";
import DoctorAppoinment from "../popup-containers/DoctorAppoinment";
import Referral from "../popup-containers/Referral";
import SpecialServiceSessionTable from "../components/SpecialServiceSessionTable";
function SpecialServiceSession() {
  const [bindServicesDatas, setBindServicesDatas] = useState([
    {
      sno: 16,
      clientId: "MH-MUM-SAT-MAN-2023-0000143",
      clientName: "Dharmender Kumar",
      clientMotherName: "Bushra Patiya",
      clientGender: "Male / 26",
      clientPhone: 8963254853,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 11,
      clientId: "OR-BHU-SAC-SHJ-2023-0000174",
      clientName: "Vikrant Patel",
      clientMotherName: "Harpreet Kaul",
      clientGender: "Male / 23",
      clientPhone: 6352638456,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 12,
      clientId: "UP-AGR-ASC-HIG-2023-0000883",
      clientName: "Vishwa Sharma",
      clientMotherName: "Divya Rani",
      clientGender: "Male / 24",
      clientPhone: 8563214578,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },

    {
      sno: 20,
      clientId: "JK-SRI-DHI-GAW-2023-0000941",
      clientName: "Sunil Kumar",
      clientMotherName: "Manpreet Kumari",
      clientGender: "Male / 21",
      clientPhone: 7356894256,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 25,
      clientId: "TN-CHE-SAT-AKM-2023-0000729",
      clientName: "Lakshmi Suryakumar",
      clientMotherName: "Rajkumari Suryakumar",
      clientGender: "Female / 26",
      clientPhone: 9635268742,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 29,
      clientId: "TN-CHE-SAC-ASK-2023-0000620",
      clientName: "Bhuvaneshwari Mahin",
      clientMotherName: "Anoushka Mahin",
      clientGender: "Female / 23",
      clientPhone: 8953627863,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 39,
      clientId: "HD-PUN-ORH-TOP-2023-00000720",
      clientName: "Gowri Kumari",
      clientMotherName: "Sumati",
      clientGender: "Female / 29",
      clientPhone: 9352638526,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 40,
      clientId: "MP-BHO-SAT-ANK-2023-0000628",
      clientName: "Heena Yadav",
      clientMotherName: "Sandhya Patel Yadav",
      clientGender: "Female / 21",
      clientPhone: 7356894256,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 43,
      clientId: "RJ-JAI-SAC-HAW-2023-0000124",
      clientName: "Jasmin Patiya",
      clientMotherName: "Padmavati",
      clientGender: "Female / 26",
      clientPhone: 6352418796,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 21,
      clientId: "GJ-RAJ-SAT-GON-2023-0000724",
      clientName: "Harmeet Patel",
      clientMotherName: "Manjusha",
      clientGender: "Male / 23",
      clientPhone: 8953626556,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 23,
      clientId: "KA-BEN-ASC-GUI-2023-0000231",
      clientName: "Jamna Yadav",
      clientMotherName: "Gayathri",
      clientGender: "Male / 22",
      clientPhone: 7822635653,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },

    {
      sno: 1,
      clientId: "DL-DEL-SAT-PUK-2023-0000666",
      clientName: "Suraj",
      clientMotherName: "Ankita",
      clientGender: "Male / 43",
      clientPhone: 8953626556,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 2,
      clientId: "HR-YAM-CBP-SAH-2023-0001011",
      clientName: "Tej Bahadur",
      clientMotherName: "Aparna",
      clientGender: "Male / 28",
      clientPhone: 7356214585,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 3,
      clientId: "WB-KAL-ORH-HAM-2023-0001114",
      clientName: "Trilokanath",
      clientMotherName: "Amarjeet",
      clientGender: "Male / 32",
      clientPhone: 7822635653,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
    {
      sno: 4,
      clientId: "NL-KOH-SAT-BUB-2023-0002012",
      clientName: "Udai patel",
      clientMotherName: "Shanti",
      clientGender: "Male / 24",
      clientPhone: 7526353866,
      address: "20/4 Perumal South Car Street, Tirunelveli Town - 627006",
    },
  ]);
  const [bindServicesData, setBindServicesData] = useState([
    {
      date: "10-10-2023",
      services:
        "Contraception-FP General Counselling-FP-OP-COC-Consultation (Includes F/u)",
    },
    {
      date: "20-09-2023",
      services:
        "Contraception-FP-OP-POP-Consultation (Includes F/u)-FP-OP-COC-Consultation (Includes F/u)",
    },
    {
      date: "20-09-2023",
      services:
        "Contraception-Fertility Awareness-Based (FAB)-FP-FAB-Fertility Awareness Based-Consultation",
    },
    {
      date: "25-08-2023",
      services: "STI/RTI-General-STI/RTI-Consultation",
    },
    {
      date: "25-08-2023",
      services: "STI/RTI-General-STI/RTI-INV-Lab-Hepatitis A Test",
    },
    {
      date: "25-08-2023",
      services: "Urology-General-SUBF-INV-Lab-Hormone assay-Sampling procedure",
    },
  ]);
  const [bindServicesAddData, setBindServicesAddData] = useState([
    { services: "HIV/AIDS-General-HIV-INV-Lab-Rapid test" },
    { services: "HIV/AIDS-General-HIV-Prev-ARVs-PEP" },
    { services: "Contraception-FP General Counselling-FP General Counselling" },
    {
      services:
        "Contraception-FP General Counselling-Consultation (Includes F/u)",
    },
    // {
    //   servicesCategory: "HIV/AIDS",
    //   servicesSubCategory: "General",
    //   services: "HIV-INV-Lab-Rapid test",
    // },
    // {
    //   servicesCategory: "HIV/AIDS",
    //   servicesSubCategory: "General ",
    //   services: "HIV-Prev-ARVs-PEP",
    // },
    // {
    //   servicesCategory: "Contraception",
    //   servicesSubCategory: "FP General Counselling",
    //   services: "FP General Counselling",
    // },
    // {
    //   servicesCategory: "Contraception",
    //   servicesSubCategory: "FP General Counselling",
    //   services: "FP-OP-COC-Consultation (Includes F/u)",
    // },
    // {
    //   servicesCategory: "Contraception",
    //   servicesSubCategory: "FP General Counselling",
    //   services: "FP-OP-COC-Consultation (Includes F/u)",
    // },
    // {
    //   servicesCategory: "Contraception",
    //   servicesSubCategory: "FP General Counselling",
    //   services: "FP-OP-COC-Consultation (Includes F/u)",
    // },
  ]);
  const [issueOfCommoditiesPopupOpen, setIssueOfCommoditiesPopupOpen] =
    useState(false);
  const [bindUnitOfMeasure, setBindUnitOfMeasure] = useState([
    { value: "1", label: "mg" },
    { value: "2", label: "ml" },
    { value: "3", label: "g" },
    { value: "4", label: "Kg" },
  ]);
  const [bindSPSessionCategory, setBindSPSessionCategory] = useState([
    { value: "1", label: "Contraception" },
    { value: "2", label: "Safe Abortion" },
    { value: "3", label: "HIV/AIDS" },
    { value: "4", label: "STI/RTI " },
    { value: "5", label: "Gynaecology" },
  ]);
  const [bindServiceCategory, setBindServiceCategory] = useState([
    { value: "1", label: "Contraception" },
    { value: "2", label: "Safe Abortion" },
    { value: "3", label: "HIV/AIDS" },
    { value: "4", label: "STI/RTI " },
    { value: "5", label: "Gynaecology" },
    { value: "6", label: "Obstetrics " },
    { value: "7", label: "Urology" },
    { value: "8", label: "Subfertility" },
    { value: "9", label: "Specialised SRH " },
    { value: "10", label: "Paediatrics" },
    { value: "11", label: "Other SRH" },
    { value: "12", label: "Non-SRH " },
    { value: "13", label: "Non-Medical Issues" },
  ]);
  const [bindSPSessionSubCategory, setBindSPSessionSubCategory] = useState([
    { value: "13", label: "General" },
    { value: "1", label: "FP General Counselling" },
    { value: "2", label: "IUD & Implant" },
    { value: "3", label: "VSC-Female" },
    { value: "4", label: "VSC-Male" },
  ]);
  const [bindServiceSubCategory, setBindServiceSubCategory] = useState([
    { value: "13", label: "General" },
    { value: "1", label: "FP General Counselling" },
    { value: "2", label: "IUD & Implant" },
    { value: "3", label: "VSC-Female" },
    { value: "4", label: "VSC-Male" },
    { value: "5", label: "Emergency Contraceptives" },
    { value: "6", label: "Fertility Awareness-Based (FAB)" },
    { value: "7", label: "FP General Counselling" },
    { value: "8", label: "IUD & Implant" },
    { value: "9", label: "VSC-Female" },
    { value: "10", label: "VSC-Male" },
    { value: "11", label: "Emergency Contraceptives" },
    { value: "12", label: "Fertility Awareness-Based (FAB)" },
    { value: "13", label: "General" },
  ]);
  const [bindServicesName, setBindServicesName] = useState([
    { value: "1", label: "FP General Counselling" },
    { value: "2", label: "FP-OP-COC-Consultation (Includes F/u)" },
    { value: "3", label: "FP-OP-Centchroman-Consultation (Includes F/u)" },
    { value: "4", label: "FP-OP-Femovan-Consultation (Includes F/u)" },
    { value: "5", label: "FP-OP-POP-Consultation (Includes F/u)" },
    { value: "6", label: "FP-Injectables(3m)-Consultation (Includes F/u)" },
    { value: "7", label: "FP-Condom(M)-Consultation (Includes F/u)" },
    { value: "8", label: "FP-Condom(F)-Consultation (Includes F/u)" },
    { value: "9", label: "HIV-Pre-test Counselling" },
    { value: "10", label: "HIV-Counselling-Post-test" },
    { value: "11", label: "HIV-Counselling-Risk reduction" },
    { value: "12", label: "HIV-Counselling-Psycho-social support" },
    { value: "13", label: "HIV-Counselling-PMTCT" },
    { value: "14", label: "HIV-Counselling-ART Adherence" },
    { value: "15", label: "HIV-Consultation" },
    { value: "16", label: "HIV-MGMT-OI (TB)" },
    { value: "17", label: "HIV-MGMT-OI (Malaria)" },
    { value: "18", label: "HIV-MGMT-OI (Other)" },
    {
      value: "19",
      label: "HIV-PREV- Post or Pre Exposure Prophylaxis (REP/PEP)",
    },
    { value: "20", label: "HIV-INV-Examination" },
    { value: "21", label: "HIV-INV-Lab-Rapid test" },
    { value: "22", label: "HIV-INV-Lab-Sampling procedure" },
    { value: "23", label: "HIV-Prev-ARVs-PEP" },
    // { value: "24", label: "FP-EC-Consultation (Includes F/u)" },
    // { value: "25", label: "FP-FAB-Fertility Awareness Based-Consultation" },
  ]);

  const [bindStatesDD, setBindStatesDD] = useState([
    { value: "1", label: "Andhra Pradesh" },
    { value: "2", label: "Arunachal Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Delhi" },
    { value: "7", label: "Goa" },
    { value: "8", label: "Gujarat" },
    { value: "9", label: "Haryana" },
    { value: "10", label: "Himachal Pradesh" },
    { value: "11", label: "Jammu & Kashmir" },
    { value: "12", label: "Jharkhand" },
    { value: "13", label: "Karnataka" },
    { value: "14", label: "Kerala" },
    { value: "15", label: "Madhya Pradesh" },
    { value: "16", label: "Maharashtra" },
    { value: "17", label: "Manipur" },
    { value: "18", label: "Meghalaya" },
    { value: "19", label: "Mizoram" },
    { value: "20", label: "Nagaland" },
    { value: "21", label: "Odisha" },
    { value: "22", label: "Punjab" },
    { value: "23", label: "Rajasthan" },
    { value: "24", label: "Tamil Nadu" },
    { value: "25", label: "Telangana" },
    { value: "26", label: "Tripura" },
    { value: "27", label: "Uttarakhand" },
    { value: "28", label: "Uttar Pradesh" },
    { value: "29", label: "West Bengal" },
  ]);
  const [bindLocationDD, setBindLocationDD] = useState([
    { value: "1", label: "Ahmednagar" },
    { value: "2", label: "Amravati" },
    { value: "3", label: "Aurangabad" },
    { value: "4", label: "Beed" },
    { value: "5", label: "Buldhana" },
    { value: "6", label: "Chandrapur" },
    { value: "7", label: "Dhule" },
    { value: "8", label: "Gondia" },
    { value: "9", label: "Hingoli" },
    { value: "10", label: "Jalgaon" },
    { value: "11", label: "Jalna" },
    { value: "12", label: "Kolhapur" },
    { value: "13", label: "Latur" },
    { value: "14", label: "Mumbai Suburban District" },
    { value: "15", label: "Mumbai" },
    { value: "16", label: "Nagpur" },
    { value: "17", label: "Nanded" },
    { value: "18", label: "Nashik" },
    { value: "19", label: "Osmanabad" },
    { value: "20", label: "Pune" },
    { value: "21", label: "Ratnagiri" },
    { value: "22", label: "Sindhudurg" },
    { value: "23", label: "Thane" },
  ]);
  const [bindAreaDD, setBindAreaDD] = useState([
    { value: "1", label: "Achalpur" },
    { value: "2", label: "Ambarnath" },
    { value: "3", label: "Amravati" },
    { value: "4", label: "Aurangabad" },
    { value: "5", label: "Barshi" },
    { value: "6", label: "Badlapur" },
    { value: "7", label: "Bhusawal" },
    { value: "8", label: "Buldhana" },
    { value: "9", label: "Dhule" },
    { value: "10", label: "Gondia" },
    { value: "11", label: "Hingoli" },
    { value: "12", label: "Hinganghat" },
    { value: "13", label: "Jalna" },
    { value: "14", label: "Kalyan-Dombivli" },
    { value: "15", label: "Malegaon" },
    { value: "16", label: "Nariman Point" },
    { value: "17", label: "Navi Mumbai" },
    { value: "18", label: "Osmanabad" },
    { value: "19", label: "PCMC, Pune" },
    { value: "20", label: "Panvel" },
    { value: "21", label: "Sangli" },
    { value: "22", label: "Solapur" },
    { value: "23", label: "Udgir" },
    { value: "24", label: "Vasai-Virar City MC" },
    { value: "25", label: "Washim" },
    { value: "26", label: "Wardha" },
    { value: "27", label: "Yavatmal" },
    { value: "28", label: "Yavatmal" },
  ]);
  const [bindSDPDD, setBindSDPDD] = useState([
    { value: "1", label: "Associated Clinics" },
    { value: "2", label: "CBPs" },
    { value: "3", label: "DHI" },
    { value: "4", label: "Outreach" },
    { value: "5", label: "Static Clinic" },
    { value: "6", label: "Satellite clinic" },
    { value: "7", label: "UFWC" },
  ]);
  const [bindReferralDD, setBindReferralDD] = useState([
    { value: "1", label: "FPAI" },
    { value: "2", label: "Static Clinic" },
    { value: "3", label: "Satellite Clinic" },
    { value: "4", label: "Pvt. Hospital" },
    { value: "4", label: "Govt. Hospital" },
  ]);
  const [serviceDatas, setServiceData] = useState({});
  const [trueButton, setTrueButton] = useState(true);
  const [servicesHistorypopUpOpen, setServicesHistorypopUpOpen] =
    useState(false);
  const HandleServices = (rowIndex) => {
    handleServicesOpen(true);
    setServiceData(bindServicesDatas[rowIndex]);
  };
  const handleServicesOpen = (isOpen) => {
    setServicespopUpOpen(isOpen);
  };

  const clientSplService = useLocation();
  const specialService = clientSplService?.state?.isAdd;

  useEffect(() => {
    if (specialService) {
      setAddScreenTitle(true);
      setVisibleAdd(true);
      setVisibleTable(false);
    }
  }, [specialService]);

  // const HandleReferral = (refferalValue) => {
  //   if(rowIndex.refferalValue == 1){
  //     setTrueButton(false);
  //   }
  //   else{
  //     setTrueButton(true);
  //   }

  //   // setReferralData(bindServicesDatas[rowIndex]);
  // };
  const handleReferralChange = (rowIndex, selectedValue) => {
    // Create a copy of tableData to avoid directly modifying state
    // const updatedTableData = [...bindServicesAddData];
    // Update the referral value for the specific row
    // updatedTableData[rowIndex].referral = selectedValue;

    // Update the state with the modified tableData
    setBindServicesAddData(rowIndex);
  };
  const HandleDelete = (rowIndex) => {
    const updatedTableData = bindServicesAddData.filter(
      (_, index) => index !== rowIndex
    );
    setBindServicesAddData(updatedTableData);
    // if(rowIndex.referral == 1){
    //  setTrueButton(isEnable);
    //}

    // setReferralData(bindServicesDatas[rowIndex]);
  };
  // const handleReferralOpen = (isOpen) => {
  //   setReferralpopUpOpen(isOpen);
  // };
  const HandleCommodities = (rowIndex) => {
    HandleCommoditiesOpen(true);
    setIssueOfCommoditiesPopupOpen(true);
    // setServiceData(bindServicesDatas[rowIndex]);
  };
  const HandleCommoditiesOpen = (isOpen) => {
    setCommoditiespopUpOpen(isOpen);
  };
  const HandleAppoinment = (rowIndex) => {
    HandleAppoinmentOpen(true);
    // setServiceData(bindServicesDatas[rowIndex]);
  };
  const HandleAppoinmentOpen = (isOpen) => {
    setAppoinmentpopUpOpen(isOpen);
  };
  const HandleServicesHistory = (rowIndex) => {
    handleServicesHistoryOpen(true);
    setServiceData(bindServicesDatas[rowIndex]);
  };
  const handleServicesHistoryOpen = (isOpen) => {
    setServicesHistorypopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    setAppoinmentpopUpOpen(false);
    setCommoditiespopUpOpen(false);
    setServicesHistorypopUpOpen(false);
  };
  const HandleGo = () => {
    setVisibleServicesAdd(true);
  };

  useEffect(() => {
    const sortedStates = [...bindStatesDD].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindStatesDD(sortedStates);
    const sortedLocation = [...bindLocationDD].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindLocationDD(sortedLocation);
    const sortedSDP = [...bindSDPDD].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindSDPDD(sortedSDP);
    const sortedArea = [...bindSDPDD].sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setBindAreaDD(sortedArea);
  }, []);
  const [bindservice, setBindservice] = useState([
    { value: "1", label: "Anti Bioties" },
    { value: "2", label: "Pain Killer " },
    { value: "3", label: "Contraceptive" },
  ]);
  const [bindserviceSub, setBindserviceSub] = useState([
    { value: "1", label: "Amoxicillin" },
    { value: "2", label: "Acyclofinac " },
    { value: "2", label: "Pills " },
  ]);
  const [bindserviceBrand, setBindserviceBrand] = useState([
    { value: "1", label: " Pfizer" },
    { value: "2", label: "MEDOPHARM " },
    { value: "2", label: "Levora " },
  ]);

  const [bindClientDD, setBindClientDD] = useState([
    { label: "Bhuvaneshwari (Anoushka)", value: "Bhuvaneshwari" },
    { label: "Bindu (Aishwarya)", value: "Bindu" },
    { label: "Chameli (Malini)", value: "Chameli" },
    { label: "Chandani (Priti)", value: "Chandani" },
    { label: "Damini (Avani)", value: "Damini" },
    { label: "Deepika (Rashmi)", value: "Deepika" },
    { label: "Deepti (Mira)", value: "Deepti" },
    { label: "Dev Dutt (Chandra)", value: "DevDutt" },
    { label: "Dharmender (Bushra)", value: "Dharmender" },
    { label: "Dharampal (Bhavana)", value: "Dharampal" },
    { label: "Damodara (Saraswati)", value: "Damodara" },
    { label: "Ganga (Dipa)", value: "Ganga" },
    { label: "Geeta (Asha)", value: "Geeta" },
    { label: "Geetanjali (Ushas)", value: "Geetanjali" },
    { label: "Gowri (Sumati)", value: "Gowri" },
    { label: "Harbhajan (Vaishnavi)", value: "Harbhajan" },
    { label: "Harcharan (Manpreet)", value: "Harcharan" },
    { label: "Harmeet (Manjusha)", value: "Harmeet" },
    { label: "Heena (Sandhya)", value: "Heena" },
    { label: "Hemlata (Radha)", value: "Hemlata" },
    { label: "Indira (Parvati)", value: "Indira" },
    { label: "Jagdish (Harshada)", value: "Jagdish" },
    { label: "Jamna (Gayathri)", value: "Jamna" },
    { label: "Jasmin  (Padmavati)", value: "Jasmin" },
    { label: "Jassi (Mitra)", value: "Jassi" },
    { label: "Jayalakshmii (Lakshmi)", value: "Jayalakshmii" },
    { label: "Kalpana (Kamakshi)", value: "Kalpana" },
    { label: "Kapila (Durga)", value: "Kapila" },
    { label: "Khushi (Chandra)", value: "Khushi" },
    { label: "Kanhaiya (Shakuntala)", value: "Kanhaiya" },
    { label: "Lakshmi (Rajkumari)", value: "Lakshmi" },
    { label: "Manikya  (Indumathi)", value: "Manikya" },
    { label: "Mayur (Chandrakanta)", value: "Mayur" },
    { label: "Shakti (Arundhati)", value: "Shakti" },
    { label: "Suraj (Ankita)", value: "Suraj" },
    { label: "Tej Bahadur (Aparna)", value: "TejBahadur" },
    { label: "Trilokanath (Amarjeet)", value: "Trilokanath" },
    { label: "Udai patel (Shanti)", value: "Udaipatel" },
    { label: "Upendra (Rupa sonam)", value: "Upendra" },
    { label: "Uttam kumar (Lochana)", value: "Uttamkumar" },
    { label: "Vasudev (Kiran)", value: "Vasudev" },
    { label: "Veer munna (Kalpana)", value: "Veermunna" },
    { label: "Vikram patel (Kajal)", value: "Vikrampatel" },
    { label: "Vijay (Jothi)", value: "Vijay" },
    { label: "Vikrant (Harpreet)", value: "Vikrant" },
    { label: "Vishwa (Divya)", value: "Vishwa" },
    { label: "Virendar (Deepika)", value: "Virendar" },
    { label: "Vivek (Chetana)", value: "Vivek" },
  ]);
  const [bindProject, setBindProject] = useState([
    { value: "1", label: "LAD" },
    { value: "2", label: "Metropolis" },
    { value: "3", label: "Migration" },
    { value: "4", label: "SPRINT IV" },
  ]);
  const [bindServices, setBindServices] = useState([
    {
      dateTime: "10/11/2023 10:30 AM",
      venue: "Ahmednagar",
      serviceTopic: "Gender Identity & Sexual Orientation",
      hcpName: "Dr. Karuna Boruah",
    },
    {
      dateTime: "06/11/2023 11:30 AM",
      venue: "Buldhana",
      serviceTopic: "Non-Communicable Disease (NCD)",
      hcpName: "Dr. V. Siva Nageswara Rao",
    },
    // {
    //     dateTime: "04/11/2023 9:30 AM",
    //     venue: "Chandrapur",
    //     serviceTopic: "Gender Based Violence / Reproductive Coercion",
    //     hcpName: "Dr. Syed Ahamed",
    // },
    // {
    //     dateTime: "03/11/2023 12:30 AM",
    //     venue: "Dhule",
    //     serviceTopic: "Sexual Reproductive Health & Rights (SRHR)",
    //     hcpName: "Dr. Randhir Singh",
    // },
  ]);
  const handleServicesCategory = (updatedOptions) => {
    // setServiceCategory(updatedOptions);
  };
  const handleServicesSubCategory = (updatedOptions) => {
    // setServiceSubCategory(updatedOptions);
  };
  const handleServices = (updatedOptions) => {
    // setServicesName(updatedOptions);
  };

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  // const [visibleClientData, setVisibleClientData] = useState(false);
  const [visibleServicesAdd, setVisibleServicesAdd] = useState(false);
  const [visibleCounselling, setVisibleCounselling] = useState(false);
  const [visibleSupplyCommodity, setVisibleSupplyCommodity] = useState(false);
  const [visibleDrAppoinment, setVisibleDrAppoinment] = useState(false);

  const [updateButton, setUpdateButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const [editMode, setEditMode] = useState([]);

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [servicesProvideScreenTitle, setServicesProvideScreenTitle] =
    useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [servicespopUpOpen, setServicespopUpOpen] = useState(false);
  const [commoditiespopUpOpen, setCommoditiespopUpOpen] = useState(false);
  const [appoinmentpopUpOpen, setAppoinmentpopUpOpen] = useState(false);
  const [referralpopUpOpen, setReferralpopUpOpen] = useState(false);
  const [visiblePlus, setVisiblePlus] = useState(false);
  // const [serviceCategory, setServiceCategory] = useState(["1"] );
  // const serviceCategory = ["1", "3"];
  const serviceSubCategory = ["1", "13"];
  const servicesName = ["1", "2", "21", "23"];
  // const [serviceSubCategory, setServiceSubCategory] = useState("");
  // const [servicesName, setServicesName] = useState("");
  const [dateSupply, setDateSupply] = useState("");
  const [serviceSelect, setserviceSelect] = useState("");
  const [counselling, setCounselling] = useState("");
  const [project, setProject] = useState("");
  const [serviceCategory, setServiceCategory] = useState();
  const [clientName, setClientName] = useState({
    value: 1,
    label: "Dharmender Kumar",
  });
  const [healthcareprovider, setHealthcareprovider] = useState("");
  const [appoinmentDate, setAppoinmentDate] = useState("");

  const [state, setState] = useState(bindStatesDD[15]);
  const [location, setLocation] = useState(bindLocationDD[14]);
  const [area, setArea] = useState(bindAreaDD[15]);
  const [sdp, setSdp] = useState(bindSDPDD[5]);

  const location1 = useLocation();
  const serviceAdd = location1?.state?.isAdd;
  const serviceSession = location1?.state?.isSession;

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setCurrentPage(1);
    setResult(10);

    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };
  const HandleAddress = (rowIndex) => {};
  const handleEdit = async (items) => {
    setEditMode(true);
    const data = [{}];
    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
  };
  const HandleProvideService = async (items) => {
    setEditMode(true);
    const data = [{}];
    setSubmitButton(false);
    setVisibleAdd(true);
    setVisibleTable(false);
    setServicesProvideScreenTitle(true);
  };
  function handleDropDown(e) {}

  const handleSubmit = async (items, flag) => {
    cancel();
  };

  const HandleUserDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 2) {
      setViewDetails(true);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 4) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    setCurrentData(bindServicesDatas[rowIndex]);
  };

  const handleDropdownChange = (e) => {
    // setVisibleClientData(true);
    setClientName();
  };

  const handleRadioChange = (e) => {
    setserviceSelect(e.target.value);
    handleRadioChangeValue(e.target.value);
  };
  const handleRadioChangeValue = (selectedValue) => {
    setVisibleCounselling("");
    setVisibleSupplyCommodity("");
    setVisibleDrAppoinment("");

    if (selectedValue === "1") {
      setVisibleCounselling(true);
      setVisibleSupplyCommodity(false);
      setVisibleDrAppoinment(false);
    } else if (selectedValue === "2") {
      setVisibleCounselling(false);
      setVisibleSupplyCommodity(true);
      setVisibleDrAppoinment(false);
    } else if (selectedValue === "3") {
      setVisibleCounselling(false);
      setVisibleSupplyCommodity(false);
      setVisibleDrAppoinment(true);
    } else {
      setVisibleCounselling(false);
      setVisibleSupplyCommodity(false);
      setVisibleDrAppoinment(false);
    }
  };
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;

      if (columnName === "productName") {
        // Logic to get description and uom based on itemCode
        let selectedProductCode = "";
        let selectedUoM = "";

        if (value === "Amoxicillin") {
          selectedProductCode = "PRO1001";
          selectedUoM = "Numbers";
        } else if (value === "White wine PRO1002") {
          selectedProductCode = "PRO1002";
          selectedUoM = "bottle";
        } else if (value === "White wine PRO1003") {
          selectedProductCode = "PRO1003";
          selectedUoM = "bottle";
        } else if (value === "White wine PRO1004") {
          selectedProductCode = "PRO1004";
          selectedUoM = "bottle";
        }

        newData[rowIndex]["productCode"] = selectedProductCode;
        newData[rowIndex]["uom"] = selectedUoM;
        newData[rowIndex].quantity = rowIndex.quantity;
      } else if (columnName === "quantity" || columnName === "rate") {
        newData[rowIndex][columnName] = value; // Update the value directly
        const quantity = parseInt(newData[rowIndex].quantity);
      }

      return newData;
    });
  };
  const [tableData, setTableData] = useState([
    {
      productName: "",
      productCode: "",
      quantity: "0",
      uom: "",
    },
  ]);
  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.productName = ""; // Clear the values of the new row
    newRow.productCode = "";
    newRow.quantity = "0";
    newRow.uom = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  const Online = () => {};
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setAppoinmentDate(formattedDateTime);
    setDateSupply(formattedDateTime);
  }, []);
  useEffect(() => {
    if (serviceAdd) {
      setVisibleAdd(true);
      setVisibleTable(false);
      setVisiblePlus(serviceAdd);
      setAddScreenTitle(true);
    }
    // if(serviceSession)
    // {
    //   setServiceCategory("");
    //   setServiceCategory(bindSPSessionCategory[1])
    // }
  }, [serviceAdd, serviceSession]);

  //Search
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindServicesDatas);

  const handleSearch = (input) => {
    const filtered = bindServicesDatas.filter((item) => {
      const clientData = `${item.clientId} ${item.clientName} ${item.clientMotherName} ${item.clientGender} ${item.clientPhone}`;
      return clientData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };
  // Function to handle the input change
  const handleInputChangeSearch = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div
              className={mainTable.gridTableHeader}
            >{`Spl. Service Session`}</div>
          </div>

          <div className=" md:hidden relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  value={searchInput}
                  onChange={handleInputChangeSearch}
                />
              </div>

          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20" selected>
                    20
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="hidden md:block relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  value={searchInput}
                  onChange={handleInputChangeSearch}
                />
              </div>
            </div>
            {/* <div className="flex self-end">
              <Link
                to={"/clientmaster"}
                state={{
                  // ...data,
                  isAdd: true,
                  isvisible3: false,
                }}
                className={mainTable.addClientButton}
                // onClick={() => onServiceProvideGrid(rowIndex)}
                // class="block px-4 py-2 hover:bg-gray-100 "
              >
                Add Client
              </Link>
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
            </div> */}
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>

              <Link
                to={"/clientmaster"}
                state={{
                  // ...data,
                  isAddSplService: true,
                  isvisible3: false,
                }}
                className={mainTable.addButton}
                // onClick={() => onServiceProvideGrid(rowIndex)}
                // class="block px-4 py-2 hover:bg-gray-100 "
              >
                Add Client
              </Link>
              {/* <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div> */}
              {/* <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button> */}
            </div>
          </div>
          {/* <div className="h-40"> */}
          <SpecialServiceSessionTable
            className={mainTable.splSessionTable}
            headerData={spServiceSessionMasterHeaders}
            tableData={bindServices}
          />
          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div className={mainTable.headerTitle}>
            <div className={mainTable.gridTableHeader}>{`Client Table`}</div>
          </div>

          <TableGrid
            className={mainTable.table}
            headerData={SpecialServiceSessionHeader}
            tableData={searchData ? searchData : bindServicesDatas}
            onEditGrid={handleEdit}
            onDeleteGrid={HandleUserDetails}
            onBlockGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
            onAddressGrid={HandleAddress}
            // onServiceProvideGrid={HandleProvideService}
          />

          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Spl. Service Session - Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Spl. Service Session - Edit"}
              </div>
            )}

            {servicesProvideScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Service Provided - View"}
              </div>
            )}

            {viewScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Spl. Service Session - View"}
              </div>
            )}

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid4}>
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title="State"
                      options={bindStatesDD}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      controlFunc={(value) => {
                        setState(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title="Location"
                      options={bindLocationDD}
                      placeholder="Select"
                      name="state"
                      selectedOption={location}
                      controlFunc={(value) => {
                        setLocation(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title="S.D.P."
                      options={bindSDPDD}
                      placeholder="Select"
                      name="state"
                      selectedOption={sdp}
                      controlFunc={(value) => {
                        setSdp(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title="Area"
                      options={bindAreaDD}
                      placeholder="Select"
                      name="state"
                      selectedOption={area}
                      controlFunc={(value) => {
                        setArea(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                  </div>
                  <div className={mainTable.grid4}>
                    <div className="flex items-center">
                      <label className={mainTable.label}>
                        Client Name : &nbsp;
                      </label>
                      <label className={mainTable.label}>
                        Dharmender Kumar
                      </label>
                    </div>
                    <div className="flex items-center">
                      <label className={mainTable.label}>
                        Mother Name : &nbsp;
                      </label>
                      <label className={mainTable.label}> Bushra Patiya </label>
                    </div>
                    <div className="flex items-center">
                      <label className={mainTable.label}>
                        Phone Number : &nbsp;
                      </label>
                      <label className={mainTable.label}> 8963254853 </label>
                    </div>
                    <DropDown
                      classStar="text-red-500"
                      title="Project"
                      classDiv="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
                      options={bindProject}
                      placeholder="Select"
                      name="project"
                      selectedOptions={project}
                      controlFunc={(e) => setProject(e.target.value)}
                    />
                  </div>
                  <div className={mainTable.grid4}>
                    <DropDown
                      classStar="text-red-500"
                      title="Service Category"
                      options={
                        serviceSession
                          ? bindSPSessionCategory
                          : bindServiceCategory
                      }
                      placeholder={"Select"}
                      defaultValue={serviceSession ? "SP. Session" : "Select"}
                      name="serviceCategory"
                      class="custom-class"
                      tabIndex="1"
                      selectedOptions={serviceCategory}
                      controlFunc={(e) => setServiceCategory(e.target.value)}
                    />
                    <MultiSelect
                      classStar="text-red-500"
                      label="Service Sub-Category"
                      filteredOptions={
                        serviceSession
                          ? bindSPSessionSubCategory
                          : bindServiceSubCategory
                      }
                      classFilterLabel="p-1 flex gap-1"
                      placeholder="Select"
                      name="serviceSubCategory"
                      class="custom-class"
                      disabled="false"
                      tabIndex="1"
                      selectedOption={serviceSubCategory}
                      onChange={handleServicesSubCategory}
                    />

                    <MultiSelect
                      classStar="text-red-500"
                      label="Services"
                      filteredOptions={bindServicesName}
                      classFilterLabel="p-1 flex gap-1"
                      placeholder="Select"
                      name="servicesName"
                      class="custom-class"
                      disabled="false"
                      tabIndex="1"
                      selectedOption={servicesName}
                      onChange={handleServices}
                    />
                    <div className={mainTable.grid3}>
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={mainTable.goButton}
                        // class="mt-10"
                        onClick={() => HandleGo()}
                      >
                        GO
                      </button>
                    </div>
                  </div>

                  {visibleServicesAdd && (
                    <div>
                      <div>
                        <hr class={mainTable.servicesHorizontalLine}></hr>
                        <TableGrid
                          className={mainTable.table}
                          headerData={ServicesAddHeader}
                          tableData={bindServicesAddData}
                          onServicesGrid={HandleServices}
                          // onReferralGrid={HandleReferral}
                          onDeleteGrid={HandleDelete}
                          onReferralGrid={handleReferralChange}
                          bindReferralDD={bindReferralDD}
                        />
                        <TextArea
                          inputType="text"
                          title="Remarks"
                          name="specificHistory"
                          rows="4"
                          value={counselling}
                          placeholder="Typing"
                          onChange={(e) => setCounselling(e.target.value)}
                        />
                        <div className="mb-5"></div>
                        <div className={mainTable.grid2}>
                          <input
                            type="button"
                            className={mainTable.submitButton}
                            value="Issue of Commodities"
                            onClick={HandleCommodities}
                          />
                          <input
                            type="button"
                            className={mainTable.submitButton}
                            value="Doctor Appoinment"
                            onClick={HandleAppoinment}
                            disabled={
                              !bindServicesAddData.some((i) => i.referral == 1)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <hr class={mainTable.servicesHorizontalLine}></hr>
                        <div className={mainTable.addScreenTitle}>
                          {"History"}
                        </div>
                        <TableGrid
                          className={mainTable.table}
                          headerData={ServicesHeadersData}
                          tableData={bindServicesData}
                          onServicesGrid={HandleServicesHistory}
                        />
                      </div>
                    </div>
                  )}

                  {/* <div className="py-4">
                    <CheckboxOrRadioGroup
                      title="Services Type"
                      type="radio"
                      setName="education"
                      selectedOptions={serviceSelect}
                      options={servicesRadio}
                      controlFunc={handleRadioChange}
                    />
                  </div>
                  {visibleCounselling && (
                    <div>
                      <TextArea
                        inputType="text"
                        title="Remarks"
                        name="specificHistory"
                        rows="4"
                        value={counselling}
                        placeholder="Typing"
                        onChange={(e) => setCounselling(e.target.value)}
                      />
                    </div>
                  )}
                  {visibleSupplyCommodity && (
                    <div>
                      <div className="flex self-end">
                        <button
                          type="button"
                          onClick={handleAddRow}
                          class={mainTable.addButton}
                        >
                          <svg
                            width="25"
                            height="20"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                              fill="white"
                            />
                          </svg>
                          ADD
                        </button>
                      </div>
                      <div class={mainTable.grid3}>
                        <InputText
                          inputType="datetime-local"
                          title="Date"
                          name="datetime"
                          content={dateSupply}
                          controlFunc={(e) => setDateSupply(e.target.value)}
                        />
                      </div>
                      <table className="w-full border border-gray-300 mt-6">
                        <thead>
                          <tr>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                              Commodity Type
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                              Commodity Name
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                              Brand
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                              Quantity
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                              U.O.M.
                            </th>
                            <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 p-2 w-56">
                                <Select
                                  options={bindservice}
                                  value={bindClientDD.find(
                                    (option) => option.value === row.productName
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(
                                      selectedOption,
                                      index,
                                      "productName"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-300 p-2 w-32">
                                <Select
                                  options={bindserviceSub}
                                  value={bindClientDD.find(
                                    (option) => option.value === row.productName
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(
                                      selectedOption,
                                      index,
                                      "productName"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-300 p-2 w-28">
                                <Select
                                  options={bindserviceBrand}
                                  value={bindClientDD.find(
                                    (option) => option.value === row.productName
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(
                                      selectedOption,
                                      index,
                                      "productName"
                                    )
                                  }
                                />
                              </td>
                              <td className="border border-gray-300 p-2 w-28">
                                <input
                                  type="text"
                                  placeholder="P.O. Qty."
                                  className={mainTable.input}
                                  value={row.quantity}
                                  onChange={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "quantity"
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                                <input
                                  type="text"
                                  placeholder=""
                                  className={mainTable.input}
                                  value={row.uom}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.value,
                                      index,
                                      "uom"
                                    )
                                  }
                                  readOnly
                                />
                              </td>
                              {/* <td className="border border-gray-300 p-2 w-24">
                              <input
                                type="text"
                                placeholder="Rate"
                                className={Maintable.input}
                                value={row.rate}
                                onChange={(e) =>
                                  handleInputChange(e.target, index, "rate")
                                }
                              />
                            </td> 
                              {/* <td className="border border-gray-300 p-2 w-40">
                              <input
                                type="text"
                                placeholder="Amount"
                                className={Maintable.input}
                                value={row.amount}
                              />
                            </td> 
                              <td className="border border-gray-300 p-2 w-12">
                                <MdCancel
                                  class="mx-auto"
                                  onClick={() => handleDeleteRow(index)}
                                />
                              </td>

                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {visibleDrAppoinment && (
                    <div className={mainTable.grid3}>
                      <InputText
                        inputType="datetime-local"
                        title="Date"
                        name="date"
                        content={appoinmentDate}
                        controlFunc={(e) => setAppoinmentDate(e.target.value)}
                      />
                      <DropDown
                        title={"Healhcare Provider Name"}
                        options={bindHcpName}
                        placeholder={"Select"}
                        name={"healthcareprovider"}
                        selectedOption={healthcareprovider}
                        controlFunc={(e) =>
                          setHealthcareprovider(e.target.value)
                        }
                      />
                      <div className="mt-7 mr-7">
                        <button
                          type="button"
                          className={mainTable.submitButton}
                          onClick={() => {
                            window.open(
                              "https://us05web.zoom.us/j/88385623106?pwd=SqFC5hOanYmAbwi9CVmgLQ8anAgFdb.1",
                              "_blank"
                            );
                          }}
                        >
                          Online Consulting
                        </button>
                      </div>

                      <div class="lg:col-span-3 sm:col-span-1">
                        <TextArea
                          inputType="text"
                          title="Your Meeting Link"
                          name="Link"
                          rows="4"
                          placeholder="Meeting Link"
                          content="https://us05web.zoom.us/j/88385623106?pwd=SqFC5hOanYmAbwi9CVmgLQ8anAgFdb.1"
                        />
                      </div>
                    </div>
                  )} */}
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}

                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>

      {/* <MainPopup
        open={viewDetails}
        closeOnDocumentClick={false}
        onClose={() => setViewDetails(false)}
      >
        <div className="modal-reset">
          <button className="close" onClick={() => setViewDetails(false)}>
            &times;
          </button>
          <div className="modal-content">
            <UserDetails data={currentData} />
          </div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div>
      </MainPopup> */}
      <MainPopup
        popUpOpen={servicesHistorypopUpOpen}
        setpopUpOpen={setServicesHistorypopUpOpen}
        Open={() => HandleServicesHistory}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <AddServices data={serviceDatas} />
        </div>

        {/* <div className="modal-reset">
          <button className="close" onClick={() => setServicespopUpOpen(!servicespopUpOpen)}>
            &times;
          </button>
          <div className="modal-content">
            <AddServices  />
          </div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.submitButton}
            onClick={() => handleSubmit(rowIndex, 3)}
          >
            Submit
          </button>

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div> */}
      </MainPopup>
      <MainPopup
        popUpOpen={servicespopUpOpen}
        setpopUpOpen={setServicespopUpOpen}
        Open={() => HandleServices}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <AddServices data={serviceDatas} />
        </div>

        {/* <div className="modal-reset">
          <button className="close" onClick={() => setServicespopUpOpen(!servicespopUpOpen)}>
            &times;
          </button>
          <div className="modal-content">
            <AddServices  />
          </div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.submitButton}
            onClick={() => handleSubmit(rowIndex, 3)}
          >
            Submit
          </button>

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div> */}
      </MainPopup>
      <MainPopup
        popUpOpen={commoditiespopUpOpen}
        setpopUpOpen={setCommoditiespopUpOpen}
        Open={() => HandleCommodities}
        issueOfCommoditiesPopup={HandleCommodities}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <IssueOfCommodity data={serviceDatas} />
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.submitButton}
            onClick={() => handleSubmit(rowIndex, 3)}
          >
            Submit
          </button>
        </div>
      </MainPopup>
      <MainPopup
        popUpOpen={appoinmentpopUpOpen}
        setpopUpOpen={setAppoinmentpopUpOpen}
        Open={() => HandleAppoinment}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <DoctorAppoinment data={serviceDatas} />
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.submitButton}
            onClick={() => handleSubmit(rowIndex, 3)}
          >
            Submit
          </button>
        </div>
      </MainPopup>
      <MainPopup
        popUpOpen={referralpopUpOpen}
        setpopUpOpen={setReferralpopUpOpen}
        Open={() => HandleAppoinment}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <Referral data={serviceDatas} />
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.submitButton}
            onClick={() => handleSubmit(rowIndex, 3)}
          >
            Submit
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
}

export default SpecialServiceSession;
