import React from 'react'
import { mainTable } from '../css/Common'

const PopUpButtons = ({deleteDetails,blockDetails,unBlockDetails,disabledBtn,rowIndex,handleSubmit,cancel}) => {
  return (
    <div className={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={disabledBtn ? mainTable.disabledSubmitButton : mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
              disabled={disabledBtn}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={disabledBtn ? mainTable.disabledSubmitButton : mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
              disabled={disabledBtn}
            >
              Block
            </button>
          )}
          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={disabledBtn ? mainTable.disabledSubmitButton : mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
              disabled={disabledBtn}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => cancel()}
          >
            CANCEL
          </button>
        </div>
  )
}

export default PopUpButtons