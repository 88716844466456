import React, { useState } from "react";
import axios from "axios";
import { BindAPI } from "../services/APIConnection";

const CreateZoomMeeting = () => {
  const [token, setToken] = useState("");
  const [meeting, setMeeting] = useState({
    topic: "Test",
    start_time: "",
    duration: 60,
    timezone: "UTC",
    agenda: "",
    settings: {
      host_video: true,
      participant_video: true,
      join_before_host: true,
      mute_upon_entry: false,
      watermark: false,
      approval_type: 2,
      registration_type: 1,
      audio: "both",
    },
    // Include emails of attendees here
    attendees: [
      { email: "karthickraja240901@gmail.com" },
      { email: "karthickraja.xlicon@gmail.com" },
    ],
  });

  // const JWT_TOKEN =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJaT0RvSVNsY1JMT2dmOGxHVW9LaHhBIiwiZXhwIjoxNzExOTQyNDMxMTU2LCJpYXQiOjE3MTE5Mzg4MzF9.2igQnA_l4jFIiT6DoSRcfYRm8CqIjbu7tm58kBSLyAk";

  const createMeeting = async () => {
    const tokenResponse = BindAPI("ZoomJwtToken");
    tokenResponse.then((response) => {
      const tokenData = response.data;
      setToken(tokenData.token);
    });
    console.log("tokenResponse", tokenResponse);
    if (token) {
      try {
        const response = await axios.post(
          "https://api.zoom.us/v2/users/me/meetings",
          meeting,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Meeting created:", response.data);

        // Update meeting settings to assign a different user as host
        await updateMeetingSettings(response.data.id);
        // Handle success: show confirmation message or redirect to meeting URL
      } catch (error) {
        console.error("Error creating meeting:", error);
        // Handle error: display error message to the user
      }
    }
  };

  const updateMeetingSettings = async (meetingId) => {
    const tokenResponse = BindAPI("ZoomJwtToken");
    console.log("tokenResponse", tokenResponse);
    try {
      // Make a PATCH request to update the meeting settings
      await axios.patch(
        `https://api.zoom.us/v2/meetings/${meetingId}`,
        {
          settings: {
            participant_video: true,
            host_video: true,
            approval_type: 2,
            registrants_email_notification: true,
            registration_type: 1,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.data.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Meeting settings updated");
    } catch (error) {
      console.error("Error updating meeting settings:", error);
    }
  };

  const handleChange = (e) => {
    setMeeting({ ...meeting, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <label>Topic: </label>
      <input
        type="text"
        name="topic"
        value={meeting.topic}
        onChange={handleChange}
      />
      {/* Other input fields for meeting details */}
      <button onClick={createMeeting}>Create Meeting</button>
    </div>
  );
};

export default CreateZoomMeeting;
