// Code review - Completed
// Documentation - Completed
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";

import { youthGroupMasterHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import YouthPopup from "../popup-containers/YouthPopup";
import toast from "react-hot-toast";
import RadioGroup from "../ReusableComponents/RadioButton";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function YouthGroup() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  //All bind dropdown value and bind data value useState hooks handling.
  const [youthData, setYouthData] = useState("");
  const [bindCountry, setBindCountry] = useState([]);
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindLocationDropDown2, setBindLocationDropDown2] = useState([]);
  const [bindYouthMasterDatas, setBindYouthMasterDatas] = useState([]);
  const [getLocationDatas1, setGetLocationDatas1] = useState([]);
  const [groupRadio, setGroupRadio] = useState([]);
  const createdBy = sessionStorage.getItem("createdBy");

  //All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleAdd1, setVisibleAdd1] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // Youth Group Master insert and edit useState hooks handling .
  const [mstYouthGroupId, setMstYouthGroupId] = useState("");

  const [youthFields, setYouthFields] = useState({
    youthGroupId: "",
    youthGroupName: "",
    date: new Date(),
    youthCountry: "",
    youthState: "",
    youthLocation: "",
    youthLocationCode: "",
    youthMstOrganizationId: "",
    youthVenue: "",
    youthVolunteerName: "",
    youthMobileNumber: "",
    youthEmailId: "",
    youthRemarks: "",
    youthTypeOfGroup: "",
  });

  const [youthFieldsError, setYouthFieldsError] = useState({
    youthGroupIdError: "",
    youthGroupNameError: "",
    youthTypeOfGroupError: "",
    youthStateError: "",
    youthLocationError: "",
    youthVenueError: "",
    youthVolunteerNameError: "",
    youthMobileNumberError: "",
    youthEmailIdError: "",
    youthTypeOfGroupError: "",
  });

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [popUpDropDownData1, setPopUpDropDownData1] = useState({});
  const [youthData1, setYouthData1] = useState({});
  const [editMode, setEditMode] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const handleGenderChange = (selectedOptions) => {
    setYouthFields({
      ...youthFields,
      youthTypeOfGroup: selectedOptions,
    });
  };

  const bindgroup = async (resultdata) => {
    try {
      if (resultdata.length > 0) {
        const genderDD = resultdata.map((m) => ({
          value: m.MstTypeOfGroupId,
          label: m.TypeOfGroupName,
        }));
        setGroupRadio(genderDD);
      } else {
        setGroupRadio([]);
      }
    } catch (error) {
      console.error("Error fetching gender data:", error);
    }
  };

  //This is Youth Group Master grid data bind functionality and Youth Group Master dropdown value bind functionality .
  useEffect(() => {
    const youthDropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        setBindStateDropDown(statesData);
        setBindLocationDropDown(locationData);
      } catch (error) {}
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas1(locationResponse.data.Table);
    };

    getSelectedLocation();
    bindYouthData(userProfileDatas);
    youthDropDownDatas();
  }, []);
  const GenerateId = () => {
    if (addScreenTitle) {
      if (youthFields.youthLocation) {
        const filteredBranchProject = getLocationDatas1
          .filter((item) => item.MstLocationId === youthFields.youthLocation)
          .map((item) => item.LocationCode);
        if (filteredBranchProject.length > 0) {
          setYouthFields({
            ...youthFields,
            youthLocationCode: filteredBranchProject[0],
          });
        }
      }
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      GenerateId();
    }
  }, [addScreenTitle, youthFields.youthLocation]);
  useEffect(() => {
    if (addScreenTitle) {
      if (youthFields.youthLocationCode) {
        const outputString = `${youthFields.youthLocationCode}/${"YTG"}`;
        setYouthFields({
          ...youthFields,
          youthGroupId: outputString,
        });
      }
    }
  }, [addScreenTitle, youthFields.youthLocationCode]);
  const bindYouthData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
      };
      const response = await BindPostAPI(
        [data],
        "YouthGroupAPI/BindYouthGroup"
      );
      setBindYouthMasterDatas(response.data.Table);
      bindgroup(response.data.Table1);
    }
  };

  // this function is bind the dropdown value for location what we select in state dropdown that selected related only show the location dropdown .
  useEffect(() => {
    const stateSelectedValue = getLocationDatas1.find(
      (item) => item.MstStateId == youthFields.youthState
    );
    console.log("stateSelectedValue", stateSelectedValue);
    if (stateSelectedValue) {
      const locationLabels = getLocationDatas1
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown2(locationSelected);
    } else {
    }
  }, [youthFields.youthState]);

  // This is Youth Group grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd1(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setYouthFields({
      ...youthFields,
      date: formattedDate,
      youthState: userProfileDatas[0].MstStateId,
      youthLocation: userProfileDatas[0].MstLocationId,
      youthMstOrganizationId: userProfileDatas[0].MstOrganizationId,
    });
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleAdd1(false);
    setVisibleTable(true);
    handleOpen(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    bindYouthData(userProfileDatas);
    GenerateId();
    setYouthFields({
      date: new Date(),
      youthGroupId: "",
      youthGroupName: "",
      youthTypeOfGroup: "",
      youthCountry: "",
      youthState: "",
      youthLocation: "",
      youthVenue: "",
      youthVolunteerName: "",
      youthMobileNumber: "",
      youthEmailId: "",
      youthRemarks: "",
    });

    setYouthFieldsError({
      youthGroupIdError: "",
      youthGroupNameError: "",
      youthTypeOfGroupError: "",
      youthStateError: "",
      youthLocationError: "",
      youthVenueError: "",
      youthVolunteerNameError: "",
      youthMobileNumberError: "",
      youthEmailIdError: "",
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setYouthFields({ ...youthFields, date: formattedDate });
    };

    fetchData();
  }, []);
  // this function is helped to edit the input value via rowindex passing and helping to visible handling for youth group.
  const handleEdit = (rowIndex) => {
    setEditMode(true);
    //setSelectedRowIndex1(rowIndex);
    setVisibleAdd1(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    var Date = bindYouthMasterDatas[rowIndex].Date;
    const formattedDate = Date.split("T")[0];
    setYouthFields({
      ...youthFields,
      youthGroupId: bindYouthMasterDatas[rowIndex].YouthGroupId,
      date: formattedDate,
      youthGroupName: bindYouthMasterDatas[rowIndex].YouthGroupName,
      youthState: bindYouthMasterDatas[rowIndex].MstStateId,
      youthLocation: bindYouthMasterDatas[rowIndex].MstBranchProjectId,
      youthVenue: bindYouthMasterDatas[rowIndex].YouthGroupVenue,
      youthVolunteerName: bindYouthMasterDatas[rowIndex].YouthVolunteerName,
      youthMobileNumber: bindYouthMasterDatas[rowIndex].YouthGroupPhoneNumber,
      youthEmailId: bindYouthMasterDatas[rowIndex].YouthGroupEmail,
      youthRemarks: bindYouthMasterDatas[rowIndex].YouthGroupRemarks,
      youthTypeOfGroup: bindYouthMasterDatas[rowIndex].MstTypeOfGroupId,
    });

    setMstYouthGroupId(bindYouthMasterDatas[rowIndex].MstYouthGroupId);

    setYouthData(bindYouthMasterDatas[rowIndex]);
  };

  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const handleDropDown = (event) => {
    // To set Number of records per page
    const selectedValue = parseInt(event.target.value); // Convert to integer
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindYouthMasterDatas);

  const handleSearch = (input) => {
    const filtered = bindYouthMasterDatas.filter((item) => {
      const providerData = `${item.YouthGroupId}${item.YouthGroupName}${item.YouthGroupContactPerson}${item.YouthGroupPhoneNumber}${item.YouthGroupEmail}`;
      return providerData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // this function is helped to the viwe, delete, block and unblock input value via rowindex passing for youth group.
  const handleYouthDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setYouthData(bindYouthMasterDatas[rowIndex]);

    const PopupGender = bindYouthMasterDatas[rowIndex].MstTypeOfGroupId
      ? bindYouthMasterDatas[rowIndex].MstTypeOfGroupId
      : "";

    const YouthData = bindYouthMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === YouthData.MstStateId
    );
    const editStateDropDownValue = {
      value: YouthData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };

    //State DropDown Value End

    //Location DropDown Value Start
    const locationDropDownValue = bindLocationDropDown.find(
      (item) => item.value === YouthData.MstBranchProjectId
    );
    const editLocationDropDownValue = {
      value: YouthData.MstLocationId,
      label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
    };

    //Location DropDown Value End
    const youthMaster = [PopupGender];
    setYouthData1(youthMaster);
    var Date = bindYouthMasterDatas[rowIndex].Date;
    const formattedDate = Date.split("T")[0];
    const autoDropDownValues = [
      groupRadio,
      editStateDropDownValue,
      editLocationDropDownValue,
      formattedDate,
    ];
    setPopUpDropDownData1(autoDropDownValues);
    //Area DropDown Value End
  };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the Youth group Id input
      // if (!youthFields.youthGroupId) {
      //   errors.youthGroupId = "Enter YouthGroup ID";
      // } else if (!/^[A-Za-z0-9 -]+$/.test(youthFields.youthGroupId)) {
      //   errors.youthGroupId = "Only alphabet, Hyphen and Numbers allowed";
      // }

      // Validation logic for the Youth group Name input
      if (!youthFields.youthGroupName) {
        errors.youthGroupName = "Enter Youth Group Name";
      } else if (!/^[A-Za-z ]+$/.test(youthFields.youthGroupName)) {
        errors.youthGroupName = "Only alphabet and space allowed";
      }

      // Validation logic for the Youth group State input
      if (youthFields.youthState == "") {
        errors.youthState = "Select state";
      }

      // Validation logic for the Youth group Location input
      if (youthFields.youthLocation == "") {
        errors.youthLocation = "Select Branch/Project";
      }

      // Validation logic for the Youth group Venue input
      if (youthFields.youthVenue == "") {
        errors.youthVenue = "Enter Youth Venue";
      }

      // Validation logic for the Youth group Volunteer input
      if (!youthFields.youthVolunteerName) {
        errors.youthVolunteerName = "Enter Youth Volunteer Name";
      } else if (!/^[A-Za-z ]+$/.test(youthFields.youthVolunteerName)) {
        errors.youthVolunteerName = "Only alphabet and space allowed";
      }

      // Validation logic for the phone input
      if (!youthFields.youthMobileNumber.trim()) {
        errors.youthMobileNumber = "Enter Mobile Number";
      } else if (!/^[\d\s]+$/.test(youthFields.youthMobileNumber)) {
        errors.youthMobileNumber = "Enter a valid mobile number";
      }

      // // Validation logic for the email input
      // if (!youthFields.youthEmailId.trim()) {
      //   errors.youthEmailId = "Enter email";
      // } else if (
      //   !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
      //     youthFields.youthEmailId
      //   )
      // ) {
      //   errors.youthEmailId = "Enter a valid email address";
      // }
      if (!youthFields.youthTypeOfGroup) {
        errors.youthTypeOfGroup = "Choose Type Of Group";
      }
    }

    setYouthFieldsError({
      ...youthFieldsError,
      // youthGroupIdError: errors.youthGroupId || "",
      youthGroupNameError: errors.youthGroupName || "",
      youthStateError: errors.youthState || "",
      youthLocationError: errors.youthLocation || "",
      youthVenueError: errors.youthVenue || "",
      youthVolunteerNameError: errors.youthVolunteerName || "",
      youthMobileNumberError: errors.youthMobileNumber || "",
      youthEmailIdError: errors.youthEmailId || "",
      youthTypeOfGroupError: errors.youthTypeOfGroup || "",
    });

    return errors; // Return the errors object containing validation errors (if any)
  };

  // this function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API response for those all and passing the rowindex for youth group.
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      const firstNameValidation = validate(flag);

      if (Object.keys(firstNameValidation).length !== 0) {
        setIsOpen(true);
      }
      console.log(
        "youthTypeOfGroupError",
        youthFieldsError.youthTypeOfGroupError
      );
      if (Object.keys(firstNameValidation).length === 0) {
        setIsOpen(false);
        setSubmitBtnDisabled(true);
        const data = {
          mstYouthGroupId: mstYouthGroupId ? mstYouthGroupId : 0,
          date: youthFields.date,
          youthGroupId: youthFields.youthGroupId,
          youthGroupName: youthFields.youthGroupName,
          mstTypeOfGroupId: youthFields.youthTypeOfGroup,
          mstCountryId: 1,
          mstStateId: youthFields.youthState,
          mstBranchProjectId: youthFields.youthLocation,
          youthGroupVenue: youthFields.youthVenue,
          youthVolunteerName: youthFields.youthVolunteerName,
          youthGroupPhoneNumber: youthFields.youthMobileNumber,
          youthGroupEmail: youthFields.youthEmailId,
          youthGroupRemarks: youthFields.youthRemarks,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "YouthGroupAPI/IUDYouthGroup");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].result === "S") {
            toast.success("Youth Group data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setSubmitVisibleButton(true);
            setYouthFields({
              ...youthFields,
              youthGroupId: response.data.Table1[0].YouthGroupId,
            });
            setTimeout(cancel, 10000);
          } else {
            toast.error("Failed to add Youth Group data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].result === "M") {
            toast.success("Youth Group data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Youth Group data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        mstYouthGroupId: bindYouthMasterDatas[rowIndex].MstYouthGroupId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "YouthGroupAPI/IUDYouthGroup");
      if (flag == 3) {
        if (response.data.Table[0].result === "D") {
          toast.success("Youth Group data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Youth Group data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].result === "B") {
          toast.success("Youth Group data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Youth Group data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success("Youth Group data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Youth Group data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location.pathname == "/youthgroup") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      cancel();
      // bindYouthData(userProfileDatas);
    }
  }, [location]);
  const handleYouthFieldsChange = (value, name) => {
    console.log("Values:", value, name);
    setYouthFields({
      ...youthFields,
      [name]: value,
    });
  };
  console.log("youthdata", youthFields.youthState);

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Youth Group"
          header={youthGroupMasterHeaders}
          records={bindYouthMasterDatas}
          handleEdit={handleEdit}
          xScroll="overflow-x-auto max-md:w-fit w-full"
          tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
          handleAction={handleYouthDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd1 && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Youth Group"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className=" p-5 min-h-[60vh] max-h-[72vh]">
                  <div class={mainTable.labelBold1}>Youth Group</div>
                  <hr class={mainTable.horizontalLine}></hr>
                  <div className={mainTable.grid3}>
                    <InputText
                      inputType="date"
                      title="Date"
                      classStar="text-red-500"
                      name="date"
                      content={youthFields.date}
                      controlFunc={(e) =>
                        setYouthFields({ ...youthFields, date: e.target.value })
                      }
                    />
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title="Youth Group Id"
                        maxLength={30}
                        name="youthGroupId"
                        placeholder={"MAD/YTG/2024/00001"}
                        content={youthFields.youthGroupId}
                        controlFunc={(e) =>
                          setYouthFields({
                            ...youthFields,
                            youthGroupId: e.target.value,
                          })
                        }
                        disabled={true}
                      />
                    </div>

                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title="Youth Group Name"
                        name="schoolName"
                        maxLength={100}
                        content={youthFields.youthGroupName}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          setYouthFields({
                            ...youthFields,
                            youthGroupName: value,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthGroupNameError:
                              value.trim().length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    value.trim()
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Youth Group Name",
                          }));
                        }}
                        controlFuncBlur={(e) => {
                          const trimmedValue = e.target.value.trim();
                          setYouthFields({
                            ...youthFields,
                            youthGroupName: trimmedValue,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthGroupNameError:
                              trimmedValue.length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    trimmedValue
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Youth Group Name",
                          }));
                        }}
                        errorMessage={youthFieldsError.youthGroupNameError}
                      />
                    </div>
                    <DropDown
                      classDiv="hidden"
                      title="Country"
                      options={bindCountry}
                      placeholder="Select"
                      name="country"
                      selectedOption={youthFields.youthCountry}
                      controlFunc={(e) =>
                        setYouthFields({
                          ...youthFields,
                          youthCountry: e.target.value,
                        })
                      }
                    />

                    <RadioGroup
                      classStar="text-red-500"
                      title="Type Of Group"
                      type="radio"
                      setName="typeofgroup"
                      selectedOptions={youthFields.youthTypeOfGroup}
                      options={groupRadio}
                      disabled={false}
                      controlFunc={handleGenderChange}
                      errorMessage={youthFieldsError.youthTypeOfGroupError}
                    />

                    <div>
                      <AutoDropDown
                        title="State"
                        classStar={"text-red-500"}
                        options={bindStateDropDown}
                        placeholder="Select"
                        name="youthState"
                        disabled={
                          youthFields.youthMstOrganizationId == 1 ? false : true
                        }
                        selectedOption={bindStateDropDown.find(
                          (x) => x.value == youthFields.youthState
                        )}
                        controlFunc={(value, e) => {
                          handleYouthFieldsChange(value.value, e.name);
                          setYouthFields({
                            ...youthFields,
                            youthState: value.value,
                            youthLocation: "",
                          });
                        }}
                        controlFuncBlur={() => {
                          setYouthFieldsError({
                            ...youthFieldsError,
                            youthStateError: youthFields.youthState
                              ? ""
                              : "Select State",
                          });
                        }}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        errorMessage={youthFieldsError.youthStateError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Branch/Project"
                        options={bindLocationDropDown2}
                        placeholder="Select"
                        name="youthLocation"
                        disabled={
                          youthFields.youthMstOrganizationId == 1 ? false : true
                        }
                        selectedOption={
                          youthFields.youthLocation
                            ? bindLocationDropDown.find(
                                (x) => x.value == youthFields.youthLocation
                              )
                            : ""
                        }
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleYouthFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setYouthFieldsError(() => ({
                            ...youthFieldsError,
                            youthLocationError: youthFields.youthLocation
                              ? ""
                              : "Select Branch/Project",
                          }));
                        }}
                        errorMessage={youthFieldsError.youthLocationError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType={"text"}
                        title={"Venue"}
                        name={"address"}
                        maxLength={500}
                        // ClassDiv="col-span-3"
                        content={youthFields.youthVenue}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          setYouthFields({
                            ...youthFields,
                            youthVenue: value,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthVenueError:
                              value.trim().length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    value.trim()
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Venue",
                          }));
                        }}
                        controlFuncBlur={(e) => {
                          const trimmedValue = e.target.value.trim();
                          setYouthFields({
                            ...youthFields,
                            youthVenue: trimmedValue,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthVenueError:
                              trimmedValue.length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    trimmedValue
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Venue",
                          }));
                        }}
                        errorMessage={youthFieldsError.youthVenueError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType={"text"}
                        title={"Youth Volunteer Name"}
                        name={"contactPerson"}
                        maxLength={100}
                        content={youthFields.youthVolunteerName}
                        controlFunc={(e) => {
                          const value = e.target.value;
                          setYouthFields({
                            ...youthFields,
                            youthVolunteerName: value,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthVolunteerNameError:
                              value.trim().length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    value.trim()
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Youth Volunteer Name",
                          }));
                        }}
                        controlFuncBlur={(e) => {
                          const trimmedValue = e.target.value.trim();
                          setYouthFields({
                            ...youthFields,
                            youthVolunteerName: trimmedValue,
                          });
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthVolunteerNameError:
                              trimmedValue.length > 0
                                ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                    trimmedValue
                                  )
                                  ? ""
                                  : "Only alphabet and single space between words allowed"
                                : "Enter Youth Volunteer Name",
                          }));
                        }}
                        errorMessage={youthFieldsError.youthVolunteerNameError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType={"text"}
                        title={"Mobile Number"}
                        name={"phone"}
                        maxLength={11}
                        content={youthFields.youthMobileNumber}
                        controlFunc={(e) =>
                          setYouthFields({
                            ...youthFields,
                            youthMobileNumber: e.target.value.trim(),
                          })
                        }
                        controlFuncBlur={(e) => {
                          setYouthFieldsError((youthFieldsError) => ({
                            ...youthFieldsError,
                            youthMobileNumberError:
                              youthFields.youthMobileNumber &&
                              youthFields.youthMobileNumber.length > 0
                                ? /^[\d\s]+$/.test(
                                    youthFields.youthMobileNumber
                                  )
                                  ? ""
                                  : "Enter a valid mobile number "
                                : "Enter Mobile Number",
                          }));
                        }}
                        errorMessage={youthFieldsError.youthMobileNumberError}
                      />
                    </div>
                    <div>
                      <InputText
                        // classStar="text-red-500"
                        inputType={"text"}
                        title={"Email Id"}
                        name={"email"}
                        maxLength={150}
                        content={youthFields.youthEmailId}
                        controlFunc={(e) =>
                          setYouthFields({
                            ...youthFields,
                            youthEmailId: e.target.value,
                          })
                        }
                        // controlFuncBlur={(e) => {
                        //   setYouthFieldsError((youthFieldsError) => ({
                        //     ...youthFieldsError,
                        //     youthEmailIdError:
                        //       youthFields.youthEmailId &&
                        //       youthFields.youthEmailId.length > 0
                        //         ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                        //             youthFields.youthEmailId
                        //           )
                        //           ? ""
                        //           : "Enter a valid email address "
                        //         : "Enter Email",
                        //   }));
                        // }}
                        // errorMessage={youthFieldsError.youthEmailIdError}
                      />
                    </div>
                    <div class="md:col-span-3 col-span-1">
                      <TextArea
                        inputType="text"
                        title="Remarks"
                        name="remarks"
                        rows="4"
                        maxLength={500}
                        content={youthFields.youthRemarks}
                        controlFunc={(e) =>
                          setYouthFields({
                            ...youthFields,
                            youthRemarks: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                  {submitVisibleButton && (
                    <div className="border p-2 flex flex-col items-center justify-center ">
                      <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                        Youth Group Id :
                        <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                          {youthFields.youthGroupId}
                        </span>
                      </label>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleYouthDetails}
      >
        <div className="w-full">
          <PopUpScreenTitle
            title="Youth Group"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />

          <YouthPopup
            data={youthData}
            data1={youthData1}
            data2={popUpDropDownData1}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default YouthGroup;
