// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import GridPage from "../components/GridPage";
import { ServicesHeadersData } from "../components/HeaderAttribute";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";
import MainPopup from "./MainPopup";
import AddServices from "./AddServices";
import PopUpButtons from "../components/PopUpButtons";

const ClientHistory = ({ data, data1 }) => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [bindServicesDatas, setBindServicesDatas] = useState([]);
  const [bindDoctorAppointmentDatas, setBindDoctorAppointmentDatas] = useState(
    []
  );
  const [getServicesData, setGetServicesDatas] = useState([]);
  const [bindMedicineDrugDatas, setBindMedicineDrugDatas] = useState([]);
  const [bindProvideServicesDatas, setBindProvideServicesDatas] = useState([]);
  const [bindServicesHistroyData, setBindServicesHistroyData] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [bindAppoinmentAreaDD, setBindAppoinmentAreaDD] = useState([]);

  const [bindArea, setBindArea] = useState([]);

  const [bindSDPDD, setBindSDPDD] = useState([]);
  const [servicesHistorypopUpOpen, setServicesHistorypopUpOpen] =
    useState(false);

  const [currentData1, setCurrentData1] = useState({});
  const [serviceDatas, setServiceData] = useState({});
  const [serviceDatas1, setServiceData1] = useState({});
  const [serviceDatas2, setServiceData2] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [upDatedTableData, setUpDatedTableData] = useState([]);

  useEffect(() => {
    debugger;
    console.log("data1", data);
    BindGridServicesData();
    BindServicesDData();
  }, [data]);

  useEffect(() => {
    debugger;
    const filteredData = bindServicesDatas?.filter(
      (item) => item.ClientId == data
    );
    setBindServicesHistroyData(filteredData);
  }, [bindServicesDatas]);

  useEffect(() => {
    debugger;
    if (data1) {
      setBindServicesDatas([]);
      setBindServicesHistroyData([]);
      setGetServicesDatas([]);
      setBindMedicineDrugDatas([]);
      setBindProvideServicesDatas([]);
      setBindServicesHistroyData([]);
      setGetLocationDatas([]);
      console.log("cancel");
    }
  }, [data1]);

  const handleServicesHistoryOpen = (isOpen) => {
    setServicesHistorypopUpOpen(isOpen);
  };
  const HandleServicesHistory = (rowIndex) => {
    handleServicesHistoryOpen(true);

    setServiceData(bindServicesHistroyData[rowIndex]);

    const doctorAppoinmentDatas = Object.values(
      bindDoctorAppointmentDatas
    ).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    setServiceData1(doctorAppoinmentDatas);

    const servicesProvideChild = Object.values(bindProvideServicesDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    setServiceData2(servicesProvideChild[0]);

    const appointmentWithRowData = doctorAppoinmentDatas;

    let appointmentWithInfo;
    let ResultAppointmentWith = "";
    let ResultEmail = "";

    if (
      appointmentWithRowData.length > 0 &&
      appointmentWithRowData[0].AppointmentWith
    ) {
      const filteredData = getLocationDatas.filter(
        (item) =>
          item.MstLocationId === appointmentWithRowData[0].AppointmentWith
      );
      ResultAppointmentWith = filteredData[0].LocationName_en;
      ResultEmail = filteredData[0].Email;
    } else {
      console.warn(
        "appointmentWithRowData is empty or missing AppointmentWith."
      );
    }

    let ResultAppointmentDate = ""; // Default value in case of an empty array or undefined

    if (
      doctorAppoinmentDatas.length > 0 &&
      doctorAppoinmentDatas[0].AppoinmentDate
    ) {
      ResultAppointmentDate = doctorAppoinmentDatas[0].AppoinmentDate;
    } else {
      console.warn("doctorAppoinmentDatas is empty or missing AppoinmentDate.");
    }

    const appointmentAreaRowData = doctorAppoinmentDatas;
    let appointmentAreaInfo;
    let ResultAppointmentArea = { value: "", label: "" }; // Default values

    if (
      appointmentAreaRowData.length > 0 &&
      appointmentAreaRowData[0].MstAreaId
    ) {
      appointmentAreaInfo = bindAppoinmentAreaDD.find(
        (L) =>
          parseInt(L.value) === parseInt(appointmentAreaRowData[0].MstAreaId)
      );

      ResultAppointmentArea = {
        value: appointmentAreaRowData[0].MstAreaId,
        label: appointmentAreaInfo ? appointmentAreaInfo.label : "Unknown",
      };
    } else {
      console.warn("appointmentAreaRowData is empty or missing MstAreaId.");
    }

    var CRSFollowUpPart = bindServicesHistroyData[rowIndex].CRSFollowUp;
    const CRSFollowUpDate = CRSFollowUpPart
      ? CRSFollowUpPart.split("T")[0]
      : null;

    var ReferralFollowUpPart =
      bindServicesHistroyData[rowIndex].ReferralFollowUp;
    const ReferralFollowUpDate = ReferralFollowUpPart
      ? ReferralFollowUpPart.split("T")[0]
      : null;

    const sdpRowData = bindServicesHistroyData[rowIndex];
    const sdpInfo = bindSDPDD.find((L) => L.value === sdpRowData.MstSDPId);
    const ResultSDP = {
      value: sdpRowData.MstSDPId,
      label: sdpInfo ? sdpInfo.label : "Unknown",
    };

    const areaRowData = bindServicesHistroyData[rowIndex];
    const areaInfo = bindArea.find((A) => A.value === areaRowData.MstAreaId);
    const ResultArea = {
      value: areaRowData.MstAreaId,
      label: areaInfo ? areaInfo.label : "Unknown",
    };
    const bindTxnServicesId = bindServicesHistroyData[rowIndex].TxnServicesId;

    const serviceSubCfilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultserviceSubC = serviceSubCfilteredData.map(
      (item) => item.MstServicesSubCategoryId
    );

    const serviceNamefilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultservicesName = serviceNamefilteredData.map(
      (item) => item.MstServicesId
    );

    const issueMedicineDrugDatas = Object.values(bindMedicineDrugDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );

    setCurrentData1(issueMedicineDrugDatas);

    const ProvideServicesFilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);

    const servicesNameData = ProvideServicesFilteredData.map(
      (item) => item.MstServicesId
    );

    const servicesProvideDatas = Object.values(bindProvideServicesDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    let updatedTableDatas = "";
    const servicesSelectedvalue = servicesNameData;
    const servicesLabels = getServicesData.filter((item) =>
      servicesSelectedvalue.includes(item.MstServicesId)
    );

    const servicesMap = new Map(
      servicesLabels.map((service) => [service.MstServicesId, service])
    );

    const updatedTableData = servicesProvideDatas.map((data) => {
      const service = servicesMap.get(data.MstServicesId);

      if (service) {
        return {
          ...data,
          value: `${service.ServicesCategory_en}-${service.ServicesSubCategory_en}-${service.Services_en}`,
          TestResultType: service.TestResultType,
          ResultUOM: `${service.ResultUOM}`,
          TestResult: data.TestResult,
          TestResultRadio: data.TestResultRadio,
          Quantity: data.Item,
          Charges: data.Charges,
          FPUser: data.FPUser,
          ReferredTo: data.ReferredTo,
        };
      }

      return data;
    });
    setUpDatedTableData(updatedTableData);

    var Date = bindServicesHistroyData[rowIndex].ServicesDate;
    const formattedDate = Date.split("T")[0];
    const AutoDropdownPopupdata = [
      ResultAppointmentWith,
      CRSFollowUpDate,
      ReferralFollowUpDate,
      ResultSDP,
      ResultArea,
      resultserviceSubC,
      resultservicesName,
      formattedDate,
      ResultEmail,
    ];

    setPopupdata(AutoDropdownPopupdata);
  };

  const BindGridServicesData = async () => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0]?.MstSDPId
          ? userProfileDatas[0]?.MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "TXNServicesAPI/BindTXNServices"
      );
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
      const sdpResponse = await BindAPI("SDPAPI/GetSDP");
      const sdpData = sdpResponse.data.Table.map((item) => ({
        value: item.MstSDPId,
        label: item.SDP_en,
      }));
      setBindSDPDD(sdpData);

      const locationResponse1 = await BindAPI("AreaAPI/GetArea");
      const areaData = locationResponse1.data.Table.map((item) => ({
        value: item.MstAreaId,
        label: item.AreaName_en,
      }));
      setBindArea(areaData);

      // Fetch service Dropdown datas
      const servicesResponse = await BindAPI("ServicesMasterAPI/BindServices");
      setGetServicesDatas(servicesResponse.data.Table);

      console.log("response", response);
      setBindServicesDatas(response.data.Table);
      setBindDoctorAppointmentDatas(response.data.Table1);
      setBindMedicineDrugDatas(response.data.Table2);
      setBindProvideServicesDatas(response.data.Table3);
    }
  };

  const BindServicesDData = async (userProfileDatas) => {
    const locationResponse = await BindAPI("LocationAPI/GetLocation");
    setGetLocationDatas(locationResponse.data.Table);
    const sdpResponse = await BindAPI("SDPAPI/GetSDP");
    const sdpData = sdpResponse.data.Table.map((item) => ({
      value: item.MstSDPId,
      label: item.SDP_en,
    }));
    setBindSDPDD(sdpData);

    const locationResponse1 = await BindAPI("AreaAPI/GetArea");
    const areaData = locationResponse1.data.Table.map((item) => ({
      value: item.MstAreaId,
      label: item.AreaName_en,
    }));
    setBindArea(areaData);

    // Fetch service Dropdown datas
    const servicesResponse = await BindAPI("ServicesMasterAPI/BindServices");
    setGetServicesDatas(servicesResponse.data.Table);
  };

  const handleClose = () => {
    setServicesHistorypopUpOpen(false);
    BindGridServicesData();
  };
  const cancel1 = () => {
    debugger;

    setServicesHistorypopUpOpen(false);
    BindGridServicesData();
  };

  return (
    <div class="w-full">
      <div class={mainTable.mainContentScreen}>
        <form class={mainTable.formForPopupcontainer}>
          <div class={mainTable.labelBold}>Client History</div>
          <hr class={mainTable.servicesHorizontalLine}></hr>

          <GridPage
            title=""
            header={ServicesHeadersData}
            records={bindServicesHistroyData}
            onServicesGrid={HandleServicesHistory}
            addView={true}
          />
        </form>
      </div>
      <MainPopup
        popUpOpen={servicesHistorypopUpOpen}
        setpopUpOpen={setServicesHistorypopUpOpen}
        Open={() => HandleServicesHistory}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <AddServices
            data={serviceDatas}
            data1={serviceDatas1}
            data2={popupdata}
            data3={serviceDatas2}
            currentData1={currentData1}
            upDatedTableData={upDatedTableData}
          />
        </div>
        <PopUpButtons cancel={cancel1} />
      </MainPopup>
    </div>
  );
};

export default ClientHistory;
