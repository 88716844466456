// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const District = ({ data, data2 }) => {
  console.log("data2", data2);
  const [bindStatesDD, setBindStatesDD] = useState([
    { value: "1", label: "Andhra Pradesh" },
    { value: "2", label: "Arunachal Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Delhi" },
    { value: "7", label: "Goa" },
    { value: "8", label: "Gujarat" },
    { value: "9", label: "Haryana" },
    { value: "10", label: "Himachal Pradesh" },
    { value: "11", label: "Jammu & Kashmir" },
    { value: "12", label: "Jharkhand" },
    { value: "13", label: "Karnataka" },
    { value: "14", label: "Kerala" },
    { value: "15", label: "Madhya Pradesh" },
    { value: "16", label: "Maharashtra" },
    { value: "17", label: "Manipur" },
    { value: "18", label: "Meghalaya" },
    { value: "19", label: "Mizoram" },
    { value: "20", label: "Nagaland" },
    { value: "21", label: "Odisha" },
    { value: "22", label: "Punjab" },
    { value: "23", label: "Rajasthan" },
    { value: "24", label: "Tamil Nadu" },
    { value: "25", label: "Telangana" },
    { value: "26", label: "Tripura" },
    { value: "27", label: "Uttarakhand" },
    { value: "28", label: "Uttar Pradesh" },
    { value: "29", label: "West Bengal" },
  ]);

  const [countryName, setCountryName] = useState("");

  const [bindCountriesDD, setBindCountriesDD] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "Nepal" },
    { value: "3", label: "Srilanka" },
  ]);

  return (
    <div class="w-full ">
      <div class=" border-black  "></div>
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <DropDown
                  classDiv="hidden"
                  
                  title={"Country"}
                  options={bindCountriesDD}
                  placeholder={"Select"}
                  name={"country"}
                  selectedOption={countryName}
                  controlFunc={(e) => setCountryName(e.target.value)}
                />
                <AutoDropDown
                  title="State"
                  classStar="text-red-500"
                  options={bindStatesDD}
                  placeholder="Select"
                  name="state"
                  selectedOption={data2[0]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />

                <InputText
                  inputType={"text"}
                  classStar="text-red-500"
                  title={"District"}
                  name={"location"}
                  content={data.District_en}
                  disabled="false"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default District;
