import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import download from "../image/download.png";
import moment from "moment/moment";
import Checkbox from "../ReusableComponents/Checkbox";
import { BindPostAPI } from "../services/APIConnection";

function InSchoolPopup({ data, data1, data2, data3 }) {
  const [selectedModules1, setSelectedModules1] = useState("");
  const [inSchoolSessionFields, setInSchoolSessionFields] = useState({
    session: data[0]?.Sessions,
    mstSchoolId: parseInt(data[0]?.MstSchoolId),
    cseClass: parseInt(data[0]?.Class),
    division: data[0]?.Division,
    resourcePersonType: data[0]?.ResourcePersonType,
    resourcePersonName1: data[0]?.ResourcesName1,
    resourcePersonName2: data[0]?.ResourcesName2,
    date: moment(data[0]?.Date).format("YYYY-MM-DD"),
    startTime: data[0]?.StartTime,
    endTime: data[0]?.EndTime,
    showImage: data[0]?.FileUpload,
    remarks: data[0]?.Remarks,
  });
  const [bindGenderDD, setBindGenderDD] = useState([]);

  const [bindReferedTo, setBindReferedTo] = useState([]);
  const [bindreferelserviceDD, setBindreferelserviceDD] = useState([]);
  const [bindCSEAgeDD, setBindCSEAgeDD] = useState([]);

  const [bindResourceTypeDD, setBindResourceTypeDD] = useState([]);
  const [bindSchoolDD, setBindSchoolDD] = useState([]);
  const [bindClassDD, setBindClassDD] = useState([]);

  const [bindModules, setBindModules] = useState([]);
  const [bindSession, setBindSession] = useState([]);

  useEffect(() => {
    const Dropdowndetails = () => {
      bindradModules([data3[0]]);
      binddrpAgedata([data3[1]]);
      binddrpGenderdata([data3[2]]);
      binddrpReferredtodata([data3[3]]);
      binddrpReferredBydata([data3[4]]);
      binddrpResourceTypedata([data3[5]]);
      binddrpSchooldata([data3[6]]);
      binddrpClassdata([data3[7]]);
      binddrpSessionsdata([data3[8]]);
    };
    Dropdowndetails();
  }, [data2, data3]);
  useEffect(() => {
    // Define an async function inside useEffect since useEffect cannot directly accept an async function
    const fetchData = async () => {
      if (data.length > 0 && data1 !== null) {
        const payload = {
          year: parseInt(data[0]?.Date.split("-")[0]),
          mstSchoolId: parseInt(data[0]?.MstSchoolId),
          class: parseInt(data[0]?.Class),
          division: data[0]?.Division,
          sessionNumber: data1,
        };
        const response = await BindPostAPI(
          [payload],
          "CSEInSchoolAPI/GetCSEInSchoolData"
        );

        setInSchoolSessionFields({
          ...inSchoolSessionFields,
          session: data1,
          mstSchoolId: response.data.Table[0].MstSchoolId,
          cseClass: parseInt(response.data.Table[0].Class),
          division: response.data.Table[0].Division,
          resourcePersonType: response.data.Table[0].ResourcePersonType,
          resourcePersonName1: response.data.Table[0].ResourcesName1,
          resourcePersonName2: response.data.Table[0].ResourcesName2,
          date: moment(response.data.Table[0].OldDate).format("YYYY-MM-DD"),
          startTime: moment(
            response.data.Table[0].StartTime,
            "HH:mm:ss"
          ).format("HH:mm:ss"),
          endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
            "HH:mm:ss"
          ),
          showImage: response.data.Table[0].FileUpload,
          remarks: response.data.Table[0].Remarks,
        });
        const modules = response.data.Table[0].Modules.split(",").map((value) =>
          parseInt(value.trim(), 10)
        );
        setSelectedModules1(modules);
        if (response.data.Table1.length === 0) {
          setTableData([
            {
              studentName: "",
              studentId: "",
              gender: "",
              age: "",
              referralService: "",
              referredTo: "",
            },
          ]);
        } else {
          // Append the filtered data to your state using appendDataToState function
          appendDataToStudentTable(JSON.stringify(response.data.Table1));
        }
        // appendDataToStudentTable(JSON.stringify(response.data.Table1));
      }
    };

    // Call the async function
    fetchData();

    // Cleanup function (if applicable)
    return () => {
      // Perform any cleanup here if needed
    };
  }, [data, data1]);
  const bindradModules = async (values) => {
    try {
      if (values[0].length > 0) {
        const modulesDD = values[0].map((m) => ({
          value: parseInt(m.value),
          label: m.label,
        }));
        setBindModules(modulesDD);
      } else {
        setBindModules([]);
      }
    } catch (error) {
      console.error("Error fetching setAwarenessAge data:", error);
    }
  };

  const binddrpAgedata = async (Agevalues) => {
    try {
      if (Agevalues[0].length > 0) {
        const AgeDD = Agevalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindCSEAgeDD(AgeDD);
      } else {
        setBindCSEAgeDD([]);
      }
    } catch (error) {
      console.error("Error fetching setAwarenessAge data:", error);
    }
  };

  const binddrpGenderdata = async (Gendervalues) => {
    try {
      if (Gendervalues[0].length > 0) {
        const GenderDD = Gendervalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindGenderDD(GenderDD);
      } else {
        setBindGenderDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindGenderDD data:", error);
    }
  };
  const binddrpReferredtodata = async (referraltovalues) => {
    try {
      if (referraltovalues[0].length > 0) {
        const ReferredTodatadrpDD = referraltovalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindReferedTo(ReferredTodatadrpDD);
      } else {
        setBindReferedTo([]);
      }
    } catch (error) {
      console.error("Error fetching ReferredTo data:", error);
    }
  };

  const binddrpReferredBydata = async (referralservicevalues) => {
    try {
      if (referralservicevalues[0].length > 0) {
        const ReferredBydatadrpDD = referralservicevalues[0].map((m) => ({
          value: parseInt(m.value),
          label: m.label,
        }));
        setBindreferelserviceDD(ReferredBydatadrpDD);
      } else {
        setBindreferelserviceDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  const binddrpResourceTypedata = async (resourceTypeValues) => {
    try {
      if (resourceTypeValues[0].length > 0) {
        const ResourcesdatadrpDD = resourceTypeValues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindResourceTypeDD(ResourcesdatadrpDD);
      } else {
        setBindResourceTypeDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };
  const binddrpSchooldata = async (schoolValues) => {
    try {
      if (schoolValues[0].length > 0) {
        const ReferredBydatadrpDD = schoolValues[0].map((m) => ({
          value: parseInt(m.value),
          label: m.label,
        }));
        setBindSchoolDD(ReferredBydatadrpDD);
      } else {
        setBindSchoolDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  const binddrpClassdata = async (classValues) => {
    try {
      if (classValues[0].length > 0) {
        const classdatadrpDD = classValues[0].map((m) => ({
          value: parseInt(m.value),
          label: m.label,
        }));
        setBindClassDD(classdatadrpDD);
      } else {
        setBindClassDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };
  const binddrpSessionsdata = async (sessionsValue) => {
    try {
      if (sessionsValue[0].length > 0) {
        const sessionsdatadrpDD = sessionsValue[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindSession(sessionsdatadrpDD);
      } else {
        setBindSession([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };
  useEffect(() => {
    const clearValues = () => {
      // Implement logic to clear values from the table
      // For example, if setTableData([]) is the function to clear the table data, use it here
      setTableData([]);
    };

    clearValues();

    const studentDatas = JSON.stringify(data2);

    if (studentDatas && typeof studentDatas === "string") {
      try {
        const newDataArray = JSON.parse(studentDatas);
        const formattedDataArray = newDataArray.map((data, index) => {
          return {
            studentName: data.StudentName,
            studentId: data.StudentId,
            gender: data.Gender,
            age: data.MstAgeId,
            referralService: data.MstReferralServicesId,
            referredTo: data.ReferredToId,
          };
        });

        setTableData((prevData) => [...prevData, ...formattedDataArray]);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error, e.g., show a message to the user or log it
      }
    } else {
      // Handle the case when data2 is not a string or is empty
    }
  }, []);

  const headerData = [
    {
      title: "Student Name",
      key: "StudentName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Student ID.",
      key: "Roll Id.",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "Gender",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "Age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "ReferralService",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referred To",
      key: "ReferredTo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const [tableData, setTableData] = useState([
    {
      studentName: "",
      studentId: "",
      gender: "",
      age: "",
      referralService: "",
      referredTo: "",
    },
  ]);
  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.studentName = ""; // Clear the values of the new row
    newRow.studentId = "";
    newRow.gender = "";
    newRow.age = "";
    newRow.referralService = "";
    newRow.referredTo = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };
  const handleSession = async (value, data) => {
    data[0].Sessions = parseInt(value);
    const data1 = {
      year: parseInt(data[0]?.Date.split("-")[0]),
      mstSchoolId: parseInt(data[0]?.MstSchoolId),
      class: parseInt(data[0]?.Class),
      division: data[0]?.Division,
      sessionNumber: parseInt(value),
    };
    const response = await BindPostAPI(
      [data1],
      "CSEInSchoolAPI/GetCSEInSchoolData"
    );

    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      session: value,
      mstSchoolId: response.data.Table[0].MstSchoolId,
      cseClass: parseInt(response.data.Table[0].Class),
      division: response.data.Table[0].Division,
      resourcePersonType: response.data.Table[0].ResourcePersonType,
      resourcePersonName1: response.data.Table[0].ResourcesName1,
      resourcePersonName2: response.data.Table[0].ResourcesName2,
      date: moment(response.data.Table[0].OldDate).format("YYYY-MM-DD"),
      startTime: moment(response.data.Table[0].StartTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      showImage: response.data.Table[0].FileUpload,
      remarks: response.data.Table[0].Remarks,
    });
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules1(modules);

    appendDataToStudentTable(JSON.stringify(response.data.Table1));
  };
  const appendDataToStudentTable = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);
    setTableData([]);
    const formattedDataArray = newDataArray.map((data) => ({
      studentName: data.StudentName,
      studentId: data.StudentId,
      age: data.MstAgeId,
      gender: data.Gender,
      referralService: data.MstReferralServicesId,
      referredTo: data.ReferredToId,
    }));

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
  };
  return (
    <div className="flex flex-col w-full">
      <div className={mainTable.mainAddScreen}>
        <div className={mainTable.addShadowScreen}>
          <div className={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5 lg:h-96 min-h-[60vh] max-h-[72vh]">
              <div className={mainTable.grid4}>
                <AutoDropDown
                  Class="Custom-class"
                  disabled={true}
                  title={`School Name`}
                  options={bindSchoolDD}
                  classStar="text-red-500"
                  placeholder="Select"
                  name="mstSchoolId"
                  selectedOption={bindSchoolDD.find(
                    (x) => x.value == inSchoolSessionFields.mstSchoolId
                  )}
                />
                <div className={mainTable.gridInventory2}>
                  <DropDown
                    title="Class / Std."
                    classStar="text-red-500"
                    disabled={true}
                    options={bindClassDD}
                    placeholder="Select"
                    name="cseClass"
                    selectedOption={inSchoolSessionFields.cseClass}
                  />
                  <InputText
                    inputType="text"
                    disabled={true}
                    maxLength={5}
                    title="Division"
                    classStar="text-red-500"
                    placeholder="A"
                    name="division"
                    content={inSchoolSessionFields.division}
                  />
                </div>
                <DropDown
                  title="Type of Resource Person"
                  classStar="text-red-500"
                  disabled={true}
                  options={bindResourceTypeDD}
                  placeholder="Select"
                  name="resourcePersonType"
                  selectedOption={inSchoolSessionFields.resourcePersonType}
                />

                <InputText
                  inputType="text"
                  title="Resource Person Name1"
                  disabled={true}
                  classStar="text-red-500"
                  placeholder="A"
                  name="division"
                  content={inSchoolSessionFields.resourcePersonName1}
                />
                <InputText
                  inputType="text"
                  title="Resource Person Name2"
                  disabled={true}
                  name="division"
                  content={inSchoolSessionFields.resourcePersonName2 ?? ""}
                />
              </div>
              <div class={mainTable.grid3}>
                <InputText
                  inputType="date"
                  title="Date"
                  classStar="text-red-500"
                  name="schoolName"
                  content={inSchoolSessionFields.date}
                  disabled={true}
                />
                <InputText
                  inputType="time"
                  title="Start Time"
                  classStar="text-red-500"
                  name="startTime"
                  content={inSchoolSessionFields.startTime}
                  disabled={true}
                />
                <InputText
                  inputType="time"
                  title="End Time"
                  classStar="text-red-500"
                  name="endTime"
                  content={inSchoolSessionFields.endTime}
                  disabled={true}
                />
              </div>
              <Checkbox
                classDiv="col-span-4 mb-2"
                title="Modules"
                classStar="text-red-500"
                type={"checkbox"}
                setName={"module"}
                selectedOptions={selectedModules1}
                options={bindModules}
                disabled={true}
                tabIndex="0"
                Class1="w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                Class="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg"
              />

              <div class={mainTable.grid3}>
                <DropDown
                  title="Session"
                  classStar="text-red-500"
                  options={bindSession}
                  placeholder="Select"
                  name="session"
                  selectedOption={inSchoolSessionFields.session}
                  controlFunc={(e) => {
                    handleSession(e.target.value, data);
                  }}
                />
                {/* <input
                  type="image"
                  src={
                    inSchoolSessionFields.showImage
                      ? inSchoolSessionFields.showImage
                      : download
                  }
                  alt="Image"
                  className="rounded-full w-20 h-20"
                /> */}
                 <img
          src={
            inSchoolSessionFields.showImage
              ? inSchoolSessionFields.showImage
              : download
          }
          alt="Image"
          className="rounded-full w-20 h-20"
        />
              </div>

              <div class="lg:col-span-4 sm:col-span-1">
                <TextArea
                  inputType="text"
                  title="Remarks"
                  name="remarks"
                  rows="4"
                  content={inSchoolSessionFields.remarks}
                  placeholder="Remarks"
                  disabled="disabled"
                />
              </div>
              <div class="block text-base capitalize font-bold mt-2 text-neutral-700">
                List Of Participants
              </div>
              <div className="hidden lg:block ">
                <table className="w-full border border-gray-300 mt-6">
                  <thead>
                    <tr>
                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                        Roll ID.
                      </th>
                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                        Student Name
                      </th>

                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                        Gender
                      </th>
                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                        Age
                      </th>
                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                        Referral Service
                      </th>
                      <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                        Referred To
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr key={index}>
                        <td className="border border-gray-300 p-2 w-24">
                          <InputText
                            type="text"
                            disabled={true}
                            content={row.studentId}
                          />
                        </td>
                        <td className="border border-gray-300 p-2 w-36">
                          <InputText
                            type="text"
                            disabled={true}
                            content={row.studentName}
                          />
                        </td>
                        <td className="border border-gray-300 p-2 w-16">
                          <DropDown
                            options={bindGenderDD}
                            disabled={true}
                            placeholder="Select"
                            name="categories"
                            selectedOption={row.gender}
                          />
                        </td>
                        <td className="border border-gray-300 p-2 w-16">
                          <DropDown
                            options={bindCSEAgeDD}
                            disabled={true}
                            placeholder="Select"
                            name="age"
                            selectedOption={row.age}
                          />
                        </td>
                        <td className="border border-gray-300 p-2 w-32">
                          <DropDown
                            options={bindreferelserviceDD}
                            disabled={true}
                            placeholder="Select"
                            name="referralService"
                            selectedOption={parseInt(row.referralService)}
                          />
                        </td>

                        <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                          <DropDown
                            options={bindReferedTo}
                            disabled={true}
                            placeholder="Select"
                            name="referredTo"
                            selectedOption={row.referredTo}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={mainTable.tableMobile} role="table">
                <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                  {tableData.length > 0 &&
                    tableData.map((row, index) => (
                      <div scope="col" class={mainTable.mobileTable}>
                        {headerData.map((keyData) => (
                          <div
                            scope="cell"
                            class={mainTable.tableData}
                            role="cell"
                          >
                            {keyData.key === "Action" ? (
                              <div class={mainTable.tableData}>
                                <div className="md:block hidden">
                                  <button
                                    id="dropdownMenuIconButton"
                                    data-dropdown-toggle="dropdownDots"
                                    class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                    type="button"
                                  >
                                    <svg
                                      class="w-6 h-6"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="md:hidden flex justify-center">
                                  <input
                                    type="button"
                                    disabled={true}
                                    className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                    onClick={handleAddRow}
                                    value="Add"
                                  />
                                  <input
                                    type="button"
                                    disabled={true}
                                    className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                    onClick={() => handleDeleteRow(index)}
                                    value="Delete"
                                  />
                                </div>
                              </div>
                            ) : keyData.key === "StudentId" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled={true}
                                  content={row.studentId}
                                />
                              </div>
                            ) : keyData.key === "StudentName" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled={true}
                                  content={row.studentName}
                                />
                              </div>
                            ) : keyData.key === "Gender" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindGenderDD}
                                  disabled={true}
                                  placeholder="Select"
                                  name="categories"
                                  selectedOption={row.gender}
                                />
                              </div>
                            ) : keyData.key === "Age" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindCSEAgeDD}
                                  disabled={true}
                                  placeholder="Select"
                                  name="age"
                                  selectedOption={row.age}
                                />
                              </div>
                            ) : keyData.key === "ReferralService" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindreferelserviceDD}
                                  disabled={true}
                                  placeholder="Select"
                                  name="referralService"
                                  selectedOption={row.referralService}
                                />
                              </div>
                            ) : keyData.key === "ReferredTo" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindReferedTo}
                                  disabled={true}
                                  placeholder="Select"
                                  name="categories"
                                  selectedOption={row.referredTo}
                                />
                              </div>
                            ) : (
                              <div className="flex border p-1">
                                <div className="flex basis-1/2 font-bold">
                                  {keyData.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InSchoolPopup;
