// Code review - In Progress
// Documentation - In Progress
import React from "react";
import InputText from "../ReusableComponents/InputText";
import { mainTable } from "../css/Common";

const LanguagePopup = ({ data }) => {
  return (
    <div class="w-full ">
      <div class="mx-auto py-2 lg:w-2/5  md:w-full md:flex md:justify-center w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <InputText
                  inputType={"text"}
                  title={"language"}
                  name={"language"}
                  content={data.Language}
                  classStar="text-red-500"
                  disabled={true}
                />

                <InputText
                  inputType={"text"}
                  title={"Language Code"}
                  name={"languageCode"}
                  content={data.LanguageKey}
                  classStar="text-red-500"
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguagePopup;
