import React, { useEffect, useState } from "react";
import Select from "react-select";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import TableGrid from "../components/Table";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindLocalAPI, GetLocalAPI, IUDAPI } from "../services/APIConnection";
import Checkbox from "../ReusableComponents/Checkbox";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReportGridPage from "../components/ReportGridPage";
import fpaiLogo from '../image/FPAILogo.jpg';


const CSEReport = () => {
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [bindYearDD, setBindYearDD] = useState([]);
  const [mstSchoolId, setMstSchoolId] = useState("");
  const [cseClass, setCseClass] = useState("");
  const [bindClassDD, setBindClassDD] = useState([]);
  const [division, setDivision] = useState("");
  const [bindDivisionDD, setBindDivisionDD] = useState([]);
  const [bindTopics, setBindTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState("");

  const [bindYearDetails, setYearDetails] = useState([]);
  const [ClassDetails, setClassDetails] = useState([]);
  const [bindDivisionDetails, setDivisionDetails] = useState([]);
  const [bindReportInschoolSeassion, setBindReportInschoolSeassion] = useState([]);

  // useEffect(() => {
  //   const today = new Date();
  //   const currentMonth = today.getMonth();
  //   const currentYear = today.getFullYear();

  //   // Set fromDate to the first day of the current month and year
  //   const fromDate = new Date(currentYear, currentMonth, 2);

  //   // Set toDate to the current date
  //   const toDate = today;

  //   // Optionally, you can format the dates if needed
  //   const formattedFromDate = fromDate.toISOString().split('T')[0];
  //   const formattedToDate = toDate.toISOString().split('T')[0];

  //   // Now, you can use formattedFromDate and formattedToDate in your state or elsewhere
  //   console.log("fromDate:", formattedFromDate);
  //   console.log("toDate:", formattedToDate);

  //   // Set the state
  //   setFromDate(formattedFromDate);
  //   setToDate(formattedToDate);
  // }, []); 

  const tableHeader = [
    {
      title: "S.No.",
      key: "sno",
      width: "md:w-10 lg:w-12 xl:w-12 2xl:w-24 px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "School ID No.",
      key: "SchoolId",
      width: "md:w-16 lg:w-16 xl:w-24 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "Name of Student",
      key: "StudentName",
      width: "md:w-28 lg:w-24 xl:w-36 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "Student Roll/ ID No",
      key: "StudentId",
      width: "md:w-32 lg:w-32 xl:w-44 2xl:m-auto 2xl:w-60 px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "Class",
      key: "Class",
      width: "md:w-14 lg:w-14 xl:w-10 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "Division",
      key: "Division",
      width: "md:w-14 lg:w-14 xl:w-16 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },

    {
      title: "Age (In Years)",
      key: "Age",
      width: "md:w-32 lg:w-28 xl:w-18 2xl:m-auto 2xl:w-18 px-2 py-3 mr-auto",
      width1: "text-red-500",
    },
    {
      title: "Gender (M/ F/ O)",
      key: "Gender",
      width: "md:w-10 lg:w-12 xl:w-32 2xl:w-24 px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "1) Gender",
      key: "1)Gender",
      width: "md:w-16 lg:w-16 xl:w-24 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "2) Sexual and Reproductive Health",
      key: "2)Sexual and Reproductive Health",
      width: "md:w-28 lg:w-24 xl:w-96 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "3)Sexual Rights and Sexual Citizenship",
      key: "3)Sexual Rights and Sexual Citizenship",
      width: "md:w-32 lg:w-32 xl:w-96 2xl:m-auto 2xl:w-60 px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "4) Pleasure",
      key: "4)Pleasure",
      width: "md:w-24 lg:w-24 xl:w-26 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },
    {
      title: "5) Violence",
      key: "5)Violence",
      width: "md:w-24 lg:w-24 xl:w-24 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },

    {
      title: "6) Diversity",
      key: "6)Diversity",
      width: "md:w-32 lg:w-28 xl:w-20 2xl:m-auto 2xl:w-48 px-2 py-3 mr-auto",
      width1: "text-red-500",
    },
    {
      title: "7)Relationships",
      key: "7)Relationships",
      width: "md:w-24 lg:w-24 xl:w-36 2xl:m-auto px-2 py-3 ",
      width1: "text-red-500",
    },

    {
      title: "8: All Topic Covered",
      key: "AllTopicCovered",
      width: "md:w-32 lg:w-28 xl:w-36 2xl:m-auto 2xl:w-48 px-2 py-3 mr-auto",
      width1: "text-red-500",
    },
    {
      title: "Count Topic",
      key: "CountTopic",
      width: "md:w-32 lg:w-28 xl:w-36 2xl:m-auto 2xl:w-48 px-2 py-3 mr-auto",
      width1: "text-red-500",
    },
  ];


  useEffect(() => {
    const fetchData = async () => {
      binddrpTopicsdata();
    };

    fetchData();
  }, []);

  const GoButton = async () => {
    debugger
    const txnInSchoolSessionId = bindDivisionDetails[0].TxnInSchoolSessionId || 0;
    const ReportData = [
      {
        Year: year,
        SchoolName: mstSchoolId.value,
        cseClass: cseClass.value,
        Division: division.value,
        TxnInSchoolSessionId: txnInSchoolSessionId,
        Fromdate: fromDate,
        ToDate: toDate

      },
    ];
    const response = await GetLocalAPI(ReportData, "ReportCSEInSchoolSessionAPI/ReportInSchoolGet");

    console.log("response", response.data.Table);
    setBindReportInschoolSeassion(response.data.Table);
    setShowTable(true);

  };

  const binddrpTopicsdata = async () => {
    try {
      const bindTopicsdatadrp = await BindAPI("CSEInSchoolAPI/BindCSEAllMasterDatas");

      if (bindTopicsdatadrp.data.Table.length > 0) {
        const Topicsdatadrp = bindTopicsdatadrp.data.Table.map((m) => ({
          value: m.MstCSEModuleID,
          label: m.CSEModule,
        }));
        setBindTopics(Topicsdatadrp);
      } else {
        setBindTopics([]);
      }
    } catch (error) { }
  };

  useEffect(() => {
    const generateYearOptions = () => {

      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1900; year--) {
        years.push({ value: year, label: year.toString() });
      }
      return years;
    };
    const yearsOptions = generateYearOptions();
    setBindYearDD(yearsOptions);

    const automaticFromDate = `${yearsOptions[0].value}-01-01`;
    setFromDate(automaticFromDate);
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const toDate = today;
    const formattedToDate = toDate.toISOString().split('T')[0];

    setToDate(formattedToDate);
  }, []);

  const [year, setYear] = useState(new Date().getFullYear());
  console.log("year", year);

  const handleYearChange = (event) => {
    debugger
    const selectedYear = parseInt(event.target.value, 10);

    const automaticFromDate = `${selectedYear}-01-01`; // Adjusted the date format
    const currentYear = new Date().getFullYear();
    setFromDate(automaticFromDate);
    if (currentYear === selectedYear) {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      const toDate = today;
      const formattedToDate = toDate.toISOString().split('T')[0];

      setToDate(formattedToDate);
    }
    else {
      const ToDate = `${selectedYear}-12-31`;
      setToDate(ToDate)
    }
    setYear(selectedYear);
  };

  const handleTopicsChange = (value) => {
    setSelectedTopics(value);
  };
  useEffect(() => {
    BindSchoolDetails();
  }, [year]);


  const BindSchoolDetails = async () => {

    try {
      const bindSchooldatadrp = await BindAPI("ReportCSEInSchoolSessionAPI/ReportCSEBindSchool");

      if (bindSchooldatadrp.data.Table.length > 0) {

        const filteredData = bindSchooldatadrp.data.Table.filter(item => {
          const itemYear = parseInt(item.YDate, 10);
          return itemYear === year;
        });

        setYearDetails(filteredData);

        console.log("filteredData", filteredData);

        const schoolNames = filteredData.map((m) => ({
          value: m.MstSchoolId,
          label: m.SchoolName,
        }));

        console.log("schoolNames", schoolNames);
        setBindSchoolDD(schoolNames);
      } else {
        console.log("No data available");
        setBindSchoolDD([]);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const [bindSchoolDD, setBindSchoolDD] = useState([]);

  // const clear =() => {
  //   setBindSchoolDD([]);
  //   setBindClassDD("");
  //   setBindDivisionDD("");
  //   setSelectedTopics("");
  // }

  const handleSchoolNameChange = (selectedSchoolId) => {


    setMstSchoolId(selectedSchoolId);

    console.log("bindYearDetails", bindYearDetails);
    const filteredData = bindYearDetails.filter(item => {
      const schoolIdMatch = item.MstSchoolId;
      return schoolIdMatch === selectedSchoolId.value; // Fix the comparison here
    });

    setClassDetails(filteredData);
    console.log("ClassDetails", filteredData);

    const classes = filteredData.map((m) => ({
      value: m.Class,
      label: m.Class,
    }));
    console.log("classes", classes);
    setBindClassDD(classes);

  };

  const handleClassNameChange = (ClassName) => {


    setCseClass(ClassName);

    const filteredData = ClassDetails.filter(item => {
      const classid = item.Class;
      return classid === ClassName.value; // Fix the comparison here
    });
    setDivisionDetails(filteredData);
    console.log("ClassDetails", filteredData);

    const Divisions = filteredData.map((m) => ({
      value: m.Division,
      label: m.Division,
    }));
    console.log("classes", Divisions);
    setBindDivisionDD(Divisions);

  };

  const handleDivisionChange = (DivisionName) => {


    setDivision(DivisionName);

    const filteredData = bindDivisionDetails.filter(item => {
      const Divisionid = item.Division;
      return Divisionid === DivisionName.value;
    });
    console.log("ClassDetails", filteredData);

    const topics1 = filteredData.map(item => item.Topics.split(',')).flat();
    console.log("Topics1", topics1);
    const topics = topics1.map((value) => parseInt(value.trim(), 10));
    console.log("Topics", topics);
    setSelectedTopics(topics);
  };


  // search box function

  // const handleExport = () => {
  //   // Assuming bindReportInschoolSeassion is your data array
  //   const dataToExport = bindReportInschoolSeassion.map((rowData) => ({
  //     ...rowData,
  //     // Format the Student Roll/ ID No.
  //     StudentRoll: `${new Date(rowData.Date).getFullYear()}/${rowData.Sno}`,
  //   }));

  //   // Convert data to worksheet
  //   const ws = XLSX.utils.json_to_sheet(dataToExport);

  //   // Create a workbook with the worksheet
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   // Save the workbook to a file
  //   XLSX.writeFile(wb, '.xlsx');
  // };
  const exportData = (columns, rows) => {
    const ws = XLSX.utils.aoa_to_sheet([
      columns,
      ...rows.map((rowData, index) => {

        return [
          index + 1,
          rowData.SchoolId,
          rowData.StudentName,
          rowData.StudentId,
          rowData.Class,
          rowData.Division,
          rowData.Age,
          rowData.Gender,
          rowData["1)Gender"],
          rowData["2)Sexual and Reproductive Health"],
          rowData["3)Sexual Rights and Sexual Citizenship"],
          rowData["4)Pleasure"],
          rowData["5)Violence"],
          rowData["6)Diversity"],
          rowData["7)Relationships"],
          rowData.AllTopicCovered,
          rowData.CountTopic,
        ];
      }),
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save or display the Excel file
    XLSX.writeFile(wb, 'CSEInSchoolSessionReport.xlsx');
  };

  const handleExport = () => {
    exportData(tableHeader.map(column => column.title), bindReportInschoolSeassion);
  };





  const downloadPDF = () => {
    const doc = new jsPDF(); 
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, 'JPEG', 10, 10, logoWidth, logoHeight);

    //const rows = searchInput.trim() === "" ? bindReportInschoolSeassion : searchData;

    doc.autoTable({
      head: [tableHeader.map(column => column.title)],
      startY: 30,
      styles: {
        fontSize: 8, fillColor: "#2d2d6e",
        textColor: [255, 255, 255], lineColor: "black",
        lineWidth: 0.5,
      },

    });

    const nextSectionY = doc.autoTable.previous.finalY;

    doc.autoTable({
      body: bindReportInschoolSeassion.map((rowData, index) => {
        return [
          index + 1,
          rowData.SchoolId,
          rowData.StudentName,
          rowData.StudentId,
          rowData.Class,
          rowData.Division,
          rowData.Age,
          rowData.Gender,
          rowData["1)Gender"],
          rowData["2)Sexual and Reproductive Health"],
          rowData["3)Sexual Rights and Sexual Citizenship"],
          rowData["4)Pleasure"],
          rowData["5)Violence"],
          rowData["6)Diversity"],
          rowData["7)Relationships"],
          rowData.AllTopicCovered,
          rowData.CountTopic,
        ];
      }),
      styles: {
        fontSize: 8, lineColor: "black",
        lineWidth: 0.5,
      },
      startY: nextSectionY,

    });

    doc.save('CSEInSchoolSessionReport.pdf');
  };


  return (
    <MainLayout pageName="4">
      <div class="relative mt-6 bg-white rounded-lg shadow-2xl shadow-gray-400  ">
        <div class="px-16 mt-12 py-4">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`CSE - In School Session Report`}</div>
          </div>
          <div className={mainTable.grid4}>
            <DropDown
              title="Year"
              classStar="text-red-500"
              options={bindYearDD}
              placeholder="Select"
              name="Year"
              selectedOption={year}
              controlFunc={(e) => {
                setYear(e.target.value);
                handleYearChange(e);
              }}
            />
            <AutoDropDown
              classDiv=""
              Class="Custom-class"
              title={`School Name`}
              options={bindSchoolDD}
              classStar="text-red-500"
              placeholder="Select"
              name="mstSchoolId"
              selectedOption={mstSchoolId}
              controlFunc={(value) => {
                setMstSchoolId(value); // Update the selected School Name
                handleSchoolNameChange(value); // Call your custom function
              }}
            />
            <AutoDropDown
              classDiv=""
              Class="Custom-class"
              title={`Class`}
              options={bindClassDD}
              classStar="text-red-500"
              placeholder="Select"
              selectedOption={cseClass}
              controlFunc={(value) => {
                setCseClass(value)
                handleClassNameChange(value)
              }}
            />
            <AutoDropDown
              classDiv=""
              Class="Custom-class"
              title={`Division`}
              options={bindDivisionDD}
              classStar="text-red-500"
              placeholder="Select"
              name="Division"
              selectedOption={division}
              controlFunc={(value) => {
                setDivision(value);
                handleDivisionChange(value);
              }}
            />
          </div>
          <div class={mainTable.grid3}>
            {/* <div class="grid justify-end">
              <label class={mainTable.label}>Category</label>
            </div>
            <div class="grid col-span-2">
              <Select options={bindMaterialDD} class={mainTable.input} />
            </div> */}
            <Checkbox
              classDiv="col-span-4 mb-2"
              title="Topics"
              classStar="text-red-500"
              type={"checkbox"}
              setName={"module"}
              selectedOptions={selectedTopics}
              options={bindTopics}
              controlFunc={handleTopicsChange}
              disabled={true}
              tabIndex="0"
            />
            <InputText
              inputType="date"
              title="From"
              name="fromDate"
              content={fromDate}
              controlFunc={(e) => setFromDate(e.target.value)}
            />
            <InputText
              inputType="date"
              title="To"
              name="toDate"
              content={toDate}
              controlFunc={(e) => setToDate(e.target.value)}
            />
            {/* <AutoDropDown
                      title="From"
                      options={bindMonth}
                      placeholder="Select"
                      name="from"
                      controlFunc={(value) => {
                        setFrom(value.value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="To"
                      options={bindMonth}
                      placeholder="Select"
                      name="to"
                      controlFunc={(value) => {
                        setTo(value.value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    /> */}
            {/* <InputText
                      inputType="date"
                      title="From Date"
                      name="date"
                      // value={appoinmentDate}
                      // onChange={(e) => setAppoinmentDate(e.target.value)}
                    />
                    <InputText
                      inputType="datetime-local"
                      title="To Date"
                      name="date"
                      // value={appoinmentDate}
                      // onChange={(e) => setAppoinmentDate(e.target.value)}
                    /> */}
            {/* <div class="grid justify-end">
              <label class={mainTable.label}>From Date</label>
            </div>
            <div class="grid col-span-2">
              <input type="Date" name="from Date" class={mainTable.input} />
            </div>
            <div class="grid justify-end">
              <label class={mainTable.label}>To Date</label>
            </div>
            <div class="grid col-span-2">
              <input type="Date" name="from Date" class={mainTable.input} />
            </div> */}
            <div class="flex justify-start items-center mt-6">
              <button
                type="button"
                onClick={() => GoButton()}
                class="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
              >
                GO
              </button>
            </div>
          </div>
        </div>
        {showTable && (
          <div className=" ">
            <ReportGridPage
              title="Report In School Session"
              header={tableHeader}
              records={bindReportInschoolSeassion}
              handleExport={handleExport}
              handlePDF={downloadPDF}
            />

          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default CSEReport;
