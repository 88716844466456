import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { eventRegisterHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import TextArea from "../ReusableComponents/TextArea";
import InputText from "../ReusableComponents/InputText";
import { TrainingMonthlyPopup } from "../popup-containers/TrainingMonthlyPopup";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function TrainingMonthly() {
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const [bindEventRegisterData, setBindEventRegisterData] = useState([]);
  const [bindEventMonthlyChildData, setBindEventMonthlyChildData] = useState(
    []
  );
  const [txnEventRegisterId, setTxnEventRegisterId] = useState("");
  const [eventData, setEventData] = useState({});
  const [bindMonthDD, setBindMonthDD] = useState([]);
  const [bindYearDD, setBindYearDD] = useState([]);
  const [visibleSchoolsEnrolled, setVisibleSchoolsEnrolled] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [servicesNameDatas, setServicesNameDatas] = useState([]);
  const userProfileDatas = userProfileState.userProfile;
  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  var createdBy = sessionStorage.getItem("createdBy");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const [eventRegisterFields, setEventRegisterFields] = useState({
    month: "",
    year: "",
    noOfYouthGroupsFormed: "",
    noOfYouthGroupsCompleted: "",
    eventTeacherTraining: "",
    eventNGOCBOStaffTraining: "",
    eventPETraining: "",
    eventPeerToPeerTraining: "",
    eventParents: "",
    eventTeachers: "",
    eventNameOfTheNGOCBO: "",
    eventNoOfSchoolsEnrolled: "",
    eventNoOfSchoolsCompleted: "",
    eventRemarks: "",
    branch: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [eventRegisterFieldsError, setEventRegisterFieldsError] = useState({
    monthError: "",
    yearError: "",
    noOfYouthGroupsFormedError: "",
    eventTeacherTrainingError: "",
    eventNGOCBOStaffTrainingError: "",
    eventPETrainingError: "",
    eventPeerToPeerTrainingError: "",
    eventParentsError: "",
    eventTeachersError: "",
    eventNameOfTheNGOCBOError: "",
    eventNameOfNGOError: "",
    eventNoOfSchoolsEnrolledError: "",
    eventNoOfSchoolsCompletedError: "",
    noOfYouthGroupsCompletedError: "",
    totalError: "",
  });

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [editMode, setEditMode] = useState([]);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [nameOfTheNGOCBO, setNameOfTheNGOCBO] = useState([]);

  const [boys, setBoys] = useState("");
  const [girls, setGirls] = useState("");
  const [other, setOther] = useState("");
  const [mixed, setMixed] = useState("");
  const [total, setTotal] = useState("");
  const [handleInput, setHandleInput] = useState("");

  useEffect(() => {
    handleInputChange(handleInput);
  }, [handleInput]);

  useEffect(() => {
    
    if (boys !== "" && girls !== "" && other !== "" && mixed !== ""){
      const totalChilds =
      (parseInt(boys, 10) || 0) +
      (parseInt(girls, 10) || 0) +
      (parseInt(other, 10) || 0) +
      (parseInt(mixed, 10) || 0);
    setTotal(totalChilds > 0 ? totalChilds : 0); // Set total only if greater than 0
    }
  }, [boys, girls, other, mixed]);

  const showAdd = () => {
    getBranch(userProfileDatas);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setSubmitBtnDisabled(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    handleOpen(false);
    setTxnEventRegisterId("");
    setUpdateScreenTitle(false);
    setAddScreenTitle(false);
    bindEventRegisterDatas(userProfileDatas);
    setVisibleSchoolsEnrolled(false);
    setEventRegisterFields({
      eventDate: "",
      eventStartTime: "",
      eventEndTime: "",
      eventVenue: "",
      resourceName: "",
      eventTeacherTraining: "",
      eventNGOCBOStaffTraining: "",
      eventPETraining: "",
      eventPeerToPeerTraining: "",
      eventParents: "",
      eventTeachers: "",
      eventNameOfTheNGOCBO: "",
      eventNoOfSchoolsEnrolled: "",
      eventNoOfSchoolsCompleted: "",
      eventRemarks: "",
      branch: "",
    });
    setNameOfTheNGOCBO([]);
    setEventRegisterFieldsError({
      eventVenueError: "",
      resourceNameError: "",
      eventTeacherTrainingError: "",
      eventNGOCBOStaffTrainingError: "",
      eventPETrainingError: "",
      eventPeerToPeerTrainingError: "",
      eventParentsError: "",
      eventTeachersError: "",
      eventNameOfTheNGOCBOError: "",
      eventNoOfSchoolsEnrolledError: "",
      eventNoOfSchoolsCompletedError: "",
      eventRemarks: "",
      totalError: "",
    });
    setHandleInput("");
    setBoys("");
    setGirls("");
    setOther("");
    setMixed("");
    setTotal("");
    setInputValues([]);
  };

  const handleOpen = (isOpen) => {
    // Popup open
    setpopUpOpen(isOpen);
  };

  const handleEdit = (rowIndex) => {
    debugger;
    setEditMode(true);
    setAddScreenTitle(false);
    // setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setTxnEventRegisterId(bindEventRegisterData[rowIndex].TxnEventMonthlyId);

    setEventRegisterFields({
      month: bindEventRegisterData[rowIndex].TxnMonthId,
      year: bindEventRegisterData[rowIndex].YearId,
      eventTeacherTraining: bindEventRegisterData[rowIndex].TeacherTraining,
      eventNGOCBOStaffTraining:
        bindEventRegisterData[rowIndex].NGOCBOStaffTraining,
      eventPETraining: bindEventRegisterData[rowIndex].PeerEducatorTrained,
      eventPeerToPeerTraining:
        bindEventRegisterData[rowIndex].PeerToPeerTraining,
      eventParents: bindEventRegisterData[rowIndex].Parents,
      eventTeachers: bindEventRegisterData[rowIndex].Teachers,
      eventNameOfTheNGOCBO:
        bindEventRegisterData[rowIndex].NoOfNGOCBOsPartnered,
      eventNoOfSchoolsEnrolled: bindEventRegisterData[rowIndex].SchoolsEnrolled,
      eventNoOfSchoolsCompleted:
        bindEventRegisterData[rowIndex].SchoolsCompleted,
      branch: parseInt(bindEventRegisterData[rowIndex].MstBranchId),
      eventRemarks: bindEventRegisterData[rowIndex].Remarks,
    });

    setBoys(bindEventRegisterData[rowIndex].YouthGroupsFormedBoys);
    setGirls(bindEventRegisterData[rowIndex].YouthGroupsFormedGirls);
    setOther(bindEventRegisterData[rowIndex].YouthGroupsFormedOthers);
    setMixed(bindEventRegisterData[rowIndex].YouthGroupsFormedMixed);
    setTotal(bindEventRegisterData[rowIndex].YouthGroupsFormedTotal);
    schoolsEnrolled(bindEventRegisterData[rowIndex].TxnMonthId);
    setHandleInput(bindEventRegisterData[rowIndex].NoOfNGOCBOsPartnered);

    const eventMonthlyFilteredData = Object.values(
      bindEventMonthlyChildData
    ).filter(
      (item) =>
        item.TxnEventMonthlyId ==
        bindEventRegisterData[rowIndex].TxnEventMonthlyId
    );

    const servicesNameData = eventMonthlyFilteredData.map((item) => ({
      NameOfTheNGOCBO: item.NameOfTheNGOCBO,
    }));

    const updateNameOfTheNGOCBO = (data) => {
      setNameOfTheNGOCBO([...nameOfTheNGOCBO, ...data]);
    };

    updateNameOfTheNGOCBO(servicesNameData);
  };

  const HandleEventDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }

    setEventData(bindEventRegisterData[rowIndex]);
    setTxnEventRegisterId(bindEventRegisterData[rowIndex].TxnEventRegisterId);

    const MonthrowData = bindEventRegisterData[rowIndex];
    const MonthInfo = bindMonthDD.find(
      (L) => L.value === MonthrowData.TxnMonthId
    );
    const ResultMonth = {
      value: MonthrowData.TxnMonthId,
      label: MonthInfo ? MonthInfo.label : "DefaultStateLabel",
    };

    const YearrowData = bindEventRegisterData[rowIndex];
    const YearInfo = bindYearDD.find((L) => L.value === MonthrowData.year);
    const ResultYear = {
      value: YearrowData.ERYearIdId,
      label: YearInfo ? YearInfo.label : "DefaultStateLabel",
    };

    const eventMonthlyFilteredData = Object.values(
      bindEventMonthlyChildData
    ).filter(
      (item) =>
        item.TxnEventMonthlyId ==
        bindEventRegisterData[rowIndex].TxnEventMonthlyId
    );

    const servicesNameData = eventMonthlyFilteredData.map((item) => ({
      NameOfTheNGOCBO: item.NameOfTheNGOCBO,
    }));

    const autoDropDownValues = [ResultYear, ResultMonth, servicesNameData];
    setPopUpDropDownData(autoDropDownValues);
  };

  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the Month input
      if (!eventRegisterFields.month) {
        errors.month = "Select Month";
      }

      // Validation logic for the Month input
      if (!eventRegisterFields.year) {
        errors.year = "Select Year";
      }

      // Validation logic for the eventTeacherTraining input
      if (eventRegisterFields.eventTeacherTraining === null || 
        eventRegisterFields.eventTeacherTraining === undefined || 
        eventRegisterFields.eventTeacherTraining === '') {
        errors.eventTeacherTraining = "Enter No. Of Teacher Training";
      }

      // Validation logic for the eventNGOCBOStaffTraining input
      if (eventRegisterFields.eventNGOCBOStaffTraining === null || 
        eventRegisterFields.eventNGOCBOStaffTraining === undefined || 
        eventRegisterFields.eventNGOCBOStaffTraining === '') {
        errors.eventNGOCBOStaffTraining = "Enter No. Of NGO/CBO Staff Training";
      }

      // Validation logic for the eventPETraining input
      if (eventRegisterFields.eventPETraining === null || 
        eventRegisterFields.eventPETraining === undefined || 
        eventRegisterFields.eventPETraining === '') {
        errors.eventPETraining = "Enter No. Of PE Training";
      }

      // Validation logic for the eventPeerToPeerTraining input
      if (eventRegisterFields.eventPeerToPeerTraining === null || 
        eventRegisterFields.eventPeerToPeerTraining === undefined || 
        eventRegisterFields.eventPeerToPeerTraining === '') {
        errors.eventPeerToPeerTraining = "Enter No. Of Peer-To-Peer Training";
      }

      // Validation logic for the eventParents input
      if (eventRegisterFields.eventParents === null || 
        eventRegisterFields.eventParents === undefined || 
        eventRegisterFields.eventParents === '') {
        errors.eventParents = "Enter No. Of Parents";
      }

      // Validation logic for the eventTeachers input
      if (eventRegisterFields.eventTeachers === null || 
        eventRegisterFields.eventTeachers === undefined || 
        eventRegisterFields.eventTeachers === '') {
        errors.eventTeachers = "Enter No. Of Teachers";
      }

      // Validation logic for the eventNameOfTheNGOCBO input
      if (eventRegisterFields.eventNameOfTheNGOCBO === null || 
        eventRegisterFields.eventNameOfTheNGOCBO === undefined || 
        eventRegisterFields.eventNameOfTheNGOCBO === '') {
        errors.eventNameOfTheNGOCBO = "Enter No. Of NGO/CBOs Partnered";
      } else if (
        !/^(?:[0-9]|10)$/.test(
          parseInt(eventRegisterFields.eventNameOfTheNGOCBO, 10)
        )
      ) {
        errors.eventNameOfTheNGOCBO = "Please enter a number between 0 and 10";
      }
      if (eventRegisterFields.eventNameOfTheNGOCBO > 0) {
        if (
          parseInt(eventRegisterFields.eventNameOfTheNGOCBO, 10) !==
          nameOfTheNGOCBO.length
        ) {
          errors.NameOfNGO = "Enter all NGO/CBOs Partner names";
        }
        // nameOfTheNGOCBO.forEach((item, index) => {
        //   if (!item?.NameOfTheNGOCBO?.trim()) {
        //     errors.NameOfNGO =
        //       "Enter all NGO/CBOs Partnered names";
        //   }
        // });
      }

      // Validation logic for the eventNoOfSchoolsEnrolled input
      if (visibleSchoolsEnrolled) {
        if (eventRegisterFields.eventNoOfSchoolsEnrolled === null || 
          eventRegisterFields.eventNoOfSchoolsEnrolled === undefined || 
          eventRegisterFields.eventNoOfSchoolsEnrolled === '') {
          errors.eventNoOfSchoolsEnrolled = "Enter No. Of Schools Enrolled";
        }
      }

      // Validation logic for the eventNoOfSchoolsCompleted input
      if (eventRegisterFields.eventNoOfSchoolsCompleted === null || 
        eventRegisterFields.eventNoOfSchoolsCompleted === undefined || 
        eventRegisterFields.eventNoOfSchoolsCompleted === '') {
        errors.eventNoOfSchoolsCompleted = "Enter No. Of Schools Completed";
      }

      if (total === "") {
        errors.total = "Enter No. Of Youth Groups Formed";
      }
    }

    setEventRegisterFieldsError({
      ...eventRegisterFieldsError,
      monthError: errors.month || "",
      yearError: errors.year || "",
      eventTeacherTrainingError: errors.eventTeacherTraining || "",
      eventNGOCBOStaffTrainingError: errors.eventNGOCBOStaffTraining || "",
      eventPETrainingError: errors.eventPETraining || "",
      eventPeerToPeerTrainingError: errors.eventPeerToPeerTraining || "",
      eventParentsError: errors.eventParents || "",
      eventTeachersError: errors.eventTeachers || "",
      eventNameOfTheNGOCBOError: errors.eventNameOfTheNGOCBO || "",
      eventNameOfNGOError: errors.NameOfNGO || "",
      eventNoOfSchoolsEnrolledError: errors.eventNoOfSchoolsEnrolled || "",
      eventNoOfSchoolsCompletedError: errors.eventNoOfSchoolsCompleted || "",
      totalError: errors.total || "",
    });
    debugger;
    return errors; // Return the errors object containing validation errors (if any)
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    if (flag === 1 || flag === 2) {
      const firstNameValidation = validate(flag);

      if (Object.keys(firstNameValidation).length === 0) {
        setIsOpen(false);
        const data = {
          txnEventMonthlyId: txnEventRegisterId ? txnEventRegisterId : 0,
          mstBranchId: eventRegisterFields.branch
            ? eventRegisterFields.branch
            : 0,
          txnMonthId: eventRegisterFields.month,
          yearId: eventRegisterFields.year,
          teacherTraining: eventRegisterFields.eventTeacherTraining,
          ngocboStaffTraining: eventRegisterFields.eventNGOCBOStaffTraining,
          peerEducatorTrained: eventRegisterFields.eventPETraining,
          peerToPeerTraining: eventRegisterFields.eventPeerToPeerTraining,
          parents: eventRegisterFields.eventParents,
          teachers: eventRegisterFields.eventTeachers,
          noOfNGOCBOsPartnered: eventRegisterFields.eventNameOfTheNGOCBO,
          schoolsEnrolled: eventRegisterFields.eventNoOfSchoolsEnrolled
            ? eventRegisterFields.eventNoOfSchoolsEnrolled
            : 0,
          schoolsCompleted: eventRegisterFields.eventNoOfSchoolsCompleted,
          youthGroupsFormedBoys: boys ? boys : 0,
          youthGroupsFormedGirls: girls ? girls : 0,
          youthGroupsFormedOthers: other ? other : 0,
          youthGroupsFormedMixed: mixed ? mixed : 0,
          youthGroupsFormedTotal: total ? total : 0,
          eventMonthlyChildData: JSON.stringify(nameOfTheNGOCBO),
          remarks: eventRegisterFields.eventRemarks,
          createdBy: createdBy,
          flag: flag,
        };
        console.log("eventdata", data);
        const response = await IUDAPI(
          [data],
          "EventMonthlyAPI/IUDEventMonthly"
        );
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("Event (monthly) data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Event (monthly) data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("Event (monthly) data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Event (monthly) data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
        setIsOpen(true);
      }
    } else {
      const data = {
        txnEventMonthlyId: bindEventRegisterData[rowIndex].TxnEventMonthlyId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "EventMonthlyAPI/IUDEventMonthly");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("Event (monthly) data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Event (monthly) data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("Event (monthly) data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Event (monthly) data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("Event (monthly) data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Event (monthly) data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  useEffect(() => {
    bindEventRegisterDatas(userProfileDatas);
  }, []);
  const getBranch = (userProfileDatas) => {
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setEventRegisterFields({
        ...eventRegisterFields,
        branch: userProfileDatas[0].MstLocationId,
      });
    } else {
      // handle else case if needed
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, []);
  // const bindEventRegisterDatas = async () => {
  //   const response = await BindAPI("EventMonthlyAPI/BindEventMonthly");
  //   setBindEventRegisterData(response.data.Table);
  //   setBindEventMonthlyChildData(response.data.Table1);
  // };
  const bindEventRegisterDatas = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      } else {
        // handle else case if needed
      }
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "EventMonthlyAPI/BindEventMonthly"
      );
      if (response && response.data && response.data.Table) {
        setBindEventRegisterData(response.data.Table);
        setBindEventMonthlyChildData(response.data.Table1);
      } else {
        setBindEventRegisterData([]);
        setBindEventMonthlyChildData([]);
      }
    }
  };

  const handleEventRegisterFieldsChange = (value, name) => {
    setEventRegisterFields({
      ...eventRegisterFields,
      [name]: value,
    });
  };
  useEffect(() => {
    // Bind data useEffect
    getMonthDDValues();
  }, []);
  const getMonthDDValues = async () => {
    // Month Dropdown binding
    const response = await BindAPI("MasterDataAPI/BindMonth");
    const getTnxMonthId =
      response.data.Table && response.data.Table.length > 0
        ? response.data.Table.map((item) => ({
            value: item.TxnMonthId,
            label: item.MonthName,
          }))
        : [];
    setBindMonthDD(getTnxMonthId);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: 70 },
      (_, index) => currentYear - index
    );
    let yearDropdown = yearsArray.map((year) => ({
      label: year.toString(),
      value: year,
    }));
    setBindYearDD(yearDropdown);
  }, []);

  const schoolsEnrolled = (value) => {
    if (value == "1") {
      setVisibleSchoolsEnrolled(true);
    } else {
      setVisibleSchoolsEnrolled(false);
    }
  };

  const handleInputChange = (value) => {
    setEventRegisterFields({
      ...eventRegisterFields,
      eventNameOfTheNGOCBO: value,
    });

    const numInputs = parseInt(value);
    if (!isNaN(numInputs)) {
      // if (addScreenTitle) {
      setInputValues(new Array(numInputs).fill(""));
      // } else if (updateScreenTitle) {
      //   setInputValues(new Array(numInputs).fill(servicesNameDatas));
      // }
    } else {
      setInputValues([]);
    }
  };

  const handleInputFieldValueChange = (index, e) => {
    const newInputValues = [...nameOfTheNGOCBO];
    newInputValues[index] = {
      ...newInputValues[index], // Preserve other properties
      NameOfTheNGOCBO: e.target.value,
    };
    setNameOfTheNGOCBO(newInputValues);
  };
  console.log("inputValues", inputValues);

  useEffect(() => {
    if (location.pathname == "/trainingmonthly") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      bindEventRegisterDatas(userProfileDatas);
    }
  }, [location, userProfileDatas]);
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Events (monthly)"
          header={eventRegisterHeaders}
          records={bindEventRegisterData}
          handleEdit={handleEdit}
          handleAction={HandleEventDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Events (monthly)"
            />

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  {/* <div class={mainTable.labelBold1}>Event register</div>
                  <hr class={mainTable.horizontalLine}></hr> */}
                  <div className={mainTable.grid3}>
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Month"
                        options={bindMonthDD}
                        placeholder="Select"
                        name="month"
                        selectedOption={bindMonthDD.find(
                          (x) => x.value == eventRegisterFields.month
                        )}
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleEventRegisterFieldsChange(value.value, e.name);
                          schoolsEnrolled(value.value);
                        }}
                        controlFuncBlur={() => {
                          setEventRegisterFieldsError(() => ({
                            ...eventRegisterFieldsError,
                            monthError: eventRegisterFields.month
                              ? ""
                              : "Select Month",
                          }));
                        }}
                        errorMessage={eventRegisterFieldsError.monthError}
                      />
                    </div>
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Year"
                        options={bindYearDD}
                        placeholder="Select"
                        name="year"
                        selectedOption={bindYearDD.find(
                          (x) => x.value == eventRegisterFields.year
                        )}
                        Class="custom-class"
                        tabIndex="1"
                        controlFunc={(value, e) => {
                          handleEventRegisterFieldsChange(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setEventRegisterFieldsError(() => ({
                            ...eventRegisterFieldsError,
                            yearError: eventRegisterFields.year
                              ? ""
                              : "Select Year",
                          }));
                        }}
                        errorMessage={eventRegisterFieldsError.yearError}
                      />
                    </div>
                  </div>
                  <div class={mainTable.labelBold1}>
                    Training by FPAI / Volunteer (no. of participants)
                  </div>
                  <hr class={mainTable.horizontalLine}></hr>
                  <div className={mainTable.grid3}>
                    <div>
                      <InputText
                        inputType="text"
                        title="Teacher Training"
                        maxLength={7}
                        classStar="text-red-500"
                        name="schoolName"
                        content={eventRegisterFields.eventTeacherTraining}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventTeacherTraining: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventTeacherTrainingError:
                                eventRegisterFields.eventTeacherTraining
                                  .length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventTeacherTraining
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of Teacher Training",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventTeacherTrainingError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="NGO/CBO Staff Training"
                        classStar="text-red-500"
                        maxLength={7}
                        name="schoolName"
                        content={eventRegisterFields.eventNGOCBOStaffTraining}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventNGOCBOStaffTraining: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventNGOCBOStaffTrainingError:
                                eventRegisterFields.eventNGOCBOStaffTraining
                                  .length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventNGOCBOStaffTraining
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of NGO/CBO Staff Training",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventNGOCBOStaffTrainingError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Peer Educator Trained (Directly / ToT)"
                        maxLength={7}
                        name="schoolName"
                        content={eventRegisterFields.eventPETraining}
                        classStar="text-red-500"
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventPETraining: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventPETrainingError:
                                eventRegisterFields.eventPETraining.length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventPETraining
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of PE Training",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventPETrainingError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        maxLength={7}
                        classStar="text-red-500"
                        title="Peer-to-Peer Training "
                        name="schoolName"
                        content={eventRegisterFields.eventPeerToPeerTraining}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventPeerToPeerTraining: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventPeerToPeerTrainingError:
                                eventRegisterFields.eventPeerToPeerTraining
                                  .length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventPeerToPeerTraining
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of Peer-To-Peer Training",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventPeerToPeerTrainingError
                        }
                      />
                    </div>
                  </div>
                  <div class={mainTable.labelBold1}>
                    Sensitization Meeting with (no. of participants)
                  </div>
                  <hr class={mainTable.horizontalLine}></hr>
                  <div className={mainTable.grid3}>
                    <div>
                      <InputText
                        inputType="text"
                        title="Parents "
                        maxLength={7}
                        classStar="text-red-500"
                        name="schoolName"
                        content={eventRegisterFields.eventParents}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventParents: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventParentsError:
                                eventRegisterFields.eventParents.length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventParents
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of Parents",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventParentsError
                        }
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Teachers"
                        maxLength={7}
                        classStar="text-red-500"
                        name="schoolName"
                        content={eventRegisterFields.eventTeachers}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventTeachers: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventTeachersError:
                                eventRegisterFields.eventTeachers.length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventTeachers
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of Teachers",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventTeachersError
                        }
                      />
                    </div>
                  </div>
                  <div class={mainTable.labelBold1}>NGO/CBOs Partner</div>
                  <hr class={mainTable.horizontalLine}></hr>
                  <div className={mainTable.grid3}>
                    <InputText
                      inputType="text"
                      title="No. Of NGO/CBOs Partnered"
                      maxLength={2}
                      classStar="text-red-500"
                      name="schoolName"
                      content={eventRegisterFields.eventNameOfTheNGOCBO}
                      controlFunc={(e) => handleInputChange(e.target.value)}
                      controlFuncBlur={(e) => {
                        setEventRegisterFieldsError(
                          (eventRegisterFieldsError) => ({
                            ...eventRegisterFieldsError,
                            eventNameOfTheNGOCBOError:
                              eventRegisterFields.eventNameOfTheNGOCBO.length >
                              0
                                ? /^(?:[0-9]|10)$/.test(
                                    eventRegisterFields.eventNameOfTheNGOCBO
                                  )
                                  ? ""
                                  : "Please enter a number between 0 and 10"
                                : "Enter No. Of NGO/CBOs Partnered",
                          })
                        );
                      }}
                      errorMessage={
                        eventRegisterFieldsError.eventNameOfTheNGOCBOError
                      }
                    />
                    {inputValues.map((value, index) => (
                      <div>
                      <InputText
                        key={index}
                        inputType="text"
                        title="Name of the NGO/CBO"
                        maxLength={100}
                        classStar="text-red-500"
                        name="schoolName"
                        content={nameOfTheNGOCBO[index]?.NameOfTheNGOCBO}
                        controlFunc={(e) =>
                          handleInputFieldValueChange(index, e)
                        }
                      />
                      <label className="text-red-500 text-sm font-medium">
                      {eventRegisterFieldsError.eventNameOfNGOError &&
                      eventRegisterFieldsError.eventNameOfNGOError != ""
                        ? eventRegisterFieldsError.eventNameOfNGOError
                        : ""}
                    </label>
                      </div>
                    ))}
                    
                  </div>
                  <div className={mainTable.grid3}>
                    {visibleSchoolsEnrolled && (
                      <div>
                        <InputText
                          inputType="text"
                          title="No. Of Schools Enrolled (Yearly In Jan.)"
                          classStar="text-red-500"
                          maxLength={7}
                          name="schoolName"
                          content={eventRegisterFields.eventNoOfSchoolsEnrolled}
                          controlFunc={(e) =>
                            setEventRegisterFields({
                              ...eventRegisterFields,
                              eventNoOfSchoolsEnrolled: e.target.value,
                            })
                          }
                          controlFuncBlur={(e) => {
                            setEventRegisterFieldsError(
                              (eventRegisterFieldsError) => ({
                                ...eventRegisterFieldsError,
                                eventNoOfSchoolsEnrolledError:
                                  eventRegisterFields.eventNoOfSchoolsEnrolled
                                    .length > 0
                                    ? /^[0-9]+$/.test(
                                        eventRegisterFields.eventNoOfSchoolsEnrolled
                                      )
                                      ? ""
                                      : "Only numbers allowed"
                                    : "Enter No. Of Schools Enrolled",
                              })
                            );
                          }}
                          errorMessage={
                            eventRegisterFieldsError.eventNoOfSchoolsEnrolledError
                          }
                        />
                      </div>
                    )}
                    <div>
                      <InputText
                        inputType="text"
                        title="No. of Schools Completed  "
                        classStar="text-red-500"
                        maxLength={7}
                        name="schoolName"
                        content={eventRegisterFields.eventNoOfSchoolsCompleted}
                        controlFunc={(e) =>
                          setEventRegisterFields({
                            ...eventRegisterFields,
                            eventNoOfSchoolsCompleted: e.target.value,
                          })
                        }
                        controlFuncBlur={(e) => {
                          setEventRegisterFieldsError(
                            (eventRegisterFieldsError) => ({
                              ...eventRegisterFieldsError,
                              eventNoOfSchoolsCompletedError:
                                eventRegisterFields.eventNoOfSchoolsCompleted
                                  .length > 0
                                  ? /^[0-9]+$/.test(
                                      eventRegisterFields.eventNoOfSchoolsCompleted
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter No. Of Schools Completed",
                            })
                          );
                        }}
                        errorMessage={
                          eventRegisterFieldsError.eventNoOfSchoolsCompletedError
                        }
                      />
                    </div>

                    <div className="col-span-1">
                      <label for="client_name" class={mainTable.label}>
                        No. Of Youth Groups Formed
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="grid gap-2 md:grid-cols-5 flex items-center bg-gray-50 p-1 border border-gray-300 rounded-lg">
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title="Boys"
                          name="boys"
                          maxLength={5}
                          content={boys}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseInt(value, 10);
                            setBoys(isNaN(parsedValue) ? 0 : parsedValue);
                          }}
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Girls"}
                          name={"girls"}
                          maxLength={5}
                          content={girls}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseInt(value, 10);
                            setGirls(isNaN(parsedValue) ? 0 : parsedValue);
                          }}
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Others"}
                          name="other"
                          maxLength={5}
                          content={other}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseInt(value, 10);
                            setOther(isNaN(parsedValue) ? 0 : parsedValue);
                          }}
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Mixed"}
                          maxLength={5}
                          name="mixed"
                          content={mixed}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseInt(value, 10);
                            setMixed(isNaN(parsedValue) ? 0 : parsedValue);
                          }}
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"total"}
                          name={"total"}
                          maxLength={6}
                          content={total}
                          readOnly={true}
                          controlFunc={(e) => setTotal(e.target.value)}
                        />
                      </div>
                      {eventRegisterFieldsError.totalError && (
                        <span class="text-red-500 text-sm font-medium">
                          {eventRegisterFieldsError.totalError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="lg:col-span-4 sm:col-span-1">
                    <TextArea
                      inputType="text"
                      title="Remarks"
                      name="remarks"
                      maxLength={500}
                      rows="4"
                      content={eventRegisterFields.eventRemarks}
                      placeholder="Remarks"
                      controlFunc={(e) =>
                        setEventRegisterFields({
                          ...eventRegisterFields,
                          eventRemarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleEventDetails}
      >
        <div>
          <PopUpScreenTitle
            title="Events (monthly)"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteButton}
            blockDetails={blockButton}
            unBlockDetails={unBlockButton}
          />

          <TrainingMonthlyPopup data={eventData} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteButton}
          blockDetails={blockButton}
          unBlockDetails={unBlockButton}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default TrainingMonthly;
