import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";

const School = ({ data, data2 }) => {
  return (
    <div class="w-full ">
      {/* <div class=" border-black  ">
      <div class="text-black text-2xl font-bold mx-4">
        <h3 className={mainTable.addScreenTitle}>
          {`Commodities Master - View`}
        </h3>
      </div>
    </div> */}
      <div className={mainTable.addShadowScreen}>
        <div className={mainTable.mainContentScreen}>
          <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
            <div>
              {/* <div class={mainTable.labelBold1}>School Group</div>
                <hr class={mainTable.horizontalLine}></hr> */}
              <div className={mainTable.grid3}>
                <InputText
                  inputType="text"
                  title="School / College Id"
                  name="schoolID"
                  classStar="text-red-500"
                  disabled="disabled"
                  content={data.SchoolId}
                />
                <InputText
                  inputType="text"
                  title="Name of the School / College"
                  classStar="text-red-500"
                  name="schoolName"
                  disabled="disabled"
                  content={data.SchoolName}
                  classLabel={mainTable.labelNoWrap}
                />
                <DropDown
                  classDiv="hidden"
                  title="Country"
                  placeholder="Select"
                  classStar="text-red-500"
                  options={[]}
                  name="country"
                />
                <AutoDropDown
                  title="State"
                  classStar="text-red-500"
                  placeholder="Select"
                  options={[]}
                  name="state"
                  selectedOption={data2[0]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Branch/Project"
                  placeholder="Select"
                  name="state"
                  classStar="text-red-500"
                  options={[]}
                  selectedOption={data2[1]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />
                <InputText
                  inputType={"text"}
                  title={"Venue"}
                  classStar="text-red-500"
                  name={"address"}
                  // ClassDiv="col-span-3"
                  placeholder="Enter the Venue"
                  disabled="disabled"
                  content={data.SchoolVenue}
                />
                <InputText
                  inputType={"text"}
                  title={"Contact Person name"}
                  name={"contactPerson"}
                  disabled="disabled"
                  classStar="text-red-500"
                  content={data.SchoolContactPerson}
                />
                <div className="col-span-3">
                  <InputText
                    inputType={"text"}
                    title={"School / College Address"}
                    name={"address"}
                    classStar="text-red-500"
                    // ClassDiv="col-span-3"
                    placeholder="7/33,first street"
                    disabled="disabled"
                    content={data.SchoolAddress}
                  />
                </div>
                <InputText
                  inputType={"text"}
                  title={"Mobile Number"}
                  name={"phone"}
                  disabled="disabled"
                  classStar="text-red-500"
                  content={data.SchoolPhoneNumber}
                />
                <InputText
                  inputType={"text"}
                  title={"Email Id"}
                  name={"email"}
                  disabled="disabled"
                  content={data.SchoolEmail}
                />
                <div class="md:col-span-3 lg:col-span-3 sm:col-span-1">
                  <TextArea
                    inputType="text"
                    title="Remarks"
                    name="remarks"
                    rows="4"
                    disabled="disabled"
                    content={data.SchoolRemarks}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { School };
