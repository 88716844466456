// Code review - In Progress
// Documentation - In Progress
import React, { useEffect } from "react";
import { mainTable } from "../css/Common";
import { useState } from "react";
import TableGrid from "../components/Table";
import { languageMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import {
  languageAddApi,
  languageGetApi,
} from "../services/LanguageAPIServices";
import MainPopup from "../popup-containers/MainPopup";
import LanguagePopup from "../popup-containers/LanguagePopup";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const State = () => {
  const location = useLocation();
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [bindLanguageMasterDatas, setBindLanguageMasterDatas] = useState([]);

  const [language, setLanguage] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const [mstLanguageId, setMstLanguageId] = useState("");
  const [languageData, setLanguageData] = useState("");

  //language Error States

  const [languageError, setLanguageError] = useState("");
  const [languageCodeError, setLanguageCodeError] = useState("");

  //popup title state

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    getLanguageData();
  }, []);
  const getLanguageData = async () => {
    const response = await BindAPI("LanguageAPI/GetLanguage");
    setBindLanguageMasterDatas(response.data.Table);
  };

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    getLanguageData();
    setVisibleAdd(false);
    setVisibleTable(true);
    handleOpen(false);
    setSubmitBtnDisabled(false);
    setLanguageFields({
      language: "",
      languageCode: "",
    });

    setLanguageFieldsError({
      languageError: "",
      languageCodeError: "",
    });
    setLanguage("");
    setLanguageCode("");
    setLanguageError("");
    setLanguageCodeError("");
  };
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);

  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const handleDropDown = (event) => {
    // To set Number of records per page
    const selectedValue = parseInt(event.target.value); // Convert to integer
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  //Value store States
  const [languageFields, setLanguageFields] = useState({
    language: "",
    languageCode: "",
  });

  const [languageFieldsError, setLanguageFieldsError] = useState({
    languageError: "",
    languageCodeError: "",
  });

  const validate = (flag) => {
    
    const errors = {};
    if (flag === 1 || flag === 2) {
      if (!languageFields.language.trim()) {
        errors.language = "Enter language";
      } else if (!/^[A-Za-z ]+$/.test(languageFields.language)) {
        errors.language = "Only alphabet and space allowed";
      }
      if (!languageFields.languageCode.trim()) {
        errors.languageCode = "Enter language Code";
      } else if (
        !/^[a-z]{2}(-[A-Za-z ]{2})?$/.test(languageFields.languageCode)
      ) {
        errors.languageCode = "Enter valid language code";
      }
    }
    setLanguageFieldsError({
      ...languageFieldsError,
      languageError: errors.language || "",
      languageCodeError: errors.languageCode || "",
    });
    // setLanguageError(errors.language || "");
    // setLanguageCodeError(errors.languageCode || "");
    return errors;
  };

  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    const validation = validate(flag);
    if(Object.keys(validation).length !== 0){
      setIsOpen(true);
    }
    if (Object.keys(validation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1) {
        const languagePayload = [
          {
            languageKey: languageFields.languageCode,
            language: languageFields.language,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(languagePayload, "LanguageAPI/IUDLanguage");
        if (response.data.Table[0].Result == "S") {
          toast.success("Language added successfully! 👍", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          getLanguageData();
        } else {
          toast.error(
            " Language addition failed. Please review the details and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 2) {
        const languagePayload = [
          {
            mstLanguageId: mstLanguageId,
            languageKey: languageFields.languageCode,
            language: languageFields.language,
            createdBy: createdBy,
            flag: flag,
          },
        ];

        const response = await IUDAPI(languagePayload, "LanguageAPI/IUDLanguage");
        if (response.data.Table[0].Result == "M") {
          toast.success("Language Modified successfully! 👍", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          getLanguageData();
        } else {
          toast.error(
            " Language modify failed. Please review the details and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else {
        const languagePayload = [
          {
            mstLanguageId: bindLanguageMasterDatas[items].MstLanguageId,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(languagePayload, "LanguageAPI/IUDLanguage");
        if (flag == 3) {
          if (response.data.Table[0].Result == "D") {
            toast.success("Deletion successful! 🗑️", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            getLanguageData();
          } else {
            toast.error(
              " Language delete failed. Please review the details and try again. ❌",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result == "B") {
            toast.success("Language blocked successfully. 🔒", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            getLanguageData();
          } else {
            toast.error(
              "Language block failed. Please review the details and try again.",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result == "UB") {
            toast.success("Language unblocked successfully. 🔓", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            getLanguageData();
          } else {
            toast.error(
              "Language unblocked failed. Please review the details and try again.",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const handleEdit = (rowIndex) => {
    setAddScreenTitle(false);
    setUpdateScreenTitle(true);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    setLanguageFields({
      language: bindLanguageMasterDatas[rowIndex].Language,
      languageCode: bindLanguageMasterDatas[rowIndex].LanguageKey,
    });
    // setLanguage(bindLanguageMasterDatas[rowIndex].Language);
    // setLanguageCode(bindLanguageMasterDatas[rowIndex].LanguageKey);
    setMstLanguageId(bindLanguageMasterDatas[rowIndex].MstLanguageId);
    setLanguageData(bindLanguageMasterDatas[rowIndex]);
  };

  const handleClose = () => {
    setpopUpOpen(false);
  };
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  useEffect(() => {
    if (location.pathname == "/languagemaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      getLanguageData();
    }
  }, [location]);
  const handleLanguagePopUp = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }
    setLanguageData(bindLanguageMasterDatas[rowIndex]);
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindLanguageMasterDatas);
  // const handleSearchInputChange = (e) => {
  //   // Search in Grid
  //   const inputValue = e.target.value;
  //   setSearchInput(inputValue);
  //   handleSearch(inputValue);
  // };

  // const handleSearch = (input) => {
  //   const filtered = bindLanguageMasterDatas.filter((item) => {
  //     const providerData = `${item.LanguageKey} ${item.Language}`;
  //     return providerData.toLowerCase().includes(input.toLowerCase());
  //   });
  //   setFilteredData(filtered);
  // };

  return (
    <MainLayout pageName="2">
     
      {visibleTable && (
        <GridPage
          title="Language Master"
          header={languageMasterHeader}
          records={bindLanguageMasterDatas}
          // currentPage={currentPage}
          // itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleAction={handleLanguagePopUp}
          showAdd={showAdd}
          blockView={true}
        />
        // <div className="">
        //   <div className={mainTable.headerTitle}>
        //     <div className={mainTable.gridTableHeader}>{`Language Master`}</div>
        //   </div>
        //   <div className="relative mt-1 md:hidden">
        //     <div className={mainTable.searchIconPosition}>
        //       <svg
        //         className={mainTable.searchIconSvg}
        //         aria-hidden="true"
        //         fill="currentColor"
        //         viewBox="0 0 20 20"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           fill-rule="evenodd"
        //           d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //           clip-rule="evenodd"
        //         ></path>
        //       </svg>
        //     </div>
        //     <input
        //       type="text"
        //       className={mainTable.searchInputBox}
        //       placeholder="Search for items"
        //       value={searchInput}
        //       onChange={handleSearchInputChange}
        //     />
        //   </div>
        //   <div className="justify-between flex items-center">
        //     <div className={mainTable.searchAdd}>
        //       <div className="mt-1 gap-2 flex justify-between items-baseline">
        //         <label for="pageSize" className="hidden md:block">
        //           Rows Per Page:
        //         </label>
        //         <select
        //           onChange={handleDropDown}
        //           className={mainTable.dropDownRecords}
        //           name="pageSize"
        //         >
        //            <option value="5">5</option>
        //           <option value="10">10</option>
        //           <option value="20">20</option>
        //           <option value="50" selected>
        //             50
        //           </option>
        //           <option value="100">100</option>
        //         </select>
        //       </div>
        //       <div>
        //         <label for="table-search" className="sr-only">
        //           Search
        //         </label>
        //       </div>
        //       <div className="hidden md:block relative mt-1">
        //         <div className={mainTable.searchIconPosition}>
        //           <svg
        //             className={mainTable.searchIconSvg}
        //             aria-hidden="true"
        //             fill="currentColor"
        //             viewBox="0 0 20 20"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               fill-rule="evenodd"
        //               d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //               clip-rule="evenodd"
        //             ></path>
        //           </svg>
        //         </div>
        //         <input
        //           type="text"
        //           className={mainTable.searchInputBox}
        //           placeholder="Search for items"
        //           value={searchInput}
        //           onChange={handleSearchInputChange}
        //         />
        //       </div>
        //     </div>
        //     <div className="flex self-end">
        //       <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
        //         <span className="mb-12 text-red-600 text-7xl">.</span>
        //         <span>Block</span>
        //       </div>
        //       <button
        //         type="button"
        //         className={mainTable.addButton}
        //         onClick={showAdd}
        //       >
        //         <svg
        //           width="25"
        //           height="20"
        //           viewBox="0 0 50 50"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
        //             fill="white"
        //           />
        //         </svg>
        //         ADD
        //       </button>
        //     </div>
        //   </div>
        //   <TableGrid
        //     className={mainTable.table}
        //     headerData={languageMasterHeader}
        //     tableData={
        //       searchData.length > 0 ? searchData : bindLanguageMasterDatas
        //     }
        //     onEditGrid={handleEdit}
        //     onViewGrid={handleLanguagePopUp}
        //     onDeleteGrid={handleLanguagePopUp}
        //     onBlockGrid={handleLanguagePopUp}
        //     currentPage={currentPage}
        //     itemsPerPage={itemsPerPage}
        //   />

        //   <div className={mainTable.submitCancelMainDiv}>
        //     <button
        //       onClick={() => setCurrentPage(currentPage - 1)}
        //       disabled={currentPage === 1}
        //       className={mainTable.submitButton}
        //     >
        //       Previous Page
        //     </button>
        //     <label>{currentPage}</label>
        //     <button
        //       onClick={() => setCurrentPage(currentPage + 1)}
        //       disabled={
        //         currentPage === bindLanguageMasterDatas.length / itemsPerPage
        //       }
        //       className={mainTable.cancelButton}
        //     >
        //       Next Page
        //     </button>
        //   </div>
        // </div>
      )}
      {visibleAdd && (
        <div class="flex flex-col w-full">
          <div class={mainTable.mainAddScreen}>
            {/* {addScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Language Master - Add"}
                </div>
              )}

              {updateScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Language Master - Edit"}
                </div>
              )} */}
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Language Master"
            />
          </div>

          <div class="mx-auto  md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <div class="flex w-full gap-6">
                      <div class="w-full">
                        <div class={`mb-2`}>
                          <InputText
                            inputType="text"
                            title="language"
                            name="language"
                            classStar={"text-red-400"}
                            content={languageFields.language}
                            controlFunc={(e) =>
                              setLanguageFields({
                                ...languageFields,
                                language: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              setLanguageFieldsError((languageFieldsError) => ({
                                ...languageFieldsError,
                                languageError:
                                  languageFields.language &&
                                  languageFields.language.length > 0
                                    ? /^[A-Za-z ]+$/.test(
                                        languageFields.language
                                      )
                                      ? ""
                                      : "Only Alphabet and space allowed"
                                    : "Enter Language",
                              }));
                            }}
                            errorMessage={languageFieldsError.languageError}
                          />
                        </div>
                        <div>
                          <InputText
                            inputType="text"
                            title="Language Code"
                            name="languageCode"
                            classStar={"text-red-400"}
                            content={languageFields.languageCode}
                            controlFunc={(e) =>
                              setLanguageFields({
                                ...languageFields,
                                languageCode: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              setLanguageFieldsError((languageFieldsError) => ({
                                ...languageFieldsError,
                                languageCodeError:
                                  languageFields.languageCode &&
                                  languageFields.languageCode.length > 0
                                    ? /^[a-z]{2}(-[A-Za-z ]{2})?$/.test(
                                        languageFields.languageCode
                                      )
                                      ? ""
                                      : "Enter Valid Language Code"
                                    : "Enter Language Code",
                              }));
                            }}
                            errorMessage={languageFieldsError.languageCodeError}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div class={mainTable.submitCancelMainDiv}>
                      {addScreenTitle && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(0, 1)}
                        >
                          SUBMIT
                        </button>
                      )}
                      {updateScreenTitle && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(0, 2)}
                        >
                          Update
                        </button>
                      )}
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        onClick={cancel}
                        class={mainTable.cancelButton}
                      >
                        CANCEL
                      </button>
                    </div> */}
                    <Buttons
                      addScreenTitle={addScreenTitle}
                      updateScreenTitle={updateScreenTitle}
                      handleSubmit={handleSubmit}
                      cancel={cancel}
                      disabledBtn={submitBtnDisabled ? true : false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
        {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleLanguagePopUp}
      >
        <div>
          <PopUpScreenTitle
            title="Language Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          {/* <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <div class="text-xl font-medium text-neutral-700 mx-5 ">
            {viewDetails && `Language - View`}
            {deleteDetails && `Language - Delete`}
            {blockDetails && `Language - Block`}
            {unBlockDetails && `Language - Unblock`}
          </div> */}
          <LanguagePopup data={languageData} />
        </div>
        {/* <div class={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            class={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div> */}
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default State;
