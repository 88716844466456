import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import {
  clientChildrenDetailsHeaders,
  clientHeaders,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import ClientDetails from "../popup-containers/ClientDetails";
import ClientAddress from "../popup-containers/ClientAddress";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import RadioGroup from "../ReusableComponents/RadioButton";
import Checkbox from "../ReusableComponents/Checkbox";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GridPage from "../components/GridPage";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import TableGrid from "../components/Table";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const ClientMaster = () => {
  const [tableView, setTableView] = useState(true);
  const [addView, setAddView] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [clientFields, setClientFields] = useState({
    clientId: "",
    clientFirstName: "",
    clientMiddleName: "",
    clientLastName: null,
    clientMotherName: "",
    tempDateOfBirth: "",
    clientDateOfBirth: "",
    clientAge: "",
    clientGender: 2,
    clientReligion: 0,
    clientMaritalStatus: 0,
    clientEducation: 0,
    clientOccupation: 0,
    clientRegisterDate: "",
    clientReferralSource: 0,
    clientExistingClientId: "",
    clientPhoneNo: "",
    clientMobilebelongs: 0,
    clientResidence: 0,
    mstCountryId: 0,
    mstStateId: 0,
    mstBranchProjectId: 0,
    mstSDPId: 0,
    mstAreaId: 0,
    clientAddress: "",
    clientPinCode: null,
    clientBPLCardholder: 2,
    clientBloodGroup: null,
    clientUsingAnyFormOfTobaccoProduct: 2,
    clientAlcoholUse: 2,
    clientHeight: null,
    clientWeight: null,
    clientCycleInDays: null,
    clientDurationInDays: null,
    clientRegularity: 0,
    clientLastMenstrualPeriodDate: null,
    clientGravida: 0,
    clientPara: 0,
    clientNoOfNeonatalDeaths: 0,
    clientCurrentlyPregnant: 0,
    clientAbortions: 0,
    clientStillBirth: 0,
    clientCurrentlyUsingAnyFPMethod: 0,
    clientEverUsedAFPMethod: 0,
    clientNoOfLivingChildren: "0",
    clientDiabetes: 2,
    clientHypertension: 2,
    clientAsthma: 2,
    clientTuberculosis: 2,
    clientCancerSpecify: null,
    clientDrugAllergies: 2,
    clientOtherSpecify: "",
    clientAdditionalNotes: "",
    clientHaveYouAttendedBulkUpload: "",
    clientDisabilityBulkUpload: "",
    clientMarginalizedAndExcludedCClientsBulkUpload: "",
    clientNoOfLivingChildrenBulkUpload: "",
  });

  const [clientFieldsError, setClientFieldsError] = useState({
    clientFirstNameError: "",
    clientMiddleNameError: "",
    clientLastNameError: "",
    clientMotherNameError: "",
    clientDateOfBirthError: "",
    clientAgeError: "",
    clientReligionError: "",
    clientMaritalStatusError: "",
    clientEducationError: "",
    clientOccupationError: "",
    clientReferralSourceError: "",
    clientPhoneNoError: "",
    clientResidenceError: "",
    mstStateIdError: "",
    mstBranchProjectIdError: "",
    mstSDPIdError: "",
    mstAreaIdError: "",
    clientAddressError: "",
    clientPinCodeError: "",
    clientBloodGroupError: "",
    clientHeightError: "",
    clientWeightError: "",
    clientCurrentlyUsingAnyFPMethodError: "",
    clientNoOfLivingChildrenError: "",
    clientLMPDateError: "",
  });

  const [organizationsCodes, setoOrganizationsCodes] = useState({
    stateCode: "",
    branchProjectCode: "",
    SDPCode: "",
    areaCode: "",
  });

  const [stateDropDown, setStateDropDown] = useState([]);
  const [branchProjectDropDown, setBranchProjectDropDown] = useState([]);
  const [SDPDropDown, setSDPDropDown] = useState([]);
  const [areaDropDown, setAreaDropDown] = useState([]);

  const [getBranchProjectDatas, setGetBranchProjectDatas] = useState([]);
  const [genderRadioBtn, setGenderRadioBtn] = useState([]);
  const [religionDropDown, setReligionDropDown] = useState([]);
  const [maritalStatusDropDown, setMaritalStatusDropDown] = useState([]);
  const [educationRadioBtn, setEducationRadioBtn] = useState([]);
  const [occupationDropDown, setOccupationDropDown] = useState([]);
  const [haveYouAttendedCheckBoxs, setHaveYouAttendedCheckBoxs] = useState([]);
  const [referralSourceDropDown, setReferralSourceDropDown] = useState([]);
  const [mobileBelongsToDropDown, setMobileBelongsToDropDown] = useState([]);
  const [residenceDropDown, setResidenceDropDown] = useState([]);
  const [disabilityCheckBoxs, setDisabilityCheckBoxs] = useState([]);
  const [
    marginalizedAndExcludedClientsCheckBoxs,
    setMarginalizedAndExcludedClientsCheckBoxs,
  ] = useState([]);
  const [yesOrNoRadioBtn, setYesOrNoRadioBtn] = useState([]);
  const [bloodGroupDropDown, setBloodGroupDropDown] = useState([]);
  const [regularityRadioBtn, setRegularityRadioBtn] = useState([]);
  const [
    currentlyUsingAnyFPMethodDropDown,
    setCurrentlyUsingAnyFPMethodDropDown,
  ] = useState([]);

  const [onChangeNoOfLivingChildren, setOnChangeNoOfLivingChildren] =
    useState(false);

  const [currentlyUsingAnyFPMethodDDShow, setCurrentlyUsingAnyFPMethodDDShow] =
    useState(false);
  const [bindChildrenDetails, setBindChildrenDetails] = useState([]);
  const [paraDD, setParaDD] = useState([
    { value: 1, label: "0" },
    { value: 2, label: "1" },
    { value: 3, label: "2" },
    { value: 4, label: "3" },
    { value: 5, label: "4" },
    { value: 6, label: "5" },
    { value: 7, label: "6" },
    { value: 8, label: "7" },
    { value: 9, label: "8" },
    { value: 10, label: "9" },
    { value: 11, label: "10" },
  ]);

  const [bindGenderDD, setGenderDD] = useState([
    { value: 1, label: "M" },
    { value: 2, label: "F" },
    { value: 3, label: "O" },
  ]);

  const [visibleNoOfLibvingChildrenTable, setVisibleNoOfLibvingChildrenTable] =
    useState(false);

  const [clientId, setClientId] = useState("");
  const [continueServiceDatas, setContinueServiceDatas] = useState([]);

  const [mstClientHaveyouattended, setMstClientHaveyouattended] = useState([]);
  const [mstClientDisability, setMstClientDisability] = useState([]);
  const [
    mstClientMarginalizedandexcluded,
    setMstClientMarginalizedandexcluded,
  ] = useState([]);
  const [mstClientNoOfLivingChildren, setMstClientNoOfLivingChildren] =
    useState([]);

  const [handleChangeAgeBool, setHandleChangeAgeBool] = useState(false);

  const [mstClientId, setMstClientId] = useState(0);

  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);

  //........client old State handle.....///

  const [pageSideMenu, setPageSideMenu] = useState("2");
  const location2 = useLocation();
  const transactionSideMenu = useLocation();

  const transaction = transactionSideMenu?.state?.isTransaction;
  console.log("transaction", transaction);

  const clientAddService = location2?.state?.isAddClient;
  const InSchoolService = location2?.state?.isAddInSchool;
  const clientAdd = location2?.state?.isAdd;
  const menuId = location2?.state?.menuId;

  const navigate = useNavigate();

  const [bindCountry, setBindCountry] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "America" },
    { value: "3", label: "Korea" },
  ]);

  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [bindClientMasterDatas, setBindClientMasterDatas] = useState([]);

  const [country, setCountry] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [clientData, setClientData] = useState({});
  const [clientData2, setClientData2] = useState({});
  const [clientData3, setClientData3] = useState({});
  const [clientData4, setClientData4] = useState({});
  const [rowIndex, setRowIndex] = useState("");

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [genderOptions, setGenderOptions] = useState(false);
  const [submitServiceButton, setSubmitServiceButton] = useState(false);

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const location1 = useLocation();
  const clientSideMenu = location1?.state;
  console.log("clientSideMenu", clientSideMenu, location1);

  const [isOpen, setIsOpen] = useState(false);

  const cancelServices = () => {
    setAddView(false);
    setTableView(true);
    setPageSideMenu("1");
    navigate("/services", { state: { isServices: true } });
  };

  useEffect(() => {
    if (transaction) {
      setPageSideMenu("3");
    }
  }, [transaction ? transaction : null]);

  useEffect(() => {
    if (clientAddService) {
      setAddView(true);
      setTableView(false);
      setAddScreenTitle(true);
      setPageSideMenu("3");
    }
    if (menuId == "27") {
      setPageSideMenu("3");
    }
    if (InSchoolService) {
      setAddView(true);
      setTableView(false);
      setAddScreenTitle(true);
      setPageSideMenu("3");
      setSubmitServiceButton(false);
    }
  }, [clientAddService]);
  useEffect(
    () => {
      if (location2.pathname == "/clientmaster") {
        setAddScreenTitle(false);
        setTableView(true);
        setAddView(false);
        // bindGridClientData(userProfileDatas);
        cancel();
      }
    },
    [location2],
    userProfileDatas
  );
  const bindGridClientData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI([data], "ClientAPI/BindClient");
      console.log("response.data.Table", response);
      setBindClientMasterDatas(response.data.Table);
    }
  };

  const bindClientChildTableDatas = async () => {
    const response = await BindAPI("ClientAPI/BindClientChildTableDatas");
    setMstClientHaveyouattended(response.data.Table);
    setMstClientDisability(response.data.Table1);
    setMstClientMarginalizedandexcluded(response.data.Table2);
    setMstClientNoOfLivingChildren(response.data.Table3);
  };

  const setGenderDatas = async (response) => {
    try {
      if (response.length > 0) {
        const gender = response.map((m) => ({
          value: m.MstGenderId,
          label: m.Gender_en,
        }));
        setGenderRadioBtn(gender);
      } else {
        setGenderRadioBtn([]);
      }
    } catch (error) {
      console.error("Error fetching gender data:", error);
    }
  };

  const setYesOrNoDatas = async (response) => {
    try {
      if (response.length > 0) {
        const yesOrNo = response.map((m) => ({
          value: m.MstDrpYesNoId,
          label: m.DrpYesNo_en,
        }));
        setYesOrNoRadioBtn(yesOrNo);
      } else {
        setYesOrNoRadioBtn([]);
      }
    } catch (error) {
      console.error("Error fetching gender data:", error);
    }
  };

  const setReligionDatas = async (response) => {
    try {
      if (response.length > 0) {
        const religionDD = response.map((m) => ({
          value: m.MstReligionId,
          label: m.Religion,
        }));
        setReligionDropDown(religionDD);
      } else {
        setReligionDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching religion data:", error);
    }
  };

  const setMaritalStatusDatas = async (response) => {
    try {
      if (response.length > 0) {
        const maritalstatusDD = response.map((m) => ({
          value: m.MstMaritalStatusId,
          label: m.MaritalStatus_en,
        }));
        setMaritalStatusDropDown(maritalstatusDD);
      } else {
        setMaritalStatusDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching maritalstatus data:", error);
    }
  };

  const setReferralSourceDatas = async (response) => {
    try {
      if (response.length > 0) {
        const referralSource = response.map((m) => ({
          value: m.MstReferralSourceId,
          label: m.ReferralSource_en,
        }));
        setReferralSourceDropDown(referralSource);
      } else {
        setReferralSourceDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching FPAIKnowledge data:", error);
    }
  };

  const setMobileBelongsToDatas = async (response) => {
    try {
      if (response.length > 0) {
        const mobilebelongstoDD = response.map((m) => ({
          value: m.MstMobileBelongsToId,
          label: m.MobileBelongsTo_en,
        }));
        setMobileBelongsToDropDown(mobilebelongstoDD);
      } else {
        setMobileBelongsToDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching mobilebelongsto data:", error);
    }
  };

  const setResidenceDatas = async (response) => {
    try {
      if (response.length > 0) {
        const residenceDD = response.map((m) => ({
          value: m.MstResidenceId,
          label: m.Residence_en,
        }));
        setResidenceDropDown(residenceDD);
      } else {
        setResidenceDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching residence data:", error);
    }
  };

  const setStateDropdownDatas = async (response) => {
    try {
      if (response.length > 0) {
        const stateDD = response.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
          code: m.StateCode,
        }));
        setStateDropDown(stateDD);
      } else {
        setStateDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const setEducationDatas = async (response) => {
    try {
      if (response.length > 0) {
        const education = response.map((m) => ({
          value: m.MstEducationId,
          label: m.EducationName,
        }));
        setEducationRadioBtn(education);
      } else {
        setEducationRadioBtn([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const setHaveYouAttendedDatas = async (response) => {
    try {
      if (response.length > 0) {
        const attended = response.map((m) => ({
          value: m.MstAttendedId,
          label: m.Attended_en,
        }));
        setHaveYouAttendedCheckBoxs(attended);
      } else {
        setHaveYouAttendedCheckBoxs([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const setDisabilityDatas = async (response) => {
    try {
      if (response.length > 0) {
        const disability = response.map((m) => ({
          value: m.MstDisabilityId,
          label: m.Disability_en,
        }));
        setDisabilityCheckBoxs(disability);
      } else {
        setDisabilityCheckBoxs([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const setMarginalizedAndExcludedClientsDatas = async (response) => {
    try {
      if (response.length > 0) {
        const clientsData = response.map((m) => ({
          value: m.MstMEClientsId,
          label: m.MEClients_en,
        }));
        setMarginalizedAndExcludedClientsCheckBoxs(clientsData);
      } else {
        setMarginalizedAndExcludedClientsCheckBoxs([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const setOccupationDatas = async (response) => {
    try {
      if (response.length > 0) {
        const occupationDD = response.map((m) => ({
          value: m.MstOccupationId,
          label: m.Occupation_en,
        }));
        setOccupationDropDown(occupationDD);
      } else {
        setOccupationDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching occupation data:", error);
    }
  };

  const setBloodGroupDatas = async (response) => {
    try {
      if (response.length > 0) {
        const bloodGroupDD = response.map((m) => ({
          value: m.MstBloodGroupId,
          label: m.BloodGroup,
        }));
        setBloodGroupDropDown(bloodGroupDD);
      } else {
        setBloodGroupDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching binddrpbloodGroupdata data:", error);
    }
  };

  const setRegularityDatas = async (response) => {
    try {
      if (response.length > 0) {
        const regularity = response.map((m) => ({
          value: m.MstRegularIrregularId,
          label: m.RegularIrregular_en,
        }));
        setRegularityRadioBtn(regularity);
      } else {
        setRegularityRadioBtn([]);
      }
    } catch (error) {
      console.error("Error fetching binddrpbloodGroupdata data:", error);
    }
  };

  const setCurrentlyUsingAnyFPMethodDatas = async (response) => {
    try {
      if (response.length > 0) {
        const fpMethod = response.map((m) => ({
          value: m.MstFPMethodId,
          label: m.FPMethod_en,
        }));
        setCurrentlyUsingAnyFPMethodDropDown(fpMethod);
      } else {
        setCurrentlyUsingAnyFPMethodDropDown([]);
      }
    } catch (error) {
      console.error("Error fetching binddrpbloodGroupdata data:", error);
    }
  };

  const generateClientId = () => {
    if (
      organizationsCodes.stateCode &&
      organizationsCodes.branchProjectCode &&
      organizationsCodes.SDPCode &&
      organizationsCodes.areaCode
    ) {
      const outputString = `${organizationsCodes.stateCode}/${organizationsCodes.branchProjectCode}/${organizationsCodes.SDPCode}/${organizationsCodes.areaCode}`;
      setClientFields((prevFields) => ({
        ...prevFields,
        clientId: outputString,
      }));
    } else {
      return;
    }
  };

  const getClientOrganizationStructureDatas = async () => {
    const getClientOrganizationDatas = await BindAPI(
      "ClientAPI/GetClientOrganizationStructure"
    );
    if (getClientOrganizationDatas) {
      setStateDropdownDatas(getClientOrganizationDatas.data.Table);
      setGetBranchProjectDatas(getClientOrganizationDatas.data.Table1);
      setGetSDPDatas(getClientOrganizationDatas.data.Table2);
      setGetAreaDatas(getClientOrganizationDatas.data.Table3);
    }
  };

  const getClientAllMastersDatas = async () => {
    const getClientAllMastersData = await BindAPI(
      "ClientAPI/GetClientAllMastersData"
    );
    if (getClientAllMastersData) {
      setGenderDatas(getClientAllMastersData.data.Table);
      setReligionDatas(getClientAllMastersData.data.Table1);
      setMaritalStatusDatas(getClientAllMastersData.data.Table2);
      setEducationDatas(getClientAllMastersData.data.Table3);
      setOccupationDatas(getClientAllMastersData.data.Table4);
      setHaveYouAttendedDatas(getClientAllMastersData.data.Table5);
      setReferralSourceDatas(getClientAllMastersData.data.Table6);
      setMobileBelongsToDatas(getClientAllMastersData.data.Table7);
      setResidenceDatas(getClientAllMastersData.data.Table8);
      setDisabilityDatas(getClientAllMastersData.data.Table9);
      setMarginalizedAndExcludedClientsDatas(
        getClientAllMastersData.data.Table10
      );
      setYesOrNoDatas(getClientAllMastersData.data.Table11);
      setBloodGroupDatas(getClientAllMastersData.data.Table12);
      setRegularityDatas(getClientAllMastersData.data.Table13);
      setCurrentlyUsingAnyFPMethodDatas(getClientAllMastersData.data.Table14);
    }
  };

  useEffect(() => {
    if (clientFields.mstStateId) {
      console.log(
        "mstStateId useEffect triggered with",
        clientFields.mstStateId
      );

      const stateCode = stateDropDown
        .filter((x) => x.value == clientFields.mstStateId)
        .map((item) => item?.code)[0];

      console.log("stateCode found:", stateCode);

      setoOrganizationsCodes((prev) => ({
        ...prev,
        stateCode: stateCode,
      }));

      const stateSelectedValue = getBranchProjectDatas.find(
        (item) => item.MstStateId == clientFields.mstStateId
      );

      if (stateSelectedValue) {
        try {
          const locationLabels = getBranchProjectDatas
            .filter((item) => item.MstStateId == stateSelectedValue.MstStateId)
            .map((item) => ({
              MstLocationId: item.MstLocationId,
              LocationName_en: item.LocationName_en,
              LocationCode: item.LocationCode,
            }));

          const locationSelected = locationLabels.map((LC) => ({
            value: LC.MstLocationId,
            label: LC.LocationName_en,
            code: LC.LocationCode,
          }));

          setBranchProjectDropDown(locationSelected);
          console.log("branchProjectDropDown set with", locationSelected);
        } catch (e) {
          console.log(`Error with getBranchProjectDatas: ${e}`);
        }
      } else {
        console.log("stateSelectedValue is undefined or not found.");
      }
    } else {
      console.log("mstStateId is empty or undefined.");
    }
  }, [clientFields.mstStateId, stateDropDown, getBranchProjectDatas]);

  useEffect(() => {
    if (clientFields.mstBranchProjectId) {
      console.log(
        "mstBranchProjectId useEffect triggered with",
        clientFields.mstBranchProjectId
      );

      const branchProjectCode = branchProjectDropDown
        .filter((x) => x.value == clientFields.mstBranchProjectId)
        .map((item) => item?.code)[0];

      console.log("branchProjectCode found:", branchProjectCode);

      setoOrganizationsCodes((prev) => ({
        ...prev,
        branchProjectCode: branchProjectCode,
      }));

      const SDPSelectedValue = getSDPDatas.find(
        (item) => item.MstLocationId == clientFields.mstBranchProjectId
      );

      if (SDPSelectedValue) {
        const sdpLabels = getSDPDatas
          .filter(
            (item) => item.MstLocationId == SDPSelectedValue.MstLocationId
          )
          .map((item) => ({
            MstSDPId: item.MstSDPId,
            SDP_en: item.SDP_en,
            SDPCode: item.SDPCode,
          }));

        const sdpSelected = sdpLabels.map((SD) => ({
          value: SD.MstSDPId,
          label: SD.SDP_en,
          code: SD.SDPCode,
        }));

        setSDPDropDown(sdpSelected);
        console.log("SDPDropDown set with", sdpSelected);
      } else {
        console.log("SDPSelectedValue is undefined or not found.");
      }
    } else {
      console.log("mstBranchProjectId is empty or undefined.");
    }
  }, [clientFields.mstBranchProjectId, branchProjectDropDown, getSDPDatas]);

  useEffect(() => {
    if (clientFields.mstSDPId) {
      console.log("mstSDPId useEffect triggered with", clientFields.mstSDPId);

      const SDPCode = SDPDropDown.filter(
        (x) => x.value == clientFields.mstSDPId
      ).map((item) => item.code)[0];

      console.log("SDPCode found:", SDPCode);

      setoOrganizationsCodes((prev) => ({
        ...prev,
        SDPCode: SDPCode,
      }));

      const areaSelectedValue = getAreaDatas.find(
        (item) => item.MstSDPId == clientFields.mstSDPId
      );

      if (areaSelectedValue) {
        try {
          const areaLabels = getAreaDatas
            .filter((item) => item.MstSDPId == areaSelectedValue.MstSDPId)
            .map((item) => ({
              MstAreaId: item.MstAreaId,
              AreaName_en: item.AreaName_en,
              AreaCode: item.AreaCode,
            }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
            code: AR.AreaCode,
          }));

          setAreaDropDown(areaSelected);
          console.log("areaDropDown set with", areaSelected);
        } catch (e) {
          console.log(`Error with getAreaDatas: ${e}`);
        }
      } else {
        console.log("areaSelectedValue is undefined or not found.");
      }
    }
  }, [clientFields.mstSDPId, SDPDropDown, getAreaDatas]);

  useEffect(() => {
    if (clientFields.mstAreaId) {
      try {
        const getAreaCode = areaDropDown

          .filter((x) => x.value == clientFields.mstAreaId)
          .map((item) => item.code);

        setoOrganizationsCodes({
          ...organizationsCodes,
          areaCode: getAreaCode[0],
        });

        console.log("getAreaCode", getAreaCode[0]);
      } catch (e) {
        console.log("Error in getAreaCode: ", e);
      }
    } else {
      console.log("Something wrong with getAreaCode");
    }
  }, [clientFields.mstAreaId]);

  const showadd = () => {
    console.log("userProfileDatas", userProfileDatas);
    setAddView(true);
    setTableView(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    const updatedClientFields = {
      ...clientFields,
      mstStateId: userProfileDatas[0]?.MstStateId,
      mstBranchProjectId: userProfileDatas[0]?.MstLocationId,
      mstSDPId: userProfileDatas[0]?.MstSDPId ?? 0,
    };

    handleGenderChange(2);

    console.log("updatedClientFields", updatedClientFields);

    setClientFields(updatedClientFields);
    // setClientFields({
    //   ...clientFields,
    //   mstStateId: userProfileDatas[0]?.MstStateId,
    //   mstBranchProjectId: userProfileDatas[0]?.MstLocationId,
    //   mstSDPId: userProfileDatas[0]?.MstSDPId,
    // });
  };
  const cancel = () => {
    clear();
    setSubmitBtnDisabled(false);
    setAddView(false);
    setTableView(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setpopUpOpen(false);
    bindGridClientData(userProfileDatas);
    bindClientChildTableDatas();
    setOnChangeNoOfLivingChildren(false);
    setRowIndex("");
    setClientId("");
    setSubmitServiceButton(false);
    setClientFields({
      ...clientFields,
      clientId: "",
      clientFirstName: "",
      clientMiddleName: "",
      clientLastName: null,
      clientMotherName: "",
      clientDateOfBirth: new Date(),
      clientAge: "",
      clientGender: 2,
      clientReligion: 0,
      clientMaritalStatus: 0,
      clientEducation: 0,
      clientOccupation: 0,
      clientRegisterDate: "",
      clientReferralSource: 0,
      clientExistingClientId: "",
      clientPhoneNo: "",
      clientMobilebelongs: 0,
      clientResidence: 0,
      mstCountryId: 0,
      mstStateId: 0,
      mstBranchProjectId: 0,
      mstSDPId: 0,
      mstAreaId: 0,
      clientAddress: "",
      clientPinCode: null,
      clientBPLCardholder: 2,
      clientBloodGroup: null,
      clientUsingAnyFormOfTobaccoProduct: 2,
      clientAlcoholUse: 2,
      clientHeight: null,
      clientWeight: null,
      clientCycleInDays: null,
      clientDurationInDays: null,
      clientRegularity: 0,
      clientLastMenstrualPeriodDate: null,
      clientGravida: 0,
      clientPara: 0,
      clientNoOfNeonatalDeaths: 0,
      clientCurrentlyPregnant: 0,
      clientAbortions: 0,
      clientStillBirth: 0,
      clientCurrentlyUsingAnyFPMethod: 0,
      clientEverUsedAFPMethod: 0,
      clientNoOfLivingChildren: "0",
      clientDiabetes: 2,
      clientHypertension: 2,
      clientAsthma: 2,
      clientTuberculosis: 2,
      clientCancerSpecify: null,
      clientDrugAllergies: 2,
      clientOtherSpecify: "",
      clientAdditionalNotes: "",
      clientHaveYouAttendedBulkUpload: "",
      clientDisabilityBulkUpload: "",
      clientMarginalizedAndExcludedCClientsBulkUpload: "",
      clientNoOfLivingChildrenBulkUpload: "",
    });
    // setClientFields({
    //   ...clientFields,
    //   clientId: "",
    //   clientFirstName: "",
    //   clientMiddleName: "",
    //   clientLastName: null,
    //   clientMotherName: "",
    //   clientDateOfBirth: new Date(),
    //   clientAge: null,
    //   clientGender: 2,
    //   clientReligion: null,
    //   clientMaritalStatus: null,
    //   clientEducation: 0,
    //   clientOccupation: null,
    //   clientRegisterDate: "",
    //   clientReferralSource: null,
    //   clientExistingClientId: "",
    //   clientPhoneNo: null,
    //   clientMobilebelongs: null,
    //   clientResidence: null,
    //   mstCountryId: 0,
    //   mstStateId: 0,
    //   mstBranchProjectId: 0,
    //   mstSDPId: 0,
    //   mstAreaId: 0,
    //   clientAddress: "",
    //   clientPinCode: null,
    //   clientBPLCardholder: 2,
    //   clientBloodGroup: null,
    //   clientUsingAnyFormOfTobaccoProduct: 2,
    //   clientAlcoholUse: 2,
    //   clientHeight: null,
    //   clientWeight: null,
    //   clientCycleInDays: null,
    //   clientDurationInDays: null,
    //   clientRegularity: null,
    //   clientLastMenstrualPeriodDate: null,
    //   clientGravida: null,
    //   clientPara: null,
    //   clientNoOfNeonatalDeaths: null,
    //   clientCurrentlyPregnant: null,
    //   clientAbortions: null,
    //   clientStillBirth: null,
    //   clientCurrentlyUsingAnyFPMethod: null,
    //   clientEverUsedAFPMethod: null,
    //   clientNoOfLivingChildren: null,
    //   clientDiabetes: 2,
    //   clientHypertension: 2,
    //   clientAsthma: 2,
    //   clientTuberculosis: 2,
    //   clientCancerSpecify: "",
    //   clientDrugAllergies: 2,
    //   clientOtherSpecify: "",
    //   clientAdditionalNotes: "",
    //   clientHaveYouAttendedBulkUpload: "",
    //   clientDisabilityBulkUpload: "",
    //   clientMarginalizedAndExcludedCClientsBulkUpload: "",
    //   clientNoOfLivingChildrenBulkUpload: "",
    // });
    setClientFieldsError({
      clientFirstNameError: "",
      clientLastNameError: "",
      clientMotherNameError: "",
      clientDateOfBirthError: "",
      clientAgeError: "",
      clientReligionError: "",
      clientMaritalStatusError: "",
      clientEducationError: "",
      clientOccupationError: "",
      clientReferralSourceError: "",
      clientPhoneNoError: "",
      clientResidenceError: "",
      mstStateIdError: "",
      mstBranchProjectIdError: "",
      mstSDPIdError: "",
      mstAreaIdAreaError: "",
      clientAddressError: "",
      clientPinCodeError: "",
      clientBloodGroupError: "",
      clientHeightError: "",
      clientWeightError: "",
      clientCurrentlyUsingAnyFPMethodError: "",
      clientNoOfLivingChildrenError: "",
      mstAreaIdError: "",
      clientLMPDateError: "",
    });

    setoOrganizationsCodes({
      stateCode: "",
      branchProjectCode: "",
      SDPCode: "",
      areaCode: "",
    });
    setVisibleNoOfLibvingChildrenTable(false);
    setBindChildrenDetails([]);
  };
  const clear = () => {
    setGenderOptions(false);
    setHandleChangeAgeBool(false);
    setViewDetails(false);
    setDeleteDetails(false);
    setBlockDetails(false);
    setUnBlockDetails(false);
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const handleClientDetails = (rowIndex, flag) => {
    handleOpen(true);

    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }

    let filterNoOfChildrenDatas = mstClientNoOfLivingChildren.filter(
      (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
    );
    setBindChildrenDetails(filterNoOfChildrenDatas);
    setClientData(bindClientMasterDatas[rowIndex]);
    const clientBackendDatas = [
      genderRadioBtn,
      educationRadioBtn,
      haveYouAttendedCheckBoxs,
      disabilityCheckBoxs,
      marginalizedAndExcludedClientsCheckBoxs,
      yesOrNoRadioBtn,
      regularityRadioBtn,
      filterNoOfChildrenDatas,
    ];
    console.log("clientBackendDatas", clientBackendDatas);
    setClientData2(clientBackendDatas);

    const filterReligionDD = religionDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientReligion
    );
    const filterMaritalStatus = maritalStatusDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientMaritalStatus
    );
    const filterOccupation = occupationDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientOccupation
    );
    const filterReferralSource = referralSourceDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientReferralSource
    );
    const filterMobileBelongsTo = mobileBelongsToDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientMobilebelongs
    );
    const filterResidence = residenceDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientResidence
    );
    const filterState = stateDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].MstStateId
    );
    const filterBranchProject = getBranchProjectDatas
      .filter((x) => x.MstStateId == bindClientMasterDatas[rowIndex].MstStateId)
      .map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }))
      .filter(
        (x) => x.value == bindClientMasterDatas[rowIndex].MstBranchProjectId
      );

    const filterSDP = getSDPDatas
      .filter(
        (x) =>
          x.MstLocationId == bindClientMasterDatas[rowIndex].MstBranchProjectId
      )
      .map((item) => ({
        value: item.MstSDPId,
        label: item.SDP_en,
      }))
      .filter((x) => x.value == bindClientMasterDatas[rowIndex].MstSDPId);
    const filterArea = getAreaDatas
      .filter((x) => x.MstSDPId == bindClientMasterDatas[rowIndex].MstSDPId)
      .map((item) => ({
        value: item.MstAreaId,
        label: item.AreaName_en,
      }))
      .filter((x) => x.value == bindClientMasterDatas[rowIndex].MstAreaId);
    const filterBloodGroup = bloodGroupDropDown.filter(
      (x) => x.value == bindClientMasterDatas[rowIndex].ClientBloodGroup
    );

    const filterCurrentlyUsingAnyFPMethod =
      currentlyUsingAnyFPMethodDropDown.filter(
        (x) =>
          x.value ==
          bindClientMasterDatas[rowIndex].ClientCurrentlyUsingAnyFPMethod
      );

    let filterHaveYouAttendClientId = mstClientHaveyouattended
      .filter(
        (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
      )
      ?.map((item) => item.MstAttendedId)
      ?.toString();

    let finalfilterHaveYouAttendClientId =
      filterHaveYouAttendClientId &&
      filterHaveYouAttendClientId.split(",").map((x) => parseInt(x.trim(), 10));

    let filterDisabilityClientId = mstClientDisability
      .filter(
        (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
      )
      ?.map((item) => item.MstDisabilityId)
      ?.toString();

    let finalfilterDisabilityClientId =
      filterDisabilityClientId &&
      filterDisabilityClientId.split(",").map((x) => parseInt(x.trim(), 10));

    let filterMarginalizedAndExcludedClientsClientId =
      mstClientMarginalizedandexcluded
        .filter(
          (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
        )
        ?.map((item) => item.MstMEClientsId)
        ?.toString();

    let finalMarginalizedAndExcludedClientsClientId =
      filterMarginalizedAndExcludedClientsClientId &&
      filterMarginalizedAndExcludedClientsClientId
        .split(",")
        .map((x) => parseInt(x.trim(), 10));

    const clientCheckBoxDatas = [
      finalfilterHaveYouAttendClientId,
      finalfilterDisabilityClientId,
      finalMarginalizedAndExcludedClientsClientId,
    ];

    console.log("clientCheckBoxDatas", clientCheckBoxDatas);
    setClientData4(clientCheckBoxDatas);

    const clinetDropDownDatas = [
      filterReligionDD,
      filterMaritalStatus,
      filterOccupation,
      filterReferralSource,
      filterMobileBelongsTo,
      filterResidence,
      filterState,
      filterBranchProject,
      filterSDP,
      filterArea,
      filterBloodGroup,
      filterCurrentlyUsingAnyFPMethod,
    ];
    console.log("clinetDropDownDatas", clinetDropDownDatas);
    setClientData3(clinetDropDownDatas);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    setAddView(true);
    setTableView(false);

    let filterHaveYouAttendClientId = mstClientHaveyouattended
      .filter(
        (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
      )
      ?.map((item) => item.MstAttendedId)
      ?.toString();

    let finalfilterHaveYouAttendClientId =
      filterHaveYouAttendClientId &&
      filterHaveYouAttendClientId.split(",").map((x) => parseInt(x.trim(), 10));

    let filterDisabilityClientId = mstClientDisability
      .filter(
        (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
      )
      ?.map((item) => item.MstDisabilityId)
      ?.toString();

    let finalfilterDisabilityClientId =
      filterDisabilityClientId &&
      filterDisabilityClientId.split(",").map((x) => parseInt(x.trim(), 10));

    let filterMarginalizedAndExcludedClientsClientId =
      mstClientMarginalizedandexcluded
        .filter(
          (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
        )
        ?.map((item) => item.MstMEClientsId)
        ?.toString();

    let finalMarginalizedAndExcludedClientsClientId =
      filterMarginalizedAndExcludedClientsClientId &&
      filterMarginalizedAndExcludedClientsClientId
        .split(",")
        .map((x) => parseInt(x.trim(), 10));

    let filterNoOfChildrenDatas = mstClientNoOfLivingChildren.filter(
      (x) => x.MstClientId == bindClientMasterDatas[rowIndex].MstClientId
    );
    setBindChildrenDetails(filterNoOfChildrenDatas);

    setClientFields({
      ...clientFields,
      clientId: bindClientMasterDatas[rowIndex].ClientId,
      clientFirstName: bindClientMasterDatas[rowIndex].ClientFirstName_en,
      clientMiddleName: bindClientMasterDatas[rowIndex].ClientMiddleName_en,
      clientLastName: bindClientMasterDatas[rowIndex].ClientLastName_en,
      clientMotherName: bindClientMasterDatas[rowIndex].ClientMotherName_en,
      clientDateOfBirth:
        bindClientMasterDatas[rowIndex].ClientDateOfBirth?.split("T")[0],
      clientAge: bindClientMasterDatas[rowIndex].ClientAge,
      clientGender: bindClientMasterDatas[rowIndex].ClientGender,
      clientReligion: bindClientMasterDatas[rowIndex].ClientReligion,
      clientMaritalStatus: bindClientMasterDatas[rowIndex].ClientMaritalStatus,
      clientEducation: bindClientMasterDatas[rowIndex].ClientEducation,
      clientOccupation: bindClientMasterDatas[rowIndex].ClientOccupation,
      clientRegisterDate:
        bindClientMasterDatas[rowIndex].ClientRegisterDate?.split("T")[0],
      clientReferralSource:
        bindClientMasterDatas[rowIndex].ClientReferralSource,
      clientExistingClientId:
        bindClientMasterDatas[rowIndex].ClientExistingClientId,
      clientPhoneNo: bindClientMasterDatas[rowIndex].ClientPhoneNo,
      clientMobilebelongs: bindClientMasterDatas[rowIndex].ClientMobilebelongs,
      clientResidence: bindClientMasterDatas[rowIndex].ClientResidence,
      mstCountryId: bindClientMasterDatas[rowIndex].MstCountryId,
      mstStateId: bindClientMasterDatas[rowIndex].MstStateId,
      mstBranchProjectId: bindClientMasterDatas[rowIndex].MstBranchProjectId,
      mstSDPId: bindClientMasterDatas[rowIndex].MstSDPId,
      mstAreaId: bindClientMasterDatas[rowIndex].MstAreaId,
      clientAddress: bindClientMasterDatas[rowIndex].ClientAddress_en,
      clientPinCode: bindClientMasterDatas[rowIndex].ClientPinCode,
      clientBPLCardholder: bindClientMasterDatas[rowIndex].ClientBPLCardholder,
      clientBloodGroup: bindClientMasterDatas[rowIndex].ClientBloodGroup,
      clientUsingAnyFormOfTobaccoProduct:
        bindClientMasterDatas[rowIndex].ClientUsingAnyFormOfTobaccoProduct,
      clientAlcoholUse: bindClientMasterDatas[rowIndex].ClientAlcoholUse,
      clientHeight: bindClientMasterDatas[rowIndex].ClientHeight,
      clientWeight: bindClientMasterDatas[rowIndex].ClientWeight,
      clientCycleInDays: bindClientMasterDatas[rowIndex].ClientCycleInDays,
      clientDurationInDays:
        bindClientMasterDatas[rowIndex].ClientDurationInDays,
      clientRegularity: bindClientMasterDatas[rowIndex].ClientRegularity,
      clientLastMenstrualPeriodDate:
        bindClientMasterDatas[rowIndex].ClientLastMenstrualPeriodDate?.split(
          "T"
        )[0],
      clientGravida: bindClientMasterDatas[rowIndex].ClientGravida,
      clientPara: bindClientMasterDatas[rowIndex].ClientPara,
      clientNoOfNeonatalDeaths:
        bindClientMasterDatas[rowIndex].ClientNoOfNeonatalDeaths,
      clientCurrentlyPregnant:
        bindClientMasterDatas[rowIndex].ClientCurrentlyPregnant,
      clientAbortions: bindClientMasterDatas[rowIndex].ClientAbortions,
      clientStillBirth: bindClientMasterDatas[rowIndex].ClientStillBirth,
      clientCurrentlyUsingAnyFPMethod:
        bindClientMasterDatas[rowIndex].ClientCurrentlyUsingAnyFPMethod,
      clientEverUsedAFPMethod:
        bindClientMasterDatas[rowIndex].ClientEverUsedAFPMethod,
      clientNoOfLivingChildren:
        bindClientMasterDatas[rowIndex].ClientNoOfLivingChildren,
      clientDiabetes: bindClientMasterDatas[rowIndex].ClientDiabetes,
      clientHypertension: bindClientMasterDatas[rowIndex].ClientHypertension,
      clientAsthma: bindClientMasterDatas[rowIndex].ClientAsthma,
      clientTuberculosis: bindClientMasterDatas[rowIndex].ClientTuberculosis,
      clientCancerSpecify:
        bindClientMasterDatas[rowIndex].ClientCancerSpecify_en,
      clientDrugAllergies: bindClientMasterDatas[rowIndex].ClientDrugAllergies,
      clientOtherSpecify: bindClientMasterDatas[rowIndex].ClientOtherSpecify_en,
      clientAdditionalNotes:
        bindClientMasterDatas[rowIndex].ClientAdditionalNotes_en,
      clientHaveYouAttendedBulkUpload: finalfilterHaveYouAttendClientId,
      clientDisabilityBulkUpload: finalfilterDisabilityClientId,
      clientMarginalizedAndExcludedCClientsBulkUpload:
        finalMarginalizedAndExcludedClientsClientId,
    });

    if (
      bindClientMasterDatas[rowIndex].ClientGender == 2 ||
      bindClientMasterDatas[rowIndex].ClientGender == 3
    ) {
      setGenderOptions(true);
    } else {
      setGenderOptions(false);
    }

    if (
      bindClientMasterDatas[rowIndex].ClientCurrentlyPregnant == 2 ||
      bindClientMasterDatas[rowIndex].ClientCurrentlyPregnant == 3
    ) {
      setCurrentlyUsingAnyFPMethodDDShow(true);
    } else {
      setCurrentlyUsingAnyFPMethodDDShow(false);
    }

    setMstClientId(bindClientMasterDatas[rowIndex].MstClientId);
  };

  const validate = (flag) => {
    const errors = {};
    if (flag === 1 || flag === 2) {
      if (!clientFields.clientFirstName) {
        errors.clientFirstName = "Enter First Name";
      }

      // if (!clientFields.clientMiddleName) {
      //   errors.clientMiddleName = "Enter Middle Name";
      // }

      // if (!clientFields.clientLastName) {
      //   errors.clientLastName = "Enter Middle Name";
      // }

      if (!clientFields.clientMotherName) {
        errors.clientMotherName = "Enter Mother's Name";
      }

      if (clientFields.clientDateOfBirth == "") {
        errors.clientDateOfBirth = "Enter Date Of Birth";
      }

      if (clientFields.clientAge == "") {
        errors.clientAge = "Enter Age";
      }

      if (clientFields.clientReligion == "") {
        errors.clientReligion = "Select Religion";
      }

      if (clientFields.clientMaritalStatus == "") {
        errors.clientMaritalStatus = "Select Marital Status";
      }

      if (clientFields.clientEducation == 0) {
        errors.clientEducation = "Select Education";
      }

      if (clientFields.clientOccupation == "") {
        errors.clientOccupation = "Select Occupation";
      }

      if (clientFields.clientReferralSource == "") {
        errors.clientReferralSource = "Select Referral Source";
      }

      // if (clientFields.clientPhoneNo == "") {
      //   errors.clientPhoneNo = "Enter Phone Number";
      // }

      if (clientFields.clientResidence == "") {
        errors.clientResidence = "Select Residence";
      }

      if (clientFields.mstStateId == "") {
        errors.mstStateId = "Select State";
      }

      if (clientFields.mstBranchProjectId == "") {
        errors.mstBranchProjectId = "Select Branch/Project";
      }

      if (clientFields.mstSDPId == "") {
        errors.mstSDPId = "Select S.D.P.";
      }

      if (clientFields.mstAreaId == "") {
        errors.mstAreaId = "Select Area";
      }

      if (clientFields.clientAddress == "") {
        errors.clientAddress = "Enter Address";
      }

      if (clientFields.clientPinCode == "") {
        errors.clientPinCode = "Enter Zipcode";
      }

      // if (clientFields.clientBloodGroup == "") {
      //   errors.clientBloodGroup = "Select Blood Group";
      // }

      // if (clientFields.clientHeight == "") {
      //   errors.clientHeight = "Enter Height";
      // }

      // if (clientFields.clientWeight == "") {
      //   errors.clientWeight = "Enter Weight";
      // }

      if (clientFields.clientCurrentlyPregnant == 2) {
        if (
          clientFields.clientCurrentlyUsingAnyFPMethod == "" ||
          clientFields.clientCurrentlyUsingAnyFPMethod == null ||
          clientFields.clientCurrentlyUsingAnyFPMethod == 0
        ) {
          errors.clientCurrentlyUsingAnyFPMethod =
            "Select Currently Using Any FP Method";
        }
      }
      if (genderOptions) {
        if (clientFieldsError.clientLMPDateError) {
          errors.LMPDateError = "Please select valid date";
        }
      }

      setClientFieldsError({
        ...clientFieldsError,
        clientFirstNameError: errors.clientFirstName || "",
        clientMiddleNameError: errors.clientMiddleName || "",
        //clientLastNameError: errors.clientLastName || "",
        clientMotherNameError: errors.clientMotherName || "",
        clientDateOfBirthError: errors.clientDateOfBirth || "",
        clientAgeError: errors.clientAge || "",
        clientReligionError: errors.clientReligion || "",
        clientMaritalStatusError: errors.clientMaritalStatus || "",
        clientEducationError: errors.clientEducation || "",
        clientOccupationError: errors.clientOccupation || "",
        clientReferralSourceError: errors.clientReferralSource || "",
        //clientPhoneNoError: errors.clientPhoneNo || "",
        clientResidenceError: errors.clientResidence || "",
        mstStateIdError: errors.mstStateId || "",
        mstBranchProjectIdError: errors.mstBranchProjectId || "",
        mstSDPIdError: errors.mstSDPId || "",
        mstAreaIdError: errors.mstAreaId || "",
        clientAddressError: errors.clientAddress || "",
        clientPinCodeError: errors.clientPinCode || "",
        //clientBloodGroupError: errors.clientBloodGroup || "",
        // clientHeightError: errors.clientHeight || "",
        // clientWeightError: errors.clientWeight || "",
        clientCurrentlyUsingAnyFPMethodError:
          errors.clientCurrentlyUsingAnyFPMethod || "",
        clientLMPDateError: errors.LMPDateError || "",
      });

      return errors;
    }
  };

  const areAllErrorsEmpty = (errors) => {
    return Object.values(errors).every((error) => error === "");
  };

  const handleSubmit = async (items, flag) => {
    console.log("clientFields", clientFields);
    var createdBy = sessionStorage.getItem("createdBy");
    if (flag == 1 || flag == 2) {
      if (!areAllErrorsEmpty(clientFieldsError)) {
        setIsOpen(true);
      }
      if (areAllErrorsEmpty(clientFieldsError)) {
        const validation = validate(flag);

        if (Object.keys(validation).length !== 0) {
          setIsOpen(true);
        }
        if (Object.keys(validation).length === 0) {
          setIsOpen(false);
          setSubmitBtnDisabled(true);
          const languageKeys = [
            "ta", // Tamil
            "hi", // Hindi
            "mr", // Marathi
            "gu", // Gujarati
            "kn", // Kannada
            "ml", // Malayalam
            "te", // Telugu
            "bn", // Bengali
            "or", // Oriya
          ];

          // const translatedFirstNameTexts = [];
          // const translatedMiddleNameTexts = [];
          // const translatedLastNameTexts = [];
          // const translatedMotherNameTexts = [];
          // const translatedAddressTexts = [];
          // const translatedClientAdditionalNotesTexts = [];
          // const translatedCancerSpecifyTexts = [];
          // const translatedOtherSpecifyTexts = [];

          // for (let i of languageKeys) {
          //   const translateText = await LanguageTranslatorAPI(
          //     clientFields.clientFirstName,
          //     i
          //   );
          //   translatedFirstNameTexts.push(
          //     translateText.data.data.translations[0].translatedText
          //   );
          // }

          // if (
          //   clientFields.clientMiddleName != null &&
          //   clientFields.clientMiddleName !== ""
          // ) {
          //   for (let i of languageKeys) {
          //     const translateText = await LanguageTranslatorAPI(
          //       clientFields.clientMiddleName,
          //       i
          //     );
          //     translatedMiddleNameTexts.push(
          //       translateText.data.data.translations[0].translatedText
          //     );
          //   }
          // }

          // for (let i of languageKeys) {
          //   const translateText = await LanguageTranslatorAPI(
          //     clientFields.clientLastName,
          //     i
          //   );
          //   translatedLastNameTexts.push(
          //     translateText.data.data.translations[0].translatedText
          //   );
          // }

          // for (let i of languageKeys) {
          //   const translateText = await LanguageTranslatorAPI(
          //     clientFields.clientMotherName,
          //     i
          //   );
          //   translatedMotherNameTexts.push(
          //     translateText.data.data.translations[0].translatedText
          //   );
          // }

          // for (let i of languageKeys) {
          //   //  var address = "test";
          //   const translateText = await LanguageTranslatorAPI(
          //     clientFields.clientAddress,
          //     i
          //   );
          //   translatedAddressTexts.push(
          //     translateText.data.data.translations[0].translatedText
          //   );
          // }

          // if (
          //   clientFields.clientCancerSpecify != "" &&
          //   clientFields.clientCancerSpecify != null
          // ) {
          //   for (let i of languageKeys) {
          //     // var specificHistory = "test";
          //     const translateText = await LanguageTranslatorAPI(
          //       clientFields.clientCancerSpecify,
          //       i
          //     );
          //     translatedCancerSpecifyTexts.push(
          //       translateText.data.data.translations[0].translatedText
          //     );
          //   }
          // }

          // if (
          //   clientFields.clientOtherSpecify != "" &&
          //   clientFields.clientOtherSpecify != null
          // ) {
          //   for (let i of languageKeys) {
          //     // var specificHistory = "test";
          //     const translateText = await LanguageTranslatorAPI(
          //       clientFields.clientOtherSpecify,
          //       i
          //     );
          //     translatedOtherSpecifyTexts.push(
          //       translateText.data.data.translations[0].translatedText
          //     );
          //   }
          // }

          // if (
          //   clientFields.clientAdditionalNotes != "" &&
          //   clientFields.clientAdditionalNotes != null
          // ) {
          //   for (let i of languageKeys) {
          //     // var specificHistory = "test";
          //     const translateText = await LanguageTranslatorAPI(
          //       clientFields.clientAdditionalNotes,
          //       i
          //     );
          //     translatedClientAdditionalNotesTexts.push(
          //       translateText.data.data.translations[0].translatedText
          //     );
          //   }
          // }

          const translatedFirstNameTexts = [];
          const translatedMiddleNameTexts = [];
          const translatedLastNameTexts = [];
          const translatedMotherNameTexts = [];
          const translatedAddressTexts = [];
          const translatedClientAdditionalNotesTexts = [];
          const translatedCancerSpecifyTexts = [];
          const translatedOtherSpecifyTexts = [];

          for (let i of languageKeys) {
            if (clientFields.clientFirstName) {
              // Check if not empty or null
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientFirstName,
                i
              );
              translatedFirstNameTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientMiddleName) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientMiddleName,
                i
              );
              translatedMiddleNameTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientLastName) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientLastName,
                i
              );
              translatedLastNameTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientMotherName) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientMotherName,
                i
              );
              translatedMotherNameTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientAddress) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientAddress,
                i
              );
              translatedAddressTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientCancerSpecify) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientCancerSpecify,
                i
              );
              translatedCancerSpecifyTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientOtherSpecify) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientOtherSpecify,
                i
              );
              translatedOtherSpecifyTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientAdditionalNotes) {
            for (let i of languageKeys) {
              const translateText = await LanguageTranslatorAPI(
                clientFields.clientAdditionalNotes,
                i
              );
              translatedClientAdditionalNotesTexts.push(
                translateText.data.data.translations[0].translatedText
              );
            }
          }

          if (clientFields.clientHaveYouAttendedBulkUpload != "") {
            clientFields.clientHaveYouAttendedBulkUpload.join(",");
          } else {
            console.log("clientHaveYouAttendedBulkUpload doesn't have data");
          }

          if (clientFields.clientDisabilityBulkUpload != "") {
            clientFields.clientDisabilityBulkUpload.join(",");
          } else {
            console.log("clientDisabilityBulkUpload doesn't have data");
          }

          if (
            clientFields.clientMarginalizedAndExcludedCClientsBulkUpload != ""
          ) {
            clientFields.clientMarginalizedAndExcludedCClientsBulkUpload.join(
              ","
            );
          } else {
            console.log(
              "clientMarginalizedAndExcludedCClientsBulkUpload doesn't have data"
            );
          }

          const clientPayload = {
            mstClientId: mstClientId != 0 ? mstClientId : 0,
            clientId: clientFields.clientId,
            clientFirstNameEn: clientFields.clientFirstName,
            clientFirstNameTa: translatedFirstNameTexts[0],
            clientFirstNameHi: translatedFirstNameTexts[1],
            clientFirstNameMr: translatedFirstNameTexts[2],
            clientFirstNameGu: translatedFirstNameTexts[3],
            clientFirstNameMl: translatedFirstNameTexts[4],
            clientFirstNameTe: translatedFirstNameTexts[5],
            clientFirstNameKn: translatedFirstNameTexts[6],
            clientFirstNameBn: translatedFirstNameTexts[7],
            clientFirstNameOr: translatedFirstNameTexts[8],
            clientMiddleNameEn: clientFields.clientMiddleName,

            clientMiddleNameTa: translatedMiddleNameTexts[0] ?? null,
            clientMiddleNameHi: translatedMiddleNameTexts[1] ?? null,
            clientMiddleNameMr: translatedMiddleNameTexts[2] ?? null,
            clientMiddleNameGu: translatedMiddleNameTexts[3] ?? null,
            clientMiddleNameMl: translatedMiddleNameTexts[4] ?? null,
            clientMiddleNameTe: translatedMiddleNameTexts[5] ?? null,
            clientMiddleNameKn: translatedMiddleNameTexts[6] ?? null,
            clientMiddleNameBn: translatedMiddleNameTexts[7] ?? null,
            clientMiddleNameOr: translatedMiddleNameTexts[8] ?? null,
            clientLastNameEn: clientFields.clientLastName ?? null,
            clientLastNameTa: translatedLastNameTexts[0],
            clientLastNameHi: translatedLastNameTexts[1],
            clientLastNameMr: translatedLastNameTexts[2],
            clientLastNameGu: translatedLastNameTexts[3],
            clientLastNameMl: translatedLastNameTexts[4],
            clientLastNameTe: translatedLastNameTexts[5],
            clientLastNameKn: translatedLastNameTexts[6],
            clientLastNameBn: translatedLastNameTexts[7],
            clientLastNameOr: translatedLastNameTexts[8],
            clientMotherNameEn: clientFields.clientMotherName,
            clientMotherNameTa: translatedMotherNameTexts[8],
            clientMotherNameHi: translatedMotherNameTexts[8],
            clientMotherNameMr: translatedMotherNameTexts[8],
            clientMotherNameGu: translatedMotherNameTexts[8],
            clientMotherNameMl: translatedMotherNameTexts[8],
            clientMotherNameTe: translatedMotherNameTexts[8],
            clientMotherNameKn: translatedMotherNameTexts[8],
            clientMotherNameBn: translatedMotherNameTexts[8],
            clientMotherNameOr: translatedMotherNameTexts[8],
            clientDateOfBirth: clientFields.clientDateOfBirth,
            clientAge: clientFields.clientAge,
            clientGender: clientFields.clientGender,
            clientReligion: clientFields.clientReligion,
            clientMaritalStatus: clientFields.clientMaritalStatus,
            clientEducation: clientFields.clientEducation,
            clientOccupation: clientFields.clientOccupation,
            clientRegisterDate: clientFields.clientRegisterDate,
            clientReferralSource: clientFields.clientReferralSource,
            clientExistingClientId: clientFields.clientExistingClientId ?? null,
            clientPhoneNo: clientFields.clientPhoneNo ?? null,
            clientMobilebelongs: clientFields.clientMobilebelongs
              ? clientFields.clientMobilebelongs
              : null,
            clientResidence: clientFields.clientResidence,
            mstCountryId: 1,
            mstStateId: clientFields.mstStateId,
            mstBranchProjectId: clientFields.mstBranchProjectId,
            mstSDPId: clientFields.mstSDPId,
            mstAreaId: clientFields.mstAreaId,
            clientAddressEn: clientFields.clientAddress,
            clientAddressTa: translatedAddressTexts[0],
            clientAddressHi: translatedAddressTexts[1],
            clientAddressMr: translatedAddressTexts[2],
            clientAddressGu: translatedAddressTexts[3],
            clientAddressMl: translatedAddressTexts[4],
            clientAddressTe: translatedAddressTexts[5],
            clientAddressKn: translatedAddressTexts[6],
            clientAddressBn: translatedAddressTexts[7],
            clientAddressOr: translatedAddressTexts[8],
            clientPinCode: clientFields.clientPinCode,
            clientBPLCardholder: clientFields.clientBPLCardholder,
            clientBloodGroup: clientFields.clientBloodGroup,
            clientUsingAnyFormOfTobaccoProduct:
              clientFields.clientUsingAnyFormOfTobaccoProduct,
            clientAlcoholUse: clientFields.clientAlcoholUse,
            clientHeight: clientFields.clientHeight,
            clientWeight: clientFields.clientWeight,
            clientCycleInDays: clientFields.clientCycleInDays ?? null,
            clientDurationInDays: clientFields.clientDurationInDays
              ? clientFields.clientDurationInDays
              : null,
            clientRegularity: clientFields.clientRegularity
              ? clientFields.clientRegularity
              : null,
            clientLastMenstrualPeriodDate:
              clientFields.clientLastMenstrualPeriodDate ?? null,
            clientGravida: clientFields.clientGravida
              ? clientFields.clientGravida
              : null,
            clientPara: clientFields.clientPara
              ? clientFields.clientPara
              : null,
            clientNoOfNeonatalDeaths: clientFields.clientNoOfNeonatalDeaths
              ? clientFields.clientNoOfNeonatalDeaths
              : null,
            clientCurrentlyPregnant: clientFields.clientCurrentlyPregnant
              ? clientFields.clientCurrentlyPregnant
              : null,
            clientAbortions: clientFields.clientAbortions
              ? clientFields.clientAbortions
              : null,
            clientStillBirth: clientFields.clientStillBirth
              ? clientFields.clientStillBirth
              : null,
            clientCurrentlyUsingAnyFPMethod:
              clientFields.clientCurrentlyUsingAnyFPMethod
                ? clientFields.clientCurrentlyUsingAnyFPMethod
                : null,
            clientEverUsedAFPMethod: clientFields.clientEverUsedAFPMethod
              ? clientFields.clientEverUsedAFPMethod
              : null,
            clientNoOfLivingChildren: clientFields.clientNoOfLivingChildren
              ? clientFields.clientNoOfLivingChildren
              : null,
            clientDiabetes: clientFields.clientDiabetes,
            clientHypertension: clientFields.clientHypertension,
            clientAsthma: clientFields.clientAsthma,
            clientTuberculosis: clientFields.clientTuberculosis,
            clientCancerSpecifyEn: clientFields.clientCancerSpecify ?? null,
            clientCancerSpecifyTa: translatedCancerSpecifyTexts[0] ?? null,
            clientCancerSpecifyHi: translatedCancerSpecifyTexts[1] ?? null,
            clientCancerSpecifyMr: translatedCancerSpecifyTexts[2] ?? null,
            clientCancerSpecifyGu: translatedCancerSpecifyTexts[3] ?? null,
            clientCancerSpecifyMl: translatedCancerSpecifyTexts[4] ?? null,
            clientCancerSpecifyTe: translatedCancerSpecifyTexts[5] ?? null,
            clientCancerSpecifyKn: translatedCancerSpecifyTexts[6] ?? null,
            clientCancerSpecifyBn: translatedCancerSpecifyTexts[7] ?? null,
            clientCancerSpecifyOr: translatedCancerSpecifyTexts[8] ?? null,
            clientDrugAllergies: clientFields.clientDrugAllergies,
            clientOtherSpecifyEn: clientFields.clientOtherSpecify ?? null,
            clientOtherSpecifyTa: translatedOtherSpecifyTexts[0] ?? null,
            clientOtherSpecifyHi: translatedOtherSpecifyTexts[1] ?? null,
            clientOtherSpecifyMr: translatedOtherSpecifyTexts[2] ?? null,
            clientOtherSpecifyGu: translatedOtherSpecifyTexts[3] ?? null,
            clientOtherSpecifyMl: translatedOtherSpecifyTexts[4] ?? null,
            clientOtherSpecifyTe: translatedOtherSpecifyTexts[5] ?? null,
            clientOtherSpecifyKn: translatedOtherSpecifyTexts[6] ?? null,
            clientOtherSpecifyBn: translatedOtherSpecifyTexts[7] ?? null,
            clientOtherSpecifyOr: translatedOtherSpecifyTexts[8] ?? null,
            clientAdditionalNotesEn: clientFields.clientAdditionalNotes ?? null,
            clientAdditionalNotesTa:
              translatedClientAdditionalNotesTexts[0] ?? null,
            clientAdditionalNotesHi:
              translatedClientAdditionalNotesTexts[1] ?? null,
            clientAdditionalNotesMr:
              translatedClientAdditionalNotesTexts[2] ?? null,
            clientAdditionalNotesGu:
              translatedClientAdditionalNotesTexts[3] ?? null,
            clientAdditionalNotesMl:
              translatedClientAdditionalNotesTexts[4] ?? null,
            clientAdditionalNotesTe:
              translatedClientAdditionalNotesTexts[5] ?? null,
            clientAdditionalNotesKn:
              translatedClientAdditionalNotesTexts[6] ?? null,
            clientAdditionalNotesBn:
              translatedClientAdditionalNotesTexts[7] ?? null,
            clientAdditionalNotesOr:
              translatedClientAdditionalNotesTexts[8] ?? null,
            clientHaveYouAttendedBulkUpload:
              clientFields.clientHaveYouAttendedBulkUpload.toString(),
            clientDisabilityBulkUpload:
              clientFields.clientDisabilityBulkUpload.toString(),
            clientMarginalizedAndExcludedCClientsBulkUpload:
              clientFields.clientMarginalizedAndExcludedCClientsBulkUpload.toString(),
            clientNoOfLivingChildrenBulkUpload:
              clientFields.clientNoOfLivingChildrenBulkUpload,
            createdBy: createdBy,
            flag: flag,
          };

          console.log("clientPayload", clientPayload);

          const response = await IUDAPI(clientPayload, "ClientAPI/IUDClient");
          console.log("client response", response);
          if (flag == 1) {
            if (response.data.Table[0].Result === "S") {
              toast.success("Client Data Inserted Successfully👍", {
                duration: 5000,
                position: "top-center",
              });
              setSubmitServiceButton(true);
              setClientId(response.data.Table1[0].ClientId);
              setContinueServiceDatas(response.data.Table2[0]);
            } else {
              toast.error("Not Insert Client 👎", {
                duration: 5000,
                position: "top-center",
              });
              setTimeout(cancel, 5000);
            }
          } else if (flag == 2) {
            if (response.data.Table[0].Result === "M") {
              toast.success("Client Data Updated Successfully👍", {
                duration: 3000,
                position: "top-center",
              });
              setTimeout(cancel, 3000);
            } else {
              toast.error("Not Update Client 👎", {
                duration: 5000,
                position: "top-center",
              });
              setTimeout(cancel, 5000);
            }
          }
        }
      }
    } else if (flag == 3 || flag == 4 || flag == 5) {
      setSubmitBtnDisabled(true);
      const clientPayload = {
        mstClientId: bindClientMasterDatas[items].MstClientId,
        createdBy: createdBy,
        flag: flag,
      };

      const response = await IUDAPI(clientPayload, "ClientAPI/IUDClient");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success("Client data deleted successfully 🗑️", 5000, cancel());
          cancel();
        } else {
          toast.error("Failed to delete Client data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success("Client data blocked successfully 🚫", 5000, cancel());
          cancel();
        } else {
          toast.error("Failed to block Client data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Client data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Client data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const convertArrayToSingleValueString = (array) => {
    if (array.length > 0) {
      return String(array[0]);
    }
    return "";
  };

  useEffect(() => {
    if (addScreenTitle) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setClientFields({
        ...clientFields,
        clientRegisterDate: formattedDate,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);

  const handleGenderChange = (selectedOptions) => {
    //setGender(selectedOptions);
    // setClientFields({
    //   ...clientFields,
    //   clientGender: selectedOptions,
    // });
    if (selectedOptions == 2 || selectedOptions == 3) {
      setGenderOptions(true);
      setClientFields({
        ...clientFields,
        clientGender: selectedOptions,
      });
    } else {
      setGenderOptions(false);
      setBindChildrenDetails([]);

      setClientFields({
        ...clientFields,
        clientGender: selectedOptions,
        clientCycleInDays: 0,
        clientDurationInDays: 0,
        clientRegularity: 0,
        clientLastMenstrualPeriodDate: null,
        clientGravida: 0,
        clientPara: 0,
        clientNoOfNeonatalDeaths: 0,
        clientCurrentlyPregnant: 0,
        clientAbortions: 0,
        clientStillBirth: 0,
        clientCurrentlyUsingAnyFPMethod: 0,
        clientEverUsedAFPMethod: 0,
        clientNoOfLivingChildren: "0",
      });
      setCurrentlyUsingAnyFPMethodDDShow(false);
    }
  };

  const handleDateChange = (selectedDate) => {
    if (addScreenTitle) {
      const currentDate = new Date();
      const birthDate = new Date(selectedDate);
      let calculatedAge = currentDate.getFullYear() - birthDate.getFullYear();
      const monthDifference = currentDate.getMonth() - birthDate.getMonth();
      const dayDifference = currentDate.getDate() - birthDate.getDate();

      // Adjust the age if the current date hasn't reached the birthday yet this year
      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        calculatedAge--;
      }

      setClientFields({
        ...clientFields,
        clientAge: calculatedAge,
        clientDateOfBirth: selectedDate,
      });
    }
  };

  useEffect(() => {
    if (handleChangeAgeBool) {
      if (clientFields.clientAge) {
        const enteredAge = parseInt(clientFields.clientAge);

        const currentDate = new Date();
        const birthYear = currentDate.getFullYear() - enteredAge;
        const defaultMonth = currentDate.getMonth() + 1; // Current month
        const defaultDay = currentDate.getDate(); // Current day

        const formattedDOB = `${birthYear}-${String(defaultMonth).padStart(
          2,
          "0"
        )}-${String(defaultDay).padStart(2, "0")}`;
        setClientFields({
          ...clientFields,
          clientDateOfBirth: formattedDOB,
        });
      }
    }
  }, [clientFields.clientAge ? clientFields.clientAge : null]);

  console.log("ClientFileds:", clientFields);

  const handleClientFieldsChange = (value, name) => {
    setClientFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   getClientAllMastersDatas()
  //     .then(() => getClientOrganizationStructureDatas())
  //     .then(() => bindClientChildTableDatas())
  //     .then(() => bindGridClientData())
  //     .catch((error) => {
  //       console.error("Error in useEffect:", error);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getClientAllMastersDatas();
        await getClientOrganizationStructureDatas();
        await bindClientChildTableDatas();
        await bindGridClientData(userProfileDatas);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    fetchData();
  }, [userProfileDatas]);

  // useEffect(() => {
  //   if (clientFields.clientNoOfLivingChildren != "0") {
  //     setVisibleNoOfLibvingChildrenTable(true);
  //     // Create an array of objects with keys childrenOrder from 1 to the entered value
  //     if (onChangeNoOfLivingChildren) {
  //       const numInputs = parseInt(clientFields.clientNoOfLivingChildren);

  //       if (!isNaN(numInputs)) {
  //         const childrenDetailsArray = new Array(numInputs)
  //           .fill("")
  //           .map((_, index) => {
  //             return { childrenOrder: index + 1 };
  //           });
  //         setBindChildrenDetails(childrenDetailsArray);
  //       } else {
  //         setBindChildrenDetails([]);
  //         setVisibleNoOfLibvingChildrenTable(false);
  //       }
  //     }
  //   } else {
  //     setBindChildrenDetails([]);
  //     setVisibleNoOfLibvingChildrenTable(false);
  //   }
  // }, [
  //   clientFields.clientNoOfLivingChildren
  //     ? clientFields.clientNoOfLivingChildren
  //     : null,
  // ]);

  // useEffect(() => {

  //   if (
  //     clientFields.clientNoOfLivingChildren !== "0" &&
  //     clientFields.clientNoOfLivingChildren !== 0
  //   ) {
  //     setVisibleNoOfLibvingChildrenTable(true);
  //     if (onChangeNoOfLivingChildren) {
  //       const numInputs = parseInt(clientFields.clientNoOfLivingChildren);

  //       if (!isNaN(numInputs)) {
  //         // If bindChildrenDetails already has data, add or remove arrays as necessary
  //         const currentLength = bindChildrenDetails.length;
  //         if (currentLength < numInputs) {
  //           // Add new children details if current length is less than numInputs
  //           const additionalArrays = new Array(numInputs - currentLength)
  //             .fill("")
  //             .map((_, index) => {
  //               return { childrenOrder: currentLength + index + 1 };
  //             });
  //           setBindChildrenDetails((prevDetails) => [
  //             ...prevDetails,
  //             ...additionalArrays,
  //           ]);
  //         } else if (currentLength > numInputs) {
  //           // Remove excess children details if current length is more than numInputs
  //           setBindChildrenDetails((prevDetails) =>
  //             prevDetails.slice(0, numInputs)
  //           );
  //         }
  //       } else {
  //         setBindChildrenDetails([]);
  //         setVisibleNoOfLibvingChildrenTable(false);
  //       }
  //     }
  //   } else {
  //     setBindChildrenDetails([]);
  //     setVisibleNoOfLibvingChildrenTable(false);
  //   }
  // }, [clientFields.clientNoOfLivingChildren, onChangeNoOfLivingChildren]);

  useEffect(() => {
    if (
      clientFields.clientNoOfLivingChildren !== "0" &&
      clientFields.clientNoOfLivingChildren !== 0
    ) {
      if (
        clientFields.clientNoOfLivingChildren == "" ||
        clientFields.clientNoOfLivingChildren == null
      ) {
        setVisibleNoOfLibvingChildrenTable(false);
      } else {
        setVisibleNoOfLibvingChildrenTable(true);
      }

      if (onChangeNoOfLivingChildren) {
        const numInputs = parseInt(clientFields.clientNoOfLivingChildren);

        if (!isNaN(numInputs)) {
          const currentLength = bindChildrenDetails.length;

          if (currentLength < numInputs) {
            const additionalArrays = new Array(numInputs - currentLength)
              .fill("")
              .map((_, index) => {
                return { childrenOrder: currentLength + index + 1 };
              });

            setBindChildrenDetails((prevDetails) => {
              if (updateScreenTitle) {
                // In edit mode, append new items without clearing old data
                return [...prevDetails, ...additionalArrays];
              } else {
                // Not in edit mode, handle as usual
                return [...prevDetails, ...additionalArrays];
              }
            });
          } else if (currentLength > numInputs) {
            setBindChildrenDetails((prevDetails) =>
              prevDetails.slice(0, numInputs)
            );
          }
        } else {
          setBindChildrenDetails([]);
          setVisibleNoOfLibvingChildrenTable(false);
        }
      }
    } else {
      setBindChildrenDetails([]);
      setVisibleNoOfLibvingChildrenTable(false);
    }
  }, [clientFields.clientNoOfLivingChildren, onChangeNoOfLivingChildren]);

  // clientFields.clientNoOfLivingChildren,
  // onChangeNoOfLivingChildren,
  // bindChildrenDetails,
  const handleNoOfLivingChidren = (livingChidrenDatas) => {
    if (livingChidrenDatas) {
      console.log("livingChidrenDatas:client", livingChidrenDatas);
      setClientFields({
        ...clientFields,
        clientNoOfLivingChildrenBulkUpload: JSON.stringify(livingChidrenDatas),
      });
    }
  };

  console.log("code datas", organizationsCodes);
  console.log("client Id:", clientFields.clientId);
  console.log("client Edit Rowindex", clientFields);

  // const onNumberOfLivingChildrenChanged = (value) => {
  //   if (value === "" || /^[0-9]+$/.test(value)) {
  //     setClientFieldsError({
  //       ...clientFieldsError,
  //       clientNoOfLivingChildrenError: "",
  //     });

  //     const numOfChildren = parseInt(value, 10) || 0;

  //     setClientFields({
  //       ...clientFields,
  //       clientNoOfLivingChildren: value,
  //     });

  //     if (updateScreenTitle && bindChildrenDetails.length > 0) {
  //       // Edit mode: Modify existing list without clearing it.
  //       const currentCount = bindChildrenDetails.length;

  //       if (numOfChildren > currentCount) {
  //         // Add new rows if the input number is greater than current rows
  //         const additionalRows = Array.from(
  //           { length: numOfChildren - currentCount },
  //           (_, index) => ({
  //             childrenOrder: currentCount + index + 1,
  //             clientYears: "",
  //             clientMonths: "",
  //             clientDays: "",
  //             clientGender: "",
  //           })
  //         );

  //         setBindChildrenDetails((prevDetails) => [
  //           ...prevDetails,
  //           ...additionalRows,
  //         ]);
  //       } else if (numOfChildren < currentCount && numOfChildren !== 0) {
  //         // Trim the rows if input number is less than current rows
  //         setBindChildrenDetails((prevDetails) =>
  //           prevDetails.slice(0, numOfChildren)
  //         );
  //       }
  //     } else if (!updateScreenTitle) {
  //       // Non-edit mode: Reset table and populate new rows based on input value
  //       if (numOfChildren > 0) {
  //         const newRows = Array.from({ length: numOfChildren }, (_, index) => ({
  //           childrenOrder: index + 1,
  //           clientYears: null,
  //           clientMonths: null,
  //           clientDays: null,
  //           clientGender: null,
  //         }));
  //         setBindChildrenDetails(newRows);
  //       } else if (numOfChildren === 0) {
  //         // Clear table only if number is explicitly set to 0
  //         setBindChildrenDetails([]);
  //       }
  //     }
  //   } else {
  //     // Invalid input: Show error
  //     setClientFieldsError({
  //       ...clientFieldsError,
  //       clientNoOfLivingChildrenError: "Only Numbers allowed",
  //     });
  //   }
  // };

  const onNumberOfLivingChildrenChanged = (value) => {
    if (value === "" || /^[0-9]+$/.test(value)) {
      setClientFieldsError({
        ...clientFieldsError,
        clientNoOfLivingChildrenError: "",
      });

      const numOfChildren = parseInt(value, 10) || 0;

      setClientFields({
        ...clientFields,
        clientNoOfLivingChildren: value,
      });
      if (value == "" || value == null) {
        setVisibleNoOfLibvingChildrenTable(false);
      } else {
        setVisibleNoOfLibvingChildrenTable(true);
      }

      if (updateScreenTitle) {
        const currentCount = bindChildrenDetails.length;

        if (numOfChildren > currentCount) {
          // Add new rows if the input number is greater than current rows
          const additionalRows = Array.from(
            { length: numOfChildren - currentCount },
            (_, index) => ({
              childrenOrder: currentCount + index + 1,
              clientYears: "",
              clientMonths: "",
              clientDays: "",
              clientGender: "",
            })
          );
          setBindChildrenDetails((prevDetails) => [
            ...prevDetails,
            ...additionalRows,
          ]);
        } else if (numOfChildren < currentCount && numOfChildren !== 0) {
          // Trim rows if input number is less than current rows
          setBindChildrenDetails((prevDetails) =>
            prevDetails.slice(0, numOfChildren)
          );
        } else if (currentCount === 0 && numOfChildren > 0) {
          // Edge case: If in edit mode and there are no children rows, add new rows
          const newRows = Array.from({ length: numOfChildren }, (_, index) => ({
            childrenOrder: index + 1,
            clientYears: "",
            clientMonths: "",
            clientDays: "",
            clientGender: "",
          }));
          setBindChildrenDetails(newRows);
        }
      } else {
        // Non-edit mode: Reset table and populate new rows based on input value
        if (numOfChildren > 0) {
          const newRows = Array.from({ length: numOfChildren }, (_, index) => ({
            childrenOrder: index + 1,
            clientYears: null,
            clientMonths: null,
            clientDays: null,
            clientGender: null,
          }));
          setBindChildrenDetails(newRows);
        } else if (numOfChildren === 0) {
          // Clear table only if number is explicitly set to 0
          setBindChildrenDetails([]);
        }
      }
    } else {
      // Invalid input: Show error
      setClientFieldsError({
        ...clientFieldsError,
        clientNoOfLivingChildrenError: "Only Numbers allowed",
      });
      setVisibleNoOfLibvingChildrenTable(false);
    }
  };

  console.log("pageSideMenu", pageSideMenu);
  return (
    <MainLayout pageName={pageSideMenu}>
      {tableView && (
        <GridPage
          title={`Client Registration`}
          header={clientHeaders}
          records={bindClientMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleClientDetails}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {addView && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Client Registration"
          />

          <div class={mainTable.addShadowScreen}>
            <div class={mainTable.mainContentScreen}>
              <form class="" action="#">
                <div
                  class={
                    "block mb-1 text-base capitalize font-medium text-neutral-70"
                  }
                >
                  Client Details
                </div>
                <hr class={mainTable.horizontalLine}></hr>
                <div className={mainTable.grid3}>
                  {/* <div>
                    <InputText
                      inputType="text"
                      title="First Name"
                      name="firstName"
                      placeholder={"xxxxx"}
                      classStar="text-red-500"
                      content={clientFields.clientFirstName}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientFirstName: e.target.value,
                        });
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientFirstNameError:
                            clientFields.clientFirstName &&
                            clientFields.clientFirstName.length > 0
                              ? /^[A-Za-z ]+$/.test(
                                  clientFields.clientFirstName
                                )
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter First Name",
                        }));
                      }}
                      errorMessage={clientFieldsError.clientFirstNameError}
                      maxLength={50}
                    />
                  </div> */}
                  <div>
                    <InputText
                      inputType="text"
                      title="First Name"
                      name="firstName"
                      placeholder="xxxxx"
                      classStar="text-red-500"
                      content={clientFields.clientFirstName}
                      controlFunc={(e) => {
                        const value = e.target.value;
                        setClientFields({
                          ...clientFields,
                          clientFirstName: value,
                        });
                      }}
                      controlFuncBlur={() => {
                        const trimmedValue =
                          clientFields.clientFirstName?.trim(); // Remove leading/trailing spaces
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientFirstNameError:
                            trimmedValue.length > 0
                              ? /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(trimmedValue)
                                ? ""
                                : "Only alphabets allowed"
                              : "Enter First Name",
                        }));

                        // Update the trimmed value in the state to remove any leading/trailing spaces
                        setClientFields({
                          ...clientFields,
                          clientFirstName: trimmedValue,
                        });
                      }}
                      errorMessage={clientFieldsError.clientFirstNameError}
                      maxLength={50}
                    />
                  </div>

                  <div>
                    <InputText
                      inputType="text"
                      title="Middle Name"
                      placeholder={"xxxxx"}
                      name="middleName"
                      content={clientFields.clientMiddleName}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientMiddleName: e.target.value,
                        });
                      }}
                      controlFuncBlur={() => {
                        const trimmedValue =
                          clientFields.clientMiddleName?.trim(); // Remove leading/trailing spaces
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientMiddleNameError:
                            trimmedValue.length > 0
                              ? /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(trimmedValue)
                                ? ""
                                : "Only alphabets allowed"
                              : "",
                        }));

                        // Update the trimmed value in the state to remove any leading/trailing spaces
                        setClientFields({
                          ...clientFields,
                          clientMiddleName: trimmedValue,
                        });
                      }}
                      errorMessage={clientFieldsError.clientMiddleNameError}
                      maxLength={50}
                      //classStar="text-red-500"
                    />
                  </div>
                  <div>
                    <InputText
                      inputType="text"
                      title="Last Name"
                      name="lastName"
                      placeholder={"xxxxx"}
                      maxLength={50}
                      content={clientFields.clientLastName}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientLastName: e.target.value,
                        });
                      }}
                      // controlFuncBlur={() => {
                      //   setClientFieldsError((clientFieldsError) => ({
                      //     ...clientFieldsError,
                      //     clientLastNameError:
                      //       clientFields.clientLastName &&
                      //       clientFields.clientLastName.length > 0
                      //         ? /^[A-Za-z ]+$/.test(clientFields.clientLastName)
                      //           ? ""
                      //           : "Only Alphabet and space allowed"
                      //         : "Enter Last Name",
                      //   }));
                      // }}
                      controlFuncBlur={() => {
                        const trimmedValue =
                          clientFields.clientLastName?.trim(); // Remove leading/trailing spaces
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientLastNameError:
                            trimmedValue?.length > 0
                              ? /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(trimmedValue)
                                ? ""
                                : "Only alphabets allowed"
                              : "",
                        }));

                        // Update the trimmed value in the state to remove any leading/trailing spaces
                        setClientFields({
                          ...clientFields,
                          clientLastName: trimmedValue,
                        });
                      }}
                      errorMessage={clientFieldsError.clientLastNameError}
                    />
                  </div>
                  <div>
                    <InputText
                      inputType="text"
                      title="Mother's Name"
                      name="motherName"
                      classStar="text-red-500"
                      placeholder={"xxxxx"}
                      maxLength={100}
                      content={clientFields.clientMotherName}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientMotherName: e.target.value,
                        });
                      }}
                      // controlFuncBlur={() => {
                      //   setClientFieldsError((clientFieldsError) => ({
                      //     ...clientFieldsError,
                      //     clientMotherNameError:
                      //       clientFields.clientMotherName &&
                      //       clientFields.clientMotherName.length > 0
                      //         ? /^[A-Za-z ]+$/.test(
                      //             clientFields.clientMotherName
                      //           )
                      //           ? ""
                      //           : "Only Alphabet and space allowed"
                      //         : "Enter Mother's Name",
                      //   }));
                      // }}
                      controlFuncBlur={() => {
                        const trimmedValue =
                          clientFields.clientMotherName?.trim(); // Remove leading/trailing spaces
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientMotherNameError:
                            trimmedValue.length > 0
                              ? /^[A-Za-z]+(?: [A-Za-z]+)*$/.test(trimmedValue)
                                ? ""
                                : "Only alphabets allowed"
                              : "Enter Mother's Name",
                        }));

                        // Update the trimmed value in the state to remove any leading/trailing spaces
                        setClientFields({
                          ...clientFields,
                          clientMotherName: trimmedValue,
                        });
                      }}
                      errorMessage={clientFieldsError.clientMotherNameError}
                    />
                  </div>
                  {/* <div>
                    <InputText
                      inputType="date"
                      title="Date of Birth"
                      classStar="text-red-500"
                      name="dob"
                      //content={dob}
                      content={clientFields.clientDateOfBirth}
                      controlFunc={(e) => {
                        //setDOB(e.target.value);
                        // setClientFields({
                        //   ...clientFields,
                        //   clientDateOfBirth: e.target.value,
                        // });
                        // handleDateChange(e.target.value);
                        const selectedDate = new Date(e.target.value);
                        const maxDate = new Date();
                        const minDate = new Date();
                        minDate.setFullYear(maxDate.getFullYear() - 100);

                        // Check if the selected date is within the allowed range
                        if (
                          selectedDate >= minDate &&
                          selectedDate <= maxDate
                        ) {
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: e.target.value,
                          });
                          handleDateChange(e.target.value);
                        } else {
                          // Reset the date if it's out of range
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: "",
                          });
                          handleDateChange("");
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError:
                              clientFields.clientDateOfBirth
                                ? ""
                                : "Please enter a valid Date of Birth within the allowed range.",
                          }));
                        }
                      }}
                      // controlFuncBlur={(e) => {
                      //   //setDOB(e.target.value);
                      //   setClientFields({
                      //     ...clientFields,
                      //     clientDateOfBirth: e.target.value,
                      //   });
                      //   setClientFieldsError((clientFieldsError) => ({
                      //     ...clientFieldsError,
                      //     clientDateOfBirthError: clientFields.clientDateOfBirth
                      //       ? ""
                      //       : "Enter Date Of Birth",
                      //   }));
                      //   //setDOBError(dob ? "" : "Enter Date");
                      // }}
                      controlFuncBlur={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const maxDate = new Date();
                        const minDate = new Date();
                        minDate.setFullYear(maxDate.getFullYear() - 100);

                        if (
                          selectedDate >= minDate &&
                          selectedDate <= maxDate
                        ) {
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: e.target.value,
                          });
                        } else {
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: "",
                          });
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError:
                              clientFields.clientDateOfBirth
                                ? ""
                                : "Please enter a valid Date of Birth within the allowed range.",
                          }));
                        }

                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientDateOfBirthError: clientFields.clientDateOfBirth
                            ? ""
                            : "Enter Date Of Birth",
                        }));
                      }}
                      max={new Date().toISOString().split("T")[0]} // Current date
                      min={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 99)
                        )
                          .toISOString()
                          .split("T")[0]
                      } // 100 years ago from today
                      errorMessage={clientFieldsError.clientDateOfBirthError}
                    />
                  </div> */}
                  <div>
                    <InputText
                      inputType="date"
                      title="Date of Birth"
                      classStar="text-red-500"
                      name="dob"
                      content={
                        clientFields.tempDateOfBirth ||
                        clientFields.clientDateOfBirth
                      } // Show temporary input
                      controlFunc={(e) => {
                        const rawValue = e.target.value;
                        const selectedDate = new Date(rawValue);
                        const currentDate = new Date();

                        // Temporarily update the raw input value
                        setClientFields({
                          ...clientFields,
                          tempDateOfBirth: rawValue, // Temporarily store the input
                        });

                        // Validate only past and current dates
                        if (
                          rawValue &&
                          rawValue.length === 10 && // Ensure input is complete
                          selectedDate <= currentDate // Allow only dates up to today
                        ) {
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: rawValue, // Update validated date
                            tempDateOfBirth: "", // Clear temporary input
                          });
                          handleDateChange(rawValue);

                          // Clear error message when valid
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError: "",
                          }));
                        } else if (rawValue.length === 10) {
                          // Handle invalid future dates
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError:
                              "Future dates are not allowed.",
                          }));
                        }
                      }}
                      controlFuncBlur={(e) => {
                        const rawValue = e.target.value;
                        const selectedDate = new Date(rawValue);
                        const currentDate = new Date();

                        // Validate on blur for past and current dates
                        if (
                          rawValue &&
                          rawValue.length === 10 && // Ensure input is complete
                          selectedDate <= currentDate // Allow only dates up to today
                        ) {
                          setClientFields({
                            ...clientFields,
                            clientDateOfBirth: rawValue,
                            tempDateOfBirth: "",
                          });

                          // Clear error message when valid
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError: "",
                          }));
                        } else {
                          setClientFields({
                            ...clientFields,
                            tempDateOfBirth: "",
                          });

                          // Set appropriate error message
                          setClientFieldsError((clientFieldsError) => ({
                            ...clientFieldsError,
                            clientDateOfBirthError:
                              rawValue.length === 10
                                ? "Future dates are not allowed."
                                : "Please enter a valid date in YYYY-MM-DD format.",
                          }));
                        }
                      }}
                      max={new Date().toISOString().split("T")[0]} // Current date
                      min={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 99)
                        )
                          .toISOString()
                          .split("T")[0]
                      } // 100 years ago
                      errorMessage={clientFieldsError.clientDateOfBirthError}
                    />
                  </div>

                  <div>
                    <InputText
                      inputType="text"
                      title="Age"
                      classStar="text-red-500"
                      name="age"
                      maxLength={2}
                      placeholder={"xx"}
                      //content={age}
                      content={clientFields.clientAge}
                      controlFunc={(e) => {
                        // setClientFields({
                        //   ...clientFields,
                        //   clientAge: e.target.value,
                        // });
                        // setHandleChangeAgeBool(true);
                        const value = e.target.value;

                        // Allow only numbers
                        if (/^\d*$/.test(value)) {
                          setClientFields({
                            ...clientFields,
                            clientAge: value,
                          });
                          setHandleChangeAgeBool(true);
                        }
                        // handleAgeInputChange(e);
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientAgeError: clientFields.clientAge
                            ? /^[0-9]+$/.test(clientFields.clientAge)
                              ? ""
                              : "Only numbers allowed"
                            : "Enter Age",
                          clientDateOfBirthError: "",
                        }));
                        // setAge(e.target.value);
                        // setAgeError(
                        //   age.length > 0
                        //     ? /^[0-9]+$/.test(age)
                        //       ? ""
                        //       : "Only numbers allowed"
                        //     : "Enter Age"
                        // );
                      }}
                      errorMessage={clientFieldsError.clientAgeError}
                    />
                  </div>
                  <div>
                    <RadioGroup
                      title="Gender"
                      classStar="text-red-500"
                      type="radio"
                      setName="gender"
                      //selectedOptions={gender}
                      selectedOptions={clientFields.clientGender}
                      options={genderRadioBtn}
                      controlFunc={handleGenderChange}
                      tabIndex="0"
                      disabled={false}

                      // controlFuncBlur={(e) => {
                      //   const selectedValue = e.target.value;
                      //   setGender(selectedValue);
                      //   handleGenderChange(selectedValue);
                      //   setGenderError(selectedValue ? "" : "Select Gender");
                      // }}
                    />
                  </div>
                  <div>
                    <DropDown
                      title="Religion"
                      classStar="text-red-500"
                      options={religionDropDown}
                      placeholder="Select"
                      name="religion"
                      selectedOption={clientFields.clientReligion}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientReligion: e.target.value,
                        });
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientReligionError: clientFields.clientReligion
                            ? ""
                            : "Select Religion",
                        }));
                      }}
                    />
                    {clientFieldsError.clientReligionError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {clientFieldsError.clientReligionError}
                      </span>
                    )}
                  </div>

                  <div>
                    <DropDown
                      title="Marital Status"
                      classStar="text-red-500"
                      options={maritalStatusDropDown}
                      placeholder="Select"
                      name="maritalStatus"
                      selectedOption={clientFields.clientMaritalStatus}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientMaritalStatus: e.target.value,
                        });
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientMaritalStatusError:
                            clientFields.clientMaritalStatus
                              ? ""
                              : "Select Marital Status",
                        }));
                      }}
                    />
                    {clientFieldsError.clientMaritalStatusError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {clientFieldsError.clientMaritalStatusError}
                      </span>
                    )}
                  </div>
                  <div className="lg:col-span-3 md:col-span-3 col-span-1">
                    <RadioGroup
                      title="Education"
                      classStar="text-red-500"
                      type="radio"
                      setName="education"
                      //selectedOptions={education}
                      selectedOptions={clientFields.clientEducation}
                      options={educationRadioBtn}
                      //controlFunc={handleEducationChange}
                      controlFunc={(selectedOption) => {
                        setClientFields({
                          ...clientFields,
                          clientEducation: selectedOption,
                        });
                        if (selectedOption) {
                          setClientFieldsError({
                            ...clientFieldsError,
                            clientEducationError: "",
                          });
                        }
                      }}
                      tabIndex="0"
                      disabled={false}
                      Class1="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-4 xl:grid xl:grid-cols-6 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
                    />
                    {clientFieldsError.clientEducationError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {clientFieldsError.clientEducationError}
                      </span>
                    )}
                  </div>
                  <div>
                    <DropDown
                      title="Occupation"
                      classStar="text-red-500"
                      options={occupationDropDown}
                      placeholder="Select"
                      name="Occupation"
                      selectedOption={clientFields.clientOccupation}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientOccupation: e.target.value,
                        });
                        // setOccupation(e.target.value);
                        // setOccupationError(
                        //   e.target.value ? "" : "Select Occupation"
                        // );
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientOccupationError: clientFields.clientOccupation
                            ? ""
                            : "Select Occupation",
                        }));
                        // setOccupation(e.target.value);
                        // setOccupationError(
                        //   occupation ? "" : "Select Occupation"
                        // );
                      }}
                    />
                    {clientFieldsError.clientOccupationError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {clientFieldsError.clientOccupationError}
                      </span>
                    )}
                  </div>
                  <div>
                    <InputText
                      inputType="date"
                      title="Register Date"
                      classStar="text-red-500"
                      name="registerDate"
                      content={clientFields.clientRegisterDate}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientRegisterDate: e.target.value,
                        });
                      }}
                      max={new Date().toISOString().split("T")[0]}
                    />
                    {/* {RegisterDateError && (
                      <span class="text-red-500">
                        {RegisterDateError}
                      </span>
                    )} */}
                  </div>
                  <div className="lg:col-span-2 md:col-span-2 col-span-1 ">
                    <Checkbox
                      title="Have you attended"
                      //classStar="text-red-500"
                      type={"Checkbox"}
                      setName={"HaveYouAttended"}
                      //selectedOptions={awarness}
                      selectedOptions={
                        clientFields.clientHaveYouAttendedBulkUpload
                      }
                      options={haveYouAttendedCheckBoxs}
                      controlFunc={(selectedOptions) => {
                        setClientFields({
                          ...clientFields,
                          clientHaveYouAttendedBulkUpload: selectedOptions,
                        });
                      }}
                      tabIndex="0"
                      disabled={false}
                      Class="grid grid-cols-1 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
                    />
                    {/* {awarnessError && (
                      <span className="text-red-500">{awarnessError}</span>
                    )} */}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Referral Source"
                      classStar="text-red-500"
                      options={referralSourceDropDown}
                      placeholder="Select"
                      name="clientReferralSource"
                      selectedOption={referralSourceDropDown.find(
                        (x) => x.value == clientFields.clientReferralSource
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value, e) => {
                        handleClientFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          clientReferralSourceError:
                            clientFields.clientReferralSource
                              ? ""
                              : "Select Referral Source",
                        });
                      }}
                      errorMessage={clientFieldsError.clientReferralSourceError}
                    />
                  </div>
                  <InputText
                    inputType="text"
                    title="Existing Client Id."
                    name="clientExistingClientId"
                    content={clientFields.clientExistingClientId}
                    controlFunc={(e) => {
                      setClientFields({
                        ...clientFields,
                        clientExistingClientId: e.target.value,
                      });
                    }}
                    //controlFunc={(e) => setExistingClientId(e.target.value)}
                  />
                </div>

                <div
                  class={
                    "block mb-1 text-base capitalize font-medium text-neutral-70"
                  }
                >
                  Address Details
                </div>
                <hr class={mainTable.horizontalLine}></hr>
                <div className={mainTable.grid3}>
                  <div>
                    <InputText
                      inputType={"text"}
                      title={"Phone Number"}
                      name={"phone"}
                      placeholder={"xxxxx"}
                      content={clientFields.clientPhoneNo}
                      maxLength={10}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientPhoneNo: e.target.value,
                        });
                        //setPhone(e.target.value)
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          clientPhoneNoError: clientFields.clientPhoneNo
                            ? /^[+\d\s]{10,}$/.test(clientFields.clientPhoneNo)
                              ? ""
                              : "Enter a valid phone number"
                            : "",
                        });
                      }}
                      errorMessage={clientFieldsError.clientPhoneNoError}
                    />
                  </div>

                  <DropDown
                    title="Mobile Belongs To"
                    options={mobileBelongsToDropDown}
                    placeholder="Select"
                    name="Mobile Belongs To"
                    selectedOption={clientFields.clientMobilebelongs}
                    controlFunc={(e) => {
                      setClientFields({
                        ...clientFields,
                        clientMobilebelongs: e.target.value,
                      });
                    }}
                  />

                  <div>
                    <DropDown
                      title="Residence"
                      options={residenceDropDown}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="residence"
                      selectedOption={clientFields.clientResidence}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientResidence: e.target.value,
                        });
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          clientResidenceError: clientFields.clientResidence
                            ? ""
                            : "Select Residence",
                        });
                      }}
                    />
                    {clientFieldsError.clientResidenceError && (
                      <span class="text-red-500 text-sm  font-medium">
                        {clientFieldsError.clientResidenceError}
                      </span>
                    )}
                  </div>
                  <DropDown
                    classDiv="hidden"
                    title="Country"
                    options={bindCountry}
                    placeholder="Select"
                    name="country"
                    selectedOption={country}
                    controlFunc={(value) => setCountry(value)}
                  />
                  <div>
                    <AutoDropDown
                      title="State"
                      options={stateDropDown}
                      disabled={updateScreenTitle}
                      classStar="text-red-500"
                      Class="p-0"
                      placeholder="Select"
                      name="mstStateId"
                      selectedOption={stateDropDown.find(
                        (x) => x.value == clientFields.mstStateId
                      )}
                      controlFunc={(value, e) => {
                        setClientFields({
                          ...clientFields,
                          mstBranchProjectId: "",
                          mstSDPId: "",
                          mstAreaId: "",
                        });
                        handleClientFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          mstStateIdError: clientFields.mstStateId
                            ? ""
                            : "Select State",
                        });
                      }}
                      errorMessage={clientFieldsError.mstStateIdError}
                    />
                  </div>
                  <div>
                    <AutoDropDown
                      title="Branch/Project"
                      options={branchProjectDropDown}
                      disabled={updateScreenTitle}
                      classStar="text-red-500"
                      placeholder="Select"
                      Class="custom-class"
                      tabIndex="1"
                      name="mstBranchProjectId"
                      selectedOption={
                        clientFields.mstBranchProjectId
                          ? branchProjectDropDown.find(
                              (x) => x.value == clientFields.mstBranchProjectId
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        setClientFields({
                          ...clientFields,
                          mstSDPId: "",
                          mstAreaId: "",
                        });
                        handleClientFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          mstBranchProjectIdError:
                            clientFields.mstBranchProjectId
                              ? ""
                              : "Select Branch/Project",
                        });
                      }}
                      errorMessage={clientFieldsError.mstBranchProjectIdError}
                    />
                  </div>
                  <div>
                    <AutoDropDown
                      classStar="text-red-500"
                      title="S.D.P."
                      options={SDPDropDown}
                      disabled={updateScreenTitle}
                      placeholder="Select"
                      Class="custom-class"
                      tabIndex="1"
                      name="mstSDPId"
                      selectedOption={
                        clientFields.mstSDPId
                          ? SDPDropDown.find(
                              (x) => x.value == clientFields.mstSDPId
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        setClientFields({
                          ...clientFields,
                          mstAreaId: "",
                        });
                        handleClientFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          mstSDPIdError: clientFields.mstSDPId
                            ? ""
                            : "Select S.D.P.",
                        });
                      }}
                      errorMessage={clientFieldsError.mstSDPIdError}
                    />
                  </div>
                  <div>
                    <AutoDropDown
                      title="Area"
                      options={areaDropDown}
                      disabled={updateScreenTitle}
                      classStar="text-red-500"
                      placeholder="Select"
                      Class="custom-class"
                      tabIndex="1"
                      name="mstAreaId"
                      selectedOption={
                        clientFields.mstAreaId
                          ? areaDropDown.find(
                              (x) => x.value == clientFields.mstAreaId
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        handleClientFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setClientFieldsError({
                          ...clientFieldsError,
                          mstAreaIdError: clientFields.mstAreaId
                            ? ""
                            : "Select Area",
                        });
                        generateClientId();
                      }}
                      errorMessage={clientFieldsError.mstAreaIdError}
                    />
                  </div>
                  <div>
                    <InputText
                      inputType={"text"}
                      title={"Address"}
                      name={"address"}
                      classStar="text-red-500"
                      content={clientFields.clientAddress}
                      maxLength={500}
                      placeholder="7/33,first street"
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientAddress: e.target.value,
                        });
                      }}
                      controlFuncBlur={(e) => {
                        const trimmedValue = clientFields.clientAddress?.trim();
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientAddressError:
                            trimmedValue.length > 0
                              ? /^[a-zA-Z0-9\s,'/.-]*$/.test(trimmedValue)
                                ? ""
                                : "Enter valid address"
                              : "Enter Address",
                        }));

                        // Update the trimmed value in the state to remove any leading/trailing spaces
                        setClientFields({
                          ...clientFields,
                          clientAddress: trimmedValue,
                        });
                      }}
                      errorMessage={clientFieldsError.clientAddressError}
                    />
                  </div>

                  <div>
                    <InputText
                      inputType={"text"}
                      title={"Zip/Pincode"}
                      classStar="text-red-500"
                      name={"zipcode"}
                      content={clientFields.clientPinCode}
                      maxLength={6}
                      placeholder="xxxxxx"
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientPinCode: e.target.value,
                        });
                      }}
                      controlFuncBlur={(e) => {
                        setClientFieldsError((clientFieldsError) => ({
                          ...clientFieldsError,
                          clientPinCodeError:
                            clientFields.clientPinCode || "".length > 0
                              ? /^[0-9 ]+$/.test(clientFields.clientPinCode)
                                ? ""
                                : "Enter a valid Zip/Pincode"
                              : "Enter Zip/Pincode",
                        }));
                      }}
                      errorMessage={clientFieldsError.clientPinCodeError}
                    />
                  </div>
                </div>
                {/* <div class={mainTable.labelBold}>Medical Details</div>
                <hr class={mainTable.horizontalLine}></hr> */}
                <div className={mainTable.grid3}>
                  {/* <div className="col-span-3">
                    <Checkbox
                      title="Disability"
                      type="Checkbox"
                      setName="disability"
                      selectedOptions={clientFields.clientDisabilityBulkUpload}
                      options={disabilityCheckBoxs}
                      //controlFunc={disabilityhandleCheckboxChange}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientDisabilityBulkUpload: value,
                        });
                      }}
                      disabled={false}
                      tabIndex="0"
                    />
                  </div>
                  <div className="col-span-3">
                    <Checkbox
                      title="Marginalized And Excluded Clients"
                      type="Checkbox"
                      setName="marginalize"
                      selectedOptions={
                        clientFields.clientMarginalizedAndExcludedCClientsBulkUpload
                      }
                      options={marginalizedAndExcludedClientsCheckBoxs}
                      //controlFunc={marginalizehandleCheckboxChange}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientMarginalizedAndExcludedCClientsBulkUpload:
                            value,
                        });
                      }}
                      disabled={false}
                      tabIndex="0"
                      Class1="w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                      Class="grid grid-cols-2 md:grid md:grid-cols-6 lg:grid lg:grid-cols-6 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
                    />
                  </div> */}
                  <div className="col-span-3">
                    <Checkbox
                      title="Disability"
                      type="Checkbox"
                      setName="disability"
                      selectedOptions={clientFields.clientDisabilityBulkUpload}
                      options={disabilityCheckBoxs}
                      controlFunc={(value) => {
                        // Ensure that clientMarginalizedAndExcludedCClientsBulkUpload is an array
                        const marginalizedClients =
                          clientFields.clientMarginalizedAndExcludedCClientsBulkUpload ||
                          [];

                        // Check if option 7 is selected
                        if (value.includes(7)) {
                          // If option 7 is selected, deselect all other options (1 to 6)
                          setClientFields({
                            ...clientFields,
                            clientDisabilityBulkUpload: [7], // Only option 7 is selected
                            clientMarginalizedAndExcludedCClientsBulkUpload:
                              marginalizedClients.filter(
                                (option) => option !== 1
                              ), // Ensure 1 is deselected
                          });
                        } else {
                          // Otherwise, check if any option from 1 to 6 is selected
                          const selectedDisabilities = value.filter(
                            (option) => option >= 1 && option <= 6
                          );

                          // Update both the Disability and Marginalized And Excluded Clients fields
                          setClientFields({
                            ...clientFields,
                            clientDisabilityBulkUpload: value,
                            clientMarginalizedAndExcludedCClientsBulkUpload:
                              selectedDisabilities.length > 0
                                ? [...new Set([...marginalizedClients, 1])] // Ensure 1 is selected if any disability option (1-6) is selected
                                : marginalizedClients.filter(
                                    (option) => option !== 1
                                  ), // Deselect 1 if no disabilities (1-6) are selected
                          });
                        }
                      }}
                      disabled={false}
                      tabIndex="0"
                    />
                  </div>
                  <div className="col-span-3">
                    <Checkbox
                      title="Marginalized And Excluded Clients"
                      type="Checkbox"
                      setName="marginalize"
                      selectedOptions={
                        clientFields.clientMarginalizedAndExcludedCClientsBulkUpload ||
                        []
                      } // Ensure it's always an array
                      options={marginalizedAndExcludedClientsCheckBoxs}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientMarginalizedAndExcludedCClientsBulkUpload:
                            clientFields.clientDisabilityBulkUpload == "" ||
                            clientFields.clientDisabilityBulkUpload != "7"
                              ? value.filter((option) => option >= 1)
                              : value.filter(
                                  (option) => option !== 1 && option >= 1
                                ),
                        });
                      }}
                      disabled={false}
                      tabIndex="0"
                    />
                  </div>
                </div>
                <div class={mainTable.grid3}>
                  <div>
                    <RadioGroup
                      title="BPL cardholder"
                      type="radio"
                      setName="clientBPLCardholder"
                      selectedOptions={clientFields.clientBPLCardholder}
                      options={yesOrNoRadioBtn}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientBPLCardholder: value,
                        });
                      }}
                      tabIndex="0"
                      disabled={false}
                    />
                  </div>
                  <div>
                    <DropDown
                      title="Blood Group"
                      options={bloodGroupDropDown}
                      placeholder="Select"
                      name="bloodGroup"
                      selectedOption={clientFields.clientBloodGroup}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientBloodGroup: e.target.value,
                        });
                      }}
                      // controlFuncBlur={(e) => {
                      //   setClientFieldsError((clientFieldsError) => ({
                      //     ...clientFieldsError,
                      //     clientBloodGroupError: clientFields.clientBloodGroup
                      //       ? ""
                      //       : "Select Blood Group",
                      //   }));
                      // }}
                    />
                    {/* {clientFieldsError.clientBloodGroupError && (
                      <span class="text-red-500 ">
                        {clientFieldsError.clientBloodGroupError}
                      </span>
                    )} */}
                  </div>
                  <div>
                    <RadioGroup
                      title="Using Any Form Of Tobacco Product"
                      type="radio"
                      setName="clientUsingAnyFormOfTobaccoProduct"
                      selectedOptions={
                        clientFields.clientUsingAnyFormOfTobaccoProduct
                      }
                      options={yesOrNoRadioBtn}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientUsingAnyFormOfTobaccoProduct: value,
                        });
                      }}
                      tabIndex="0"
                      disabled={false}
                    />
                  </div>
                  <div>
                    <RadioGroup
                      title="Alcohol Use"
                      type="radio"
                      setName="clientAlcoholUse"
                      selectedOptions={clientFields.clientAlcoholUse}
                      options={yesOrNoRadioBtn}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientAlcoholUse: value,
                        });
                      }}
                      tabIndex="0"
                      disabled={false}
                    />
                  </div>
                  {/* <div>
                    <label for="client_name" class={mainTable.label}>
                      Physique<span className="text-red-500">*</span>
                    </label>

                    <div className={mainTable.inputDiv2}>
                      <div>
                        <InputText
                          inputType="text"
                          ClassDiv="grid gap-2 grid-cols-2"
                          classLabel={
                            "block mb-2 text-sm  font-medium text-neutral-700"
                          }
                          Class={mainTable.smallInput}
                          title="Height (in cm)"
                          name="height"
                          placeholder="175"
                          content={clientFields.clientHeight}
                          controlFunc={(e) => {
                            setClientFields({
                              ...clientFields,
                              clientHeight: e.target.value,
                            });
                          }}
                          controlFuncBlur={(e) => {
                            setClientFieldsError((clientFieldsError) => ({
                              ...clientFieldsError,
                              clientHeightError:
                                clientFields.clientHeight.length > 0
                                  ? /^[0-9]+(\.[0-9]{1,2})?$/.test(
                                      clientFields.clientHeight
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter Height",
                            }));
                          }}
                          errorMessage={clientFieldsError.clientHeightError}
                        />
                      </div>
                      <div>
                        <InputText
                          inputType="text"
                          ClassDiv="grid gap-2 grid-cols-2"
                          classLabel={
                            "block mb-2 text-sm  font-medium text-neutral-700"
                          }
                          Class={mainTable.smallInput}
                          title="Weight (in kg)"
                          name="weight"
                          placeholder="160"
                          content={clientFields.clientWeight}
                          controlFunc={(e) => {
                            setClientFields({
                              ...clientFields,
                              clientWeight: e.target.value,
                            });
                          }}
                          controlFuncBlur={(e) => {
                            setClientFieldsError((clientFieldsError) => ({
                              ...clientFieldsError,
                              clientWeightError:
                                clientFields.clientWeight.length > 0
                                  ? /^[0-9]+(\.[0-9]{1,2})?$/.test(
                                      clientFields.clientWeight
                                    )
                                    ? ""
                                    : "Only numbers allowed"
                                  : "Enter Weight",
                            }));
                          }}
                          errorMessage={clientFieldsError.clientWeightError}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <label htmlFor="client_name" className={mainTable.label}>
                      Physique
                    </label>

                    <div className={mainTable.inputDiv2}>
                      <div>
                        <InputText
                          inputType="number"
                          ClassDiv="grid gap-2 grid-cols-2"
                          classLabel={
                            "block mb-2 text-sm font-medium text-neutral-700"
                          }
                          Class={mainTable.smallInput}
                          title="Height (in cm)"
                          name="height"
                          placeholder="175"
                          maxLength={3}
                          content={clientFields.clientHeight}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
                            let errorMessage = "";

                            if (value.length === 0) {
                              errorMessage = "";
                            } else if (!regex.test(value)) {
                              errorMessage = "Only numbers allowed";
                            } else if (parseFloat(value) > 300) {
                              errorMessage =
                                "Height must be less than or equal to 300 cm";
                            }

                            setClientFields({
                              ...clientFields,
                              clientHeight: value,
                            });
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientHeightError: errorMessage,
                            });
                          }}
                          // controlFuncBlur={(e) => {
                          //   const value = clientFields.clientHeight;

                          //   if (value.length === 0) {
                          //     setClientFieldsError({
                          //       ...clientFieldsError,
                          //       clientHeightError: "Enter Height",
                          //     });
                          //   }
                          // }}
                          errorMessage={clientFieldsError.clientHeightError}
                        />
                      </div>

                      <div>
                        <InputText
                          inputType="number"
                          ClassDiv="grid gap-2 grid-cols-2"
                          classLabel={
                            "block mb-2 text-sm font-medium text-neutral-700"
                          }
                          Class={mainTable.smallInput}
                          title="Weight (in kg)"
                          name="weight"
                          placeholder="70"
                          maxLength={3}
                          content={clientFields.clientWeight}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
                            let errorMessage = "";

                            if (value.length === 0) {
                              errorMessage = "";
                            } else if (!regex.test(value)) {
                              errorMessage = "Only numbers allowed";
                            } else if (parseFloat(value) > 700) {
                              errorMessage =
                                "Weight must be less than or equal to 700 kg";
                            }

                            setClientFields({
                              ...clientFields,
                              clientWeight: value,
                            });
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientWeightError: errorMessage,
                            });
                          }}
                          // controlFuncBlur={(e) => {
                          //   const value = clientFields.clientWeight;

                          //   if (value.length === 0) {
                          //     setClientFieldsError({
                          //       ...clientFieldsError,
                          //       clientWeightError: "Enter Weight",
                          //     });
                          //   }
                          // }}
                          errorMessage={clientFieldsError.clientWeightError}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                {genderOptions && (
                  <div className="block mb-2 text-base capitalize font-medium text-neutral-700 ">
                    Menstrual History
                  </div>
                )}
                {genderOptions && (
                  <div class={mainTable.grid4}>
                    <div>
                      <InputText
                        inputType="number"
                        //classStar="text-red-500"
                        title="Cycle (in days)"
                        classLabel={
                          "block mb-2 text-sm  font-medium text-neutral-700"
                        }
                        name="Cycle"
                        placeholder="00"
                        maxLength={2}
                        content={clientFields.clientCycleInDays}
                        controlFunc={(e) => {
                          setClientFields({
                            ...clientFields,
                            clientCycleInDays: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="number"
                        //classStar="text-red-500"
                        title="Duration (in days)"
                        classLabel={
                          "block mb-2 text-sm  font-medium text-neutral-700"
                        }
                        name="Duration"
                        maxLength={2}
                        placeholder={"00"}
                        content={clientFields.clientDurationInDays}
                        controlFunc={(e) => {
                          setClientFields({
                            ...clientFields,
                            clientDurationInDays: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <RadioGroup
                        //classStar="text-red-500"
                        title="Regularity"
                        type="radio"
                        setName="clientRegularity"
                        selectedOptions={clientFields.clientRegularity}
                        options={regularityRadioBtn}
                        //controlFunc={handleRegularIrregular}
                        controlFunc={(value) => {
                          setClientFields({
                            ...clientFields,
                            clientRegularity: value,
                          });
                        }}
                        tabIndex="0"
                        disabled={false}
                      />
                    </div>
                    {/* <div>
                      <InputText
                        inputType="date"
                        title="Last menstrual period (Date)"
                        //classStar="text-red-500"
                        classLabel={
                          "block mb-2 text-sm  font-medium text-neutral-700"
                        }
                        name="clientLastMenstrualPeriodDate"
                        content={clientFields.clientLastMenstrualPeriodDate}
                        // controlFunc={(e) => {
                        //   setClientFields({
                        //     ...clientFields,
                        //     clientLastMenstrualPeriodDate: e.target.value,
                        //   });
                        // }}
                        controlFunc={(e) => {
                          const selectedDate = new Date(e.target.value);
                          const today = new Date();
                          today.setHours(0, 0, 0, 0); // Clear the time for accurate comparison
                          const pastLimit = new Date();
                          pastLimit.setDate(today.getDate() - 365); // Set the past limit to 365 days ago

                          if (selectedDate > today) {
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientLMPDateError:
                                "Please select a past date or the current date.",
                            });
                            // alert(
                            //   "Please select a past date or the current date."
                            // );
                          } else if (selectedDate < pastLimit) {
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientLMPDateError:
                                "Please select a date within the last year.",
                            });
                            //alert("Please select a date within the last year.");
                          } else {
                            setClientFields({
                              ...clientFields,
                              clientLastMenstrualPeriodDate: e.target.value,
                            });
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientLMPDateError: "",
                            });
                          }
                        }}
                        errorMessage={clientFieldsError.clientLMPDateError}
                      />
                    </div> */}
                    <InputText
                      inputType="date"
                      title="Last menstrual period (Date)"
                      classLabel={
                        "block mb-2 text-sm font-medium text-neutral-700"
                      }
                      name="clientLastMenstrualPeriodDate"
                      content={clientFields.clientLastMenstrualPeriodDate}
                      controlFunc={(e) => {
                        const selectedDate = new Date(e.target.value);
                        selectedDate.setHours(0, 0, 0, 0); // Normalize the selected date to midnight

                        const today = new Date();
                        today.setHours(0, 0, 0, 0); // Normalize today to midnight

                        const pastLimit = new Date();
                        pastLimit.setDate(today.getDate() - 365); // Set the past limit to 365 days ago
                        pastLimit.setHours(0, 0, 0, 0); // Normalize pastLimit to midnight

                        if (selectedDate > today) {
                          setClientFieldsError({
                            ...clientFieldsError,
                            clientLMPDateError:
                              "Please select a past date or the current date.",
                          });
                        } else if (selectedDate < pastLimit) {
                          setClientFieldsError({
                            ...clientFieldsError,
                            clientLMPDateError:
                              "Please select a date within the last year.",
                          });
                        } else {
                          setClientFields({
                            ...clientFields,
                            clientLastMenstrualPeriodDate: e.target.value,
                          });
                          setClientFieldsError({
                            ...clientFieldsError,
                            clientLMPDateError: "",
                          });
                        }
                      }}
                      max={new Date().toISOString().split("T")[0]}
                      errorMessage={clientFieldsError.clientLMPDateError}
                    />
                  </div>
                )}
                {genderOptions && (
                  <div className="block mb-2 text-base capitalize font-medium text-neutral-700 ">
                    Obstetric History
                  </div>
                )}
                {genderOptions && (
                  <div class={mainTable.grid3}>
                    <DropDown
                      title={"Gravida"}
                      //classStar="text-red-500"
                      options={paraDD}
                      placeholder={"Select"}
                      name={"Gravida"}
                      selectedOption={clientFields.clientGravida}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientGravida: e.target.value,
                        });
                      }}
                    />
                    <DropDown
                      title={"Para"}
                      //classStar="text-red-500"
                      options={paraDD}
                      placeholder={"Select"}
                      name={"Para"}
                      selectedOption={clientFields.clientPara}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientPara: e.target.value,
                        });
                      }}
                    />
                    <InputText
                      inputType="number"
                      //classStar="text-red-500"
                      title="No. of neonatal deaths (28days)"
                      name="Noofneonataldeaths"
                      classLabel={
                        "block mb-2 text-sm  font-medium text-neutral-700"
                      }
                      maxLength={1}
                      content={clientFields.clientNoOfNeonatalDeaths}
                      controlFunc={(e) => {
                        setClientFields({
                          ...clientFields,
                          clientNoOfNeonatalDeaths: e.target.value,
                        });
                      }}
                    />

                    <div>
                      <RadioGroup
                        title="Currently pregnant"
                        //classStar="text-red-500"
                        type="radio"
                        setName="clientCurrentlyPregnant"
                        selectedOptions={clientFields.clientCurrentlyPregnant}
                        options={yesOrNoRadioBtn ? yesOrNoRadioBtn : []}
                        //controlFunc={handleCurrentlyPregnant}
                        controlFunc={(value) => {
                          if (value == 2) {
                            setCurrentlyUsingAnyFPMethodDDShow(true);
                            setClientFields({
                              ...clientFields,
                              clientCurrentlyPregnant: value,
                            });
                          } else {
                            setCurrentlyUsingAnyFPMethodDDShow(false);
                            setClientFields({
                              ...clientFields,
                              clientCurrentlyPregnant: value,
                              clientCurrentlyUsingAnyFPMethod: null,
                            });
                            setClientFieldsError({
                              ...clientFieldsError,
                              clientCurrentlyUsingAnyFPMethodError: "",
                            });
                          }
                        }}
                        tabIndex="0"
                        disabled={false}
                      />
                    </div>

                    <div>
                      <InputText
                        inputType="number"
                        //classStar="text-red-500"
                        title="Abortions"
                        name="clientAbortions"
                        maxLength={1}
                        placeholder={"0"}
                        content={clientFields.clientAbortions}
                        controlFunc={(e) => {
                          setClientFields({
                            ...clientFields,
                            clientAbortions: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <InputText
                        inputType="number"
                        //classStar="text-red-500"
                        title="Still Birth"
                        name="Stillbirth"
                        maxLength={1}
                        placeholder={"0"}
                        content={clientFields.clientStillBirth}
                        controlFunc={(e) => {
                          setClientFields({
                            ...clientFields,
                            clientStillBirth: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <RadioGroup
                      title="Ever used a FP method"
                      //classStar="text-red-500"
                      type="radio"
                      setName="clientEverUsedAFPMethod"
                      selectedOptions={clientFields.clientEverUsedAFPMethod}
                      options={yesOrNoRadioBtn}
                      controlFunc={(value) => {
                        setClientFields({
                          ...clientFields,
                          clientEverUsedAFPMethod: value,
                        });
                      }}
                      tabIndex="0"
                      disabled={false}
                    />
                    {currentlyUsingAnyFPMethodDDShow && (
                      <div>
                        <DropDown
                          title={"Currently Using any FP method"}
                          classStar="text-red-500"
                          options={currentlyUsingAnyFPMethodDropDown}
                          placeholder={"Select"}
                          name={"Currently Using any FP method"}
                          selectedOption={
                            clientFields.clientCurrentlyUsingAnyFPMethod
                          }
                          controlFunc={(e) => {
                            setClientFields({
                              ...clientFields,
                              clientCurrentlyUsingAnyFPMethod: e.target.value,
                            });
                          }}
                          controlFuncBlur={(e) => {
                            setClientFieldsError((clientFieldsError) => ({
                              ...clientFieldsError,
                              clientCurrentlyUsingAnyFPMethodError:
                                clientFields.clientCurrentlyUsingAnyFPMethod
                                  ? ""
                                  : "Select Currently Using Any FP Method",
                            }));
                          }}
                        />
                        {clientFieldsError.clientCurrentlyUsingAnyFPMethodError && (
                          <span class="text-red-500 text-sm  font-medium">
                            {
                              clientFieldsError.clientCurrentlyUsingAnyFPMethodError
                            }
                          </span>
                        )}
                      </div>
                    )}
                    <InputText
                      inputType="number"
                      //classStar="text-red-500"
                      title="No. Of Living Children"
                      name="clientNoOfLivingChildren"
                      placeholder={"00"}
                      maxLength={2}
                      content={clientFields.clientNoOfLivingChildren}
                      controlFunc={(e) =>
                        onNumberOfLivingChildrenChanged(e.target.value)
                      }
                      controlFuncBlur={(e) => {
                        if (clientFields.clientNoOfLivingChildren == "") {
                          onNumberOfLivingChildrenChanged("");
                        }
                      }}
                      // controlFunc={(e) => {
                      //   if (/^[0-9]*$/.test(e.target.value)) {
                      //     setClientFields({
                      //       ...clientFields,
                      //       clientNoOfLivingChildren: e.target.value,
                      //     });
                      //     setClientFieldsError({
                      //       ...clientFieldsError,
                      //       clientNoOfLivingChildrenError: "",
                      //     });
                      //     setOnChangeNoOfLivingChildren(true);
                      //   } else {
                      //     setClientFieldsError({
                      //       ...clientFieldsError,
                      //       clientNoOfLivingChildrenError:
                      //         "Enter No. Of Living Children",
                      //     });
                      //   }

                      //   // handleChildrenDetails(e);
                      // }}
                      errorMessage={
                        clientFieldsError.clientNoOfLivingChildrenError
                      }
                    />
                  </div>
                )}
                {genderOptions && visibleNoOfLibvingChildrenTable && (
                  <div
                    className={"grid gap-4 mb-6 xl:grid-cols-2 sm:col-span-1"}
                  >
                    <TableGrid
                      className={mainTable.table}
                      headerData={clientChildrenDetailsHeaders}
                      tableData={bindChildrenDetails}
                      tableHeight="h-36"
                      itemsPerPage={100}
                      currentPage={1}
                      bindClientGenderDD={bindGenderDD}
                      onNoOfLivingChidrensDatas={handleNoOfLivingChidren}
                    />
                    {/* <GridPage
                    header={clientChildrenDetailsHeaders}
                    records={bindChildrenDetails}
                    tableHeight={'h-36'}
                    bindClientGenderDD={bindGenderDD}
                    addClientView={false}
                    /> */}
                  </div>
                )}

                <div className="block mb-2 text-base capitalize font-medium text-neutral-70">
                  Medical History
                </div>
                <div class={mainTable.grid3}>
                  <RadioGroup
                    title="Diabetes"
                    type="radio"
                    setName="clientDiabetes"
                    selectedOptions={clientFields.clientDiabetes}
                    options={yesOrNoRadioBtn}
                    controlFunc={(value) => {
                      setClientFields({
                        ...clientFields,
                        clientDiabetes: value,
                      });
                    }}
                    tabIndex="0"
                    disabled={false}
                  />

                  <RadioGroup
                    title="Hypertension"
                    type="radio"
                    setName="clientHypertension"
                    selectedOptions={clientFields.clientHypertension}
                    options={yesOrNoRadioBtn}
                    controlFunc={(value) => {
                      setClientFields({
                        ...clientFields,
                        clientHypertension: value,
                      });
                    }}
                    tabIndex="0"
                    disabled={false}
                  />

                  <RadioGroup
                    title="Asthma"
                    type="radio"
                    setName="clientAsthma"
                    selectedOptions={clientFields.clientAsthma}
                    options={yesOrNoRadioBtn}
                    controlFunc={(value) => {
                      setClientFields({
                        ...clientFields,
                        clientAsthma: value,
                      });
                    }}
                    tabIndex="0"
                    disabled={false}
                  />

                  <RadioGroup
                    title="Tuberculosis"
                    type="radio"
                    setName="clientTuberculosis"
                    selectedOptions={clientFields.clientTuberculosis}
                    options={yesOrNoRadioBtn}
                    controlFunc={(value) => {
                      setClientFields({
                        ...clientFields,
                        clientTuberculosis: value,
                      });
                    }}
                    tabIndex="0"
                    disabled={false}
                  />

                  <InputText
                    inputType={"text"}
                    ClassDiv="grid gap-2 grid-cols-2"
                    title={"Cancer (specify)"}
                    name={"Cancer(specify)"}
                    content={clientFields.clientCancerSpecify}
                    controlFunc={(e) => {
                      setClientFields({
                        ...clientFields,
                        clientCancerSpecify: e.target.value,
                      });
                    }}
                    controlFuncBlur={() => {
                      const trimmedValue =
                        clientFields.clientCancerSpecify?.trim(); // Remove leading/trailing spaces

                      setClientFields({
                        ...clientFields,
                        clientCancerSpecify: trimmedValue,
                      });
                    }}
                    maxLength={500}
                  />
                  <RadioGroup
                    title="Drug allergies"
                    type="radio"
                    setName="clientDrugAllergies"
                    selectedOptions={clientFields.clientDrugAllergies}
                    options={yesOrNoRadioBtn}
                    controlFunc={(value) => {
                      setClientFields({
                        ...clientFields,
                        clientDrugAllergies: value,
                      });
                    }}
                    tabIndex="0"
                    disabled={false}
                  />
                  <InputText
                    inputType={"text"}
                    ClassDiv="grid gap-2 grid-cols-2"
                    title={"Other (specify)"}
                    name={"Other (specify)"}
                    maxLength={500}
                    content={clientFields.clientOtherSpecify}
                    controlFunc={(e) => {
                      setClientFields({
                        ...clientFields,
                        clientOtherSpecify: e.target.value,
                      });
                    }}
                    controlFuncBlur={() => {
                      const trimmedValue =
                        clientFields.clientOtherSpecify?.trim(); // Remove leading/trailing spaces

                      setClientFields({
                        ...clientFields,
                        clientOtherSpecify: trimmedValue,
                      });
                    }}
                  />
                </div>
                <TextArea
                  inputType="text"
                  title="Additional Notes"
                  name="additionalnotes"
                  maxLength={500}
                  rows="4"
                  content={clientFields.clientAdditionalNotes}
                  placeholder=""
                  controlFunc={(e) => {
                    setClientFields({
                      ...clientFields,
                      clientAdditionalNotes: e.target.value,
                    });
                  }}
                  controlFuncBlur={() => {
                    const trimmedValue =
                      clientFields.clientAdditionalNotes?.trim(); // Remove leading/trailing spaces

                    setClientFields({
                      ...clientFields,
                      clientAdditionalNotes: trimmedValue,
                    });
                  }}
                />
              </form>
              <Buttons
                addScreenTitle={addScreenTitle}
                updateScreenTitle={updateScreenTitle}
                handleSubmit={handleSubmit}
                cancel={clientAddService ? cancelServices : cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />

              {submitServiceButton && (
                <div className="border p-2 flex flex-col items-center justify-center ">
                  <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                    Your ClientId is :
                    <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                      {clientId}
                    </span>
                  </label>

                  <Link
                    to={"/services"}
                    state={{
                      //...data,
                      isAdd: true,
                      isvisible3: false,
                      isData: continueServiceDatas,
                    }}
                    className={mainTable.addButton}
                    // onClick={() => onServiceProvideGrid(rowIndex)}
                    // class="block px-4 py-2 hover:bg-gray-100 "
                  >
                    Continue Service
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleClientDetails}
      >
        <div>
          <PopUpScreenTitle
            title="Client Registration"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <ClientDetails
            data={clientData}
            data1={clientData2}
            data2={clientData3}
            data3={clientData4}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default ClientMaster;
