// Code review - In Progress
// Documentation - In Progress
import React from "react";

const MainPopup = ({
  children,
  popUpOpen,
  setpopUpOpen,
  forgetPopup,
  QRPopup,
  loginPopups,
  notificationPopups,
}) => {
  const handleClose = () => {
    setpopUpOpen(false);
  };
  const handlePopupClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      class={`fixed inset-0 flex overflow-y-auto justify-center z-[999] ${
        popUpOpen ? "block" : "hidden"
      } pointer-events-auto bg-[#00000080]`}
      onClick={handleClose}
    >
      <div
        class={`${
          loginPopups
            ? "m-auto md:w-2/3 lg:w-1/2 w-full mx-3 md:mx-0 relative overflow-hidden"
            : notificationPopups
            ? "lg:w-11/12 lg:mr-10 lg:my-16 m-auto w-full md:mx-auto md:my-auto"
            : forgetPopup
            ? "lg:mr-96 md:mx-auto lg:mx-96"
            : "relative lg:w-9/12 lg:mr-20 lg:my-16 m-auto w-10/12 md:mx-auto md:my-auto"
        }`}
        onClick={handlePopupClick}
      >
        <div
          class={`relative bg-white rounded-lg shadow-2xl shadow-gray-400  `}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainPopup;
