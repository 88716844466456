// Code review - In Progress
//Documentation - In Progress

import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import TableGrid from "../components/Table";
import { appointmentHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import {
  appointmentIndertAPI,
  bindAppointment,
} from "../services/AppointmentAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from "buffer";

function Appointment() {
  const [bindAppointmentDatas, setBindAppointmentDatas] = useState([
    {
      sno: 1,
      appointmentTime: "10:00 am",
      appointmentClient: "Dharampal",
      appointmentService: "FP General Counselling",
      appointmentDate: "02-01-2023",
      appointmentType: "Online",
      appointmentHCP: "Dr. Karuna Boruah",
    },
    {
      sno: 2,
      appointmentTime: "11:00 am",
      appointmentClient: "Harbhajan",
      appointmentService: "FP-OP-COC-Consultation (Includes F/u)",
      appointmentDate: "12-11-2023",
      appointmentType: "Offline",
      appointmentHCP: "Dr. V. Siva Nageswara Rao",
    },
    {
      sno: 3,
      appointmentTime: "06:00 pm",
      appointmentClient: "Tej Bahadur",
      appointmentService: "FP-OP-Centchroman-Consultation (Includes F/u)",
      appointmentDate: "20-10-2023",
      appointmentType: "Online",
      appointmentHCP: "Dr. Karra Ravi Babu",
    },
    {
      sno: 4,
      appointmentTime: "07:00 pm",
      appointmentClient: "Udai patel",
      appointmentService: "FP-OP-Femovan-Consultation (Includes F/u)",
      appointmentDate: "11-09-2023",
      appointmentType: "Offline",
      appointmentHCP: "Dr. Murlidhar Gupta",
    },
    {
      sno: 5,
      appointmentTime: "10:00 am",
      appointmentClient: "Veer munna",
      appointmentService: "FP-OP-POP-Consultation (Includes F/u)",
      appointmentDate: "12-05-2023",
      appointmentType: "Online",
      appointmentHCP: "Dr. Pedapati Rao",
    },
  ]);
  const [bindServiceDD, setBindServiceDD] = useState([
    { value: "1", label: "FP General Counselling" },
    { value: "2", label: "FP-OP-COC-Consultation (Includes F/u)" },
    { value: "3", label: "FP-OP-Centchroman-Consultation (Includes F/u)" },
    { value: "4", label: "FP-OP-Femovan-Consultation (Includes F/u)" },
    { value: "5", label: "FP-OP-POP-Consultation (Includes F/u)" },
    { value: "6", label: "FP-Injectables(3m)-Consultation (Includes F/u)" },
    { value: "7", label: "FP-Condom(M)-Consultation (Includes F/u)" },
    { value: "8", label: "FP-Condom(F)-Consultation (Includes F/u)" },
    { value: "9", label: "FP-IUD-Removal" },
    { value: "10", label: "FP-IUD(10yrs)-Consultation (Includes F/u)" },
    { value: "11", label: "FP-IUD(5yrs)-Consultation (Includes F/u)" },
    { value: "12", label: "FP-Implant 3 Yrs-Removal" },
    { value: "13", label: "FP-Implant 3 Yrs-Consultation (Includes F/u)" },
    { value: "14", label: "FP-VSC(F)-Counselling" },
    { value: "15", label: "FP-VSC(F)-Consultation" },
    { value: "16", label: "FP-VSC(F)-Consultation-Follow-up" },
    { value: "17", label: "FP-VSC(F)-MGMT-Minilaparotomy" },
    { value: "18", label: "FP-VSC(F)-MGMT-Laparoscopy" },
    { value: "19", label: "FP-VSC(M)-Counselling" },
    { value: "20", label: "FP-VSC(M)-Consultation" },
    { value: "21", label: "FP-VSC(M)-Consultation-Follow-up" },
    { value: "22", label: "FP-VSC(M)-MGMT-Non-scalpel Vasectomy" },
    { value: "23", label: "FP-EC-Counselling" },
    { value: "24", label: "FP-EC-Consultation (Includes F/u)" },
    { value: "25", label: "FP-FAB-Fertility Awareness Based-Consultation" },
  ]);
  const [bindAppointment, setBindAppointment] = useState([
    { value: "1", label: "OnLine" },
    { value: "2", label: "Offline" },
  ]);

  const [bindStates, setState1] = useState([
    { value: "1", label: "Arunachal Pradesh" },
    { value: "2", label: "Andhra Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Delhi" },
    { value: "7", label: "Haryana" },
    { value: "8", label: "Himachal Pradesh" },
    { value: "9", label: "Nagaland" },
    { value: "10", label: "Karnataka" },
    { value: "11", label: "Kerala" },
    { value: "12", label: "Rajasthan" },
    { value: "13", label: "Odisha" },
    { value: "14", label: "Goa" },
    { value: "15", label: "Gujarat" },
    { value: "16", label: "Jammu & Kashmir" },
    { value: "17", label: "West Bengal" },
    { value: "18", label: "Uttarakhand" },
    { value: "19", label: "Uttar Pradesh" },
    { value: "20", label: "Maharashtra" },
    { value: "21", label: "Meghalaya" },
    { value: "22", label: "Manipur" },
    { value: "23", label: "Madhya Pradesh" },
    { value: "24", label: "Mizoram" },
    { value: "25", label: "Jharkhand" },
    { value: "26", label: "Punjab" },
    { value: "27", label: "Telangana" },
    { value: "28", label: "Tripura" },
    { value: "29", label: "Tamil Nadu" },
  ]);

  const [bindLocation, setLocation1] = useState([
    { value: "1", label: "Ahmednagar" },
    { value: "2", label: "Amravati" },
    { value: "3", label: "Aurangabad" },
    { value: "4", label: "Beed" },
    { value: "5", label: "Buldhana" },
    { value: "6", label: "Chandrapur" },
    { value: "7", label: "Dhule" },
    { value: "8", label: "Jalgaon" },
    { value: "9", label: "Jalna" },
    { value: "10", label: "Nanded" },
    { value: "11", label: "Gondia" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Sindhudurg" },
    { value: "14", label: "Pune" },
    { value: "15", label: "Thane" },
    { value: "16", label: "Nashik" },
    { value: "17", label: "Nagpur" },
    { value: "18", label: "Osmanabad" },
    { value: "19", label: "Kolhapur" },
    { value: "20", label: "Latur" },
    { value: "21", label: "Mumbai Suburban District" },
    { value: "22", label: "Mumbai" },
    { value: "23", label: "Ratnagiri" },
  ]);
  const [bindArea, setArea1] = useState([
    { value: "1", label: "Achalpur" },
    { value: "2", label: "Ambarnath" },
    { value: "3", label: "Amravati" },
    { value: "4", label: "Barshi" },
    { value: "5", label: "Badlapur" },
    { value: "6", label: "Bhusawal" },
    { value: "7", label: "Buldhana" },
    { value: "8", label: "Jalna" },
    { value: "9", label: "Gondia" },
    { value: "10", label: "Sangli" },
    { value: "11", label: "Dhule" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Hinganghat" },
    { value: "14", label: "Kalyan-Dombivli" },
    { value: "15", label: "Navi Mumbai" },
    { value: "16", label: "PCMC, Pune" },
    { value: "17", label: "Aurangabad" },
    { value: "18", label: "Malegaon" },
    { value: "19", label: "Panvel" },
    { value: "20", label: "Vasai-Virar City MC" },
    { value: "21", label: "Washim" },
    { value: "22", label: "Osmanabad" },
    { value: "23", label: "Nandurbar" },
    { value: "24", label: "Solapur" },
    { value: "25", label: "Wardha" },
    { value: "26", label: "Udgir" },
    { value: "27", label: "Yavatmal" },
    { value: "28", label: "Yavatmal" },
    { value: "29", label: "Nariman Point" },
  ]);
  const [bindSDP, setSdp1] = useState([
    { value: "1", label: "Associated Clinics" },
    { value: "2", label: "CBPs" },
    { value: "3", label: "DHI" },
    { value: "4", label: "Outreach" },
    { value: "5", label: "Static Clinic" },
    { value: "6", label: "Satellite clinic" },
    { value: "7", label: "UFWC" },
  ]);
  const [bindClientName, setBindClientName1] = useState([
    { label: "Bhuvaneshwari (Anoushka)", value: "Bhuvaneshwari" },
    { label: "Bindu (Aishwarya)", value: "Bindu" },
    { label: "Chameli (Malini)", value: "Chameli" },
    { label: "Chandani (Priti)", value: "Chandani" },
    { label: "Damini (Avani)", value: "Damini" },
    { label: "Deepika (Rashmi)", value: "Deepika" },
    { label: "Deepti (Mira)", value: "Deepti" },
    { label: "Dev Dutt (Chandra)", value: "DevDutt" },
    { label: "Dharmender (Bushra)", value: "Dharmender" },
    { label: "Dharampal (Bhavana)", value: "Dharampal" },
    { label: "Damodara (Saraswati)", value: "Damodara" },
    { label: "Ganga (Dipa)", value: "Ganga" },
    { label: "Geeta (Asha)", value: "Geeta" },
    { label: "Geetanjali (Ushas)", value: "Geetanjali" },
    { label: "Gowri (Sumati)", value: "Gowri" },
    { label: "Harbhajan (Vaishnavi)", value: "Harbhajan" },
    { label: "Harcharan (Manpreet)", value: "Harcharan" },
    { label: "Harmeet (Manjusha)", value: "Harmeet" },
    { label: "Heena (Sandhya)", value: "Heena" },
    { label: "Hemlata (Radha)", value: "Hemlata" },
    { label: "Indira (Parvati)", value: "Indira" },
    { label: "Jagdish (Harshada)", value: "Jagdish" },
    { label: "Jamna (Gayathri)", value: "Jamna" },
    { label: "Jasmin  (Padmavati)", value: "Jasmin" },
    { label: "Jassi (Mitra)", value: "Jassi" },
    { label: "Jayalakshmii (Lakshmi)", value: "Jayalakshmii" },
    { label: "Kalpana (Kamakshi)", value: "Kalpana" },
    { label: "Kapila (Durga)", value: "Kapila" },
    { label: "Khushi (Chandra)", value: "Khushi" },
    { label: "Kanhaiya (Shakuntala)", value: "Kanhaiya" },
    { label: "Lakshmi (Rajkumari)", value: "Lakshmi" },
    { label: "Manikya  (Indumathi)", value: "Manikya" },
    { label: "Mayur (Chandrakanta)", value: "Mayur" },
    { label: "Shakti (Arundhati)", value: "Shakti" },
    { label: "Suraj (Ankita)", value: "Suraj" },
    { label: "Tej Bahadur (Aparna)", value: "TejBahadur" },
    { label: "Trilokanath (Amarjeet)", value: "Trilokanath" },
    { label: "Udai patel (Shanti)", value: "Udaipatel" },
    { label: "Upendra (Rupa sonam)", value: "Upendra" },
    { label: "Uttam kumar (Lochana)", value: "Uttamkumar" },
    { label: "Vasudev (Kiran)", value: "Vasudev" },
    { label: "Veer munna (Kalpana)", value: "Veermunna" },
    { label: "Vikram patel (Kajal)", value: "Vikrampatel" },
    { label: "Vijay (Jothi)", value: "Vijay" },
    { label: "Vikrant (Harpreet)", value: "Vikrant" },
    { label: "Vishwa (Divya)", value: "Vishwa" },
    { label: "Virendar (Deepika)", value: "Virendar" },
    { label: "Vivek (Chetana)", value: "Vivek" },
  ]);
  const [bindHcpName, setBindHcpName1] = useState([
    { value: "1", label: "Dr. Karuna Boruah" },
    { value: "2", label: "Dr. V. Siva Nageswara Rao" },
    { value: "3", label: "Dr. Karra Ravi Babu" },
    { value: "4", label: "Dr. Murlidhar Gupta" },
    { value: "5", label: "Dr. Pedapati Rao" },
    { value: "6", label: "Dr. Syed Ahamed" },
    { value: "7", label: "Dr. Randhir Singh" },
    { value: "8", label: "Dr. Mitesh Patel" },
    { value: "9", label: "Dr. Tshering Zomba" },
    { value: "10", label: "Dr. Venkata Siva" },
    { value: "11", label: "Dr. Anand Bhokray" },
    { value: "12", label: "Dr. Pentareddy Rajareddy" },
  ]);
  const [state, setState] = useState(bindStates[19]);
  const [location, setLocation] = useState(bindLocation[21]);
  const [area, setArea] = useState(bindArea[28]);
  const [sdp, setSDP] = useState(bindSDP[4]);
  const [clientName, setclientName] = useState("");
  const [hcpName, setHcpName] = useState("");
  const [service, setService] = useState("");
  const [appointment, setAppointment] = useState("");
  const [appoinmentDate, setAppoinmentDate] = useState("");

  const [loading, setLoading] = useState(false);
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);

  const [updateButton, setUpdateButton] = React.useState(false);
  const [submitButton, setSubmitButton] = React.useState(true);
  const [editMode, setEditMode] = useState([]);
  const [appointmentLink, setAppoinmentLink] = useState("");
  const [appointmentLinks, setAppoinmentLinks] = useState(false);

  const [currentData, setCurrentData] = useState({});
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [searchValue, setSearchValue] = useState(0);

  const [popUpOpen, setpopUpOpen] = useState(false);

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setCurrentPage(1);
    setResult(10);

    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };
  const handleEdit = async (items) => {
    setEditMode(true);
    const data = [
      {}, // Will be used in functionality
    ];
    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setAppoinmentDate(bindAppointmentDatas[items].User_Email);
  };

  function HandleDropDown(e) {}

  const handleSubmit = async (flag) => {
    cancel();

    // const addappointment = [
    //   {
    //     appointmentState: state,
    //     appointmentLocation: location,
    //     appointmentCity: area,
    //     language: sdp,
    //     mstClientId: clientName,
    //     mstHealthCareProviderId: hcpName,
    //     appointmentServices: service,
    //     appointmentType: appointment,
    //     appointmentDate: appoinmentDate,
    //     flag: flag,
    //   },
    // ];

    // if (flag === 1) {
    //   const response = await appointmentIndertAPI(addappointment);

    //   if (response.data.Table[0].Result === "S") {
    //     toast.success("Salary Data Inserted", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //     cancel();
    //   } else {
    //     toast.error("Not Insert Salary Data", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //   }
    // }
  };

  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const zoomApiKey = "hJgcfkoESFe6UM4kbXa9-Q";
        const zoomApiSecret = "GEQObzx2agHmjL1NyNSNbbnF9QGJanFgMazH";
        const tokenEndpoint = "https://zoom.us/oauth/token";
        const refreshToken = "_khJFR8EQVSXTzHQDcf79A";
        const redirectUri = "http://localhost:3000";

        const authHeader = `Basic ${Buffer.from(
          `${zoomApiKey}:${zoomApiSecret}`
        ).toString("base64")}`;

        const tokenResponse = await axios.post(tokenEndpoint, null, {
          params: {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            redirect_uri: redirectUri,
          },
          headers: {
            Authorization: authHeader,
          },
        });
        const newAccessToken = tokenResponse.data.access_token;
        setAccessToken(newAccessToken);
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    const createZoomMeeting = async () => {
      try {
        if (!accessToken) {
          await getAccessToken();
        }
        const zoomApiEndpoint =
          "https://api.zoom.us/v2/users/gokul.xlicon@gmail.com/meetings";
        const response = await axios.post(
          zoomApiEndpoint,
          {
            topic: "My Zoom Meeting",
            type: 2, // Scheduled meeting
            start_time: "2023-10-12T10:00:00Z",
            duration: 60,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        // You can return response.data.join_url or handle it as needed
      } catch (error) {
        console.error("Error creating Zoom meeting:", error);
        // Handle the error or return null as needed
      }
    };
    createZoomMeeting();
  }, [accessToken]);

  const HandleUserDetails = (rowIndex, flag) => {
    setpopUpOpen(true);
    
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 2) {
      setViewDetails(true);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 4) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    setCurrentData(bindAppointmentDatas[rowIndex]);
  };
  const AppointmentLink = ()=>{
    setAppoinmentLinks(true)
  }
  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setAppoinmentDate(formattedDateTime);
  }, []);
  return (
    <MainLayout pageName="1">
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Appointment Scheduling`}</div>
          </div>
          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={HandleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="5" selected>
                    5
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={appointmentHeaders}
            tableData={bindAppointmentDatas}
            onEditGrid={handleEdit}
            onDeleteGrid={HandleUserDetails}
            onBlockGrid={HandleUserDetails}
            onViewGrid={HandleUserDetails}
          />
          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Appointment Scheduling -> Add"}
              </div>
            )}
            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Appointment Scheduling -> Edit"}
              </div>
            )}
            {viewScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Appointment Scheduling -> View"}
              </div>
            )}
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <AutoDropDown
                      title="State"
                      options={bindStates}
                      selectedOption={state}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setState(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Location"
                      options={bindLocation}
                      selectedOption={location}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setLocation(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="S.D.P."
                      options={bindSDP}
                      selectedOption={sdp}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setSDP(value.value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Area"
                      options={bindArea}
                      selectedOption={area}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setArea(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Client Name"
                      options={bindClientName}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setclientName(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Service"
                      options={bindServiceDD}
                      placeholder="Select"
                      name="state"
                      controlFunc={(value) => {
                        setService(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <InputText
                      inputType="datetime-local"
                      title="Date"
                      name="date"
                      content={appoinmentDate}
                      controlFunc={(e) => setAppoinmentDate(e.target.value)}
                    />
                    <AutoDropDown
                      title="Appointment Type"
                      options={bindAppointment}
                      placeholder="Select"
                      name="state"
                      //selectedOption={studentName}
                      controlFunc={(value) => {
                        setAppointment(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Healhcare Provider Name"
                      options={bindHcpName}
                      placeholder="Select"
                      name="state"
                      //selectedOption={studentName}
                      controlFunc={(value) => {
                        setHcpName(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    {/* <DropDown
                      title={"Time Slot"}
                      options={bindTimeSlot}
                      placeholder={"Select"}
                      name={"timeslot"}
                      selectedOption={timeslot}
                      controlFunc={(e) => setTimeSlot(e.target.value)}
                    /> */}
                    
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        className={mainTable.submitButton}
                        onClick={AppointmentLink}
                      >
                        Schedule Appointment
                      </button>
                      {appointmentLinks && (
                    <div class="lg:col-span-2 sm:col-span-1">
                    <InputText
                      inputType="text"
                    
                      name="date"
                      content="https://us05web.zoom.us/j/88385623106?pwd=SqFC5hOanYmAbwi9CVmgLQ8anAgFdb.1

                     "
                      value={appointmentLink}
                      controlFunc={(value) => {
                        setAppoinmentLink(value.value);
                      }}
                    />
                    </div>)}
                  </div>
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}
          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
              {currentData.Emp_Blockedby == 1 ? "Unblock" : "Block"}
            </button>
          )}
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
      <MainPopup
        open={viewDetails}
        closeOnDocumentClick={false}
        onClose={() => setViewDetails(false)}
      >
        <div className="modal-reset">
          <button className="close" onClick={() => setViewDetails(false)}>
            &times;
          </button>
          <div className="modal-content"></div>
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}
          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setViewDetails(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
}

export default Appointment;
