import React from "react";
import { mainTable } from "../css/Common";

const Buttons = ({addScreenTitle,updateScreenTitle,handleSubmit,cancel,disabledBtn,viewSaveButton}) => {
  return (
    <div className={mainTable.submitCancelMainDiv}>
      {addScreenTitle && (
        <button
          data-modal-hide="bottom-right-modal"
          type="button"
          className={disabledBtn ? mainTable.disabledSubmitButton : mainTable.submitButton}
          onClick={() => handleSubmit(0, 1)}
          disabled={disabledBtn}
        >
          Submit
        </button>
      )}
      {updateScreenTitle && (
        <button
          data-modal-hide="bottom-right-modal"
          type="button"
          className={disabledBtn ? mainTable.disabledSubmitButton : mainTable.submitButton}
          onClick={() => handleSubmit(0, 2)}
          disabled={disabledBtn}
        >
          {viewSaveButton ? "SAVE" : "UPDATE"}
        </button>
      )}
      <button
        data-modal-hide="bottom-right-modal"
        type="button"
        className={mainTable.cancelButton}
        onClick={cancel}
      >
        Cancel
      </button>
    </div>
  );
};

export default Buttons;
