// Code review - In Progress
// Documentation - In Progress
import axios from "axios";
var token = localStorage.getItem("jwtToken");
console.log("Token:", token);

const IUDAPI = async (payload, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.post(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
   // `http://localhost:5154/FPAIOutReachAPI/${apiCall}`,
    payload,
    { headers }
  );
  return response;
};

const BindAPI = async (apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.get(
    //  `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
     `http://localhost:5154/FPAIOutReachAPI/${apiCall}`,

    { headers }
  );
  return response;
};

const BindLocalAPI = async (apiCall) => {
  
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
    //  `http://localhost:5154/FPAIOutReachAPI/${apiCall}`,

    { headers }
  );
  return response;
};

const GetLocalAPI = async (payload, apiCall) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const response = await axios.post(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
    //`http://localhost:5154/FPAIOutReachAPI/${apiCall}`,
    payload,

    { headers }
  );
  return response;
};

const BindPostAPI = async (Binddata, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const response = await axios.post(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
    // `http://localhost:5154/FPAIOutReachAPI/${apiCall}`,
    Binddata,
    { headers }
  );
  return response;
};

const ImageAPI = async (imagePayload, apiCall) => {
  var token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  const Imageresponse = await axios.post(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/${apiCall}`,
    //`http://localhost:5154/FPAIOutReachAPI/${apiCall}`,
    imagePayload,
    { headers }
  );

  return Imageresponse;
};

const LanguageTranslatorAPI = async (text, targetLangKey) => {
  const response = await axios.post(
    "https://translation.googleapis.com/language/translate/v2",
    {},
    {
      params: {
        q: text,
        target: targetLangKey,
        key: "AIzaSyD_rr-Z5LQxnxt2PI6gWdhkEQX4koMBol8",
      },
    }
  );
  return response;
};

const authenticationAPI = async (payload, apiCall) => {
  const response = await axios.post(
   // `http://localhost:5154/LoginAPI/${apiCall}`,
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/LoginAPI/${apiCall}`,
    payload
  );
  return response;
};

export {
  IUDAPI,
  BindAPI,
  BindLocalAPI,
  GetLocalAPI,
  ImageAPI,
  LanguageTranslatorAPI,
  BindPostAPI,
  authenticationAPI,
};
