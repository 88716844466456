import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import {
  ServicesAddHeader,
  ServicesClientHeaders,
  ServicesHeaders,
  ServicesHeadersData,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

import MultiSelect from "../ReusableComponents/MultiSelect";
import { useLocation, useNavigate } from "react-router-dom";
import AddServices from "../popup-containers/AddServices";
import IssueOfCommodity from "../popup-containers/IssueOfCommodity";
import DoctorAppoinment from "../popup-containers/DoctorAppoinment";
import GridPage from "../components/GridPage";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import toast, { Toaster } from "react-hot-toast";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useUserCapability } from "../components/ConstantReducerProvider";
import Tooltip from "../ReusableComponents/Tooltip";
import ClientHistory from "../popup-containers/ClientHistotyPopup";

function Services() {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [notificationDesign, setNotificationDesign] = useState(false);
  const [bindServicesHistroyData, setBindServicesHistroyData] = useState([]);
  const [bindReferralDD, setBindReferralDD] = useState([]);
  const [bindServicesName, setBindServicesName] = useState([]);
  const [bindClientDatas, setBindClientDatas] = useState([]);
  const [bindServicesDatas, setBindServicesDatas] = useState([]);
  const [bindDoctorAppointmentDatas, setBindDoctorAppointmentDatas] = useState(
    []
  );
  const [bindMedicineDrugDatas, setBindMedicineDrugDatas] = useState([]);
  const [bindProvideServicesDatas, setBindProvideServicesDatas] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [getBrandDatas, setGetBrandDatas] = useState([]);
  const [bindServicesAddData, setBindServicesAddData] = useState([]);
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const [bindArea, setBindArea] = useState([]);
  const [bindUserSDPDD, setBindUserSDPDD] = useState([]);
  const [bindSDPDD, setBindSDPDD] = useState([]);
  const [bindProject, setBindProject] = useState([]);
  const [bindServiceCategory, setBindServiceCategory] = useState([]);
  const [getServiceSubCDatas, setGetServiceSubCDatas] = useState([]);
  const [getServicesData, setGetServicesDatas] = useState([]);
  const [bindServiceSubCategory, setBindServiceSubCategory] = useState([]);

  const [bindFPUserDD, setBindFPUserDD] = useState([
    { value: "1", label: "F.T.U. " },
    { value: "2", label: "C.U." },
  ]);
  const [bindTestResultTypeDD, setBindTestResultTypeDD] = useState([
    { value: "1", label: "+ve" },
    { value: "2", label: "-ve" },
  ]);
  const [bindPACServices, setBindPACServices] = useState([]);
  const [serviceDatas, setServiceData] = useState({});
  const [serviceDatas1, setServiceData1] = useState({});
  const [serviceDatas2, setServiceData2] = useState({});
  const [popupdata, setPopupdata] = useState({});
  const [upDatedTableData, setUpDatedTableData] = useState([]);

  const [servicesHistorypopUpOpen, setServicesHistorypopUpOpen] =
    useState(false);

  const [clientHistorypopUpOpen, setClientHistorypopUpOpen] = useState(false);
  const [clientHistoryPopupClear, setClientHistoryPopupClear] = useState(false);

  const HandleCommodities = (rowIndex) => {
    setNotificationDesign(true);
    HandleCommoditiesOpen(true);
  };
  const HandleCommoditiesOpen = (isOpen) => {
    setCommoditiespopUpOpen(isOpen);
  };
  const HandleAppoinment = (rowIndex) => {
    const referredToConditions = (data) =>
      Array.isArray(data) &&
      data.some(
        (i) => i.ReferredTo == "1" || i.ReferredTo == "2" || i.ReferredTo == "3"
      );

    const servicesProvideDataCondition =
      referredToConditions(servicesProvideData);
    const servicesProvideDatasCondition =
      referredToConditions(servicesProvideDatas);

    if (servicesProvideDataCondition) {
      HandleAppoinmentOpen(true);
    } else if (servicesProvideDatasCondition) {
      HandleAppoinmentOpen(true);
    } else {
      HandleAppoinmentOpen(false);
    }
  };
  const HandleAppoinmentOpen = (isOpen) => {
    setAppoinmentpopUpOpen(isOpen);
  };

  const HandleServicesHistory = (rowIndex) => {
    handleServicesHistoryOpen(true);

    setServiceData(bindServicesHistroyData[rowIndex]);

    const doctorAppoinmentDatas = Object.values(
      bindDoctorAppointmentDatas
    ).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    setServiceData1(doctorAppoinmentDatas);

    const servicesProvideChild = Object.values(bindProvideServicesDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    setServiceData2(servicesProvideChild[0]);

    const appointmentWithRowData = doctorAppoinmentDatas;

    let appointmentWithInfo;
    let ResultAppointmentWith = "";
    let ResultEmail = "";

    if (
      appointmentWithRowData.length > 0 &&
      appointmentWithRowData[0].AppointmentWith
    ) {
      const filteredData = getLocationDatas.filter(
        (item) =>
          item.MstLocationId === appointmentWithRowData[0].AppointmentWith
      );
      ResultAppointmentWith = filteredData[0].LocationName_en;
      ResultEmail = filteredData[0].Email;
    } else {
      console.warn(
        "appointmentWithRowData is empty or missing AppointmentWith."
      );
    }

    let ResultAppointmentDate = ""; // Default value in case of an empty array or undefined

    if (
      doctorAppoinmentDatas.length > 0 &&
      doctorAppoinmentDatas[0].AppoinmentDate
    ) {
      ResultAppointmentDate = doctorAppoinmentDatas[0].AppoinmentDate;
    } else {
      console.warn("doctorAppoinmentDatas is empty or missing AppoinmentDate.");
    }

    const appointmentAreaRowData = doctorAppoinmentDatas;
    let appointmentAreaInfo;
    let ResultAppointmentArea = { value: "", label: "" }; // Default values

    if (
      appointmentAreaRowData.length > 0 &&
      appointmentAreaRowData[0].MstAreaId
    ) {
      appointmentAreaInfo = bindAppoinmentAreaDD.find(
        (L) =>
          parseInt(L.value) === parseInt(appointmentAreaRowData[0].MstAreaId)
      );

      ResultAppointmentArea = {
        value: appointmentAreaRowData[0].MstAreaId,
        label: appointmentAreaInfo ? appointmentAreaInfo.label : "Unknown",
      };
    } else {
      console.warn("appointmentAreaRowData is empty or missing MstAreaId.");
    }

    var CRSFollowUpPart = bindServicesHistroyData[rowIndex].CRSFollowUp;
    const CRSFollowUpDate = CRSFollowUpPart
      ? CRSFollowUpPart.split("T")[0]
      : null;

    var ReferralFollowUpPart =
      bindServicesHistroyData[rowIndex].ReferralFollowUp;
    const ReferralFollowUpDate = ReferralFollowUpPart
      ? ReferralFollowUpPart.split("T")[0]
      : null;

    const sdpRowData = bindServicesHistroyData[rowIndex];
    const sdpInfo = bindSDPDD.find((L) => L.value === sdpRowData.MstSDPId);
    const ResultSDP = {
      value: sdpRowData.MstSDPId,
      label: sdpInfo ? sdpInfo.label : "Unknown",
    };

    const areaRowData = bindServicesHistroyData[rowIndex];
    const areaInfo = bindArea.find((A) => A.value === areaRowData.MstAreaId);
    const ResultArea = {
      value: areaRowData.MstAreaId,
      label: areaInfo ? areaInfo.label : "Unknown",
    };
    const bindTxnServicesId = bindServicesHistroyData[rowIndex].TxnServicesId;

    const serviceSubCfilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultserviceSubC = serviceSubCfilteredData.map(
      (item) => item.MstServicesSubCategoryId
    );

    const serviceNamefilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultservicesName = serviceNamefilteredData.map(
      (item) => item.MstServicesId
    );

    const issueMedicineDrugDatas = Object.values(bindMedicineDrugDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );

    setCurrentData1(issueMedicineDrugDatas);
    setCurrentData3(servicesFields.sdp, servicesFields.area);
    const ProvideServicesFilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);

    const servicesNameData = ProvideServicesFilteredData.map(
      (item) => item.MstServicesId
    );

    const servicesProvideDatas = Object.values(bindProvideServicesDatas).filter(
      (item) =>
        item.TxnServicesId === bindServicesHistroyData[rowIndex].TxnServicesId
    );
    let updatedTableDatas = "";
    const servicesSelectedvalue = servicesNameData;
    const servicesLabels = getServicesData.filter((item) =>
      servicesSelectedvalue.includes(item.MstServicesId)
    );

    const servicesMap = new Map(
      servicesLabels.map((service) => [service.MstServicesId, service])
    );

    const updatedTableData = servicesProvideDatas.map((data) => {
      const service = servicesMap.get(data.MstServicesId);
      const FPItemProvidedIdOptions = getBrandDatas
        .filter(
          (item) =>
            item.MstCommodityCategoryId === service.MstCommodityCategoryId
        )
        .map((items) => ({
          value: items.MstBrandId,
          label: items.Brand_en,
        }));
      if (service) {
        return {
          ...data,
          value: `${service.ServicesCategory_en}-${service.ServicesSubCategory_en}-${service.Services_en}`,
          TestResultType: service.TestResultType,
          ResultUOM: `${service.ResultUOM}`,
          TestResult: data.TestResult,
          TestResultRadio: data.TestResultRadio,
          Quantity: data.Item,
          FPItemProvidedIdOptions: FPItemProvidedIdOptions,
          MstBrandId: data.MstBrandId,
          Charges: data.Charges,
          FPUser: data.FPUser,
          ReferredTo: data.ReferredTo,
        };
      }

      return data;
    });
    setUpDatedTableData(updatedTableData);

    var Date = bindServicesHistroyData[rowIndex].ServicesDate;
    const formattedDate = Date.split("T")[0];
    const AutoDropdownPopupdata = [
      ResultAppointmentWith,
      CRSFollowUpDate,
      ReferralFollowUpDate,
      ResultSDP,
      ResultArea,
      resultserviceSubC,
      resultservicesName,
      formattedDate,
      ResultEmail,
    ];

    setPopupdata(AutoDropdownPopupdata);
  };

  const HandleClientHistory = (rowIndex) => {
    debugger;
    const filteredData = bindServicesDatas?.filter(
      (item) => item.ClientId == bindClientDatas[rowIndex].ClientId
    );
    if (filteredData.length != 0) {
      setClientHistoryPopupClear(false);
      handleClientHistoryOpen(true);

      setServiceData(bindClientDatas[rowIndex].ClientId);
    } else {
      setClientHistoryPopupClear(true);
      handleClientHistoryOpen(false);
      toast.error(
        `No records for this client id - ${bindClientDatas[rowIndex].ClientId}`,
        {
          position: "top-center",
          autoClose: 3000, // Duration in ms
        }
      );
    }
  };

  const handleServicesHistoryOpen = (isOpen) => {
    setServicesHistorypopUpOpen(isOpen);
  };

  const handleClientHistoryOpen = (isOpen) => {
    setClientHistorypopUpOpen(isOpen);
  };

  const handleClose = () => {
    setpopUpOpen(false);
    setAppoinmentpopUpOpen(false);
    setCommoditiespopUpOpen(false);
    setServicesHistorypopUpOpen(false);
    setClientHistorypopUpOpen(false);
  };

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [visibleServicesAdd, setVisibleServicesAdd] = useState(false);

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [commoditiespopUpOpen, setCommoditiespopUpOpen] = useState(false);
  const [appoinmentpopUpOpen, setAppoinmentpopUpOpen] = useState(false);

  const [visiblePlus, setVisiblePlus] = useState(false);
  const [serviceNameChanged, setServiceNameChanged] = useState(false);

  //Value store States

  const [txnServicesId, setTxnServicesId] = useState("");
  const [serviceSubCategory, setServiceSubCategory] = useState("");
  const [servicesName, setServicesName] = useState("");
  const [serviceSubCategoryError, setServiceSubCategoryError] = useState("");
  const [servicesNameError, setServicesNameError] = useState("");
  const [editDate, setEditDate] = useState("");

  // useEffect(() => {
  //   debugger;
  //   if (servicesName && serviceNameChanged) {
  //     const accumulatedServices = [];

  //     // Use a for loop to iterate over servicesName
  //     for (let i = 0; i < servicesName.length; i++) {
  //       const element = servicesName[i];
  //       const newServicesProvide = bindServicesAddData.filter(
  //         (newService) => newService.MstServicesId == element
  //       );

  //       // Add the new filtered data to the accumulated array
  //       accumulatedServices.push(...newServicesProvide);
  //     }

  //     // Update the state once with the accumulated data
  //     setBindServicesAddData(accumulatedServices);

  //     const servicesSelectedvalue = servicesName;
  //     const servicesLabels = getServicesData.filter((item) =>
  //       servicesSelectedvalue.includes(item.MstServicesId)
  //     );

  //     if (servicesLabels.length > 0) {
  //       const servicesProvideName = servicesLabels.map((service, index) => {
  //         const FPItemProvidedIdOptions = getBrandDatas
  //           .filter(
  //             (item) =>
  //               item.MstCommodityCategoryId === service.MstCommodityCategoryId
  //           )
  //           .map((items) => ({
  //             value: items.MstBrandId,
  //             label: items.Brand_en,
  //           }));

  //         return {
  //           key: `${index}`,
  //           MstServicesCategoryId: `${service.MstServicesCategoryId}`,
  //           MstServicesSubCategoryId: `${service.MstServicesSubCategoryId}`,
  //           MstServicesId: `${service.MstServicesId}`,
  //           value: `${service.Services_en}`,
  //           TestResultType: `${service.TestResultType}`,
  //           ItemsProvided: `${service.ItemsProvided}`,
  //           ResultUOM: `${service.ResultUOM}`,
  //           TestResult: "",
  //           FPItemProvidedIdOptions: FPItemProvidedIdOptions,
  //           FPItemProvidedId: `${service.FPItemProvidedId}`,
  //           MstBrandId: "",
  //           PACServices: "",
  //           MstCommodityCategoryId: `${service.MstCommodityCategoryId}`,
  //           TestResultRadio: "",
  //           Quantity: "",
  //           Charges: "",
  //           FPUser: "",
  //           ReferredTo: "",
  //           MstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
  //           MstUserId: userProfileDatas[0].MstUserId,
  //           MstRoleId: userProfileDatas[0].MstRoleId,
  //           MstBranchProjectId: userProfileDatas[0].MstLocationId,
  //           MstSDPId: servicesFields.sdp,
  //           MstAreaId: servicesFields.area,
  //           MstORWId:
  //             userProfileDatas[0].MstRoleId == 7
  //               ? userProfileDatas[0].MstUserId
  //               : 0,
  //         };
  //       });

  //       const currentData = bindServicesAddData; // Assuming a getter function for current state
  //       const newServicesProvide = servicesProvideName.filter(
  //         (newService) =>
  //           !currentData.some(
  //             (existingService) =>
  //               existingService.MstServicesId == newService.MstServicesId
  //           )
  //       );

  //       // Only update state if there are new services to add
  //       if (newServicesProvide.length > 0) {
  //         const updatedBindServicesAddData = [
  //           ...currentData,
  //           ...newServicesProvide,
  //         ];
  //         setBindServicesAddData(updatedBindServicesAddData);
  //         console.log(
  //           "No matching services found.",
  //           updatedBindServicesAddData
  //         );
  //       }

  //       setServicesProvideData1(servicesProvideName);
  //     } else {
  //       // Handle the case when servicesLabels is empty
  //       console.log("No matching services found.");
  //       setBindServicesAddData([]);
  //     }
  //   }
  // }, [servicesName ? servicesName : null]);

  const [servicesFields, setServicesFields] = useState({
    branchId: "",
    clientId: "",
    clientName: "",
    motherName: "",
    phoneNumber: "",
    date: new Date(),
    reasonForVisit: "",
    sdp: "",
    area: "",
    project: "",
    serviceCategory: "",
    renewalDate: "",
    referralfollowupDate: "",
    servicesRemark: "",
    charges: "",
  });
  const [servicesFieldsError, setServicesFieldsError] = useState({
    sdpError: "",
    areaError: "",
    projectError: "",
    serviceCategoryError: "",
    reasonForVisitError: "",
    servicesNameError: "",
    chargesError: "",
  });

  const [tooltipText, setTooltipText] = useState();
  const location1 = useLocation();
  const serviceAdd = location1?.state?.isAdd;

  const [addClientVisible, setAddClientVisible] = useState(true);
  // useEffect(() => {
  //   if (serviceAdd) {
  //     setVisibleAdd(true);
  //     setVisibleTable(false);
  //     setVisiblePlus(serviceAdd);
  //     setAddScreenTitle(true);
  //     setIssuePopupCancel(false);
  //     setDoctorPopupCancel(false);
  //     const rowclient = location1?.state?.isData;
  //     console.log("rowclient", rowclient);
  //     const currentDate = new Date();
  //     const formattedDate = currentDate.toISOString().split("T")[0];
  //     setCurrentData1(false);

  //     if (userProfileDatas.length == 1) {
  //       setServicesFields({
  //         ...servicesFields,
  //         clientId: rowclient?.ClientId ?? "",
  //         clientName: rowclient?.ClientName_en ?? "",
  //         motherName: rowclient?.ClientMotherName_en ?? "",
  //         phoneNumber: rowclient?.ClientPhoneNo ?? "",
  //         date: formattedDate,
  //         branchId: userProfileDatas[0]?.MstLocationId,
  //         // sdp: userProfileDatas[0]?.MstSDPId,
  //         sdp: rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
  //         area: rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
  //       });
  //     } else {
  //       setServicesFields({
  //         ...servicesFields,
  //         clientId: rowclient?.ClientId ?? "",
  //         clientName: rowclient?.ClientName_en ?? "",
  //         motherName: rowclient?.ClientMotherName_en ?? "",
  //         phoneNumber: rowclient?.ClientPhoneNo ?? "",
  //         date: formattedDate,
  //         branchId: userProfileDatas[0]?.MstLocationId,
  //         sdp: rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
  //         area: rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
  //       });
  //     }

  //     const clientN = rowclient?.ClientName_en;
  //     const maxLength = 30;
  //     let truncatedLabelText = clientN?.slice(0, maxLength);

  //     if (clientN?.length > maxLength) {
  //       truncatedLabelText += "...";
  //     }

  //     setTooltipText(truncatedLabelText);
  //   }
  // }, [serviceAdd]);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    navigate("/clientmaster", { state: { isAddClient: true, menuId: 3 } });
  };
  const location = useLocation();
  const ServicesClient = location?.state?.isServices;
  useEffect(() => {
    if (ServicesClient) {
      setAddClientVisible(true);
    }
  }, []);

  const cancel = () => {
    setSubmitBtnDisabled(false);
    setIssuePopupCancel(true);
    setDoctorPopupCancel(true);
    handleServicesHistoryOpen(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    BindGridServicesData(userProfileDatas);
    BindGridClientData(userProfileDatas);
    setVisibleServicesAdd("");
    setServicesProvideData([]);
    setServicesProvideDatas([]);
    setServicesProvideData1([]);
    setBindProvideServicesDatas([]);
    setBindServicesName();
    setBindServiceSubCategory();
    navigate("/services", {
      state: {
        menuId: 28,
      },
    });
    setpopUpOpen(false);
    setServicesFields({
      branchId: "",
      clientId: "",
      clientName: "",
      motherName: "",
      phoneNumber: "",
      sdp: "",
      area: "",
      project: "",
      serviceCategory: "",
      renewalDate: "",
      referralfollowupDate: "",
      servicesRemark: "",
    });
    setServicesFieldsError({
      sdpError: "",
      areaError: "",
      projectError: "",
      serviceCategoryError: "",
      chargesError: "",
      reasonForVisitError: "",
    });
    setServiceSubCategoryError("");
    setServicesNameError("");

    setCurrentData1({});
    setCurrentData3([]);
    setCurrentData2("");
    setServiceSubCategory("");
    setServicesName("");
    setServiceData("");
    setServiceData1("");
    setServiceData2("");
    setPopupdata("");
    setBindServicesAddData([]);
  };

  const cancel1 = () => {
    setIssuePopupCancel(true);
    setDoctorPopupCancel(true);
    handleServicesHistoryOpen(false);
    setClientHistorypopUpOpen(false);
    setClientHistoryPopupClear(true);
  };

  const handleEdit = async (rowIndex) => {
    debugger;
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(true);
    setVisibleServicesAdd(true);
    setIssuePopupCancel(false);
    setDoctorPopupCancel(false);

    const filteredData = Object.values(bindServicesDatas).filter(
      (item) => item.ClientId === bindServicesDatas[rowIndex].ClientId
    );
    setBindServicesHistroyData(filteredData);

    const currentCRSFollowUpDate = bindServicesDatas[rowIndex].CRSFollowUp;
    const formattedCRSFollowUpDate = currentCRSFollowUpDate
      ? currentCRSFollowUpDate.split("T")[0]
      : null;

    const currentReferralFollowUpDate =
      bindServicesDatas[rowIndex].ReferralFollowUp;
    const formattedReferralFollowUpDate = currentReferralFollowUpDate
      ? currentReferralFollowUpDate.split("T")[0]
      : null;

    const bindTxnServicesId = bindServicesDatas[rowIndex].TxnServicesId;

    const ProvideServicesFilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);

    // const serviceSubCategory = ProvideServicesFilteredData.map(
    //   (item) => item.MstServicesSubCategoryId
    // );
    const servicesNameData = ProvideServicesFilteredData.map(
      (item) => item.MstServicesId
    );

    const servicesCategoryData = ProvideServicesFilteredData.map(
      (item) => item.MstServicesCategoryId
    );

    // setServiceSubCategory(serviceSubCategory);
    // setServicesName(servicesNameData);
    setEditDate(bindServicesDatas[rowIndex].ServicesDate);
    var Date = bindServicesDatas[rowIndex].ServicesDate;
    const formattedDate = Date.split("T")[0];
    setServicesFields({
      ...servicesFields,
      branchId: userProfileDatas[0]?.MstLocationId,
      clientId: bindServicesDatas[rowIndex].ClientId,
      date: formattedDate,
      reasonForVisit: bindServicesDatas[rowIndex].ReasonForVisit_en,
      clientName: bindServicesDatas[rowIndex].ClientName_en,
      motherName: bindServicesDatas[rowIndex].ClientMotherName_en,
      phoneNumber: bindServicesDatas[rowIndex].ClientPhoneNo,
      sdp: bindServicesDatas[rowIndex].MstSDPId,
      area: bindServicesDatas[rowIndex].MstAreaId,
      project: bindServicesDatas[rowIndex].MstProject,
      // serviceCategory: bindProvideServicesDatas[rowIndex].MstServicesCategoryId,
      // serviceCategory: servicesCategoryData[0],
      renewalDate: formattedCRSFollowUpDate,
      referralfollowupDate: formattedReferralFollowUpDate,
      servicesRemark: bindServicesDatas[rowIndex].Remarks_en,
    });

    setTxnServicesId(bindServicesDatas[rowIndex].TxnServicesId);

    const issueMedicineDrugDatas = Object.values(bindMedicineDrugDatas).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );

    setCurrentData1(issueMedicineDrugDatas);
    setCurrentData3([
      bindServicesDatas[rowIndex].MstSDPId,
      bindServicesDatas[rowIndex].MstAreaId,
      bindServicesDatas[rowIndex].ServicesId,
      2,
    ]);
    setIssueMedicineDrugDatas(issueMedicineDrugDatas);
    const doctorAppoinmentDatas = Object.values(
      bindDoctorAppointmentDatas
    ).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );
    setDoctorAppoinmentDatas(doctorAppoinmentDatas[0]);
    setCurrentData2(doctorAppoinmentDatas);
    console.log("doctorAppoinmentDatas", doctorAppoinmentDatas[0]);

    const servicesProvideDatas = Object.values(bindProvideServicesDatas).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );

    const servicesSelectedvalue = servicesNameData;
    const servicesLabels = getServicesData.filter((item) =>
      servicesSelectedvalue.includes(item.MstServicesId)
    );

    const servicesMap = new Map(
      servicesLabels.map((service) => [service.MstServicesId, service])
    );

    const updatedTableData = servicesProvideDatas.map((data) => {
      const service = servicesMap.get(data.MstServicesId);
      const FPItemProvidedIdOptions = getBrandDatas
        .filter(
          (item) =>
            item.MstCommodityCategoryId === service.MstCommodityCategoryId
        )
        .map((items) => ({
          value: items.MstBrandId,
          label: items.Brand_en,
        }));
      if (service) {
        return {
          ...data,
          value: `${service.Services_en}`,
          TestResultType: service.TestResultType,
          UOM: service.ResultUOM,
          ResultUOM: `${service.ResultUOM}`,
          TestResult: data.TestResult,
          TestResultRadio: data.TestResultRadio,
          ItemsProvided: data.ItemsProvided,
          Quantity: data.Item,
          FPItemProvidedIdOptions: FPItemProvidedIdOptions,
          MstBrandId: data.MstBrandId,
          FPItemProvidedId: `${service.FPItemProvidedId}`,
          MstCommodityCategoryId: `${service.MstCommodityCategoryId}`,
          Charges: data.Charges,
          FPUser: data.FPUser,
          PACServices: data.PACServices,
          ReferredTo: data.ReferredTo,
          MstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          MstUserId: userProfileDatas[0].MstUserId,
          MstRoleId: userProfileDatas[0].MstRoleId,
          MstBranchProjectId: userProfileDatas[0].MstLocationId,
          MstSDPId: bindServicesDatas[rowIndex].MstSDPId,
          MstAreaId: bindServicesDatas[rowIndex].MstAreaId,
          MstORWId:
            userProfileDatas[0].MstRoleId == 7
              ? userProfileDatas[0].MstUserId
              : 0,
          ServicesId: bindServicesDatas[rowIndex].ServicesId,
          Flag: 2,
        };
      }
      return data;
    });

    setBindServicesAddData(updatedTableData);
    setServicesProvideDatas(updatedTableData);
  };
  const goValidate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (!servicesFields.serviceCategory) {
      errors.serviceCategory = "Select Service Category";
    }

    if (!serviceSubCategory) {
      errors.serviceSubCategory = "Select Service Sub-Category";
    }
    if (!servicesName) {
      errors.servicesName = "Select Service";
    }

    setServiceSubCategoryError(errors.serviceSubCategory || "");
    setServicesNameError(errors.servicesName || "");
    setServicesFieldsError({
      ...servicesFieldsError,

      serviceCategoryError: errors.serviceCategory || "",
    });
    console.log("aaaaa", servicesFieldsError.projectError);
    return errors;
  };

  const HandleGo = () => {
    debugger;
    const firstNameValidation = goValidate();

    if (Object.keys(firstNameValidation).length === 0) {
      setVisibleServicesAdd(true);

      if (servicesName && serviceNameChanged) {
        const accumulatedServices = [];

        // Use a for loop to iterate over servicesName
        for (let i = 0; i < servicesName.length; i++) {
          const element = servicesName[i];
          const newServicesProvide = bindServicesAddData.filter(
            (newService) => newService.MstServicesId == element
          );

          // Add the new filtered data to the accumulated array
          accumulatedServices.push(...newServicesProvide);
        }

        // Update the state once with the accumulated data
        setBindServicesAddData(accumulatedServices);

        const servicesSelectedvalue = servicesName;
        const servicesLabels = getServicesData.filter((item) =>
          servicesSelectedvalue.includes(item.MstServicesId)
        );

        if (servicesLabels.length > 0) {
          const servicesProvideName = servicesLabels.map((service, index) => {
            const FPItemProvidedIdOptions = getBrandDatas
              .filter(
                (item) =>
                  item.MstCommodityCategoryId === service.MstCommodityCategoryId
              )
              .map((items) => ({
                value: items.MstBrandId,
                label: items.Brand_en,
              }));

            return {
              key: `${index}`,
              MstServicesCategoryId: `${service.MstServicesCategoryId}`,
              MstServicesSubCategoryId: `${service.MstServicesSubCategoryId}`,
              MstServicesId: `${service.MstServicesId}`,
              value: `${service.Services_en}`,
              TestResultType: `${service.TestResultType}`,
              ItemsProvided: `${service.ItemsProvided}`,
              ResultUOM: `${service.ResultUOM}`,
              TestResult: "",
              FPItemProvidedIdOptions: FPItemProvidedIdOptions,
              FPItemProvidedId: `${service.FPItemProvidedId}`,
              MstBrandId: "",
              PACServices: "",
              MstCommodityCategoryId: `${service.MstCommodityCategoryId}`,
              TestResultRadio: "",
              Quantity: "",
              Charges: "",
              FPUser: "",
              ReferredTo: "",
              MstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
              MstUserId: userProfileDatas[0].MstUserId,
              MstRoleId: userProfileDatas[0].MstRoleId,
              MstBranchProjectId: userProfileDatas[0].MstLocationId,
              MstSDPId: servicesFields.sdp,
              MstAreaId: servicesFields.area,
              MstORWId:
                userProfileDatas[0].MstRoleId == 7
                  ? userProfileDatas[0].MstUserId
                  : 0,
            };
          });

          const currentData = bindServicesAddData; // Assuming a getter function for current state
          const newServicesProvide = servicesProvideName.filter(
            (newService) =>
              !currentData.some(
                (existingService) =>
                  existingService.MstServicesId == newService.MstServicesId
              )
          );

          // Only update state if there are new services to add
          if (newServicesProvide.length > 0) {
            const updatedBindServicesAddData = [
              ...currentData,
              ...newServicesProvide,
            ];
            setBindServicesAddData(updatedBindServicesAddData);
            console.log(
              "No matching services found.",
              updatedBindServicesAddData
            );
          }

          setServicesProvideData1(servicesProvideName);
        } else {
          // Handle the case when servicesLabels is empty
          console.log("No matching services found.");
          setBindServicesAddData([]);
        }
      }

      const filteredData = Object.values(bindServicesDatas).filter(
        (item) => item.ClientId === servicesFields.clientId
      );
      setBindServicesHistroyData(filteredData);
    }
  };

  const createdBy = sessionStorage.getItem("createdBy");
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the Youth group Id input

      // Validation logic for sdp input
      if (servicesFields.sdp == "") {
        errors.sdp = "Select SDP";
      }
      // Validation logic for the project input
      // if (servicesFields.project == "") {
      //   errors.project = "Select Project";
      // }
      // Validation logic for the area input
      if (servicesFields.area == "") {
        errors.area = "Select Area";
      }
      // Validation logic for the serviceCategory input
      // if (!servicesFields.serviceCategory) {
      //   errors.serviceCategory = "Select Service Category";
      // }

      // if (!serviceSubCategory) {
      //   errors.serviceSubCategory = "Select Service Sub-Category";
      // }
      // if (!servicesName) {
      //   errors.servicesName = "Select Service";
      // }
      if (servicesFields.reasonForVisit == "") {
        errors.reasonForVisit = "Enter reasonForVisit";
      }
    }
    setServiceSubCategoryError(errors.serviceSubCategory || "");
    setServicesNameError(errors.servicesName || "");
    setServicesFieldsError({
      ...servicesFieldsError,
      sdpError: errors.sdp || "",
      areaError: errors.area || "",
      projectError: errors.project || "",
      serviceCategoryError: errors.serviceCategory || "",
      reasonForVisitError: errors.reasonForVisit || "",
    });
    console.log("aaaaa", servicesFieldsError.projectError);
    return errors;
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      const firstNameValidation = validate(flag);
      let servicesProvideValues = [];

      if (servicesProvideData.length > 0) {
        servicesProvideValues = servicesProvideData;
      } else if (servicesProvideDatas.length > 0) {
        servicesProvideValues = servicesProvideDatas;
      } else {
        servicesProvideValues = servicesProvideData1;
      }

      let doctorAppointmentDataValues;

      if (Object.keys(docterAppoinmetData).length !== 0) {
        doctorAppointmentDataValues = docterAppoinmetData;
      } else {
        doctorAppointmentDataValues = doctorAppoinmentDatas;
      }

      let issueCommodityDataValues = [];

      if (issueCommodityData.length > 0) {
        issueCommodityDataValues = issueCommodityData;
      } else {
        let transformedData = issueMedicineDrugDatas.map((item) => {
          return {
            commodity: item.MstCommodityTypeId,
            commodityCategory: item.MstCommodityCategoryId,
            commodityName: item.MstCommodityId,
            brand: item.MstBrandId,
            quantity: item.Quantity.toString(),
            uom: item.UOM,
            charges: item.Charges.toString(),
          };
        });

        console.log(transformedData);
        issueCommodityDataValues = JSON.stringify(transformedData);
      }

      const languageKeys = [
        "ta", // Tamil
        "hi", // Hindi
        "mr", // Marathi
        "gu", // Gujarati
        "kn", // Kannada
        "ml", // Malayalam
        "te", // Telugu
        "bn", // Bengali
        "or", // Oriya
      ];

      const translatedReasonForVisit = []; // Create an array to store translations

      for (let i of languageKeys) {
        const translateText = await LanguageTranslatorAPI(
          servicesFields.reasonForVisit,
          i
        );
        translatedReasonForVisit.push(
          translateText.data.data.translations[0].translatedText
        );
      }

      const translatedClientName = []; // Create an array to store translations

      for (let i of languageKeys) {
        const translateText = await LanguageTranslatorAPI(
          servicesFields.clientName,
          i
        );
        translatedClientName.push(
          translateText.data.data.translations[0].translatedText
        );
      }

      const translatedMotherName = []; // Create an array to store translations

      for (let i of languageKeys) {
        const translateText = await LanguageTranslatorAPI(
          servicesFields.motherName,
          i
        );
        translatedMotherName.push(
          translateText.data.data.translations[0].translatedText
        );
      }

      const translatedRemarks = []; // Create an array to store translations
      if (servicesFields.servicesRemark) {
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            servicesFields.servicesRemark,
            i
          );
          translatedRemarks.push(
            translateText.data.data.translations[0].translatedText
          );
        }
      }

      if (Object.keys(firstNameValidation).length === 0) {
        const data = {
          txnServicesId: txnServicesId ? txnServicesId : 0,
          mstBranchProjectId: servicesFields.branchId
            ? servicesFields.branchId
            : 0,
          mstORWId:
            userProfileDatas[0].MstRoleId == 7
              ? userProfileDatas[0].MstUserId
              : null,
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstRoleId: userProfileDatas[0].MstRoleId,
          servicesDate:flag ==1 ? servicesFields.date :editDate,

          reasonForVisit_en: servicesFields.reasonForVisit,
          reasonForVisit_ta: translatedReasonForVisit[0],
          reasonForVisit_hi: translatedReasonForVisit[1],
          reasonForVisit_mr: translatedReasonForVisit[2],
          reasonForVisit_gu: translatedReasonForVisit[3],
          reasonForVisit_kn: translatedReasonForVisit[4],
          reasonForVisit_ml: translatedReasonForVisit[5],
          reasonForVisit_te: translatedReasonForVisit[6],
          reasonForVisit_bn: translatedReasonForVisit[7],
          reasonForVisit_or: translatedReasonForVisit[8],

          clientId: servicesFields.clientId,
          clientName_en: servicesFields.clientName,

          clientName_ta: translatedClientName[0],
          clientName_hi: translatedClientName[1],
          clientName_mr: translatedClientName[2],
          clientName_gu: translatedClientName[3],
          clientName_kn: translatedClientName[4],
          clientName_ml: translatedClientName[5],
          clientName_te: translatedClientName[6],
          clientName_bn: translatedClientName[7],
          clientName_or: translatedClientName[8],

          clientMotherName_en: servicesFields.motherName,

          clientMotherName_ta: translatedMotherName[0],
          clientMotherName_hi: translatedMotherName[1],
          clientMotherName_mr: translatedMotherName[2],
          clientMotherName_gu: translatedMotherName[3],
          clientMotherName_kn: translatedMotherName[4],
          clientMotherName_ml: translatedMotherName[5],
          clientMotherName_te: translatedMotherName[6],
          clientMotherName_bn: translatedMotherName[7],
          clientMotherName_or: translatedMotherName[8],

          clientPhoneNo: servicesFields.phoneNumber,
          mstSDPId: servicesFields.sdp,
          mstAreaId: servicesFields.area,
          mstProject: servicesFields.project ? servicesFields.project : null,
          CRSFollowUp:
            servicesFields.renewalDate &&
            servicesFields.renewalDate.trim() !== ""
              ? new Date(servicesFields.renewalDate)
              : null,

          ReferralFollowUp:
            servicesFields.referralfollowupDate &&
            servicesFields.referralfollowupDate.trim() !== ""
              ? new Date(servicesFields.referralfollowupDate)
              : null,

          remarks_en: servicesFields.servicesRemark,
          remarks_ta: translatedRemarks[0],
          remarks_hi: translatedRemarks[1],
          remarks_mr: translatedRemarks[2],
          remarks_gu: translatedRemarks[3],
          remarks_kn: translatedRemarks[4],
          remarks_ml: translatedRemarks[5],
          remarks_te: translatedRemarks[6],
          remarks_bn: translatedRemarks[7],
          remarks_or: translatedRemarks[8],

          servicesProvide: JSON.stringify(servicesProvideValues),

          servicesMedicineDrugs: issueCommodityDataValues,

          servicesDoctor: JSON.stringify(doctorAppointmentDataValues),

          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "TXNServicesAPI/IUDTXNServices");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("Services data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "U") {
            toast.success("Services data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Services data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        txnServicesId: bindServicesDatas[rowIndex].TxnServicesId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "TXNServicesAPI/IUDTXNServices");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success("Services data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Services data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success("Services data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Services data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success("Services data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Services data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  useEffect(() => {
    BindGridClientData(userProfileDatas);
    BindGridServicesData(userProfileDatas);
  }, [userProfileDatas]);

  const BindGridClientData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0]?.MstSDPId
          ? userProfileDatas[0]?.MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI([data], "ClientAPI/BindClient");
      const filteredData = response.data.Table.filter(
        (item) => item.BlockedBy === null
      );
      setBindClientDatas(filteredData);
    }
  };
  const BindGridServicesData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0]?.MstSDPId
          ? userProfileDatas[0]?.MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "TXNServicesAPI/BindTXNServices"
      );

      console.log("response", response);
      setBindServicesDatas(response.data.Table);
      setBindDoctorAppointmentDatas(response.data.Table1);
      setBindMedicineDrugDatas(response.data.Table2);
      setBindProvideServicesDatas(response.data.Table3);
    }
  };

  const HandleDelete = (rowIndex) => {
    debugger;
    const updatedTableData = bindServicesAddData.filter(
      (_, index) => index !== rowIndex
    );

    // Update the state with the filtered data
    setBindServicesAddData(updatedTableData);

    // // Clear the MstServicesId for the deleted row
    // const deletedRow = parseInt(bindServicesAddData[rowIndex].MstServicesId);
    // const deleteServiceId = servicesName.filter(
    //   (serviceId) => serviceId !== deletedRow
    // );

    // setServicesName(deleteServiceId); // Adjust according to your requirement
  };

  const handleReferralChange = (rowIndex) => {};

  const navigate = useNavigate();

  const serviceLocation = useLocation();

  const handleServicesFieldsChange = (value, name) => {
    setServicesFields({
      ...servicesFields,
      [name]: value,
    });
  };

  const servicesSubCategoryChange = (updatedOptions) => {
    setServicesName("");
    setServiceSubCategory(updatedOptions);
  };
  const servicesNameChange = (updatedOptions) => {
    setServicesName(updatedOptions);
    setServiceNameChanged(true);
  };

  useEffect(() => {
    debugger;
    const fetchDataAndSetState = async () => {
      try {
        // Bind SDP Dropdown datas
        const sdpResponse = await BindAPI("SDPAPI/GetSDP");
        const sdpData = sdpResponse.data.Table.map((item) => ({
          value: item.MstSDPId,
          label: item.SDP_en,
        }));
        setBindSDPDD(sdpData);
        const locationResponse = await BindAPI("LocationAPI/GetLocation");
        setGetLocationDatas(locationResponse.data.Table);
        // Fetch AREA Dropdown datas
        const locationResponse1 = await BindAPI("AreaAPI/GetArea");
        setGetAreaDatas(locationResponse1.data.Table);
        const commoditiesBrands = await BindAPI("BrandAPI/BindBrand");

        const filteredData3 = commoditiesBrands.data.Table;
        const filteredWithBlockedByNull3 = filteredData3.filter(
          (item) => item.BlockedBy === null
        );
        setGetBrandDatas(filteredWithBlockedByNull3);
        // Fetch service sub-category Dropdown datas
        const serviceSubCategoryResponse = await BindAPI(
          "ServicesSubCategoryAPI/BindServicesSubCategory"
        );
        setGetServiceSubCDatas(serviceSubCategoryResponse.data.Table);

        // Fetch service Dropdown datas
        const servicesResponse = await BindAPI(
          "ServicesMasterAPI/BindServices"
        );
        setGetServicesDatas(servicesResponse.data.Table);

        if (userProfileDatas[0]?.MstSDPId) {
          const sdpData = sdpResponse.data.Table.filter(
            (item) => item.MstSDPId === userProfileDatas[0].MstSDPId
          ).map((item) => ({
            value: item.MstSDPId,
            label: item.SDP_en,
          }));

          setBindUserSDPDD(sdpData);
        } else {
          const sdpLabels = sdpResponse.data.Table.filter(
            (item) => item.MstLocationId === userProfileDatas[0]?.MstLocationId
          ).map((item) => ({
            MstSDPId: item.MstSDPId,
            SDP_en: item.SDP_en,
          }));

          const sdpSelected = sdpLabels.map((AR) => ({
            value: AR.MstSDPId,
            label: AR.SDP_en,
          }));

          setBindUserSDPDD(sdpSelected);
        }

        // After fetching data, proceed to set the servicesFields if serviceAdd is true
        if (serviceAdd) {
          setVisibleAdd(true);
          setVisibleTable(false);
          setVisiblePlus(serviceAdd);
          setAddScreenTitle(true);
          setIssuePopupCancel(false);
          setDoctorPopupCancel(false);
          setCurrentData1(false);

          const rowclient = location1?.state?.isData;
          const currentDate = new Date();
          const formattedDate = currentDate.toISOString().split("T")[0];

          if (userProfileDatas.length == 1) {
            setServicesFields({
              ...servicesFields,
              clientId: rowclient?.ClientId ?? "",
              clientName: rowclient?.ClientName_en ?? "",
              motherName: rowclient?.ClientMotherName_en ?? "",
              phoneNumber: rowclient?.ClientPhoneNo ?? "",
              date: formattedDate,
              branchId: userProfileDatas[0]?.MstLocationId,
              // sdp: userProfileDatas[0]?.MstSDPId,
              sdp: rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
              area: rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
            });
            setCurrentData3([
              rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
              rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
            ]);
          } else {
            setServicesFields({
              ...servicesFields,
              clientId: rowclient?.ClientId ?? "",
              clientName: rowclient?.ClientName_en ?? "",
              motherName: rowclient?.ClientMotherName_en ?? "",
              phoneNumber: rowclient?.ClientPhoneNo ?? "-",
              date: formattedDate,
              branchId: userProfileDatas[0]?.MstLocationId,
              sdp: rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
              area: rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
            });
            setCurrentData3([
              rowclient?.MstSDPId ?? userProfileDatas[0]?.MstSDPId,
              rowclient?.MstAreaId ?? userProfileDatas[0]?.MstAreaId,
            ]);
          }

          const clientN = rowclient?.ClientName_en;
          const maxLength = 30;
          let truncatedLabelText = clientN?.slice(0, maxLength);

          if (clientN?.length > maxLength) {
            truncatedLabelText += "...";
          }

          setTooltipText(truncatedLabelText);
        }
      } catch (error) {
        console.error("Error fetching dropdown data", error);
      }
    };

    fetchDataAndSetState();
  }, [serviceAdd]);

  useEffect(() => {
    // Bind Area Dropdown datas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == servicesFields.sdp
    );
    console.log("areaSelectedValue", areaSelectedValue);
    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId,
        label: AR.AreaName_en,
      }));
      setBindAreaDD(areaSelected);
    } else {
    }
  }, [servicesFields.sdp]);

  useEffect(() => {
    // Bind Area Dropdown datas
    const dropDownDatas = async () => {
      try {
        const areaResponse = await BindAPI("AreaAPI/GetArea");

        const areaData = areaResponse.data.Table.map((item) => ({
          value: item.MstAreaId,
          label: item.AreaName_en,
        }));

        setBindArea(areaData);
      } catch (error) {}
    };

    dropDownDatas();
  }, []);

  useEffect(() => {
    // Bind Project Dropdown datas
    const ProjectDropDownData = async () => {
      try {
        const projectResponse = await BindAPI("MasterDataAPI/BindProject");

        const ProjectData = projectResponse.data.Table.map((item) => ({
          value: item.MstProjectId,
          label: item.ProjectName_en,
        }));

        setBindProject(ProjectData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };
    // Bind Service Category  Dropdown datas
    const serviceCategoryDD = async () => {
      try {
        const serviceCategoryResponse = await BindAPI(
          "ServicesCategoryAPI/BindServicesCategory"
        );

        const serviceCategoryData = serviceCategoryResponse.data.Table.map(
          (item) => ({
            value: item.MstServicesCategoryId,
            label: item.ServicesCategory_en,
          })
        );

        setBindServiceCategory(serviceCategoryData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    const ReferredToDD = async () => {
      try {
        const referredToResponse = await BindAPI(
          "MasterDataAPI/BindReferredTo"
        );
        const bindServicesDropDownsData = await BindAPI(
          "ServicesMasterAPI/BindServicesDropDowns"
        );
        const referredToData = referredToResponse.data.Table.map((item) => ({
          value: item.MstReferredToId,
          label: item.ReferredTo_en,
        }));
        const bindPACServicesDD =
          bindServicesDropDownsData.data.Table1.length > 0 &&
          bindServicesDropDownsData.data.Table1.map((m) => ({
            value: m.MstPACServicesId,
            label: m.PACServices,
          }));
        setBindPACServices(bindPACServicesDD);
        setBindReferralDD(referredToData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };
    ReferredToDD();
    serviceCategoryDD();
    ProjectDropDownData();
  }, []);

  useEffect(() => {
    // Bind Services Sub Category Dropdown datas
    const serviceSubCategorySelectedValue = getServiceSubCDatas.find(
      (item) => item.MstServicesCategoryId == servicesFields.serviceCategory
    );

    setBindServiceSubCategory([]);
    if (serviceSubCategorySelectedValue) {
      const serviceSubCategoryLabels = getServiceSubCDatas
        .filter(
          (item) =>
            item.MstServicesCategoryId ===
            serviceSubCategorySelectedValue.MstServicesCategoryId
        )
        .map((item) => ({
          MstServicesSubCategoryId: item.MstServicesSubCategoryId,
          ServicesSubCategory_en: item.ServicesSubCategory_en,
        }));

      const servicesSubCategorySelected = serviceSubCategoryLabels.map(
        (AR) => ({
          value: AR.MstServicesSubCategoryId,
          label: AR.ServicesSubCategory_en,
        })
      );
      setBindServiceSubCategory(servicesSubCategorySelected);
    } else {
    }
  }, [servicesFields.serviceCategory]);

  useEffect(() => {
    // Bind Services Sub Category Dropdown datas
    const selectedSubCategories = serviceSubCategory;
    setBindServicesName([]);
    if (selectedSubCategories.length > 0) {
      const servicesLabels = getServicesData
        .filter((item) =>
          selectedSubCategories.includes(item.MstServicesSubCategoryId)
        )
        .map((item) => ({
          MstServicesId: item.MstServicesId,
          Services_en: item.Services_en,
        }));

      const servicessSelected = servicesLabels.map((AR) => ({
        value: AR.MstServicesId,
        label: AR.Services_en,
      }));
      setBindServicesName(servicessSelected);
    } else {
      // Handle the case when no sub-categories are selected
      setBindServicesName([]);
    }
  }, [serviceSubCategory]);
  const [docterAppoinmetData, setDocterAppoinmentData] = useState([]);
  const [issueCommodityData, setIssueCommodityData] = useState([]);
  const [servicesProvideData, setServicesProvideData] = useState([]);
  const [servicesProvideData1, setServicesProvideData1] = useState([]);
  const [issueMedicineDrugDatas, setIssueMedicineDrugDatas] = useState([]);
  const [doctorAppoinmentDatas, setDoctorAppoinmentDatas] = useState();
  const [servicesProvideDatas, setServicesProvideDatas] = useState([]);
  // const [servicesProvideValues, setServicesProvideValues] = useState([]);
  const handleDoctorAppoinment = (updatedData) => {
    setDocterAppoinmentData(updatedData);
  };
  const handleIssueCommodity = (updatedData) => {
    setIssueCommodityData(updatedData);
  };
  const handleServicesProvide = (updatedData) => {
    debugger;
    console.log("setServicesProvideDataaaaa", updatedData);

    setServicesProvideData(updatedData);
  };

  const [currentData1, setCurrentData1] = useState({});
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState([]);
  const [issuePopupCancel, setIssuePopupCancel] = useState(false);
  const [doctorPopupCancel, setDoctorPopupCancel] = useState(false);
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [bindAppoinmentWithDD, setBindAppoinmentWithDD] = useState([]);
  const [bindAppoinmentAreaDD, setBindAppoinmentAreaDD] = useState([]);

  useEffect(() => {
    const appoinmentWithDD = async () => {
      try {
        const appoinmentWithResponse = await BindAPI("SDPAPI/BindSDP");

        const appoinmentWithData = appoinmentWithResponse.data.Table.map(
          (item) => ({
            value: item.MstSDPId,
            label: item.SDP_en,
          })
        );

        const appoinmentAreaResponse = await BindAPI("AreaAPI/GetArea");
        const appoinmentAreaData = appoinmentAreaResponse.data.Table.map(
          (item) => ({
            value: item.MstAreaId,
            label: item.AreaName_en,
          })
        );
        setBindAppoinmentAreaDD(appoinmentAreaData);
        setBindAppoinmentWithDD(appoinmentWithData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    appoinmentWithDD();
  }, []);

  const handleServicesPopup = (rowIndex, flag) => {
    debugger;
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setServiceData(bindServicesDatas[rowIndex]);

    const doctorAppoinmentDatas = Object.values(
      bindDoctorAppointmentDatas
    ).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );
    setServiceData1(doctorAppoinmentDatas);

    const servicesProvideChild = Object.values(bindProvideServicesDatas).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );
    setServiceData2(servicesProvideChild[0]);

    const appointmentWithRowData = doctorAppoinmentDatas;

    let appointmentWithInfo;
    let ResultAppointmentWith = "";
    let ResultEmail = "";

    if (
      appointmentWithRowData.length > 0 &&
      appointmentWithRowData[0].AppointmentWith
    ) {
      const filteredData = getLocationDatas.filter(
        (item) =>
          item.MstLocationId === appointmentWithRowData[0].AppointmentWith
      );
      ResultAppointmentWith = filteredData[0].LocationName_en;
      ResultEmail = filteredData[0].Email;
    } else {
      console.warn(
        "appointmentWithRowData is empty or missing AppointmentWith."
      );
    }

    let ResultAppointmentDate = ""; // Default value in case of an empty array or undefined

    if (
      doctorAppoinmentDatas.length > 0 &&
      doctorAppoinmentDatas[0].AppoinmentDate
    ) {
      ResultAppointmentDate = doctorAppoinmentDatas[0].AppoinmentDate;
    } else {
      console.warn("doctorAppoinmentDatas is empty or missing AppoinmentDate.");
    }

    const appointmentAreaRowData = doctorAppoinmentDatas;
    let appointmentAreaInfo;
    let ResultAppointmentArea = { value: "", label: "" }; // Default values

    if (
      appointmentAreaRowData.length > 0 &&
      appointmentAreaRowData[0].MstAreaId
    ) {
      appointmentAreaInfo = bindAppoinmentAreaDD.find(
        (L) =>
          parseInt(L.value) === parseInt(appointmentAreaRowData[0].MstAreaId)
      );

      ResultAppointmentArea = {
        value: appointmentAreaRowData[0].MstAreaId,
        label: appointmentAreaInfo ? appointmentAreaInfo.label : "Unknown",
      };
    } else {
      console.warn("appointmentAreaRowData is empty or missing MstAreaId.");
    }
    var CRSFollowUpPart = bindServicesDatas[rowIndex].CRSFollowUp;
    const CRSFollowUpDate = CRSFollowUpPart
      ? CRSFollowUpPart.split("T")[0]
      : null;

    var ReferralFollowUpPart = bindServicesDatas[rowIndex].ReferralFollowUp;
    const ReferralFollowUpDate = ReferralFollowUpPart
      ? ReferralFollowUpPart.split("T")[0]
      : null;

    const sdpRowData = bindServicesDatas[rowIndex];
    const sdpInfo = bindSDPDD.find((L) => L.value === sdpRowData.MstSDPId);
    const ResultSDP = {
      value: sdpRowData.MstSDPId,
      label: sdpInfo ? sdpInfo.label : "Unknown",
    };

    const areaRowData = bindServicesDatas[rowIndex];

    const areaInfo = bindArea.find((A) => A.value === areaRowData.MstAreaId);
    console.log("areaRowData", areaRowData, areaInfo);
    const ResultArea = {
      value: areaRowData.MstAreaId,
      label: areaInfo ? areaInfo.label : "Unknown",
    };
    const bindTxnServicesId = bindServicesDatas[rowIndex].TxnServicesId;

    const serviceSubCfilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultserviceSubC = serviceSubCfilteredData.map(
      (item) => item.MstServicesSubCategoryId
    );

    const serviceNamefilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);
    const resultservicesName = serviceNamefilteredData.map(
      (item) => item.MstServicesId
    );

    const issueMedicineDrugDatas = Object.values(bindMedicineDrugDatas).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );

    setCurrentData1(issueMedicineDrugDatas);
    setCurrentData3(servicesFields.sdp, servicesFields.area);
    const ProvideServicesFilteredData = Object.values(
      bindProvideServicesDatas
    ).filter((item) => item.TxnServicesId === bindTxnServicesId);

    const servicesNameData = ProvideServicesFilteredData.map(
      (item) => item.MstServicesId
    );

    const servicesProvideDatas = Object.values(bindProvideServicesDatas).filter(
      (item) => item.TxnServicesId === bindServicesDatas[rowIndex].TxnServicesId
    );
    let updatedTableDatas = "";
    const servicesSelectedvalue = servicesNameData;
    const servicesLabels = getServicesData.filter((item) =>
      servicesSelectedvalue.includes(item.MstServicesId)
    );

    const servicesMap = new Map(
      servicesLabels.map((service) => [service.MstServicesId, service])
    );

    const updatedTableData = servicesProvideDatas.map((data) => {
      const service = servicesMap.get(data.MstServicesId);
      const FPItemProvidedIdOptions = getBrandDatas
        .filter(
          (item) =>
            item.MstCommodityCategoryId === service.MstCommodityCategoryId
        )
        .map((items) => ({
          value: items.MstBrandId,
          label: items.Brand_en,
        }));
      if (service) {
        return {
          ...data,
          value: `${service.ServicesCategory_en}-${service.ServicesSubCategory_en}-${service.Services_en}`,
          TestResultType: service.TestResultType,
          ResultUOM: `${service.ResultUOM}`,
          TestResult: data.TestResult,
          TestResultRadio: data.TestResultRadio,
          Quantity: data.Item,
          FPItemProvidedIdOptions: FPItemProvidedIdOptions,
          MstBrandId: data.MstBrandId,
          Charges: data.Charges,
          FPUser: data.FPUser,
          ReferredTo: data.ReferredTo,
        };
      }

      return data;
    });

    setUpDatedTableData(updatedTableData);
    var Date = bindServicesDatas[rowIndex].ServicesDate;
    const formattedDate = Date.split("T")[0];
    const AutoDropdownPopupdata = [
      ResultAppointmentWith,
      CRSFollowUpDate,
      ReferralFollowUpDate,
      ResultSDP,
      ResultArea,
      resultserviceSubC,
      resultservicesName,
      formattedDate,
      ResultEmail,
      ResultAppointmentDate, 
    ];

    setPopupdata(AutoDropdownPopupdata);
  };

  console.log("bindServicesAddData:", bindServicesAddData);

  return (
    <MainLayout pageName="3">
      {visibleTable && (
        // Client Data grid
        <div>
          <div className={mainTable.headerTitle}>
            <div className={mainTable.gridTableHeader}>{`Services`}</div>
            <hr class="h-px bg-gray-200 border-0"></hr>
          </div>

          <GridPage
            title="Client Data"
            xScroll="overflow-x-auto max-md:w-fit w-full"
            tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
            header={ServicesClientHeaders}
            records={bindClientDatas}
            addClientView={addClientVisible}
            showAdd={showAdd}
            onHistoryGrid={HandleClientHistory}
          />
          <div>
            <hr class={mainTable.servicesHorizontalLine}></hr>
            <GridPage
              // Services Provided grid
              title="Services Provided"
              xScroll="overflow-x-auto max-md:w-fit w-full"
              tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
              header={ServicesHeaders}
              handleEdit={handleEdit}
              records={bindServicesDatas}
              handleAction={handleServicesPopup}
              addView={true}
              blockView={true}
            />
          </div>
        </div>
      )}

      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Services"
            />

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className="h-screen">
                  <div className={mainTable.grid2}>
                    <div className="flex items-center">
                      <label className={mainTable.serviceLabel}>
                        Client Id. : &nbsp;
                      </label>
                      <label className={mainTable.label}>
                        {servicesFields.clientId}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <label className={mainTable.serviceLabel}>
                        Client Name : &nbsp;
                      </label>

                      <Tooltip text={servicesFields.clientName}>
                        <label className={mainTable.label}>{tooltipText}</label>
                      </Tooltip>
                    </div>
                    <div className="flex items-center">
                      <label className={mainTable.serviceLabel}>
                        Mother Name : &nbsp;
                      </label>
                      <label className={mainTable.label}>
                        {servicesFields.motherName}
                      </label>
                    </div>
                    <div className="flex items-center">
                      <label className={mainTable.serviceLabel}>
                        Phone Number : &nbsp;
                      </label>
                      <label className={mainTable.label}>
                        {servicesFields.phoneNumber}
                      </label>
                    </div>
                  </div>
                  <div className="md:grid gap-4 mb-6 md:grid-cols-3 xl:grid-cols-4 sm:col-span-1 w-full">
                    <InputText
                      inputType="date"
                      title="Date"
                      classStar="text-red-500"
                      name="date"
                      content={servicesFields.date}
                      controlFunc={(e) =>
                        setServicesFields({
                          ...servicesFields,
                          date: e.target.value,
                        })
                      }
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType="text"
                      title="Reason For Visit"
                      maxLength={500}
                      name="reasonForVisit"
                      content={servicesFields.reasonForVisit}
                      controlFunc={(e) =>
                        setServicesFields({
                          ...servicesFields,
                          reasonForVisit: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        setServicesFieldsError((servicesFieldsError) => ({
                          ...servicesFieldsError,
                          reasonForVisitError:
                            servicesFields.reasonForVisit &&
                            servicesFields.reasonForVisit.length > 0
                              ? /^[A-Za-z ]+$/.test(
                                  servicesFields.reasonForVisit
                                )
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Reason For Visit",
                        }));
                      }}
                      errorMessage={servicesFieldsError.reasonForVisitError}
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="S.D.P."
                      options={bindUserSDPDD}
                      placeholder="Select"
                      name="sdp"
                      disabled=""
                      selectedOption={bindUserSDPDD.find(
                        (x) => x.value == servicesFields.sdp
                      )}
                      controlFunc={(value, e) => {
                        setServicesFields({
                          ...servicesFields,
                          area: "",
                        });
                        handleServicesFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setServicesFieldsError(() => ({
                          ...servicesFieldsError,
                          sdpError: servicesFields.sdp ? "" : "Select S.D.P",
                        }));
                      }}
                      Class="custom-class"
                      tabIndex="1"
                      errorMessage={servicesFieldsError.sdpError}
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="Area"
                      options={bindAreaDD}
                      placeholder="Select"
                      name="area"
                      selectedOption={
                        servicesFields.area
                          ? bindAreaDD.find(
                              (x) => x.value == servicesFields.area
                            )
                          : ""
                      }
                      controlFunc={(value, e) => {
                        handleServicesFieldsChange(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setServicesFieldsError(() => ({
                          ...servicesFieldsError,
                          areaError: servicesFields.area ? "" : "Select Area",
                        }));
                      }}
                      Class="custom-class"
                      tabIndex="1"
                      errorMessage={servicesFieldsError.areaError}
                    />
                    <div className="dropdown-container">
                      <DropDown
                        title="Project"
                        options={bindProject}
                        placeholder="Select"
                        name="project"
                        // classStar="text-red-500"
                        selectedOption={servicesFields.project}
                        controlFunc={(e) =>
                          setServicesFields({
                            ...servicesFields,
                            project: e.target.value,
                          })
                        }
                        // controlFuncBlur={() => {
                        //   setServicesFieldsError(() => ({
                        //     ...servicesFieldsError,
                        //     projectError: servicesFields.project
                        //       ? ""
                        //       : "Select Project",
                        //   }));
                        // }}
                      />

                      {servicesFieldsError.projectError && (
                        <span className="error-message text-red-500">
                          {servicesFieldsError.projectError}
                        </span>
                      )}
                    </div>

                    <div className="dropdown-container">
                      <DropDown
                        title="Service Category"
                        options={bindServiceCategory}
                        placeholder={"Select"}
                        name="serviceCategory"
                        class="custom-class"
                        classStar="text-red-500"
                        tabIndex="1"
                        selectedOption={servicesFields.serviceCategory}
                        controlFunc={(e) => {
                          setServiceSubCategory("");
                          setServicesFields({
                            ...servicesFields,
                            serviceCategory: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setServiceSubCategory("");
                          setServicesFieldsError(() => ({
                            ...servicesFieldsError,
                            serviceCategoryError: servicesFields.serviceCategory
                              ? ""
                              : "Select Service Category",
                          }));
                        }}
                      />

                      {servicesFieldsError.serviceCategoryError && (
                        <span className="error-message text-red-500">
                          {servicesFieldsError.serviceCategoryError}
                        </span>
                      )}
                    </div>
                    <div>
                      <MultiSelect
                        classStar="text-red-500"
                        label="Service Sub-Category"
                        filteredOptions={bindServiceSubCategory}
                        classFilterLabel="p-1 flex gap-1"
                        class="custom-class"
                        placeholder={
                          serviceSubCategory?.length > 0
                            ? "More Selected"
                            : "Select..."
                        }
                        name="ServiceSubCategory"
                        selectedOptions={serviceSubCategory}
                        classDiv="relative w-full inline-block"
                        onChange={servicesSubCategoryChange}
                        classLabel={mainTable.labelNoWrap}
                      />
                      {serviceSubCategoryError && (
                        <span className="text-red-500">
                          {serviceSubCategoryError}
                        </span>
                      )}
                    </div>
                    <div>
                      <MultiSelect
                        classStar="text-red-500"
                        label="Services"
                        filteredOptions={bindServicesName}
                        classFilterLabel="p-1 flex gap-1"
                        class="custom-class"
                        placeholder={
                          servicesName?.length > 0
                            ? "More Selected"
                            : "Select..."
                        }
                        name="services"
                        selectedOptions={servicesName}
                        classDiv="relative w-full inline-block"
                        onChange={servicesNameChange}
                      />
                      {servicesNameError && (
                        <span className="text-red-500">
                          {servicesNameError}
                        </span>
                      )}
                    </div>
                    <div className="flex justify-center col-span-2 lg:col-span-4 md:col-span-4">
                      <div className={mainTable.grid2}>
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          className={mainTable.goButton}
                          onClick={() => HandleGo()}
                        >
                          GO
                        </button>
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          className={mainTable.backButton}
                          onClick={() => cancel()}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>

                  {visibleServicesAdd && (
                    <div>
                      <div>
                        <hr class={mainTable.servicesHorizontalLine}></hr>
                        <GridPage
                          header={ServicesAddHeader}
                          records={bindServicesAddData}
                          bindReferralDD={bindReferralDD}
                          handleAction={HandleDelete}
                          onReferralGrid={handleReferralChange}
                          rowsPerPageView={true}
                          onFPUserDD={bindFPUserDD}
                          onTestResultTypeDD={bindTestResultTypeDD}
                          addView={true}
                          bindPACServicesDD={bindPACServices}
                          onServicesProvide={handleServicesProvide}
                          xScroll="overflow-x-auto max-md:w-fit w-full"
                          tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
                        />

                        <hr class={mainTable.servicesHorizontalLine}></hr>
                        <div className={mainTable.grid2}>
                          <InputText
                            inputType="date"
                            title=" Contraceptive renewal supply follow up"
                            name="renewalDate"
                            content={servicesFields.renewalDate}
                            min={new Date().toISOString().split("T")[0]}
                            controlFunc={(e) =>
                              setServicesFields({
                                ...servicesFields,
                                renewalDate: e.target.value,
                              })
                            }
                          />
                          <InputText
                            inputType="date"
                            title=" Referral follow up"
                            name="Referral follow up"
                            content={servicesFields.referralfollowupDate}
                            min={new Date().toISOString().split("T")[0]}
                            controlFunc={(e) =>
                              setServicesFields({
                                ...servicesFields,
                                referralfollowupDate: e.target.value,
                              })
                            }
                          />
                        </div>
                        <hr class={mainTable.servicesHorizontalLine}></hr>
                        <div class={"my-6"}>
                          <TextArea
                            inputType="text"
                            title="Diagnosis / Remarks"
                            name="specificHistory"
                            rows="4"
                            maxLength={500}
                            content={servicesFields.servicesRemark}
                            placeholder="Typing"
                            controlFunc={(e) =>
                              setServicesFields({
                                ...servicesFields,
                                servicesRemark: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="grid gap-4 mb-6 md:grid-cols-2 sm:col-span-1">
                          <input
                            type="button"
                            className={mainTable.submitButton}
                            value="Medicine / Commodities"
                            onClick={HandleCommodities}
                          />

                          <input
                            type="button"
                            className={mainTable.submitButton}
                            value="Doctor Appointment"
                            onClick={HandleAppoinment}
                          />
                        </div>
                      </div>
                      <div>
                        <hr class={mainTable.servicesHorizontalLine}></hr>

                        <GridPage
                          title="History"
                          header={ServicesHeadersData}
                          records={bindServicesHistroyData}
                          onServicesGrid={HandleServicesHistory}
                          addView={true}
                        />
                      </div>
                      <Buttons
                        addScreenTitle={addScreenTitle}
                        updateScreenTitle={updateScreenTitle}
                        handleSubmit={handleSubmit}
                        cancel={cancel}
                        disabledBtn={submitBtnDisabled ? true : false}
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleServicesPopup}
      >
        <div>
          <PopUpScreenTitle
            title="Services"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <AddServices
            data={serviceDatas}
            data1={serviceDatas1}
            data2={popupdata}
            data3={serviceDatas2}
            currentData1={currentData1}
            upDatedTableData={upDatedTableData}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>

      <MainPopup
        popUpOpen={servicesHistorypopUpOpen}
        setpopUpOpen={setServicesHistorypopUpOpen}
        Open={() => HandleServicesHistory}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <AddServices
            data={serviceDatas}
            data1={serviceDatas1}
            data2={popupdata}
            data3={serviceDatas2}
            currentData1={currentData1}
            upDatedTableData={upDatedTableData}
          />
        </div>
        <PopUpButtons cancel={cancel1} />
      </MainPopup>

      <MainPopup
        popUpOpen={clientHistorypopUpOpen}
        setpopUpOpen={setClientHistorypopUpOpen}
        Open={() => HandleClientHistory}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <ClientHistory data={serviceDatas} data1={clientHistoryPopupClear} />
        </div>
        <PopUpButtons cancel={cancel1} />
      </MainPopup>

      <MainPopup
        popUpOpen={commoditiespopUpOpen}
        setpopUpOpen={setCommoditiespopUpOpen}
        Open={() => HandleCommodities}
        notificationPopups={notificationDesign}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <IssueOfCommodity
            isOpen={setCommoditiespopUpOpen}
            data1={handleIssueCommodity}
            currentData1={currentData1}
            currentData2={currentData3}
            issuePopupCancel={issuePopupCancel}
          />
        </div>
        <div className={mainTable.submitCancelMainDiv}></div>
      </MainPopup>

      <MainPopup
        popUpOpen={appoinmentpopUpOpen}
        setpopUpOpen={setAppoinmentpopUpOpen}
        Open={() => HandleAppoinment}
      >
        <div>
          <button class="w-full">
            <span
              className="text-white flex justify-end"
              onClick={handleClose}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <DoctorAppoinment
            isOpen={setAppoinmentpopUpOpen}
            data1={handleDoctorAppoinment}
            clientData={location1?.state?.isData}
            currentData2={currentData2}
            doctorPopupCancel={doctorPopupCancel}
          />
        </div>
      </MainPopup>
    </MainLayout>
  );
}

export default Services;
