// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { ToastContainer } from "react-toastify"; // Will be used in functionality
import "react-toastify/dist/ReactToastify.css"; // Will be used in functionality
import TableGrid from "../components/Table";
import { campsMeetingsMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

function CampsMeetings() {

  const [camps, setCamps] = useState("");
  const [campsTitle, setCampsTitle] = useState("");
  const [type, setType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [country, setCountry] = useState("");
  
  const [address, setAddress] = useState("");
  const [language, setLanguage] = useState([]);
  const [languageKey, setLanguageKey] = useState([]);

  const [bindCountryDD, setBindCountryDD] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "Srilanka" },
    { value: "3", label: "Nepal" },
  ]);
  const [bindState, setBindStatesDD] = useState([
    { value: "1", label: "Arunachal Pradesh" },
    { value: "2", label: "Andhra Pradesh" },
    { value: "3", label: "Assam" },
    { value: "4", label: "Bihar" },
    { value: "5", label: "Chhattisgarh" },
    { value: "6", label: "Delhi" },
    { value: "7", label: "Haryana" },
    { value: "8", label: "Himachal Pradesh" },
    { value: "9", label: "Nagaland" },
    { value: "10", label: "Karnataka" },
    { value: "11", label: "Kerala" },
    { value: "12", label: "Rajasthan" },
    { value: "13", label: "Odisha" },
    { value: "14", label: "Goa" },
    { value: "15", label: "Gujarat" },
    { value: "16", label: "Jammu & Kashmir" },
    { value: "17", label: "West Bengal" },
    { value: "18", label: "Uttarakhand" },
    { value: "19", label: "Uttar Pradesh" },
    { value: "20", label: "Maharashtra" },
    { value: "21", label: "Meghalaya" },
    { value: "22", label: "Manipur" },
    { value: "23", label: "Madhya Pradesh" },
    { value: "24", label: "Mizoram" },
    { value: "25", label: "Jharkhand" },
    { value: "26", label: "Punjab" },
    { value: "27", label: "Telangana" },
    { value: "28", label: "Tripura" },
    { value: "29", label: "Tamil Nadu" },
  ]);
  const [bindLocation, setBindLocationDD] = useState([
    { value: "1", label: "Ahmednagar" },
    { value: "2", label: "Amravati" },
    { value: "3", label: "Aurangabad" },
    { value: "4", label: "Beed" },
    { value: "5", label: "Buldhana" },
    { value: "6", label: "Chandrapur" },
    { value: "7", label: "Dhule" },
    { value: "8", label: "Jalgaon" },
    { value: "9", label: "Jalna" },
    { value: "10", label: "Nanded" },
    { value: "11", label: "Gondia" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Sindhudurg" },
    { value: "14", label: "Pune" },
    { value: "15", label: "Thane" },
    { value: "16", label: "Nashik" },
    { value: "17", label: "Nagpur" },
    { value: "18", label: "Osmanabad" },
    { value: "19", label: "Kolhapur" },
    { value: "20", label: "Latur" },
    { value: "21", label: "Mumbai Suburban District" },
    { value: "22", label: "Mumbai" },
    { value: "23", label: "Ratnagiri" },
  ]);
  const [bindArea, setBindAreaDD] = useState([
    { value: "1", label: "Achalpur" },
    { value: "2", label: "Ambarnath" },
    { value: "3", label: "Amravati" },
    { value: "4", label: "Barshi" },
    { value: "5", label: "Badlapur" },
    { value: "6", label: "Bhusawal" },
    { value: "7", label: "Buldhana" },
    { value: "8", label: "Jalna" },
    { value: "9", label: "Gondia" },
    { value: "10", label: "Sangli" },
    { value: "11", label: "Dhule" },
    { value: "12", label: "Hingoli" },
    { value: "13", label: "Hinganghat" },
    { value: "14", label: "Kalyan-Dombivli" },
    { value: "15", label: "Navi Mumbai" },
    { value: "16", label: "PCMC, Pune" },
    { value: "17", label: "Aurangabad" },
    { value: "18", label: "Malegaon" },
    { value: "19", label: "Panvel" },
    { value: "20", label: "Vasai-Virar City MC" },
    { value: "21", label: "Washim" },
    { value: "22", label: "Osmanabad" },
    { value: "23", label: "Nandurbar" },
    { value: "24", label: "Solapur" },
    { value: "25", label: "Wardha" },
    { value: "26", label: "Udgir" },
    { value: "27", label: "Yavatmal" },
    { value: "28", label: "Yavatmal" },
    { value: "29", label: "Nariman Point" },
  ]);
  const [bindSDP, setBindSDP] = useState([
    { value: "1", label: "Associated Clinics" },
    { value: "2", label: "CBPs" },
    { value: "3", label: "DHI" },
    { value: "4", label: "Outreach" },
    { value: "5", label: "Static Clinic" },
    { value: "6", label: "Satellite clinic" },
    { value: "7", label: "UFWC" },
  ]);
  const [bindCampOrMeetingDD, setBindCampOrMeetingDD] = useState([
    { value: "1", label: "Camps" },
    { value: "2", label: "Meetings" },
  ]);
  const [bindTypeDD, setBindTypeDD] = useState([
    { value: "1", label: "Medical" },
    { value: "2", label: "Healthcare" },
  ]);

  const [bindCampsMeetingsMasterDatas, setBindCampsMeetingsMasterDatas] =useState([]);
    const [state, setState] = useState(bindState[19]);
    const [area, setArea] = useState(bindArea[28]);
    const [sdp, setSdp] = useState(bindSDP[4]);
    const [location, setLocation] = useState(bindLocation[21]);
  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [updateButton, setUpdateButton] = useState(false);
  const [submitButton, setSubmitButton] = useState(true);
  const [editMode, setEditMode] = useState([]);

  const [currentData, setCurrentData] = useState({}); // Will be used in functionality
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewScreenTitle, setViewScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false); // Will be used in functionality
  const [deleteButton, setDeleteButton] = useState(false); // Will be used in functionality
  const [blockButton, setBlockButton] = useState(false); // Will be used in functionality
  const [unBlockButton, setUnBlockButton] = useState(false); // Will be used in functionality

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState(""); // Will be used in functionality
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);

  const [popUpOpen, setpopUpOpen] = useState(false); // Will be used in functionality

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setSubmitButton(true);
    setAddScreenTitle(true);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setCurrentPage(1);
    setResult(10);
    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitButton(false);
    setAddScreenTitle(false);
    setUpdateButton(false);
    setUpdateScreenTitle(false);
  };
  const handleEdit = async (items) => {
    // Will be used in functionality
    setEditMode(true);
    const data = [{}];
    setSubmitButton(false);
    setUpdateButton(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
  };


  useEffect(() => {
    const languageKeyValue = language.map((item) => item.LanguageKey);
    setLanguageKey(languageKeyValue);
  }, [language]);

  const handleSubmit = async (items, flag) => {
    cancel();
  };

  useEffect(() => {
    // Set the default value to the current date when the component mounts
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setFromDate(formattedDate);
    setToDate(formattedDate);
  }, []);


  return (
    <MainLayout pageName="2">
      <ToastContainer />
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Camps / Meetings`}</div>
          </div>

          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select className={mainTable.dropDownRecords} name="pageSize">
                  <option value="2">2</option>
                  <option value="5" selected>
                    5
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showadd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={campsMeetingsMasterHeader}
            tableData={bindCampsMeetingsMasterDatas}
          />

          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Camps / Meetings - Add"}
              </div>
            )}

            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Admin - Master - Camps / Meetings - Edit"}
              </div>
            )}

            {viewScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"Admin - Master - Camps / Meetings - View"}
              </div>
            )}

            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <div>
                      <DropDown
                        title="Camps / Meeting"
                        options={bindCampOrMeetingDD}
                        placeholder="Select"
                        name="camps"
                        value={camps}
                        onChange={(e) => setCamps(e.target.value)}
                      />
                    </div>
                    <InputText
                      inputType="text"
                      title="Title"
                      name="campsTitle"
                      value={campsTitle}
                      onChange={(e) => setCampsTitle(e.target.value)}
                    />
                    <DropDown
                      title="Type"
                      options={bindTypeDD}
                      placeholder="Select"
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                    <InputText
                      inputType="date"
                      title="From"
                      name="fromDate"
                      content={fromDate}
                      controlFunc={(e) => setFromDate(e.target.value)}
                    />
                    <InputText
                      inputType="date"
                      title="To"
                      name="toDate"
                      content={toDate}
                      controlFunc={(e) => setToDate(e.target.value)}
                    />
                    <AutoDropDown
                      title="State"
                      options={bindState}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      controlFunc={(value) => {
                        setState(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Location"
                      options={bindLocation}
                      placeholder="Select"
                      name="state"
                      selectedOption={location}
                      controlFunc={(value) => {
                        setLocation(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="S.D.P."
                      options={bindSDP}
                      placeholder="Select"
                      name="state"
                      selectedOption={sdp}
                      controlFunc={(value) => {
                        setSdp(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      title="Area"
                      options={bindArea}
                      placeholder="Select"
                      name="state"
                      selectedOption={area}
                      controlFunc={(value) => {
                        setArea(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <div className="lg:col-span-3 sm:col-span-1">
                    <InputText
                      inputType="text"
                      title="Address"
                      placeholder={"1/66A ,West Street"}
                      name="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    </div>
                  </div>
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {submitButton && (
                    <button
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateButton && (
                    <button
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}

export default CampsMeetings;
