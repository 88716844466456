import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import pdf from "../image/pdf.png";
const BrochureMasterPopup = ({ data, data1 }) => {
  const [bindLanguage, setbindLanguagesDD] = useState([]);
  const [bindTopic, setbindTopic] = useState([]);
  // const [brochureType, setBrochureType] = useState("1");
  const [brochureTypeBind, setBrochureTypeBind] = useState([
    { value: "1", label: "Image" },
    { value: "2", label: "Video" },
    { value: "3", label: "PDF" },
  ]);
  return (
    <div class="w-full">
      {/* <div class="text-xl mx-14 font-medium text-neutral-700 ">
        {"Brochures - View"}
      </div> */}
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5  min-h-fit max-h-fit ">
              <div className="mb-4">
                <CheckboxOrRadioGroup
                  // classStar="text-red-500"
                  // title="Items Provided"
                  type="radio"
                  setName="itemsProvided"
                  selectedOptions={data1[1]}
                  options={brochureTypeBind}
                  // controlFunc={(e) => {
                  //   setBrochureType(e.target.value);
                  // }}
                  disabled={true}
                />
              </div>
              {data1[1] === "1" && (
                <div className={mainTable.grid2}>
                  <AutoDropDown
                    title="Topics"
                    options={bindTopic}
                    placeholder="Select"
                    name="topic"
                    selectedOption={data1[2]}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                    classStar="text-red-500"
                  />
                  <AutoDropDown
                    title="Language"
                    options={bindLanguage}
                    classStar="text-red-500"
                    placeholder="Select"
                    name="language"
                    selectedOption={data1[0]}
                    // controlFunc={(value) => {
                    //   setLanguage(value);
                    // }}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                  />
                  <InputText
                    inputType="text"
                    title="Brochure Name"
                    classStar="text-red-500"
                    name="brochureName"
                    content={data.BrochureName}
                    placeholder="Brochure Name"
                    disabled="disabled"
                  />

                  <div className="flex">
                    <div>
                      <img
                        src={data.BrochurePhoto}
                        alt="Image"
                        className="rounded-full w-20 h-20"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
              )}
              {data1[1] === "2" && (
                <div className={mainTable.grid2}>
                  <AutoDropDown
                    title="Topics"
                    options={bindTopic}
                    classStar="text-red-500"
                    placeholder="Select"
                    name="topic"
                    selectedOption={data1[2]}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                  />
                  <AutoDropDown
                    title="Language"
                    options={bindLanguage}
                    classStar="text-red-500"
                    placeholder="Select"
                    name="language"
                    selectedOption={data1[0]}
                    // controlFunc={(value) => {
                    //   setLanguage(value);
                    // }}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                  />
                  <InputText
                    inputType="text"
                    title="Brochure Name"
                    classStar="text-red-500"
                    name="brochureName"
                    content={data.BrochureName}
                    placeholder="Brochure Name"
                    disabled="disabled"
                  />
                  <InputText
                    inputType="text"
                    classStar="text-red-500"
                    title="Video URL"
                    name="Youtube"
                    content={data.BrochureVedio}
                    disabled="disabled"
                  />

                  <div className="flex">
                    <div>
                      <iframe
                        title="Video"
                        className=" w-40 h-40"
                        src={data.BrochureVedio}
                        frameBorder="0"
                        allowFullScreen
                        disabled="disabled"
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
              {data1[1] === "3" && (
                <div className={mainTable.grid2}>
                  <AutoDropDown
                    title="Topics"
                    options={bindTopic}
                    classStar="text-red-500"
                    placeholder="Select"
                    name="topic"
                    selectedOption={data1[2]}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                  />
                  <AutoDropDown
                    title="Language"
                    options={bindLanguage}
                    classStar="text-red-500"
                    placeholder="Select"
                    name="language"
                    selectedOption={data1[0]}
                    // controlFunc={(value) => {
                    //   setLanguage(value);
                    // }}
                    Class="custom-class"
                    disabled="true"
                    tabIndex="1"
                  />
                  <InputText
                    inputType="text"
                    title="Brochure Name"
                    classStar="text-red-500"
                    name="brochureName"
                    content={data.BrochureName}
                    placeholder="Brochure Name"
                    disabled="disabled"
                  />

                  <div className="flex">
                    <div>
                      {data.BrochurePhoto &&
                        data.BrochurePhoto.endsWith(".pdf") && (
                          <a
                            href={data.BrochurePhoto}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={pdf} // You can replace this with a custom PDF icon
                              alt="PDF"
                              className="w-20 h-20"
                            />
                            <p>View PDF</p>
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrochureMasterPopup;
