// Code review - In Progress
// Documentation - In Progress
import React from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";

const Role = ({ data }) => {
  return (
    <div class="w-full ">
      <div class=" border-black  "></div>
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <InputText
                  inputType={"text"}
                  classStar="text-red-500"
                  title={"Role / Groups"}
                  name={"role/groups"}
                  content={data.RoleName}
                  disabled="disabled"
                />
                <TextArea
                  inputType="text"
                  title="Description"
                  name="description"
                  rows="4"
                  content={data.RoleDiscription}
                  disabled="disabled"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Role;
