import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";

const ParticipantBoxTable = ({ participantBoxData }) => {
  const [rowCount, setRowCount] = useState(participantBoxData); // Initial row count
  useEffect(() => {
    if (participantBoxData) {
      setRowCount(participantBoxData);
    }
  }, [participantBoxData]);

  // Create an array to store input values for each row
  const initialRowData = Array.from({ length: rowCount }, () => ({
    participantName: "",
    participantAge: "",
    participantGender: "",
    participantReferral: "", // Add selectedOption for participantReferral
    participantReferredTo: "", // Add selectedOption for participantReferredTo
  }));

  const [rowData, setRowData] = useState(initialRowData);

  // Handle input changes for a specific row and field
  const handleInputChange = (rowIndex, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[rowIndex][field] = value;
    setRowData(updatedRowData);
  };

  // Handle dropdown selection changes
  const handleDropdownChange = (rowIndex, field, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[rowIndex][field] = value;
    setRowData(updatedRowData);
  };

  // Define dropdown options for "participantReferral" and "participantReferredTo"
  const referralOptions = ["Option 1", "Option 2", "Option 3"];
  const referredToOptions = ["Option A", "Option B", "Option C"];

  // Function to render table rows dynamically
  const renderTableRows = () => {
    return rowData.map((row, rowIndex) => (
      <div
        key={rowIndex}
        className="grid grid-cols-1 gap-4 shadow-md rounded-md p-4 mb-4"
      >
        {Object.keys(row).map((field) => (
          <div key={field} className="flex gap-3 items-center justify-between">
            <div className={"basis-1/2"}>
              <label className={mainTable.label}>{field}</label>
            </div>
            <div className={"basis-1/2"}>
              {field === "participantReferral" ? ( // Check if the field is for participantReferral
                <select
                  value={row.participantReferral}
                  onChange={(e) =>
                    handleDropdownChange(rowIndex, "participantReferral", e.target.value)
                  }
                  className="border w-full rounded-md p-2"
                >
                  <option value="">Select </option>
                  {referralOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : field === "participantReferredTo" ? ( // Check if the field is for participantReferredTo
                <select
                  value={row.participantReferredTo}
                  onChange={(e) =>
                    handleDropdownChange(rowIndex, "participantReferredTo", e.target.value)
                  }
                  className="border w-full rounded-md p-2"
                >
                  <option value="">Select </option>
                  {referredToOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  className={mainTable.input}
                  name={field}
                  value={row[field]}
                  onChange={(e) =>
                    handleInputChange(rowIndex, field, e.target.value)
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div>
      <div>{renderTableRows()}</div>
    </div>
  );
};

export default ParticipantBoxTable;
