import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../components/MainLayout";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  Polyline,
  InfoWindow,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const TrackOutreachWorker = ({ clusterer }) => {
  const [outReachWorker, setOutReachWorker] = useState("");
  const [bindUserMasterDatas, setBindUserMasterDatas] = useState([]);
  const [bindUser, setBindUser] = useState([]);
  const [mapShow, setMapShow] = useState(false);
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [activeMarker, setActiveMarker] = useState(null);
  const [polylines, setPolylines] = useState([]);
  const [groupedDataTest, setGroupedDataTest] = useState([]);
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyAabEMh7g-WUtZtZV891N6Pzo9KZscbk3k",
  // });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBXopMMfvmJPHBWdJ_uid1sGrcg354oDrQ",
  });

  const [isOpen, setIsOpen] = useState(false);

  const [markers, setMarkers] = useState([]);
  const [tablePositionData, setTablePositionData] = useState([]);

  const [startPosition, setStartPosition] = useState(null);
  console.log("startPosition", startPosition);
  const [endPosition, setEndPosition] = useState(null);

  const [stateDropDown, setStateDropDown] = useState([]);
  const [locationDropDown, setLocationDropDown] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [bindLocationDropDown1, setBindLocationDropDown1] = useState([]);

  const [trackingErrorHandling, setTrackingErrorHandling] = useState({
    stateError: "",
    locationError: "",
    outReachWorkerError: "",
    startDateTimeError: "",
    endDateTimeError: "",
  });

  const [mapMarker, setMapMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [showingInfoWindow2, setShowingInfoWindow2] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(null);

  useEffect(() => {
    const dropDownDatas = async () => {
      //GetState
      //GetLocation
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");

        const data = {
          mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
          mstBranchId: userProfileDatas[0]?.MstLocationId,
          mstSdpId: userProfileDatas[0].MstSDPId
            ? userProfileDatas[0].MstSDPId
            : 0,
          mstUserId: userProfileDatas[0]?.MstUserId,
          mstRoleId: userProfileDatas[0]?.MstRoleId,
        };
        const outReachWorkerResponse = await BindPostAPI(
          data,
          "UserAPI/BindUser"
        );
        // const outReachWorkerResponse = await BindAPI("UserAPI/BindUser");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        setStateDropDown(statesData);
        setLocationDropDown(locationData);
        const filteredWithData = outReachWorkerResponse.data.Table.filter(
          (item) => [7].includes(item.MstRoleId)
        );
        setBindUser(filteredWithData);
      } catch (error) {}
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    dropDownDatas();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown1(locationSelected);
    } else {
    }
  }, [state ? state : null]);

  useEffect(() => {
    const locationSelectedValue = bindUser.find(
      (item) => item.MstLocationId == location.value
    );

    if (locationSelectedValue) {
      const userFileds = bindUser
        .filter(
          (item) => item.MstLocationId == locationSelectedValue.MstLocationId
        )
        .map((item) => ({
          MstUserId: item.MstUserId,
          userName: item.FirstName_en + " " + item.LastName_en,
        }));
      console.log("userFileds", userFileds);
      const userSelected = userFileds.map((UC) => ({
        value: UC.MstUserId,
        label: UC.userName,
      }));
      console.log("userSelected", userSelected);
      setBindUserMasterDatas(userSelected);
    }
  }, [location ? location : null]);

  useEffect(() => {
    // This block will run every time markers state is updated
    if (markers.length > 0) {
      console.log("markers", markers);
      const formattedData = markers.map((marker, index) => {
        // Check if 'Position' property exists before accessing it
        const position = marker.Position || { lat: 0, lng: 0 };

        return {
          MstUserId: marker.MstUserId,
          position: {
            lat: parseFloat(position.Lat),
            lng: parseFloat(position.Lng),
          },
          timestamp: new Date(marker.TrackLocationDateTime),
          txnTrackId: index + 1,
        };
      });

      console.log("formattedData", formattedData);

      formattedData.sort((a, b) => a.timestamp - b.timestamp);

      const groupedData = {};
      formattedData.forEach((data) => {
        const dateKey = data.timestamp.toISOString().split("T")[0]; // Extract date string (YYYY-MM-DD)
        if (!groupedData[dateKey]) {
          groupedData[dateKey] = [];
        }
        groupedData[dateKey].push(data);
      });
      setGroupedDataTest(groupedData);
      console.log("groupedData", groupedData);
      Object.keys(groupedData).forEach((dateKey) => {
        const dataForDate = groupedData[dateKey];

        // Create polyline path for the current date
        const polylinePath = dataForDate.map((data) => data.position);

        // Set polyline options
        const polylineOptions = {
          strokeColor: "#FF0000", // Red color for polyline (customize as needed)
          strokeOpacity: 0.8,
          strokeWeight: 2,
        };

        // Add polyline to map
        setPolylines((prevPolylines) => [
          ...prevPolylines,
          { path: polylinePath, options: polylineOptions },
        ]);
      });

      if (formattedData.length > 1) {
        setStartPosition(formattedData[0].position);
        setEndPosition(formattedData[formattedData.length - 1].position);
      }
      setTablePositionData(formattedData);
    }
  }, [markers ? markers : null]);

  const validate = () => {
    let errors = {};
    if (state == "") {
      errors.state = "Select State";
    }

    if (location == "") {
      errors.location = "Select Location";
    }

    if (outReachWorker == "") {
      errors.outReachWorker = "Select Outreach Worker";
    }

    if (startDateTime == "") {
      errors.startDateTime = "Select Start Date";
    }

    if (endDateTime == "") {
      errors.endDateTime = "Select End Date";
    }

    setTrackingErrorHandling({
      ...trackingErrorHandling,
      stateError: errors.state || "",
      locationError: errors.location || "",
      outReachWorkerError: errors.outReachWorker || "",
      startDateTimeError: errors.startDateTime || "",
      endDateTimeError: errors.endDateTime || "",
    });

    return errors;
  };

  const handleTrack = async () => {
    setMarkers([]);
    const validation = validate();
    if (Object.keys(validation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(validation).length === 0) {
      setIsOpen(false);
      const startDateObject = new Date(startDateTime);
      const endDateObject = new Date(endDateTime);

      const formattedStartDateTime = formatDate(startDateObject);
      const formattedEndDateTime = formatDate(endDateObject);
      console.log(formattedStartDateTime, formattedEndDateTime);
      const trackPayload = [
        {
          mstuserId: 1,
          startDateTime: formattedStartDateTime,
          endDateTime: formattedEndDateTime,
        },
      ];
      console.log("trackPayload", trackPayload);

      const response = await BindPostAPI(
        trackPayload,
        "LocationTrackingAPI/GetLocationDataByUser"
      );
      console.log("trackData", response.data.Table);
      setMarkers(response.data.Table);

      setMapShow(true);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  console.log("poly", polylines);

  useEffect(() => {
    // Set the default value to the current date and time in Indian Standard Time (IST)
    const currentDate = new Date();
    const indianCurrentDate = new Date(
      currentDate.getTime() + 5.5 * 60 * 60 * 1000
    ); // Add 5 hours and 30 minutes to UTC time
    const formattedCurrentDate = indianCurrentDate.toISOString().slice(0, 16); // Format as YYYY-MM-DDTHH:MM
    setStartDateTime(formattedCurrentDate);
    setEndDateTime(formattedCurrentDate);
  }, []);

  const onMarkerClick = (flag) => {
    if (flag == 1) {
      setShowingInfoWindow(true);
    } else {
      setShowingInfoWindow2(true);
    }
  };

  const onInfoWindowClose = () => {
    setShowingInfoWindow(false);
    setShowingInfoWindow2(false);
  };

  const onLoad = (mapMarker) => {
    // setMapMarker(mapMarker);
  };

  const handleMouseOver = (lat, lng, txnTrackId) => {
    console.log("handleMouseOver", lat, lng, txnTrackId);
    const key = `${lat},${lng}`;
    const point = polylines[key]?.find((p) => p.txnTrackId == txnTrackId);
    if (point) {
      setHoverInfo({
        lat: point.position.lat,
        lng: point.position.lng,
        date: point.timestamp,
      });
    }
  };

  const handleMouseOut = () => {
    setHoverInfo(null);
  };

  const formatDateTime = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    const hours = d.getHours() % 12 || 12;
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");
    const ampm = d.getHours() < 12 ? "AM" : "PM";

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  return (
    <MainLayout pageName={"2"}>
      <div className="flex flex-col w-full">
        <div className={mainTable.mainAddScreen}>
          <div className={mainTable.addScreenTitle}>
            {"Track Outreach-Staff"}
          </div>

          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form className={``}>
                {/* <div
                  className={
                    "flex mb-6 gap-5 grid col-span-1 md:grid-cols-4 w-full"
                  }
                >  */}
                <div class={mainTable.grid3}>
                  <AutoDropDown
                    title="State"
                    classStar="text-red-500"
                    options={stateDropDown}
                    placeholder="Select"
                    name="state"
                    selectedOption={state}
                    controlFunc={(value) => {
                      setState(value);
                      setLocation("");
                      setOutReachWorker("");
                      setBindUserMasterDatas([]);
                    }}
                    Class="custom-class"
                    //disabled="false"
                    tabIndex="1"
                    errorMessage={trackingErrorHandling.stateError}
                  />
                  <AutoDropDown
                    title="Branch/Project"
                    classStar="text-red-500"
                    options={locationDropDown && bindLocationDropDown1}
                    placeholder="Select"
                    name="state"
                    selectedOption={location}
                    controlFunc={(value) => {
                      setLocation(value);
                      setOutReachWorker("");
                      setBindUserMasterDatas([]);
                    }}
                    Class="custom-class"
                    //disabled="false"
                    tabIndex="1"
                    errorMessage={trackingErrorHandling.locationError}
                  />
                  <AutoDropDown
                    classStar="text-red-500"
                    title="Outreach staff"
                    options={bindUserMasterDatas}
                    placeholder="Select"
                    name="state"
                    selectedOption={outReachWorker}
                    controlFunc={(value) => {
                      setOutReachWorker(value);
                    }}
                    Class="custom-class"
                    //disabled="false"
                    tabIndex="1"
                    errorMessage={trackingErrorHandling.outReachWorkerError}
                  />
                </div>
                <div class={`md:grid gap-4 mb-0 md:grid-cols-3 sm:col-span-1`}>
                  <InputText
                    inputType="datetime-local"
                    title="Start Date"
                    classStar="text-red-500"
                    name="startDateTime"
                    content={startDateTime}
                    controlFunc={(e) => {
                      setStartDateTime(e.target.value);
                    }}
                    errorMessage={trackingErrorHandling.startDateTimeError}
                  />

                  <InputText
                    inputType="datetime-local"
                    title="End Date"
                    classStar="text-red-500"
                    name="endDateTime"
                    content={endDateTime}
                    controlFunc={(e) => setEndDateTime(e.target.value)}
                    errorMessage={trackingErrorHandling.endDateTimeError}
                  />
                </div>
                <div class="w-full flex justify-center">
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={
                      "text-white bg-[#2d2d6e] w-32 mt-6  hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    }
                    onClick={() => handleTrack()}
                  >
                    Track
                  </button>
                </div>
                {mapShow && (
                  <div
                    class={`w-full mx-2 my-3 shadow-[rgba(50,50,93,0.25)0px_6px_12px-2px,rgba(0,0,0,0.3)_0px_3px_7px-3px] rounded-md`}
                  >
                    <div
                      class={`"my-2 max-lg:mt-4 mx-4 lg:mx-4 py-2 text-xl font-medium text-neutral-700 `}
                    >
                      Tracking history
                    </div>
                    {isLoaded ? (
                      <GoogleMap
                        center={startPosition}
                        zoom={14}
                        mapContainerStyle={{
                          width: "100%",
                          height: "50vh",
                        }}
                      >
                        {/* {startPosition && (
                          <MarkerF key="start" position={startPosition}>
                            <div>Start</div>
                          </MarkerF>
                        )} */}
                        {/* {endPosition && (
                          <MarkerF
                            key="end"
                            position={endPosition}
                            title="data"
                          >
                            <div>End</div>
                          </MarkerF>
                        )} */}

                        <MarkerF
                          key="start"
                          position={startPosition}
                          clusterer={clusterer}
                          onLoad={onLoad}
                          clickable
                          onClick={() => onMarkerClick(1)}
                        >
                          {/* <div>Start</div> */}
                          {showingInfoWindow && (
                            <InfoWindowF
                              position={startPosition}
                              onCloseClick={onInfoWindowClose}
                            >
                              <div
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  backgroundColor: "red",
                                  padding: "10px",
                                }}
                              >
                                <p style={{ color: "white" }}>starting point</p>
                              </div>
                            </InfoWindowF>
                          )}
                        </MarkerF>

                        <MarkerF
                          key="end"
                          position={endPosition}
                          title="data"
                          clusterer={clusterer}
                          onLoad={onLoad}
                          clickable
                          onClick={onMarkerClick}
                        >
                          {showingInfoWindow2 && (
                            <InfoWindowF
                              position={endPosition}
                              onCloseClick={onInfoWindowClose}
                            >
                              <div
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  backgroundColor: "red",
                                  padding: "10px",
                                }}
                              >
                                <p style={{ color: "white" }}>ending point</p>
                              </div>
                            </InfoWindowF>
                          )}
                        </MarkerF>

                        {/* {Object.entries(groupedDataTest).map(([date, data]) => (
                          <React.Fragment key={date}>
                            <Polyline
                              path={data.map((marker) => ({
                                lat: parseFloat(marker.position.lat),
                                lng: parseFloat(marker.position.lng),
                              }))}
                              options={{
                                strokeColor: "#0000FF",
                                strokeOpacity: 1,
                                strokeWeight: 6,
                              }}
                              onMouseOver={() => {
                                console.log("date", date, data);
                              }}
                            />
                          
                          </React.Fragment>
                        ))} */}

                        {Object.entries(groupedDataTest).map(([date, data]) => (
                          <React.Fragment key={date}>
                            <Polyline
                              path={data.map((marker) => ({
                                lat: parseFloat(marker.position.lat),
                                lng: parseFloat(marker.position.lng),
                              }))}
                              options={{
                                strokeColor: "#0000FF",
                                strokeOpacity: 1,
                                strokeWeight: 6,
                              }}
                              onMouseOver={(e) => {
                                const point = data.find((p) => {
                                  console.log(
                                    "latLngtestdata",
                                    p.position.lat.toFixed(2),
                                    p.position.lng.toFixed(2),
                                    e.latLng.lat().toFixed(2),
                                    e.latLng.lng().toFixed(2)
                                  );
                                  return (
                                    p.position.lat.toFixed(2) ==
                                      e.latLng.lat().toFixed(2) &&
                                    p.position.lng.toFixed(2) ==
                                      e.latLng.lng().toFixed(2)
                                  );
                                });
                                console.log("point", point, data);
                                console.log(
                                  "pointE",
                                  e.latLng.lat().toFixed(7),
                                  e.latLng.lng().toFixed(7)
                                );

                                setHoverInfo({
                                  lat: e.latLng.lat(),
                                  lng: e.latLng.lng(),
                                  date: point?.timestamp,
                                });
                              }}
                              onMouseOut={() => setHoverInfo(null)}
                              // onMouseOver={(e) => handleMouseOver(e.latLng.lat(), e.latLng.lng(), e.mapId)}
                              // onMouseOut={handleMouseOut}
                            />
                          </React.Fragment>
                        ))}

                        {hoverInfo && (
                          <InfoWindowF
                            position={{
                              lat: hoverInfo.lat,
                              lng: hoverInfo.lng,
                            }}
                            onCloseClick={() => setHoverInfo(null)}
                          >
                            <div>
                              {/* <p>Date: {new Date(hoverInfo?.date).toLocaleString()}</p> */}
                              <p>{`Date: ${formatDateTime(
                                hoverInfo?.date
                              )}`}</p>
                              {/* <p>Lat: {hoverInfo.lat.toFixed(6)}</p>
                              <p>Lng: {hoverInfo.lng.toFixed(6)}</p> */}
                            </div>
                          </InfoWindowF>
                        )}

                        {/* {polylines.map((polyline, index) => (
                          <Polyline
                            key={index}
                            path={polyline.path}
                            options={polyline.options}
                          />
                        ))} */}
                      </GoogleMap>
                    ) : null}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
    </MainLayout>
  );
};

export default TrackOutreachWorker;
