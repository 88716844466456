// Code review - Completed
// Documentation - Completed
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/Table";
import { schoolMasterHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { School } from "../popup-containers/SchoolPopup";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function SchoolGroup() {
  //All bind dropdown value and bind data value useState hooks handling.
  const location = useLocation();
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [schoolData, setSchoolData] = useState("");
  const [bindCountry, setBindCountry] = useState([]);
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindLocationDropDown, setBindLocationDropDown] = useState([]);
  const [bindLocationDropDown1, setBindLocationDropDown1] = useState([]);
  const [bindSchoolMasterDatas, setBindSchoolMasterDatas] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);

  //All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [schoolView, setSchoolView] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [submitVisibleButton, setSubmitVisibleButton] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // School Master insert and edit useState hooks handling .
  const [mstSchoolId, setMstSchoolId] = useState("");

  const [schoolFields, setSchoolFields] = useState({
    schoolId: "",
    schoolName: "",
    schoolCountry: "",
    schoolState: "",
    schoolLocation: "",
    schoolLocationCode: "",
    schoolMstOrganizationId: "",
    schoolVenue: "",
    schoolContactPerson: "",
    schoolAddress: "",
    schoolMobileNumber: "",
    schoolEmailId: "",
    schoolRemarks: "",
  });
  //const [schoolId, setSchoolId] = useState("");
  //const [schoolName, setSchoolName] = useState("");
  //const [schoolAddress, setSchoolAddress] = useState("");
  //const [schoolLocation, setSchoolLocation] = useState("");
  //const [schoolState, setSchoolState] = useState("");
  //const [schoolCountry, setSchoolCountry] = useState("");
  //const [schoolVenue, setSchoolVenue] = useState("");
  //const [schoolContactPerson, setSchoolContactPerson] = useState("");
  //const [schoolMobileNumber, setSchoolMobileNumber] = useState("");
  //const [schoolEmailId, setSchoolEmailId] = useState("");
  //const [schoolRemarks, setSchoolRemarks] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindSchoolMasterDatas);

  // School Master Error useState hooks handling .

  const [schoolFieldsError, setSchoolFieldsError] = useState({
    schoolIdError: "",
    schoolNameError: "",
    schoolCountryError: "",
    schoolStateError: "",
    schoolLocationError: "",
    schoolVenueError: "",
    schoolContactPersonError: "",
    schoolAddressError: "",
    schoolMobileNumberError: "",
    schoolEmailIdError: "",
    schoolRemarksError: "",
  });
  // const [schoolIdError, setSchoolIdError] = useState("");
  // const [schoolNameError, setSchoolNameError] = useState("");
  // const [schoolAddressError, setSchoolAddressError] = useState("");
  // const [schoolVenueError, setSchoolVenueError] = useState("");
  // const [schoolLocationError, setSchoolLocationError] = useState("");
  // const [schoolSDPError, setSchoolSDPError] = useState("");
  // const [schoolStateError, setSchoolStateError] = useState("");
  // const [schoolContactPersonError, setSchoolContactPersonError] = useState("");
  // const [schoolMobileNumberError, setSchoolMobileNumberError] = useState("");
  // const [schoolEmailIdError, setSchoolEmailIdError] = useState("");

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);
  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [editMode, setEditMode] = useState([]);
  const createdBy = sessionStorage.getItem("createdBy");
  const [isOpen, setIsOpen] = useState(false);

  //This is School Master grid data bind functionality and School Master dropdown value bind functionality .
  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");
        const locationResponse = await BindAPI("LocationAPI/GetLocation");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));
        const locationData = locationResponse.data.Table.map((item) => ({
          value: item.MstLocationId,
          label: item.LocationName_en,
        }));

        setBindStateDropDown(statesData);
        setBindLocationDropDown(locationData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    bindSchoolData(userProfileDatas);
    dropDownDatas();
  }, []);

  const bindSchoolData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
      };
      const response = await BindPostAPI([data], "SchoolCollegeAPI/BindSchool");
      setBindSchoolMasterDatas(response.data.Table);
    }
  };

  // this function is bind the dropdown value for location what we select in state dropdown that selected related only show the location dropdown .
  useEffect(() => {
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == schoolFields.schoolState
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDropDown1(locationSelected);
    } else {
    }
  }, [schoolFields.schoolState]);

  // This is School grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setSchoolFields({
      schoolMstOrganizationId: userProfileDatas[0].MstOrganizationId,
      schoolState: userProfileDatas[0].MstStateId,
      schoolLocation: userProfileDatas[0].MstLocationId,
    });
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    handleOpen(false);
    setSubmitBtnDisabled(false);
    setSubmitVisibleButton(false);
    bindSchoolData(userProfileDatas);
    GenerateId();
    setSchoolFields({
      schoolId: "",
      schoolName: "",
      schoolCountry: "",
      schoolState: "",
      schoolLocation: "",
      schoolVenue: "",
      schoolContactPerson: "",
      schoolAddress: "",
      schoolMobileNumber: "",
      schoolEmailId: "",
      schoolRemarks: "",
    });
    // setSchoolId("");
    // setSchoolName("");
    // setSchoolState("");
    // setSchoolLocation("");
    // setSchoolVenue("");
    // setSchoolAddress("");
    // setSchoolContactPerson("");
    // setSchoolMobileNumber("");
    // setSchoolEmailId("");
    // setSchoolRemarks("");

    setSchoolFieldsError({
      youthGroupIdError: "",
      youthGroupNameError: "",
      youthTypeOfGroupError: "",
      youthStateError: "",
      youthLocationError: "",
      youthVenueError: "",
      youthContactPersonError: "",
      youthMobileNumberError: "",
      youthEmailIdError: "",
    });

    // setSchoolIdError("");
    // setSchoolNameError("");
    // setSchoolAddressError("");
    // setSchoolVenueError("");
    // setSchoolContactPersonError("");
    // setSchoolMobileNumberError("");
    // setSchoolEmailIdError("");
    // setSchoolStateError("");
    // setSchoolLocationError("");
  };

  // this function is helped to edit the deopdown value via rowindex passing for school.
  // const editDropDownValues = () => {
  //   if (editMode && selectedRowIndex !== null) {
  //     handleStateDropDown(selectedRowIndex);
  //     handleLocationDropDown(selectedRowIndex);
  //   }
  // };
  //useEffect(editDropDownValues, [editMode, selectedRowIndex]);

  // School Edit

  //State DropDown Value Start
  // const handleStateDropDown = (rowIndex) => {
  //   const SchoolData = bindSchoolMasterDatas[rowIndex];
  //   const stateDropDownValue = bindStateDropDown.find(
  //     (item) => item.value === SchoolData.MstStateId
  //   );
  //   const editStateDropDownValue = {
  //     value: SchoolData.MstStateId,
  //     label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
  //   };

  //   setSchoolState(editStateDropDownValue);
  // };
  //State DropDown Value End

  //Location DropDown Value Start
  // const handleLocationDropDown = (rowIndex) => {
  //   const SchoolData = bindSchoolMasterDatas[rowIndex];
  //   const locationDropDownValue = bindLocationDropDown.find(
  //     (item) => item.value === SchoolData.MstLocationId
  //   );
  //   const editLocationDropDownValue = {
  //     value: SchoolData.MstLocationId,
  //     label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
  //   };

  //   setSchoolLocation(editLocationDropDownValue);
  // };
  //Location DropDown Value End

  // this function is helped to edit the input value via rowindex passing and helping to visible handling for school.
  const handleEdit = (rowIndex) => {
    setEditMode(true);
    //setSelectedRowIndex(rowIndex);
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    setMstSchoolId(bindSchoolMasterDatas[rowIndex].MstSchoolId);

    setSchoolFields({
      schoolId: bindSchoolMasterDatas[rowIndex].SchoolId,
      schoolName: bindSchoolMasterDatas[rowIndex].SchoolName,
      schoolState: bindSchoolMasterDatas[rowIndex].MstStateId,
      schoolLocation: bindSchoolMasterDatas[rowIndex].MstLocationId,
      schoolVenue: bindSchoolMasterDatas[rowIndex].SchoolVenue,
      schoolContactPerson: bindSchoolMasterDatas[rowIndex].SchoolContactPerson,
      schoolAddress: bindSchoolMasterDatas[rowIndex].SchoolAddress,
      schoolMobileNumber: bindSchoolMasterDatas[rowIndex].SchoolPhoneNumber,
      schoolEmailId: bindSchoolMasterDatas[rowIndex].SchoolEmail,
      schoolRemarks: bindSchoolMasterDatas[rowIndex].SchoolRemarks,
    });

    // setSchoolId(bindSchoolMasterDatas[rowIndex].SchoolId);
    // setSchoolName(bindSchoolMasterDatas[rowIndex].SchoolName);
    // setSchoolVenue(bindSchoolMasterDatas[rowIndex].SchoolVenue);
    // setSchoolContactPerson(bindSchoolMasterDatas[rowIndex].SchoolContactPerson);
    // setSchoolAddress(bindSchoolMasterDatas[rowIndex].SchoolAddress);
    // setSchoolMobileNumber(bindSchoolMasterDatas[rowIndex].SchoolPhoneNumber);
    // setSchoolEmailId(bindSchoolMasterDatas[rowIndex].SchoolEmail);
    // setSchoolRemarks(bindSchoolMasterDatas[rowIndex].SchoolRemarks);
    setMstSchoolId(bindSchoolMasterDatas[rowIndex].MstSchoolId);
    setSchoolData(bindSchoolMasterDatas[rowIndex]);
  };
  const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page

  const [itemsPerPage, setItemsPerPage] = useState(50);
  const handleDropDown = (event) => {
    // To set Number of records per page
    const selectedValue = parseInt(event.target.value); // Convert to integer
    setItemsPerPage(selectedValue);
    setCurrentPage(1); // Reset to first page when items per page changes
  };
  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // this function is helped to the viwe, delete, block and unblock input value via rowindex passing for school .
  const handleSchoolDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setSchoolData(bindSchoolMasterDatas[rowIndex]);
    setMstSchoolId(bindSchoolMasterDatas[rowIndex].MstSchoolId);

    const SchoolData = bindSchoolMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === SchoolData.MstStateId
    );
    const editStateDropDownValue = {
      value: SchoolData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };

    //State DropDown Value End

    //Location DropDown Value Start
    const locationDropDownValue = bindLocationDropDown.find(
      (item) => item.value === SchoolData.MstLocationId
    );
    const editLocationDropDownValue = {
      value: SchoolData.MstLocationId,
      label: locationDropDownValue ? locationDropDownValue.label : "Unknown",
    };

    //Location DropDown Value End

    const autoDropDownValues = [
      editStateDropDownValue,
      editLocationDropDownValue,
    ];
    setPopUpDropDownData(autoDropDownValues);
    //Area DropDown Value End
  };
  const GenerateId = () => {
    if (addScreenTitle) {
      if (schoolFields.schoolLocation) {
        const filteredBranchProject = getLocationDatas
          .filter((item) => item.MstLocationId === schoolFields.schoolLocation)
          .map((item) => item.LocationCode);
        if (filteredBranchProject.length > 0) {
          setSchoolFields({
            ...schoolFields,
            schoolLocationCode: filteredBranchProject[0],
          });
          // setBranchProjectCode(filteredBranchProject[0]);
        }
      }
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      GenerateId();
    }
  }, [addScreenTitle, schoolFields.schoolLocation]);
  useEffect(() => {
    if (addScreenTitle) {
      if (schoolFields.schoolLocationCode) {
        const outputString = `${schoolFields.schoolLocationCode}/${"SCH"}`;
        setSchoolFields({
          ...schoolFields,
          schoolId: outputString,
        });
        // setIECNo(outputString);
        console.log("IEC No:", outputString);
      }
    }
  }, [addScreenTitle, schoolFields.schoolLocationCode]);
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      // Validation logic for the Youth group Id input
      // if (!schoolFields.schoolId) {
      //   errors.schoolId = "Enter School ID";
      // } else if (!/^[A-Za-z0-9 -]+$/.test(schoolFields.schoolId)) {
      //   errors.schoolId = "Only alphabet, Hyphen and Numbers allowed";
      // }

      // Validation logic for the Youth group Name input
      if (!schoolFields.schoolName) {
        errors.schoolName = "Enter Name Of The School / College";
      }
      // Validation logic for the Youth group State input
      if (schoolFields.schoolState == "") {
        errors.schoolState = "Select state";
      }

      // Validation logic for the Youth group Location input
      if (schoolFields.schoolLocation == "") {
        errors.schoolLocation = "Select Branch/Project";
      }

      // Validation logic for the Youth group Venue input
      if (!schoolFields.schoolVenue) {
        errors.schoolVenue = "Enter Venue";
      }

      if (!schoolFields.schoolAddress) {
        errors.schoolAddress = "Enter School / College Address";
      }

      // Validation logic for the Youth group Location input
      // if (youthTypeOfGroup == "") {
      //   errors.youthTypeOfGroup = "Select Location";
      // }

      // Validation logic for the Youth group Volunteer input
      if (!schoolFields.schoolContactPerson) {
        errors.schoolContactPerson = "Enter Contact Person Name";
      }

      // Validation logic for the phone input
      if (!schoolFields.schoolMobileNumber) {
        errors.schoolMobileNumber = "Enter Mobile Number";
      } else if (!/^[\d\s]+$/.test(schoolFields.schoolMobileNumber)) {
        errors.schoolMobileNumber = "Enter a valid mobile number";
      }
      // // Validation logic for the email input
      // if (!schoolFields.schoolEmailId) {
      //   errors.schoolEmailId = "Enter Email Id";
      // } else if (
      //   !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
      //     schoolFields.schoolEmailId
      //   )
      // ) {
      //   errors.schoolEmailId = "Enter a valid email address";
      // }
    }
    setSchoolFieldsError({
      ...schoolFieldsError,
      // schoolIdError: errors.schoolId || "",
      schoolNameError: errors.schoolName || "",
      schoolStateError: errors.schoolState || "",
      schoolLocationError: errors.schoolLocation || "",
      schoolVenueError: errors.schoolVenue || "",
      schoolContactPersonError: errors.schoolContactPerson || "",
      schoolMobileNumberError: errors.schoolMobileNumber || "",
      schoolEmailIdError: errors.schoolEmailId || "",
      schoolAddressError: errors.schoolAddress || "",
    });

    return errors; // Return the errors object containing validation errors (if any)
  };

  // this function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API response for those all and passing the rowindex for school.
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // let schoolIdValidate = "";
      // let schoolNameValidate = "";
      // let schoolStateValidate = "";
      // let schoolLocationValidate = "";
      // let schoolVenueValidate = "";
      // let schoolAddressValidate = "";
      // let schoolContactPersonValidate = "";
      // let schoolMobileNumberValidate = "";
      // let schoolEmailIdValidate = "";

      // schoolIdValidate = schoolId.length > 0 ? "" : "Enter School / College Id";
      // setSchoolIdError(schoolIdValidate);
      // schoolNameValidate =
      //   schoolName.length > 0 ? "" : "Enter Name Of The School / College";
      // setSchoolNameError(schoolNameValidate);
      // schoolStateValidate = schoolState ? "" : "Select a State";
      // setSchoolStateError(schoolStateValidate);
      // schoolLocationValidate = schoolLocation ? "" : "Select a Location";
      // setSchoolLocationError(schoolLocationValidate);
      // schoolVenueValidate =
      //   schoolAddress.length > 0 ? "" : "Enter School / College Venue";
      // setSchoolVenueError(schoolVenueValidate);
      // schoolAddressValidate = schoolAddress.length > 0 ? "" : "Enter Address";
      // setSchoolAddressError(schoolAddressValidate);
      // schoolContactPersonValidate =
      //   schoolContactPerson.length > 0 ? "" : "Enter ContactPerson Name";
      // setSchoolContactPersonError(schoolContactPersonValidate);
      // schoolMobileNumberValidate =
      //   schoolMobileNumber.length > 0 ? "" : "Enter MobileNumber";
      // setSchoolMobileNumberError(schoolMobileNumberValidate);
      // schoolEmailIdValidate = schoolEmailId.length > 0 ? "" : "Enter EmailId";
      // setSchoolEmailIdError(schoolEmailIdValidate);

      // if (
      //   !schoolIdValidate &&
      //   !schoolNameValidate &&
      //   !schoolStateValidate &&
      //   !schoolLocationValidate &&
      //   !schoolVenueValidate &&
      //   !schoolAddressValidate &&
      //   !schoolContactPersonValidate &&
      //   !schoolMobileNumberValidate &&
      //   !schoolEmailIdValidate
      // ) {
      const firstNameValidation = validate(flag);

      if (Object.keys(firstNameValidation).length !== 0) {
        setIsOpen(true);
      }

      if (Object.keys(firstNameValidation).length === 0) {
        setIsOpen(false);
        setSubmitBtnDisabled(true);
        const data = {
          mstSchoolId: mstSchoolId ? mstSchoolId : 0,
          schoolId: schoolFields.schoolId,
          schoolName: schoolFields.schoolName,
          mstCountryId: 1,
          mstStateId: schoolFields.schoolState,
          mstLocationId: schoolFields.schoolLocation,
          schoolVenue: schoolFields.schoolVenue,
          schoolAddress: schoolFields.schoolAddress,
          schoolContactPerson: schoolFields.schoolContactPerson,
          schoolPhoneNumber: schoolFields.schoolMobileNumber,
          schoolEmail: schoolFields.schoolEmailId,
          schoolRemarks: schoolFields.schoolRemarks,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "SchoolCollegeAPI/IUDSchool");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].result === "S") {
            toast.success("School / College data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setSubmitVisibleButton(true);
            setSchoolFields({
              ...schoolFields,
              schoolId: response.data.Table1[0].SchoolId,
            });
            setTimeout(cancel, 10000);
          } else {
            toast.error("Failed to add School / College data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].result === "M") {
            toast.success("School / College data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update School / College data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        mstSchoolId: bindSchoolMasterDatas[rowIndex].MstSchoolId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "SchoolCollegeAPI/IUDSchool");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].result === "D") {
          toast.success("School / College data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete School / College data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].result === "B") {
          toast.success("School / College data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block School / College data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("School / College data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock School / College data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const handleSearch = (input) => {
    const filtered = bindSchoolMasterDatas.filter((item) => {
      const providerData = `${item.SchoolName}${item.SchoolId}${item.SchoolContactPerson}`;
      return providerData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };
  useEffect(() => {
    if (location.pathname == "/schoolgroup") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // bindSchoolData();
      cancel();
    }
  }, [location]);
  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  const handleSchoolFieldsChange = (value, name) => {
    console.log("Values:", value, name);
    setSchoolFields({
      ...schoolFields,
      [name]: value,
    });
  };
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="School / College"
          header={schoolMasterHeaders}
          records={bindSchoolMasterDatas}
          // currentPage={currentPage}
          blockView={true}
          handleEdit={handleEdit}
          handleAction={handleSchoolDetails}
          showAdd={showAdd}
        />
        // <div className=" ">
        //   <div className={mainTable.headerTitle}>
        //     <div className={mainTable.gridTableHeader}>{`School / College`}</div>
        //   </div>
        //   <div className="relative mt-1 md:hidden">
        //     <div className={mainTable.searchIconPosition}>
        //       <svg
        //         className={mainTable.searchIconSvg}
        //         aria-hidden="true"
        //         fill="currentColor"
        //         viewBox="0 0 20 20"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           fill-rule="evenodd"
        //           d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //           clip-rule="evenodd"
        //         ></path>
        //       </svg>
        //     </div>
        //     <input
        //       type="text"
        //       className={mainTable.searchInputBox}
        //       placeholder="Search for items"
        //     />
        //   </div>
        //   <div className="justify-between flex items-center">
        //     <div className={mainTable.searchAdd}>
        //       <div className="mt-1 gap-2 flex justify-between items-baseline">
        //         <label for="pageSize" className="hidden md:block">
        //           Rows Per Page:
        //         </label>
        //         <select
        //           onChange={handleDropDown}
        //           className={mainTable.dropDownRecords}
        //           name="pageSize"
        //         >
        //           <option value="2">2</option>
        //           <option value="5">5 </option>
        //           <option value="10" >10</option>
        //           <option value="20">20</option>
        //           <option value="50" selected>50</option>
        //           <option value="100">100</option>
        //         </select>
        //       </div>
        //       <div>
        //         <label for="table-search" className="sr-only">
        //           Search
        //         </label>
        //       </div>
        //       <div className="hidden md:block relative mt-1">
        //         <div className={mainTable.searchIconPosition}>
        //           <svg
        //             className={mainTable.searchIconSvg}
        //             aria-hidden="true"
        //             fill="currentColor"
        //             viewBox="0 0 20 20"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               fill-rule="evenodd"
        //               d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //               clip-rule="evenodd"
        //             ></path>
        //           </svg>
        //         </div>
        //         <input
        //           type="text"
        //           className={mainTable.searchInputBox}
        //           placeholder="Search for items"
        //           value={searchInput}
        //           onChange={handleSearchInputChange}
        //         />
        //       </div>
        //     </div>
        //     <div className="flex self-end">
        //       <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
        //         <span className="mb-12 text-red-600 text-7xl">.</span>
        //         <span>Block</span>
        //       </div>
        //       <button
        //         type="button"
        //         className={mainTable.addButton}
        //         onClick={showAdd}
        //       >
        //         <svg
        //           width="25"
        //           height="20"
        //           viewBox="0 0 50 50"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
        //             fill="white"
        //           />
        //         </svg>
        //         ADD
        //       </button>
        //     </div>
        //   </div>
        //   <TableGrid
        //     className={mainTable.table}
        //     headerData={schoolMasterHeaders}
        //     tableData={
        //       searchData.length > 0 ? searchData : bindSchoolMasterDatas
        //     }

        //     onEditGrid={handleEdit}
        //     onDeleteGrid={handleSchoolDetails}
        //     onBlockGrid={handleSchoolDetails}
        //     onViewGrid={handleSchoolDetails}
        //     currentPage={currentPage}
        //     itemsPerPage={itemsPerPage}
        //   />
        //   <div className={mainTable.submitCancelMainDiv}>
        //     <button
        //       onClick={() => setCurrentPage(currentPage - 1)}
        //       disabled={currentPage === 1}
        //       className={mainTable.submitButton}
        //     >
        //       Previous Page
        //     </button>
        //     <label>{currentPage}</label>
        //     <button
        //       onClick={() => setCurrentPage(currentPage + 1)}
        //       disabled={
        //         currentPage === bindSchoolMasterDatas.length / itemsPerPage
        //       }
        //       className={mainTable.cancelButton}
        //     >
        //       Next Page
        //     </button>
        //   </div>
        // </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {/* {addScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"School / College - Add"}
              </div>
            )}
            {updateScreenTitle && (
              <div className={mainTable.addScreenTitle}>
                {"School / College - Edit"}
              </div>
            )} */}
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="School / College"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div>
                    <div class={mainTable.labelBold1}>School / College</div>
                    <hr class={mainTable.horizontalLine}></hr>
                    <div className={mainTable.grid3}>
                      <div>
                        <InputText
                          classStar="text-red-500"
                          inputType="text"
                          title="School / College Id"
                          name="schoolID"
                          maxLength={30}
                          placeholder={"MAD/SCH/2024/00001"}
                          content={schoolFields.schoolId}
                          controlFunc={(e) =>
                            setSchoolFields({
                              ...schoolFields,
                              schoolId: e.target.value,
                            })
                          }
                          disabled={true}
                        />
                      </div>
                      <div>
                        <InputText
                          classStar="text-red-500"
                          inputType="text"
                          title="Name Of The School / College"
                          name="schoolName"
                          maxLength={100}
                          content={schoolFields.schoolName}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            setSchoolFields({
                              ...schoolFields,
                              schoolName: value,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolNameError:
                                value.trim().length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      value.trim()
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Name Of The School / College",
                            }));
                          }}
                          controlFuncBlur={(e) => {
                            const trimmedValue = e.target.value.trim();
                            setSchoolFields({
                              ...schoolFields,
                              schoolName: trimmedValue,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolNameError:
                                trimmedValue.length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      trimmedValue
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Name Of The School / College",
                            }));
                          }}
                          errorMessage={schoolFieldsError.schoolNameError}
                        />
                      </div>
                      <DropDown
                        classDiv="hidden"
                        title="Country"
                        options={bindCountry}
                        placeholder="Select"
                        name="country"
                        selectedOption={schoolFields.schoolCountry}
                        controlFunc={(e) =>
                          setSchoolFields({
                            ...schoolFields,
                            schoolCountry: e.target.value,
                          })
                        }
                      />
                      <div>
                        <AutoDropDown
                          classStar="text-red-500"
                          title="State"
                          options={bindStateDropDown}
                          placeholder="Select"
                          name="schoolState"
                          Class="custom-class"
                          // disabled="false"
                          tabIndex="1"
                          disabled={
                            schoolFields.schoolMstOrganizationId == 1
                              ? false
                              : true
                          }
                          selectedOption={bindStateDropDown.find(
                            (x) => x.value == schoolFields.schoolState
                          )}
                          controlFunc={(value, e) => {
                            handleSchoolFieldsChange(value.value, e.name);
                            setSchoolFields({
                              ...schoolFields,
                              schoolState: value.value,
                              schoolLocation: "",
                            });
                          }}
                          controlFuncBlur={() => {
                            setSchoolFieldsError({
                              ...schoolFieldsError,
                              schoolStateError: schoolFields.schoolState
                                ? ""
                                : "Select State",
                            });
                          }}
                          errorMessage={schoolFieldsError.schoolStateError}
                        />
                      </div>
                      <div>
                        <AutoDropDown
                          classStar="text-red-500"
                          title="Branch/Project"
                          options={
                            bindLocationDropDown && bindLocationDropDown1
                          }
                          disabled={
                            schoolFields.schoolMstOrganizationId == 1
                              ? false
                              : true
                          }
                          placeholder="Select"
                          name="schoolLocation"
                          selectedOption={
                            schoolFields.schoolLocation
                              ? bindLocationDropDown.find(
                                  (x) => x.value == schoolFields.schoolLocation
                                )
                              : ""
                          }
                          Class="custom-class"
                          tabIndex="1"
                          controlFunc={(value, e) => {
                            handleSchoolFieldsChange(value.value, e.name);
                          }}
                          controlFuncBlur={() => {
                            setSchoolFieldsError(() => ({
                              ...schoolFieldsError,
                              schoolLocationError: schoolFields.schoolLocation
                                ? ""
                                : "Select Branch/Project",
                            }));
                          }}
                          errorMessage={schoolFieldsError.schoolLocationError}
                        />
                      </div>
                      <div>
                        <InputText
                          classStar="text-red-500"
                          inputType={"text"}
                          title={"Venue"}
                          name={"venue"}
                          maxLength={100}
                          content={schoolFields.schoolVenue}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            setSchoolFields({
                              ...schoolFields,
                              schoolVenue: value,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolVenueError:
                                value.trim().length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      value.trim()
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Venue",
                            }));
                          }}
                          controlFuncBlur={(e) => {
                            const trimmedValue = e.target.value.trim();
                            setSchoolFields({
                              ...schoolFields,
                              schoolVenue: trimmedValue,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolVenueError:
                                trimmedValue.length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      trimmedValue
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Venue",
                            }));
                          }}
                          errorMessage={schoolFieldsError.schoolVenueError}
                        />
                      </div>
                      <div>
                        <InputText
                          classStar="text-red-500"
                          inputType={"text"}
                          title={"Contact Person Name"}
                          name={"contactPerson"}
                          maxLength={100}
                          content={schoolFields.schoolContactPerson}
                          controlFunc={(e) => {
                            const value = e.target.value;
                            setSchoolFields({
                              ...schoolFields,
                              schoolContactPerson: value,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolContactPersonError:
                                value.trim().length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      value.trim()
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Contact Person Name",
                            }));
                          }}
                          controlFuncBlur={(e) => {
                            const trimmedValue = e.target.value.trim();
                            setSchoolFields({
                              ...schoolFields,
                              schoolContactPerson: trimmedValue,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolContactPersonError:
                                trimmedValue.length > 0
                                  ? /^[A-Za-z]+(?:\s[A-Za-z]+)*$/.test(
                                      trimmedValue
                                    )
                                    ? ""
                                    : "Only alphabet and single space between words allowed"
                                  : "Enter Contact Person Name",
                            }));
                          }}
                          errorMessage={
                            schoolFieldsError.schoolContactPersonError
                          }
                        />
                      </div>
                      <div className="col-span-3">
                        <InputText
                          inputType={"text"}
                          title={"School / College Address"}
                          name={"address"}
                          maxLength={200}
                          classStar="text-red-500"
                          content={schoolFields.schoolAddress}
                          placeholder="7/33, first street"
                          controlFunc={(e) => {
                            const value = e.target.value;
                            setSchoolFields({
                              ...schoolFields,
                              schoolAddress: value,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolAddressError:
                                value.trim().length > 0
                                  ? ""
                                  : "Enter School / College Address",
                            }));
                          }}
                          controlFuncBlur={(e) => {
                            const trimmedValue = e.target.value.trim();
                            setSchoolFields({
                              ...schoolFields,
                              schoolAddress: trimmedValue,
                            });
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolAddressError:
                                // trimmedValue.length > 0
                                //   ? /^[A-Za-z0-9/,]+(?:\s[A-Za-z0-9/,]+)*$/.test(
                                //       trimmedValue
                                //     )
                                //     ? ""
                                //     : "Only alphabets, numbers, commas, slashes, and single spaces allowed"
                                //   : "Enter School / College Address",
                                trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter School / College Address"  // Error message if the input is empty
                            }));
                          }}
                          errorMessage={schoolFieldsError.schoolAddressError}
                        />
                      </div>
                      <div>
                        <InputText
                          classStar="text-red-500"
                          inputType={"text"}
                          title={"Mobile Number"}
                          name={"phone"}
                          maxLength={11}
                          content={schoolFields.schoolMobileNumber}
                          controlFunc={(e) =>
                            setSchoolFields({
                              ...schoolFields,
                              schoolMobileNumber: e.target.value.trim(),
                            })
                          }
                          controlFuncBlur={(e) => {
                            setSchoolFieldsError((schoolFieldsError) => ({
                              ...schoolFieldsError,
                              schoolMobileNumberError:
                                schoolFields.schoolMobileNumber &&
                                schoolFields.schoolMobileNumber.length > 0
                                  ? /^[0-9]+$/.test(
                                      schoolFields.schoolMobileNumber
                                    )
                                    ? ""
                                    : "Enter Valid Mobile Number"
                                  : "Enter Mobile Number",
                            }));
                          }}
                          errorMessage={
                            schoolFieldsError.schoolMobileNumberError
                          }
                        />
                      </div>
                      <div>
                        <InputText
                          // classStar="text-red-500"
                          inputType={"text"}
                          title={"Email Id"}
                          name={"email"}
                          maxLength={150}
                          content={schoolFields.schoolEmailId}
                          controlFunc={(e) =>
                            setSchoolFields({
                              ...schoolFields,
                              schoolEmailId: e.target.value,
                            })
                          }
                          // controlFuncBlur={(e) => {
                          //   setSchoolFieldsError((schoolFieldsError) => ({
                          //     ...schoolFieldsError,
                          //     schoolEmailIdError:
                          //       schoolFields.schoolEmailId &&
                          //       schoolFields.schoolEmailId.length > 0
                          //         ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                          //             schoolFields.schoolEmailId
                          //           )
                          //           ? ""
                          //           : "Enter a valid email address "
                          //         : "Enter Email Id",
                          //   }));
                          // }}
                          // errorMessage={schoolFieldsError.schoolEmailIdError}
                        />
                      </div>
                      <div class="md:col-span-3 col-span-1">
                        <TextArea
                          inputType="text"
                          title="Remarks"
                          name="remarks"
                          rows="4"
                          maxLength={500}
                          content={schoolFields.schoolRemarks}
                          controlFunc={(e) =>
                            setSchoolFields({
                              ...schoolFields,
                              schoolRemarks: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
                {submitVisibleButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-neutral-700 ">
                      School / College Id :
                      <span className=" mb-2 text-sm font-bold text-neutral-700 ">
                        {schoolFields.schoolId}
                      </span>
                    </label>
                  </div>
                )}
                {/* <div className={mainTable.submitCancelMainDiv}>
                  {addScreenTitle && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateScreenTitle && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleSchoolDetails}
      >
        <div className=" w-full">
          <PopUpScreenTitle
            title="School / College"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          {/* <div class="text-black text-2xl font-bold mx-4 flex">
            <h3 className="my-1 max-lg:mt-4 mx-2 lg:mx-0 whitespace-nowrap text-xl font-medium text-neutral-700 ">
              {viewDetails && `School / College - View`}
              {deleteDetails && `School / College - Delete`}
              {blockDetails && `School / College - Block`}
              {unBlockDetails && `School / College - Unblock`}
            </h3>
            <button className="w-full">
              <span
                className="text-white flex justify-end"
                onClick={handleClose}
                class="text-2xl p-0 m-o block float-right mr-3"
              >
                &times;
              </span>
            </button>
          </div> */}
          <School data={schoolData} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
        {/* <div className={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div> */}
      </MainPopup>
    </MainLayout>
  );
}

export default SchoolGroup;
