// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";

const SPServiceSessionPopup = ({ data, data1 }) => {
  const [bindTest, setBindTestDD] = useState([]);
  return (
    <div class="w-full">
      <div class=" flex flex-col w-full">
        <div class={mainTable.addShadowScreen}>
          <div class={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5 lg:h-96 min-h-[60vh] max-h-[72vh]">
              <div className={mainTable.grid3}>
                <InputText
                  inputType="date"
                  title="Date"
                  name="date"
                  classStar={"text-red-500"}
                  content={data1[0]}
                  disabled="disabled"
                />
               
                <AutoDropDown
                  title="State"
                  classStar={"text-red-500"}
                  options={bindTest}
                  placeholder="Select"
                  name="state"
                  selectedOption={[data1[1]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Branch/Project"
                  classStar={"text-red-500"}
                  options={bindTest}
                  placeholder="Select"
                  name="state"
                  selectedOption={[data1[2]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="S.D.P."
                  options={bindTest}
                  placeholder="Select"
                  classStar={"text-red-500"}
                  name="state"
                  selectedOption={[data1[3]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Area"
                  options={bindTest}
                  placeholder="Select"
                  name="state"
                  classStar={"text-red-500"}
                  selectedOption={[data1[4]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <InputText
                  inputType="text"
                  title="Venue"
                  name="Services"
                  content={data.Venue_en}
                  classStar={"text-red-500"}
                  disabled="disabled"
                />
                  <AutoDropDown
                  title="District"
                  classStar={"text-red-500"}
                  options={bindTest}
                  placeholder="Select"
                  name="District"
                  selectedOption={[data1[5]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Block"
                  classStar={"text-red-500"}
                  options={bindTest}
                  placeholder="Select"
                  name="Block"
                  selectedOption={[data1[6]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="City / Village"
                  options={bindTest}
                  placeholder="Select"
                  classStar={"text-red-500"}
                  name="City / Village"
                  selectedOption={[data1[7]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <InputText
                  inputType="text"
                  title="Healthcare Provider Name"
                  name="Services"
                  content={data.HealthcareProviderName_en}
                  classStar={"text-red-500"}
                  disabled="disabled"
                />
                <AutoDropDown
                  title="Special Service Session Type"
                  classStar={"text-red-500"}
                  options={bindTest}
                  placeholder="Select"
                  name="Special Service Session Type"
                  selectedOption={[data1[8]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Client Type"
                  options={bindTest}
                  placeholder="Select"
                  classStar={"text-red-500"}
                  name="Client Type"
                  selectedOption={[data1[9]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                /> 
               
                <div className="col-span-1">
                  <label for="client_name" class={mainTable.label}>
                    No. of Beneficiaries
                    <span className="text-red-500">*</span>
                  </label>
                  <div className={mainTable.inputGenderClient}>
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Male"}
                      name={"male"}
                      content={data.Male}
                      disabled="disabled"
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Female"}
                      name={"female"}
                      content={data.Female}
                      disabled="disabled"
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Other"}
                      name={"other"}
                      content={data.Other}
                      disabled="disabled"
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"total"}
                      name={"total"}
                      content={data.Total}
                      disabled="disabled"
                    />
                  </div>
                </div>
                <div className="lg:col-span-3 sm:col-span-1">
                  <TextArea
                    inputType="text"
                    title="Remarks"
                    name="remarks"
                    content={data.Remarks_en}
                    rows={4}
                    disabled="disabled"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SPServiceSessionPopup;
