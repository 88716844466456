// Code review - In Progress
// Documentation - In Progress
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import { GroupDiscussionHeaders } from "../components/HeaderAttribute";
import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import MultiSelect from "../ReusableComponents/MultiSelect";
import GroupDiscussionPopup from "../popup-containers/GroupDiscussionPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  BindPostAPI,
  IUDAPI,
  ImageAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
// import {  FaFilePdf, FaFileExcel, FaFileWord, FaFile } from 'react-icons/fa';
// import pdfImage from "../image/PDF_file_icon.png";
// import wordImage  from "../image/Microsoft_Office_Word-icon.png";
// import excelImage from "../image/Excel-Icon.png";
// import powerpointImage from "../image/Microsoft_Office_PowerPoint-icon.png";

function GroupDiscussion() {
  const location = useLocation();
  useEffect(() => {
    getCurrentDate();
  }, []);
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [imageDisabled, setImageDisabled] = useState(false);
  const [currentData, setCurrentData] = useState("");
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [currentData4, setCurrentData4] = useState({});
  const [currentData5, setCurrentData5] = useState("");
  const createdBy = sessionStorage.getItem("createdBy");

  const [isOpen, setIsOpen] = useState(false);

  //Data Binding
  const [bindGDDatas, setBindGDDatas] = useState([]);
  const [bindGDParticipantDetailDatas, setBindGDParticipantDetailDatas] =
    useState([]);
  const [bindGDTopicsDatas, setBindGDTopicsDatas] = useState([]);
  //const [bindResourceNameDatas, setBindResourceNameDatas] = useState([]);
  const [bindgenderRadioDD, setBindGenderRadioDD] = useState([]);
  const [bindtopics, setBindTopics] = useState([]);
  const [bindreferredtoDD, setBindreferredtoDD] = useState([]);
  const [bindreferelserviceDD, setBindreferelserviceDD] = useState([]);
  const [bindState, setBindState] = useState([]);
  const [bindStaffName, setBindStaffName] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindBlock, setBindBlock] = useState([]);
  const [bindCityVillage, setBindCityVillage] = useState([]);
  const [bindDistrict, setBindDistrict] = useState([]);
  const [bindFileData, setBindFileData] = useState([]);
  const [bindAgeDD, setBindAgeDD] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [getDistrictDatas, setGetDistrictDatas] = useState([]);
  const [getBlockDatas, setGetBlockDatas] = useState([]);
  const [getCityDatas, setGetCityDatas] = useState([]);

  const [IECParticipantDetails, setIECParticipantDetails] = useState("");
  const [topics, setTopics] = useState([]);
  const [venue, setVenue] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [sdp, setSdp] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [branchProject, setBranchProject] = useState("");
  const [IECNo, setIECNo] = useState("");
  const [channel, setChannel] = useState("");
  const [staffName1, setStaffName1] = useState("");
  const [staffName2, setStaffName2] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [cityVillage, setCityVillage] = useState("");
  const [male, setMale] = useState("");
  const [female, setFemale] = useState("");
  const [other, setOther] = useState("");
  const [total, setTotal] = useState("");
  const [totalError, setTotalError] = useState("");
  const [age1, setAge1] = useState("");
  const [age2, setAge2] = useState("");
  const [age3, setAge3] = useState("");
  const [ageTotal, setAgeTotal] = useState("");
  const [ageTotalError, setAgeTotalError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [stateCode, setStateCode] = useState("");
  const [branchProjectCode, setBranchProjectCode] = useState("");
  const [sdpCode, setSdpCode] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [stateCodeDD, setStateCodeDD] = useState([]);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [topicsError, setTopicsError] = useState("");
  const [dateError, setDateError] = useState("");
  // const [IECNoError, setIECNoError] = useState("");
  const [venueError, setVenueError] = useState("");
  const [stateError, setStateError] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSdpError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [staffNameError1, setStaffNameError1] = useState("");
  // const [staffNameError2, setStaffNameError2] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [blockError, setBlockError] = useState("");
  const [cityVillageError, setCityVillageError] = useState("");
  const [channelError, setChannelError] = useState("");
  const [participantError, setParticipantError] = useState("");

  const [editMstIECId, setEditMstIECId] = useState("");
  const [image, setImage] = useState("");
  const [IECType, setIECType] = useState("GD");
  const translateParticipantText = [];
  const [issue, setIssue] = useState([
    { value: "1", label: "In-person" },
    { value: "2", label: "Virtual" },
  ]);

  const handleTopicsNameChange = (updatedOptions) => {
    setTopics(updatedOptions);
  };

  useEffect(() => {
    BindIECGridData(userProfileDatas);
  }, []);
  const BindIECGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const Binddata = {
        iecType: IECType,
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI([Binddata], "IECAPI/BindIECData");
      setBindGDDatas(response.data.Table);
      console.log("dddddd", response.data.Table);
      setBindGDParticipantDetailDatas(response.data.Table1);
      setBindGDTopicsDatas(response.data.Table2);
      //setBindResourceNameDatas(response.data.Table3);
      setBindFileData(response.data.Table3);
    }
  };
  const cancel = () => {
    setEditMode(false);
    setImageDisabled(false);
    // setCurrentData4({});
    setCurrentData3({});
    setIECParticipantDetails("");
    setSelectedRowIndex("");
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    Clearvalues();
    BindIECGridData(userProfileDatas);
    GenerateId();
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    getCurrentDate();
    Clearvalues();
    const stateLable = bindState.find(
      (ST) => ST.value === userProfileDatas[0].MstStateId
    );
    const stateValue = {
      value: userProfileDatas[0].MstStateId,
      label: stateLable ? stateLable.label : "Select",
    };
    setState(stateValue);

    const locationLable = getLocationDatas.find(
      (L) => L.MstLocationId === userProfileDatas[0].MstLocationId
    );
    const locationValue = {
      value: userProfileDatas[0].MstLocationId,
      label: locationLable ? locationLable.LocationName_en : "Select",
    };
    setBranchProject(locationValue);
    if(userProfileDatas[0].MstOrganizationId == 3){
    const SDPInLable = getSDPDatas.find(
      (SD) => SD.MstSDPId === userProfileDatas[0].MstSDPId
    );
    const SDPValue = {
      value: userProfileDatas[0].MstSDPId,
      label: SDPInLable ? SDPInLable.SDP_en : "Select",
    };
    setSdp(SDPValue);
  }
  };

  const Clearvalues = () => {
    setIECNo("");
    setTotalError("");
    setAgeTotalError("");
    setParticipantError("");
    setTopics("");
    setSdpCode("");
    setAreaCode("");
    setStateCode("");
    setBranchProjectCode("");
    setState("");
    setBranchProject("");
    setSdp("");
    setArea("");
    setVenue("");
    setDescription("");
    setChannel("");
    setStaffName1("");
    setStaffName2("");
    setDistrict("");
    setBlock("");
    setCityVillage("");
    setMale("");
    setFemale("");
    setOther("");
    setTotal("");
    setAge1("");
    setAge2("");
    setAge3("");
    setAgeTotal("");
    setShowImage("");
    setShowImageError("");
    setSelectedFiles([]);
    setTableData([
      {
        participantNameEn: "",
        participantNameTa: "",
        participantNameHi: "",
        participantNameMr: "",
        participantNameGu: "",
        participantNameKn: "",
        participantNameMl: "",
        participantNameTe: "",
        participantNameBn: "",
        participantNameOr: "",
        age: "",
        gender: "",
        mobileNumber: "",
        referralService: "",
        referredTo: "",
        fallowUpDate: "",
      },
    ]);
    // setIECNoError("");
    setDateError("");
    setStateError("");
    setTopicsError("");
    setBranchProjectError("");
    setSdpError("");
    setAreaError("");
    setVenueError("");
    setCityVillageError("");
    setBlockError("");
    setDistrictError("");
    setStaffNameError1("");
    // setStaffNameError2("");
    setChannelError("");
  };

  useEffect(() => {
    const fetchData = async () => {
      binddrpTopicsdata();
      binddrpstatedata();
      binddrpAgedata();
      binddrpGenderdata();
      binddrpReferredtodata();
      binddrpReferredBydata();
    };

    fetchData();
  }, []);

  const binddrpTopicsdata = async () => {
    try {
      const bindTopicsdatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindTopicsdatadrp.data.Table.length > 0) {
        const Topicsdatadrp = bindTopicsdatadrp.data.Table.map((m) => ({
          value: m.MstIECTopicsId,
          label: m.IECTopic_en,
        }));
        setBindTopics(Topicsdatadrp);
      } else {
        setBindTopics([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpstatedata = async () => {
    try {
      const bindStatedatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindStatedatadrp.data.Table1.length > 0) {
        setStateCodeDD(bindStatedatadrp.data.Table1);
        const stateDD = bindStatedatadrp.data.Table1.map((m) => ({
          value: m.MstStateId,
          label: m.StateName_en,
        }));
        setBindState(stateDD);
      } else {
        setBindState([]);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };

  const binddrpAgedata = async () => {
    try {
      const bindAgedatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindAgedatadrp.data.Table2.length > 0) {
        const AgeDD = bindAgedatadrp.data.Table2.map((m) => ({
          value: m.MstAgeId,
          label: m.Age,
        }));
        setBindAgeDD(AgeDD);
      } else {
        setBindAgeDD([]);
      }
    } catch (error) {
      console.error("Error fetching setAwarenessAge data:", error);
    }
  };

  const binddrpGenderdata = async () => {
    try {
      const bindGenderdatadrp = await BindAPI("IECAPI/BindIECAllMasterDatas");

      if (bindGenderdatadrp.data.Table3.length > 0) {
        const GenderDD = bindGenderdatadrp.data.Table3.map((m) => ({
          value: m.MstGenderId,
          label: m.Gender_en,
        }));
        setBindGenderRadioDD(GenderDD);
      } else {
        setBindGenderRadioDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };
  const binddrpReferredtodata = async () => {
    try {
      const bindReferredTodatadrp = await BindAPI(
        "IECAPI/BindIECAllMasterDatas"
      );

      if (bindReferredTodatadrp.data.Table4.length > 0) {
        const ReferredTodatadrpDD = bindReferredTodatadrp.data.Table4.map(
          (m) => ({
            value: m.MstReferredToId,
            label: m.ReferredTo_en,
          })
        );
        setBindreferredtoDD(ReferredTodatadrpDD);
      } else {
        setBindreferredtoDD([]);
      }
    } catch (error) {
      console.error("Error fetching ReferredTo data:", error);
    }
  };

  const binddrpReferredBydata = async () => {
    try {
      const bindReferredBydatadrp = await BindAPI(
        "IECAPI/BindIECAllMasterDatas"
      );

      if (bindReferredBydatadrp.data.Table5.length > 0) {
        const ReferredBydatadrpDD = bindReferredBydatadrp.data.Table5.map(
          (m) => ({
            value: m.MstReferredById,
            label: m.ReferredBy_en,
          })
        );
        setBindreferelserviceDD(ReferredBydatadrpDD);
      } else {
        setBindreferelserviceDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      setGetLocationDatas(locationResponse.data.Table);
    };

    const getSelectedSDP = async () => {
      const locationResponse = await BindAPI("SDPAPI/GetSDP");
      setGetSDPDatas(locationResponse.data.Table);
    };

    const getSelectedArea = async () => {
      const locationResponse = await BindAPI("AreaAPI/GetArea");
      setGetAreaDatas(locationResponse.data.Table);
    };

    const getSelectedDistrict = async () => {
      const locationResponse = await BindAPI("IECAPI/BindDistrict");
      setGetDistrictDatas(locationResponse.data.Table);
    };

    const getSelectedBlock = async () => {
      const locationResponse = await BindAPI("IECAPI/BindBlock");
      setGetBlockDatas(locationResponse.data.Table);
    };

    const getSelectedCity = async () => {
      const locationResponse = await BindAPI("IECAPI/BindCityVillage");
      setGetCityDatas(locationResponse.data.Table);
    };

    getSelectedLocation();
    getSelectedSDP();
    getSelectedArea();
    getSelectedDistrict();
    getSelectedBlock();
    getSelectedCity();
  }, []);

  useEffect(() => {
    const staffDropDownDatas = async () => {
      try {
        const staffNameResponse = await BindAPI("StaffNameAPI/BindStaffName");
        const staffNameData = staffNameResponse.data.Table.filter(
          (item) =>
            item.MstBranchProjecctId === userProfileDatas[0].MstLocationId
        ).map((item) => ({
          value: item.MstStaffNameId,
          label: item.StaffName_en,
        }));

        setBindStaffName(staffNameData);
      } catch (error) {}
    };

    staffDropDownDatas();
  }, []);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getDistrictDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );
    setBindDistrict([]);
    if (stateSelectedValue) {
      const districtLabels = getDistrictDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstDistrictId: item.MstDistrictId,
          District_en: item.District_en,
        }));

      const districtSelected = districtLabels.map((LC) => ({
        value: LC.MstDistrictId, // Replace with the appropriate value
        label: LC.District_en,
      }));
      setBindDistrict(districtSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const BlockSelectedValue = getBlockDatas.find(
      (item) => item.MstDistrictId == district.value // Replace with the desired MstStateId
    );
    setBindBlock([]);
    if (BlockSelectedValue) {
      const blockLabels = getBlockDatas
        .filter(
          (item) => item.MstDistrictId === BlockSelectedValue.MstDistrictId
        )
        .map((item) => ({
          MstBlockId: item.MstBlockId,
          Block_en: item.Block_en,
        }));

      const blockSelected = blockLabels.map((SD) => ({
        value: SD.MstBlockId, // Replace with the appropriate value
        label: SD.Block_en,
      }));
      setBindBlock(blockSelected);
    } else {
    }
  }, [district]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const citySelectedValue = getCityDatas.find(
      (item) => item.MstBlockId == block.value // Replace with the desired MstStateId
    );
    setBindCityVillage([]);
    if (citySelectedValue) {
      const cityLabels = getCityDatas
        .filter((item) => item.MstBlockId === citySelectedValue.MstBlockId)
        .map((item) => ({
          MstCityId: item.MstCityId,
          City_en: item.City_en,
        }));

      const citySelected = cityLabels.map((AR) => ({
        value: AR.MstCityId, // Replace with the appropriate value
        label: AR.City_en,
      }));
      setBindCityVillage(citySelected);
    } else {
    }
  }, [block]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value // Replace with the desired MstStateId
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId, // Replace with the appropriate value
        label: LC.LocationName_en,
      }));
      setBindLocationDD(locationSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const SDPSelectedValue = getSDPDatas.find(
      (item) => item.MstLocationId == branchProject.value // Replace with the desired MstStateId
    );

    if (SDPSelectedValue) {
      const sdpLabels = getSDPDatas
        .filter((item) => item.MstLocationId === SDPSelectedValue.MstLocationId)
        .map((item) => ({ MstSDPId: item.MstSDPId, SDP_en: item.SDP_en }));

      const sdpSelected = sdpLabels.map((SD) => ({
        value: SD.MstSDPId, // Replace with the appropriate value
        label: SD.SDP_en,
      }));
      setBindSDP(sdpSelected);
    } else {
    }
  }, [branchProject]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = getAreaDatas.find(
      (item) => item.MstSDPId == sdp.value // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = getAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
      }));
      setBindAreaDD(areaSelected);
    } else {
    }
  }, [sdp]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, "0");
    setDate(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;
  };

  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
      dropdownstate(selectedRowIndex);
      dropdownsdp(selectedRowIndex);
      dropdownlocation(selectedRowIndex);
      dropdownArea(selectedRowIndex);
      dropdownStaff1(selectedRowIndex);
      dropdownStaff2(selectedRowIndex);
      dropdownDistrict(selectedRowIndex);
      dropdownBlock(selectedRowIndex);
      dropdownCity(selectedRowIndex);
    }
  };

  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);

  const dropdownsdp = (rowIndex) => {
    try {
      const SDProwData = bindGDDatas[rowIndex];
      const SDPInfo = getSDPDatas.find(
        (SD) => SD.MstSDPId === SDProwData.MstSDPId
      );
      const ResultSDP = {
        value: SDProwData.MstSDPId,
        label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
      };
      setSdp(ResultSDP);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownlocation = (rowIndex) => {
    try {
      const locationrowData = bindGDDatas[rowIndex];
      const LocationInfo = getLocationDatas.find(
        (L) => L.MstLocationId === locationrowData.MstLocationId
      );
      const ResultLocation = {
        value: locationrowData.MstLocationId,
        label: LocationInfo
          ? LocationInfo.LocationName_en
          : "DefaultStateLabel",
      };
      setBranchProject(ResultLocation);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownArea = (rowIndex) => {
    try {
      const ArearowData = bindGDDatas[rowIndex];
      const ArearowDataInfo = getAreaDatas.find(
        (AT) => AT.MstAreaId === ArearowData.MstAreaId
      );
      const ResultArea = {
        value: ArearowData.MstAreaId,
        label: ArearowDataInfo
          ? ArearowDataInfo.AreaName_en
          : "DefaultStateLabel",
      };
      setArea(ResultArea);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownstate = (rowIndex) => {
    try {
      const StaterowData = bindGDDatas[rowIndex];
      const StateInfo = bindState.find(
        (ST) => ST.value === StaterowData.MstStateId
      );

      const resultState = {
        value: StaterowData.MstStateId,
        label: StateInfo ? StateInfo.label : "DefaultStateLabel",
      };

      setState(resultState);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownStaff1 = (rowIndex) => {
    try {
      const staffDropdown1 = bindGDDatas[rowIndex];

      const staffInfo1 = bindStaffName.find(
        (SD) => SD.value === staffDropdown1.StaffId1
      );
      const ResultStaff1 = {
        value: staffDropdown1.StaffId1,
        label: staffInfo1 ? staffInfo1.label : "DefaultStateLabel",
      };
      setStaffName1(ResultStaff1);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownStaff2 = (rowIndex) => {
    debugger;
    try {
      const staffDropdown2 = bindGDDatas[rowIndex];

      const staffInfo2 = bindStaffName.find(
        (SD) => SD.value === staffDropdown2.StaffId2
      );
      const ResultStaff2 = {
        value: staffDropdown2.StaffId2,
        label: staffInfo2 ? staffInfo2.label : "",
      };
      if (staffDropdown2.StaffId2 != null) {
        setStaffName2(ResultStaff2);
      } else {
        setStaffName2(ResultStaff2.value);
      }
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownDistrict = (rowIndex) => {
    try {
      const districtData = bindGDDatas[rowIndex];
      const districtInfo = getDistrictDatas.find(
        (L) => L.MstDistrictId === districtData.MstDistrictId
      );
      const ResultDistrict = {
        value: districtData.MstDistrictId,
        label: districtInfo ? districtInfo.District_en : "DefaultStateLabel",
      };
      setDistrict(ResultDistrict);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownBlock = (rowIndex) => {
    try {
      const blockData = bindGDDatas[rowIndex];
      const blockDataInfo = getBlockDatas.find(
        (AT) => AT.MstBlockId === blockData.MstBlockId
      );
      const ResultBlock = {
        value: blockData.MstBlockId,
        label: blockDataInfo ? blockDataInfo.Block_en : "DefaultStateLabel",
      };
      setBlock(ResultBlock);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };
  const dropdownCity = (rowIndex) => {
    try {
      const cityData = bindGDDatas[rowIndex];
      const cityInfo = getCityDatas.find(
        (ST) => ST.MstCityId === cityData.MstCityId
      );

      const resultCity = {
        value: cityData.MstCityId,
        label: cityInfo ? cityInfo.City_en : "DefaultStateLabel",
      };
      setCityVillage(resultCity);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    const bindMstIECId = bindGDDatas[rowIndex].MstIECId;
    setEditMstIECId(bindGDDatas[rowIndex].MstIECId);
    setIECNo(bindGDDatas[rowIndex].IECNo);
    setDate(bindGDDatas[rowIndex].OpeningDate);
    setVenue(bindGDDatas[rowIndex].Venue_en);
    setDescription(bindGDDatas[rowIndex].Descripition_en);
    setMale(bindGDDatas[rowIndex].NoOfPaticipantsMale);
    setFemale(bindGDDatas[rowIndex].NoOfPaticipantsFemale);
    setOther(bindGDDatas[rowIndex].NoOfPaticipantsOthers);
    setTotal(bindGDDatas[rowIndex].NoOfPaticipantsTotal);
    setAge1(bindGDDatas[rowIndex].Age1);
    setAge2(bindGDDatas[rowIndex].Age2);
    setAge3(bindGDDatas[rowIndex].Age3);
    setAgeTotal(bindGDDatas[rowIndex].AgeTotal);

    const ParticipantDetailDatas = Object.values(
      bindGDParticipantDetailDatas
    ).filter((item) => String(item.MstIECId) === String(bindMstIECId));

    // if (ParticipantDetailDatas.length === 0) {
    //   setTableData([
    //     {
    //       participantNameEn: "",
    //       age: "",
    //       gender: "",
    //       mobileNumber: "",
    //       referralService: "",
    //       referredTo: "",
    //       followUpDate: "",
    //     },
    //   ]);
    // } else {
    //   // Append the filtered data to your state using appendDataToState function
    //   appendDataToState(JSON.stringify(ParticipantDetailDatas));
    // }

    //new child table corrrected code

    setTableData([
      {
        participantNameEn: "",
        age: "",
        gender: "",
        mobileNumber: "",
        referralService: "",
        referredTo: "",
        followUpDate: "",
      },
    ]);

    if (ParticipantDetailDatas.length > 0) {
      const formattedDataArray = ParticipantDetailDatas.map((data) => {
        const formattedDateOfExpiry = data.FallowUpDate;
        const formattedDate1 = formattedDateOfExpiry
          ? formattedDateOfExpiry.split("T")[0]
          : null;
        return {
          participantNameEn: data.ParticipantName_en
            ? data.ParticipantName_en
            : "",
          age: data.MstAgeId ? data.MstAgeId : "",
          gender: data.MstGenderId ? data.MstGenderId : "",
          mobileNumber: data.MobileNumber ? data.MobileNumber : "",
          referralService: data.MstReferredById ? data.MstReferredById : "",
          referredTo: data.MstReferredToId ? data.MstReferredToId : "",
          fallowUpDate: formattedDate1 ? formattedDate1 : "",
        };
      });

      const nonEmptyRows = formattedDataArray.filter((row) => {
        return Object.values(row).some((value) => value !== "");
      });

      setTableData(nonEmptyRows);
      convertArrayToJSONString(nonEmptyRows);
    } else {
      // If there's no participant data, add a new row
      setTableData((prevData) => [...prevData]);
    }

    const ParticipantDetailsfilteredData = Object.values(
      bindGDTopicsDatas
    ).filter((item) => item.MstIECId === bindMstIECId);

    const topics = ParticipantDetailsfilteredData.map(
      (item) => item.MstTopicId
    );
    setTopics(topics);

    const IssueRadio = bindGDDatas[rowIndex].Channel;
    const IssueRadioString = IssueRadio ? IssueRadio.toString() : "";
    setChannel(IssueRadioString);

    const fileData = Object.values(bindFileData).filter(
      (item) => item.MstIECId === bindMstIECId
    );

    // Map over fileData to create an array of objects with file and previewUrl properties
    const fileupload = fileData.map((item, index) => ({
      file: { name: `Image ${index + 1}` },
      previewUrl: item.MultiFileUpload,
    }));

    // Create an array of preview URLs
    const imageUrlArray = fileupload.map((item) => item.previewUrl);

    // Join the URLs into a single string
    const imageUrlString = imageUrlArray.join(",");

    // Update the state with the selected files and image URLs
    setSelectedFiles(fileupload);
    setShowImage(imageUrlString);
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    console.log("sdpValidate", sdp);
    setSubmitBtnDisabled(true);
    const totalSize = selectedFiles.reduce(
      (acc, file) => acc + file.file.size,
      0
    );

    if (totalSize > 5 * 1024 * 1024) {
      toast.error("Total file size exceeds the limit (5MB)", {
        duration: 3000,
        position: "top-center",
      });
      return;
    }

    if (flag == 1 || flag == 2) {
      setFormSubmitted(true);
      // validation start
      const totalValidation = total
        ? total == ageTotal
          ? ""
          : "Gender and Age total be equal"
        : "Enter No. Of Participants/Coverage Gender";
      setTotalError(totalValidation);

      const ageTotalValidation = ageTotal
        ? ageTotal == total
          ? ""
          : "Age and Gender total be equal"
        : "Enter No. Of Participants/Coverage Age";
      setAgeTotalError(ageTotalValidation);

      const dateValidation = date ? "" : "Enter DOB";
      setDateError(dateValidation);

      const stateValidate = state ? "" : "Select State";
      setStateError(stateValidate);

      const channelValidate = channel ? "" : "Choose Channel";
      setChannelError(channelValidate);

      const locationValidate = branchProject ? "" : "Select Branch/Project";
      setBranchProjectError(locationValidate);

      const sdpValidate = sdp ? "" : "Select S.D.P.";
      setSdpError(sdpValidate);

      const areaValidate = area ? "" : "Select Area";
      setAreaError(areaValidate);

      const placeValidation = venue ? "" : "Enter Venue";
      setVenueError(placeValidation);

      const staffNameValidate1 = staffName1 ? "" : "Select Staff Name1";
      setStaffNameError1(staffNameValidate1);

      // const staffNameValidate2 = staffName2 ? "" : "Select Staff Name2";
      // setStaffNameError2(staffNameValidate2);

      const cityValidate = cityVillage ? "" : "Select City / Village";
      setCityVillageError(cityValidate);

      const districtValidate = district ? "" : "Select District";
      setDistrictError(districtValidate);

      const blockValidate = block ? "" : "Select Block";
      setBlockError(blockValidate);

      const topicsValidation = topics.length > 0 ? "" : "Select Topics";
      setTopicsError(topicsValidation);

      if (
        totalValidation ||
        ageTotalValidation ||
        dateValidation ||
        stateValidate ||
        locationValidate ||
        sdpValidate ||
        areaValidate ||
        placeValidation ||
        staffNameValidate1 ||
        cityValidate ||
        districtValidate ||
        blockValidate ||
        channelValidate ||
        topicsValidation
      ) {
        setIsOpen(true);
      }

      // let imageValidation = "";
      // let imageUrlString = showImage ? showImage.split(",") : [];
      // if (!showImage) {
      //   imageValidation = "Please Upload an Image";
      // } else {
      //   for (let i = 0; i < selectedFiles.length; i++) {
      //     if (!imageUrlString.includes(selectedFiles[i].previewUrl)) {
      //       imageValidation = "Please Upload an Image";
      //       break;
      //     }
      //   }
      // }

      // let imageValidation = "";
      // let imageUrlString = showImage ? showImage.split(",") : [];
      // if (!showImage) {
      //   imageValidation = "Please Upload an Image";
      // } else {
      //   if (selectedFiles.length !== imageUrlString.length) {
      //     imageValidation = "Please Upload an Image";
      //   }
      // }

      // console.log(imageValidation);
      // setShowImageError(imageValidation);

      let participantData;
      try {
        participantData = JSON.parse(IECParticipantDetails);
      } catch (error) {
        console.error("Error parsing participant data:", error);
        participantData = [];
      }

      const isEmptyParticipant = (participant) => {
        return (
          !participant.participantNameEn &&
          !participant.gender &&
          !participant.age &&
          !participant.mobileNumber &&
          !participant.referralService &&
          !participant.referredTo &&
          !participant.fallowUpDate
        );
      };

      if (!Array.isArray(participantData) && participantData !== " ") {
        console.error("Participant data is not an array");
        participantData = [];
      }

      if (Array.isArray(participantData)) {
        const allEmpty = participantData.every(isEmptyParticipant);
        if (allEmpty) {
          participantData = " ";
        }
      }

      const participantErrors = Array.isArray(participantData)
        ? participantData.map((participant, index) => {
            if (!participant) {
              return `Participant at row ${index + 1} is missing.`;
            }
            if (!participant.participantNameEn) {
              return `Participant at row ${index + 1} is missing a Name.`;
            }
            if (!participant.age) {
              return `Participant at row ${index + 1} is missing an Age.`;
            }
            if (!participant.gender) {
              return `Participant at row ${index + 1} is missing a Gender.`;
            }
            if (participant.referralService && !participant.referredTo) {
              setSubmitBtnDisabled(false);
              return `Participant at row ${
                index + 1
              } has Referral Service provided but is missing Referred To field.`;
            }
            return null;
          })
        : [];

      const minErrors = 5;
      const filteredErrors = participantErrors.filter(
        (error) => error !== null
      ); // Filter out null or undefined values

      if (filteredErrors.length > 0) {
        setIsOpen(true);
      }
      if (participantErrors.length < minErrors) {
        setIsOpen(true);
        filteredErrors.push(
          `A minimum of 5 rows is required. Please enter the participant details in Row ${
            participantErrors.length + 1
          }`
        );
      }

      setParticipantError(
        filteredErrors.length > 0 ? filteredErrors.join("; ") : ""
      );

      if (
        filteredErrors.length === 0 &&
        !totalValidation &&
        !ageTotalValidation &&
        !dateValidation &&
        !stateValidate &&
        !locationValidate &&
        !sdpValidate &&
        !areaValidate &&
        !placeValidation &&
        !staffNameValidate1 &&
        !cityValidate &&
        !districtValidate &&
        !blockValidate &&
        !channelValidate &&
        !topicsValidation
        // !imageValidation
        //  &&
        // filteredErrors.length === 0
      ) {
        setIsOpen(false);
        var topicsArrayString = JSON.stringify(topics);
        topicsArrayString = topicsArrayString.slice(1, -1);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalama
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = []; // Create an array to store translations
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(venue, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const translatedRemarks = []; // Create an array to store translations
        if (description) {
          for (let i of languageKeys) {
            const translateText = await LanguageTranslatorAPI(description, i);
            translatedRemarks.push(
              translateText.data.data.translations[0].translatedText
            );
          }
        }
        const data = {
          MstIECId: editMstIECId ? editMstIECId : 0,
          IECNo: IECNo,
          iecType: IECType,
          topics: topicsArrayString,
          iecDate: date,
          noOfPaticipantsMale: male ? male : null,
          noOfPaticipantsFemale: female ? female : null,
          noOfPaticipantsOthers: other ? other : null,
          noOfPaticipantsTotal: total ? total : null,
          age1: age1 ? age1 : null,
          age2: age2 ? age2 : null,
          age3: age3 ? age3 : null,
          ageTotal: ageTotal ? ageTotal : null,
          channel: channel,
          mstCountryId: 1,
          mstStateId: state.value,
          mstLocationId: branchProject.value,
          mstSDPId: sdp.value,
          mstAreaId: area.value,
          staffId1: staffName1.value,
          staffId2: staffName2.value,
          venueEn: venue,
          venueTa: translatedTexts[0],
          venueHi: translatedTexts[1],
          venueMr: translatedTexts[2],
          venueGu: translatedTexts[3],
          venueKn: translatedTexts[4],
          venueMl: translatedTexts[5],
          venueTe: translatedTexts[6],
          venueBn: translatedTexts[7],
          venueOr: translatedTexts[8],
          mstDistrictId: district.value,
          mstBlockId: block.value,
          mstCityId: cityVillage.value,
          iecImage: showImage,
          iecParticipantDetailsBulkUpload: IECParticipantDetails,
          descripition_en: description,
          descripition_ta: translatedRemarks[0],
          descripition_hi: translatedRemarks[1],
          descripition_mr: translatedRemarks[2],
          descripition_gu: translatedRemarks[3],
          descripition_kn: translatedRemarks[4],
          descripition_ml: translatedRemarks[5],
          descripition_te: translatedRemarks[6],
          descripition_bn: translatedRemarks[7],
          descripition_or: translatedRemarks[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "IECAPI/IUDIECICAPI");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Group Discussion data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Group Discussion data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Group Discussion data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Group Discussion data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        MstIECId: bindGDDatas[items].MstIECId
          ? bindGDDatas[items].MstIECId
          : editMstIECId,
        iecType: IECType,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "IECAPI/IUDIECICAPI");
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success("Group Discussion data deleted successfully 🗑️", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Group Discussion data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success("Group Discussion data blocked successfully 🚫", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Group Discussion data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success("Group Discussion data unblocked successfully ✅", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Group Discussion data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false);
    // setPopUpOpenCountry(false);
  };

  const HandleGDDetails = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }

    setCurrentData(bindGDDatas[rowIndex]);
    const EbindMstIECId = bindGDDatas[rowIndex].MstIECId;
    setEditMstIECId(EbindMstIECId);

    const StaterowData = bindGDDatas[rowIndex];
    const StateInfo = bindState.find(
      (ST) => ST.value === StaterowData.MstStateId
    );

    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };

    const locationrowData = bindGDDatas[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const SDProwData = bindGDDatas[rowIndex];
    const SDPInfo = getSDPDatas.find(
      (SD) => SD.MstSDPId === SDProwData.MstSDPId
    );
    const ResultSDP = {
      value: SDProwData.MstSDPId,
      label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
    };

    const ArearowData = bindGDDatas[rowIndex];
    const ArearowDataInfo = getAreaDatas.find(
      (AT) => AT.MstAreaId === ArearowData.MstAreaId
    );
    const ResultArea = {
      value: ArearowData.MstAreaId,
      label: ArearowDataInfo
        ? ArearowDataInfo.AreaName_en
        : "DefaultStateLabel",
    };

    const staffDropdown1 = bindGDDatas[rowIndex];
    const staffInfo1 = bindStaffName.find(
      (SD) => SD.value === staffDropdown1.StaffId1
    );
    const ResultStaff1 = {
      value: staffDropdown1.StaffId1,
      label: staffInfo1 ? staffInfo1.label : "DefaultStateLabel",
    };

    const staffDropdown2 = bindGDDatas[rowIndex];
    const staffInfo2 = bindStaffName.find(
      (SD) => SD.value === staffDropdown2.StaffId2
    );
    const ResultStaff2 = {
      value: staffDropdown2.StaffId2,
      label: staffInfo2 ? staffInfo2.label : "Select",
    };

    const districtData = bindGDDatas[rowIndex];
    const districtInfo = getDistrictDatas.find(
      (L) => L.MstDistrictId === districtData.MstDistrictId
    );
    const ResultDistrict = {
      value: districtData.MstDistrictId,
      label: districtInfo ? districtInfo.District_en : "DefaultStateLabel",
    };

    const blockData = bindGDDatas[rowIndex];
    const blockDataInfo = getBlockDatas.find(
      (AT) => AT.MstBlockId === blockData.MstBlockId
    );
    const ResultBlock = {
      value: blockData.MstBlockId,
      label: blockDataInfo ? blockDataInfo.Block_en : "DefaultStateLabel",
    };

    const cityData = bindGDDatas[rowIndex];
    const cityInfo = getCityDatas.find(
      (ST) => ST.MstCityId === cityData.MstCityId
    );

    const resultCity = {
      value: cityData.MstCityId,
      label: cityInfo ? cityInfo.City_en : "DefaultStateLabel",
    };

    const bindMstIECId = bindGDDatas[rowIndex].MstIECId;

    const ParticipantDetailDatas = Object.values(
      bindGDParticipantDetailDatas
    ).filter((item) => {
      return String(item.MstIECId) === String(bindMstIECId);
    });

    const TopicsfilteredData = Object.values(bindGDTopicsDatas).filter(
      (item) => item.MstIECId === bindMstIECId
    );

    const resultTopics = TopicsfilteredData.map((item) => item.MstTopicId);

    const IssueRadio = bindGDDatas[rowIndex].Channel;
    const IssueRadioString = IssueRadio ? IssueRadio.toString() : "";

    const fileData = Object.values(bindFileData).filter(
      (item) => item.MstIECId === bindMstIECId
    );

    const fileupload = fileData.map((item, index) => ({
      file: { name: `Image ${index + 1}` }, // You can customize the name if needed
      previewUrl: item.MultiFileUpload, // Assuming MultiFileUpload contains the image URL
    }));

    const GDData2 = [
      resultTopics,
      resultState,
      ResultLocation,
      ResultSDP,
      ResultArea,
      ResultStaff1,
      ResultDistrict,
      ResultBlock,
      resultCity,
      IssueRadioString,
      ResultStaff2,
    ];
    setCurrentData2(GDData2);
    setCurrentData3(ParticipantDetailDatas);
    const drpdetailsData = [
      bindtopics,
      bindAgeDD,
      bindgenderRadioDD,
      bindreferredtoDD,
      bindreferelserviceDD,
    ];
    setCurrentData4(drpdetailsData);
    setCurrentData5(fileupload);
  };

  // Add screen table grid function
  const [tableData, setTableData] = useState([
    {
      participantNameEn: "",
      participantNameTa: "",
      participantNameHi: "",
      participantNameMr: "",
      participantNameGu: "",
      participantNameKn: "",
      participantNameMl: "",
      participantNameTe: "",
      participantNameBn: "",
      participantNameOr: "",
      age: "",
      gender: "",
      mobileNumber: "",
      referralService: "",
      referredTo: "",
      fallowUpDate: "",
    },
  ]);

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;
      convertArrayToJSONString(newData);
      return newData;
    });
  };

  const updateTableData = (value, index, property) => {
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[index] = { ...updatedData[index], [property]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  const convertArrayToJSONString = async (array) => {
    try {
      const languageKeys = [
        "ta", // Tamil
        "hi", // Hindi
        "mr", // Marathi
        "gu", // Gujarati
        "kn", // Kannada
        "ml", // Malayalam
        "te", // Telugu
        "bn", // Bengali
        "or", // Oriya
      ];
      console.log("data", languageKeys, array);

      const formattedDataArray = await Promise.all(
        array.map(async (data) => {
          console.log("data", data, 2);

          const translateParticipantText = Array(languageKeys.length).fill("");

          for (let i = 0; i < languageKeys.length; i++) {
            const translateText = await LanguageTranslatorAPI(
              data.participantNameEn,
              languageKeys[i]
            );
            translateParticipantText[i] =
              translateText.data.data.translations[0].translatedText;
          }

          return {
            participantNameEn: data.participantNameEn,
            participantNameTa: translateParticipantText[0],
            participantNameHi: translateParticipantText[1],
            participantNameMr: translateParticipantText[2],
            participantNameGu: translateParticipantText[3],
            participantNameKn: translateParticipantText[4],
            participantNameMl: translateParticipantText[5],
            participantNameTe: translateParticipantText[6],
            participantNameBn: translateParticipantText[7],
            participantNameOr: translateParticipantText[8],
            age: data.age,
            gender: data.gender,
            mobileNumber: data.mobileNumber,
            referralService: data.referralService,
            referredTo: data.referredTo,
            fallowUpDate: data.fallowUpDate,
          };
        })
      );
      // setTableData(formattedDataArray);

      const jsonString = JSON.stringify(formattedDataArray);
      console.log("jsonString", jsonString);
      setIECParticipantDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          participantNameEn: "",
          age: "",
          gender: "",
          mobileNumber: "",
          referralService: "",
          referredTo: "",
          fallowUpDate: "",
        };
        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const handleAddRow = () => {
    if (tableData.length > 19) {
      return; // Exit the function if there are already 4 rows
    }
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.participantNameEn = ""; // Clear the values of the new row
    newRow.age = "";
    newRow.gender = "";
    newRow.mobileNumber = "";
    newRow.referralService = "";
    newRow.referredTo = "";
    newRow.fallowUpDate = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  const appendDataToState = (newDataString) => {
    const newDataArray = JSON.parse(newDataString);
    handleDeleteRow(0);
    const formattedDataArray = newDataArray.map((data) => {
      const formattedDateOfExpiry = data.FallowUpDate;
      const formattedDate1 = formattedDateOfExpiry
        ? formattedDateOfExpiry.split("T")[0]
        : null;
      return {
        participantNameEn: data.ParticipantName_en
          ? data.ParticipantName_en
          : "",
        age: data.MstAgeId ? data.MstAgeId : "",
        gender: data.MstGenderId ? data.MstGenderId : "",
        mobileNumber: data.MobileNumber ? data.MobileNumber : "",
        referralService: data.MstReferredById ? data.MstReferredById : "",
        referredTo: data.MstReferredToId ? data.MstReferredToId : "",
        fallowUpDate: formattedDate1 ? formattedDate1 : "",
      };
    });

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };

  const headerData = [
    {
      title: "Name",
      key: "participantNameEn",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "gender",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Mobile No.",
      key: "mobileNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "referralService",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral To",
      key: "referredTo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Follow-up",
      key: "fallowUpDate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showImage, setShowImage] = useState("");
  const [showImageError, setShowImageError] = useState("");

  const MAX_FILE_SIZE_MB = 2;
  const ALLOWED_FORMATS = ["image/jpeg", "image/jpg", "image/png"];

  const isFileFormatAllowed = (file) => ALLOWED_FORMATS.includes(file.type);

  const isFileSizeBelowLimit = (file) =>
    file.size <= MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFileChange = (event) => {
    setImageDisabled(false);
    const files = event.target.files;

    const selectedFilesLimited = Array.from(files)
      .filter((file) => isFileFormatAllowed(file) && isFileSizeBelowLimit(file))
      .slice(0, 3);

    if (selectedFiles.length + selectedFilesLimited.length > 3) {
      toast.error("Cannot select more than 3 files", {
        duration: 3000,
        position: "top-center",
      });
      return;
    }

    setSelectedFiles((prevFiles) => [
      ...prevFiles,
      ...selectedFilesLimited.map((file) => ({
        file,
        previewUrl: URL.createObjectURL(file),
      })),
    ]);

    const unsupportedFormatFiles = Array.from(files).filter(
      (file) => !isFileFormatAllowed(file)
    );
    if (unsupportedFormatFiles.length > 0) {
      const unsupportedFilesNames = unsupportedFormatFiles
        .map((file) => file.name)
        .join(", ");
      const errorMessage = `Unsupported file format: ${unsupportedFilesNames}`;
      toast.error(errorMessage, {
        duration: 4000,
        position: "top-center",
      });
    }

    const oversizedFiles = Array.from(files).filter(
      (file) => !isFileSizeBelowLimit(file)
    );
    if (oversizedFiles.length > 0) {
      const oversizedFilesNames = oversizedFiles
        .map((file) => file.name)
        .join(", ");
      const errorMessage = `File size exceeds the limit (${MAX_FILE_SIZE_MB}MB): ${oversizedFilesNames}`;
      toast.error(errorMessage, {
        duration: 4000,
        position: "top-center",
      });
    }
  };

  const handleUpload = async () => {
    setImageDisabled(true);
    if (selectedFiles.length === 0) {
      toast.error("Please select files to upload", {
        duration: 2000,
        position: "top-center",
      });
      return;
    }

    const IECType = "Group Discussion";
    const formData = new FormData();

    // Append each selected file to the formData
    selectedFiles.forEach((fileObj) => {
      formData.append("IECLogos", fileObj.file);
    });

    formData.append("IECType", IECType);

    try {
      const response = await ImageAPI(formData, "IECAPI/IECImageUpload");

      if (Array.isArray(response.data) && response.data.length > 0) {
        // const imageUrlArray = response.data.map(fileUrl => fileUrl);
        const allImageUrls = [
          ...selectedFiles.map((fileObj) => fileObj.previewUrl),
          ...response.data.map((fileUrl) => fileUrl),
        ];

        // Filter out URLs that start with 'blob:http://'
        const filteredImageUrls = allImageUrls.filter(
          (url) => !url.startsWith("blob:http://")
        );

        const imageUrlString = filteredImageUrls.join(",");

        setShowImage(imageUrlString);
        toast.success("Images uploaded successfully 📷", {
          duration: 3000,
          position: "top-center",
        });
      } else {
        setShowImageError("Failed to upload images");
        toast.error("Failed to upload images 👎", {
          duration: 2000,
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      setShowImageError("Error uploading images");
      toast.error("Failed to upload images 👎", {
        duration: 2000,
        position: "top-center",
      });
    }
  };

  const handleImageCancel = (index) => {
    const updatedTableData = [...selectedFiles];
    updatedTableData.splice(index, 1);
    setSelectedFiles(updatedTableData);

    const imageUrlArray = updatedTableData.map((fileObj) => fileObj.previewUrl);
    const imageUrlString = imageUrlArray.join(",");

    setShowImage(imageUrlString);
  };

  useEffect(() => {
    // Filter out empty values and sum the rest for gender (male, female, other)
    const totalChilds = [male, female, other]
      .filter((value) => typeof value === "number" && !isNaN(value)) // Filter out non-numeric and empty values
      .reduce((acc, value) => acc + value, 0); // Sum the values

    setTotal(totalChilds);

    if (formSubmitted) {
      GenderError();
    }

    // Compare total and ageTotal for equality after setting totals
    if (totalChilds !== ageTotal) {
      setTotalError("Gender and Age totals are not equal");
    } else {
      setTotalError(""); // Clear the error if they are equal
    }
  }, [male, female, other, ageTotal, formSubmitted]);

  useEffect(() => {
    // Filter out empty values and sum the rest for ages (age1, age2, age3)
    const totalAge = [age1, age2, age3]
      .filter((value) => typeof value === "number" && !isNaN(value)) // Filter out non-numeric and empty values
      .reduce((acc, value) => acc + value, 0); // Sum the values

    setAgeTotal(totalAge);

    if (formSubmitted) {
      AgeError();
    }

    // Compare ageTotal and total for equality after setting totals
    if (total !== totalAge) {
      setAgeTotalError("Gender and Age totals are not equal");
    } else {
      setAgeTotalError(""); // Clear the error if they are equal
    }
  }, [age1, age2, age3, total, formSubmitted]);

  // Validation for Age
  const AgeError = () => {
    if (ageTotal === 0) {
      setAgeTotalError("Enter No. Of Participants/Coverage Age");
    } else {
      setAgeTotalError("");
    }
  };

  // Validation for Gender
  const GenderError = () => {
    if (total === 0) {
      setTotalError("Enter No. Of Participants/Coverage Gender");
    } else {
      setTotalError("");
    }
  };

  const GenerateId = () => {
    if (addScreenTitle) {
      if (state && branchProject && sdp && area) {
        console.log("All required data is available");

        const filteredCategory = stateCodeDD
          .filter((item) => item.MstStateId === state.value)
          .map((item) => item.StateCode);
        const filteredBranchProject = getLocationDatas
          .filter((item) => item.MstLocationId === branchProject.value)
          .map((item) => item.LocationCode);
        const filteredSdp = getSDPDatas
          .filter((item) => item.MstSDPId === sdp.value)
          .map((item) => item.SDPCode);
        const filteredArea = getAreaDatas
          .filter((item) => item.MstAreaId === area.value)
          .map((item) => item.AreaCode);

        if (
          filteredCategory.length > 0 &&
          filteredBranchProject.length > 0 &&
          filteredSdp.length > 0 &&
          filteredArea.length > 0
        ) {
          console.log("Setting state codes");
          setStateCode(filteredCategory[0]);
          setBranchProjectCode(filteredBranchProject[0]);
          setSdpCode(filteredSdp[0]);
          setAreaCode(filteredArea[0]);
        }
      }
    }
  };
  useEffect(() => {
    if (addScreenTitle) {
      GenerateId();
    }
  }, [
    addScreenTitle,
    state,
    branchProject,
    sdp,
    area,
    stateCode,
    branchProjectCode,
    sdpCode,
    areaCode,
  ]);

  useEffect(() => {
    if (addScreenTitle) {
      if (stateCode && branchProjectCode && sdpCode) {
        const outputString = `${stateCode}/${branchProjectCode}/${sdpCode}/${areaCode}`;
        setIECNo(outputString);
        console.log("IEC No:", outputString);
      }
    }
  }, [addScreenTitle, stateCode, branchProjectCode, sdpCode, areaCode]);
  useEffect(() => {
    if (location.pathname == "/groupdiscussion") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // BindIECGridData(userProfileDatas);
      cancel();
    }
  }, [location, userProfileDatas]);
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="Group Discussion (5-20)"
          header={GroupDiscussionHeaders}
          records={bindGDDatas}
          handleEdit={handleEdit}
          handleAction={HandleGDDetails}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Group Discussion (5 - 20)"
          />
          <div className={mainTable.addShadowScreen}>
            <div className={mainTable.mainContentScreen}>
              <form class={mainTable.formMaster}>
                <div className={mainTable.grid3}>
                  <div>
                    <InputText
                      inputType="date"
                      classStar="text-red-500"
                      title=" Date"
                      name="date"
                      content={date}
                      controlFunc={(e) => setDate(e.target.value)}
                      controlFuncBlur={(e) => {
                        setDate(e.target.value);
                        setDateError(date ? "" : "Please Select Date");
                      }}
                      min={new Date().toISOString().split("T")[0]}
                    />
                    {dateError && <span class="text-red-500">{dateError}</span>}
                  </div>
                  <div>
                    <AutoDropDown
                      title="State"
                      classStar="text-red-500"
                      options={bindState}
                      placeholder="Select"
                      name="state"
                      selectedOption={state}
                      disabled={true}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setState(value);
                        setStateError(value ? "" : "Select State");
                        setBranchProject("");
                        setSdp("");
                        setArea("");
                        setDistrict("");
                        setBlock("");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select State");
                      }}
                    />
                    {stateError && (
                      <span className="text-red-500">{stateError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Branch/Project"
                      classStar="text-red-500"
                      options={bindLocation}
                      placeholder="Select"
                      name="branchProject"
                      selectedOption={branchProject}
                      disabled={true}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBranchProject(value);
                        setBranchProjectError(
                          value ? "" : "Select Branch/Project"
                        );
                        setSdp("");
                        setArea("");
                      }}
                      controlFuncBlur={() => {
                        setBranchProjectError(
                          branchProject ? "" : "Select Branch/Project"
                        );
                      }}
                    />
                    {branchProjectError && (
                      <span className="text-red-500">{branchProjectError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="S.D.P."
                      classStar="text-red-500"
                      options={bindSDP}
                      placeholder="Select"
                      name="sdp"
                      selectedOption={sdp}
                      disabled={editMode}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setSdp(value);
                        setSdpError(value ? "" : "Select S.D.P.");
                        setArea("");
                      }}
                      controlFuncBlur={() => {
                        setSdpError(sdp ? "" : "Select S.D.P.");
                      }}
                    />
                    {sdpError && (
                      <span className="text-red-500">{sdpError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Area"
                      classStar="text-red-500"
                      options={bindArea}
                      placeholder="Select"
                      name="area"
                      selectedOption={area}
                      disabled={editMode}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setArea(value);
                        setAreaError(value ? "" : "Select Area");
                      }}
                      controlFuncBlur={() => {
                        setAreaError(area ? "" : "Select Area");
                      }}
                    />
                    {areaError && (
                      <span className="text-red-500">{areaError}</span>
                    )}
                  </div>
                  <div>
                    <InputText
                      inputType="text"
                      classStar="text-red-500"
                      title="Group Discussion No. "
                      placeholder="MH/MUM/DHI/MAN/GD/2023/0001"
                      name="groupDiscussionNo"
                      maxLength={30}
                      disabled="disabled"
                      content={IECNo}
                      controlFunc={(e) => setIECNo(e.target.value)}
                      controlFuncBlur={(e) => {
                        setIECNo(e.target.value);
                        // setIECNoError(
                        //   IECNo ? "" : "Enter the Group Discussion No."
                        // );
                      }}
                    />
                    {/* {IECNoError && (
                      <span class="text-red-500">{IECNoError}</span>
                    )} */}
                  </div>
                  <div>
                    <MultiSelect
                      classStar="text-red-500"
                      label="Topics"
                      filteredOptions={bindtopics}
                      classFilterLabel="p-1 flex gap-1"
                      class="custom-class"
                      placeholder={
                        topics?.length > 0 ? "More Selected" : "Select..."
                      }
                      name="topics"
                      selectedOptions={topics}
                      classDiv="relative w-full inline-block"
                      onChange={handleTopicsNameChange}
                      controlFuncBlur={() => {
                        setTopicsError(
                          topics.length === 0 ? "Select Topics" : null
                        );
                      }}
                    />
                    {topicsError && (
                      <span class="text-red-500">{topicsError}</span>
                    )}
                  </div>
                </div>
                <div class={mainTable.grid2}>
                  <div className="col-span-2">
                    <label for="client_name" class={mainTable.label}>
                      No. of Participants/Coverage
                      <span className="text-red-500">*</span>
                    </label>
                    <hr class={mainTable.horizontalLine}></hr>
                    <div class="flex gap-6">
                      <div class="flex flex-col">
                        <label class={mainTable.label}>Gender</label>
                        <div className={mainTable.inputGenderClient}>
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"Male"}
                            name={"male"}
                            maxLength={5}
                            content={male}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setMale(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"Female"}
                            maxLength={5}
                            name={"femaleChild"}
                            content={female}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setFemale(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"Other"}
                            maxLength={5}
                            name={"otherChild"}
                            content={other}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setOther(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"total"}
                            maxLength={5}
                            disabled="disabled"
                            name={"totalChild"}
                            content={total}
                            controlFuncBlur={(e) => {
                              setTotal(e.target.value);
                            }}
                          />
                        </div>
                        {totalError && (
                          <span class="text-red-500">{totalError}</span>
                        )}
                      </div>
                      <div class="flex flex-col">
                        <label class={mainTable.label}>Age</label>
                        <div className={mainTable.inputGenderClient}>
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"10-14"}
                            name={"age1"}
                            maxLength={5}
                            content={age1}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setAge1(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"15-24"}
                            maxLength={5}
                            name={"age2"}
                            content={age2}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setAge2(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"25+"}
                            maxLength={5}
                            name={"age3"}
                            content={age3}
                            controlFunc={(e) => {
                              const value = e.target.value;
                              const parsedValue = parseInt(value, 10);
                              setAge3(isNaN(parsedValue) ? "" : parsedValue);
                            }}
                          />
                          <InputText
                            inputType={"text"}
                            ClassDiv="grid gap-2 grid-cols-2"
                            ClassLabel={mainTable.labelBaseline}
                            Class={mainTable.smallInput}
                            title={"Total"}
                            maxLength={5}
                            disabled="disabled"
                            name={"ageTotal"}
                            content={ageTotal}
                            controlFuncBlur={(e) => {
                              setAgeTotal(e.target.value);
                            }}
                          />
                        </div>
                        {ageTotalError && (
                          <span class="text-red-500">{ageTotalError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class={mainTable.grid3}>
                  <div>
                    <CheckboxOrRadioGroup
                      classStar="text-red-500"
                      title="Channel"
                      type={"radio"}
                      setName="channel"
                      selectedOptions={channel}
                      options={issue}
                      disabled={false}
                      tabIndex="1"
                      controlFunc={(e) => {
                        setChannel(e.target.value);
                        setChannelError(e.target.value ? "" : "Choose Chennel");
                      }}
                      controlFuncBlur={(e) => {
                        setChannel(e.target.value);
                        setChannelError(channel ? "" : "Choose Chennel");
                      }}
                    />
                    {channelError && (
                      <span class="text-red-500">{channelError}</span>
                    )}
                  </div>

                  <div>
                    <AutoDropDown
                      title="Staff Name1"
                      classStar="text-red-500"
                      options={bindStaffName}
                      placeholder="Select"
                      name="staffName1"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={staffName1}
                      controlFunc={(value) => {
                        setStaffName1(value);
                        setStaffNameError1(value ? "" : "Select Staff Name1");
                      }}
                      controlFuncBlur={() => {
                        setStaffNameError1(
                          staffName1 ? "" : "Select Staff Name1"
                        );
                      }}
                    />
                    {staffNameError1 && (
                      <span className="text-red-500">{staffNameError1}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Staff Name2"
                      options={bindStaffName}
                      placeholder="Select"
                      // classStar="text-red-500"
                      name="staffName2"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={staffName2}
                      controlFunc={(value) => {
                        setStaffName2(value);
                      }}
                      // controlFuncBlur={() => {
                      //   setStaffNameError2(
                      //     staffName2 ? "" : "Select Staff Name2"
                      //   );
                      // }}
                    />
                    {/* {staffNameError2 && (
                      <span className="text-red-500">{staffNameError2}</span>
                    )} */}
                  </div>
                  <div>
                    <InputText
                      title="Venue "
                      type="text"
                      name="venue"
                      maxLength={100}
                      classStar="text-red-500"
                      placeholder="Venue"
                      content={venue}
                      controlFunc={(e) => setVenue(e.target.value)}
                      controlFuncBlur={(e) => {
                        setVenue(e.target.value);
                        setVenueError(venue ? "" : "Enter Venue");
                      }}
                    />
                    {venueError && (
                      <span class="text-red-500">{venueError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="District"
                      classStar="text-red-500"
                      options={bindDistrict}
                      placeholder="Select"
                      name="district"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={district}
                      controlFunc={(value) => {
                        setDistrict(value);
                        setDistrictError(value ? "" : "Select District");
                        setBlock("");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setDistrictError(district ? "" : "Select District");
                      }}
                    />
                    {districtError && (
                      <span className="text-red-500">{districtError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="Block"
                      classStar="text-red-500"
                      options={bindBlock}
                      placeholder="Select"
                      name="block"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={block}
                      controlFunc={(value) => {
                        setBlock(value);
                        setBlockError(value ? "" : "Select Block");
                        setCityVillage("");
                      }}
                      controlFuncBlur={() => {
                        setBlockError(block ? "" : "Select Block");
                      }}
                    />
                    {blockError && (
                      <span className="text-red-500">{blockError}</span>
                    )}
                  </div>
                  <div>
                    <AutoDropDown
                      title="City / Village"
                      classStar="text-red-500"
                      options={bindCityVillage}
                      placeholder="Select"
                      name="cityVillage"
                      Class="custom-class"
                      tabIndex="1"
                      selectedOption={cityVillage}
                      controlFunc={(value) => {
                        setCityVillage(value);
                        setCityVillageError(
                          value ? "" : "Select City / Village"
                        );
                      }}
                      controlFuncBlur={() => {
                        setCityVillageError(
                          cityVillage ? "" : "Select City / Village"
                        );
                      }}
                    />
                    {cityVillageError && (
                      <span className="text-red-500">{cityVillageError}</span>
                    )}
                  </div>
                </div>
                <div className={mainTable.grid3}>
                  <div>
                    <div className="mb-2">
                      <label className="block mb-2 text-sm capitalize font-medium text-neutral-700">
                        File Upload
                      </label>
                    </div>
                    <input
                      type="file"
                      className={mainTable.input}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png"
                    />
                    {showImageError && (
                      <span className="text-red-500">{showImageError}</span>
                    )}
                    {selectedFiles.length > 3 && (
                      <div className="text-red-500">
                        Reached Maximum No. of Uploads
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {selectedFiles.length > 0 && (
                    <div class="flex gap-8">
                      {selectedFiles.map((item, index) => (
                        <div key={index} className="image-container">
                          <span
                            class="float-right cursor-pointer"
                            style={{ cursor: "pointer" }} // Add cursor pointer style here
                            onClick={() => handleImageCancel(index)}
                          >
                            X
                          </span>
                          <img
                            src={item.previewUrl}
                            alt={`preview-${index}`}
                            class={`w-28 h-28 m-3`}
                          />
                          <span class={mainTable.label}>{item.file?.name}</span>
                          {index === selectedFiles.length - 1 && (
                            <input
                              type="button"
                              // className={mainTable.addButton}
                              className={
                                imageDisabled
                                  ? mainTable.disabledSubmitButton
                                  : mainTable.submitButton
                              }
                              onClick={handleUpload}
                              // disabled={selectedFiles.length >= 4}
                              disabled={imageDisabled ? true : false}
                              value="Upload"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div class={mainTable.labelBold}>List Of Participants</div>
                <div className="hidden md:block overflow-x-auto">
                  <div className="table-container w-screen md:w-max xl:w-full max-h-96">
                    <table className="w-full border border-gray-300">
                      <thead>
                        <tr>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Name
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Age
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Gender
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Mobile No.
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Referral Service
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Referred To
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                            Follow-up
                          </th>
                          <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            <td className="border border-gray-300 p-2">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.participantNameEn}
                                maxLength={100}
                                placeholder="Name"
                                onChange={(e) => {
                                  const InputText = e.target.value;
                                  if (/^[A-Za-z\s]*$/.test(InputText)) {
                                    handleInputChange(
                                      InputText,
                                      index,
                                      "participantNameEn"
                                    );
                                    updateTableData(
                                      InputText,
                                      index,
                                      "participantNameEn"
                                    );
                                  }
                                }}
                              />
                            </td>

                            <td className="border border-gray-300 p-2 w-28">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.age}
                                maxLength={2}
                                placeholder="Age"
                                onChange={(e) => {
                                  const InputText = e.target.value;
                                  if (/^\d{0,2}$/.test(InputText)) {
                                    handleInputChange(InputText, index, "age");
                                    updateTableData(InputText, index, "age");
                                  }
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindgenderRadioDD}
                                placeholder="Select"
                                name="gender"
                                selectedOption={row.gender}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "gender"
                                  )
                                }
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <input
                                type="text"
                                className={mainTable.input}
                                value={row.mobileNumber}
                                maxLength={11}
                                onChange={(e) => {
                                  const InputText = e.target.value;
                                  if (/^[0-9]*$/.test(InputText)) {
                                    handleInputChange(
                                      InputText,
                                      index,
                                      "mobileNumber"
                                    );
                                    updateTableData(
                                      InputText,
                                      index,
                                      "mobileNumber"
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindreferelserviceDD}
                                placeholder="Select"
                                name="referralService"
                                selectedOption={row.referralService}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "referralService"
                                  )
                                }
                              />
                            </td>
                            <td className="border border-gray-300 p-2">
                              <DropDown
                                options={bindreferredtoDD}
                                placeholder="Select"
                                name="referredTo"
                                selectedOption={row.referredTo}
                                controlFunc={(e) =>
                                  updateTableData(
                                    e.target.value,
                                    index,
                                    "referredTo"
                                  )
                                }
                              />
                            </td>

                            <td className="border border-gray-300 p-2">
                              <input
                                type="date"
                                className={mainTable.input}
                                value={row.fallowUpDate}
                                onChange={(e) => {
                                  const selectedDate = new Date(e.target.value);
                                  const currentDate = new Date();
                                  // if (selectedDate >= currentDate) {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "fallowUpDate"
                                    );
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "fallowUpDate"
                                    );
                                  // } else {
                                  //   console.log("Please select a future date.");
                                  // }
                                }}
                                min={new Date().toISOString().split("T")[0]}
                              />
                            </td>

                            <td className="border border-gray-300 w-16">
                              <div className="flex gap-4 align-baseline items-center justify-center">
                                <svg
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  class="w-6 h-6 text-black cursor-pointer"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                </svg>
                                <svg
                                  viewBox="0 0 1024 1024"
                                  fill="currentColor"
                                  onClick={handleAddRow}
                                  class="w-6 h-6 text-black cursor-pointer"
                                >
                                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                </svg>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="hidden md:block">
                  {participantError && (
                    <div className="text-red-500">{participantError}</div>
                  )}
                </div>
                <div className={mainTable.childTableMobile} role="table">
                  <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                    {tableData.length > 0 &&
                      tableData.map((row, index) => (
                        <div scope="col" class={mainTable.mobileTable}>
                          {headerData.map((keyData) => (
                            <div
                              scope="cell"
                              class={mainTable.tableData}
                              role="cell"
                            >
                              {keyData.key === "Action" ? (
                                <div class={mainTable.tableData}>
                                  <div className="md:block hidden">
                                    <button
                                      id="dropdownMenuIconButton"
                                      data-dropdown-toggle="dropdownDots"
                                      class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                      type="button"
                                    >
                                      <svg
                                        class="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                  <div className="md:hidden flex justify-center">
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={handleAddRow}
                                      value="Add"
                                    />
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={() => handleDeleteRow(index)}
                                      value="Delete"
                                    />
                                  </div>
                                </div>
                              ) : keyData.key === "participantNameEn" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.participantNameEn}
                                    maxLength={100}
                                    placeholder="Name"
                                    onChange={(e) => {
                                      const InputText = e.target.value;
                                      if (/^[A-Za-z\s]*$/.test(InputText)) {
                                        handleInputChange(
                                          InputText,
                                          index,
                                          "participantNameEn"
                                        );
                                        updateTableData(
                                          InputText,
                                          index,
                                          "participantNameEn"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "age" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.age}
                                    maxLength={100}
                                    placeholder="Age"
                                    onChange={(e) => {
                                      const InputText = e.target.value;
                                      if (/^\d{0,2}$/.test(InputText)) {
                                        handleInputChange(
                                          InputText,
                                          index,
                                          "age"
                                        );
                                        updateTableData(
                                          InputText,
                                          index,
                                          "age"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "gender" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindgenderRadioDD}
                                    placeholder="Select"
                                    name="gender"
                                    selectedOption={row.gender}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "gender"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "mobileNumber" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="text"
                                    className={mainTable.input}
                                    value={row.mobileNumber}
                                    maxLength={11}
                                    onChange={(e) => {
                                      const InputText = e.target.value;
                                      if (/^[0-9]*$/.test(InputText)) {
                                        handleInputChange(
                                          InputText,
                                          index,
                                          "mobileNumber"
                                        );
                                        updateTableData(
                                          InputText,
                                          index,
                                          "mobileNumber"
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "referralService" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindreferelserviceDD}
                                    placeholder="Select"
                                    name="referralService"
                                    selectedOption={row.referralService}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "referralService"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "referredTo" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <DropDown
                                    options={bindreferredtoDD}
                                    placeholder="Select"
                                    name="referredTo"
                                    selectedOption={row.referredTo}
                                    controlFunc={(e) =>
                                      updateTableData(
                                        e.target.value,
                                        index,
                                        "referredTo"
                                      )
                                    }
                                  />
                                </div>
                              ) : keyData.key === "fallowUpDate" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <input
                                    type="date"
                                    className={mainTable.input}
                                    value={row.fallowUpDate}
                                    onChange={(e) => {
                                      const selectedDate = new Date(
                                        e.target.value
                                      );
                                      const currentDate = new Date();
                                      if (selectedDate >= currentDate) {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "fallowUpDate"
                                        );
                                        updateTableData(
                                          e.target.value,
                                          index,
                                          "fallowUpDate"
                                        );
                                      } else {
                                        console.log(
                                          "Please select a future date."
                                        );
                                      }
                                    }}
                                    min={new Date().toISOString().split("T")[0]}
                                  />
                                </div>
                              ) : (
                                <div className="flex border p-1">
                                  <div className="flex basis-1/2 font-bold">
                                    {keyData.title}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="md:hidden">
                  {participantError && (
                    <div className="text-red-500">{participantError}</div>
                  )}
                </div>
                <div class="lg:col-span-5 sm:col-span-1">
                  <TextArea
                    inputType="text"
                    title="Description"
                    name="description"
                    rows="4"
                    content={description}
                    placeholder="Description"
                    controlFunc={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
              <Buttons
                addScreenTitle={addScreenTitle}
                updateScreenTitle={updateScreenTitle}
                handleSubmit={handleSubmit}
                cancel={cancel}
                disabledBtn={submitBtnDisabled ? true : false}
              />
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={HandleGDDetails}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Group Discussion (5 - 20)"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
          />
          <GroupDiscussionPopup
            data={currentData}
            data1={currentData2}
            data2={currentData3}
            data3={currentData4}
            data4={currentData5}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
}

export default GroupDiscussion;
