import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import GridPage from "../components/GridPage";
import {
  consumablesHeader,
  consumptionHeaders,
  contraceptiveHeader,
  medicinesDrugsHeader,
  socialMarketingHeader,
} from "../components/HeaderAttribute";
import AddScreenTitle from "../components/AddScreenTitle";
import { mainTable } from "../css/Common";
import Buttons from "../components/Buttons";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import InputText from "../ReusableComponents/InputText";
import { useLocation } from "react-router-dom";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";
import MainPopup from "../popup-containers/MainPopup";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Commoditiespopup from "../popup-containers/Commodities";
import PopUpButtons from "../components/PopUpButtons";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const Commodities = () => {
  //visiable state handle
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [visibleMode, setVisibleMode] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // const [visibleTable1, setVisibleTable1] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [tableTitle, setTableTitle] = useState("");
  const [addTitle, setAddTitle] = useState("");
  const [commodityType, setCommodityType] = useState("");
  const [tableHeader, setTableHeader] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [commodityTypeState, setCommodityTypeState] = useState(0);
  //location path handle states
  const location = useLocation();

  //commodities dropdown state handle
  const [commoditiesCategories, setCommoditiesCategories] = useState([]);
  const [commoditiesCategoriesLabel, setCommoditiesCategoriesLabel] =
    useState("");
  const [bindModeDD, setBindModeDD] = useState([]);
  const [bindUnitOfMeasure, setBindUnitOfMeasure] = useState([]);

  //text filed state handle
  const [categoriesNameFiled, setCategoriesNameFiled] = useState("");

  //handle payload state
  const [mstCommoditiesId, setMstCommoditiesId] = useState("");
  const [commoditiesFileds, setCommoditiesFileds] = useState({
    categories: "",
    name: "",
    description: "",
    mode: "",
    mstCommodityUOMId: "",
    sdpLevel: "",
    outreachLevel: "",
    branchLevel: "",
    itemCategory: "",
  });

  const [commoditiesFieldsError, setCommoditiesFieldsError] = useState({
    categoriesError: "",
    nameError: "",
    descriptionError: "",
    modeError: "",
    openingQuantityError: "",
    unitOfMeasureError: "",
    openingDateError: "",
    chargesError: "",
    sdpLevelError: "",
    outreachLevelError: "",
    branchLevelError: "",
  });

  //popup state handle
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [textFiledsPopupData, setTextFiledsPopupData] = useState([]);
  const [autoCompleteDropDownPopupData, setAutoCompleteDropDownPopupData] =
    useState({});

    const [isOpen, setIsOpen] = useState(false);

  const showAdd = () => {
    setVisibleTable(false);
    setVisibleAdd(true);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];

    setCommoditiesFileds((prevFields) => ({
      ...prevFields,
      openingDate: formattedDate,
    }));
  };

  const bindGridData = async (fetchData) => {
    console.log("fetchData", fetchData);
    try {
      let response = await BindAPI("CommoditiesAPI/BindCommodities");
      let filteredContraceptiveGridData = Object.values(
        response.data.Table
      ).filter((item) => item.MstCommodityTypeId == fetchData);
      console.log(
        "filteredContraceptiveGridData",
        filteredContraceptiveGridData
      );
      setTableData(filteredContraceptiveGridData);
    } catch (error) {
      console.log(`Data fetching error: ${error}`);
    }
  };

  const cancel = async () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    clear();
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    bindGridData(commodityTypeState);
  };

  const clear = () => {
    setCommoditiesFileds({
      categories: "",
      name: "",
      description: "",
      mode: "",
      mstCommodityUOMId: "",
      sdpLevel: "",
      outreachLevel: "",
      branchLevel: "",
    });

    setCommoditiesFieldsError({
      categoriesError: "",
      nameError: "",
      descriptionError: "",
      modeError: "",
      unitOfMeasureError: "",
      sdpLevelError: "",
      outreachLevelError: "",
      branchLevelError: "",
    });
  };

  const getCommodityUOMDDValues = async () => {
    // Unit of Measure Dropdown binding
    const response = await BindAPI("CommoditiesAPI/BindCommodityUOM");
    const getMstCommodityUOMId =
      response.data.Table && response.data.Table.length > 0
        ? response.data.Table.map((item) => ({
            value: item.MstCommodityUOMId,
            label: item.CommodityUOM,
          }))
        : [];
    setBindUnitOfMeasure(getMstCommodityUOMId);
  };

  const getModeDDValues = async () => {
    // Mode Dropdown value binding
    const response = await BindAPI("CommoditiesAPI/BindMode");
    const getMstModeId =
      response.data.Table && response.data.Table.length > 0
        ? response.data.Table.map((item) => ({
            value: item.MstModeId,
            label: item.ModeName,
          }))
        : [];
    setBindModeDD(getMstModeId);
  };

  const categoriesDropDown = async (fetchData) => {
    try {
      let response = await BindAPI("CommoditiesAPI/BindCommodityCategory");
      let filteredCategoriosData = Object.values(response.data.Table).filter(
        (item) => item.MstCommodityTypeId == fetchData
      );
      const getCommoditiesCategory = filteredCategoriosData.map((item) => ({
        value: item.MstCommodityCategoryId,
        label: item.CommodityCategory_en,
      }));
      setCommoditiesCategories(getCommoditiesCategory);
      console.log("getCommoditiesCategory", getCommoditiesCategory);
    } catch (error) {
      console.log(`Data fetching error: ${error}`);
    }
  };

  useEffect(() => {
    // cancel();
    // if (visibleTable1) {
    if (location.pathname == "/contraceptive") {
      setTableTitle("Contraceptive items");
      setAddTitle("Contraceptive items");
      setCommodityType(1);
      setTableHeader(contraceptiveHeader);
      bindGridData(1);
      categoriesDropDown(1);
      setCommoditiesCategoriesLabel("Contraceptive items Categories");
      setCategoriesNameFiled("Contraceptive items");
      setCommodityTypeState(1);
      setVisibleAdd(false);
      setVisibleTable(true);
      setVisibleMode(false);
      clear();
    } else if (location.pathname == "/medicinesdrugs") {
      setTableTitle("Medicines / Drugs");
      setAddTitle("Medicines / Drugs");
      setCommodityType(2);
      setTableHeader(medicinesDrugsHeader);
      bindGridData(2);
      categoriesDropDown(2);
      setCommoditiesCategoriesLabel("Medicines / Drugs Categories");
      setCategoriesNameFiled("Medicines Name");
      getModeDDValues();
      setCommodityTypeState(2);
      setVisibleAdd(false);
      setVisibleTable(true);
      setVisibleMode(true);
      clear();
    } else if (location.pathname == "/socialmarketing") {
      setTableTitle("Social Marketing");
      setAddTitle("Social Marketing");
      setCommodityType(3);
      setTableHeader(socialMarketingHeader);
      bindGridData(3);
      categoriesDropDown(3);
      setCommoditiesCategoriesLabel("Social Marketing Categories");
      setCategoriesNameFiled("Social Marketing Name");
      setCommodityTypeState(3);
      setVisibleAdd(false);
      setVisibleTable(true);
      setVisibleMode(false);
      clear();
    } else if (location.pathname == "/consumables") {
      setTableTitle("Consumables");
      setAddTitle("Consumables");
      setCommodityType(4);
      setTableHeader(consumablesHeader);
      bindGridData(4);
      categoriesDropDown(4);
      setCommoditiesCategoriesLabel("Consumables Categories");
      setCategoriesNameFiled("Consumables Name");
      setCommodityTypeState(4);
      setVisibleAdd(false);
      setVisibleTable(true);
      setVisibleMode(false);
      clear();
    }
    // }
    getCommodityUOMDDValues();
  }, [location.pathname ? location.pathname : null]);
  useEffect(() => {
    if (
      location.pathname == "/contraceptive" ||
      location.pathname == "/medicinesdrugs" ||
      location.pathname == "/socialmarketing" ||
      location.pathname == "/consumables"
    ) {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      // bindGridData();
    }
  }, [location]);
  const handleCommoditiesFileds = (value, name) => {
    setCommoditiesFileds({
      ...commoditiesFileds,
      [name]: value,
    });
  };

  const validate = (flag, currentScreen) => {
    const errors = {};

    if (flag == 1 || flag == 2) {
      if (commoditiesFileds.categories == "") {
        errors.categories = `Select ${commoditiesCategoriesLabel}`;
      }

      // Validation logic for the contraceptiveFields.contraceptiveName input
      if (!commoditiesFileds.name.trim()) {
        errors.name = `Enter ${categoriesNameFiled}`;
      } 
      // else if (!/^[A-Za-z ]+$/.test(commoditiesFileds.name)) {
      //   errors.name = "Only alphabet and space allowed";
      // }

      // // Validation logic for the opening quantity input
      // if (!commoditiesFileds.openingQuantity) {
      //   errors.openingQuantity = "Enter Opening Quantity";
      // } else if (!/^\d+$/.test(commoditiesFileds.openingQuantity)) {
      //   errors.openingQuantity = "Only numbers allowed";
      // }

      // Validation logic for the Unit Of Measure input
      if (commoditiesFileds.mstCommodityUOMId == "") {
        errors.unitOfMeasure = "Select Unit Of Measure";
      }

      // // Validation logic for the Charges input
      // if (!commoditiesFileds.charges) {
      //   errors.charges = "Enter Charges";
      // } else if (!/^\d+(\.\d+)?$/.test(commoditiesFileds.charges)) {
      //   errors.charges = "Only numbers and dot allowed";
      // }

      // Validation logic for the SDP Level input
      if (!commoditiesFileds.sdpLevel) {
        errors.sdpLevel = "Enter SDP Level";
      } else if (!/^\d+$/.test(commoditiesFileds.sdpLevel)) {
        errors.sdpLevel = "Only numbers allowed";
      }

      // Validation logic for the Outreach Level input
      if (!commoditiesFileds.outreachLevel) {
        errors.outreachLevel = "Enter Outreach Level";
      } else if (!/^\d+$/.test(commoditiesFileds.outreachLevel)) {
        errors.outreachLevel = "Only numbers allowed";
      }

      // Validation logic for the Branch Level input
      if (!commoditiesFileds.branchLevel) {
        errors.branchLevel = "Enter Branch Level";
      } else if (!/^\d+$/.test(commoditiesFileds.branchLevel)) {
        errors.branchLevel = "Only numbers allowed";
      }

      if (currentScreen == "2") {
        if (commoditiesFileds.mode == "") {
          errors.mode = "Select Mode";
        }
      }
    }

    setCommoditiesFieldsError({
      ...commoditiesFieldsError,
      categoriesError: errors.categories || "",
      nameError: errors.name || "",
      modeError: errors.mode || "",
      unitOfMeasureError: errors.unitOfMeasure || "",
      sdpLevelError: errors.sdpLevel || "",
      outreachLevelError: errors.outreachLevel || "",
      branchLevelError: errors.branchLevel || "",
    });

    return errors;
  };

  const handleSubmit = async (items, flag) => {
    console.log("commoditoies", commoditiesFileds);
    setSubmitBtnDisabled(true);
    const commoditiesValidation = validate(flag, commodityType);
    if(Object.keys(commoditiesValidation).length !== 0){
      setIsOpen(true);
    }
    if (Object.keys(commoditiesValidation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1 || flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const commodityNametxt = [];

        const descriptiontxt = [];

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            commoditiesFileds.name,
            i
          );
          commodityNametxt.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            commoditiesFileds.description,
            i
          );
          descriptiontxt.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const commoditiesData = {
          mstCommoditiesID: mstCommoditiesId ? mstCommoditiesId : 0,
          mstCommodityTypeId: commodityType,
          mstCommodityCategoryId: commoditiesFileds.categories,
          commoditiesNameEn: commoditiesFileds.name,
          commoditiesNameTa: commodityNametxt[0],
          commoditiesNameHi: commodityNametxt[1],
          commoditiesNameMr: commodityNametxt[2],
          commoditiesNameGu: commodityNametxt[3],
          commoditiesNameKn: commodityNametxt[4],
          commoditiesNameMl: commodityNametxt[5],
          commoditiesNameTe: commodityNametxt[6],
          commoditiesNameBn: commodityNametxt[7],
          commoditiesNameOr: commodityNametxt[8],
          mode: commoditiesFileds.mode ? commoditiesFileds.mode : 0,
          descriptionEn: commoditiesFileds.description,
          descriptionTa: descriptiontxt[0],
          descriptionHi: descriptiontxt[1],
          descriptionMr: descriptiontxt[2],
          descriptionGu: descriptiontxt[3],
          descriptionKn: descriptiontxt[4],
          descriptionMl: descriptiontxt[5],
          descriptionTe: descriptiontxt[6],
          descriptionBn: descriptiontxt[7],
          descriptionOr: descriptiontxt[8],
          mstCommodityUOMId: commoditiesFileds.mstCommodityUOMId,
          commoditySDPLevel: commoditiesFileds.sdpLevel,
          commodityOutreachLevel: commoditiesFileds.outreachLevel,
          commodityBranchLevel: commoditiesFileds.branchLevel,
          createdBy: createdBy,
          flag: flag,
          itemCategory: commoditiesFileds.itemCategory,
        };

        console.log("commoditiesData", commoditiesData);

        const response = await IUDAPI(
          commoditiesData,
          "CommoditiesAPI/IUDCommodities"
        );
        if (flag == 1) {
          if (response.data.Table[0].result === "S") {
            toast.success(`${tableTitle} data added successfully 👍`, {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error(`Failed to add ${tableTitle} data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].result === "M") {
            toast.success(`${tableTitle} data updated successfully 👍`, {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error(`Failed to update  ${tableTitle} data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        const commoditiesData = {
          mstCommoditiesID: tableData[items].MstCommoditiesId,
          createdBy: createdBy,
          flag: flag,
        };
        console.log("commoditiesData2", commoditiesData);
        const response = await IUDAPI(
          commoditiesData,
          "CommoditiesAPI/IUDCommodities"
        );
        if (flag == 3) {
          // Delete
          if (response.data.Table[0].result === "D") {
            toast.success(` ${tableTitle} data deleted successfully 🗑️`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(`Failed to delete  ${tableTitle} data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          // Block
          if (response.data.Table[0].result === "B") {
            toast.success(` ${tableTitle} data blocked successfully 🚫`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(`Failed to block  ${tableTitle} data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          // Unblock
          if (response.data.Table[0].result === "UB") {
            toast.success(` ${tableTitle} data unblocked successfully ✅`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(`Failed to unblock  ${tableTitle} data 👎`, {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
      console.log("no all data here");
    }
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    // const currentDate = tableData[rowIndex].OpeningDate;
    // const formattedDate = currentDate.split("T")[0];
    // console.log("Assssssssss", tableData[rowIndex].Mode);
    setCommoditiesFileds({
      ...commoditiesFileds,

      mode: tableData[rowIndex].Mode,
    });
    setCommoditiesFileds({
      ...commoditiesFileds,
      categories: tableData[rowIndex].MstCommodityCategoryId,
      name: tableData[rowIndex].CommodityName_en,
      description: tableData[rowIndex].Description_en,

      mstCommodityUOMId: tableData[rowIndex].MstCommodityUOMId,

      sdpLevel: tableData[rowIndex].CommoditySDPLevel,
      outreachLevel: tableData[rowIndex].CommodityOutreachLevel,
      branchLevel: tableData[rowIndex].CommodityBranchLevel,
      mode: tableData[rowIndex].Mode,
      itemCategory: tableData[rowIndex].ItemCategory,
    });
    setMstCommoditiesId(tableData[rowIndex].MstCommoditiesId);
  };
  const handleOpen = (isOpen) => {
    // Popup open
    setpopUpOpen(isOpen);
  };
  const handleClose = () => {
    setpopUpOpen(false); // Popup close
  };

  const handleCommoditiesDetails = (rowIndex, flag) => {
    console.log(rowIndex, flag);
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }

    const CategoriesrowData = tableData[rowIndex];
    const CategoriesInfo = commoditiesCategories.find(
      (CT) => CT.value == CategoriesrowData.MstCommodityCategoryId
    );
    const ResultCategories = [
      {
        value: CategoriesrowData.MstCommodityCategoryId,
        label: CategoriesInfo ? CategoriesInfo.label : "Unknown",
      },
    ];

    const UOMrowData = tableData[rowIndex];
    const UOMInfo = bindUnitOfMeasure.find((UM) => UM.value == UOMrowData.UOM);
    const ResultUOM = [
      {
        value: UOMrowData.UOM,
        label: UOMInfo ? UOMInfo.label : "Unknown",
      },
    ];

    const filterUOM = bindUnitOfMeasure.filter(
      (x) => x.value == tableData[rowIndex].MstCommodityUOMId
    );

    // var DateOpeningPart = tableData[rowIndex].OpeningDate;
    // const OpeningdatePart = DateOpeningPart.split("T")[0];

    const moderowData = tableData[rowIndex];
    const modeInfo = bindModeDD.find((UM) => UM.value == moderowData.Mode);
    const ResultMode = [
      {
        value: moderowData.Mode,
        label: modeInfo ? modeInfo.label : "Unknown",
      },
    ];

    const dropdownDatas = [ResultCategories, filterUOM, ResultMode];
    setAutoCompleteDropDownPopupData(dropdownDatas);
    setTextFiledsPopupData(tableData[rowIndex]);
    console.log("textFiledsPopupData", textFiledsPopupData);
    console.log("autoCompleteDropDownPopupData", autoCompleteDropDownPopupData);
  };

  return (
    <MainLayout pageName={"2"}>
      {visibleTable && (
        <GridPage
          title={tableTitle}
          header={tableHeader}
          records={tableData ? tableData : []}
          handleEdit={handleEdit}
          handleAction={handleCommoditiesDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title={addTitle}
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <AutoDropDown
                      classStar="text-red-500"
                      title={commoditiesCategoriesLabel}
                      options={commoditiesCategories}
                      placeholder="Select"
                      Class="p-0"
                      name="categories"
                      selectedOption={commoditiesCategories.find(
                        (x) => x.value == commoditiesFileds.categories
                      )}
                      controlFunc={(value, e) => {
                        handleCommoditiesFileds(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setCommoditiesFieldsError({
                          ...commoditiesFieldsError,
                          categoriesError: commoditiesFileds.categories
                            ? ""
                            : `Select ${commoditiesCategoriesLabel}`,
                        });
                      }}
                      errorMessage={commoditiesFieldsError.categoriesError}
                    />

                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title={categoriesNameFiled}
                        placeholder={
                          location.pathname === "/contraceptive"
                            ? "Contraceptive Name"
                            : location.pathname === "/medicinesdrugs"
                            ? "Medicines Drugs Name"
                            : location.pathname === "/socialmarketing"
                            ? "Social Marketing Name"
                            : location.pathname === "/consumables"
                            ? "Consumables Name"
                            : ""
                        }
                        name="name"
                        maxLength={200}
                        content={commoditiesFileds.name}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            name: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError(
                            (commoditiesFieldsError) => ({
                              ...commoditiesFieldsError,
                              nameError:
                                commoditiesFileds.name.length > 0
                                  // ? /^[A-Za-z ]+$/.test(commoditiesFileds.name)
                                    ? ""
                                    // : "Only Alphabet and space allowed"
                                  : `Enter ${categoriesNameFiled}`,
                            })
                          );
                        }}
                        errorMessage={commoditiesFieldsError.nameError}
                      />
                    </div>
                    {visibleMode && (
                      <AutoDropDown
                        classStar="text-red-500"
                        title={"Route"}
                        options={bindModeDD}
                        placeholder="Select"
                        Class="p-0"
                        name="mode"
                        selectedOption={bindModeDD.find(
                          (x) => x.value == commoditiesFileds.mode
                        )}
                        controlFunc={(value, e) => {
                          handleCommoditiesFileds(value.value, e.name);
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError({
                            ...commoditiesFieldsError,
                            modeError: commoditiesFileds.mode
                              ? ""
                              : `Select Mode`,
                          });
                        }}
                        errorMessage={commoditiesFieldsError.modeError}
                      />
                    )}

                    <InputText
                      inputType="text"
                      title="Description"
                      placeholder="Description"
                      name="description"
                      maxLength={500}
                      content={commoditiesFileds.description}
                      controlFunc={(e) => {
                        setCommoditiesFileds({
                          ...commoditiesFileds,
                          description: e.target.value,
                        });
                      }}
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="Unit Of Measure"
                      options={bindUnitOfMeasure}
                      placeholder="Select"
                      Class="p-0"
                      name="mstCommodityUOMId"
                      selectedOption={bindUnitOfMeasure.find(
                        (x) => x.value == commoditiesFileds.mstCommodityUOMId
                      )}
                      controlFunc={(value, e) => {
                        handleCommoditiesFileds(value.value, e.name);
                      }}
                      controlFuncBlur={() => {
                        setCommoditiesFieldsError((commoditiesFieldsError) => ({
                          ...commoditiesFieldsError,
                          unitOfMeasureError:
                            commoditiesFileds.mstCommodityUOMId
                              ? ""
                              : `Select Unit Of Measure`,
                        }));
                      }}
                      errorMessage={commoditiesFieldsError.unitOfMeasureError}
                    />
                    {addTitle === "Contraceptive items" && (
                      <InputText
                        inputType="text"
                        title="Item Category"
                        placeholder="Item Category"
                        name="itemCategory"
                        maxLength={100}
                        content={commoditiesFileds.itemCategory}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            itemCategory: e.target.value,
                          });
                        }}
                      />
                    )}

                    <div className="col-span-2">
                      <div className="grid gap-4 md:grid-cols-3 sm:col-span-1">
                        <div>
                          {/* <InputText
                            classStar="text-red-500"
                            inputType="text"
                            title=" Opening Quantity"
                            placeholder="10"
                            name="openingQuantity"
                            maxLength={10}
                            content={commoditiesFileds.openingQuantity}
                            controlFunc={(e) => {
                              setCommoditiesFileds({
                                ...commoditiesFileds,
                                openingQuantity: e.target.value,
                              });
                            }}
                            controlFuncBlur={() => {
                              setCommoditiesFieldsError({
                                ...commoditiesFieldsError,
                                openingQuantityError:
                                  commoditiesFileds.openingQuantity
                                    ? /^\d+$/.test(
                                        commoditiesFileds.openingQuantity
                                      )
                                      ? ""
                                      : "Only numbers allowed"
                                    : "Enter Opening Quantity",
                              });
                            }}
                            errorMessage={
                              commoditiesFieldsError.openingQuantityError
                            }
                          /> */}
                        </div>
                        <div>
                          {/* <AutoDropDown
                            classStar="text-red-500"
                            title="Unit Of Measure"
                            options={bindUnitOfMeasure}
                            placeholder="Select"
                            Class="p-0"
                            name="unitOfMeasure"
                            selectedOption={bindUnitOfMeasure.find(
                              (x) => x.value == commoditiesFileds.unitOfMeasure
                            )}
                            controlFunc={(value, e) => {
                              handleCommoditiesFileds(value.value, e.name);
                            }}
                            controlFuncBlur={() => {
                              setCommoditiesFieldsError(
                                (commoditiesFieldsError) => ({
                                  ...commoditiesFieldsError,
                                  unitOfMeasureError:
                                    commoditiesFileds.unitOfMeasure
                                      ? ""
                                      : `Select Unit Of Measure`,
                                })
                              );
                            }}
                            errorMessage={
                              commoditiesFieldsError.unitOfMeasureError
                            }
                          /> */}
                        </div>
                        <div>
                          {/* <InputText
                            inputType="date"
                            title="Opening Date"
                            classStar="text-red-500"
                            name="openingDate"
                            content={commoditiesFileds.openingDate}
                            controlFunc={(e) => {
                              setCommoditiesFileds({
                                ...commoditiesFileds,
                                openingDate: e.target.value,
                              });
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <InputText
                        inputType="text"
                        title="Charges"
                        classStar="text-red-500"
                        placeholder="00000.00"
                        maxLength={10}
                        name="charges"
                        content={commoditiesFileds.charges}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            charges: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError(() => ({
                            ...commoditiesFieldsError,
                            chargesError: commoditiesFileds.charges
                              ? /^\d+(\.\d+)?$/.test(commoditiesFileds.charges)
                                ? ""
                                : "Only numbers and dot allowed"
                              : "Enter Charges",
                          }));
                        }}
                        errorMessage={commoditiesFieldsError.chargesError}
                      /> */}
                    </div>
                    <div className="col-span-3">
                      <div class={mainTable.labelBold}>Re-Order Level</div>
                      <hr class={mainTable.horizontalLine}></hr>
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title=" SDP Level"
                        placeholder="100"
                        name="sdpLevel"
                        maxLength={6}
                        content={commoditiesFileds.sdpLevel}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            sdpLevel: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError(() => ({
                            ...commoditiesFieldsError,
                            sdpLevelError: commoditiesFileds.sdpLevel
                              ? /^\d+$/.test(commoditiesFileds.sdpLevel)
                                ? ""
                                : "Only numbers allowed"
                              : "Enter SDP Level",
                          }));
                        }}
                        errorMessage={commoditiesFieldsError.sdpLevelError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title=" Outreach level"
                        placeholder="200"
                        name="outreachLevel"
                        maxLength={6}
                        content={commoditiesFileds.outreachLevel}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            outreachLevel: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError(() => ({
                            ...commoditiesFieldsError,
                            outreachLevelError: commoditiesFileds.outreachLevel
                              ? /^\d+$/.test(commoditiesFileds.outreachLevel)
                                ? ""
                                : "Only numbers allowed"
                              : "Enter Outreach level",
                          }));
                        }}
                        errorMessage={commoditiesFieldsError.outreachLevelError}
                      />
                    </div>
                    <div>
                      <InputText
                        classStar="text-red-500"
                        inputType="text"
                        title=" Branch level"
                        placeholder="300"
                        name="branchLevel"
                        maxLength={6}
                        content={commoditiesFileds.branchLevel}
                        controlFunc={(e) => {
                          setCommoditiesFileds({
                            ...commoditiesFileds,
                            branchLevel: e.target.value,
                          });
                        }}
                        controlFuncBlur={() => {
                          setCommoditiesFieldsError(() => ({
                            ...commoditiesFieldsError,
                            branchLevelError: commoditiesFileds.branchLevel
                              ? /^\d+$/.test(commoditiesFileds.branchLevel)
                                ? ""
                                : "Only numbers allowed"
                              : "Enter Branch level",
                          }));
                        }}
                        errorMessage={commoditiesFieldsError.branchLevelError}
                      />
                    </div>
                  </div>
                </form>
                <Buttons
                  addScreenTitle={addScreenTitle}
                  updateScreenTitle={updateScreenTitle}
                  handleSubmit={handleSubmit}
                  cancel={cancel}
                  disabledBtn={submitBtnDisabled ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
       {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleCommoditiesDetails}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title={tableTitle}
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <Commoditiespopup
            data={textFiledsPopupData}
            data2={autoCompleteDropDownPopupData}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default Commodities;
