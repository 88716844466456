import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";
import Select from "react-select";

const AutoDropDown = forwardRef((props, ref) => {
  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.ClassLabel ? props.ClassLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>
      <Select
        ref={ref}
        name={props.name}
        value={props.selectedOption}
        onChange={props.controlFunc}
        onBlur={props.controlFuncBlur}
        className={props.Class ? props.Class : mainTable.input}
        isDisabled={props.disabled}
        placeholder={props.placeholder}
        tabIndex={props.tabIndex}
        options={props.options}
      />
      {props.errorMessage && (
        <span className="text-red-500 text-sm font-medium">{props.errorMessage}</span>
      )}
    </div>
  );
});

AutoDropDown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  selectedOption: PropTypes.object, // Adjusting this to be an object as it's coming from find
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool, // Change to bool for better type checking
  tabIndex: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default AutoDropDown;


// import React from "react";
// import PropTypes from "prop-types";
// import { mainTable } from "../css/Common";
// import Select from "react-select";

// const AutoDropDown = (props) => (
//   <div className={props.classDiv ? props.classDiv : "form-group"}>
//     <label className={props.ClassLabel ? props.ClassLabel : mainTable.label}>
//       {props.title}
//     </label>
//     <Select
//       name={props.name}
//       value={props.value} // Use the value prop for the selected value
//       onChange={props.controlFunc}
//       className={props.Class ? props.Class : mainTable.input}
//       disabled={props.disabled}
//       tabIndex={props.tabIndex}
//       options={props.options}
//     >
//       <option value="">{props.placeholder}</option>
//       {props.options.map((opt) => (
//         <option key={opt.value} value={opt.value}>
//           {opt.label}
//         </option>
//       ))}
//     </Select>
//   </div>
// );

// AutoDropDown.propTypes = {
//   name: PropTypes.string.isRequired,
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   value: PropTypes.string, // New prop for setting the selected value
//   controlFunc: PropTypes.func.isRequired,
//   placeholder: PropTypes.string,
//   disabled: PropTypes.oneOf(["true", "false"]).isRequired,
//   tabIndex: PropTypes.string.isRequired,
// };

// export default AutoDropDown;
