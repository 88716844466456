// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";

const Referral = ({ data }) => {
  const [bindReferredBy, setBindReferredBy] = useState([
    { value: "1", label: "Pvt.Hospital" },
    { value: "2", label: "Govt.Hospital" },
    { value: "3", label: "Static Clinic" },
    { value: "4", label: "Satelite Clinic" },
  ]);
  return (
    <div class="mx-auto w-3/5 border-black">
      <div class="relative w-full h-full max-w-xl md:h-auto">
        {/* <h3 class="mb-4 text-xl font-medium text-gray-900 ">
          {`Country Master - Add`}
        </h3> */}
        <div class="relative bg-white rounded-lg shadow ">
          <div class="px-6 py-6 lg:px-8">
            <form class={mainTable.formForClientMaster}>
              <DropDown
                title="Referred To"
                options={bindReferredBy}
                placeholder="Select"
                name="referredBy"
                class="custom-class"
                tabIndex="1"
                //   selectedOptions={serviceCategory}
                //   controlFunc={(e) => setServiceCategory(e.target.value)}
              />
              {/* <InputText inputType="text" title="Country" name="country" /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
