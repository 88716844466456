// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import MainPopup from "../popup-containers/MainPopup";
import { servicesCategoryHeaders } from "../components/HeaderAttribute";
import ServiceCategory from "../popup-containers/ServiceCategory";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";
const ServicesCategoryMaster = () => {
  const location = useLocation();
  // All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // Services Category Master insert and edit useState hooks handling .
  const [servicesCategory, setServicesCategory] = useState("");
  const [mstServicesCategoryId, setMstServicesCategoryId] = useState("");
  const [categoryError, setCategoryError] = useState("");

  // All bind data value useState hooks handling.
  const [bindServicesCategory, setBindServicesCategory] = useState([]);
  const [serviceCategoryData, setServiceCategoryData] = useState("");

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [rowIndex, setRowIndex] = useState("");
  const [popUpOpen, setpopUpOpen] = useState(false);

  // Language translate useState hooks handling.
  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  // This function is helping for Language translation .
  useEffect(() => {
    const getLanguageCode = async () => {
      const response = await BindAPI("LanguageAPI/GetLanguage");
      setLanguageCodekey(response.data.Table);
    };
    getLanguageCode();
  }, []);

  useEffect(() => {
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  // This function is helped to the viwe, delete, block and unblock
  // input value via rowindex passing for Services Category .
  const handleServiceCategory = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setServiceCategoryData(bindServicesCategory[rowIndex]);
  };

  // This function is helped to edit the input value via rowindex passing and helping
  // to visible handling for Services Category.
  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setServicesCategory(bindServicesCategory[rowIndex].ServicesCategory_en);
    setMstServicesCategoryId(
      bindServicesCategory[rowIndex].MstServicesCategoryId
    );
  };

  // This is Services Category grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setServicesCategory("");
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setCategoryError("");
    servicesCategoryData();
    setSubmitBtnDisabled(false);
  };

  // This function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API
  // response for those all and passing the rowindex for Services Category.
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let servicesCategoryValidate = "";
      servicesCategoryValidate =
        servicesCategory.length > 0 ? "" : "Enter Category";
      setCategoryError(servicesCategoryValidate);
      // Validation end
      if (servicesCategoryValidate) {
        setIsOpen(true);
      }

      if (!servicesCategoryValidate) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(
            servicesCategory,
            i
          );
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const data = {
          mstServicesCategoryId: mstServicesCategoryId
            ? mstServicesCategoryId
            : 0,
          servicesCategoryEn: servicesCategory,
          servicesCategoryTa: translatedTexts[0],
          servicesCategoryHi: translatedTexts[1],
          servicesCategoryMr: translatedTexts[2],
          servicesCategoryGu: translatedTexts[3],
          servicesCategoryKn: translatedTexts[4],
          servicesCategoryMl: translatedTexts[5],
          servicesCategoryTe: translatedTexts[6],
          servicesCategoryBn: translatedTexts[7],
          servicesCategoryOr: translatedTexts[8],
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI(
          [data],
          "ServicesCategoryAPI/IUDServicesCategory"
        );
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("Services Category added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Services Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            // alert("Not Insert Services Category ");
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Services Category updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Services Category data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
            // alert("Not Update Services Category ");
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = {
        mstServicesCategoryId:
          bindServicesCategory[items].MstServicesCategoryId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI(
        [data],
        "ServicesCategoryAPI/IUDServicesCategory"
      );
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Services Category data deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Services Category data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not Delete Services Category ");
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Services Category data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Services Category data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not Block Services Category ");
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Services Category data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Services Category data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
          // alert("Not UnBlock Services Category ");
        }
      }
    }
  };

  // This hook function is helped to bind all data from Services Category master table.
  useEffect(() => {
    servicesCategoryData();
  }, []);

  const servicesCategoryData = async () => {
    const response = await BindAPI("ServicesCategoryAPI/BindServicesCategory");
    setBindServicesCategory(response.data.Table);
  };
  useEffect(() => {
    if (location.pathname == "/servicescategory") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      servicesCategoryData();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Services Category Master"
          header={servicesCategoryHeaders}
          records={bindServicesCategory}
          handleEdit={handleEdit}
          handleAction={handleServiceCategory}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <AddScreenTitle
            addScreenTitle={addScreenTitle}
            updateScreenTitle={updateScreenTitle}
            title="Services Category Master"
          />
          <div class={mainTable.firstMainAddScreen}>
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Category"}
                      name={"Category"}
                      content={servicesCategory}
                      controlFunc={(e) => {
                        // setServicesCategory(e.target.value)
                        const value = e.target.value;
                        setServicesCategory(value);
                        setCategoryError(
                          value.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(value)
                              ? ""  // No error if the input is valid
                              : ""
                            : "Enter Category"  // Error message if the input is empty
                        );
                      }}
                      controlFuncBlur={(e) => {
                        // setServicesCategory(e.target.value);
                        // setCategoryError(
                        //   servicesCategory.length > 0 ? "" : "Enter Category"
                        // );
                        const trimmedValue = e.target.value.trim();
                        setServicesCategory(trimmedValue);
                        setCategoryError(
                          trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter Category"  // Error message if the input is empty
                        );
                      }}
                    />
                    {categoryError && (
                      <span class="text-red-500">{categoryError}</span>
                    )}
                    <Buttons
                      addScreenTitle={addScreenTitle}
                      updateScreenTitle={updateScreenTitle}
                      handleSubmit={handleSubmit}
                      cancel={cancel}
                      disabledBtn={submitBtnDisabled ? true : false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleServiceCategory}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="Services Category Master"
            setpopUpOpen={handleClose}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <ServiceCategory data={serviceCategoryData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default ServicesCategoryMaster;
