// Code review - In Progress
// Documentation - In Progress

import InputText from "../ReusableComponents/InputText";
import { mainTable } from "../css/Common";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const StaffName = ({ data, data2 }) => {
  return (
    <div class="mx-auto flex  border-black">
      <div class="relative w-full h-full  md:h-auto">
        <div class="relative bg-white rounded-lg  ">
          <div class="px-6 py-6 lg:px-8">
            <form className={mainTable.grid3}>
              <AutoDropDown
                Class="Custom-class"
                title="State"
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                name="commodities"
                selectedOption={data2[0]}
                disabled={true}
              />

              <AutoDropDown
                Class="Custom-class"
                title="Branch/Project"
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                name="commodities"
                selectedOption={data2[1]}
                disabled={true}
              />

              <InputText
                inputType={"text"}
                title="Staff Name"
                name="StaffName"
                content={data.StaffName_en}
                classDiv={"w-full"}
                classStar="text-red-500"
                disabled={true}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffName;
