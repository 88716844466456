// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import DropDown from "../ReusableComponents/DropDown";
import { BindAPI } from "../services/APIConnection";

const InventoryReceiptsPopup = ({ data, data1, data2 }) => {
  const [productReceivedFrom, setProductReceivedFrom] = useState("");
  const [selectedReceivedFrom1, setSelectedReceivedFrom1] = useState(null);
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const from1Value = data.ReceiptFrom1;
  // setSelectedReceivedFrom1(from1Value)
  const [productReceivedFrom1, setProductReceivedFrom1] = useState("");
  const handleFromDropdownChange = (e) => {
    setSelectedReceivedFrom1(e.target.value);
    setProductReceivedFrom(e.target.value);
  };
  const getDataBasedOnReceivedFrom = () => {
    if (from1Value === "1") {
      setSelectedReceivedFrom1(null);
    } else if (from1Value == "2") {
      return bindLocation;
    } else if (from1Value == "3") {
      return bindSupplier;
    } else if (from1Value == "4") {
      return bindSDP;
    } else if (from1Value == "5") {
      return bindFieldWorkerName;
    } else if (from1Value == "6") {
      return bindPartnerOrganization;
    }
    // Default to an empty array or any other default data source.
    return [];
  };
  const headerData = [
    {
      title: "Category Type",
      key: "commoditiesCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Category",
      key: "commoditiesCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "commoditiesName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "commoditiesBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "commoditiesQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch No.",
      key: "BatchNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date Of Expiry",
      key: "DateOfExpiry",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Rate",
      key: "Rate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  const [tableData, setTableData] = useState([
    {
      receiptsCategoryType: "",
      receiptsCategory: "",
      receiptsName: "",
      receiptsBrand: "",
      receivedQuantity: "",
      unitOfMeasure: "",
      batchNo: "",
      dateOfExpiry: new Date(),
      rate: "",
      receiptsCategoryList: [],
      receiptsNameList: [],
      receiptsBrandList: [],
    },
  ]);
  const [commoditiesDetails, setCommoditiesDetails] = useState({});
  const [bindState, setBindStateDD] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);
  const [bindCommodityDD, setBindCommodityDD] = useState([]);
  const [bindCommodityNameDD, setBindCommodityNameDD] = useState([]);
  const [bindCommodityBrandDD, setBindCommodityBrandDD] = useState([]);
  const [bindReceivedFromDD, setBindReceivedFromDD] = useState([]);
  const [commoditiesDD, setCommoditiesDD] = useState([]);
  useEffect(() => {
    const indentDropDownDatas = async () => {
      try {
        const categoryResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        setBindCommoditiesCategory(categoryResponse.data.Table);
        const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
        setBindCommodities(nameResponse.data.Table);
        const brandResponse = await BindAPI("BrandAPI/BindBrand");
        setBindBrandDD(brandResponse.data.Table);
        const categoryTypeResponse = await BindAPI(
          "InventoryReceiptAPI/BindCommodityType"
        );
        const receivedFromResponse = await BindAPI(
          "InventoryReceiptAPI/BindInventoryReceivedFrom"
        );
        const categoryData = categoryResponse.data.Table.map((item) => ({
          value: item.MstCommodityCategoryId,
          label: item.CommodityCategory,
        }));

        const nameData = nameResponse.data.Table.map((item) => ({
          value: item.MstCommoditiesId,
          label: item.CommodityName_en,
        }));

        const brandData = brandResponse.data.Table.map((item) => ({
          value: item.MstBrandId,
          label: item.Brand_en,
        }));

        const categoryTypeData = categoryTypeResponse.data.Table.map(
          (item) => ({
            value: item.MstCommodityTypeId,
            label: item.CommodityType_en,
          })
        );
        const receivedFromData = receivedFromResponse.data.Table.map(
          (item) => ({
            value: item.TxnReceivedFromId,
            label: item.ReceivedFrom_en,
          })
        );

        setBindCommodityDD(categoryData);
        setBindCommodityNameDD(nameData);
        setBindCommodityBrandDD(brandData);
        setCommoditiesDD(categoryTypeData);
        setBindReceivedFromDD(receivedFromData);
      } catch (error) {}
    };

    indentDropDownDatas();
  }, []);

  useEffect(() => {
    // Iterate through all rows in tableData
    tableData.forEach((row) => {
      if (row?.receiptsCategoryType) {
        const getFilteredCategory = bindCommoditiesCategory
          .filter((item) => item.MstCommodityTypeId == row.receiptsCategoryType)
          .map((items) => ({
            value: items.MstCommodityCategoryId,
            label: items.CommodityCategory_en,
          }));
        row.receiptsCategoryList = getFilteredCategory;
        console.log("getFilteredCategory", row.receiptsCategoryList);
        setBindCommodityDD(getFilteredCategory);
      } else {
        setBindCommodityDD([]);
      }
      if (row?.receiptsCategory) {
        const selectedCommoditiesCategory = bindCommodities
          .filter((item) => item.MstCommodityCategoryId == row.receiptsCategory)
          .map((item) => ({
            value: item.MstCommoditiesId,
            // label: `${item.CommodityName_en} (${item.CommodityUOM})`,
            label: item.CommodityName_en,
          }));
        row.receiptsNameList = selectedCommoditiesCategory;
        setBindCommodityNameDD(selectedCommoditiesCategory);

        // Check if there's a selected commodity for the current row
        const selectedCommodity = selectedCommoditiesCategory.find(
          (item) => item.value === row.receiptsName
        );

        // If a selected commodity is found, set the unitOfMeasure for the row
      } else {
        setBindCommodityNameDD([]);
      }

      if (row?.receiptsName) {
        const selectedBrandCategory = bindBrandDD
          .filter((item) => item.MstCommoditiesId == row.receiptsName)
          .map((items) => ({
            value: items.MstBrandId,
            label: items.Brand_en,
          }));
        row.receiptsBrandList = selectedBrandCategory;
        setBindCommodityBrandDD(selectedBrandCategory);
      } else {
        setBindCommodityBrandDD([]);
      }
    });
  }, [tableData]);
  const [bindSupplier, setBindSupplier] = useState([]);
  const [bindFieldWorkerName, setBindFieldWorkerName] = useState([]);
  const [bindPartnerOrganization, setBindPartnerOrganization] = useState([]);

  const [bindSDP, setSdp1] = useState([]);
  const [bindLocation, setLocation1] = useState([]);
  const [bindBranchDD, setBindBranchDD] = useState([]);

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.receiptsCategoryType = "";
    newRow.receiptsCategory = ""; // Clear the values of the new row
    newRow.receiptsName = "";
    newRow.receiptsBrand = "";
    newRow.receivedQuantity = "";
    newRow.unitOfMeasure = "";
    newRow.batchNo = "";
    newRow.dateOfExpiry = "";
    newRow.rate = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };
  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setCommoditiesDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    // let value = "";
    // if (columnName == "receiptsCategoryType") {
    //   value = selectedOption
    //     ? selectedOption.label
    //     : selectedOption.value || "";
    // } else {
    //   value = selectedOption
    //     ? selectedOption.value
    //     : selectedOption.label || "";
    // }
    // setTableData((prevData) => {
    //   const newData = [...prevData];
    //   newData[rowIndex][columnName] = value;

    //   return newData;
    // });
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };
  useEffect(() => {
    const ParticipantDatas = JSON.stringify(data2);
    if (data2.length === 0) {
      setTableData([
        {
          receiptsCommodities: "",
          receiptsCategory: "",
          receiptsName: "",
          receiptsBrand: "",
          receivedQuantity: "",
          unitOfMeasure: "",
          batchNo: "",
          dateOfExpiry: new Date(),
          rate: "",
          receiptsCategoryList: [],
          receiptsNameList: [],
          receiptsBrandList: [],
        },
      ]);
    } else {
      const clearValues = () => {
        // Assuming Clearvalues is a function you want to call
        // If it's not, you can remove this function call
        setTableData([]);
      };

      clearValues();

      if (ParticipantDatas && typeof ParticipantDatas === "string") {
        try {
          const newDataArray = JSON.parse(ParticipantDatas);
          const formattedDataArray = newDataArray.map((data) => {
            const formattedDateOfExpiry = data.ReceiptDateOfExpiry;
            const formattedDate1 = formattedDateOfExpiry.split("T")[0];

            return {
              receiptsCategoryType: data.MstCommoditiesId,
              receiptsCategory: data.MstCategoryId,
              receiptsName: data.MstNameId,
              receiptsBrand: data.MstBrandId,
              receivedQuantity: data.ReceiptQuantity,
              unitOfMeasure: data.ReceiptUOM,
              batchNo: data.ReceiptBatchNo,
              dateOfExpiry: formattedDate1,
              rate: data.ReceiptRate,
            };
          });

          setTableData((prevData) => [...prevData, ...formattedDataArray]);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle the error, e.g., show a message to the user or log it
        }
      } else {
        // Handle the case when data2 is not a string or is empty
      }
    }
  }, [data2]);

  return (
    <div class="w-full ">
      <div class=" border-black  ">
        <div className={mainTable.addShadowScreen}>
          <div className={mainTable.mainContentScreen}>
            <form class={mainTable.formPopupcontainer}>
              <div className={mainTable.grid5}>
                <InputText
                  inputType="text"
                  title="Receipt No."
                  name="receiptNo"
                  classStar="text-red-500"
                  content={data.ReceiptNo}
                  placeholder="100"
                  disabled="disabled"
                />
                <InputText
                  inputType="date"
                  title="Receipt Date"
                  name="date"
                  classStar="text-red-500"
                  content={data1[2]}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Branch"
                  name="branch"
                  content={data.LocationName_en}
                  placeholder="100"
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="S.D.P."
                  name="sdp"
                  content={data.SDP_en ?? ""}
                  placeholder=""
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Area"
                  name="area"
                  content={data.AreaName_en ?? ""}
                  placeholder=""
                  disabled="disabled"
                />
              </div>
              <div className={mainTable.gridInventory3}>
                <AutoDropDown
                  title="Outreach staff"
                  options={[]}
                  disabled={true}
                  name="mstOrwId"
                  placeholder="Select"
                  Class="custom-class"
                  selectedOption={data1[5]}
                />
                <DropDown
                  title="Received From"
                  classStar="text-red-500"
                  options={bindReceivedFromDD}
                  placeholder="Select"
                  name="productReceivedFrom"
                  selectedOption={data.ReceiptFrom1}
                  controlFunc={handleFromDropdownChange}
                  disabled="disabled"
                />
                {from1Value && from1Value !== 1 && from1Value !== 7 && (
                  <AutoDropDown
                    Class="p-0"
                    classStar="text-red-500"
                    title={
                      from1Value == "2"
                        ? "Received Branch/Project"
                        : from1Value == "3"
                        ? "Received Supplier"
                        : from1Value == "4"
                        ? "Received S.D.P"
                        : from1Value == "5"
                        ? "Received Outreach Staff Name"
                        : from1Value == "6"
                        ? "Received Partner Organization"
                        : "Add New Field"
                    }
                    options={getDataBasedOnReceivedFrom()}
                    name="branch"
                    placeholder="Select"
                    selectedOption={data1[3]}
                    controlFunc={(value) => setProductReceivedFrom1(value)}
                    disabled="disabled"
                  />
                )}
                {from1Value == "4" && (
                  <AutoDropDown
                    classStar="text-red-500"
                    title="Area Name"
                    options={[]}
                    name="area"
                    placeholder="Select"
                    Class="custom-class"
                    selectedOption={data1[4]}
                    disabled="disabled"
                  />
                )}
                {from1Value && from1Value == data.ReceiptFrom1 && (
                  <InputText
                    inputType="text"
                    classStar="text-red-500"
                    title="Received By"
                    name="receivedBy"
                    placeholder="Received By"
                    disabled="disabled"
                    content={data.ReceiptReceivedBy_en}
                  />
                )}
              </div>
              {/* <div className={mainTable.grid3}>
              
              </div> */}
              <div className="hidden md:block overflow-x-auto ">
                <div className="table-container md:w-max overflow-x-auto max-h-400 w-screen">
                  <table className="w-screen border border-gray-300 mt-6">
                    <thead>
                      <tr>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-40">
                          Commodities
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Category
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Name
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Brand
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Qty.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          U.O.M.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Batch No.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Date Of Expiry
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={commoditiesDD}
                              placeholder="Select"
                              name="Category"
                              disabled="disabled"
                              Class="p-0"
                              selectedOption={commoditiesDD.find(
                                (x) => x.value == row.receiptsCategoryType
                              )} // Use the entire object
                              controlFunc={(e) => {
                                handleInputChange(
                                  e,
                                  index,
                                  "receiptsCategoryType"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={row.receiptsCategoryList || []}
                              placeholder="Select"
                              name="Category"
                              Class="p-0"
                              disabled="disabled"
                              selectedOption={
                                row.receiptsCategoryList
                                  ? row.receiptsCategoryList.find(
                                      (x) => x.value === row.receiptsCategory
                                    )
                                  : null
                              } // Use the entire object
                              controlFunc={(e) => {
                                handleInputChange(e, index, "receiptsCategory");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={row.receiptsNameList || []}
                              name="name"
                              disabled="disabled"
                              placeholder="Select"
                              selectedOption={
                                row.receiptsNameList
                                  ? row.receiptsNameList.find(
                                      (x) => x.value === row.receiptsName
                                    )
                                  : null
                              }
                              Class="p-0"
                              controlFunc={(e) => {
                                handleInputChange(e, index, "receiptsName");
                              }}
                            />
                            {/* <DropDown
                              options={bindCommodityNameDD}
                              placeholder="Select"
                              name="receiptsName"
                              selectedOption={row.receiptsName}
                              disabled="disabled"
                              controlFunc={(e) =>
                                handleInputChange(
                                  e.target,
                                  index,
                                  "receiptsName"
                                )
                              }
                            /> */}
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={row.receiptsBrandList || []}
                              name="brand"
                              disabled="disabled"
                              placeholder="Select"
                              selectedOption={
                                row.receiptsBrandList
                                  ? row.receiptsBrandList.find(
                                      (x) => x.value === row.receiptsBrand
                                    )
                                  : null
                              }
                              Class="p-0"
                              controlFunc={(e) => {
                                handleInputChange(e, index, "receiptsBrand");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              content={row.receivedQuantity}
                              disabled="disabled"
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "receivedQuantity"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              content={row.unitOfMeasure}
                              disabled="disabled"
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "unitOfMeasure"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.batchNo}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "batchNo");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              inputType="date"
                              disabled="disabled"
                              content={row.dateOfExpiry}
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "dateOfExpiry"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.rate}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "rate");
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={mainTable.tableMobile} role="table">
                <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                  {tableData.length > 0 &&
                    tableData.map((row, index) => (
                      <div scope="col" class={mainTable.mobileTable}>
                        {headerData.map((keyData) => (
                          <div
                            scope="cell"
                            class={mainTable.tableData}
                            role="cell"
                          >
                            {keyData.key === "Action" ? (
                              <div class={mainTable.tableData}>
                                <div className="md:block hidden">
                                  <button
                                    id="dropdownMenuIconButton"
                                    data-dropdown-toggle="dropdownDots"
                                    class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                    type="button"
                                  >
                                    <svg
                                      class="w-6 h-6"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : keyData.key === "commoditiesCategoryType" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={commoditiesDD}
                                  placeholder="Select"
                                  name="Category"
                                  Class="p-0"
                                  disabled="disabled"
                                  selectedOption={commoditiesDD.find(
                                    (x) => x.value == row.receiptsCategoryType
                                  )} // Use the entire object
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsCategoryType"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesCategory" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={row.receiptsCategoryList || []}
                                  placeholder="Select"
                                  name="Category"
                                  disabled="disabled"
                                  Class="p-0"
                                  selectedOption={
                                    row.receiptsCategoryList
                                      ? row.receiptsCategoryList.find(
                                          (x) =>
                                            x.value === row.receiptsCategory
                                        )
                                      : null
                                  } // Use the entire object
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsCategory"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesName" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={row.receiptsNameList || []}
                                  name="name"
                                  placeholder="Select"
                                  disabled="disabled"
                                  selectedOption={
                                    row.receiptsNameList
                                      ? row.receiptsNameList.find(
                                          (x) => x.value === row.receiptsName
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "receiptsName");
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesBrand" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={row.receiptsBrandList || []}
                                  name="brand"
                                  disabled="disabled"
                                  placeholder="Select"
                                  selectedOption={
                                    row.receiptsBrandList
                                      ? row.receiptsBrandList.find(
                                          (x) => x.value === row.receiptsBrand
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e,
                                      index,
                                      "receiptsBrand"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesQty" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled="disabled"
                                  content={row.receivedQuantity}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "receivedQuantity"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesUOM" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled="disabled"
                                  content={row.unitOfMeasure}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "unitOfMeasure"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "BatchNumber" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled="disabled"
                                  content={row.batchNo}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "batchNo"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "DateOfExpiry" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  inputType="date"
                                  disabled="disabled"
                                  content={row.dateOfExpiry}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "dateOfExpiry"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "Rate" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  disabled="disabled"
                                  content={row.rate}
                                  controlFunc={(e) => {
                                    handleInputChange(e.target, index, "rate");
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="flex border p-1">
                                <div className="flex basis-1/2 font-bold">
                                  {keyData.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              <div class="lg:col-span-3 sm:col-span-1">
                <TextArea
                  inputType="text"
                  title="Remarks"
                  name="remarks"
                  rows="4"
                  content={data.ReceiptRemarks_en}
                  disabled="disabled"
                  placeholder="Remarks"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryReceiptsPopup;
