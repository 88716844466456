// Code review - In Progress
// Documentation - In Progress
import axios from "axios";
const studentMasterInsertAPI = async (data) => {      // For Insert, Update, Delete, Block, Unblock functionality
  
  const response = await axios.post(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/IUDStudent`,
    // `http://localhost:5154/FPAIOutReachAPI/IUDStudent`,
    data
  );
  return response;
};
const studentMasterBindAPI = async () => {      // For binding data in Grid
  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindStudent`,
    // `http://localhost:5154/FPAIOutReachAPI/BindStudent`
  );
  return response;
};

const schoolBindAPI = async () => {       // For binding School in School / Youth master
  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindSchool`,
    // `http://localhost:5154/FPAIOutReachAPI/BindSchool`
  );
  return response;
};

const genderBindAPI = async () => {            // For binding Gender 
  const response = await axios.get(
    // `http://localhost:5154/FPAIOutReachAPI/BindGender`
    `http://localhost:5154/MasterDataAPI/BindGender`
  );
  return response;
};

export {
  studentMasterInsertAPI,
  studentMasterBindAPI,
  schoolBindAPI,
  genderBindAPI,
};
