// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import { roleMasterHeader } from "../components/HeaderAttribute";
import MainPopup from "../popup-containers/MainPopup";
import TableGrid from "../components/Table";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import Role from "../popup-containers/RolePopup";
import toast, { Toaster } from "react-hot-toast";
import { BindAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const RoleMaster = () => {
  const location = useLocation();
  //All visible functionality useState hooks handling.
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  // Role Master insert and edit useState hooks handling .
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState("");
  const [mstRoleId, setMstRoleId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [roleData, setRoleData] = useState("");
  const [bindRoleMasterDatas, setbindRoleMasterDatas] = useState([]);

  // Rowindex, Popup, Pagination, next page and other some useState hooks handling.
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [editMode, setEditMode] = useState([]);

  // const [currentPage, setCurrentPage] = useState(1); // Set the initial items per page

  const [itemsPerPage, setItemsPerPage] = useState(50);
  // const handleDropDown = (event) => {
  //   // To set Number of records per page
  //   const selectedValue = parseInt(event.target.value); // Convert to integer
  //   setItemsPerPage(selectedValue);
  //   setCurrentPage(1); // Reset to first page when items per page changes
  // };

  const [isOpen, setIsOpen] = useState(false);

  // This function is helping for Popup open .
  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  // This function is helping for Popup close .
  const handleClose = () => {
    setpopUpOpen(false);
  };

  //search state start
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindRoleMasterDatas);
  //search state end

  //Value store States
  const [roleFields, setRoleFields] = useState({
    roleGroups: "",
    description: "",
  });

  const [roleFieldsError, setRoleFieldsError] = useState({
    roleGroupsError: "",
    descriptionError: "",
  });
  //search functionality start

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  const handleSearch = (input) => {
    const filtered = bindRoleMasterDatas.filter((item) => {
      const RoleData = `${item.RoleName}${item.RoleDiscription}`;
      return RoleData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };

  //search functionality end

  // This is grid page add button visible handling functionality .
  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(false);
    setAddScreenTitle(true);
    setVisibleUpdate(false);
  };

  // This is ovrall cancel button visible handling functionality .
  const cancel = async () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    handleOpen(false);
    setSubmitBtnDisabled(false);
    setRoleFields({
      roleGroups: "",
      description: "",
    });

    setRoleFieldsError({
      roleGroupsError: "",
      descriptionError: "",
    });
    // setRoleError("");
    // // RoleDatas();
    // // setEditMode(false);
    // setRole("");
    // setRemarks("");
    RoleDatas();
  };

  //This is Role Master grid data bind functionality.
  useEffect(() => {
    RoleDatas();
  }, []);

  const RoleDatas = async () => {
    const response = await BindAPI("RoleAPI/BindRole");
    setbindRoleMasterDatas(response.data.Table);
  };

  // this function is helped to the viwe, delete, block and unblock input value via rowindex passing.
  const handleRole = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setRoleData(bindRoleMasterDatas[rowIndex]);
  };

  // this function is helped to edit the input value via rowindex passing and helping to visible handling.
  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setVisibleUpdate(true);
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setRoleFields({
      roleGroups: bindRoleMasterDatas[rowIndex].RoleName,
      description: bindRoleMasterDatas[rowIndex].RoleDiscription,
    });
    setMstRoleId(bindRoleMasterDatas[rowIndex].MstRoleId);
    // setRole(bindRoleMasterDatas[rowIndex].RoleName);
    // setRemarks(bindRoleMasterDatas[rowIndex].RoleDiscription);
  };

  const validate = (flag) => {
    const errors = {};
    if (flag === 1 || flag === 2) {
      if (!roleFields.roleGroups.trim()) {
        errors.roleGroups = "Enter Role / Groups";
      } else if (!/^[A-Za-z ]+$/.test(roleFields.roleGroups)) {
        errors.roleGroups = "Only alphabet and space allowed";
      }
    }
    setRoleFieldsError({
      ...roleFieldsError,
      roleGroupsError: errors.roleGroups || "",
    });
    // setLanguageError(errors.language || "");
    // setLanguageCodeError(errors.languageCode || "");
    return errors;
  };
  // this function is helped to the edit, viwe, delete, block and unblock flag passing and hanling the API response for those all and passing the rowindex.
  // const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    const validation = validate(flag);
    if (Object.keys(validation).length !== 0) {
      setIsOpen(true);
    }
    if (Object.keys(validation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1 || flag == 2) {
        const data = {
          mstRoleId: mstRoleId ? mstRoleId : 0,
          roleName: roleFields.roleGroups,
          roleDiscription: roleFields.description,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "RoleAPI/IUDRole");
        if (flag == 1) {
          if (response.data.Table[0].Result === "S") {
            toast.success("New role added successfully! 👍", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
            RoleDatas();
          } else {
            toast.error("Role creation failed 👎", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
          }
        } else if (flag == 2) {
          if (response.data.Table[0].Result === "M") {
            toast.success("Role modified successfully! 👍", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
            RoleDatas();
          } else {
            toast.error("Role modification failed 👎", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
          }
        }
      } else {
        const data = {
          mstRoleId: bindRoleMasterDatas[items].MstRoleId,
          createdBy: createdBy,
          flag: flag,
        };
        const response = await IUDAPI([data], "RoleAPI/IUDRole");
        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success("Role deleted successfully! 👍", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
            RoleDatas();
          } else {
            toast.error("Role deletion failed 👎", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success("Role blocked successfully! 👍", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
            RoleDatas();
          } else {
            toast.error("Role blocking failed 👎", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success("Role activated successfully! 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
            RoleDatas();
          } else {
            toast.error("Role activated failed! 👎", {
              duration: 3000,
              position: "top-center",
            });
            setTimeout(cancel, 3000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  useEffect(() => {
    if (location.pathname == "/rolemaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      RoleDatas();
    }
  }, [location]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title="Role / Groups Master"
          header={roleMasterHeader}
          records={bindRoleMasterDatas}
          // currentPage={currentPage}
          // itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleAction={handleRole}
          showAdd={showAdd}
          blockView={true}
        />
        // <div className="">
        //   <div className={mainTable.headerTitle}>
        //     <div className={mainTable.gridTableHeader}>{`Role / Group `}</div>
        //   </div>
        //   <div className=" md:hidden relative mt-1">
        //     <div className={mainTable.searchIconPosition}>
        //       <svg
        //         className={mainTable.searchIconSvg}
        //         aria-hidden="true"
        //         fill="currentColor"
        //         viewBox="0 0 20 20"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           fill-rule="evenodd"
        //           d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //           clip-rule="evenodd"
        //         ></path>
        //       </svg>
        //     </div>
        //     <input
        //       type="text"
        //       className={mainTable.searchInputBox}
        //       placeholder="Search for items"
        //       value={searchInput}
        //       onChange={handleSearchInputChange}
        //     />
        //   </div>
        //   <div className="justify-between flex items-center">
        //     <div className={mainTable.searchAdd}>
        //       <div className="mt-1 gap-2 flex justify-between items-baseline">
        //         <label for="pageSize" className="hidden md:block">
        //           Rows Per Page:
        //         </label>
        //         <select
        //           onChange={handleDropDown}
        //           className={mainTable.dropDownRecords}
        //           name="pageSize"
        //         >
        //           <option value="2">2</option>
        //           <option value="5">
        //             5
        //           </option>
        //           <option value="10" >10</option>
        //           <option value="20">20</option>
        //           <option value="50" selected>50</option>
        //           <option value="100">100</option>
        //         </select>
        //       </div>
        //       <div>
        //         <label for="table-search" className="sr-only">
        //           Search
        //         </label>
        //       </div>
        //       <div className="hidden md:block relative mt-1">
        //         <div className={mainTable.searchIconPosition}>
        //           <svg
        //             className={mainTable.searchIconSvg}
        //             aria-hidden="true"
        //             fill="currentColor"
        //             viewBox="0 0 20 20"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               fill-rule="evenodd"
        //               d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        //               clip-rule="evenodd"
        //             ></path>
        //           </svg>
        //         </div>
        //         <input
        //           type="text"
        //           className={mainTable.searchInputBox}
        //           placeholder="Search for items"
        //           value={searchInput}
        //           onChange={handleSearchInputChange}
        //         />
        //       </div>
        //     </div>
        //     <div className="flex self-end">
        //       <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
        //         <span className="mb-12 text-red-600 text-7xl">.</span>
        //         <span>Block</span>
        //       </div>
        //       <button
        //         type="button"
        //         className={mainTable.addButton}
        //         onClick={showAdd}
        //       >
        //         <svg
        //           width="25"
        //           height="20"
        //           viewBox="0 0 50 50"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
        //             fill="white"
        //           />
        //         </svg>
        //         ADD
        //       </button>
        //     </div>
        //   </div>
        //   <TableGrid
        //     className={mainTable.table}
        //     headerData={roleMasterHeader}
        //     tableData={searchData.length > 0 ? searchData : bindRoleMasterDatas}
        //     onEditGrid={handleEdit}
        //     onDeleteGrid={handleRole}
        //     onBlockGrid={handleRole}
        //     onViewGrid={handleRole}
        //     currentPage={currentPage}
        //     itemsPerPage={itemsPerPage}
        //   />
        //   <div className={mainTable.submitCancelMainDiv}>
        //     <button
        //       onClick={() => setCurrentPage(currentPage - 1)}
        //       disabled={currentPage === 1}
        //       className={mainTable.submitButton}
        //     >
        //       Previous Page
        //     </button>
        //     <label>{currentPage}</label>
        //     <button
        //       onClick={() => setCurrentPage(currentPage + 1)}
        //       disabled={
        //         currentPage === bindRoleMasterDatas.length / itemsPerPage
        //       }
        //       className={mainTable.cancelButton}
        //     >
        //       Next Page
        //     </button>
        //   </div>
        // </div>
      )}
      {visibleAdd && (
        <div class="flex flex-col w-full">
          <div class={mainTable.mainAddScreen}>
            {/*    {addScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Role / Group  - Add"}
                </div>
              )}

              {updateScreenTitle && (
                <div class={mainTable.addScreenTitle}>
                  {"Role / Group  - Edit"}
                </div>
              )}*/}
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Role / Groups Master"
            />
          </div>

          <div class="mx-auto  md:w-2/5 w-full">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Role / Groups"}
                      name={"rolegroups"}
                      content={roleFields.roleGroups}
                      controlFunc={(e) =>
                        setRoleFields({
                          ...roleFields,
                          roleGroups: e.target.value,
                        })
                      }
                      controlFuncBlur={(e) => {
                        setRoleFieldsError((roleFieldsError) => ({
                          ...roleFieldsError,
                          roleGroupsError:
                            roleFields.roleGroups &&
                            roleFields.roleGroups.length > 0
                              ? // ? /^[A-Za-z ]+$/.test(roleFields.roleGroups)
                                !visibleUpdate &&
                                bindRoleMasterDatas.find(
                                  (x) =>
                                    x.RoleName.toLowerCase() ==
                                    roleFields.roleGroups
                                )
                                ? "Role / Group code already available"
                                : ""
                              : // : "Only Alphabet and space allowed"
                                "Enter Role / Groups",
                        }));
                      }}
                      errorMessage={roleFieldsError.roleGroupsError}
                    />

                    <TextArea
                      inputType="text"
                      title="Description"
                      name="description"
                      rows="4"
                      content={roleFields.description}
                      controlFunc={(e) =>
                        setRoleFields({
                          ...roleFields,
                          description: e.target.value,
                        })
                      }
                    />
                    {/* <div class={mainTable.submitCancelMainDiv}>
                      {addScreenTitle && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(1, 1)}
                        >
                          SUBMIT
                        </button>
                      )}
                      {updateScreenTitle && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(0, 2)}
                        >
                          Update
                        </button>
                      )}
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        onClick={cancel}
                        class={mainTable.cancelButton}
                      >
                        CANCEL
                      </button>
                    </div> */}
                    <Buttons
                      addScreenTitle={addScreenTitle}
                      updateScreenTitle={updateScreenTitle}
                      handleSubmit={handleSubmit}
                      cancel={cancel}
                      disabledBtn={submitBtnDisabled ? true : false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleRole}
      >
        <div>
          <PopUpScreenTitle
            title="Role / Groups Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          {/* <div class="text-black text-2xl font-bold mx-4 flex">
            <h3 className="my-1 max-lg:mt-4 mx-2 lg:mx-0 whitespace-nowrap text-xl font-medium text-neutral-700 ">
              {viewDetails && `Role / Group - View`}
              {deleteDetails && `Role / Group - Delete`}
              {blockDetails && `Role / Group - Block`}
              {unBlockDetails && `Role / Group - Unblock`}
            </h3>
            <button class="w-full">
              <span
                className="text-white flex justify-end"
                onClick={handleClose}
                class="text-2xl p-0 m-o block float-right mr-3"
              >
                &times;
              </span>
            </button>
          </div> */}
          <Role data={roleData} />
        </div>
        {/* <div class={mainTable.submitCancelMainDiv}>
          {deleteDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}

          {blockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockDetails && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              class={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}

          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            class={mainTable.cancelButton}
            onClick={cancel}
          >
            CANCEL
          </button>
        </div> */}
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default RoleMaster;
