import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const TestDropDown = () => {
  const location = useLocation();
  const [selectedData, setSelectedData] = useState(null);
  const [dropdownValue, setDropdownValue] = useState("");

  useEffect(() => {
    const dataFromProps = location.state?.selectedData;

    if (dataFromProps) {
      setSelectedData(dataFromProps);

      // Set the dropdown value based on the selected data
      setDropdownValue(dataFromProps.dropdownValue); // Replace 'dropdownValue' with the actual property name in your data
    } else {
      // Handle the case when there's no selected data
    }
  }, [location.state]);

  const handleDropdownChange = (e) => {
    // Update the dropdown value in the state when it changes
    setDropdownValue(e.target.value);
  };
  // Dropdown component
  const Dropdown = ({ value, onChange, options }) => (
    <select value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

  return (
    <div>
        <div>Hello</div>
      {selectedData && (
        <form>
          <label>Edit Data</label>
          {/* Use the dropdown component with the updated value */}
          <Dropdown
            value={dropdownValue}
            class='border'
            onChange={handleDropdownChange}
            options={[
              { value: "option1", label: "Option 1" },
              { value: "option2", label: "Option 2" },
              // Add other options as needed
            ]}
          />
          {/* Other form fields */}
        </form>
      )}
    </div>
  );
};

export default TestDropDown;
