// Code review - In Progress
// Documentation - In Progress
import InputText from "../ReusableComponents/InputText";
import { mainTable } from "../css/Common";
import React from "react";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const AreaPopupmaster = ({ data, data1 }) => {
  return (
    <div class="w-full ">
      {/* <div class=" border-black  ">
        <div class="text-black text-2xl font-bold mx-4">
          <h3 className={mainTable.addScreenTitle}>{`Area Master - View`}</h3>
        </div>
      </div> */}
      <div className={mainTable.addShadowScreen}>
        <div className={mainTable.mainContentScreen}>
          <form className="overflow-auto p-5 min-h-fit max-h-fit">
            <div className={mainTable.grid2}>
              <AutoDropDown
                classDiv="hidden"
                title="Country"
                options={[]}
                placeholder="Select"
                name="country"
                disabled="false"
                tabIndex="1"
              />
              <AutoDropDown
                title="State"
                options={[]}
                placeholder="Select"
                classStar="text-red-500"
                name="state"
                selectedOption={data1[0]}
                disabled="false"
                tabIndex="1"
                Class="custom-class"
              />
              <AutoDropDown
                title={"Branch/Project"}
                options={[]}
                placeholder={"Select"}
                name={"location"}
                selectedOption={data1[1]}
                disabled="false"
                classStar="text-red-500"
                tabIndex="1"
                Class="custom-class"
              />
              <AutoDropDown
                title={"S.D.P."}
                options={[]}
                placeholder={"Select"}
                name={"sdp"}
                selectedOption={data1[2]}
                classStar="text-red-500"
                disabled="false"
                tabIndex="1"
                Class="custom-class"
              />
              <InputText
                inputType={"text"}
                classStar="text-red-500"
                title={"Area"}
                name={"area"}
                content={data.AreaName_en}
              />
              <InputText
                inputType={"text"}
                classStar="text-red-500"
                title={"Area Code"}
                name={"area"}
                content={data.AreaCode}
              />
              <InputText
                // classStar="text-red-500"
                inputType={"text"}
                title={"Email Id"}
                name={"area"}
                content={data.AreaEmail}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AreaPopupmaster;
