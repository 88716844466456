import React from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const TextArea = (props) => (
  <div className="form-group">
    <label className={props.classLabel ? props.classLabel : mainTable.label}>
      {props.title}
      <span className={props.classStar ? props.classStar : "hidden"}>*</span>
    </label>
    <textarea
      className={props.class ? props.class : mainTable.input}
      style={props.resize ? null : { resize: "none" }}
      name={props.name}
      rows={props.rows}
      value={props.content}
      onChange={props.controlFunc}
      onBlur={props.controlFuncBlur}
      errorMessage={props.errorMessage}
      placeholder={props.placeholder}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      maxLength={props.maxLength}
    />
    {props.errorMessage && (
      <span class="text-red-500">{props.errorMessage}</span>
    )}
  </div>
);

TextArea.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  resize: PropTypes.bool,
  placeholder: PropTypes.string,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  disabled: PropTypes.oneOf(["true", "false"]).isRequired,
  tabIndex: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default TextArea;
