import React, { useState, useEffect } from "react";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import {
  ReferralFollowUpNotificationsHeader,
  contraceptiveRSFHeader,
} from "../components/HeaderAttribute";
import toast, { Toaster } from "react-hot-toast";
import GridPage from "../components/GridPage";
import { useUserCapability } from "../components/ConstantReducerProvider";

const Notifications = ({ closePopup }) => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  // Contraceptive Renewal Supply Start
  const [bindContraceptiveRenewalData, setBindContraceptiveRenewalData] =
    useState([]);

  const [bindContraceptiveRemarks, setBindContraceptiveRemarks] = useState([]);
  const [
    bindContraceptiveRemarksDiscontinued,
    setBindContraceptiveRemarksDiscontinued,
  ] = useState([]);

  const [contraceptiveNotificationId, setContraceptiveNotificationId] =
    useState("");
  const [contraceptiveDate, setContraceptiveDate] = useState("");
  const [contraceptiveServiceDate, setContraceptiveServiceDate] = useState("");
  const [contraceptiveClientId, setContraceptiveClientId] = useState("");
  const [fpMethod, setFPMethod] = useState("");
  const [serviceId, setServiceId] = useState("");

  const [contraceptiveRemarks, setContraceptiveRemarks] = useState("");
  const [
    contraceptiveRemarksDiscontinued,
    setContraceptiveRemarksDiscontinued,
  ] = useState("");
  const [contraceptiveNextFollowUpDate, setContraceptiveNextFollowUpDate] =
    useState("");

  // Contraceptive Renewal Supply End

  // Referral Follow Up Start

  const [bindReferralFollowUpData, setBindReferralFollowUpData] = useState([]);
  const [bindReferralFollowUpRemarks, setBindReferralFollowUpRemarks] =
    useState([]);

  const [txnServicesId, setTxnServicesId] = useState("");
  const [referralDate, setReferralDate] = useState("");
  const [referralClientId, setReferralClientId] = useState("");
  const [referralService, setReferralService] = useState("");
  const [referralRemarks, setReferralRemarks] = useState("");

  // Referral Follow Up Start

  useEffect(() => {
    notificationData(userProfileDatas);
  }, []);

  const notificationData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      const data = {
        mstOrganizationStructureId: userProfileDatas[0]?.MstOrganizationId,
        mstBranchId: userProfileDatas[0]?.MstLocationId,
        mstSdpId: userProfileDatas[0]?.MstSDPId
          ? userProfileDatas[0]?.MstSDPId
          : 0,
        mstUserId: userProfileDatas[0]?.MstUserId,
        mstRoleId: userProfileDatas[0]?.MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "NotificationAPI/BindReferralFollowUpNotification"
      );

      const contraceptiveRenewalresponse = await BindPostAPI(
        [data],
        "NotificationAPI/BindContraceptiveNotification"
      );

      setBindContraceptiveRenewalData(contraceptiveRenewalresponse.data.Table);
      const filteredData = response.data.Table;
      console.log("filteredData", filteredData);
      setBindReferralFollowUpData(filteredData);
    }
  };

  const [rowIndexData, setRowIndexData] = useState("");
  const [clearNotificationValues, setClearNotificationValues] = useState(false);

  const handleNotificationData = (updatedData) => {
    console.log("ccxcxcxc", updatedData);
    updatedData.forEach((item) => {
      setRowIndexData(item.RowIndex);
      setTxnServicesId(item.TxnServicesId);
      setReferralDate(item.Date);
      setReferralClientId(item.ClientID);
      setReferralService(item.ReferralService);
      setReferralRemarks(item.Remarks);
    });
  };

  // Contraceptive Renewal Supply Start

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const remarksResponse = await BindAPI(
          "NotificationAPI/BindContraceptiveRemarks"
        );
        const remarksDiscontinuedResponse = await BindAPI(
          "NotificationAPI/BindContraceptiveDiscontinuedRemarks"
        );
        const referralFollowUpRemarksResponse = await BindAPI(
          "NotificationAPI/BindReferralFollowUpRemarks"
        );
        const remarksResponseDD = remarksResponse.data.Table.map((m) => ({
          value: m.MstContraceptiveRemarksId,
          label: m.Remarks_en,
        }));
        const remarksDiscontinuedResponseDD =
          remarksDiscontinuedResponse.data.Table.map((m) => ({
            value: m.MstContraceptiveDiscontinuedRemarksId,
            label: m.Remarks_en,
          }));
        const referralFollowUpRemarksResponseDD =
          referralFollowUpRemarksResponse.data.Table.map((m) => ({
            value: m.MstReferralFollowUpRemarksId,
            label: m.Remarks_en,
          }));

        setBindContraceptiveRemarks(remarksResponseDD);
        setBindContraceptiveRemarksDiscontinued(remarksDiscontinuedResponseDD);
        setBindReferralFollowUpRemarks(referralFollowUpRemarksResponseDD);
      } catch (error) {}
    };
    dropDownDatas();
  }, []);

  const handleContraceptiveNotificationData = (updatedData) => {
    

    updatedData.forEach((item) => {
      setRowIndexData(item.RowIndex);
      setContraceptiveNotificationId(item.TxnContraceptiveNotificationId);
      setContraceptiveDate(item.Date);
      setContraceptiveServiceDate(item.ServiceDate);
      setContraceptiveClientId(item.ClientID);
      setFPMethod(item.FPMethod);
      setServiceId(item.TxnServicesId);

      setContraceptiveRemarks(item.Remarks);
      setContraceptiveRemarksDiscontinued(item.Remarks2);
      setContraceptiveNextFollowUpDate(item.NextFollowUpDate);
    });
  };
  // Contraceptive Renewal Supply End

  const handleClearNotificationValues = (data) => {
    data && setClearNotificationValues(false);
  };

  const cancel = () => {
    setClearNotificationValues(true);
    setRowIndexData("");
    setContraceptiveNotificationId("");
    setContraceptiveDate("");
    setContraceptiveServiceDate("");
    setContraceptiveClientId("");
    setFPMethod("");
    setServiceId("");

    setContraceptiveRemarks("");
    setContraceptiveRemarksDiscontinued("");
    setContraceptiveNextFollowUpDate("");

    setTxnServicesId("");
    setReferralDate("");
    setReferralClientId("");
    setReferralService("");
    setReferralRemarks("");

    notificationData(userProfileDatas);
  };

  const HandleSubmit = async (rowIndex) => {
    console.log("rowIndex", rowIndex);
    if (rowIndex === rowIndexData[0]) {
      const data = {
        txnContraceptiveNotificationId: contraceptiveNotificationId,
        date: contraceptiveDate,
        serviceDate: contraceptiveServiceDate,
        clientID: contraceptiveClientId,

        remarks: contraceptiveRemarks,
        remarksDiscontinued: contraceptiveRemarksDiscontinued
          ? contraceptiveRemarksDiscontinued
          : 0,
        fpMethod: fpMethod,
        txnServicesId: serviceId,
        nextFollowUp: contraceptiveNextFollowUpDate
          ? contraceptiveNextFollowUpDate
          : null,
        createdBy: sessionStorage.getItem("createdBy"),
        flag: 1,
      };

      const response = await IUDAPI(
        [data],
        "NotificationAPI/IUDContraceptiveNotification"
      );

      if (response.data.Table[0].Result === "M") {
        toast.success(
          "Contraceptive Notification data Insert successfully 👍",
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(cancel, 3000);
        notificationData(userProfileDatas);
      } else {
        toast.error("Contraceptive Notification data Insert Failed 👎", {
          duration: 5000,
          position: "top-center",
        });
        setTimeout(cancel, 5000);
        notificationData(userProfileDatas);
      }
    } else {
      toast.error(
        "NextFollowUp data Insert Failed 👎. Please Fill One Row only",
        {
          duration: 3000,
          position: "top-center",
        }
      );
      setTimeout(cancel, 3000);
      notificationData(userProfileDatas);
    }
  };

  const HandleSubmit1 = async (rowIndex) => {
    if (rowIndex === rowIndexData[0]) {
      const data = {
        txnServicesId: txnServicesId,
        date: referralDate,
        clientID: referralClientId,

        remarks: referralRemarks,

        createdBy: sessionStorage.getItem("createdBy"),
        flag: 1,
      };

      const response = await IUDAPI(
        [data],
        "NotificationAPI/IUDReferralFollowUpNotification"
      );

      if (response.data.Table[0].Result === "M") {
        toast.success(
          "Referral Follow Up Notification data Insert successfully 👍",
          {
            duration: 3000,
            position: "top-center",
          }
        );
        setTimeout(cancel, 3000);
        notificationData(userProfileDatas);
      } else {
        toast.error("Referral Follow Up Notification data Insert Failed 👎", {
          duration: 5000,
          position: "top-center",
        });
        setTimeout(cancel, 5000);
        notificationData(userProfileDatas);
      }
    } else {
      // cancel();
      toast.error(
        "NextFollowUp data Insert Failed 👎. Please Fill One Row only",
        {
          duration: 3000,
          position: "top-center",
        }
      );
      setTimeout(cancel, 3000);
      // notificationData();
    }
  };

  return (
    <div className="w-full">
      <div className="">
        <div className="block mb-2 px-2 text-base capitalize font-bold text-neutral-700 ">
          Notifications
        </div>
        <div className="px-2 ">
          <hr className="h-px my-2  bg-red-600 border-0 "></hr>
        </div>

        <form className="overflow-y-scroll px-5 max-h-[72vh]">
          <GridPage
            title="Contraceptive Renewal Supply Follow Up"
            header={contraceptiveRSFHeader}
            records={bindContraceptiveRenewalData}
            bindContraceptiveRemarks={bindContraceptiveRemarks}
            bindDiscontinuedRemarks={bindContraceptiveRemarksDiscontinued}
            onContraceptiveNotificationDatas={
              handleContraceptiveNotificationData
            }
            onNotificationSave={HandleSubmit}
            onNotificationClear={clearNotificationValues}
            onNotificationClearFalse={handleClearNotificationValues}
            addView={true}
          />
        </form>
        <div className="px-2 ">
          <hr className="h-px my-2  bg-red-600 border-0 "></hr>
        </div>
        <form className="overflow-y-scroll px-5 max-h-[72vh]">
          <GridPage
            title="Referral Follow Up"
            header={ReferralFollowUpNotificationsHeader}
            records={bindReferralFollowUpData}
            onReferralFollowUpNotificationDatas={handleNotificationData}
            bindReferralFollowUpRemarks={bindReferralFollowUpRemarks}
            onNotificationSave={HandleSubmit1}
            onNotificationClear={clearNotificationValues}
            onNotificationClearFalse={handleClearNotificationValues}
            addView={true}
          />
        </form>
      </div>
    </div>
  );
};

export default Notifications;
