// Code review - In Progress
// Documentation - In Progress

import React, { useEffect, useState } from "react";
import CheckboxTable from "../components/CheckBoxTable";
import "react-toastify/dist/ReactToastify.css";
import { BindAPI } from "../services/APIConnection";
import RoleVsCapabilityTable from "../components/RoleVsCapabilityTable";
const RoleVsCapabilityTwo = () => {
  const [bindMenuMasterDatas, setBindMenuMasterDatas] = useState([]);
  const [bindRoleVsCapRightsDatas, setBindRoleVsCapRightsDatas] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    const getMenuTableDatas = async () => {
      const rightsResponse = await BindAPI("RoleVsCapabilityAPI/BindRoleVsCapability");
      setBindRoleVsCapRightsDatas(rightsResponse.data.Table);

      const response = await BindAPI("RoleVsCapabilityAPI/BindScreenMenu");
      const organizedData = {};

      const processMenuData = (menuHeadingId) => {
        const filteredData = response.data.Table.filter(
          (item) => item.MstMenuHeadingId === menuHeadingId
        );

        const menuList = {};

        filteredData.forEach((item) => {
          if (!menuList[item.MenuName]) {
            menuList[item.MenuName] = {
              MenuName: item.MenuName,
              MstMenuId: item.MainMenuId ? null : item.MstMenuId,
              MainMenuId: item.MainMenuId,
              MstMenuHeadingId: item.MstMenuHeadingId,
              checked: item.MainMenuId && item.SubMenu && item.SubMenu.length > 0 ? null : [],
              SubMenu: [],
              View: item.View,
              Add: item.Add,
              Edit: item.Edit,
              Delete: item.Delete,
              Inactive: item.Inactive,
              Service: item.Service,
            };
          }

          const submenuFiltered = response.data.Table.filter(
            (subItem) =>
              subItem.MenuName === item.MenuName &&
              subItem.MainMenuId &&
              subItem.MstMenuHeadingId === menuHeadingId
          ).map((subItem) => ({
            SubMenu: subItem.SubMenu,
            MstMenuId: subItem.MstMenuId,
            MainMenuId: subItem.MainMenuId,
            checked: [],
            View: item.View,
            Add: item.Add,
            Edit: item.Edit,
            Delete: item.Delete,
            Inactive: item.Inactive,
            Service: item.Service,
          }));

          const arr = rightsResponse.data.Table.filter(
            (e) =>
              e.MstMenuId == menuList[item.MenuName].MstMenuId &&
              e.MstRoleId == selectedRole
          );
          console.log("MstMenuId", arr);
          const checkedData =
            arr.length > 0
              ? {
                  add: arr[0].AddPermission === 1,
                  view: arr[0].ViewPermission === 1,
                  inActive: arr[0].BlockPermission === 1,
                  delete: arr[0].DeletePermission === 1,
                  edit: arr[0].ModifyPermission === 1,
                  service: arr[0].ProvideServicePermission === 1,
                  title:
                    arr[0].AddPermission === 1 &&
                    arr[0].ViewPermission === 1 &&
                    arr[0].BlockPermission === 1 &&
                    arr[0].DeletePermission === 1 &&
                    arr[0].ModifyPermission === 1 &&
                    arr[0].ProvideServicePermission === 1,
                }
              : {
                  title: false,
                  add: false,
                  view: false,
                  inActive: false,
                  delete: false,
                  edit: false,
                  service: false,
                };
          if (menuList[item.MenuName].checked !== null) {
            menuList[item.MenuName].checked.push(checkedData);
          }

          submenuFiltered.forEach((subItem) => {
            const subMenuItemExists = menuList[item.MenuName].SubMenu.some(
              (existingSubMenu) => existingSubMenu.SubMenu == subItem.SubMenu
            );
            if (!subMenuItemExists) {
              const arr = rightsResponse.data.Table.filter(
                (e) =>
                  e.MstMenuId == subItem.MstMenuId &&
                  e.MstRoleId == selectedRole
              );
              console.log("subItem Cheacked:", subItem.checked);
              subItem.checked =
                arr.length > 0
                  ? {
                      add: arr[0].AddPermission === 1,
                      view: arr[0].ViewPermission === 1,
                      inActive: arr[0].BlockPermission === 1,
                      delete: arr[0].DeletePermission === 1,
                      edit: arr[0].ModifyPermission === 1,
                      service: arr[0].ProvideServicePermission === 1,
                      title:
                        arr[0].AddPermission === 1 &&
                        arr[0].ViewPermission === 1 &&
                        arr[0].BlockPermission === 1 &&
                        arr[0].DeletePermission === 1 &&
                        arr[0].ModifyPermission === 1 &&
                        arr[0].ProvideServicePermission === 1,
                    }
                  : {
                      title: false,
                      add: false,
                      view: false,
                      inActive: false,
                      delete: false,
                      edit: false,
                      service: false,
                    };

              if (!subMenuItemExists) {
                menuList[item.MenuName].SubMenu.push(subItem);
              }
            }
          });
        });

        return Object.values(menuList);
      };

      organizedData.Dashboard = processMenuData(1); // Dashboard
      organizedData.Administrator = processMenuData(2); // Administrator
      organizedData.Transaction = processMenuData(3); // Transaction
      organizedData.Reports = processMenuData(4); // Reports

      setBindMenuMasterDatas(organizedData);
      console.log("organizedData:", organizedData);
    };

    getMenuTableDatas();
  }, [selectedRole]);

  const handleUpdateMenuData = (updatedData) => {
    setBindMenuMasterDatas(updatedData);
    console.log("roleData", bindMenuMasterDatas);
  };

  return (
    <div className="RoleVsCapability">
      <RoleVsCapabilityTable
        menuData={bindMenuMasterDatas}
        handleChange={handleUpdateMenuData}
        handleRoleChange={setSelectedRole}
      />
    </div>
  );
};

export default RoleVsCapabilityTwo;
