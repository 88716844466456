// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import download from "../image/download.png";
import { useUserCapability } from "../components/ConstantReducerProvider";
import MultiSelect from "../ReusableComponents/MultiSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { BindAPI, IUDAPI, ImageAPI } from "../services/APIConnection";
import { toast } from "react-toastify";

const UserProfile = ({ data, closePopup }) => {
  const { userProfileState } = useUserCapability();
  //const [bindUser, setBindUser] = useState([]);
  //Will be used in Functionality
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sdp, setSdp] = useState([]);
  const [location, setLocation] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState(1);
  const [userId, setUserId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showImage, setShowImage] = useState("");
  const [imageUploaded, setimageUploaded] = useState("");
  const [designation, setDesignation] = useState("");
  const [roleGroub, setRoleGroup] = useState("");
  const [mstUserId, setMstUserId] = useState("");
  const [bindCountryDD, setBindCountryDD] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "America" },
    { value: "3", label: "Korea" },
  ]);

  const [sdpDropDown, setSdpDropDown] = useState([]);
  const [sdpEditedValues, setSDPEditedValues] = useState([]);
  const [sdpDatas, setSdpDatas] = useState([]);

  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  // const handleSdpDropDown = () => {
  //   const sdpData = data[0];
  //   const mstResourceString = sdpData.MstSDPId; // Assuming it's a string like '77,78,79,80,82'

  //   // Convert the string of numbers to an array of integers
  //   const mstResourceArray = mstResourceString.split(",").map(Number);

  //   let sdpModifyData = [];

  //   // Assuming sdpDropDown is an array of objects like [{ value: 1, label: 'Option 1' }, ...]
  //   for (let MstSDPId of mstResourceArray) {
  //     const mstResourceNumber = parseInt(MstSDPId, 10);

  //     const resourcesDropDownValue = sdpDropDown.find(
  //       (item) => item.value === mstResourceNumber
  //     );

  //     if (resourcesDropDownValue) {
  //       sdpModifyData.push(resourcesDropDownValue);
  //     }
  //   }
  //   setSDPEditedValues(sdpModifyData);
  // };

  // const data = userProfileState.userProfile;

  const locationData = useLocation();

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setMstUserId(data[0].MstUserId);
      // setBindUser(data[0]);
      setFirstName(data[0].FirstName_en);
      setMiddleName(data[0].MiddleName_en);
      setLastName(data[0].LastName_en);
      setEmail(data[0].UserEmail);
      setPhone(data[0].UserMobile);
      setUserId(data[0].UserId);
      setShowImage(data[0].UserProfileImg);

      setimageUploaded(data[0].UserProfileImg);
      //  setState(data[0].MstStateId);
      // handleDesignationDropDown(data[0].Designation);
      setDesignation(data[0].Designation);
      //handleRoleGroupDropDown(data[0].RoleName);
      setRoleGroup(data[0].RoleName);
      //  handleStateDropDown(data[0].LocationName_en);
      setState(data[0].StateName_en);
      // handleLocationDropDown(data[0].LocationName_en);
      setLocation(data[0].LocationName_en);
      //handleSdpDropDown(data[0].MstSDPId);
      // const getSDPDatas = data.SDP_en;
      // var storeSDP = [];
      // for (var i in getSDPDatas) {
      //   storeSDP = storeSDP.push(i);
      // }
      // setSdp(storeSDP);
      // console.log("storeSDP", storeSDP);
    } else {
    }

    // const fetchSDPData = async () => {
    //   const response = await BindAPI("SDPAPI/GetSDP");
    //   setSdpDatas(response.data.Table);
    //   console.log("sdpsIDDD", sdpDatas);
    // };

    // fetchSDPData();

    // if (Object.values(data)) {
    //   let userSDPs = Object.values(data);
    //   let sdpData = userSDPs.map((items) => items.SDP_en);
    //   setSdp(sdpData);
    //   // const getSDPDatas = userSDPs.map((item) => item.SDP_en);
    //   // var storeSDP = [];
    //   // for (var i of getSDPDatas) {
    //   //   storeSDP = storeSDP.push(i);
    //   // }
    //   // setSdp(getSDPDatas);
    //   // console.log("storeSDP", storeSDP);
    // }

    if (Object.values(data)) {
      let userSDPs = Object.values(data);

      // Filter out undefined, null, and empty string values
      let sdpData = userSDPs
        .map((items) => items.SDP_en)
        .filter((sdp) => sdp !== undefined && sdp !== null && sdp !== "");

      setSdp(sdpData);
    }
  }, []);

  // useEffect(() => {
  //   if (sdpDatas && sdpDatas.length > 0) {
  //     if (Array.isArray(data) && data.length > 0) {
  //       let splitSDPs = data[0].MstSDPId;
  //       let sdps = splitSDPs.split(",");
  //       let sdpDatats = [];
  //       for (var i of sdps) {
  //         console.log("IIISDPS", i);
  //         sdpDatats = sdpDatats.concat(sdpDatas.filter((x) => x.MstSDPId == i));
  //       }
  //       let sdpEndData = sdpDatats.map((x) => x.SDP_en);
  //       console.log("sdpEndData", sdpEndData);
  //       setSdp(sdpEndData);
  //     }
  //   }
  // }, [sdpDatas ? sdpDatas : undefined]);

  const navigate = useNavigate();
  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    if (createdBy == null || createdBy == "") {
      navigate("/");
    }
  }, [navigate, data]);

  const handleImageUpload = async () => {
    if (!imageUploaded) {
      return;
    } else {
      const formData = new FormData();
      formData.append("UserImage", imageUploaded);
      const response = await ImageAPI(formData, "UserAPI/UserImageUpload");
      setShowImage(response.data);

      setNewImageUploaded(true);
    }
  };

  const handleSubmit = async (items, flag) => {
    setDisabledBtn(true);
    if (flag == 2) {
      const userPayload = {
        mstUserId: mstUserId,
        userProfileImg: showImage,
        createdBy: mstUserId,
        flag: flag,
      };

      const response = await IUDAPI(
        userPayload,
        "UserAPI/UpdateUserProfileImage"
      );
      if (response.data.Table[0].Result === "M") {
        data[0].UserProfileImg = showImage;
        setTimeout(() => {
          closePopup(true);
        }, 1000);
        setNewImageUploaded(false);
        setDisabledBtn(false);
        //window.location.reload(false);
        // alert("updated");
        // toast.success("Image Updated successfully 👍", {
        //   position: "top-center",
        // });
      }
    }
  };

  console.log("sdp", sdp);

  return (
    <div class="w-full">
      <div class="text-xl mx-5 font-medium text-neutral-700 ">
        {"User Profile"}
      </div>
      <div class=" flex flex-col w-full">
        <div class={""}>
          <div className={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
              <div class="grid gap-4 mb-6 md:grid-cols-3">
                <InputText
                  inputType="text"
                  title="First Name"
                  name="firstName"
                  placeholder="Vaibhav"
                  content={firstName}
                  controlFunc={(e) => setFirstName(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Middle Name"
                  name="middleName"
                  placeholder="Middle Name"
                  content={middleName}
                  controlFunc={(e) => setMiddleName(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Last Name"
                  name="lastName"
                  placeholder="S"
                  content={lastName}
                  controlFunc={(e) => setLastName(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="email"
                  title="Email"
                  name="email"
                  placeholder="vaibhav@fpaindia.org"
                  content={email}
                  controlFunc={(e) => setEmail(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Mobile Number"
                  name="phone"
                  placeholder="XXXXX XXXXX"
                  content={phone}
                  controlFunc={(e) => setPhone(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Designation"
                  name="userId"
                  content={designation}
                  controlFunc={(e) => setDesignation(e.target.value)}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Role/Groups"
                  name="userId"
                  content={roleGroub}
                  controlFunc={(e) => setRoleGroup(e.target.value)}
                  disabled="disabled"
                />
                <DropDown
                  classDiv="hidden"
                  title="Country"
                  options={bindCountryDD}
                  placeholder="Select"
                  name="country"
                  selectedOption={country}
                  controlFunc={(e) => setCountry(e.target.value)}
                  disabled="disabled"
                />
                {state && (
                  <InputText
                    inputType="text"
                    title="State"
                    name="userId"
                    content={state}
                    controlFunc={(e) => setState(e.target.value)}
                    disabled="disabled"
                  />
                )}
                {location && (
                  <InputText
                    inputType="text"
                    title="Branch/Project"
                    name="userId"
                    content={location}
                    controlFunc={(e) => setLocation(e.target.value)}
                    disabled="disabled"
                  />
                )}
                {sdp.length > 0 && (
                  <InputText
                    inputType="text"
                    title="S.D.P."
                    name="sdp"
                    content={sdp}
                    disabled="disabled"
                  />
                )}
                {/* <InputText
                  inputType="text"
                  title="User Id"
                  placeholder="MUM-PO-00001"
                  name="userId"
                  content={userId}
                  controlFunc={(e) => setUserId(e.target.value)}
                  disabled="disabled"
                /> */}
                <InputText
                  inputType="file"
                  title="Photo"
                  name="image"
                  handleImageUpload={(e) => setimageUploaded(e.target.files[0])}
                />{" "}
                <div class={mainTable.grid2}>
                  {/* <div className="flex"> */}
                  <div className="mt-7 mr-7">
                    <button
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleImageUpload()}
                    >
                      UPLOAD
                    </button>
                  </div>
                  <div>
                    <img
                      src={showImage ? showImage : download}
                      alt="Image"
                      className="rounded-full w-20 h-20"
                    />
                  </div>
                  {/* </div> */}
                </div>{" "}
              </div>
            </form>
            {newImageUploaded && (
              <div class={mainTable.submitCancelMainDiv}>
                <button
                  data-modal-hide="bottom-right-modal"
                  type="button"
                  //class={mainTable.submitButton}
                  className={
                    disabledBtn
                      ? mainTable.disabledSubmitButton
                      : mainTable.submitButton
                  }
                  onClick={() => handleSubmit(0, 2)}
                  disabled={disabledBtn}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
