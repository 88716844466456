// Code review - Not Started
// Documentation - Not Started
import React, { useEffect, useState } from "react";
//import { Route, Routes } from "react-router-dom";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ClientMaster from "../containers/ClientMaster";
import StateMaster from "../containers/StateMaster";
import LocationMaster from "../containers/LocationMaster";
import LanguageMaster from "../containers/LanguageMaster";
import CityVillageMaster from "../containers/CityVillageMaster";
import UserMaster from "../containers/UserMaster";
import CampsMeetings from "../containers/CampsMeetings";
// import SchoolMaster from "../containers/SchoolMaster";
import StudentMaster from "../containers/StudentMaster";
import CountryMaster from "../containers/CountryMaster";
import HealthCareMaster from "../containers/HealthCareMaster";
import RoleMaster from "../containers/RoleMaster";
import InventoryProduct from "../containers/InventoryProduct";
import Appointment from "../containers/Appointment";
import InventoryIssue from "../containers/InventoryIssue";
import Dashboard from "../containers/Dashboard";
import Login from "../containers/Login";
import Report1 from "../containers/Report1";
//import ClientManage from "../containers/ClientManage";
// import Resources from "../containers/Resources";
import CSE from "../containers/CSE";
import CSEOutSchoolReport from "../containers/CSEOutSchoolReport";
import InventoryReceipts from "../containers/InventoryReceipts";
import EducationMaster from "../containers/EducationMaster";
import AreaMaster from "../containers/AreaMaster";
import SDPMaster from "../containers/SDPMaster";
import SRHCommodities from "../containers/SRHCommodities";
import NonSRHProducts from "../containers/NonSRHProducts";
import Services from "../containers/Services";
import InventoryIndent from "../containers/InventoryIndent";
import Awarness from "../containers/Awarness";
import ResourcesMaster from "../containers/ResourcesMaster";
import ServicesCategoryMaster from "../containers/ServicesCategoryMaster";
import ServicesSubCategoryMaster from "../containers/ServicesSubCategoryMaster";
import ServicesMaster from "../containers/ServicesMaster";
import UserProfile from "../popup-containers/UserProfile";
import BrochureMaster from "../containers/BrochureMaster";
import ViewBrochure from "../containers/ViewBrochure";
import CSEReport from "../containers/CSEReport";
 
import AddServices from "../popup-containers/AddServices";
import TestDropDown from "../unused-code/TestDropDown";
import YouthGroup from "../containers/YouthGroup";
import SchoolGroup from "../containers/SchoolGroup";
import SupplierMaster from "../containers/SupplierMaster";
import SPServicesSession from "../containers/SPServicesSession";
import TrackOutreachWorker from "../containers/TrackOutreachWorker";
import InterpersonalContacts from "../containers/InterpersonalContacts";
import ParticipantBoxTable from "../components/ParticipantBoxTable";
import GeneralMassMeeting from "../containers/GeneralMassMeeting";
import GroupDiscussion from "../containers/GroupDiscussion";
import MassMedia from "../containers/MassMedia";
import BlockMaster from "../containers/BlockMaster";
import DistrictMaster from "../containers/DistrictMaster";
import DoctorAppointments from "../containers/DoctorAppointments";
import SpecialServiceSession from "../containers/SpecialServiceSession";
import SpecialServiceSessionTable from "../components/SpecialServiceSessionTable";
import InSchoolSession from "../containers/InSchoolSession";
import OutSchoolSession from "../containers/OutSchoolSession";
import PartnerOrganization from "../containers/PartnerOrganization";
import CBP from "../containers/CBP";
import SplServicesTrans from "../containers/SplServicesTrans";
import BrandMaster from "../containers/BrandMaster";
import ReportOutschoolSession from "../components/ReportOutSchoolSession";
import TrainingMonthly from "../containers/TrainingMonthly";
import RoleVsCapabilityTwo from "../containers/RoleVsCapabilityTwo";
import SystemSettings from "../containers/SystemSettings";
import Consumption from "../containers/Consumption";
import Commodities from "../containers/Commodities";
import { useUserCapability } from "../components/ConstantReducerProvider";
import { authenticationAPI } from "../services/APIConnection";
import MainLayout from "../components/MainLayout";
import CreateZoomMeeting from "../containers/CreateZoomMeeting";
import ReferralTracking from "../containers/ReferralTracking";
import SingleSession from "../containers/SingleSession";
import OpeningStock from "../containers/OpeningStock";
import CSEInSchoolReport from "../containers/CSEInSchoolReport";
import IECIPCReport from "../containers/IECIPCReport";
import IECReferralsReports from "../containers/IECReferralsReports";
import CSEReferralsReport from "../containers/CSEReferralsReport";
import ProfileCATClientsReport from "../containers/ProfileCATClientsReport";
import AgeGenderDHIS2SRH from "../containers/AgeGenderDHIS2SRH";
import AgeGenderChildren from "../containers/AgeGenderChildren";
import ClientsByAgeGenderDHIS2SRHReport from "../containers/ClientsByAgeGenderDHIS2SRH";
import ClientProfileMEReport from "../containers/ClientProfileMEReport";
import FPItemProvidedDHIS2SRH from "../containers/FPItemProvidedDHIS2SRH";
import DropOutsReport from "../containers/DropOutsReport";
import ClientsByAgeGenderChildren from "../containers/ClientsByAgeGenderChildren";
import FPUserDHIS2SRHByAge from "../containers/FPUserDHIS2SRHByAge";
import CYPDHIS2SRH from "../containers/CYPDHIS2SRH";
import ProjectMaster from "../containers/ProjectMaster";
import ReportAgeGenderDonorSRH from "../containers/ReportAgeGenderDonorSRH";
import ReportAgeDonorSRH from "../containers/ReportAgeDonorSRH";
import IECMMReport from "../containers/IECMMReport";
import IECGDReport from "../containers/IECGDReport";
import IECGMMReport from "../containers/IECGMMReport";
import CSESingleSessionReport from "../containers/CSESingleSessionReport";
import ReportAgeChildren from "../containers/ReportAgeChildren";
import ReportAgeDHIS2SRH from "../containers/ReportAgeDHIS2SRH";
import MonthlyEventsReport from "../containers/MonthlyEventsReport";
import ClientsByAgeGenderDonorSRHReport from "../containers/ClientsByAgeGenderDonorSRHReport";
import ReportGender from "../containers/ReportGender";
import ReportAgeGenderDHIS2NonSRH from "../containers/ReportAgeGenderDHIS2NonSRH";
import ReportClientProfilePAC from "../containers/ReportClientProfilePAC";
import ReportStockBranchLevel from "../containers/ReportStockBranchLevel";
import ReportStockSDPLevel from "../containers/ReportStockSDPLevel";
import ReportIECSummary from "../containers/ReportIECSummary";
import ReportCSESummary from "../containers/ReportCSESummary";
import StaffNameMaster from "../containers/StaffNameMaster";
import ReportStockORSLevel from "../containers/ReportStockORSLevel";

const RootRouter = () => {
  const { userProfileState, dispatch } = useUserCapability();
  const [tableSdpData, setTableSdpData] = useState({});
  const navigate = useNavigate();
  const refreshUserData = async () => {
    
    const userName = sessionStorage.getItem("userName");
    const password = sessionStorage.getItem("password");
    const AllSDP = sessionStorage.getItem("AllSDP") ? sessionStorage.getItem("AllSDP") : "";
    const AllSDPArray = AllSDP.split(",").map(item => parseInt(item.trim()));
    try {
      const loginPayload = {
        userName: userName,
        password: password,
      };
      const response = await authenticationAPI(
        loginPayload,
        "LoginAPI/LoginValid"
      );
      if (response && response.data && response.data.jsonString) {
        
        localStorage.setItem("jwtToken", response.data.token);
        let tableDatas = JSON.parse(response.data.jsonString);
        if (tableDatas.Table[0].Result === "S") {
          if (response && response.data) {
            // Check if Table1 exists and is not empty
            if (tableDatas.Table1 && tableDatas.Table1.length > 0) {
              
              // Access the desired value from the first item in Table1
              const createdBy = tableDatas.Table1[0].MstUserId;
              sessionStorage.setItem("createdBy", createdBy);

              // Add AllSDP to each item in Table1
              tableDatas.Table1.forEach((item) => {
                item.AllSDP = AllSDPArray;
              });

              setTableSdpData(tableDatas.Table1);
              console.log("updatedUser", tableDatas.Table1);

              dispatch({ type: "USER_PROFILE", payload: tableDatas.Table1 });
            } else {
              console.log("User not found");
            }

            // Set user capability data
            if (tableDatas.Table2) {
              dispatch({
                type: "USER_VS_CAPABILITY",
                payload: tableDatas.Table2,
              });
            }
          }
        }else{
          navigate("/");
        }
      }
    } catch (error) {
      console.log(`Refresh Login Error: ${error}`);
    }
  };

  useEffect(() => {
    const userId = sessionStorage.getItem("createdBy");

    if (userId) {
      refreshUserData();
    } else if (
      userProfileState.userVsCapability &&
      userProfileState.userProfile
    ) {
      return;
    } else {
      return;
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route path="/clientmanage" element={<ClientManage />} /> */}
      {/* <Route path="/resources" element={<Resources />} /> */}
      <Route path="/cse" element={<CSE />} />
      <Route path="/clientmaster" element={<ClientMaster />} />
      <Route path="/statemaster" element={<StateMaster />} />
      <Route path="/areamaster" element={<AreaMaster />} />
      <Route path="/sdpsmaster" element={<SDPMaster />} />
      <Route path="/locationmaster" element={<LocationMaster />} />
      <Route path="/languagemaster" element={<LanguageMaster />} />
      <Route path="/countrymaster" element={<CountryMaster />} />
      <Route path="/rolemaster" element={<RoleMaster />} />
      {/* <Route path="/rolevscapability" element={<RoleVsCapability />} /> */}
      <Route path="/rolevscapability" element={<RoleVsCapabilityTwo />} />
      <Route path="/usermaster" element={<UserMaster />} />
      <Route path="/campsmeetings" element={<CampsMeetings />} />
      <Route path="/healthcaremaster" element={<HealthCareMaster />} />
      <Route path="/inventoryproduct" element={<InventoryProduct />} />
      <Route path="/educationmaterial" element={<EducationMaster />} />
      {/* <Route path="/schoolmaster" element={<SchoolMaster />} /> */}
      <Route path="/studentmaster" element={<StudentMaster />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/inventoryissue" element={<InventoryIssue />} />
      <Route path="/report1" element={<Report1 />} />
      <Route path="/cseoutschoolreport" element={<CSEOutSchoolReport />} />
      <Route path="/inventoryreceipts" element={<InventoryReceipts />} />
      <Route path="/srhcommodities" element={<SRHCommodities />} />
      <Route path="/nonsrhproducts" element={<NonSRHProducts />} />
      <Route path="/services" element={<Services />} />
      <Route path="/inventoryindent" element={<InventoryIndent />} />
      <Route path="/awarness" element={<Awarness />} />
      <Route path="/resourcesmaster" element={<ResourcesMaster />} />
      <Route path="/servicescategory" element={<ServicesCategoryMaster />} />
      <Route
        path="/servicessubcategory"
        element={<ServicesSubCategoryMaster />}
      />
      <Route path="/servicesmaster" element={<ServicesMaster />} />
      <Route path="/spservicessession" element={<SPServicesSession />} />
      <Route path="/userprofile" element={<UserProfile />} />
      <Route
        path="/servicessubcategory"
        element={<ServicesSubCategoryMaster />}
      />
      <Route path="/servicesmaster" element={<ServicesMaster />} />
      <Route path="/brochuremaster" element={<BrochureMaster />} />
      <Route path="/viewbrochure" element={<ViewBrochure />} />
      <Route path="/csereport" element={<CSEReport />} />
     
      <Route path="/addservices" element={<AddServices />} />
      <Route path="/testdropdown" element={<TestDropDown />} />
      <Route path="/youthgroup" element={<YouthGroup />} />
      <Route path="/schoolgroup" element={<SchoolGroup />} />
      <Route path="/suppliermaster" element={<SupplierMaster />} />
      <Route path="/trackoutreachworker" element={<TrackOutreachWorker />} />
      <Route path="/trainingmonthly" element={<TrainingMonthly />} />
      <Route
        path="/interpersonalcontacts"
        element={<InterpersonalContacts />}
      />
      <Route path="/participantboxtable" element={<ParticipantBoxTable />} />
      <Route path="/generalmassmeetings" element={<GeneralMassMeeting />} />
      <Route path="/groupdiscussion" element={<GroupDiscussion />} />
      <Route path="/massmedia" element={<MassMedia />} />
      {/* <Route path="/outofschoolsession" element={<OutOfSchoolSession />} /> */}
      <Route path="/singlesession" element={<SingleSession />} />
      {/* <Route path="/singlesessiononline" element={<SingleSessionOffline />} /> */}
      <Route path="/doctorappointments" element={<DoctorAppointments />} />
      <Route path="/splservicessession" element={<SpecialServiceSession />} />
      <Route
        path="/specialservicesessiontable"
        element={<SpecialServiceSessionTable />}
      />
      <Route path="/inschoolsession" element={<InSchoolSession />} />
      <Route path="/outofschoolsession" element={<OutSchoolSession />} />
      {/* <Route path="/outofschoolsession" element={<InSchoolSession />} /> */}
      <Route path="/contraceptive" element={<Commodities />} />
      <Route path="/medicinesdrugs" element={<Commodities />} />
      <Route path="/socialmarketing" element={<Commodities />} />
      <Route path="/consumables" element={<Commodities />} />
      <Route path="/partnerorganization" element={<PartnerOrganization />} />
      <Route path="/cbpmaster" element={<CBP />} />
      <Route path="/spserviceevent" element={<SplServicesTrans />} />
      <Route path="/brandmaster" element={<BrandMaster />} />
      <Route
        path="/ReportOutSchoolSession"
        element={<ReportOutschoolSession />}
      />
      <Route path="/systemsettings" element={<SystemSettings />} />
      <Route path="/consumption" element={<Consumption />} />
      <Route path="/mainlayout" element={<MainLayout />} />
      <Route path="/zoom" element={<CreateZoomMeeting />} />
      <Route path="/referraltracking" element={<ReferralTracking />} />
      <Route path="/openingstock" element={<OpeningStock />} />
      <Route path="/districtmaster" element={<DistrictMaster />} />
      <Route path="/blockmaster" element={<BlockMaster />} />
      <Route path="/cityvillagemaster" element={<CityVillageMaster />} />
      <Route path="/cseinschoolreport" element={<CSEInSchoolReport />} />
      <Route path="/iecreferralsreport" element={<IECReferralsReports />} />
      <Route path="/iecipcreport" element={<IECIPCReport />} />
      <Route path="/iecmmreport" element={<IECMMReport />} />
      <Route path="/iecgdreport" element={<IECGDReport />} />
      <Route path="/iecgmmreport" element={<IECGMMReport />} />
      <Route path="/reportclientprofilepac" element={<ReportClientProfilePAC />} />
      <Route path="/clientsbyagegenderdonorsrhreport" element={<ClientsByAgeGenderDonorSRHReport />} />
      <Route path="/csereferralsreport" element={<CSEReferralsReport />} />
      <Route path="/dropoutsreport" element={<DropOutsReport />} />
      <Route
        path="/profilecatclientsreport"
        element={<ProfileCATClientsReport />}
      />
      <Route
        path="/clientprofilemereport"
        element={<ClientProfileMEReport />}
      />
      <Route path="/agegenderdhis2srh" element={<AgeGenderDHIS2SRH />} />
      <Route path="/agegenderchildren" element={<AgeGenderChildren />} />
      <Route
        path="/clientsbyagegenderdhis2srh"
        element={<ClientsByAgeGenderDHIS2SRHReport />}
      />
      <Route
        path="/fpitemprovideddhis2srh"
        element={<FPItemProvidedDHIS2SRH />}
      />
      <Route
        path="/clientsbyagegenderchildren"
        element={<ClientsByAgeGenderChildren />}
      />
      <Route path="/fpuserdhis2srhbyage" element={<FPUserDHIS2SRHByAge />} />
      <Route path="/cypdhis2srh" element={<CYPDHIS2SRH />} />
      <Route path="/project" element={<ProjectMaster />} />
      <Route path="/reportagegenderdonorsrh" element={<ReportAgeGenderDonorSRH/>} />
      <Route path="/reportagedonorsrh" element={<ReportAgeDonorSRH/>} />
      <Route path="/reportagechildren" element={<ReportAgeChildren/>} />
      <Route path="/reportagedhis2srh" element={<ReportAgeDHIS2SRH/>} />

      <Route path="/csesinglesessionreport" element={<CSESingleSessionReport />} />
      <Route path="/monthlyeventsreport" element={<MonthlyEventsReport />} />
      <Route path="/reportgender" element={<ReportGender/>} />
      <Route path="/reportagegenderdhis2nonsrh" element={<ReportAgeGenderDHIS2NonSRH/>} />
      <Route path="/stockreportbranch" element={<ReportStockBranchLevel />} />
      <Route path="/stockreportsdp" element={<ReportStockSDPLevel />} />
      <Route path="/iecsummary" element={<ReportIECSummary/>} />
      <Route path="/csesummary" element={<ReportCSESummary/>} />
      <Route path="/staffname" element={<StaffNameMaster/>} />
      <Route path="/stockreportorslevel" element={<ReportStockORSLevel/>} />

    </Routes>
  );
};

export default RootRouter;
