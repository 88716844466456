import React, { useState } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const InputPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.classLabel ? props.classLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>
      <div>
        <div className="relative">
          <input
            className={props.Class ? props.Class : mainTable.input}
            name={props.name}
            type={showPassword ? "text" : "password"}
            value={props.content}
            onChange={props.controlFunc}
            onBlur={props.controlFuncBlur}
            errorMessage={props.errorMessage}
            placeholder={props.placeholder}
            disabled={props.disabled}
            tabIndex={props.tabIndex}
          />
          <span
            onClick={togglePasswordVisibility}
            style={{ cursor: "pointer" }}
            className="absolute inset-y-0 right-4 flex justify-center items-center text-gray-600"
          >
            {/* <span
          onClick={togglePasswordVisibility}
          style={{ cursor: "pointer" }}
          className="absolute inset-y-0 right-4 flex items-center text-gray-600 "
        > */}
            {showPassword ? (
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M396 512a112 112 0 10224 0 112 112 0 10-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z" />
              </svg>
            ) : (
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                height="1em"
                width="1em"
              >
                <path d="M508 624a112 112 0 00112-112c0-3.28-.15-6.53-.43-9.74L498.26 623.57c3.21.28 6.45.43 9.74.43zm370.72-458.44L836 122.88a8 8 0 00-11.31 0L715.37 232.23Q624.91 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.7 119.43 136.55 191.45L112.56 835a8 8 0 000 11.31L155.25 889a8 8 0 0011.31 0l712.16-712.12a8 8 0 000-11.32zM332 512a176 176 0 01258.88-155.28l-48.62 48.62a112.08 112.08 0 00-140.92 140.92l-48.62 48.62A175.09 175.09 0 01332 512z" />
                <path d="M942.2 486.2Q889.4 375 816.51 304.85L672.37 449A176.08 176.08 0 01445 676.37L322.74 798.63Q407.82 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5z" />
              </svg>
            )}
          </span>
        </div>
        <div>
          {props.errorMessage && (
            <span class="text-red-500">{props.errorMessage}</span>
          )}
        </div>
      </div>
    </div>
  );
};

InputPassword.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOf(["true", "false"]).isRequired,
  tabIndex: PropTypes.string.isRequired,
  classDiv: PropTypes.string,
  classLabel: PropTypes.string,
  classStar: PropTypes.string,
  Class: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default InputPassword;
