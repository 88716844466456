import React from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const CheckboxOrRadioGroup = (props) => {
  const selectedOptionsArray =
    props.selectedOptions && props.selectedOptions.length
      ? Array.isArray(props.selectedOptions)
        ? props.selectedOptions
        : props.selectedOptions.split(',')
      : [];

  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.ClassLabel ? props.ClassLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>
      <div className={props.Class ? props.Class : mainTable.inputRadioDiv}>
        {props.options.map((option) => (
          <label
            key={option.value}
            className={
              props.ClassLabel ? props.ClassLabel : mainTable.inputRadioLabel
            }
          >
            <input
              // className={props.Class ? props.Class : mainTable.inputRadio}
              className={props.Inputclass ? props.Inputclass : mainTable.inputRadio}

              name={props.setName}
              onChange={props.controlFunc}
              onBlur={props.controlFuncBlur}
              value={option.value}
              checked={selectedOptionsArray.indexOf(option.value) > -1}
              type={props.type}
              disabled={props.disabled}
              tabIndex={props.tabIndex}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

CheckboxOrRadioGroup.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  tabIndex: PropTypes.string.isRequired,
};

export default CheckboxOrRadioGroup;
