import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import MultiSelect from "../ReusableComponents/MultiSelect";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";

const GeneralMassMeetingPopup = ({ data, data1, data2, data3, data4 }) => {
  const [bindgenderRadioDD, setBindGenderRadioDD] = useState([]);
  const [bindtopics, setBindTopics] = useState([]);
  const [bindreferredtoDD, setBindreferredtoDD] = useState([]);
  const [bindreferelserviceDD, setBindreferelserviceDD] = useState([]);
  const [IECParticipantDetails, setIECParticipantDetails] = useState({});
  const [bindAgeDD, setBindAgeDD] = useState([]);
  const [image, setImage] = useState("");
  const [issue, setIssue] = useState([
    { value: "1", label: "In-person" },
    { value: "2", label: "Virtual" },
  ]);

  useEffect(() => {
    const Dropdowndetails = () => {
      binddrpAgedata([data3[1]]);
      binddrpGenderdata([data3[2]]);
      binddrpReferredtodata([data3[3]]);
      binddrpReferredBydata([data3[4]]);
      DropDownValues([data3[0]]);
    };
    Dropdowndetails();
  }, [data3]);

  const DropDownValues = async (Topicsvalues, bindtopics) => {
    try {
      // Ensure Topicsvalues is defined and has at least one element
      const arrayValue =
        Array.isArray(Topicsvalues) && Topicsvalues.length > 0
          ? Topicsvalues[0]
          : [];

      // Ensure bindtopics is defined
      const bindtopicsArray = Array.isArray(bindtopics) ? bindtopics : [];

      // Use map only if arrayValue is an array
      const Drpbindtopics = arrayValue.map((m, index) => ({
        value: m.value,
        label: arrayValue[index] ? arrayValue[index].label : "", // Use the label from bindtopics if available
      }));

      // Create a new array with the desired structure
      const newBindTopics = Drpbindtopics.map((item) => ({
        value: item.value,
        label: item.label,
      }));

      setBindTopics(newBindTopics);
    } catch (error) {
      console.error("Error fetching setBindTopics data:", error);
    }
  };

  const binddrpAgedata = async (Agevalues) => {
    try {
      if (Agevalues[0].length > 0) {
        const AgeDD = Agevalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindAgeDD(AgeDD);
      } else {
        setBindAgeDD([]);
      }
    } catch (error) {
      console.error("Error fetching setAwarenessAge data:", error);
    }
  };

  const binddrpGenderdata = async (Gendervalues) => {
    try {
      if (Gendervalues[0].length > 0) {
        const GenderDD = Gendervalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindGenderRadioDD(GenderDD);
      } else {
        setBindGenderRadioDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };
  const binddrpReferredtodata = async (referraltovalues) => {
    try {
      if (referraltovalues[0].length > 0) {
        const ReferredTodatadrpDD = referraltovalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindreferredtoDD(ReferredTodatadrpDD);
      } else {
        setBindreferredtoDD([]);
      }
    } catch (error) {
      console.error("Error fetching ReferredTo data:", error);
    }
  };

  const binddrpReferredBydata = async (referralservicevalues) => {
    try {
      if (referralservicevalues[0].length > 0) {
        const ReferredBydatadrpDD = referralservicevalues[0].map((m) => ({
          value: m.value,
          label: m.label,
        }));
        setBindreferelserviceDD(ReferredBydatadrpDD);
      } else {
        setBindreferelserviceDD([]);
      }
    } catch (error) {
      console.error("Error fetching setBindreferelserviceDD data:", error);
    }
  };

  useEffect(() => {
    const ParticipantDatas = JSON.stringify(data2);
    if (data2.length === 0) {
      setTableData([
        {
          participantNameEn: "",
          age: "",
          gender: "",
          mobileNumber: "",
          referralService: "",
          referredTo: "",
          fallowUpDate: "",
        },
      ]);
    } else {
    const Clearvalues = () => {
      // Implement logic to clear values from the table
      // For example, if setTableData([]) is the function to clear the table data, use it here
      setTableData([]);
    };

    Clearvalues();

   
    if (ParticipantDatas && typeof ParticipantDatas === "string") {
      try {
        const newDataArray = JSON.parse(ParticipantDatas);
        const formattedDataArray = newDataArray.map((data) => {
          const formattedDateOfExpiry = data.FallowUpDate;
          const formattedDate1 = formattedDateOfExpiry
            ? formattedDateOfExpiry.split("T")[0]
            : null;
          return {
            participantNameEn: data.ParticipantName_en,
            age: data.MstAgeId,
            gender: data.MstGenderId,
            mobileNumber: data.MobileNumber,
            referralService: data.MstReferredById,
            referredTo: data.MstReferredToId,
            fallowUpDate: formattedDate1,
          };
        });

        setTableData((prevData) => [...prevData, ...formattedDataArray]);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error, e.g., show a message to the user or log it
      }
    } else {
      // Handle the case when data2 is not a string or is empty
    }}
  }, [data2]);

  const [tableData, setTableData] = useState([
    {
      participantNameEn: "",
      age: "",
      gender: "",
      mobileNumber: "",
      referralService: "",
      referredTo: "",
      fallowUpDate: "",
    },
  ]);

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;
      return newData;
    });
  };

  const updateTableData = (value, index, property) => {
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[index] = { ...updatedData[index], [property]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setIECParticipantDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const headerData = [
    {
      title: "Name",
      key: "participantNameEn",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "gender",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Mobile No.",
      key: "mobileNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "referralService",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral To",
      key: "referredTo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Follow-up",
      key: "fallowUpDate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  return (
    <div class="w-full">
      {/* <div class="text-xl mx-5 font-medium text-neutral-700 ">
        {"General Mass Meeting - View"}
      </div> */}
      <div class=" flex flex-col w-full">
        <div class={mainTable.addShadowScreen}>
          <div class={mainTable.mainContentScreen}>
            <form class={mainTable.formPopupcontainer}>
              <div className={mainTable.grid3}>
                <InputText
                  inputType="date"
                  classStar="text-red-500"
                  title=" Date"
                  name="date"
                  disabled="disabled"
                  content={data.OpeningDate}
                />
                <AutoDropDown
                  title="State"
                  classStar={"text-red-500"}
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={[data1[1]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Branch/Project"
                  classStar={"text-red-500"}
                  options={[]}
                  placeholder="Select"
                  name="branchProject"
                  selectedOption={[data1[2]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="S.D.P."
                  options={[]}
                  placeholder="Select"
                  classStar={"text-red-500"}
                  name="sdp"
                  selectedOption={[data1[3]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Area"
                  options={[]}
                  placeholder="Select"
                  name="area"
                  classStar={"text-red-500"}
                  selectedOption={[data1[4]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <InputText
                  inputType="text"
                  classStar="text-red-500"
                  title="General / Mass Meetings No."
                  placeholder="MH/MUM/DHI/MAN/GD/2023/0001  "
                  name="generalMassMeetingsNo"
                  disabled="disabled"
                  classLabel={mainTable.labelNoWrap}
                  content={data.IECNo}
                />
                <MultiSelect
                  classStar="text-red-500"
                  label="Topics"
                  filteredOptions={bindtopics}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    data1 && data1.length > 0 && data1[0] && data1[0].length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  classDiv="relative w-full inline-block"
                  name="Topics"
                  disabled="disabled"
                  selectedOptions={data1[0]}
                />
              </div>
              <div class={mainTable.grid2}>
                <div className="col-span-2">
                  <label for="client_name" class={mainTable.label}>
                    No. of Participants/Coverage
                    <span className="text-red-500">*</span>
                  </label>
                  <hr class={mainTable.horizontalLine}></hr>
                  <div class="flex gap-6">
                    <div class="flex flex-col">
                      <label class={mainTable.label}>Gender</label>
                      <div className={mainTable.inputGenderClient}>
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Male"}
                          name={"male"}
                          content={data.NoOfPaticipantsMale}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Female"}
                          name={"femaleChild"}
                          content={data.NoOfPaticipantsFemale}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Other"}
                          name={"otherChild"}
                          content={data.NoOfPaticipantsOthers}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Total"}
                          name={"totalChild"}
                          content={data.NoOfPaticipantsTotal}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <label class={mainTable.label}>Age</label>
                      <div className={mainTable.inputGenderClient}>
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"10-14"}
                          name={"age1"}
                          content={data.Age1}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"15-24"}
                          name={"age2"}
                          content={data.Age2}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"25+"}
                          name={"age3"}
                          content={data.Age3}
                          disabled="disabled"
                        />
                        <InputText
                          inputType={"text"}
                          ClassDiv="grid gap-2 grid-cols-2"
                          ClassLabel={mainTable.labelBaseline}
                          Class={mainTable.smallInput}
                          title={"Total"}
                          name={"ageTotal"}
                          content={data.AgeTotal}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={mainTable.grid3}>
                <CheckboxOrRadioGroup
                  classStar="text-red-500"
                  title="Channel"
                  type="radio"
                  setName="channel"
                  selectedOptions={[data1[9]]}
                  options={issue}
                  disabled="disabled"
                />

                <AutoDropDown
                  title="Staff Name1"
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="staffName1"
                  Class="custom-class"
                  tabIndex="1"
                  selectedOption={[data1[5]]}
                  disabled="disabled"
                />
                <AutoDropDown
                  title="Staff Name2"
                  options={[]}
                  placeholder="Select"
                  name="staffName2"
                  Class="custom-class"
                  tabIndex="1"
                  selectedOption={[data1[10]]}
                  disabled="disabled"
                />
                <InputText
                  title="Venue"
                  inputType="text"
                  classStar={"text-red-500"}
                  name="venue"
                  placeholder="Venue"
                  disabled="disabled"
                  content={data.Venue_en}
                />
                <AutoDropDown
                  title="District"
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="district"
                  Class="custom-class"
                  tabIndex="1"
                  selectedOption={[data1[6]]}
                  disabled="disabled"
                />
                <AutoDropDown
                  title="Block"
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="block"
                  Class="custom-class"
                  tabIndex="1"
                  selectedOption={[data1[7]]}
                  disabled="disabled"
                />
                <AutoDropDown
                  title="City / Village"
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="cityVillage"
                  Class="custom-class"
                  tabIndex="1"
                  selectedOption={[data1[8]]}
                  disabled="disabled"
                />
              </div>
              <div className={mainTable.grid3}>
                <div className="flex">
                  {data4 &&
                    Array.isArray(data4) &&
                    data4.length > 0 &&
                    data4.map((item, index) => (
                      <div key={index}>
                        <img
                          src={item.previewUrl}
                          alt={`Image ${index + 1}`}
                          class={`w-28 h-28 m-3`}
                          disabled="disabled"
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div class={mainTable.labelBold}>List of Referrals</div>
              <div className="hidden md:block overflow-x-auto">
                <div className="table-container w-screen md:w-max xl:w-full max-h-96 ">
                  <table className="w-full border border-gray-300">
                    <thead>
                      <tr>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Name
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Age
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Gender
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Mobile No.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Referral Service
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Referred To
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Follow-up
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="text"
                              className={mainTable.input}
                              value={row.participantNameEn}
                              disabled="disabled"
                              onChange={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "participantNameEn"
                                );
                                updateTableData(
                                  e.target.value,
                                  index,
                                  "participantNameEn"
                                );
                              }}
                            />
                          </td>

                          <td className="border border-gray-300 p-2 w-28">
                            <input
                              type="text"
                              className={mainTable.input}
                              value={row.age}
                              disabled="disabled"
                              onChange={(e) => {
                                handleInputChange(e.target, index, "age");
                                updateTableData(e.target.value, index, "age");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <DropDown
                              options={bindgenderRadioDD}
                              placeholder="Select"
                              name="gender"
                              disabled="disabled"
                              selectedOption={row.gender}
                              controlFunc={(e) =>
                                updateTableData(e.target.value, index, "gender")
                              }
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <input
                              type="number"
                              disabled="disabled"
                              className={mainTable.input}
                              value={row.mobileNumber}
                              onChange={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "mobileNumber"
                                );
                                updateTableData(
                                  e.target.value,
                                  index,
                                  "mobileNumber"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <DropDown
                              options={bindreferelserviceDD}
                              placeholder="Select"
                              name="referralService"
                              disabled="disabled"
                              selectedOption={row.referralService}
                              controlFunc={(e) =>
                                updateTableData(
                                  e.target.value,
                                  index,
                                  "referralService"
                                )
                              }
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <DropDown
                              options={bindreferredtoDD}
                              placeholder="Select"
                              name="referredTo"
                              disabled="disabled"
                              selectedOption={row.referredTo}
                              controlFunc={(e) =>
                                updateTableData(
                                  e.target.value,
                                  index,
                                  "referredTo"
                                )
                              }
                            />
                          </td>

                          <td className="border border-gray-300 p-2">
                            <input
                              type="date"
                              disabled="disabled"
                              className={mainTable.input}
                              value={row.fallowUpDate}
                              onChange={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "fallowUpDate"
                                );
                                updateTableData(
                                  e.target.value,
                                  index,
                                  "fallowUpDate"
                                );
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={mainTable.childTableMobile} role="table">
                <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                  {tableData.length > 0 &&
                    tableData.map((row, index) => (
                      <div scope="col" class={mainTable.mobileTable}>
                        {headerData.map((keyData) => (
                          <div
                            scope="cell"
                            class={mainTable.tableData}
                            role="cell"
                          >
                            {keyData.key === "Action" ? (
                              <div class={mainTable.tableData}>
                                <div className="md:block hidden">
                                  <button
                                    id="dropdownMenuIconButton"
                                    data-dropdown-toggle="dropdownDots"
                                    class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                    type="button"
                                  >
                                    <svg
                                      class="w-6 h-6"
                                      aria-hidden="true"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                    </svg>
                                  </button>
                                </div>
                                <div className="md:hidden flex justify-center">
                                  <input
                                    type="button"
                                    className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                    value="Add"
                                  />
                                  <input
                                    type="button"
                                    className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                    value="Delete"
                                  />
                                </div>
                              </div>
                            ) : keyData.key === "participantNameEn" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <input
                                  type="text"
                                  className={mainTable.input}
                                  value={row.participantNameEn}
                                  disabled="disabled"
                                  onChange={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "participantNameEn"
                                    );
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "participantNameEn"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "age" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <input
                                  type="text"
                                  className={mainTable.input}
                                  value={row.age}
                                  disabled="disabled"
                                  onChange={(e) => {
                                    handleInputChange(e.target, index, "age");
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "age"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "gender" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindgenderRadioDD}
                                  placeholder="Select"
                                  name="gender"
                                  selectedOption={row.gender}
                                  disabled="disabled"
                                  controlFunc={(e) =>
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "gender"
                                    )
                                  }
                                />
                              </div>
                            ) : keyData.key === "mobileNumber" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <input
                                  type="number"
                                  disabled="disabled"
                                  className={mainTable.input}
                                  value={row.mobileNumber}
                                  onChange={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "mobileNumber"
                                    );
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "mobileNumber"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "referralService" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindreferelserviceDD}
                                  placeholder="Select"
                                  name="referralService"
                                  selectedOption={row.referralService}
                                  disabled="disabled"
                                  controlFunc={(e) =>
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "referralService"
                                    )
                                  }
                                />
                              </div>
                            ) : keyData.key === "referredTo" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <DropDown
                                  options={bindreferredtoDD}
                                  placeholder="Select"
                                  name="referredTo"
                                  selectedOption={row.referredTo}
                                  disabled="disabled"
                                  controlFunc={(e) =>
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "referredTo"
                                    )
                                  }
                                />
                              </div>
                            ) : keyData.key === "fallowUpDate" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <input
                                  type="date"
                                  disabled="disabled"
                                  className={mainTable.input}
                                  value={row.fallowUpDate}
                                  onChange={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "fallowUpDate"
                                    );
                                    updateTableData(
                                      e.target.value,
                                      index,
                                      "fallowUpDate"
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="flex border p-1">
                                <div className="flex basis-1/2 font-bold">
                                  {keyData.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>

              <div className="lg:col-span-3 sm:col-span-1">
                <TextArea
                  inputType="text"
                  title="Description"
                  name="description"
                  rows="4"
                  content={data.Descripition_en}
                  placeholder="Description"
                  disabled="disabled"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralMassMeetingPopup;
