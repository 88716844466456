import React from "react";
import MainLayout from "../components/MainLayout";

const Report1 = () => {
  return (
    <MainLayout  pageName="4">
      <div className="flex justify-center align-middle text-slate-500 text-3xl">
        Report 1
      </div>
    </MainLayout>
  );
};

export default Report1;
