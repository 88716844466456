// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import { BindAPI, IUDAPI } from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";
import Buttons from "../components/Buttons";

const SystemSettings = () => {
  const [rowsPerPage, setRowsPerPage] = useState("");
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [NODtoEditPastEntries, setNODtoEditPastEntries] = useState("");
  const [systemSettings2, setSystemSettings2] = useState("");
  const [systemSettings3, setSystemSettings3] = useState("");
  const [systemSettings4, setSystemSettings4] = useState("");
  const [bindSystemSettingsData, setBindSystemSettingsData] = useState([]);
  const allowedValues = [10, 25, 50, 100, 500, 1000, 5000];
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  useEffect(() => {
    ssnew();
  }, [bindSystemSettingsData]);

  const ssnew = () => {
    if (
      bindSystemSettingsData.length > 0 &&
      bindSystemSettingsData[0].NoofRowsPerPage
    ) {
      setRowsPerPage(bindSystemSettingsData[0].NoofRowsPerPage);
      setEmailId(bindSystemSettingsData[0].EmaiId);
      setNODtoEditPastEntries(bindSystemSettingsData[0].NODtoEditPastEntries);
      setSystemSettings2(bindSystemSettingsData[0].SystemSettings2);
      setSystemSettings3(bindSystemSettingsData[0].SystemSettings3);
      setSystemSettings4(bindSystemSettingsData[0].SystemSettings4);
    }
  };

  useEffect(() => {
    systemSettingsData();
  }, []);

  const systemSettingsData = async () => {
    const response = await BindAPI("SystemSettingsAPI/BindSystemSettings");
    setBindSystemSettingsData(response.data.Table);
  };

  const Cancel = () => {
    // setNODtoEditPastEntries("");
    // setSystemSettings2("");
    // setSystemSettings3("");
    // setSystemSettings4("");
    // setEmailId("");
    // setRowsPerPage("");
    systemSettingsData();
    setSubmitBtnDisabled(false);
  };

  const HandleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (!allowedValues.includes(Number(rowsPerPage))) {
      toast.error(
        "Please enter a valid value for Rows Per Page (10, 25, 50, 100, 500, 1000, 5000)",
        {
          duration: 2000,
          position: "top-center",
        }
      );
      return; // Stop the submission if the value is not allowed
    }

    const userPayload = {
      systemSettingsId: 1,
      noofRowsPerPage: rowsPerPage,
      emaiId: emailId,
      noDtoEditPastEntries:NODtoEditPastEntries,
      systemSettings2,
      systemSettings3,
      systemSettings4,
      createdBy: sessionStorage.getItem("createdBy"),
      flag,
    };
    const response = await IUDAPI(
      [userPayload],
      "SystemSettingsAPI/IUDSystemSettings"
    );

    if (response.data.Table[0].result === "M") {
      toast.success("System Settings added successfully 👍", {
        duration: 5000,
        position: "top-center",
      });
      setTimeout(5000);
    } else {
      toast.error("Failed to add System Settings data 👎", {
        duration: 2000,
        position: "top-center",
      });
      setTimeout(2000);
    }
    setSubmitBtnDisabled(false);
  };
  return (
    <MainLayout pageName="2">
      <div class="container flex flex-col">
        <div class="mt-4 mx-3">
          <p class={mainTable.headerTitle}>System Settings</p>
        </div>
        <form action="" class="formCss">
          <div class="Mainwrapper shadow-lg p-3 mb-5 bg-white rounded h-96">
            <div class="grid gap-4 mb-6 md:grid-cols-2">
              <InputText
                inputType="number"
                title="Rows Per Page"
                name="lastName"
                placeholder="Rows Per Page"
                content={rowsPerPage}
                controlFunc={(e) => setRowsPerPage(e.target.value)}
              />

              {/* <InputText
                  inputType="email"
                  title="Email"
                  name="email"
                  placeholder="vaibhav@fpaindia.org"
                  content={emailId}
                  controlFunc={(e) => setEmailId(e.target.value)}
                /> */}
              <InputText
                inputType={"text"}
                title={"Email"}
                name={"contactPersonEmail"}
                placeholder="vaibhav@fpaindia.org"
                content={emailId}
                controlFunc={(e) => setEmailId(e.target.value)}
                controlFuncBlur={(e) => {
                  const enteredEmail = e.target.value.trim(); // Trim to remove leading/trailing spaces
                  setEmailId(enteredEmail); // Update emailId state with the trimmed value

                  if (enteredEmail.length > 0) {
                    // Check if enteredEmail matches a broader email pattern
                    const isValidEmail =
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                        enteredEmail
                      );
                    setEmailIdError(
                      isValidEmail ? "" : "Enter a Valid Email Id"
                    );
                  } else {
                    setEmailIdError("Enter Email");
                  }
                }}
                errorMessage={emailIdError}
              />

              <InputText
                inputType="text"
                title="NOD to Edit Past Entries"
                name="phone"
                placeholder="NOD to Edit Past Entries"
                content={NODtoEditPastEntries}
                controlFunc={(e) => setNODtoEditPastEntries(e.target.value)}
              />
              <InputText
                inputType="text"
                title="System Settings2"
                name="userId"
                placeholder="System Settings2"
                content={systemSettings2}
                controlFunc={(e) => setSystemSettings2(e.target.value)}
              />
              <InputText
                inputType="text"
                title="System Settings3"
                name="userId"
                placeholder="System Settings3"
                content={systemSettings3}
                controlFunc={(e) => setSystemSettings3(e.target.value)}
              />
              <InputText
                inputType="text"
                title="System Settings4"
                name="userId"
                placeholder="System Settings4"
                content={systemSettings4}
                controlFunc={(e) => setSystemSettings4(e.target.value)}
              />
            </div>

            <Buttons
              addScreenTitle={true}
              handleSubmit={HandleSubmit}
              cancel={Cancel}
              disabledBtn={submitBtnDisabled ? true : false}
            />
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default SystemSettings;
