// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { useNavigate } from "react-router-dom";

const StudentTableGrid = ({
  headerData,
  tableData,
  onEditGrid,
  onViewGrid,
  onDeleteGrid,
  onBlockGrid,
  onAddressGrid,
  onSelectedGrid,
  selectedRowTableData,
  checkBoxStatus,
}) => {
  const [viewsAll, setViewsAll] = useState(false);

  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showCheckBox, setshowCheckBox] = useState(true);
  const showPopUp = (views, rowIndex) => {
    setViewsAll(views);
    setCurrentRowIndex(views ? rowIndex : null);
  };
  const [errorMessage, setErrorMessage] = useState("");
  // Set selected rows when tableData changes
  useEffect(() => {
    selectedRowTableData.forEach((rowIndex) => {
      handleRowCheckboxChange(rowIndex);
    });
    setSelectedRows(selectedRowTableData);
  }, []);

  const handleHeaderCheckboxChange = () => {
    setIsHeaderCheckboxChecked((prevIsHeaderCheckboxChecked) => {
      const isHeaderCheckboxChecked = !prevIsHeaderCheckboxChecked;

      let updatedSelectedRows = [];
      if (isHeaderCheckboxChecked) {
        updatedSelectedRows = tableData.map((_, index) => index);
      }

      setSelectedRows(updatedSelectedRows);
      const selectedRowData = updatedSelectedRows.map(
        (rowIndex) => tableData[rowIndex]
      );
      onSelectedGrid(selectedRowData);
      return isHeaderCheckboxChecked;
    });
  };
  const handleRowCheckboxChange = (rowIndex) => {
    
    setSelectedRows((prevSelectedRows) => {
      const newRow = [];
      const updatedSelectedRows = [...prevSelectedRows];
      const index = updatedSelectedRows.indexOf(rowIndex);

      if (index > -1) {
        updatedSelectedRows.splice(index, 1);
       newRow.push(rowIndex);
       checkBoxStatus(false);
      // Remove row index if already selected
      } else {
        updatedSelectedRows.push(rowIndex); // Add row index if not already selected
        newRow.push(rowIndex);
        checkBoxStatus(true);
      }

      const isHeaderCheckboxChecked =
        updatedSelectedRows.length === tableData.length;

      setIsHeaderCheckboxChecked(isHeaderCheckboxChecked);
      const selectedRowData = newRow.map(
        (rowIndex) => tableData[rowIndex]
      );
      // const selectedRowData = updatedSelectedRows.map(
      //   (rowIndex) => tableData[rowIndex]
      // );
      onSelectedGrid(selectedRowData);
      return updatedSelectedRows;
    });
  };
  return (
    <div className={mainTable.tableShadowWidth}>
      <div className={mainTable.table} role="table">
        <div className={mainTable.tableHeader} role="rowgroup">
          {showCheckBox && (
            <div className="px-2 py-2 md:py-3 flex bg-black">
              <input
                type="checkbox"
                checked={isHeaderCheckboxChecked}
                onChange={handleHeaderCheckboxChange}
              />
            </div>
          )}
          {headerData.map((item, itemIndex) => (
            <div className={item.width} role="columnheader">
              <label class="text-sm  font-medium">{item.title}</label>
            </div>
          ))}
        </div>

        <div className="overflow-y-auto h-96">
          {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div class={mainTable.tableRow}>
                <div className="px-2 py-2  md:py-3 flex bg-white">
                  <input
                    type="checkbox"
                    checked={
                      isHeaderCheckboxChecked || selectedRows.includes(rowIndex)
                    }
                    // disabled = {selectedRowTableData.length > 0 ? true : false}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </div>
                {headerData.map((keyData) => (
                  <div scope="cell" role="cell" className={keyData.width}>
                    {keyData.key === "address" || keyData.key === "details" ? (
                      <button
                        onClick={() => onAddressGrid(rowIndex)}
                        class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                      >
                        <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                        <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                        <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                        <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                          View
                        </span>
                      </button>
                    ) : keyData.key === "action" ? (
                      <div>
                        <button
                          id="dropdownMenuIconButton"
                          data-dropdown-toggle="dropdownDots"
                          class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                          type="button"
                          onClick={() => showPopUp(!viewsAll, rowIndex)}
                        >
                          <svg
                            class="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                          </svg>
                        </button>
                        {viewsAll && currentRowIndex == rowIndex && (
                          <div
                            id="dropdownDots"
                            class="z-10 bg-white absolute right-14 divide-y divide-gray-100 rounded-lg shadow w-44 "
                          >
                            <ul
                              class="py-2 text-sm text-gray-700 "
                              aria-labelledby="dropdownMenuIconButton"
                            >
                              <li>
                                <a
                                  onClick={() => onViewGrid(rowIndex, 1)}
                                  class="block px-4 py-2 hover:bg-gray-100 "
                                >
                                  View
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => onEditGrid(rowIndex)}
                                  class="block px-4 py-2 hover:bg-gray-100 "
                                >
                                  Edit
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => onDeleteGrid(rowIndex, 2)}
                                  class="block px-4 py-2 hover:bg-gray-100 "
                                >
                                  Delete
                                </a>
                              </li>
                              <li>
                                {data.User_Blockedby ||
                                data.Cli_Blockedby ||
                                data.Pro_Blockedby ? (
                                  <a
                                    onClick={() => onBlockGrid(rowIndex, 4)}
                                    class="block px-4 py-2 hover:bg-gray-100 "
                                  >
                                    Unblock
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => onBlockGrid(rowIndex, 3)}
                                    class="block px-4 py-2 hover:bg-gray-100 "
                                  >
                                    Block
                                  </a>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : keyData.title === "S.No." ? (
                      <div>{rowIndex + 1}</div>
                    ) : (
                      // <div>{data[keyData.key]}</div>
                      <div class="relative">
                        <div
                          class="truncate relative"
                          title={data[keyData.key]}
                        >
                          {data[keyData.key]}
                        </div>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          {/* <div class="bg-white text-black rounded p-1">
                              ...
                            </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>

      <div className={mainTable.tableMobile} role="table">
        <div className="w-full md:overflow-y-auto lg:h-96 h-full">
          {showCheckBox && (
            <div className="px-2 py-2 md:py-3 flex bg-[#2d2d6e]">
              <input
                type="checkbox"
                checked={isHeaderCheckboxChecked}
                onChange={handleHeaderCheckboxChange}
              />
            </div>
          )}
          {/* {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div scope="col" class={mainTable.mobileTable}>
                {headerData.map((keyData) => (
                  
                  <div scope="cell" class={mainTable.mobileStudentTable} role="cell">
                    {keyData.key === "address" ? (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold ">
                          {keyData.title}
                        </div>
                        <div className="flex basis-1/2">
                          <button
                            onClick={() => onAddressGrid(rowIndex)}
                            class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                          >
                            <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                            <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                            <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                            <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                              View
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : keyData.key === "action" ? (
                      <div class={mainTable.tableData}>
                        <div className="md:block hidden">
                          <button
                            id="dropdownMenuIconButton"
                            data-dropdown-toggle="dropdownDots"
                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                            type="button"
                            onClick={() => showPopUp(!viewsAll, rowIndex)}
                          >
                            <svg
                              class="w-6 h-6"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                            </svg>
                          </button>
                        </div>
                        <div className="md:hidden block flex justify-center">
                          <button
                            className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                            onClick={() => onViewGrid(rowIndex, 1)}
                          >
                            View
                          </button>
                          <button
                            className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                            onClick={() => onEditGrid(rowIndex)}
                          >
                            Edit
                          </button>
                          <button
                            className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                            onClick={() => onDeleteGrid(rowIndex, 2)}
                          >
                            Delete
                          </button>
                          <button
                            className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                            onClick={() => onBlockGrid(rowIndex, 3)}
                          >
                            {data.User_Blockedby ? "Unblock" : "Block"}
                          </button>
                        </div>
                      </div>
                    ) : keyData.key === "User_Image" ? (
                      <div className="flex justify-center ">
                        <input
                          type="image"
                          src={data[keyData.key]}
                          alt="Image"
                          className="rounded-full  w-20 md:w-12 md:h-12"
                        />
                      </div>
                    ) : keyData.title === "S.No" ? (
                      <div class="hidden"></div>
                    ) : (
                      <div className="flex border p-1">
                        <div className="flex basis-1/2 font-bold">
                          {keyData.title}
                        </div>
                        <div className="flex basis-1/2">
                          {data[keyData.key]}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))} */}

          {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div class={mainTable.tableIssueRow}>
                {/* <div className="px-2 mx-2 py-2 md:py-3 flex bg-[#2d2d6e]">
                <input
                    type="checkbox"
                    checked={
                      isHeaderCheckboxChecked || selectedRows.includes(rowIndex)
                    }
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </div> */}
                <div scope="col" class={mainTable.mobileTable}>
                  {headerData.map((keyData) => (
                    <div
                      scope="cell"
                      class={mainTable.mobileStudentTable}
                      role="cell"
                    >
                      {
                        // keyData.key === "address" ? (
                        //   <div className="flex border p-1">
                        //     <div className="flex basis-1/2 font-bold ">
                        //       {keyData.title}
                        //     </div>
                        //     <div className="flex basis-1/2">
                        //       <button
                        //         onClick={() => onAddressGrid(rowIndex)}
                        //         class="relative px-5 py-2 overflow-hidden font-medium text-gray-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner group"
                        //       >
                        //         <span class="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-gray-600 group-hover:w-full ease"></span>
                        //         <span class="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-gray-600 group-hover:w-full ease"></span>
                        //         <span class="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        //         <span class="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-gray-600 group-hover:h-full ease"></span>
                        //         <span class="absolute inset-0 w-full h-full duration-300 delay-300 bg-gray-900 opacity-0 group-hover:opacity-100"></span>
                        //         <span class="relative transition-colors duration-300 delay-200 group-hover:text-white ease">
                        //           View
                        //         </span>
                        //       </button>
                        //     </div>
                        //   </div>
                        // ) :
                        //  keyData.key === "action" ? (
                        //   <div class={mainTable.tableData}>
                        //     <div className="md:block hidden">
                        //       <button
                        //         id="dropdownMenuIconButton"
                        //         data-dropdown-toggle="dropdownDots"
                        //         class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                        //         type="button"
                        //         onClick={() => showPopUp(!viewsAll, rowIndex)}
                        //       >
                        //         <svg
                        //           class="w-6 h-6"
                        //           aria-hidden="true"
                        //           fill="currentColor"
                        //           viewBox="0 0 20 20"
                        //           xmlns="http://www.w3.org/2000/svg"
                        //         >
                        //           <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                        //         </svg>
                        //       </button>
                        //     </div>
                        //     <div className="md:hidden block flex justify-center">
                        //       <button
                        //         className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                        //         onClick={() => onViewGrid(rowIndex, 1)}
                        //       >
                        //         View
                        //       </button>
                        //       <button
                        //         className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                        //         onClick={() => onEditGrid(rowIndex)}
                        //       >
                        //         Edit
                        //       </button>
                        //       <button
                        //         className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                        //         onClick={() => onDeleteGrid(rowIndex, 2)}
                        //       >
                        //         Delete
                        //       </button>
                        //       <button
                        //         className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                        //         onClick={() => onBlockGrid(rowIndex, 3)}
                        //       >
                        //         {data.User_Blockedby ? "Unblock" : "Block"}
                        //       </button>
                        //     </div>
                        //   </div>
                        // ) :
                        //  keyData.key === "User_Image" ? (
                        //   <div className="flex justify-center ">
                        //     <input
                        //       type="image"
                        //       src={data[keyData.key]}
                        //       alt="Image"
                        //       className="rounded-full  w-20 md:w-12 md:h-12"
                        //     />
                        //   </div>
                        // ) :
                        keyData.title === "S.No." ? (
                          <div className="flex border p-1">
                            <div className="flex basis-1/2">
                              
                                <input
                                  type="checkbox"
                                  className="border-4 border-[#2d2d6e]"
                                  checked={
                                    isHeaderCheckboxChecked ||
                                    selectedRows.includes(rowIndex)
                                  }
                                  onChange={() =>
                                    handleRowCheckboxChange(rowIndex)
                                  }
                                />
                              
                            </div>
                            {/* <div className="flex basis-1/2 break-words"> */}
                            <div className="flex justify-center">
                              {rowIndex + 1}
                            </div>
                            {/* </div> */}
                          </div>
                        ) : (
                          // <div className="flex justify-center">{rowIndex + 1}</div>
                          <div className="grid max-md:grid-cols-2 border p-1">
                            <div className="font-bold whitespace-pre-wrap break-words">
                              {keyData.title}
                            </div>
                            <div className="whitespace-pre-wrap break-words">
                              {data[keyData.key]}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StudentTableGrid;
