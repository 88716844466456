import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import TableGrid from "./Table";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserCapability } from "./ConstantReducerProvider";
import DropDown from "../ReusableComponents/DropDown";
import { BindAPI } from "../services/APIConnection";
import "../css/loader.css";
const GridPage = ({
  title,
  rowsPerPageView,
  addView,
  addClientView,
  blockView,
  showAdd,
  tableHeight,
  bindReferralDD,
  bindPACServicesDD,
  onFPUserDD,
  onServicesGrid,
  onNotificationSave,
  onReferralGrid,
  bindActionDD,
  bindContraceptiveRemarks,
  bindDiscontinuedRemarks,
  onContraceptiveNotificationDatas,
  bindReferralFollowUpRemarks,
  onReferralFollowUpNotificationDatas,
  onServicesProvide,
  onTestResultTypeDD,
  onNotificationDatas,
  onNotificationClear,
  onNotificationClearFalse,
  header,
  records,
  handleAddress,
  handleAction,
  handleEdit,
  onHistoryGrid,
  handleSchoolView,
  setVisibleIssueDetailsTable,
  handlePrint,
  tableView,
  xScroll,
  tableScroll,
  onAdd,
  bindClientGenderDD,
  onDisabled,
}) => {
  //search state start
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const [reFetchTableData, setReFetchTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  //search state end

  useEffect(() => {
    if (records && !isInitialized) {
      const recordsWithIndex = records.map((record, index) => ({
        ...record,
        rowIndex: index, // current index
        oldIndex: index, // set oldIndex only the first time
      }));
      setTableData(recordsWithIndex);
      setSearchData(recordsWithIndex);
      
    }
  
  }, [records ? records : null]);

  //next page state start
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("");
  
  //next page state end

  const [bindDDValues, setBindDDValues] = useState([]);

  const systemSettingsData = async () => {
    const response = await BindAPI("SystemSettingsAPI/BindSystemSettings");
    const rowsPerPageFromApi = response.data.Table[0].NoofRowsPerPage;
    setItemsPerPage(rowsPerPageFromApi);
  };

  const getDDValues = async () => {
    try {
      const response = await BindAPI("MasterDataAPI/BindDDValues");
      const resultdata = response.data.Table;

      if (resultdata.length > 0) {
        const religionDD = resultdata.map((m) => ({
          value: m.NoOfRecords,
          label: m.NoOfRecords,
        }));
        setBindDDValues(religionDD);
      } else {
        setBindDDValues([]);
      }
    } catch (error) {
      console.error("Error fetching dropdown values:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await systemSettingsData();
      await getDDValues();
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleDropDown = (event) => {
    const selectedValue = event.target.value;
  
    if (selectedValue === "ALL") {
      setItemsPerPage(-1); // Setting a large number or use Infinity to bypass pagination
    } else {
      setItemsPerPage(parseInt(selectedValue));
    }
    setCurrentPage(1); // Reset to the first page
  };

  const { userProfileState } = useUserCapability();
  const userVsCapability = userProfileState.userVsCapability;
  const navigate = useNavigate();
  const [viewBag, setViewBag] = useState(0);
  const [addBag, setAddBag] = useState(0);
  const [rowsPerPageVisible, setRowsPerPageVisible] = useState(1);
  const [editBag, setEditBag] = useState(0);
  const [deleteBag, setDeleteBag] = useState(0);
  const [blockBag, setBlockBag] = useState(0);
  const [serviceBag, setServiceBag] = useState(0);

  const location1 = useLocation();
  const userSideMenu = location1?.state?.menuId;
  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    if (createdBy == null || createdBy == "") {
      navigate("/");
    }
    if (Array.isArray(userVsCapability) && userVsCapability.length > 0) {
      let menuFilter = userVsCapability.filter(
        (menu) => menu.MstMenuId == userSideMenu
      );
      setViewBag(menuFilter[0]?.ViewPermission);
      if (addView) {
        setAddBag(0);
      } else {
        setAddBag(menuFilter[0]?.AddPermission);
      }
      setEditBag(menuFilter[0]?.ModifyPermission);
      setDeleteBag(menuFilter[0]?.DeletePermission);
      setBlockBag(menuFilter[0]?.BlockPermission);
      setServiceBag(menuFilter[0]?.ProvideServicePermission);
    }
  }, [userVsCapability, addView]);

  useEffect(() => {
    if (rowsPerPageView) {
      setRowsPerPageVisible(0);
    }
  }, []);

  useEffect(() => {
    if (addClientView) {
      setAddBag(1);
    }
  }, []);
 

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
    setCurrentPage(1);
  };
  // const handleSearch = (input) => {
  //   if (input === "") {
      
  //     // If the input is cleared, reset search data to the original records
  //     const recordsWithIndex = tableData.map((record, index) => ({
  //       ...record,
  //       rowIndex: index,
  //     }));
  //     setSearchData(recordsWithIndex);
  //   } else {
  //     setIsInitialized(true);
  //     const normalizedInput = input.toLowerCase();
  //     const filtered = tableData.filter((item) => {
  //       const matchFound = header.some((data) => {
  //         const userDataKey = data.key;
  //         const userDataValue = item[userDataKey];

  //         if (userDataValue && typeof userDataValue === "string") {
  //           const normalizedValue = userDataValue.toLowerCase();
  //           let inputIndex = 0;

  //           for (let i = 0; i < normalizedValue.length; i++) {
  //             if (normalizedValue[i] === normalizedInput[inputIndex]) {
  //               inputIndex++;
  //             }
  //             if (inputIndex === normalizedInput.length) {
  //               return true; // All characters in input found in order
  //             }
  //           }
  //         }
  //         return false; // Property doesn't exist, not a string, or doesn't match the pattern
  //       });

  //       return matchFound;
  //     });

  //     const filteredWithIndex = filtered.map((record) => ({
  //       ...record,
  //       rowIndex: tableData.findIndex((r) => r === record),
  //     }));

  //     setSearchData(filteredWithIndex);
  //   }
  // };

  // const handleSearch = (input) => {
  //   if (input === "") {
  //     // Reset search data to the original records
  //     const recordsWithIndex = tableData.map((record, index) => ({
  //       ...record,
  //       rowIndex: index,
  //     }));
  //     setSearchData(recordsWithIndex);
  //   } else {
  //     setIsInitialized(true);
  //     const normalizedInput = input.toLowerCase();
  //     const filtered = tableData.filter((item) => {
  //       const matchFound = header.some((data) => {
  //         const userDataKey = data.key;
  //         const userDataValue = item[userDataKey];
  
  //         if (userDataValue && typeof userDataValue === "string") {
  //           const normalizedValue = userDataValue.toLowerCase();
  
  //           // Check if the input matches the start of any word
  //           return normalizedValue.split(" ").some((word) => 
  //             word.startsWith(normalizedInput)
  //           );
  //         }
  //         return false;
  //       });
  
  //       return matchFound;
  //     });
  
  //     const filteredWithIndex = filtered.map((record) => ({
  //       ...record,
  //       rowIndex: tableData.findIndex((r) => r === record),
  //     }));
  
  //     setSearchData(filteredWithIndex);
  //   }
  // };
  const handleSearch = (input) => {
    if (input === "") {
      // Reset search data to the original records
      const recordsWithIndex = tableData.map((record, index) => ({
        ...record,
        rowIndex: index,
      }));
      setSearchData(recordsWithIndex);
    } else {
      setIsInitialized(true);
      const normalizedInput = input.toLowerCase();
      const filtered = tableData.filter((item) => {
        const matchFound = header.some((data) => {
          const userDataKey = data.key;
          const userDataValue = item[userDataKey];
  
          if (userDataValue && typeof userDataValue === "string") {
            const normalizedValue = userDataValue.toLowerCase();
  
            // Check if input matches the start of any word
            return normalizedValue.split(" ").some((word) =>
              word.startsWith(normalizedInput) // Checks if the input matches the start of the word
            );
          }
          return false;
        });
  
        return matchFound;
      });
  
      const filteredWithIndex = filtered.map((record) => ({
        ...record,
        rowIndex: tableData.findIndex((r) => r === record),
      }));
  
      setSearchData(filteredWithIndex);
    }
  };
  
  return (
    <div className={loading ? "lex justify-center items-center h-full" : ""}>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          <>
            <div className={mainTable.headerTitle}>
              <div className={mainTable.gridTableHeader}>{title}</div>
            </div>

            <div className="relative mt-1 md:hidden">
              <div className={mainTable.searchIconPosition}>
                <svg
                  className={mainTable.searchIconSvg}
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                className={mainTable.searchInputBox}
                placeholder="Search for items"
                value={searchInput}
                onChange={handleSearchInputChange}
              />
            </div>
            <div className="justify-between flex items-center">
              {rowsPerPageVisible == 1 && (
                <div className={mainTable.searchAdd}>
                  <DropDown
                    
                    classDiv="md:mt-1 mt-0 gap-2 flex flex-col md:flex-row items-start md:items-center justify-between"
                    Class={mainTable.dropDownRecords}
                   
                    title="Rows Per Page:"
                    options={bindDDValues}
                    placeholder="Select"
                    name="ddValues"
                    selectedOption={itemsPerPage === -1 ? "ALL" : itemsPerPage}
                    controlFunc={handleDropDown}
                  />
                  <div>
                    <label for="table-search" className="sr-only">
                      Search
                    </label>
                  </div>
                  <div className="hidden md:block relative mt-1">
                    <div className={mainTable.searchIconPosition}>
                      <svg
                        className={mainTable.searchIconSvg}
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="text"
                      className={mainTable.searchInputBox}
                      placeholder="Search for items"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
              )}
              <div className="flex self-end">
               
                {blockView && (
                  <div className="text-red-600 bg-gray-100 border border-gray-100 shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                    <span className="w-4 h-4 bg-red-600 rounded-full inline-block"></span>
                    <span className="ml-2">Block</span>
                  </div>
                )}
                {addBag == 1 && (
                  <button
                    type="button"
                    className={mainTable.addButton}
                    onClick={showAdd}
                  >
                    <svg
                      width="25"
                      height="20"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                        fill="white"
                      />
                    </svg>
                    {addClientView === true ? "Add Client" : "Add"}
                  </button>
                )}
              </div>
            </div>
            <TableGrid
              className={mainTable.table}
              tableHeight={tableHeight}
              headerData={header}
              // tableData={searchData.length > 0 ? searchData : records ? records : []}
              tableData={searchData.length > 0 ? searchData : []}
              onAddressGrid={handleAddress ? handleAddress : ""}
              onViewGrid={handleAction ? handleAction : ""}
              onEditGrid={handleEdit ? handleEdit : ""}
              onHistoryGrid={onHistoryGrid}
              onBlockGrid={handleAction ? handleAction : ""}
              onDeleteGrid={handleAction ? handleAction : ""}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              viewBag={viewBag}
              serviceBag={serviceBag}
              editBag={editBag}
              deleteBag={deleteBag}
              blockBag={blockBag}
              bindReferralDD={bindReferralDD}
              onFPUserDD={onFPUserDD}
              onServicesProvide={onServicesProvide}
              onNotificationDatas={onNotificationDatas}
              onNotificationClear={onNotificationClear}
              onNotificationClearFalse={onNotificationClearFalse}
              onServicesGrid={onServicesGrid}
              onNotificationSave={onNotificationSave}
              onSchoolView={handleSchoolView ? handleSchoolView : ""}
              onReferralGrid={onReferralGrid}
              bindActionDD={bindActionDD}
              onTestResultTypeDD={onTestResultTypeDD}
              bindContraceptiveRemarks={bindContraceptiveRemarks}
              bindDiscontinuedRemarks={bindDiscontinuedRemarks}
              onContraceptiveNotificationDatas={
                onContraceptiveNotificationDatas
              }
              bindReferralFollowUpRemarks={bindReferralFollowUpRemarks}
              onReferralFollowUpNotificationDatas={
                onReferralFollowUpNotificationDatas
              }
              handlePrint={handlePrint}
              tableView={tableView}
              xScroll={xScroll}
              tableScroll={tableScroll}
              onAdd={onAdd}
              bindClientGenderDD={bindClientGenderDD}
              onDisabled={onDisabled}
              bindPACServicesDD={bindPACServicesDD}
            />
            {rowsPerPageVisible == 1 && (
              <div className={mainTable.submitCancelMainDiv}>
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={mainTable.submitButton}
                >
                  Previous
                </button>
                <label>
  {itemsPerPage === -1
    ? `${currentPage} out of ${1}`
    : `${currentPage} out of ${Math.ceil(searchData?.length / itemsPerPage)} pages`}
</label>

                <button
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  disabled={currentPage >= searchData?.length / itemsPerPage}
                  className={mainTable.cancelButton}
                >
                  Next
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default GridPage;
