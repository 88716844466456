// Code review - In Progress
// Documentation - In Progress
// import React, { useEffect } from "react";
// import TopMenu from "./TopMenu";
// import SideMenu from "./SideMenu";
// import MobileMenu from "./mobileMenu";

// const MainLayout = ({ children, pageName }) => {
//   return (
//     // <div className="flex flex-col  min-h-screen  overflow-hidden">
//     <div className="flex flex-col  min-h-screen  ">
//       <div className="sticky top-0 z-50">
//         <TopMenu />
//       </div>
//       <div className="flex flex-1 relative h-full">
//         <SideMenu pageName={pageName} />
//         <MobileMenu pageName={pageName} />
//         <div class="lg:flex-1 md:flex-1 lg:mx-8 overflow-y-auto m-4 w-full">
//           {children}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainLayout;

import React from "react";
import TopMenu from "./TopMenu";
import SideMenu from "./SideMenu";
import MobileMenu from "./mobileMenu";

const MainLayout = ({ children, pageName }) => {
  return (
    <div className="w-full h-screen flex flex-col">
      {/* Fixed Top Menu */}
      <div className="w-full flex-none">
        <TopMenu />
      </div>

      {/* Main Content Area with Fixed Side Menu */}
      <div className="flex-1 flex overflow-hidden">
        {/* Side Menu - Desktop */}
        <div className="hidden lg:block flex-none h-full">
          <SideMenu pageName={pageName} />
        </div>

        {/* Mobile Menu */}
        <div className="lg:hidden">
          <MobileMenu pageName={pageName} />
        </div>

        {/* Scrollable Content Area */}
        <div className="flex-1 overflow-auto p-4">
          <div className="min-w-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
