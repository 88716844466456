import React, { createContext, useContext, useEffect } from "react";
import { useUserCapabilitiesReducer } from "../components/ConstantReducer";

const UserCapabilityContext = createContext();

export const UserCapabilityProvider = ({ children }) => {
  const [userProfileState, dispatch] = useUserCapabilitiesReducer();

  return (
    <UserCapabilityContext.Provider value={{ userProfileState, dispatch }}>
      {children}
    </UserCapabilityContext.Provider>
  );
};

export const useUserCapability = () => useContext(UserCapabilityContext);
