import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import {
  CSESummaryHeader,
  ProfileCATClientsReportHeader,
} from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import ReportRowRowPage from "../components/ReportGridRowPage";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ReportIECTable from "../components/ReportIECTable";

const ReportCSESummary = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");

  const [branchProjectError, setBranchProjectError] = useState("");

  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindCSESummaryData, setBindCSESummaryData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [bindMonthDD, setBindMonthDD] = useState([]);
  const [bindYearDD, setBindYearDD] = useState([]);

  const [cseSummaryFields, setCSESummaryFields] = useState({
    month: "",
    year: "",
  });

  const [monthError, setMonthError] = useState("");

  const [yearError, setYearError] = useState("");
  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [yearName, setYearName] = useState("");

  useEffect(() => {
    if (cseSummaryFields.month) {
      // Find the object with the matching value in bindMonthDD
      const monthObj = bindMonthDD?.find(
        (item) => item.value === cseSummaryFields.month
      );
      setMonthName(monthObj?.label || ""); // Safely set the label or an empty string
    }

    if (cseSummaryFields.year) {
      // Find the object with the matching value in bindYearDD
      const yearObj = bindYearDD?.find(
        (item) => item.value === cseSummaryFields.year
      );
      setYearName(yearObj?.label || ""); // Safely set the label or an empty string
    }

    console.log("cseSummaryFields", cseSummaryFields);
  }, [cseSummaryFields]);

  const handleCSESummaryFieldsChange = (value, name) => {
    setCSESummaryFields({
      ...cseSummaryFields,
      [name]: value,
    });
  };

  useEffect(() => {
    // Bind data useEffect
    getMonthDDValues();
  }, []);
  const getMonthDDValues = async () => {
    // Month Dropdown binding
    const response = await BindAPI("MasterDataAPI/BindMonth");
    const getTnxMonthId =
      response.data.Table && response.data.Table.length > 0
        ? response.data.Table.map((item) => ({
            value: item.TxnMonthId,
            label: item.MonthName,
          }))
        : [];
    setBindMonthDD(getTnxMonthId);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = Array.from(
      { length: 70 },
      (_, index) => currentYear - index
    );
    let yearDropdown = yearsArray.map((year) => ({
      label: year.toString(),
      value: year,
    }));
    setBindYearDD(yearDropdown);
  }, []);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      setBranchName(userProfileDatas[0]?.LocationName_en);
      setBranchProject([userProfileDatas[0]?.MstLocationId]);
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    debugger;
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const monthValidate = cseSummaryFields.month ? "" : "Select Month";
    setMonthError(monthValidate);

    const yearValidate = cseSummaryFields.year ? "" : "Select Year";
    setYearError(yearValidate);

    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");

    if (!branchProjectValidate && !monthValidate && !yearValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,

          month: cseSummaryFields.month,
          year: cseSummaryFields.year,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "CSEInSchoolAPI/ReportCSESummary"
      );

      console.log("response", response.data.Table);
      setBindCSESummaryData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };

  const handleExport = () => {
    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      monthData: monthName,
      yearData: yearName,
    };

    exportData(
      CSESummaryHeader.map((column) => column.title),
      bindCSESummaryData,
      selectedOptionsData
    );
  };

  const exportData = (columns, rows, selectedDetails) => {
    const titleRow = [
      {
        v: "CSE Summary",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Month:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.monthData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Year:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.yearData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow1 = [
      {
        v: "S.No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Branch",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Module Name",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Gender",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "A. In School/College",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },

      {
        v: "B. Out of School youth",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },

      {
        v: "Cumulative",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "3A3A3A" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    const headerRow2 = [
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "10-14",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "15-19",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "20-24",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "10-14",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "15-19",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "20-24",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
    ];
    const columnTotals = {
      InSchool_10_14: 0,
      InSchool_15_19: 0,
      InSchool_20_24: 0,
      OutOfSchool_10_14: 0,
      OutOfSchool_15_19: 0,
      OutOfSchool_20_24: 0,
      Cumulative: 0,
    };

    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.InSchool_10_14 || 0) +
        Number(rowData.InSchool_15_19 || 0) +
        Number(rowData.InSchool_20_24 || 0) +
        Number(rowData.OutOfSchool_10_14 || 0) +
        Number(rowData.OutOfSchool_15_19 || 0) +
        Number(rowData.OutOfSchool_20_24 || 0) +
        Number(rowData.Cumulative || 0);

      // Add to column totals
      columnTotals.InSchool_10_14 += Number(rowData.InSchool_10_14 || 0);
      columnTotals.InSchool_15_19 += Number(rowData.InSchool_15_19 || 0);
      columnTotals.InSchool_20_24 += Number(rowData.InSchool_20_24 || 0);
      columnTotals.OutOfSchool_10_14 += Number(rowData.OutOfSchool_10_14 || 0);
      columnTotals.OutOfSchool_15_19 += Number(rowData.OutOfSchool_15_19 || 0);
      columnTotals.OutOfSchool_20_24 += Number(rowData.OutOfSchool_20_24 || 0);
      columnTotals.Cumulative += Number(rowData.Cumulative || 0);

      return [
        {
          v: rowData.RowNumber,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.ModuleName,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Gender,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData.InSchool_10_14 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.InSchool_15_19 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.InSchool_20_24 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OutOfSchool_10_14 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OutOfSchool_15_19 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.OutOfSchool_20_24 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Cumulative || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.InSchool_10_14, t: "n", s: totalsRowStyle },
      { v: columnTotals.InSchool_15_19, t: "n", s: totalsRowStyle },
      { v: columnTotals.InSchool_20_24, t: "n", s: totalsRowStyle },
      { v: columnTotals.OutOfSchool_10_14, t: "n", s: totalsRowStyle },
      { v: columnTotals.OutOfSchool_15_19, t: "n", s: totalsRowStyle },
      { v: columnTotals.OutOfSchool_20_24, t: "n", s: totalsRowStyle },
      { v: columnTotals.Cumulative, t: "n", s: totalsRowStyle },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows
    // const wsData = [titleRow, headerRow1, headerRow2, ...dataRows, totalsRow];

    const wsData = [
      titleRow,
      branchRow,
      spacingRow,
      headerRow1,
      headerRow2,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Add merge cells configuration
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, //title
      { s: { r: 3, c: 0 }, e: { r: 4, c: 0 } }, // Merge "S.No"
      { s: { r: 3, c: 1 }, e: { r: 4, c: 1 } }, // Merge  "Module Name" cells
      { s: { r: 3, c: 2 }, e: { r: 4, c: 2 } }, // Merge "Gender" column
      { s: { r: 3, c: 3 }, e: { r: 4, c: 3 } }, // Merge "Gender" column
      { s: { r: 3, c: 4 }, e: { r: 3, c: 6 } }, // Merge " In School/College" column
      { s: { r: 3, c: 7 }, e: { r: 3, c: 9 } }, // Merge Out of School youth
      { s: { r: 3, c: 10 }, e: { r: 4, c: 10 } }, // Merge Cumulative
      { s: { r: 3, c: 11 }, e: { r: 4, c: 11 } }, // Merge  Row Total columns
    ];
    // ws["!merges"] = [
    //   // Merge Age columns
    //   { s: { r: 1, c: 3 }, e: { r: 1, c: 5 } },
    //   // Merge Gender columns
    //   { s: { r: 1, c: 6 }, e: { r: 1, c: 8 } },
    //   // Merge title cell if needed
    //   { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    //   // Merge all single-row headers
    //   ...Array.from({ length: 27 }, (_, i) => ({
    //     s: { r: 1, c: i },
    //     e: { r: 2, c: i },
    //   })),

    //   // Merge Row Total
    //   { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } },
    // ];
    // Define consistent column widths for all columns (adjust as needed)
    ws["!cols"] = [
      { width: 8 },
      { width: 10 },
      { width: 30 },
      { width: 10 },
      { width: 16 },
      { width: 30 },
      { width: 20 },
      { width: 15 },
      { width: 12 },
      { width: 12 },
      { width: 12 },
      { width: 15 },
    ];

    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header rows
    // [headerRow1, headerRow2].forEach((headerRow, headerIndex) => {
    //   headerRow.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: headerIndex + 1,
    //       c: colIndex,
    //     });
    //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
    //   });
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 3,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 3,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "CSE_Summary_Report.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure iecGDReportHeader is an array of objects with a title property
    // const headers = iecGDReportHeader.map((column) => column.title);
    const headers = [
      { content: "S.No.", rowSpan: 2 },
      { content: "Branch", rowSpan: 2 },
      { content: "Module Name", rowSpan: 2 },
      { content: "Gender", rowSpan: 2 },
      { content: "A. In School/College", colSpan: 3 },
      { content: "B. Out of School youth", colSpan: 3 },
      { content: "Cumulative", rowSpan: 2 },
    ];
    const subHeaders = ["10-14", "15-19", "20-24", "10-14", "15-19", "20-24"];
    // Map bindReportMonthlyEvents to the appropriate format for autoTable
    const data = bindCSESummaryData.map((rowData, index) => {
      return [
        rowData.RowNumber,
        rowData.Branch,
        rowData.ModuleName,
        rowData.Gender,
        rowData.InSchool_10_14,
        rowData.InSchool_15_19,
        rowData.InSchool_20_24,
        rowData.OutOfSchool_10_14,
        rowData.OutOfSchool_15_19,
        rowData.OutOfSchool_20_24,
        rowData.Cumulative,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers, subHeaders],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Age
        7: { cellWidth: "auto" }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("CSE_Summary_Report.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const brangeChange = (updatedOptions) => {
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`CSE Summary`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="schoolName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <AutoDropDown
                  classStar="text-red-500"
                  title="Month"
                  options={bindMonthDD}
                  placeholder="Select"
                  name="month"
                  selectedOption={bindMonthDD.find(
                    (x) => x.value == cseSummaryFields.month
                  )}
                  Class="custom-class"
                  tabIndex="1"
                  controlFunc={(value, e) => {
                    handleCSESummaryFieldsChange(value.value, e.name);
                  }}
                  controlFuncBlur={() => {
                    setMonthError(cseSummaryFields.month ? "" : "Select Month");
                  }}
                  errorMessage={monthError}
                />
              </div>

              <div>
                <AutoDropDown
                  classStar="text-red-500"
                  title="Year"
                  options={bindYearDD}
                  placeholder="Select"
                  name="year"
                  selectedOption={bindYearDD.find(
                    (x) => x.value == cseSummaryFields.year
                  )}
                  Class="custom-class"
                  tabIndex="1"
                  controlFunc={(value, e) => {
                    handleCSESummaryFieldsChange(value.value, e.name);
                  }}
                  controlFuncBlur={() => {
                    setYearError(cseSummaryFields.year ? "" : "Select Year");
                  }}
                  errorMessage={yearError}
                />
              </div>
            </div>
            <div>
              <div class="flex justify-center items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindCSESummaryData && bindCSESummaryData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=" "
                header={CSESummaryHeader}
                records={bindCSESummaryData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ReportCSESummary;
