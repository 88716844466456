// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import { cityvillageMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import CityVillagePopup from "../popup-containers/CityVillagePopup";
import MainPopup from "../popup-containers/MainPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const CityVillageMaster = () => {
  const location1 = useLocation();
  //All bind dropdown value and bind data value useState hooks handling.

  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindCityMasterDatas, setBindCityMasterDatas] = useState([]);
  const [getDistrictDatas, setGetDistrictDatas] = useState([]);
  const [bindDistrict, setBindDistrict] = useState([]);
  const [bindDistrict1, setBindDistrict1] = useState([]);
  const [getBlockDatas, setGetBlockDatas] = useState([]);
  const [bindBlock, setBindBlock] = useState([]);
  const [bindBlock1, setBindBlock1] = useState([]);

  //All visible functionality useState hooks handling.
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  // SDP Master insert and edit useState hooks handling .
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [blockError, setBlockError] = useState("");

  const [districtError, setDistrictError] = useState("");
  const [mstCityVillageId, setMSTCityVillageId] = useState("");

  const createdBy = sessionStorage.getItem("createdBy");

  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [blockMasterDatas, setBlockMasterData] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    CityMasterData();
    setState("");
    setDistrict("");
    setDistrictError("");
    setStateError("");
    setBlock("");
    setBlockError("");
    setCity("");
    setCityError("");
  };

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        setBindStateDropDown(statesData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    dropDownDatas();
    CityMasterData();
    getSelectedDistrict();
  }, []);
  const CityMasterData = async () => {
    const response = await BindAPI("CityVillageAPI/BindCityVillageMaster");
    setBindCityMasterDatas(response.data.Table);
  };
  const getSelectedDistrict = async () => {
    const locationResponse = await BindAPI("IECAPI/BindDistrict");
    setGetDistrictDatas(locationResponse.data.Table);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    setState(bindCityMasterDatas[rowIndex].MstStateId);
    setDistrict(bindCityMasterDatas[rowIndex].MstDistrictId);
    setMSTCityVillageId(bindCityMasterDatas[rowIndex].MstCityId);
    setBlock(bindCityMasterDatas[rowIndex].MstBlockId);
    setCity(bindCityMasterDatas[rowIndex].City_en);
  };

  const handleBlock = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setBlockMasterData(bindCityMasterDatas[rowIndex]);
    const CityData = bindCityMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === CityData.MstStateId
    );
    const editStateDropDownValue = {
      value: CityData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };
    //State DropDown Value End

    //District DropDown Value Start
    const DistrictDropDownValue = bindDistrict1.find(
      (item) => item.value === CityData.MstDistrictId
    );
    const editDistrictDropDownValue = {
      value: CityData.MstDistrictId,
      label: DistrictDropDownValue ? DistrictDropDownValue.label : "Unknown",
    };
    //District DropDown Value End

    //Block DropDown Value Start
    const BlockDropDownValue = bindBlock1.find(
      (item) => item.value === CityData.MstBlockId
    );
    const editBlockDropDownValue = {
      value: CityData.MstBlockId,
      label: BlockDropDownValue ? BlockDropDownValue.label : "Unknown",
    };
    //Block DropDown Value End

    const autoDropDownValues = [
      editStateDropDownValue,
      editDistrictDropDownValue,
      editBlockDropDownValue,
    ];
    setPopUpDropDownData(autoDropDownValues);
  };

  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let districtValidate = "";
      let blockValidate = "";
      let cityValidate = "";

      cityValidate = city ? "" : "Enter City";

      stateValidate = state ? "" : "Select a State";
      districtValidate = district ? "" : "Select a District";
      blockValidate = block ? "" : "Select a Block";
      setBlockError(blockValidate);
      setStateError(stateValidate);
      setDistrictError(districtValidate);
      setCityError(cityValidate);
      if (stateValidate || districtValidate || blockValidate || cityValidate) {
        setSubmitBtnDisabled(false);
        setIsOpen(true);
      }
      // Validation end

      if (
        !stateValidate &&
        !districtValidate &&
        !blockValidate &&
        !cityValidate
      ) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(city, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const data = [
          {
            mstCityId: mstCityVillageId ? mstCityVillageId : 0,
            mstCountryId: 1,
            mstStateId: state,
            mstDistrictId: district,
            mstBlockId: block,
            city_en: city,
            city_ta: translatedTexts[0],
            city_hi: translatedTexts[1],
            city_mr: translatedTexts[2],
            city_gu: translatedTexts[3],
            city_kn: translatedTexts[4],
            city_ml: translatedTexts[5],
            city_te: translatedTexts[6],
            city_bn: translatedTexts[7],
            city_or: translatedTexts[8],
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "CityVillageAPI/IUDCityVillage");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("City / Village data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add City / Village data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("City / Village data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update City / Village data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = [
        {
          mstCityId: bindCityMasterDatas[items].MstCityId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "CityVillageAPI/IUDCityVillage");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("City / Village data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete City / Village data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("City / Village data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block City / Village data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("City / Village data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock City / Village data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location1.pathname == "/cityvillagemaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
    }
  }, [location1]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getDistrictDatas.find(
      (item) => item.MstStateId == state // Replace with the desired MstStateId
    );
    setBindDistrict([]);
    if (stateSelectedValue) {
      const districtLabels = getDistrictDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstDistrictId: item.MstDistrictId,
          District_en: item.District_en,
        }));

      const districtSelected = districtLabels.map((LC) => ({
        value: LC.MstDistrictId, // Replace with the appropriate value
        label: LC.District_en,
      }));
      setBindDistrict(districtSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("IECAPI/BindDistrict");
        const blockResponse = await BindAPI("IECAPI/BindBlock");

        const DistrictData = statesResponse.data.Table.map((item) => ({
          value: item.MstDistrictId,
          label: item.District_en,
        }));

        const blockResponseDD = blockResponse.data.Table.map((item) => ({
          value: item.MstBlockId,
          label: item.Block_en,
        }));
        setBindBlock1(blockResponseDD);
        setBindDistrict1(DistrictData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };
    const getSelectedBlock = async () => {
      const locationResponse = await BindAPI("IECAPI/BindBlock");
      setGetBlockDatas(locationResponse.data.Table);
    };
    dropDownDatas();
    getSelectedBlock();
  }, []);

  useEffect(() => {
    const BlockSelectedValue = getBlockDatas.find(
      (item) => item.MstDistrictId == district // Replace with the desired MstStateId
    );
    setBindBlock([]);
    if (BlockSelectedValue) {
      const blockLabels = getBlockDatas
        .filter(
          (item) => item.MstDistrictId === BlockSelectedValue.MstDistrictId
        )
        .map((item) => ({
          MstBlockId: item.MstBlockId,
          Block_en: item.Block_en,
        }));

      const blockSelected = blockLabels.map((SD) => ({
        value: SD.MstBlockId, // Replace with the appropriate value
        label: SD.Block_en,
      }));
      setBindBlock(blockSelected);
    } else {
    }
  }, [district]);

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={"City / Village Master"}
          header={cityvillageMasterHeader}
          records={bindCityMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleBlock}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class="border-black">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="City / Village Master"
            />
          </div>
          <div class="mx-auto md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title={"Country"}
                      options={[]}
                      selectedOption={country}
                      placeholder={"Select"}
                      name={"country"}
                      controlFunc={(value) => {
                        setCountry(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindStateDropDown}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDown.find(
                        (x) => x.value == state
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBlock("");
                        setDistrict("");
                        setState(value.value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select a State");
                      }}
                      errorMessage={stateError}
                    />

                    <AutoDropDown
                      classStar="text-red-500"
                      title="District"
                      options={bindDistrict}
                      placeholder="Select"
                      name="District"
                      selectedOption={
                        district
                          ? bindDistrict.find((x) => x.value == district)
                          : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBlock("");
                        setDistrict(value.value);
                        setDistrictError(value ? "" : "Select a District");
                      }}
                      controlFuncBlur={() => {
                        setDistrictError(district ? "" : "Select a District");
                      }}
                      errorMessage={districtError}
                    />

                    <AutoDropDown
                      classStar="text-red-500"
                      title="Block"
                      options={bindBlock}
                      placeholder="Select"
                      name="block"
                      selectedOption={
                        block ? bindBlock.find((x) => x.value == block) : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setBlock(value.value);
                        setBlockError(value ? "" : "Select a Block");
                      }}
                      controlFuncBlur={() => {
                        setBlockError(block ? "" : "Select a Block");
                      }}
                      errorMessage={blockError}
                    />

                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title="City / Village"
                      name={"city"}
                      maxLength={100}
                      content={city}
                      controlFunc={(e) => setCity(e.target.value)}
                      // controlFuncBlur={(e) => {
                      //   setCity(e.target.value);
                      //   setCityError(city.length > 0 ? "" : " Enter City");
                      // }}
                      controlFuncBlur={() => {
                        const trimmedValue = city?.trim(); // Remove leading/trailing spaces
                        setCityError(
                          trimmedValue.length > 0 ? "" : " Enter City"
                        );

                        setCity(trimmedValue);

                      }}
                      errorMessage={cityError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleBlock}
      >
        <div>
          <PopUpScreenTitle
            title="City / Village Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <CityVillagePopup data={blockMasterDatas} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default CityVillageMaster;
