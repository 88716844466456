import React, { useState } from "react";

import { mainTable } from "../css/Common";

const ReportTableGrid = ({
  tableHeight,

  headerData,

  tableData,

  onViewGrid,

  currentPage,

  itemsPerPage,

  viewBag,

  isGenderBased,
}) => {
  debugger;
  const [startIndex, setStartIndex] = useState(0);

  const [viewsAll, setViewsAll] = useState(false);

  const showPopUp = (views, rowIndex) => {
    setViewsAll(views);
  };

  const renderCategorizedList = () => {
    return (
      <div className={mainTable.table}>
        {tableData.map((branchData, branchIndex) => (
          <div key={branchIndex}>
            {/* Render Disability Categories and Counts */}

            {headerData.map((header, index) => {
              if (
                header.key !== "BranchName" &&
                header.key !== "SDP" &&
                header.key !== "Area" &&
                header.key !== "TotalClient"
              ) {
                const label = header.title; // Use the title property for the label

                const value = branchData[header.key]; // Use the key to fetch the value from branchData

                return (
                  <div key={index} className={mainTable.tableRow}>
                    <div
                      className={`${headerData[0].width} px-2 py-3 text-left`}
                    >
                      <label className="text-sm font-medium">
                        {branchData.BranchName}
                      </label>
                    </div>
                    <div
                      className={`${headerData[0].width} px-2 py-3 text-left`}
                    >
                      <label className="text-sm font-medium">
                        {branchData.SDP}
                      </label>
                    </div>
                    <div
                      className={`${headerData[0].width} px-2 py-3 text-left`}
                    >
                      <label className="text-sm font-medium">
                        {branchData.Area}
                      </label>
                    </div>
                    <div
                      className={`${headerData[1].width} px-2 py-3 text-left`}
                    >
                      <label className="text-sm whitespace-pre-line font-medium">
                        {label}
                      </label>
                    </div>

                    <div
                      className={`${headerData[2].width} px-2 py-3 text-left`}
                    >
                      <label className="text-sm whitespace-pre-line font-medium">
                        {value}
                      </label>
                    </div>
                  </div>
                );
              }

              return null;
            })}

            {/* Render Total Row */}

            <div className={mainTable.tableRow}>
              <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                <label className="text-sm font-medium">
                  {branchData.BranchName}
                </label>
              </div>

              <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                <label className="text-sm font-medium">{branchData.SDP}</label>
              </div>

              <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                <label className="text-sm font-medium">{branchData.Area}</label>
              </div>

              <div className={`${headerData[1].width} px-2 py-3 text-left`}>
                <label className="text-sm whitespace-pre-line font-medium">
                  Total Marginalized and Excluded
                </label>
              </div>

              <div className={`${headerData[2].width} px-2 py-3 text-left`}>
                <label className="text-sm font-medium">
                  {/* Sum up all disability counts for the total */}

                  {headerData

                    .filter(
                      (header) =>
                        header.key !== "BranchName" &&
                        header.key !== "SDP" &&
                        header.key !== "Area" &&
                        header.key !== "TotalClient"
                    )

                    .reduce(
                      (total, header) => total + (branchData[header.key] || 0),

                      0
                    )}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTableRows = () => {
    return tableData.map((rowData, index) => (
      <React.Fragment key={rowData.MstServicesCategoryId}>
        {/* Render for Male */}

        <div className={mainTable.tableRow}>
          <div className={`${headerData[0].width} px-2 py-3`}>{index + 1}</div>

          <div className={`${headerData[1].width} px-2 py-3`}>
            {rowData.Branch}
          </div>

          <div className={`${headerData[2].width} px-2 py-3`}>
            {rowData.SDP}
          </div>

          <div className={`${headerData[3].width} px-2 py-3`}>
            {rowData.Area}
          </div>

          <div className={`${headerData[4].width} px-2 py-3`}>
            {rowData.ServicesCategory_en}
          </div>

          <div className={`${headerData[5].width} px-2 py-3`}>Male</div>

          {headerData.slice(4).map((ageItem, ageIndex) => (
            <div key={ageIndex} className={`${ageItem.width} px-2 py-3`}>
              {rowData[`Male${ageItem.key}`]}
            </div>
          ))}
        </div>

        {/* Render for Female */}

        <div className={mainTable.tableRow}>
          <div className={`${headerData[0].width} px-2 py-3`}></div>

          <div className={`${headerData[1].width} px-2 py-3`}></div>

          <div className={`${headerData[2].width} px-2 py-3`}></div>

          <div className={`${headerData[3].width} px-2 py-3`}></div>

          <div className={`${headerData[4].width} px-2 py-3`}></div>

          <div className={`${headerData[5].width} px-2 py-3`}>Female</div>

          {headerData.slice(4).map((ageItem, ageIndex) => (
            <div key={ageIndex} className={`${ageItem.width} px-2 py-3`}>
              {rowData[`Female${ageItem.key}`]}
            </div>
          ))}
        </div>

        {/* Render for Other Gender */}

        <div className={mainTable.tableRow}>
          <div className={`${headerData[0].width} px-2 py-3`}></div>

          <div className={`${headerData[1].width} px-2 py-3`}></div>

          <div className={`${headerData[2].width} px-2 py-3`}></div>

          <div className={`${headerData[3].width} px-2 py-3`}></div>

          <div className={`${headerData[4].width} px-2 py-3`}></div>

          <div className={`${headerData[5].width} px-2 py-3`}>Other</div>

          {headerData.slice(4).map((ageItem, ageIndex) => (
            <div key={ageIndex} className={`${ageItem.width} px-2 py-3`}>
              {rowData[`Other${ageItem.key}`]}
            </div>
          ))}
        </div>
      </React.Fragment>
    ));
  };

  const renderMobileView = () => {
    return (
      <div className={mainTable.tableMobile} role="table">
        <div className="w-full md:overflow-y-auto lg:h-96 h-full">
          {tableData.length > 0 &&
            tableData.map((data, rowIndex) => (
              <div key={rowIndex} className={mainTable.mobileTable}>
                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">S.No.</div>

                  <div className="flex basis-1/2">{rowIndex + 1}</div>
                </div>

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">Branch</div>

                  <div className="flex basis-1/2">{data.Branch}</div>
                </div>

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">SDP</div>

                  <div className="flex basis-1/2">{data.SDP}</div>
                </div>

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">Area</div>

                  <div className="flex basis-1/2">{data.Area}</div>
                </div>

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">
                    Category-Wise Clients
                  </div>

                  <div className="flex basis-1/2">
                    {data.ServicesCategory_en}
                  </div>
                </div>

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">Gender</div>

                  <div className="flex basis-1/2">Male</div>
                </div>

                {headerData.slice(3).map((ageItem, ageIndex) => (
                  <div key={ageIndex} className="flex border p-1">
                    <div className="flex basis-1/2 font-bold">
                      {ageItem.title}
                    </div>

                    <div className="flex basis-1/2">
                      {data[`Male${ageItem.key}`]}
                    </div>
                  </div>
                ))}

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">Gender</div>

                  <div className="flex basis-1/2">Female</div>
                </div>

                {headerData.slice(3).map((ageItem, ageIndex) => (
                  <div key={ageIndex} className="flex border p-1">
                    <div className="flex basis-1/2 font-bold">
                      {ageItem.title}
                    </div>

                    <div className="flex basis-1/2">
                      {data[`Female${ageItem.key}`]}
                    </div>
                  </div>
                ))}

                <div className="flex border p-1">
                  <div className="flex basis-1/2 font-bold">Gender</div>

                  <div className="flex basis-1/2">Other</div>
                </div>

                {headerData.slice(3).map((ageItem, ageIndex) => (
                  <div key={ageIndex} className="flex border p-1">
                    <div className="flex basis-1/2 font-bold">
                      {ageItem.title}
                    </div>

                    <div className="flex basis-1/2">
                      {data[`Other${ageItem.key}`]}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className={mainTable.tableShadowWidth}>
      <div className={mainTable.table} role="table">
        <div
          className={`overflow-x-auto ${isGenderBased ? "w-full" : "w-fit"}`}
        >
          <div className={mainTable.tableHeader} role="rowgroup">
            {isGenderBased ? (
              <>
                <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                  <label className="text-sm font-medium">Branch</label>
                </div>
                <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                  <label className="text-sm font-medium">SDP</label>
                </div>
                <div className={`${headerData[0].width} px-2 py-3 text-left`}>
                  <label className="text-sm font-medium">Area</label>
                </div>
                <div className={`${headerData[0].width} px-2 whitespace-pre-line py-3 text-left`}>
                  <label className="text-sm font-medium">
                    Marginalized and Excluded Clients
                  </label>
                </div>

                <div className={`${headerData[1].width} px-2 py-3 text-left`}>
                  <label className="text-sm font-medium">No.</label>
                </div>
              </>
            ) : (
              headerData.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className={`${item.width} px-2 py-3 text-left`}
                  role="columnheader"
                >
                  <label className="text-sm font-medium">{item.title}</label>
                </div>
              ))
            )}
          </div>

          <div className={`overflow-y-auto h-96`}>
            {isGenderBased ? renderCategorizedList() : renderTableRows()}
          </div>
        </div>
      </div>

      {/* {isGenderBased ? "" : renderMobileView()} */}

      {/* {renderMobileView()} */}

      {isGenderBased ? (
        <div className={mainTable.tableMobile} role="table">
          <div className="w-full md:overflow-y-auto lg:h-96 h-full">
            {tableData.length > 0 &&
              tableData.map((data, rowIndex) => (
                <div key={rowIndex} className={mainTable.mobileTable}>
                  {headerData.map((keyData) => (
                    <div
                      key={keyData.key}
                      className={mainTable.tableData}
                      role="cell"
                    >
                      {keyData.key === "action" || keyData.key === "action1" ? (
                        <div className={mainTable.tableData}>
                          <div className="md:block hidden">
                            <button
                              id="dropdownMenuIconButton"
                              data-dropdown-toggle="dropdownDots"
                              className="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none"
                              type="button"
                              onClick={() => showPopUp(!viewsAll, rowIndex)}
                            >
                              <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                              </svg>
                            </button>
                          </div>

                          <div className="md:hidden block flex justify-center">
                            {viewBag === 1 && (
                              <button
                                className="bg-[#213F99] text-white mr-1 p-1 rounded-lg"
                                onClick={() =>
                                  onViewGrid(
                                    startIndex +
                                      rowIndex +
                                      (currentPage - 1) * itemsPerPage,

                                    1
                                  )
                                }
                              >
                                View
                              </button>
                            )}
                          </div>
                        </div>
                      ) : keyData.title === "S.No." ? (
                        <div className="flex border p-1">
                          <div className="flex basis-1/2 font-bold">
                            {keyData.title}
                          </div>

                          <div>
                            {startIndex +
                              rowIndex +
                              1 +
                              (currentPage - 1) * itemsPerPage}
                          </div>
                        </div>
                      ) : (
                        <div className="flex border p-1">
                          <div className="flex basis-1/2 font-bold">
                            {keyData.title}
                          </div>

                          <div className="flex basis-1/2">
                            {data[keyData.key]}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      ) : (
        renderMobileView()
      )}
    </div>
  );
};

export default ReportTableGrid;
