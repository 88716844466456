// Code review - In Progress
// Documentation - In Progress

import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputPassword from "../ReusableComponents/InputPassword";
import { IUDAPI } from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";

const PasswordPopup = ({ closePopup }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");

  const resetForm = () => {
    closePopup(true);
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmPasswordError("");
    setNewPasswordError("");
    setNewPassword("");
  };

  const handlePasswordChange = async () => {
    try {
      // Your additional client-side validation logic can be added here

      // Simple validation for demonstration purposes
      if (
        password.length === 0 ||
        newPassword.length === 0 ||
        confirmPassword.length === 0
      ) {
        setPasswordError("Enter Password");
        setNewPasswordError("Enter new password");
        setConfirmPasswordError("Enter confirm password");
        return;
      }

      if (newPassword !== confirmPassword) {
        setNewPasswordError("Passwords do not match");
        setConfirmPasswordError("Passwords do not match");
        return;
      }

      const data = [
        {
          mstUserId: sessionStorage.getItem("createdBy"),
          oldPassword: password,
          newPassword: newPassword,
        },
      ];

      const passwordChangeResponse = await IUDAPI(
        data,
        "UserAPI/PasswordChange"
      );

      if (
        passwordChangeResponse.data.Table[0].Result ===
        "Password updated successfully."
      ) {
        toast.success(
          "Password Reset Completed. You can now use your new password.",
          {
            duration: 2000,
            position: "top-center",
          }
        );
        // toast.success("Password Reset Completed ", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        resetForm();
      } else {
        toast.error("Password Reset Not Completed", {
          duration: 2000,
          position: "top-center",
        });
        // toast.error("Password Reset Not Completed", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        resetForm();
      }
    } catch (error) {
      console.error("Error changing password:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div class="w-full">
     
      <div class="text-xl mx-6 font-medium text-neutral-700  ">
        {"Reset Password"}
      </div>
      <div class={`text-center text-base px-2 text-neutral-700`}>
        {"Enter your old password to reset your password"}
      </div>
      <form class={"mx-4"}>
        <div class=" flex flex-col w-full">
          <div class="grid gap-4 mb-6">
            <InputPassword
              title="Old Password"
              name="password"
              placeholder="Enter your password"
              content={password}
              controlFunc={(e) => setPassword(e.target.value)}
              controlFuncBlur={(e) => {
                setPassword(e.target.value);
                setPasswordError(
                  password.length > 0 ? "" : "Enter Old Password"
                );
              }}
            />
            {passwordError && (
              <div style={{ color: "red" }}>{passwordError}</div>
            )}

            <InputPassword
              title="New Password"
              name="password"
              placeholder="Enter your password"
              content={newPassword}
              controlFunc={(e) => setNewPassword(e.target.value)}
              controlFuncBlur={(e) => {
                setNewPassword(e.target.value);
                setNewPasswordError(
                  newPassword.length > 0 ? "" : "Enter new password"
                );
              }}
            />
            {newPasswordError && (
              <span class="text-red-500">{newPasswordError}</span>
            )}

            <InputPassword
              title="Confirm Password"
              name="password"
              placeholder="Enter your password"
              content={confirmPassword}
              controlFunc={(e) => setConfirmPassword(e.target.value)}
              controlFuncBlur={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(
                  confirmPassword.length > 0 ? "" : "Enter confirm password"
                );
              }}
            />
            {confirmPasswordError && (
              <span class="text-red-500">{confirmPasswordError}</span>
            )}

            <div
              class={
                "flex justify-center p-3 space-x-2 items-baseline border-gray-200 rounded-b"
              }
            >
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.submitButton}
                onClick={handlePasswordChange}
              >
                Change Password
              </button>

              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.cancelButton}
                onClick={resetForm}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PasswordPopup;
