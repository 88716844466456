// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Checkbox from "../ReusableComponents/Checkbox";
import RadioGroup from "../ReusableComponents/RadioButton";

const Service = ({ data, data1, data2 }) => {
  const [testResultTypeDD, setTestResultTypeDD] = useState([]);
  const [serviceProvisionDD, setServiceProvisionDD] = useState([]);

  useEffect(() => {
    const Dropdowndetails = () => {
      binddrptestResultdata(data2[0]);
      binddrpServiceProvisiondata(data2[1]);
    };
    Dropdowndetails();
  }, [data2]);

  const binddrptestResultdata = async (TestResultvalues) => {
    try {
      if (Array.isArray(TestResultvalues)) {
        const getTestResultLabels = TestResultvalues.map((item) => ({
          value: item.value,
          label: item.label,
        }));
        setTestResultTypeDD(getTestResultLabels);
      } else {
        console.error("data2 is not an array");
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };

  const binddrpServiceProvisiondata = async (ServiceProvisionvalues) => {
    try {
      if (Array.isArray(ServiceProvisionvalues)) {
        const getServiceProvisionLabels = ServiceProvisionvalues.map(
          (item) => ({
            value: item.value,
            label: item.label,
          })
        );
        setServiceProvisionDD(getServiceProvisionLabels);
      } else {
        console.error("data2 is not an array");
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };

  const [itemsprovidedDD, setItemsprovidedDD] = useState([
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ]);
  const [renewalSupplyDD, setRenewalSupplyDD] = useState([
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ]);
  return (
    <div class="w-full ">
      <div className={mainTable.addShadowScreen}>
        <div className={mainTable.mainContentScreen}>
          <form class="overflow-auto p-5 md:h-96 min-h-fit max-h-fit ">
            <div className={mainTable.grid3}>
              <AutoDropDown
                classStar="text-red-500"
                title="Category"
                options={[]}
                placeholder="Select"
                name="category"
                selectedOption={data1[1]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
              />
              <AutoDropDown
                classStar="text-red-500"
                title="Sub-Category"
                options={[]}
                placeholder="Select"
                name="subCategory"
                selectedOption={data1[2]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
              />
              <InputText
                classStar="text-red-500"
                inputType={"text"}
                title={"Services"}
                name={"Services"}
                content={data.Services_en}
                disabled="disabled"
              />
              <RadioGroup
                classStar="text-red-500"
                title="Items Provided"
                type="radio"
                setName="itemsProvided"
                selectedOptions={data.ItemsProvided}
                options={itemsprovidedDD}
                disabled="disabled"
              />
              <div className="col-span-2">
                <RadioGroup
                  classStar="text-red-500"
                  title="Test Result Type ?"
                  type={"radio"}
                  setName={"testresulttype"}
                  selectedOptions={data1[0]}
                  options={testResultTypeDD}
                  disabled="disabled"
                />
              </div>
              <div className="col-span-3">
                <Checkbox
                  title="Service Provision"
                  type="Checkbox"
                  setName="serviceProvision"
                  options={serviceProvisionDD}
                  selectedOptions={data1[3]}
                  disabled="disabled"
                  tabIndex="0"
                  Class="grid grid-cols-2 md:grid md:grid-cols-4 lg:grid lg:grid-cols-5 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
                />
              </div>
              <InputText
                inputType={"text"}
                title={"Result UOM"}
                name={"resultUOM"}
                content={data.ResultUOM}
                disabled="disabled"
              />
              <RadioGroup
                classStar="text-red-500"
                title="Renewal Supply"
                type={"radio"}
                setName={"renewalSupply"}
                selectedOptions={data.RenewalSupply}
                options={renewalSupplyDD}
                disabled="disabled"
              />
              <InputText
                classStar="text-red-500"
                inputType={"text"}
                title={"Charges"}
                name={"charges"}
                content={data.Charges}
                disabled="disabled"
              />
             <InputText
                inputType={"text"}
                title={"PAC"}
                name={"pac"}
                content={data.PAC}
                disabled="disabled"
              />
              <InputText
                inputType={"text"}
                title={"FP Dropout"}
                name={"FPDropout"}
                content={data.FPDropout}
                disabled="disabled"
              />
              <InputText
                inputType={"text"}
                title={"CYP"}
                name={"cyp"}
                content={data.CYP}
                disabled="disabled"
              />
                <InputText
                inputType={"text"}
                title={"CYP Conversion Factor"}
                name={"CYPConversionFactor"}
                content={data.CYPConversionFactor}
                disabled="disabled"
              />
              <InputText
                inputType={"text"}
                title={"FP Item Provided"}
                name={"FPItemProvided"}
                content={data.FPItemProvided_en}
                disabled="disabled"
              />
            
            
              <InputText
                inputType={"text"}
                title={"Abortion Clients"}
                name={"abortionClients"}
                content={data.AbortionClients_en}
                disabled="disabled"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Service;
