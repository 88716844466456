// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI } from "../services/APIConnection";
import UsePrevious from "../Hook/UsePrevious";
import DropDown from "../ReusableComponents/DropDown";
import MultiSelect from "../ReusableComponents/MultiSelect";
import GridPage from "../components/GridPage";
import { ServicesAddViewHeader } from "../components/HeaderAttribute";

const AddServices = ({
  data,
  data1,
  data2,
  data3,
  currentData1,
  upDatedTableData,
}) => {
  console.log("data2", data2);
  // Bind data values useStates
  const [bindServicesAddData, setBindServicesAddData] = useState([]);
  const [bindReferralDD, setBindReferralDD] = useState([]);
  const [bindPACServices, setBindPACServices] = useState([]);

  const [bindProject, setBindProject] = useState([]);
  const [bindServiceCategory, setBindServiceCategory] = useState([]);
  const [bindServiceSubCategory, setBindServiceSubCategory] = useState([]);
  const [bindServicesName, setBindServicesName] = useState([]);
  const [getServicesData, setGetServicesDatas] = useState([]);

  const [bindCommodityDD, setBindCommodityDD] = useState([]);
  const [bindCommodityTypeDD, setBindCommodityTypeDD] = useState([]);
  const [bindCommodityNameDD, setBindCommodityNameDD] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);

  const [bindTestResultTypeDD, setBindTestResultTypeDD] = useState([
    { value: "1", label: "+ve" },
    { value: "2", label: "-ve" },
  ]);
  // FPUser dropdown values bind
  const [bindFPUserDD, setBindFPUserDD] = useState([
    { value: "1", label: "F.T.U. " },
    { value: "2", label: "C.U." },
  ]);

  // handleInputChange for Issue Commodity Table
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnName] = value;

      return newData;
    });
  };

  // Issue commodity Table values
  const [tableData, setTableData] = useState([
    {
      commodity: "",
      commodityCategory: "",
      commodityName: "",
      brand: "",
      quantity: "",
      uom: "",
      charges: "",
      commodityCategoryList: [],
      commodityNameList: [],
      brandList: [],
    },
  ]);

  // Issue commodity handleDeleteRow
  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };

  // Issue commodity HandleDelete
  const HandleDelete = (rowIndex) => {
    const updatedTableData = bindServicesAddData.filter(
      (_, index) => index !== rowIndex
    );
    setBindServicesAddData(updatedTableData);
  };

  // Issue commodity handleAddRow
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] };
    newRow.commodity = "";
    newRow.commodityCategory = ""; // Clear the values of the new row
    newRow.commodityName = "";
    newRow.quantity = "";
    newRow.uom = "";
    newRow.charges = "";
    newRow.commodityCategoryList = [];
    newRow.commodityNameList = [];
    newRow.brandList = [];

    setTableData((prevData) => [...prevData, newRow]);
  };

  // Issue commodity dropdown values bind
  useEffect(() => {
    const commodityTypeDD = async () => {
      try {
        const commodityTypeResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        const commodityResponse = await BindAPI(
          "InventoryReceiptAPI/BindCommodityType"
        );

        const brandResponse = await BindAPI("BrandAPI/BindBrand");

        const commodityResponseData = commodityResponse.data.Table.filter(
          (item) =>
            item.MstCommodityTypeId === 2 || item.MstCommodityTypeId === 3
        ).map((item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        }));

        setBindBrandDD(brandResponse.data.Table);

        setBindCommodityDD(commodityResponseData);
        setBindCommodityTypeDD(commodityTypeResponse.data.Table);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    const commodityNameDD = async () => {
      try {
        const commodityNameResponse = await BindAPI(
          "CommoditiesAPI/BindCommodities"
        );
        // setBindCommodityDD(commodityNameResponse.data.Table);

        setBindCommodityNameDD(commodityNameResponse.data.Table);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    commodityTypeDD();
    commodityNameDD();
  }, []);

  // Appeand  Issue commodity table Values
  const previousTableData = UsePrevious(tableData);
  useEffect(() => {
    if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
      const currentTableData = [...tableData];
      currentTableData.forEach((row, index) => {
        if (row?.commodity) {
          const getFilteredCategory = bindCommodityTypeDD
            .filter((item) => item.MstCommodityTypeId == row.commodity)
            .map((items) => ({
              value: items.MstCommodityCategoryId,
              label: items.CommodityCategory_en,
            }));
          row.commodityCategoryList = getFilteredCategory;
        }
        if (row?.commodityCategory) {
          const selectedCommoditiesCategory = bindCommodityNameDD
            .filter(
              (item) => item.MstCommodityCategoryId == row.commodityCategory
            )
            .map((item) => ({
              value: item.MstCommoditiesId,
              label: item.CommodityName_en,
              uom: item.CommodityUOM,
            }));
          row.commodityNameList = selectedCommoditiesCategory;

          const selectedCommodity = selectedCommoditiesCategory.find(
            (item) => item.value === row.commodityName
          );

          if (selectedCommodity) {
            row.uom = selectedCommodity.uom;
          } else {
            row.uom = "";
          }
        }

        if (row?.commodityName) {
          const selectedBrandCategory = bindBrandDD
            .filter((item) => item.MstCommoditiesId == row.commodityName)
            .map((items) => ({
              value: items.MstBrandId,
              label: items.Brand_en,
            }));
          row.brandList = selectedBrandCategory;
        }
      });
      setTableData(currentTableData);
    }
  }, [tableData]);

  // Appeand  Issue commodity table Values
  useEffect(() => {
    if (currentData1.length === 0) {
      setTableData([
        {
          commodity: "",
          commodityCategory: "",
          commodityName: "",
          brand: "",
          quantity: "",
          uom: "",
          charges: "",
          commodityCategoryList: [],
          commodityNameList: [],
          brandList: [],
        },
      ]);
    } else {
      const clearValues = () => {
        setTableData([]);
      };
      clearValues();
      const ParticipantDatas = JSON.stringify(currentData1);
      try {
        const newDataArray = JSON.parse(ParticipantDatas);
        const formattedDataArray = newDataArray.map((data) => ({
          commodity: data.MstCommodityTypeId,
          commodityCategory: data.MstCommodityCategoryId,
          commodityName: data.MstCommodityId,
          brand: data.MstBrandId,
          quantity: data.Quantity,
          uom: data.UOM,
          charges: data.Charges,
        }));

        setTableData((prevData) => [...prevData, ...formattedDataArray]);

        // Call clearValues only when currentData1 has a value
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [currentData1]);

  // handleReferralChange functionality
  const handleReferralChange = (rowIndex) => {};

  // handleServicesProvide functionality
  const handleServicesProvide = (updatedData) => {
    console.log("setServicesProvideDataaaaa", updatedData);
  };

  // FPUser dropdown values bind
  useEffect(() => {
    const ProjectDropDownData = async () => {
      // Project dropdown values bind
      try {
        const projectResponse = await BindAPI("MasterDataAPI/BindProject");

        const ProjectData = projectResponse.data.Table.map((item) => ({
          value: item.MstProjectId,
          label: item.ProjectName_en,
        }));

        setBindProject(ProjectData);
      } catch (error) {}
    };
    const serviceCategoryDD = async () => {
      // Services Category dropdown values bind
      try {
        const serviceCategoryResponse = await BindAPI(
          "ServicesCategoryAPI/BindServicesCategory"
        );

        const serviceCategoryData = serviceCategoryResponse.data.Table.map(
          (item) => ({
            value: item.MstServicesCategoryId,
            label: item.ServicesCategory_en,
          })
        );

        setBindServiceCategory(serviceCategoryData);
      } catch (error) {}
    };

    const ReferredToDD = async () => {
      // ReferredTo dropdown values bind
      try {
        const referredToResponse = await BindAPI(
          "MasterDataAPI/BindReferredTo"
        );
        const bindServicesDropDownsData = await BindAPI(
          "ServicesMasterAPI/BindServicesDropDowns"
        );
        const referredToData = referredToResponse.data.Table.map((item) => ({
          value: item.MstReferredToId,
          label: item.ReferredTo_en,
        }));
        const bindPACServicesDD =
          bindServicesDropDownsData.data.Table1.length > 0 &&
          bindServicesDropDownsData.data.Table1.map((m) => ({
            value: m.MstPACServicesId,
            label: m.PACServices,
          }));
        setBindPACServices(bindPACServicesDD);
        setBindReferralDD(referredToData);
      } catch (error) {}
    };

    const serviceSubCategoryDD = async () => {
      // serviceSubCategory dropdown values bind
      try {
        const serviceSubCategoryResponse = await BindAPI(
          "ServicesSubCategoryAPI/BindServicesSubCategory"
        );

        const serviceSubCategoryData =
          serviceSubCategoryResponse.data.Table.map((item) => ({
            value: item.MstServicesSubCategoryId,
            label: item.ServicesSubCategory_en,
          }));

        setBindServiceSubCategory(serviceSubCategoryData);
      } catch (error) {}
    };

    const servicesDD = async () => {
      // service dropdown values bind
      try {
        const servicesResponse = await BindAPI(
          "ServicesMasterAPI/BindServices"
        );

        const servicesData = servicesResponse.data.Table.map((item) => ({
          value: item.MstServicesId,
          label: item.Services_en,
        }));

        setBindServicesName(servicesData);
      } catch (error) {}
    };

    // Bind service Dropdown datas
    const getSelectedServices = async () => {
      const serviceSubCategoryResponse = await BindAPI(
        "ServicesMasterAPI/BindServices"
      );
      setGetServicesDatas(serviceSubCategoryResponse.data.Table);
    };
    getSelectedServices();
    ReferredToDD();
    serviceCategoryDD();
    ProjectDropDownData();
    serviceSubCategoryDD();
    servicesDD();
  }, []);
  const headerData = [
    {
      title: "Commodity",
      key: "commoditiesCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Commodity Category",
      key: "commoditiesCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Commodity Name",
      key: "commoditiesName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "commoditiesBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "commoditiesQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Charges",
      key: "charges",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  // useEffect(() => {
  //   if (data2 && data2.length > 6) {
  //     const servicesSelectedvalue = data2[6];
  //     const servicesLabels = getServicesData.filter((item) =>
  //       servicesSelectedvalue.includes(item.MstServicesId)
  //     );
  //     if (servicesLabels.length > 0) {
  //       const servicesProvideName = servicesLabels.map((service, index) => ({
  //         key: `${index}`,
  //         MstServicesCategoryId: `${service.MstServicesCategoryId}`,
  //         MstServicesSubCategoryId: `${service.MstServicesSubCategoryId}`,
  //         MstServicesId: `${service.MstServicesId}`,
  //         value: `${service.ServicesCategory_en}-${service.ServicesSubCategory_en}-${service.Services_en}`,
  //         TestResultType: `${service.TestResultType}`,
  //       }));
  //       setBindServicesAddData(servicesProvideName);
  //     } else {
  //       // Handle the case when servicesLabels is empty
  //       console.log("No matching services found.");
  //       setBindServicesAddData([]);
  //     }
  //   } else {
  //     console.log(
  //       "data2 is undefined or does not contain at least 7 elements."
  //     );
  //     // Handle the case when data2 is undefined or does not contain at least 7 elements.
  //   }
  // }, [data2]);

  useEffect(() => {
    if (upDatedTableData) {
      setBindServicesAddData(upDatedTableData);
    } else {
      console.log(
        "data2 is undefined or does not contain at least 7 elements."
      );
      // Handle the case when data2 is undefined or does not contain at least 7 elements.
    }
  }, [upDatedTableData]);

  return (
    <div class="w-full">
      <div class={mainTable.mainContentScreen}>
        <form class={mainTable.formForPopupcontainer}>
          <div className={mainTable.grid2}>
            <div className="flex items-center">
              <label className={mainTable.serviceLabel}>
                Client Id. : &nbsp;
              </label>
              <label className={mainTable.label}>{data.ClientId}</label>
            </div>
            <div className="flex items-center">
              <label className={mainTable.serviceLabel}>
                Client Name : &nbsp;
              </label>
              <label className={mainTable.label}>{data.ClientName_en}</label>
            </div>
            <div className="flex items-center">
              <label className={mainTable.serviceLabel}>
                Mother Name : &nbsp;
              </label>
              <label className={mainTable.label}>
                {data.ClientMotherName_en}
              </label>
            </div>
            <div className="flex items-center">
              <label className={mainTable.serviceLabel}>
                Phone Number : &nbsp;
              </label>
              <label className={mainTable.label}>{data.ClientPhoneNo}</label>
            </div>
          </div>
          <div className={mainTable.grid4}>
            {data2 && data2.length > 0 && (
              <InputText
                inputType="date"
                title="Date"
                classStar="text-red-500"
                name="date"
                content={data2[7]}
              />
            )}
            <InputText
              classStar="text-red-500"
              inputType="text"
              title="Reason For Visit"
              maxLength={30}
              name="reasonForVisit"
              content={data.ReasonForVisit_en}
            />
            {data2 && data2.length > 0 && (
              <AutoDropDown
                classStar="text-red-500"
                title="S.D.P."
                options={[]}
                placeholder="Select"
                name="sdp"
                selectedOption={data2[3]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
              />
            )}
            {data2 && data2.length > 0 && (
              <AutoDropDown
                classStar="text-red-500"
                title="Area"
                options={[]}
                placeholder="Select"
                name="area"
                selectedOption={data2[4]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
              />
            )}

            <DropDown
               
              title="Project"
              classDiv="text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full "
              options={bindProject}
              placeholder="Select"
              name="project"
              selectedOption={data.MstProject}
              disabled="disabled"
            />
            {/* </div>
          <div className={mainTable.grid4}> */}
            {data3 && (
              <DropDown
                classStar="text-red-500"
                title="Service Category"
                options={bindServiceCategory}
                placeholder={"Select"}
                name="serviceCategory"
                class="custom-class"
                tabIndex="1"
                selectedOption={[]}
                disabled="disabled"
              />
            )} 
            {data2 && data2.length > 0 && (
              <MultiSelect
                classStar="text-red-500"
                label="Service Sub-Category"
                filteredOptions={bindServiceSubCategory}
                classFilterLabel="p-1 flex gap-1"
                class="custom-class"
                placeholder={"Select..."}
                name="ServiceSubCategory"
                selectedOptions={[]}
                classDiv="relative w-full inline-block"
                disabled="disabled"
              />
            )}
            {data2 && data2.length > 0 && (
              <MultiSelect
                classStar="text-red-500"
                label="Services"
                filteredOptions={bindServicesName}
                classFilterLabel="p-1 flex gap-1"
                class="custom-class"
                placeholder={"Select..."}
                name="services"
                selectedOptions={[]}
                classDiv="relative w-full inline-block"
                disabled="disabled"
                // onChange={servicesNameChange}
              />
            )}
          </div>

          <div>
            <div>
              <hr class={mainTable.servicesHorizontalLine}></hr>
              <GridPage
                header={ServicesAddViewHeader}
                records={bindServicesAddData}
                bindReferralDD={bindReferralDD}
                handleAction={HandleDelete}
                onReferralGrid={handleReferralChange}
                rowsPerPageView={true}
                onFPUserDD={bindFPUserDD}
                onTestResultTypeDD={bindTestResultTypeDD}
                onServicesProvide={handleServicesProvide}
                addView={true}
                onDisabled={true}
                bindPACServicesDD={bindPACServices}
                xScroll="overflow-x-auto max-md:w-fit w-full"
                tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
              />

              <hr class={mainTable.servicesHorizontalLine}></hr>
              <div className={mainTable.grid2}>
                {data2 && data2.length > 0 && (
                  <InputText
                    inputType="date"
                    title=" Contraceptive renewal supply follow up"
                    name="renewalDate"
                    content={data2[1]}
                    disabled="disabled"
                  />
                )}
                {data2 && data2.length > 0 && (
                  <InputText
                    inputType="date"
                    title=" Referral follow up"
                    name="Referral follow up"
                    content={data2[2]}
                    disabled="disabled"
                  />
                )}
                {/* <InputText
                  classStar="text-red-500"
                  inputType="text"
                  title="Charges"
                  maxLength={30}
                  name="reasonForVisit"
                  content={data.Charges}
                /> */}
              </div>
            </div>
          </div>

          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div class={mainTable.labelBold}>Issued Commodities </div>
          <div className="hidden md:block">
            <table className="w-full border border-gray-300 mt-6">
              <thead>
                <tr>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity Category
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Commodity Name
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Brand
                  </th>

                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Qty.
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    U.O.M.
                  </th>
                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2">
                    Charges
                  </th>
                  {/* <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 w-48">
                      <AutoDropDown
                        Class="Custom-class"
                        disabled="disabled"
                        options={bindCommodityDD}
                        placeholder="Select"
                        name="commodity"
                        selectedOption={bindCommodityDD.find(
                          (x) => x.value == row.commodity
                        )}
                        controlFunc={(e) => {
                          handleInputChange(e, index, "commodity");
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-52">
                      <AutoDropDown
                        Class="Custom-class"
                        disabled="disabled"
                        options={row.commodityCategoryList || []}
                        placeholder="Select"
                        name="commodityCategory"
                        selectedOption={
                          row.commodityCategoryList
                            ? row.commodityCategoryList.find(
                                (x) => x.value === row.commodityCategory
                              )
                            : null
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(
                            selectedOption,
                            index,
                            "commodityCategory"
                          );
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-44">
                      <AutoDropDown
                        Class="Custom-class"
                        options={row.commodityNameList || []}
                        placeholder="Select"
                        disabled="disabled"
                        name="productName"
                        selectedOption={
                          row.commodityNameList
                            ? row.commodityNameList.find(
                                (x) => x.value === row.commodityName
                              )
                            : null
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(
                            selectedOption,
                            index,
                            "commodityName"
                          );
                        }}
                      />
                    </td>
                    <td className="border border-gray-300 p-2 w-40">
                      <AutoDropDown
                        Class="Custom-class"
                        options={row.brandList || []}
                        placeholder="Select"
                        disabled="disabled"
                        name="brand"
                        selectedOption={
                          row.brandList
                            ? row.brandList.find((x) => x.value === row.brand)
                            : null
                        }
                        controlFunc={(selectedOption) => {
                          handleInputChange(selectedOption, index, "brand");
                        }}
                      />
                    </td>

                    <td className="border border-gray-300 p-2 w-20">
                      <InputText
                        type="text"
                        content={row.quantity}
                        disabled="disabled"
                        controlFunc={(e) => {
                          handleInputChange(e.target, index, "quantity");
                        }}
                      />
                    </td>

                    <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                      <InputText
                        type="text"
                        content={row.uom}
                        disabled="disabled"
                        controlFunc={(e) =>
                          handleInputChange(e.target, index, "uom")
                        }
                      />
                    </td>
                    <td className="border border-gray-300 p-1 w-20 font-normal">
                      <InputText
                        type="text"
                        disabled="disabled"
                        content={row.charges}
                        controlFunc={(e) =>
                          handleInputChange(e.target, index, "charges")
                        }
                      />
                    </td>
                    {/* <td className="border border-gray-300 w-16">
                      <div className="flex gap-4 align-baseline items-center justify-center">
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-6 h-6 text-black cursor-pointer"
                          onClick={() => handleDeleteRow(index)}
                        >
                          <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                        </svg>
                        <svg
                          viewBox="0 0 1024 1024"
                          fill="currentColor"
                          onClick={handleAddRow}
                          class="w-6 h-6 text-black cursor-pointer"
                        >
                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                        </svg>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={mainTable.tableMobile} role="table">
            <div className="w-full md:overflow-y-auto lg:h-96 h-full">
              {tableData.length > 0 &&
                tableData.map((row, index) => (
                  <div scope="col" class={mainTable.mobileTable}>
                    {headerData.map((keyData) => (
                      <div scope="cell" class={mainTable.tableData} role="cell">
                        {keyData.key === "Action" ? (
                          <div class={mainTable.tableData}>
                            <div className="md:block hidden">
                              <button
                                id="dropdownMenuIconButton"
                                data-dropdown-toggle="dropdownDots"
                                class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                type="button"
                              >
                                <svg
                                  class="w-6 h-6"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                </svg>
                              </button>
                            </div>
                            <div className="md:hidden flex justify-center">
                              <input
                                type="button"
                                className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                onClick={handleAddRow}
                                value="Add"
                              />
                              <input
                                type="button"
                                className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                onClick={() => handleDeleteRow(index)}
                                value="Delete"
                              />
                            </div>
                          </div>
                        ) : keyData.key === "commoditiesCategoryType" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={bindCommodityDD}
                              placeholder="Select"
                              disabled="disabled"
                              name="commodity"
                              selectedOption={bindCommodityDD.find(
                                (x) => x.value == row.commodity
                              )}
                              controlFunc={(e) => {
                                handleInputChange(e, index, "commodity");
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesCategory" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              options={row.commodityCategoryList || []}
                              placeholder="Select"
                              disabled="disabled"
                              name="commodityCategory"
                              selectedOption={
                                row.commodityCategoryList
                                  ? row.commodityCategoryList.find(
                                      (x) => x.value === row.commodityCategory
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "commodityCategory"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesName" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              disabled="disabled"
                              options={row.commodityNameList || []}
                              placeholder="Select"
                              name="productName"
                              selectedOption={
                                row.commodityNameList
                                  ? row.commodityNameList.find(
                                      (x) => x.value === row.commodityName
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "commodityName"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesBrand" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full font-bold">
                              {keyData.title}
                            </div>
                            <AutoDropDown
                              Class="Custom-class"
                              disabled="disabled"
                              options={row.brandList || []}
                              placeholder="Select"
                              name="brand"
                              selectedOption={
                                row.brandList
                                  ? row.brandList.find(
                                      (x) => x.value === row.brand
                                    )
                                  : null
                              }
                              controlFunc={(selectedOption) => {
                                handleInputChange(
                                  selectedOption,
                                  index,
                                  "brand"
                                );
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesQty" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.quantity}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "quantity");
                              }}
                            />
                          </div>
                        ) : keyData.key === "commoditiesUOM" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.uom}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "uom");
                              }}
                            />
                          </div>
                        ) : keyData.key === "charges" ? (
                          <div className=" border p-1">
                            <div className="flex items-center w-full whitespace-nowrap font-bold">
                              {keyData.title}
                            </div>
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.charges}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "charges");
                              }}
                            />
                          </div>
                        ) : (
                          <div className="flex border p-1">
                            <div className="flex basis-1/2 font-bold">
                              {keyData.title}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <hr class={mainTable.horizontalLine}></hr>
          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div class={mainTable.labelBold}>Doctor Appointment</div>
          <div className={mainTable.grid3}>
            <InputText
              inputType="datetime-local"
              title="Appoinment Date"
              name="Area"
              content={data1[0]?.AppoinmentDate}
              disabled="disabled"
            />

            {/* <AutoDropDown
              title="Appointment with"
              options={[]}
              placeholder="Select"
              classStar="text-red-500"
              name="state"
              selectedOption={data2[0]}
              Class="custom-class"
              tabIndex="1"
              disabled="disabled"
            />

            <AutoDropDown
              classStar="text-red-500"
              title="Area"
              options={[]}
              Class="custom-class"
              placeholder="Select"
              name="area"
              disabled="disabled"
              selectedOption={data2[8]}
            /> */}

            <InputText
              inputType="text"
              title={"Appointment with"}
              name={"CYPConversionFactor"}
              content={data2[0]}
              disabled="true"
            />
            <InputText
              inputType="text"
              disabled="true"
              title={"Branch / Project Email"}
              content={data2[8]}
              name={"CYPConversionFactor"}
            />

            <div class="lg:col-span-3 sm:col-span-1 md:col-span-3">
              <TextArea
                inputType="text"
                title="Your Meeting Link"
                name="Link"
                rows="4"
                placeholder="Meeting Link"
                content={data1[0]?.MeetingLink}
                disabled="disabled"
              />
            </div>
          </div>

          <hr class={mainTable.servicesHorizontalLine}></hr>
          <div class={mainTable.labelBold}>Counselling / Consulting</div>
          <TextArea
            inputType="text"
            title="Diagnosis / Remarks"
            name="specificHistory"
            rows="4"
            placeholder="Typing"
            content={data.Remarks_en}
            disabled="disabled"
          />
        </form>
      </div>
    </div>
  );
};

export default AddServices;
