// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import toast, { Toaster } from "react-hot-toast";
import { systemSettingsInsertAPI } from "../unused-code/SystemSettingsAPI";
import { BindAPI, IUDAPI } from "../services/APIConnection";

const SystemSettingsPopup = ({ closePopup }) => {
  const [rowsPerPage, setRowsPerPage] = useState("");
  const [emailId, setEmailId] = useState("");
  const [systemSettings1, setSystemSettings1] = useState("");
  const [systemSettings2, setSystemSettings2] = useState("");
  const [systemSettings3, setSystemSettings3] = useState("");
  const [systemSettings4, setSystemSettings4] = useState("");
  const [bindSystemSettingsData, setBindSystemSettingsData] = useState([]);
  const allowedValues = [10, 25, 50, 100, 500, 1000, 5000];

  useEffect(() => {
    if (
      bindSystemSettingsData.length > 0 &&
      bindSystemSettingsData[0].NoofRowsPerPage
    ) {
      setRowsPerPage(bindSystemSettingsData[0].NoofRowsPerPage);
      setEmailId(bindSystemSettingsData[0].EmaiId);
      setSystemSettings1(bindSystemSettingsData[0].SystemSettings1);
      setSystemSettings2(bindSystemSettingsData[0].SystemSettings2);
      setSystemSettings3(bindSystemSettingsData[0].SystemSettings3);
      setSystemSettings4(bindSystemSettingsData[0].SystemSettings4);
    }
  }, []);

  useEffect(() => {
    systemSettingsData();
  }, []);

  const systemSettingsData = async () => {
    const response = await BindAPI("SystemSettingsAPI/BindSystemSettings");
    setBindSystemSettingsData(response.data.Table);
  };

  const Cancel = () => {
    closePopup(true);
    setSystemSettings1("");
    setSystemSettings2("");
    setSystemSettings3("");
    setSystemSettings4("");
    setEmailId("");
    setRowsPerPage("");
    systemSettingsData();
  };

  const HandleSubmit = async (items, flag) => {
    if (!allowedValues.includes(Number(rowsPerPage))) {
      toast.error(
        "Please enter a valid value for Rows Per Page (10, 25, 50, 100, 500, 1000, 5000)",
        {
          duration: 2000,
          position: "top-center",
        }
      );
      return; // Stop the submission if the value is not allowed
    }

    const userPayload = {
      systemSettingsId: sessionStorage.getItem("createdBy"),
      noofRowsPerPage: rowsPerPage,
      emaiId: emailId,
      systemSettings1,
      systemSettings2,
      systemSettings3,
      systemSettings4,
      createdBy: sessionStorage.getItem("createdBy"),
      flag,
    };
    const response = await IUDAPI([userPayload], "SystemSettingsAPI/IUDSystemSettings");

    if (response.data.Table[0].result === "M") {
      toast.success("System Settings added successfully 👍", {
        duration: 5000,
        position: "top-center",
      });
      setTimeout(Cancel, 5000);
    } else {
      toast.error("Failed to add System Settings data 👎", {
        duration: 2000,
        position: "top-center",
      });
      setTimeout(Cancel, 2000);
    }
  };
  return (
    <div class="w-full">
     
      <div class="text-xl mx-5 font-medium text-neutral-700 ">
        {"System Settings"}
      </div>
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form class={mainTable.formForPopup}>
              <div class="grid gap-4 mb-6 md:grid-cols-2">
                <InputText
                  inputType="number"
                  title="Rows Per Page"
                  name="lastName"
                  placeholder="Rows Per Page"
                  content={rowsPerPage}
                  controlFunc={(e) => setRowsPerPage(e.target.value)}
                />

                <InputText
                  inputType="email"
                  title="Email"
                  name="email"
                  placeholder="vaibhav@fpaindia.org"
                  content={emailId}
                  controlFunc={(e) => setEmailId(e.target.value)}
                />
                <InputText
                  inputType="text"
                  title="System Settings1"
                  name="phone"
                  placeholder="System Settings1"
                  content={systemSettings1}
                  controlFunc={(e) => setSystemSettings1(e.target.value)}
                />
                <InputText
                  inputType="text"
                  title="System Settings2"
                  name="userId"
                  placeholder="System Settings2"
                  content={systemSettings2}
                  controlFunc={(e) => setSystemSettings2(e.target.value)}
                />
                <InputText
                  inputType="text"
                  title="System Settings3"
                  name="userId"
                  placeholder="System Settings3"
                  content={systemSettings3}
                  controlFunc={(e) => setSystemSettings3(e.target.value)}
                />
                <InputText
                  inputType="text"
                  title="System Settings4"
                  name="userId"
                  placeholder="System Settings4"
                  content={systemSettings4}
                  controlFunc={(e) => setSystemSettings4(e.target.value)}
                />
              </div>
            </form>
            <div class={mainTable.submitCancelMainDiv}>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.submitButton}
                onClick={() => HandleSubmit(0, 1)}
              >
                Submit
              </button>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.cancelButton}
                onClick={Cancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingsPopup;
