// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import InputText from "../ReusableComponents/InputText";
import { mainTable } from "../css/Common";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const BrandPopup = ({ data, data2 }) => {
  console.log("BrandPopup", data2);
  useEffect(() => {
    if (data) {
      setBrands([data.Brand_en]);
    }
  }, [data ? data : null]);

  const [brands, setBrands] = useState([""]);
  return (
    <div class="mx-auto flex  border-black">
      <div class="relative w-full h-full  md:h-auto">
        <div class="relative bg-white rounded-lg  ">
          <div class="px-6 py-6 lg:px-8">
            <form className={mainTable.grid3}>
              <AutoDropDown
                Class="Custom-class"
                title="Commodities"
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                name="commodities"
                selectedOption={data2[0]}
                disabled={true}
                // controlFuncBlur={}
              />

              <AutoDropDown
                Class="Custom-class"
                title="Category"
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                name="commodities"
                selectedOption={data2[1]}
                disabled={true}
              />

              <AutoDropDown
                Class="Custom-class"
                title="Name"
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                name="name"
                selectedOption={data2[2]}
                disabled={true}
              />
              {/* <div className={mainTable.grid3}> */}
              {brands.map((brand, index) => (
                <div key={index} className="flex items-center">
                  <InputText
                    inputType={"text"}
                    title={`Brand ${index + 1}`}
                    name={`brand${index}`}
                    content={brand}
                    classDiv={"w-full"}
                    classStar="text-red-500"
                    disabled={true}
                  />
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPopup;
