// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const ServicesSubCategory = ({ data, data1 }) => {
  const [category, setCategory] = useState([]);
  return (
    <div class="w-full ">
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow  m-2">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <AutoDropDown
                  title="Category"
                  classStar="text-red-500"
                  options={category}
                  placeholder="Select"
                  name="category"
                  selectedOption={data1}
                  Class="custom-class"
                  tabIndex="1"
                  disabled="disabled"
                />
                <InputText
                  inputType={"text"}
                  title={"Sub-Category"}
                  classStar="text-red-500"
                  name={"subCategory"}
                  content={data.ServicesSubCategory_en}
                  disabled="disabled"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSubCategory;
