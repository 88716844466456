import axios from "axios";

const IUDAwarenessICAPI = async (data) => {
  

  const response = await axios.post(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/IUDAwarenessICAPI`,
    // `http://localhost:5154/FPAIOutReachAPI/IUDAwarenessICAPI`,
    data
  );
  return response;
}
 
 
const BindAwarenessData = async (Binddata) => {
  

  const response = await axios.post(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindAwarenessData`,
    // `http://localhost:5154/FPAIOutReachAPI/BindAwarenessData`,
    Binddata
  );
  return response;
};

const BindAwarnessAllMasterDatas = async () => {
  

  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindAwarnessAllMasterDatas`,
    // `http://localhost:5154/FPAIOutReachAPI/BindAwarnessAllMasterDatas`
  );
  return response;
};
const getLocationApi = async () => {
  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/GetLocation`
    // `http://localhost:5154/FPAIOutReachAPI/GetLocation`
  );
  return response;
};

const getSdpApi = async () => {
  const response = await axios.get(
    // `http://localhost:5154/FPAIOutReachAPI/GetSDP`,
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/GetSDP`
  );
  return response;
};

const getAreaApi = async () => {
  const response = await axios.get(
    `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/GetArea`
    // `http://localhost:5154/FPAIOutReachAPI/GetArea`
  );
  return response;
};
 
const AwarenessImageUpload = async (formData) => {
  
  try {
    const response = await axios.post(
      `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/AwarenessImageUpload`,
      // "http://localhost:5154/FPAIOutReachAPI/AwarenessImageUpload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          type: "formData",
        },
      }
    );
    return response.data; // Assuming the response has a 'data' property
  } catch (error) {
    throw error;
  }
};


const bindDisrictApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindDistrict`,
  );
  return response;
};

const bindBlockApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindBlock`,
  );
  return response;
};

const bindCityApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindCityVillage`,
  );
  return response;
};

const bindMassMediaApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindMassMedia`,
  );
  return response;
};

const bindMidMediaApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindMidMedia`,
  );
  return response;
};

const bindSocialMediaApi = async () => {
  const response = await axios.get(
     `http://fpai-outreach.xlicon.com/fpai-outreach-api/FPAIOutReachAPI/BindSocialMedia`,
  );
  return response;
};

export { IUDAwarenessICAPI,BindAwarenessData,
  BindAwarnessAllMasterDatas,getLocationApi,getSdpApi,getAreaApi, AwarenessImageUpload,bindDisrictApi,
  bindBlockApi,bindCityApi,bindMassMediaApi,bindMidMediaApi,bindSocialMediaApi };
