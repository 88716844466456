import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";


const SupplierPopup = ({ data, data1 }) => {
  const [locationBindDD, setLocationBindDD] = useState([]);
  const [bindMedicinesDrugsDD, setBindMedicinesDrugsDD] = useState([]);
  const [stateBindDD, setStateBindDD] = useState([]);
  const [countryBindDD, setCountryBindDD] = useState([]);

  return (
    <div class="w-full">
      <div class={mainTable.mainContentScreen}>
      <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit">
          <div class={mainTable.labelBold}>Supplier Details</div>
          <hr class={mainTable.horizontalLine}></hr>
          <div class="grid gap-4 mb-6 md:grid-cols-3">
            <div>
              <InputText
                inputType={"text"}
                title={" Supplier Id"}
                name={" SupplierId"}
                content={data.SupplierId}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Supplier Name"}
                name={"suppliername"}
                content={data.SupplierName}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Address"}
                name={"address"}
                content={data.SupplierAddress}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <AutoDropDown
                title="Country"
                options={countryBindDD}
                placeholder="Select"
                name="state"
                selectedOption={[data1[0]]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>

            <div>
              <AutoDropDown
                title="State"
                options={stateBindDD}
                placeholder="Select"
                name="state"
                selectedOption={[data1[1]]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <AutoDropDown
                title="City"
                options={locationBindDD}
                placeholder="Select"
                name="city"
                selectedOption={[data1[2]]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"number"}
                title={"Pin Code"}
                name={"pincode"}
                content={data.SupplierPincode}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType="date"
                title="Date"
                name="date"
                content={[data1[4]]}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>

            <div>
              {/* <AutoDropDown
                title="Commodities"
                options={bindMedicinesDrugsDD}
                placeholder="Select"
                name="commodities"
                selectedOption={[data1[3]]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
                classStar="text-red-500"
              /> */}
            </div>

            <div className="flex">
              <div>
                <img
                  src={data.SupplierLogo}
                  alt="Image"
                  className="rounded-full w-20 h-20"
                />
              </div>
            </div>
          </div>
          <div class={mainTable.labelBold}>Contact Info</div>
          <hr class={mainTable.horizontalLine}></hr>
          <div class="grid gap-4 mb-6 md:grid-cols-3">
            <div>
              <InputText
                inputType={"text"}
                title={"Contact Person"}
                name={"contactperson"}
                content={data.SupplierContactPersonName}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Designation"}
                name={"designation"}
                content={data.SupplierContactPersonDesignation}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Contact Number"}
                name={"designation"}
                content={data.SupplierMobileNumber}
                disabled="disabled"
                classStar="text-red-500"
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Email"}
                name={"email"}
                content={data.SupplierEmailId}
                disabled="disabled"
                // classStar="text-red-500"
              />
            </div>
            <div class="lg:col-span-3 sm:col-span-1">
              <div class={mainTable.input_richtextbox}></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupplierPopup;
