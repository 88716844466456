// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import MainPopup from "../popup-containers/MainPopup";
import "react-toastify/dist/ReactToastify.css";
import { CBPHeader } from "../components/HeaderAttribute";
import { BindAPI, IUDAPI } from "../services/APIConnection";
import toast, { Toaster } from "react-hot-toast";
import CBPPopup from "../popup-containers/CBPPopup";
import GridPage from "../components/GridPage";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";

const CBP = () => {
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [CBPData, setCBPData] = useState("");
  const [popupdata1, setPopupdata1] = useState({});
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [updateScreen, setUpdateScreen] = useState(false);
  const [addScreen, setAddScreen] = useState(false);
  
  const createdBy = sessionStorage.getItem("createdBy");
  const [mstCBPId, setMstCBPId] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [location, setLocation] = useState("");
  const [cbp, setCBP] = useState("");
  const [stateError, setStateError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [cbpError, setCBPError] = useState("");

  const [bindCountriesDD, setBindCountriesDD] = useState([]);
  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [bindLocation, setBindLocationDD] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  const [bindCBPDatas, setBindCBPDatas] = useState([]);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreen(true);
    setUpdateScreen(false);
  };


  useEffect(() => {
    
    const stateSelectedValue = getLocationDatas.find(
      (item) => item.MstStateId == state.value
    );

    if (stateSelectedValue) {
      const locationLabels = getLocationDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstLocationId: item.MstLocationId,
          LocationName_en: item.LocationName_en,
        }));

      const locationSelected = locationLabels.map((LC) => ({
        value: LC.MstLocationId,
        label: LC.LocationName_en,
      }));
      setBindLocationDD(locationSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [state]);

  useEffect(() => {
    const getSelectedLocation = async () => {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");
      const filteredData = locationResponse.data.Table;
      const filteredWithBlockedByNull = filteredData.filter(
        (item) => item.BlockedBy === null
      );
      setGetLocationDatas(filteredWithBlockedByNull);
    };

    getSelectedLocation();
  }, []);


  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");

        const filteredData = statesResponse.data.Table;
        const filteredWithBlockedByNull = filteredData.filter(
          (item) => item.BlockedBy === null
        );

        const statesData = filteredWithBlockedByNull.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        setBindStateDropDown(statesData);
      } catch (error) {}
    };
    dropDownDatas();
    CbpData();
  }, []);

 
  const CbpData = async () => {
    const response = await BindAPI("CBPAPI/BindCBP");
    setBindCBPDatas(response.data.Table);
  };

  const cancel = () => {
    setEditMode(false);
    setSelectedRowIndex(null);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    clearData();
    CbpData();
  };


  const clearData = () => {
    setState("");
    setLocation("");
    setCBP("");
    setStateError("");
    setLocationError("");
    setCBPError("");
  };

  const handleSubmit = async (items, flag) => {
    
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let locationValidate = "";
      let cbpValidate = "";

      stateValidate = state ? "" : "Select a State";
      setStateError(stateValidate);

      locationValidate = location ? "" : "Select a Branch/Project";
      setLocationError(locationValidate);

      cbpValidate =
        cbp.length > 0
          ? /^[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(cbp)
            ? ""
            : "Only Alphabet, Numbers and space allowed"
          : "Enter CBP";
      setCBPError(cbpValidate);

      if (!stateValidate && !locationValidate && !cbpValidate) {
        const data = [
          {
            mstCBPId: mstCBPId ? mstCBPId : 0,
            mstCountryId: 1,
            mstStateId: state.value,
            mstLocationId: location.value,
            cbpName: cbp,
            createdby: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "CBPAPI/IUDCBP");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("CBP data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add CBP data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("CBP data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update CBP data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      const data = [
        {
          mstCBPId: bindCBPDatas[rowIndex].MstCBPId,
          createdby: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "CBPAPI/IUDCBP");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("CBP data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete CBP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("CBP data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block CBP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("CBP data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock CBP data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };


 
  const handleCBP = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setCBPData(bindCBPDatas[rowIndex]);

    const StaterowData = bindCBPDatas[rowIndex];
    const StateInfo = bindStateDropDown.find(
      (ST) => ST.value === StaterowData.MstStateId
    );

    const resultState = {
      value: StaterowData.MstStateId,
      label: StateInfo ? StateInfo.label : "DefaultStateLabel",
    };

    const locationrowData = bindCBPDatas[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstLocationId
    );
    const ResultLocation = {
      value: locationrowData.MstLocationId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const AutoDropdownPopupdata = [resultState, ResultLocation];
    setPopupdata1(AutoDropdownPopupdata);
  };

 
  const dropdownValuesEffect = () => {
    if (editMode && selectedRowIndex !== null) {
      dropdownstate(selectedRowIndex);
      dropdownlocation(selectedRowIndex);
    }
  };

  
  const dropdownstate = (rowIndex) => {
    try {
      const StaterowData = bindCBPDatas[rowIndex];
      const StateInfo = bindStateDropDown.find(
        (ST) => ST.value === StaterowData.MstStateId
      );

      const resultState = {
        value: StaterowData.MstStateId,
        label: StateInfo ? StateInfo.label : "DefaultStateLabel",
      };

      setState(resultState);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  const dropdownlocation = (rowIndex) => {
    try {
      const locationrowData = bindCBPDatas[rowIndex];
      const LocationInfo = getLocationDatas.find(
        (L) => L.MstLocationId === locationrowData.MstLocationId
      );
      const ResultLocation = {
        value: locationrowData.MstLocationId,
        label: LocationInfo
          ? LocationInfo.LocationName_en
          : "DefaultStateLabel",
      };
      setLocation(ResultLocation);
    } catch (error) {
      console.error("Error setting state:", error);
    }
  };

  useEffect(dropdownValuesEffect, [editMode, selectedRowIndex]);
  const handleEdit = (rowIndex) => {
    setEditMode(true);
    setSelectedRowIndex(rowIndex);
    
    setUpdateScreen(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreen(false);

    setCBP(bindCBPDatas[rowIndex].CBPName);

  };



  return (
    <MainLayout pageName="2">
     

      {visibleTable && (
        <GridPage
          title={`CBP Master`}
          header={CBPHeader}
          records={bindCBPDatas}
          handleEdit={handleEdit}
          handleAction={handleCBP}
          showAdd={showAdd}
        />
       
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class=" border-black  ">
            <div class="text-black text-2xl font-bold my-2">
              {addScreen && (
                <div class={mainTable.addScreenTitle}>{"CBP Master - Add"}</div>
              )}

              {updateScreen && (
                <div class={mainTable.addScreenTitle}>
                  {"CBP Master - Edit"}
                </div>
              )}
            </div>
          </div>
          <div class="mx-auto  md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title={"Country"}
                      options={bindCountriesDD}
                      selectedOption={country}
                      placeholder={"Select"}
                      name={"country"}
                      controlFunc={(value) => {
                        setCountry(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="State"
                        options={bindStateDropDown}
                        placeholder="Select"
                        name="state"
                        selectedOption={ state}
                        Class="custom-class"
                        // disabled="false"
                        tabIndex="1"
                        controlFunc={(value) => {
                          setState(value);
                          setStateError(value ? "" : "Select a State");
                          setLocation("");
                        }}
                        controlFuncBlur={() => {
                          setStateError(state ? "" : "Select a State");
                        }}
                      />
                      {stateError && (
                        <span className="text-red-500">{stateError}</span>
                      )}
                    </div>
                    <div>
                      <AutoDropDown
                        classStar="text-red-500"
                        title="Branch/Project"
                        options={bindLocation}
                        placeholder="Select"
                        name="state"
                        selectedOption={location}
                        Class="custom-class"
                        //disabled="false"
                        tabIndex="1"
                        controlFunc={(value) => {
                          setLocation(value);
                          setLocationError(value ? "" : "Select a Branch/Project");
                        }}
                        controlFuncBlur={() => {
                          setLocationError(location ? "" : "Select a Branch/Project");
                        }}
                      />
                      {locationError && (
                        <span className="text-red-500">{locationError}</span>
                      )}
                    </div>

                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"CBP"}
                      name={"cbp"}
                      content={cbp}
                      maxLength={100}
                      controlFunc={(e) => setCBP(e.target.value)}
                      controlFuncBlur={(e) => {
                        setCBP(e.target.value);
                        setCBPError(
                          cbp.length > 0
                            ? /^[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/.test(
                                cbp
                              )
                              ? ""
                              : "Only Alphabet, Numbers and space allowed"
                            : "Enter CBP"
                        );
                      }}
                    />
                    {cbpError && <span class="text-red-500">{cbpError}</span>}

                    <div class={mainTable.submitCancelMainDiv}>
                      {addScreen && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(0, 1)}
                        >
                          SUBMIT
                        </button>
                      )}
                      {updateScreen && (
                        <button
                          data-modal-hide="bottom-right-modal"
                          type="button"
                          class={mainTable.submitButton}
                          onClick={() => handleSubmit(0, 2)}
                        >
                          Update
                        </button>
                      )}
                      <button
                        data-modal-hide="bottom-right-modal"
                        type="button"
                        onClick={cancel}
                        class={mainTable.cancelButton}
                      >
                        CANCEL
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleCBP}
      >
        <div >
        <PopUpScreenTitle
            title="CBP Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          
          <CBPPopup data={CBPData} data1={popupdata1} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
        />
   
      </MainPopup>
    </MainLayout>
  );
};

export default CBP;
