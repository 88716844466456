const ErrorPopup = ({ closeModal }) => {
    const handleOutsideClick = (e) => {
      if (e.target.id === "popup-overlay") {
        closeModal();
      }
    };
  
    return (
      <div
        id="popup-overlay"
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={handleOutsideClick}
      >
        <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
          <h2 className="text-red-500 font-semibold">
           Please input All Mandatory fields and Submit.
          </h2>
          {/* <button
            onClick={closeModal}
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Close
          </button> */}
        </div>
      </div>
    );
  };
  
  export default ErrorPopup;
  