// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const Location = ({ data, data2 }) => {
  return (
    <div class="w-full ">
      <div class="mx-auto  lg:w-2/5 flex justify-center md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <DropDown
                  classDiv="hidden"
                  title={"Country"}
                  options={[]}
                  placeholder={"Select"}
                  name={"country"}
                  selectedOption={null}
                />
                <AutoDropDown
                  title="State"
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={data2[0]}
                  Class="custom-class"
                  classStar="text-red-500"
                  disabled={true}
                  // disabled="false"
                  tabIndex="1"
                />

                <InputText
                  inputType={"text"}
                  title={"Branch/Project"}
                  name={"branchProjec"}
                  content={data.LocationName_en}
                  classStar="text-red-500"
                  disabled={true}
                />
                <InputText
                  inputType={"text"}
                  title={"Branch/Project Code"}
                  name={"branchProjectCode"}
                  content={data.LocationCode}
                  classStar="text-red-500"
                  disabled={true}
                />
                  <InputText
                  inputType={"text"}
                  title={"Email"}
                  name={"branchProjectCode"}
                  content={data.Email}
                  classStar="text-red-500"
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
