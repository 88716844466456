// Code review - Completed
// Documentation - Completed
import React, { useState, useEffect } from "react";
import {
  blockMasterHeader,
  districtMasterHeader,
  sdpMasterHeader,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import BlockPopup from "../popup-containers/BlockPopup";
import MainPopup from "../popup-containers/MainPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const BlockMaster = () => {
  const location1 = useLocation();
  //All bind dropdown value and bind data value useState hooks handling.

  const [bindStateDropDown, setBindStateDropDown] = useState([]);
  const [bindBlockMasterDatas, setBindBlockMasterDatas] = useState([]);
  const [getDistrictDatas, setGetDistrictDatas] = useState([]);
  const [bindDistrict, setBindDistrict] = useState([]);
  const [bindDistrict1, setBindDistrict1] = useState([]);

  //All visible functionality useState hooks handling.
  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  // SDP Master insert and edit useState hooks handling .
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");

  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [blockError, setBlockError] = useState("");

  const [districtError, setDistrictError] = useState("");
  const [mstBlockId, setMSTBlockId] = useState("");

  const createdBy = sessionStorage.getItem("createdBy");

  const [popUpDropDownData, setPopUpDropDownData] = useState({});
  const [blockMasterDatas, setBlockMasterData] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const showadd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  const cancel = () => {
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setSubmitBtnDisabled(false);
    blockMasterData();
    setState("");
    setDistrict("");
    setDistrictError("");
    setStateError("");
    setBlock("");
    setBlockError("");
  };

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("StateAPI/GetState");

        const statesData = statesResponse.data.Table.map((item) => ({
          value: item.MstStateId,
          label: item.StateName_en,
        }));

        setBindStateDropDown(statesData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    dropDownDatas();
    blockMasterData();
    getSelectedDistrict();
  }, []);
  const blockMasterData = async () => {
    const response = await BindAPI("BlockAPI/BindBlockMaster");
    setBindBlockMasterDatas(response.data.Table);
  };
  const getSelectedDistrict = async () => {
    const locationResponse = await BindAPI("IECAPI/BindDistrict");
    setGetDistrictDatas(locationResponse.data.Table);
  };

  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);

    setState(bindBlockMasterDatas[rowIndex].MstStateId);
    setDistrict(bindBlockMasterDatas[rowIndex].MstDistrictId);
    setMSTBlockId(bindBlockMasterDatas[rowIndex].MstBlockId);
    setBlock(bindBlockMasterDatas[rowIndex].Block_en);
  };

  const handleBlock = (rowIndex, flag) => {
    
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    setBlockMasterData(bindBlockMasterDatas[rowIndex]);
    const BlockData = bindBlockMasterDatas[rowIndex];
    //State DropDown Value Start
    const stateDropDownValue = bindStateDropDown.find(
      (item) => item.value === BlockData.MstStateId
    );
    const editStateDropDownValue = {
      value: BlockData.MstStateId,
      label: stateDropDownValue ? stateDropDownValue.label : "Unknown",
    };
    //State DropDown Value End

    //District DropDown Value Start
    const DistrictDropDownValue = bindDistrict1.find(
      (item) => item.value === BlockData.MstDistrictId
    );
    const editDistrictDropDownValue = {
      value: BlockData.MstDistrictId,
      label: DistrictDropDownValue ? DistrictDropDownValue.label : "Unknown",
    };
    //District DropDown Value End

    const autoDropDownValues = [
      editStateDropDownValue,
      editDistrictDropDownValue,
    ];
    setPopUpDropDownData(autoDropDownValues);
  };

  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      // validation start
      let stateValidate = "";
      let districtValidate = "";
      let blockValidate = "";

      blockValidate = block ? "" : "Enter Block";

      stateValidate = state ? "" : "Select a State";
      districtValidate = district ? "" : "Select a District";
      setBlockError(blockValidate);
      setStateError(stateValidate);
      setDistrictError(districtValidate);
      if (stateValidate && districtValidate && blockValidate) {
        setSubmitBtnDisabled(false);
      }
      // Validation end
     
      if(stateValidate || districtValidate || blockValidate){
        setIsOpen(true);
      }

      if (!stateValidate && !districtValidate && !blockValidate) {
        setIsOpen(false);
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];
        const translatedTexts = [];
        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(block, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }
        const data = [
          {
            mstBlockId: mstBlockId ? mstBlockId : 0,
            mstCountryId: 1,
            mstStateId: state,
            mstDistrictId: district,
            block_en: block,
            block_ta: translatedTexts[0],
            block_hi: translatedTexts[1],
            block_mr: translatedTexts[2],
            block_gu: translatedTexts[3],
            block_kn: translatedTexts[4],
            block_ml: translatedTexts[5],
            block_te: translatedTexts[6],
            block_bn: translatedTexts[7],
            block_or: translatedTexts[8],
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(data, "BlockAPI/IUDBlock");
        if (flag == 1) {
          // Submit
          if (response.data.Table[0].Result === "S") {
            toast.success("Block data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add Block data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            toast.success("Block data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update Block data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      }else{
        setSubmitBtnDisabled(false);
      }
    } else {
      const data = [
        {
          mstBlockId: bindBlockMasterDatas[items].MstBlockId,
          createdBy: createdBy,
          flag: flag,
        },
      ];
      const response = await IUDAPI(data, "BlockAPI/IUDBlock");
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          toast.success("Block data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete Block data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          toast.success("Block data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block Block data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          toast.success("Block data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock Block data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
  };
  useEffect(() => {
    if (location1.pathname == "/blockmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
    }
  }, [location1]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const stateSelectedValue = getDistrictDatas.find(
      (item) => item.MstStateId == state // Replace with the desired MstStateId
    );
    setBindDistrict([]);
    if (stateSelectedValue) {
      const districtLabels = getDistrictDatas
        .filter((item) => item.MstStateId === stateSelectedValue.MstStateId)
        .map((item) => ({
          MstDistrictId: item.MstDistrictId,
          District_en: item.District_en,
        }));

      const districtSelected = districtLabels.map((LC) => ({
        value: LC.MstDistrictId, // Replace with the appropriate value
        label: LC.District_en,
      }));
      setBindDistrict(districtSelected);
    } else {
    }
  }, [state]);

  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const statesResponse = await BindAPI("IECAPI/BindDistrict");

        const DistrictData = statesResponse.data.Table.map((item) => ({
          value: item.MstDistrictId,
          label: item.District_en,
        }));

        setBindDistrict1(DistrictData);
      } catch (error) {
        // Handle API call errors (e.g., show an error message)
      }
    };

    dropDownDatas();
  }, []);

  return (
    <MainLayout pageName="2">
     

      {visibleTable && (
        <GridPage
          title={"Block Master"}
          header={blockMasterHeader}
          records={bindBlockMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleBlock}
          showAdd={showadd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class="border-black">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Block Master"
            />
          </div>
          <div class="mx-auto md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <AutoDropDown
                      classStar="text-red-500"
                      classDiv="hidden"
                      title={"Country"}
                      options={[]}
                      selectedOption={country}
                      placeholder={"Select"}
                      name={"country"}
                      controlFunc={(value) => {
                        setCountry(value);
                      }}
                      Class="custom-class"
                      disabled="false"
                      tabIndex="1"
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindStateDropDown}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDown.find(
                        (x) => x.value == state
                      )}
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setDistrict("");
                        setState(value.value);
                        setStateError(value ? "" : "Select a State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select a State");
                      }}
                      errorMessage={stateError}
                    />

                    <AutoDropDown
                      classStar="text-red-500"
                      title="District"
                      options={bindDistrict}
                      placeholder="Select"
                      name="District"
                      selectedOption={
                        district
                          ? bindDistrict.find((x) => x.value == district)
                          : ""
                      }
                      Class="custom-class"
                      tabIndex="1"
                      controlFunc={(value) => {
                        setDistrict(value.value);
                        setDistrictError(value ? "" : "Select a District");
                      }}
                      controlFuncBlur={() => {
                        setDistrictError(district ? "" : "Select a District");
                      }}
                      errorMessage={districtError}
                    />

                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title="Block"
                      name={"block"}
                      maxLength={100}
                      content={block}
                      controlFunc={(e) => {
                        // setBlock(e.target.value)
                        const value = e.target.value;
                        setBlock(value);
                        setBlockError(
                          value.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(value)
                              ? ""  // No error if the input is valid
                              : ""
                            : "Enter Block"  // Error message if the input is empty
                        );
                      }}
                      controlFuncBlur={(e) => {
                        // setBlock(e.target.value);
                        // setBlockError(block.length > 0 ? "" : " Enter Block");
                        const trimmedValue = e.target.value.trim();
                        setBlock(trimmedValue);
                        setBlockError(
                          trimmedValue.length > 0
                            ? /^[\S]+(?:\s[\S]+)*$/.test(trimmedValue)
                              ? ""  // No error if the input is valid
                              : "Only single spaces between words allowed"
                            : "Enter Block"  // Error message if the input is empty
                        );
                      }}
                      errorMessage={blockError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleBlock}
      >
        <div>
          <PopUpScreenTitle
            title="Block Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <BlockPopup data={blockMasterDatas} data2={popUpDropDownData} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default BlockMaster;
