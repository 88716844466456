// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import { BindAPI } from "../services/APIConnection";

const ConsumptionPopup = ({ data, data1, data2 }) => {
  const [commoditiesDetails, setCommoditiesDetails] = useState({});
  const [bindCategoryDD, setBindCategoryDD] = useState([]);
  const [bindNameDD, setBindNameDD] = useState([]);
  const [bindBrandDD, setBindBrandDD] = useState([]);
  const [tableData, setTableData] = useState([
    {
      commodities: "",
      category: "",
      name: "",
      brand: "",
      batchNo: "",
      dateOfExpiry: "",
      demonstration: "",
      damagedAdjustments: "",
      expired: "",
      total: "",
      categoryList: [],
      nameList: [],
      brandList: [],
    },
  ]);
  const headerData = [
    {
      title: "Category",
      key: "consumptionCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "consumptionName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "consumptionBrand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "commoditiesUOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch No.",
      key: "BatchNumber",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date Of Expiry",
      key: "DateOfExpiry",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Demo.",
      key: "consumptionDemo",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Damaged/Adjustments",
      key: "consumptionWaste",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Exp.",
      key: "ConsumptionExp",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Total",
      key: "ConsumptionTotal",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setCommoditiesDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  useEffect(() => {
    const ParticipantDatas = JSON.stringify(data2);
    if (data2.length === 0) {
      setTableData([
        {
          commodities: "",
          category: "",
          name: "",
          brand: "",
          batchNo: "",
          dateOfExpiry: "",
          demonstration: null,
          damagedAdjustments: null,
          expired: null,
          total: null,
          categoryList: [],
          nameList: [],
          brandList: [],
        },
      ]);
    } else {
      const clearValues = () => {
        setTableData([]);
      };
      clearValues();

      if (ParticipantDatas && typeof ParticipantDatas === "string") {
        try {
          const newDataArray = JSON.parse(ParticipantDatas);
          const formattedDataArray = newDataArray.map((data) => {
            const formattedDateOfExpiry = data.DateOfExpiry;
            const formattedDate1 = formattedDateOfExpiry.split("T")[0];
            return {
              category: data.MstCategoryId,
              name: data.MstNameId,
              brand: data.MstBrandId,
              unitOfMeasure: data.UOM,
              batchNo: data.BatchNo,
              dateOfExpiry: formattedDate1,
              demonstration: data.Demonstration,
              damagedAdjustments: data.DamagedAdjustments,
              expired: data.Expired,
              total: data.Total,
            };
          });

          setTableData((prevData) => [...prevData, ...formattedDataArray]);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else {
        // Handle the case when data2 is not a string or is empty
      }
    }
  }, [data2]);

  useEffect(() => {
    const consumptionDropDownDatas = async () => {
      try {
        const categoryResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
        const brandResponse = await BindAPI("BrandAPI/BindBrand");

        const categoryData = categoryResponse.data.Table.map((item) => ({
          value: item.MstCommodityCategoryId,
          label: item.CommodityCategory_en,
        }));

        const nameData = nameResponse.data.Table.map((item) => ({
          value: item.MstCommoditiesId,
          label: item.CommodityName_en,
        }));

        const brandData = brandResponse.data.Table.map((item) => ({
          value: item.MstBrandId,
          label: item.Brand_en,
        }));

        setBindCategoryDD(categoryData);
        setBindNameDD(nameData);
        setBindBrandDD(brandData);
      } catch (error) {}
    };

    consumptionDropDownDatas();
  }, []);

  return (
    <div class="w-full">
      <div class=" flex flex-col w-full">
        <div class={mainTable.addShadowScreen}>
          <div class={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit">
              <div className={mainTable.grid5}>
                <AutoDropDown
                  title="Commodities"
                  classStar={"text-red-500"}
                  options={[]}
                  placeholder="Select"
                  name="commodities"
                  selectedOption={[data1[0]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <InputText
                  inputType="text"
                  title="Consumption No."
                  name="Consumption No"
                  classStar={"text-red-500"}
                  content={data.ConsumptionNo}
                  disabled="disabled"
                />
                <InputText
                  inputType="date"
                  title="Consumption Date"
                  name="consumptionDate"
                  classStar={"text-red-500"}
                  content={data1[1]}
                  disabled="disabled"
                />
                <AutoDropDown
                  title="Branch"
                  classStar={"text-red-500"}
                  options={[]}
                  placeholder="Select"
                  name="branch"
                  selectedOption={[data1[2]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="S.D.P."
                  options={[]}
                  placeholder="Select"
                  name="sdp"
                  selectedOption={[data1[3]]}
                  disabled="disabled"
                  Class="custom-class"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Area Name"
                  options={[]}
                  name="area"
                  disabled="disabled"
                  placeholder="Select"
                  Class="custom-class"
                  selectedOption={[data1[5]]}
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Outreach staff"
                  options={[]}
                  name="mstOrwId"
                  disabled="disabled"
                  placeholder="Select"
                  Class="custom-class"
                  selectedOption={[data1[6]]}
                />
                <InputText
                  inputType="date"
                  title="Session Date"
                  name="sessionDate"
                  content={data1[4]}
                  disabled="disabled"
                />
                <InputText
                  inputType="text"
                  title="Session Place"
                  name="sessionPlace"
                  content={data.SessionPlace_en}
                  disabled="disabled"
                />
              </div>
              <div className="hidden md:block overflow-x-auto overflow-y-none max-h-max relative ">
                <div className="overflow-y-none w-screen">
                  <table className="border border-gray-300  w-full">
                    <thead>
                      <tr>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Category
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Name
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Brand
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                          U.O.M.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-40">
                          Batch No.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 md:w-20">
                          Date Of Expiry
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Demo.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Damaged/Adjustments
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Exp.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto">
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={bindCategoryDD || []}
                              placeholder="Select"
                              name="Category"
                              Class="p-0"
                              disabled="disabled"
                              selectedOption={
                                bindCategoryDD
                                  ? bindCategoryDD.find(
                                      (x) => x.value === row.category
                                    )
                                  : null
                              }
                              controlFunc={(e) => {
                                handleInputChange(e, index, "category");
                              }}
                            />
                          </td>
                          <td
                            key={index}
                            className="border border-gray-300 p-2"
                          >
                            <AutoDropDown
                              options={bindNameDD || []}
                              name="name"
                              placeholder="Select"
                              disabled="disabled"
                              selectedOption={
                                bindNameDD
                                  ? bindNameDD.find((x) => x.value === row.name)
                                  : null
                              }
                              Class="p-0"
                              controlFunc={(e) => {
                                handleInputChange(e, index, "name");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={bindBrandDD || []}
                              name="brand"
                              placeholder="Select"
                              disabled="disabled"
                              selectedOption={
                                bindBrandDD
                                  ? bindBrandDD.find(
                                      (x) => x.value === row.brand
                                    )
                                  : null
                              }
                              Class="p-0"
                              controlFunc={(e) => {
                                handleInputChange(e, index, "brand");
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              content={row.unitOfMeasure}
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "unitOfMeasure"
                                );
                              }}
                              disabled="disabled"
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              placeholder="100"
                              content={row.batchNo}
                              maxLength={30}
                              controlFunc={(e) => {
                                if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                  handleInputChange(e.target, index, "batchNo");
                                }
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              inputType="date"
                              content={row.dateOfExpiry}
                              maxLength={5}
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "dateOfExpiry"
                                );
                              }}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              placeholder="100"
                              disabled="disabled"
                              content={row.demonstration}
                              maxLength={5}
                              controlFunc={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  handleInputChange(
                                    e.target,
                                    index,
                                    "demonstration"
                                  );
                                }
                              }}
                            />
                          </td>

                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              placeholder="100"
                              disabled="disabled"
                              content={row.damagedAdjustments}
                              maxLength={5}
                              controlFunc={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  handleInputChange(
                                    e.target,
                                    index,
                                    "damagedAdjustments"
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              placeholder="100"
                              content={row.expired}
                              disabled="disabled"
                              maxLength={5}
                              controlFunc={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  handleInputChange(e.target, index, "expired");
                                }
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              placeholder="100"
                              content={row.total}
                              disabled="disabled"
                              maxLength={5}
                              controlFunc={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                  handleInputChange(e.target, index, "total");
                                }
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={mainTable.tableMobile} role="table">
                <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                  {tableData.length > 0 &&
                    tableData.map((row, index) => (
                      <div scope="col" class={mainTable.mobileTable}>
                        {headerData.map((keyData) => (
                          <div
                            scope="cell"
                            class={mainTable.tableData}
                            role="cell"
                          >
                            {keyData.key === "consumptionCategory" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={bindCategoryDD || []}
                                  placeholder="Select"
                                  name="Category"
                                  Class="p-0"
                                  disabled="disabled"
                                  selectedOption={
                                    bindCategoryDD
                                      ? bindCategoryDD.find(
                                          (x) => x.value === row.category
                                        )
                                      : null
                                  }
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "category");
                                  }}
                                />
                              </div>
                            ) : keyData.key === "consumptionName" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={bindNameDD || []}
                                  name="name"
                                  placeholder="Select"
                                  disabled="disabled"
                                  selectedOption={
                                    bindNameDD
                                      ? bindNameDD.find(
                                          (x) => x.value === row.name
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "name");
                                  }}
                                />
                              </div>
                            ) : keyData.key === "consumptionBrand" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full font-bold">
                                  {keyData.title}
                                </div>
                                <AutoDropDown
                                  options={bindBrandDD || []}
                                  name="brand"
                                  placeholder="Select"
                                  disabled="disabled"
                                  selectedOption={
                                    bindBrandDD
                                      ? bindBrandDD.find(
                                          (x) => x.value === row.brand
                                        )
                                      : null
                                  }
                                  Class="p-0"
                                  controlFunc={(e) => {
                                    handleInputChange(e, index, "brand");
                                  }}
                                />
                              </div>
                            ) : keyData.key === "commoditiesUOM" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  content={row.unitOfMeasure}
                                  disabled="disabled"
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "unitOfMeasure"
                                    );
                                  }}
                                />
                              </div>
                            ) : keyData.key === "BatchNumber" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  content={row.batchNo}
                                  maxLength={30}
                                  controlFunc={(e) => {
                                    if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "batchNo"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ) : keyData.key === "DateOfExpiry" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  inputType="date"
                                  content={row.dateOfExpiry}
                                  controlFunc={(e) => {
                                    handleInputChange(
                                      e.target,
                                      index,
                                      "dateOfExpiry"
                                    );
                                  }}
                                  min={new Date().toISOString().split("T")[0]}
                                />
                              </div>
                            ) : keyData.key === "consumptionDemo" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.demonstration}
                                  maxLength={5}
                                  disabled="disabled"
                                  controlFunc={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "demonstration"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ) : keyData.key === "consumptionWaste" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.damagedAdjustments}
                                  maxLength={5}
                                  disabled="disabled"
                                  controlFunc={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "damagedAdjustments"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ) : keyData.key === "ConsumptionExp" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.expired}
                                  maxLength={5}
                                  disabled="disabled"
                                  controlFunc={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "expired"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ) : keyData.key === "ConsumptionTotal" ? (
                              <div className=" border p-1">
                                <div className="flex items-center w-full whitespace-nowrap font-bold">
                                  {keyData.title}
                                </div>
                                <InputText
                                  type="text"
                                  placeholder="100"
                                  content={row.total}
                                  maxLength={5}
                                  disabled="disabled"
                                  controlFunc={(e) => {
                                    if (/^[0-9]*$/.test(e.target.value)) {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "total"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="flex border p-1">
                                <div className="flex basis-1/2 font-bold">
                                  {keyData.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              <div className="lg:col-span-3 sm:col-span-1">
                <TextArea
                  inputType="text"
                  title="Reason"
                  name="remarks"
                  content={data.Reason_en}
                  rows={4}
                  disabled="disabled"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumptionPopup;
