import React from "react";
import { mainTable } from "../css/Common";

const AddScreenTitle = ({ addScreenTitle, updateScreenTitle, title}) => {
  return (
    <div class={mainTable.addScreenTitle}>
      {addScreenTitle && (
        <div className={mainTable.addScreenTitle}>{`${title} - Add`}</div>
      )}

      {updateScreenTitle && (
        <div className={mainTable.addScreenTitle}>{`${title} - Edit`}</div>
      )}
    </div>
  );
};

export default AddScreenTitle;
