import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { fpUserDHIS2SRHByAgeHeader } from "../components/HeaderAttribute";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";
import InputText from "../ReusableComponents/InputText";

const FPUserDHIS2SRHByAge = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindFPUserDHIS2SRHByAge, setBindFPUserDHIS2SRHByAge] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);

  
  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);


  useEffect(() => {
    if (branchProject) {
      try {
        const getSelectedSDP = async () => {
          const locationResponse = await BindAPI("SDPAPI/GetSDP");
          setGetSDPDatas(locationResponse.data.Table);

          if (userProfileDatas && userProfileDatas[0]) {
            const sdpLabels = locationResponse.data.Table.filter((item) =>
              branchProject.includes(parseInt(item.MstLocationId))
            ).map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

            const sdpSelected = sdpLabels.map((SD) => ({
              value: SD.MstSDPId,
              label: SD.SDP_en,
            }));

            if (
              userProfileDatas[0].MstOrganizationId == 1 ||
              userProfileDatas[0].MstOrganizationId == 2
            ) {
              setBindSDP(sdpSelected);
            } else {
              const allSDPIds = userProfileDatas[0]?.AllSDP;

              if (allSDPIds && Array.isArray(allSDPIds)) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  allSDPIds.includes(sdp.value)
                );
                setBindSDP(filteredSdpSelected);
              }
              const sdpId = userProfileDatas[0]?.MstSDPId;
              if (sdpId) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  Array.isArray(sdpId)
                    ? sdpId.includes(sdp.value)
                    : sdpId === sdp.value
                );

                setBindSDP(filteredSdpSelected);
              }
            }
          }
        };

        getSelectedSDP();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [
    branchProject ? branchProject : undefined,
    userProfileDatas ? userProfileDatas : undefined,
  ]);
  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchProjectArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "TXNServicesAPI/ReportFPUserDHIS2SRHByAge"
      );

      console.log("response", response.data.Table);
      setBindFPUserDHIS2SRHByAge(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };
    exportData(
      fpUserDHIS2SRHByAgeHeader.map((column) => column.title),
      bindFPUserDHIS2SRHByAge,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "FP user - SRH by age",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];
    // Initialize column totals
    const columnTotals = {
      Age0to9: 0,
      Age10to14: 0,
      Age15to19: 0,
      Age20to24: 0,
      Age25to29: 0,
      Age30to34: 0,
      Age35to39: 0,
      Age40to44: 0,
      Age45to49: 0,
      Age50andAbove: 0,
    };

    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.Age0to9 || 0) +
        Number(rowData.Age10to14 || 0) +
        Number(rowData.Age15to19 || 0) +
        Number(rowData.Age20to24 || 0) +
        Number(rowData.Age25to29 || 0) +
        Number(rowData.Age30to34 || 0) +
        Number(rowData.Age35to39 || 0) +
        Number(rowData.Age40to44 || 0) +
        Number(rowData.Age45to49 || 0) +
        Number(rowData.Age50andAbove || 0);
      // Add to column totals
      columnTotals.Age0to9 += Number(rowData.Age0to9 || 0);
      columnTotals.Age10to14 += Number(rowData.Age10to14 || 0);
      columnTotals.Age15to19 += Number(rowData.Age15to19 || 0);
      columnTotals.Age20to24 += Number(rowData.Age20to24 || 0);
      columnTotals.Age25to29 += Number(rowData.Age25to29 || 0);
      columnTotals.Age30to34 += Number(rowData.Age30to34 || 0);
      columnTotals.Age35to39 += Number(rowData.Age35to39 || 0);
      columnTotals.Age40to44 += Number(rowData.Age40to44 || 0);
      columnTotals.Age45to49 += Number(rowData.Age45to49 || 0);
      columnTotals.Age50andAbove += Number(rowData.Age50andAbove || 0);

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.PACServicesGrouped,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData.Age0to9 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age10to14 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age15to19 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age20to24 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age25to29 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age30to34 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age35to39 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age40to44 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age45to49 || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
        {
          v: Number(rowData.Age50andAbove || 0),
          t: "n",
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },

        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );
    const totalsRow = [
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.Age0to9, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age10to14, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age15to19, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age20to24, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age25to29, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age30to34, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age35to39, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age40to44, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age45to49, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age50andAbove, t: "n", s: totalsRowStyle },

      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];
    // Combine all rows
    // const wsData = [titleRow, headerRow, ...dataRows, totalsRow];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow,
      ...dataRows,
      totalsRow,
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 30 }, // Branch
      { width: 30 }, // IPC  No
      { width: 30 }, // IPC  No
      { width: 30 }, // IPC  No
      { width: 10 }, // Gender
      { width: 15 }, // Topics1
      { width: 15 }, // Topics2
      { width: 15 }, // Topics3
      { width: 20 }, // Topics4
      { width: 15 }, // Topics5
      { width: 10 }, // Topics6
      { width: 15 }, // Topics7
      { width: 15 }, // Topics8
      { width: 20 }, // Topics9
      { width: 20 }, // Topics10

      { width: 15 }, // Row Total
    ];
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 2,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create and save workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "FP user - SRH by age.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    const headers = fpUserDHIS2SRHByAgeHeader.map((column) => column.title);

    const data = bindFPUserDHIS2SRHByAge.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.PACServicesGrouped,
        rowData.Age0to9,
        rowData.Age10to14,
        rowData.Age15to19,
        rowData.Age20to24,
        rowData.Age25to29,
        rowData.Age30to34,
        rowData.Age35to39,
        rowData.Age40to44,
        rowData.Age45to49,
        rowData.Age50andAbove,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Auto adjust width
        7: { cellWidth: "auto" }, // Auto adjust width
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("FP user - SRH by age.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const sdpChange = (updatedOptions) => {
    setArea("");
    setSDP(updatedOptions);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };


  const areaChange = (updatedOptions) => {
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  console.log("branchProject111", branchProject);
  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`FP user - SRH by age`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>

              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindFPUserDHIS2SRHByAge.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={fpUserDHIS2SRHByAgeHeader}
                records={bindFPUserDHIS2SRHByAge}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default FPUserDHIS2SRHByAge;
