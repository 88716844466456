// Code review - In Progress
// Documentation - In Progress
import React from "react";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const ProjectPopup = ({ data, data1 }) => {
  debugger;
  return (
    <div class="w-full ">
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <AutoDropDown
                  classStar="text-red-500"
                  title={"Branch/Project"}
                  options={[]}
                  placeholder={"Select"}
                  name={"location"}
                  selectedOption={data1[0]}
                  Class="custom-class"
                  tabIndex="1"
                />
                <InputText
                  inputType={"text"}
                  classStar="text-red-500"
                  title={"Project"}
                  name={"Project"}
                  disabled="disabled"
                  content={data.ProjectName_en}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPopup;
