// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import TableGrid from "../components/Table";
import { studentMasterHeaders } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import InputText from "../ReusableComponents/InputText";
import CheckboxOrRadioGroup from "../ReusableComponents/CheckBoxOrRadioButton";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import {
  genderBindAPI,
  schoolBindAPI,
  studentMasterBindAPI,
  studentMasterInsertAPI,
} from "../services/StudentMasterAPI";
import StudentPopupMaster from "../popup-containers/StudentPopupMaster";

function StudentMaster() {
  const [bindStudentMasterDatas, setBindStudentMasterDatas] = useState({});
  const [bindSchoolDropDownData, setBindSchoolDropDownData] = useState([]);
  const [bindGenderDropDownData, setBindGenderDropDownData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);
  const [editMode, setEditMode] = useState([]);

  const [currentData, setCurrentData] = useState({});
  const [addScreen, setAddScreen] = useState(false);
  const [updateScreen, setUpdateScreen] = useState(false);

  // View details start

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [unBlockButton, setUnBlockButton] = useState(false);
  const [popUpOpen, setpopUpOpen] = useState(false);
  const [popViewdata, setPopViewdata] = useState({});

  // view details end

  const [currentPage1, setCurrentPage] = useState(1);
  const [rowIndex, setRowIndex] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [result, setResult] = useState(10);

  const [schoolName, setSchoolName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [division, setDivision] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");

  const [schoolNameError, setSchoolNameError] = useState("");
  const [studentNameError, setStudentNameError] = useState("");
  const [studentIDError, setStudentIDError] = useState("");
  const [studentClassError, setStudentClassError] = useState("");
  const [divisionError, setDivisionError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [genderError, setGenderError] = useState("");

  const [mstStudentId, setMstStudentId] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [searchData, setFilteredData] = useState(bindStudentMasterDatas);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreen(true); // Add Screen title & button
    setUpdateScreen(false); // Update Screen title & button
  };

  const cancel = () => {
    setCurrentPage(1);
    setResult(10);
    setpopUpOpen(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    setUpdateScreen(false);
    // clear Student data

    setStudentName("");
    setStudentID("");
    setStudentClass("");
    setDivision("");
    setAge("");
    setSchoolName("");
    setGender("");
    setStudentNameError("");
    setStudentIDError("");
    setStudentClassError("");
    setAgeError("");
    setGenderError("");
    setDivisionError("");
  };
  const handleEdit = async (rowIndex) => {
    // Edit functionality

    setEditMode(true);
    setAddScreen(false); // Add Screen title & button
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreen(true); // Update Screen title & button

    setStudentName(bindStudentMasterDatas[rowIndex].StudentName);
    setStudentID(bindStudentMasterDatas[rowIndex].StudentId);
    setStudentClass(bindStudentMasterDatas[rowIndex].StudentClass);
    setDivision(bindStudentMasterDatas[rowIndex].StudentDivision);
    setAge(bindStudentMasterDatas[rowIndex].StudentAge);
    setMstStudentId(bindStudentMasterDatas[rowIndex].MstStudentId);

    const rowData = bindStudentMasterDatas[rowIndex];
    const schoolInfo = bindSchoolDropDownData.find(
      (school) => school.value == rowData.MstSchoolId
    );
    const data = {
      value: rowData.MstSchoolId,
      label: schoolInfo ? schoolInfo.label : "Unknown",
    };

    setSchoolName(data);

    const genderRadio = bindStudentMasterDatas[rowIndex].StudentGender;
    const genderRadioString = genderRadio.toString();
    setGender(genderRadioString);
  };

  const HandleStudent = (rowIndex, flag) => {
    // Setting visibility in popup container
    setpopUpOpen(true);
    
    if (flag === 1) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setUnBlockButton(false);
    } else if (flag === 2) {
      setViewDetails(true);
      setDeleteButton(true);
      setBlockButton(false);
      setUnBlockButton(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(true);
      setRowIndex(rowIndex);
      setUnBlockButton(false);
    } else if (flag === 4) {
      setViewDetails(true);
      setDeleteButton(false);
      setBlockButton(false);
      setRowIndex(rowIndex);
      setUnBlockButton(true);
    }
    setCurrentData(bindStudentMasterDatas[rowIndex]);

    const genderRadio = bindStudentMasterDatas[rowIndex].StudentGender;
    const genderRadioString = genderRadio.toString();
    setGender(genderRadioString);

    const MstSchoolIdrowData = bindStudentMasterDatas[rowIndex];

    const SchoolIdInfo = bindSchoolDropDownData.find(
      (UM) => UM.value == MstSchoolIdrowData.MstSchoolId
    );
    const ResultScoolId = [
      {
        value: MstSchoolIdrowData.MstSchoolId,
        label: SchoolIdInfo ? SchoolIdInfo.label : "Unknown",
      },
    ];

    const Popupdatas = [ResultScoolId, genderRadioString];
    setPopViewdata(Popupdatas);
  };

  function handleDropDown(e) {}

  const handleSubmit = async (items, flag) => {
    
    // Submit functionality

    if (flag == 1 || flag == 2) {
      // validation start
      let schoolNameValidate = "";
      let studentNameValidate = "";
      let studentIDValidate = "";
      let studentClassValidate = "";
      let DivisionValidate = "";
      let ageValidate = "";
      let genderValidate = "";

      schoolNameValidate = schoolName ? "" : "Select School Name";
      setSchoolNameError(schoolNameValidate);
      studentNameValidate =
        studentName.length > 0
          ? /^[A-Za-z ]+$/.test(studentName)
            ? ""
            : "Only Alphabet and space allowed"
          : "Enter Student Name";
      setStudentNameError(studentNameValidate);

      studentIDValidate = studentID.length > 0 ? "" : "Enter Student Id";
      setStudentIDError(studentIDValidate);

      studentClassValidate =
        studentClass.length > 0 ? "" : "Enter Student Class";
      setStudentClassError(studentClassValidate);

      DivisionValidate = division.length > 0 ? "" : "Enter Student Division";
      setDivisionError(DivisionValidate);

      ageValidate =
        age > 0
          ? /^[0-9 ]{2}$/.test(age)
            ? ""
            : "Only Numbers allowed"
          : "Enter Student Age";
      setDivisionError(DivisionValidate);

      genderValidate = gender.length > 0 ? "" : "Choose Gender";
      setGenderError(genderValidate);

      // Validation end
      if (
        !schoolNameValidate &&
        !studentNameValidate &&
        !studentIDValidate &&
        !studentClassValidate &&
        !DivisionValidate &&
        !ageValidate &&
        !genderValidate
      ) {
        const studentPayload = [
          {
            mstStudentId: mstStudentId ? mstStudentId : 0,
            mstSchoolId: schoolName.value,
            studentName: studentName,
            studentId: studentID,
            studentClass: studentClass,
            studentDivision: division,
            studentAge: age,
            studentGender: gender,
            createdBy: 22,
            flag: flag,
          },
        ];

        const response = await studentMasterInsertAPI(studentPayload);
        if (flag == 1) {
          // Submit
          
          if (response.data.Table[0].Result === "S") {
            alert("Student data Insert Successfully");
            cancel();
          } else {
            alert("Student data Insert Failed");
          }
        } else if (flag == 2) {
          // Update
          if (response.data.Table[0].Result === "M") {
            alert("Student data Updated Successfully");
            cancel();
          } else {
            alert("Student data Updated Failed");
          }
        }
      }
    } else {
      const studentPayload = [
        {
          mstStudentId: bindStudentMasterDatas[rowIndex].MstStudentId,
          createdBy: 3,
          flag: flag,
        },
      ];
      const response = await studentMasterInsertAPI(studentPayload);
      if (flag == 3) {
        // Delete
        if (response.data.Table[0].Result === "D") {
          alert("Student data Deleted Successfully");
          cancel();
        } else {
          alert("Student data Deleted Failed");
        }
      } else if (flag == 4) {
        // Block
        if (response.data.Table[0].Result === "B") {
          alert("Student data Blocked Successfully");
          cancel();
        } else {
          alert("Student data Blocked Failed");
        }
      } else if (flag == 5) {
        // Unblock
        if (response.data.Table[0].Result === "UB") {
          alert("Student data UnBlocked Successfully");
          cancel();
        } else {
          alert("Student data UnBlocked Failed");
        }
      }
    }
  };

  useEffect(() => {
    // Bind Data functionality
    const studentMaster = async () => {
      const response = await studentMasterBindAPI();
      setBindStudentMasterDatas(response.data.Table);
    };

    studentMaster();
  }, []);
  useEffect(() => {
    // Bind School name functionality
    
    const getSchoolDropdownValues = async () => {
      const response = await schoolBindAPI();
      const getMstSchoolId =
        response.data.Table && response.data.Table.length > 0
          ? response.data.Table.map((item) => ({
              value: item.MstSchoolId,
              label: item.SchoolName,
            }))
          : [];
      setBindSchoolDropDownData(getMstSchoolId);
    };

    getSchoolDropdownValues();
  }, []);

  useEffect(() => {
    // Bind Gender functionality
    
    const getGenderDropdownValues = async () => {
      const response = await genderBindAPI();
      const getMstGenderId =
        response.data.Table && response.data.Table.length > 0
          ? response.data.Table.map((item) => ({
              value: item.MstGenderId,
              label: item.Gender,
            }))
          : [];
      setBindGenderDropDownData(getMstGenderId);
    };

    getGenderDropdownValues();
  }, []);

  const handleSearch = (input) => {
    const filtered = bindStudentMasterDatas.filter((item) => {
      const providerData = `${item.StudentName} ${item.S} ${item.SchoolName} ${item.StudentId}
      ${item.StudentGender}`;
      return providerData.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredData(filtered);
  };

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
  };

  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <div className="max-md:absolute">
          <div className={mainTable.headerTitle}>
            <div className="my-2 mx-12 lg:mx-0">{`Student Master`}</div>
          </div>
          <div className="justify-between flex items-center">
            <div className={mainTable.searchAdd}>
              <div className="mt-1 gap-2 flex justify-between items-baseline">
                <label for="pageSize" className="hidden md:block">
                  Rows Per Page:
                </label>
                <select
                  onChange={handleDropDown}
                  className={mainTable.dropDownRecords}
                  name="pageSize"
                >
                  <option value="2">2</option>
                  <option value="5" selected>
                    5
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div>
                <label for="table-search" className="sr-only">
                  Search
                </label>
              </div>
              <div className="relative mt-1">
                <div className={mainTable.searchIconPosition}>
                  <svg
                    className={mainTable.searchIconSvg}
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className={mainTable.searchInputBox}
                  placeholder="Search for items"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            <div className="flex self-end">
              <div className="text-red-600 bg-gray-100 border border-gray-100 rounded-lg shadow-inner w-fit h-9 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                <span className="mb-12 text-red-600 text-7xl">.</span>
                <span>Block</span>
              </div>
              <button
                type="button"
                className={mainTable.addButton}
                onClick={showAdd}
              >
                <svg
                  width="25"
                  height="20"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 37.5H27.5V27.5H37.5V22.5H27.5V12.5H22.5V22.5H12.5V27.5H22.5V37.5ZM25 50C21.5417 50 18.2917 49.3433 15.25 48.03C12.2083 46.7183 9.5625 44.9375 7.3125 42.6875C5.0625 40.4375 3.28167 37.7917 1.97 34.75C0.656667 31.7083 0 28.4583 0 25C0 21.5417 0.656667 18.2917 1.97 15.25C3.28167 12.2083 5.0625 9.5625 7.3125 7.3125C9.5625 5.0625 12.2083 3.28083 15.25 1.9675C18.2917 0.655833 21.5417 0 25 0C28.4583 0 31.7083 0.655833 34.75 1.9675C37.7917 3.28083 40.4375 5.0625 42.6875 7.3125C44.9375 9.5625 46.7183 12.2083 48.03 15.25C49.3433 18.2917 50 21.5417 50 25C50 28.4583 49.3433 31.7083 48.03 34.75C46.7183 37.7917 44.9375 40.4375 42.6875 42.6875C40.4375 44.9375 37.7917 46.7183 34.75 48.03C31.7083 49.3433 28.4583 50 25 50ZM25 45C30.5833 45 35.3125 43.0625 39.1875 39.1875C43.0625 35.3125 45 30.5833 45 25C45 19.4167 43.0625 14.6875 39.1875 10.8125C35.3125 6.9375 30.5833 5 25 5C19.4167 5 14.6875 6.9375 10.8125 10.8125C6.9375 14.6875 5 19.4167 5 25C5 30.5833 6.9375 35.3125 10.8125 39.1875C14.6875 43.0625 19.4167 45 25 45Z"
                    fill="white"
                  />
                </svg>
                ADD
              </button>
            </div>
          </div>
          <TableGrid
            className={mainTable.table}
            headerData={studentMasterHeaders}
            tableData={
              searchData.length > 0 ? searchData : bindStudentMasterDatas
            }
            onEditGrid={handleEdit}
            onDeleteGrid={HandleStudent}
            onBlockGrid={HandleStudent}
            onViewGrid={HandleStudent}
          />
          <div className={mainTable.submitCancelMainDiv}>
            <button
              disabled={currentPage1 === 1}
              onClick={() => setCurrentPage(currentPage1 - 1)}
              type="button"
              className={mainTable.submitButton}
            >
              Previous
            </button>
            <label>{currentPage1}</label>
            <button
              disabled={currentPage1 === totalPages / result}
              onClick={() => setCurrentPage(currentPage1 + 1)}
              type="button"
              className={mainTable.cancelButton}
            >
              Next
            </button>
          </div>
        </div>
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            {addScreen && (
              <div className={mainTable.addScreenTitle}>
                {"Student Master - Add"}
              </div>
            )}
            {updateScreen && (
              <div className={mainTable.addScreenTitle}>
                {" Student Master - Edit"}
              </div>
            )}
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid3}>
                    <div>
                      <AutoDropDown
                        title="School Name"
                        options={bindSchoolDropDownData}
                        placeholder="Select"
                        name="state"
                        selectedOption={schoolName}
                        controlFunc={(value) => {
                          setSchoolName(value);
                          setSchoolNameError(value ? "" : "Select School Name");
                        }}
                        controlFuncBlur={() => {
                          setSchoolNameError(
                            schoolName ? "" : "Select School Name"
                          );
                        }}
                        Class="custom-class"
                        tabIndex="1"
                        classStar="text-red-500"
                      />
                      {schoolNameError && (
                        <span class="text-red-500">{schoolNameError}</span>
                      )}
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Student Name"
                        name="studentName"
                        content={studentName}
                        controlFunc={(e) => setStudentName(e.target.value)}
                        controlFuncBlur={(e) => {
                          setStudentName(e.target.value);
                          setStudentNameError(
                            studentName.length > 0
                              ? /^[A-Za-z ]+$/.test(studentName)
                                ? ""
                                : "Only Alphabet and space allowed"
                              : "Enter Student Name"
                          );
                        }}
                      />
                      {studentNameError && (
                        <span class="text-red-500">{studentNameError}</span>
                      )}
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        classStar="text-red-500"
                        title="Student ID."
                        name="studentID"
                        content={studentID}
                        controlFunc={(e) => setStudentID(e.target.value)}
                        controlFuncBlur={(e) => {
                          setStudentID(e.target.value);
                          setStudentIDError(
                            studentID.length > 0 ? "" : "Enter Student Id"
                          );
                        }}
                      />
                      {studentIDError && (
                        <span class="text-red-500">{studentIDError}</span>
                      )}
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Class"
                        name="studentClass"
                        classStar="text-red-500"
                        content={studentClass}
                        controlFunc={(e) => setStudentClass(e.target.value)}
                        controlFuncBlur={(e) => {
                          setStudentClass(e.target.value);
                          setStudentClassError(
                            studentClass.length > 0 ? "" : "Enter Student Class"
                          );
                        }}
                      />
                      {studentClassError && (
                        <span class="text-red-500">{studentClassError}</span>
                      )}
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Division"
                        classStar="text-red-500"
                        name="division"
                        content={division}
                        controlFunc={(e) => setDivision(e.target.value)}
                        controlFuncBlur={(e) => {
                          setDivision(e.target.value);
                          setDivisionError(
                            division.length > 0 ? "" : "Enter Student Division"
                          );
                        }}
                      />
                      {divisionError && (
                        <span class="text-red-500">{divisionError}</span>
                      )}
                    </div>
                    <div>
                      <InputText
                        inputType="text"
                        title="Age"
                        classStar="text-red-500"
                        name="age"
                        content={age}
                        controlFunc={(e) => setAge(e.target.value)}
                        controlFuncBlur={(e) => {
                          setAge(e.target.value);
                          setAgeError(
                            age > 0
                              ? /^[0-9 ]{2}$/.test(age)
                                ? ""
                                : "Only Numbers allowed"
                              : "Enter Student Age"
                          );
                        }}
                      />
                      {ageError && <span class="text-red-500">{ageError}</span>}
                    </div>
                    <div className="col-span-2">
                      <CheckboxOrRadioGroup
                        title="Gender"
                        type={"radio"}
                        classStar="text-red-500"
                        setName={"gender"}
                        selectedOptions={gender}
                        options={bindGenderDropDownData}
                        controlFunc={(e) => setGender(e.target.value)}
                        controlFuncBlur={(e) => {
                          setGender(e.target.value);
                          setGenderError(
                            gender.length > 0 ? "" : "Enter Student Age"
                          );
                        }}
                      />
                      {genderError && (
                        <span class="text-red-500">{genderError}</span>
                      )}
                    </div>
                  </div>
                </form>
                <div className={mainTable.submitCancelMainDiv}>
                  {addScreen && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="button"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 1)}
                    >
                      SUBMIT
                    </button>
                  )}
                  {updateScreen && (
                    <button
                      data-modal-hide="bottom-right-modal"
                      type="submitButton"
                      className={mainTable.submitButton}
                      onClick={() => handleSubmit(0, 2)}
                    >
                      UPDATE
                    </button>
                  )}
                  <button
                    data-modal-hide="bottom-right-modal"
                    type="button"
                    className={mainTable.cancelButton}
                    onClick={cancel}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div>
          <button className="w-full">
            <span
              className="text-white flex justify-end"
              onClick={() => setpopUpOpen(false)}
              class="text-2xl p-0 m-o block float-right mr-3"
            >
              &times;
            </span>
          </button>
          <StudentPopupMaster data={currentData} data1={popViewdata} />
        </div>
        <div className={mainTable.submitCancelMainDiv}>
          {deleteButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 3)}
            >
              Delete
            </button>
          )}
          {blockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 4)}
            >
              Block
            </button>
          )}
          {unBlockButton && (
            <button
              data-modal-hide="bottom-right-modal"
              type="button"
              className={mainTable.submitButton}
              onClick={() => handleSubmit(rowIndex, 5)}
            >
              Unblock
            </button>
          )}
          <button
            data-modal-hide="bottom-right-modal"
            type="button"
            className={mainTable.cancelButton}
            onClick={() => setpopUpOpen(false)}
          >
            CANCEL
          </button>
        </div>
      </MainPopup>
    </MainLayout>
  );
}
export default StudentMaster;
