import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";
import {
  cseInSchoolHeaders,
  inschoolsessionStudentHeader,
} from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import MainPopup from "../popup-containers/MainPopup";
import DropDown from "../ReusableComponents/DropDown";
import TextArea from "../ReusableComponents/TextArea";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import moment from "moment/moment";
import InSchoolPopup from "../popup-containers/InSchoolPopup";
import Checkbox from "../ReusableComponents/Checkbox";
import toast, { Toaster } from "react-hot-toast";
import { BindPostAPI, IUDAPI } from "../services/APIConnection";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import FileUpload from "../ReusableComponents/FileUpload";
import Buttons from "../components/Buttons";
import { useLocation } from "react-router-dom";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function InSchoolSession() {
  const location = useLocation();
  //Value store States
  const [mstBranchId, setMstBranchId] = useState("");
  const { userProfileState } = useUserCapability();
  const [inSchoolSessionFields, setInSchoolSessionFields] = useState({
    session: "",
    mstSchoolId: "",
    cseClass: "",
    division: "",
    resourcePersonType: "",
    resourcePersonName1: "",
    resourcePersonName2: "",
    date: new Date(),
    startTime: getCurrentTime(),
    endTime: getCurrentTime(),
    showImage: "",
    remarks: "",
    GridTableClass: "",
  });
  const [inSchoolSessionFieldsError, setInSchoolSessionFieldsError] = useState({
    sessionError: "",
    mstSchoolIdError: "",
    cseClassError: "",
    divisionError: "",
    resourcePersonTypeError: "",
    resourcePersonNameError: "",
    dateError: "",
    startTimeError: "",
    endTimeError: "",
    showImageError: "",
    remarksError: "",
  });
  const handleInSchoolFieldsChange = (value, name) => {
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      [name]: value,
    });
  };
  const [inSchoolId, setInSchoolId] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [sessionAddDisability, setSessionAddDisability] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const [bindDBStudents, setBindDBStudents] = useState([]);
  const [bindResourcePersonType, setBindResourcePersonType] = useState([]);
  const [bindResourcePersonName, setBindResourcePersonName] = useState([]);
  const [bindSession, setBindSession] = useState([]);
  const [bindClass, setBindClass] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModules1, setSelectedModules1] = useState([]);
  const [selectedModules2, setSelectedModules2] = useState("");
  const [viewAction, setViewAction] = useState(false);

  const [bindCSEAgeDD, setBindCSEAgeDD] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [bindServicesName, setBindreferelserviceDD] = useState([]);
  const [bindGenderDD, setBindGenderDD] = useState([]);
  const [bindReferedTo, setBindReferedTo] = useState([]);
  const [bindSchoolDD, setBindSchoolDD] = useState([]);
  const [bindCSEDatas, setBindCSEDatas] = useState([]);
  const [bindModules, setBindModules] = useState([]);

  const bindAllDropdownData = async () => {
    try {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        setMstBranchId(userProfileDatas[0].MstLocationId);
        const data = {
          mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
          mstBranchId: userProfileDatas[0].MstLocationId,
          mstSdpId: userProfileDatas[0].MstSDPId
            ? userProfileDatas[0].MstSDPId
            : 0,
          mstUserId: userProfileDatas[0].MstUserId,
          mstRoleId: userProfileDatas[0].MstRoleId,
        };
        const response = await BindPostAPI(
          [data],
          "CSEInSchoolAPI/BindCSEAllMasterDatas"
        );

        if (response.data) {
          // Extracting Modules data
          const Modulesdatadrp =
            response.data.Table.length > 0
              ? response.data.Table.map((m) => ({
                  value: m.MstCSEModuleID,
                  label: m.CSEModule,
                }))
              : [];
          setBindModules(Modulesdatadrp);

          // Extracting Age data
          const AgeDD =
            response.data.Table1.length > 0
              ? response.data.Table1.map((m) => ({
                  value: m.MstAgeId,
                  label: m.Age,
                }))
              : [];
          setBindCSEAgeDD(AgeDD);

          // Extracting Gender data
          const GenderDD =
            response.data.Table2.length > 0
              ? response.data.Table2.map((m) => ({
                  value: m.MstGenderId,
                  label: m.Gender,
                }))
              : [];
          setBindGenderDD(GenderDD);

          // Extracting Referral Services data
          const ReferredBydatadrpDD =
            response.data.Table3.length > 0
              ? response.data.Table3.map((m) => ({
                  value: m.MstReferralServicesId,
                  label: m.ReferralServices,
                }))
              : [];
          setBindreferelserviceDD(ReferredBydatadrpDD);

          // Extracting Referred To data
          const ReferredTodatadrpDD =
            response.data.Table4.length > 0
              ? response.data.Table4.map((m) => ({
                  value: m.MstReferredToId,
                  label: m.ReferredTo_en,
                }))
              : [];
          setBindReferedTo(ReferredTodatadrpDD);

          // Extracting CSE Class data
          const ClassDataDD =
            response.data.Table5.length > 0
              ? response.data.Table5.map((m) => ({
                  value: m.MstCSEClassId,
                  label: m.MstCSEClass,
                }))
              : [];
          setBindClass(ClassDataDD);

          // Extracting School data

          const SchoolDataDD =
            response.data.Table6.length > 0
              ? response.data.Table6.map((m) => ({
                  value: m.MstSchoolId,
                  label: m.SchoolName,
                }))
              : [];
          setBindSchoolDD(SchoolDataDD);
        } else {
          setBindSchoolDD([]);
        }

        // Extracting Resource Person Type data
        const ResourceDataDD =
          response.data.Table7.length > 0
            ? response.data.Table7.map((m) => ({
                value: m.MstResourcepersonTypeId,
                label: m.ResourcepersonType,
              }))
            : [];
        setBindResourcePersonType(ResourceDataDD);

        const ResourcePersonNameDD =
          response.data.Table9.length > 0
            ? response.data.Table9.map((m) => ({
                value: m.MstResourcesId,
                label: m.ResourcesName,
              }))
            : [];
        setBindResourcePersonName(ResourcePersonNameDD);
      } else {
        // Handle the case where response.data is undefined or has no data
        setBindModules([]);
        setBindCSEAgeDD([]);
        setBindGenderDD([]);
        setBindreferelserviceDD([]);
        setBindReferedTo([]);
        setBindClass([]);
        setBindSchoolDD([]);
        setBindResourcePersonType([]);
        setBindResourcePersonName([]);
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      // Handle error cases for each dropdown if needed
    }
  };

  const handleModulesChange = (value) => {
    // Assuming setSelectedModules1 is a state setter and selectedModules1 is the current state value
    const newValues = value.filter((item) => !selectedModules1.includes(item));

    setSelectedModules(value);
    setSelectedModules1(value);
  };

  const [topicsError, setTopicsError] = useState("");
  const [studentDetailsError, setStudentDetailsError] = useState("");
  const [minStudentDetailsError, setMinStudentDetailsError] = useState("");

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleAddPage, setVisibleAddPage] = useState(false);
  // const [visibleAddPage, setVisibleAddPage] = useState(false);
  const [visibleTable, setVisibleTable] = useState(true);

  const [sessionIdEdit, setSessionIdEdit] = useState("");

  const [currentData1, setCurrentData1] = useState({});
  const [currentData2, setCurrentData2] = useState({});
  const [currentData3, setCurrentData3] = useState({});
  const [currentData4, setCurrentData4] = useState({});

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [rowIndex, setRowIndex] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [studentDetails, setStudentDetails] = useState("");

  const showAdd = () => {
    setInSchoolSessionFields({
      session: 1,
    });
    setViewAction(false);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setViewAction(false);
    getBranch(userProfileDatas);
    // setInSchoolSessionFields({
    //   ...inSchoolSessionFields,
    //   startTime: getCurrentTime(),
    //   endTime: getCurrentTime(),
    //   date: new Date(),
    // });
  };
  const showAddSession = async (rowIndex) => {
    
    setSessionAddDisability(true);
    setVisibleAddPage(true);
    const bind = bindCSEDatas[rowIndex];

    console.log("bindDatas", bind);
    console.log("bindCSEDatas", bindCSEDatas);

    const lastSession = bind.Sessions.split(",").map(
      (value) => parseInt(value.trim(), 10) % 10
    ); // % 10 gives the last digit
    const length = lastSession.length - 1;
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      session: lastSession[length] + 1,
      mstSchoolId: bind.MstSchoolId,
      cseClass: bind.Class,
      division: bind.Division,
      startTime: getCurrentTime(),
      endTime: getCurrentTime(),
      date: new Date(),
    });
    const data = {
      year: parseInt(bind.Date.split("-")[0]),
      mstSchoolId: parseInt(bind.MstSchoolId),
      class: parseInt(bind.Class),
      division: bind.Division,
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/BindCSEInSchoolPreviousData"
    );
    // const topics1 = response.data.Table1[0].Topics;
    const modules = response.data.Table1[0].Topics?.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules([]);
    setSelectedModules1(modules);
    setSelectedModules2(modules);

    appendDataToStudentTable(JSON.stringify(response.data.Table));
    setViewAction(false);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    setViewAction(false);
  };
  const cancel = () => {
    setStudentDetails("");
    setStudentDetailsError("");
    setMinStudentDetailsError("");
    setVisibleAddPage(false);
    setSessionAddDisability(false);
    setSubmitBtnDisabled(false);
    setTopicsError("");
    setViewAction(false);
    setCurrentData1({});
    setCurrentData2({});
    setCurrentData3({});
    setCurrentData4({});

    const reset = {
      studentName: "",
      studentId: "",
      gender: "",
      age: "",
      referralService: "",
      referredTo: "",
    };
    setTableData([reset]);
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      // sessionNo: "",
      session: "",
      mstSchoolId: "",
      cseClass: "",
      division: "",
      resourcePersonType: "",
      resourcePersonName1: "",
      resourcePersonName2: "",
      date: "",
      startTime: "",
      endTime: "",
      showImage: "",
      remarks: "",
    });

    setInSchoolSessionFieldsError({
      ...inSchoolSessionFieldsError,
      sessionError: "",
      mstSchoolIdError: "",
      cseClassError: "",
      divisionError: "",
      resourcePersonNameError: "",
      resourcePersonTypeError: "",
      dateError: "",
      startTimeError: "",
      endTimeError: "",
      showImageError: "",
      remarksError: "",
    });
    BindCSEGridData(userProfileDatas);
    setVisibleAdd(false);
    setVisibleTable(true);
    setpopUpOpen(false);
    setAddScreenTitle(false);
    setSelectedModules([]);
    setSelectedModules1([]);
    setUpdateScreenTitle(false);
    setStudentDetailsError("");
  };
  useEffect(() => {
    if (addScreenTitle) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setInSchoolSessionFields({
        ...inSchoolSessionFields,
        date: formattedDate,
      });
    }
  }, [addScreenTitle ? addScreenTitle : false]);
  const createdBy = sessionStorage.getItem("createdBy");
  const handleSubmit = async (items, flag) => {    
    setSubmitBtnDisabled(true);
    if (flag == 1 || flag == 2) {
      const isFormError = {
        ...inSchoolSessionFieldsError,
        sessionError: inSchoolSessionFields.session ? "" : "Select Session",
        mstSchoolIdError: inSchoolSessionFields.mstSchoolId
          ? ""
          : "Select School Name",
        cseClassError: inSchoolSessionFields.cseClass
          ? ""
          : "Select Class / Std.",
        divisionError: inSchoolSessionFields.division ? "" : "Enter Division",
        resourcePersonNameError: inSchoolSessionFields.resourcePersonName1
          ? ""
          : "Select Resource Person Name 1",
        resourcePersonTypeError: inSchoolSessionFields.resourcePersonType
          ? ""
          : "Select Type Of Resource Person",
        dateError: inSchoolSessionFields.date ? "" : "Select Date",
        startTimeError: inSchoolSessionFields.startTime
          ? ""
          : "Select Start Time",
        endTimeError: inSchoolSessionFields.endTime ? "" : "Select End Time",
        // showImageError: inSchoolSessionFields.showImage
        //   ? ""
        //   : "Please Upload an Image",
      };
      debugger;
      setInSchoolSessionFieldsError(isFormError);
      var topicsArrayString;
      if (updateScreenTitle){
        debugger;
        topicsArrayString =
          selectedModules1.length > 0
            ? JSON.stringify(selectedModules1)
            : JSON.stringify(selectedModules);
      }
      if (addScreenTitle) {
        debugger;
        const newValues = selectedModules.filter(
          (item) => !selectedModules2.includes(item)
        );
        topicsArrayString = JSON.stringify(newValues);
      }
      const topicsValidation =
        topicsArrayString != "[]" ? "" : "Select Module";
      // const topicsValidation = addScreenTitle
      //   ? selectedModules
      //     ? ""
      //     : "Select Module"
      //   : updateScreenTitle
      //   ? selectedModules1
      //     ? ""
      //     : "Select Module"
      //   : "";
      setTopicsError(topicsValidation);
      const isFormValid = Object.values(isFormError).every(
        (error) => error === ""
      );
      if (!isFormValid || topicsValidation || studentDetailsError) {
        setIsOpen(true);
      }
      // Early validation for studentDetails
      if (!studentDetails || studentDetails.trim() === "") {
        setStudentDetailsError("Fill the above table");
        setSubmitBtnDisabled(false);
        return; // Stop further execution
      }

      let studentData;
      try {
        studentData = JSON.parse(studentDetails);
      } catch (error) {
        console.error("Error parsing student data:", error);
        studentData = [];
      }

      // Check if student data is an array
      if (!Array.isArray(studentData)) {
        debugger;
        console.error("Student data is not an array");
        studentData = [];
      }

      // Map over student data to check for errors and collect error messages
      const studentErrors = studentData.map((student, index) => {
        debugger;
        if (!student) {
          setSubmitBtnDisabled(false);
          return `Student at row ${index + 1} is missing.`;
        }
        if (!student.studentName) {
          setSubmitBtnDisabled(false);
          return `Student at row ${index + 1} is missing a name.`;
        }
        if (!student.gender) {
          setSubmitBtnDisabled(false);
          return `Student at row ${index + 1} is missing a Ggnder.`;
        }
        if (!student.age) {
          setSubmitBtnDisabled(false);
          return `Student at row ${index + 1} is missing an age.`;
        }
        if (student.referralService && !student.referredTo) {
          setSubmitBtnDisabled(false);
          return `Student at row ${
            index + 1
          } has Referral Service provided but is missing Referred To field.`;
        }

        return null;
      });

      // Filter out null errors
      const filteredErrors = studentErrors.filter((error) => error !== null);

      // Check if there are any errors in student data and set the error message accordingly
      if (filteredErrors.length > 0) {
        setStudentDetailsError(filteredErrors.join("; "));
        return; // Stop further execution if there are student errors
      } else {
        setStudentDetailsError(""); // No errors found
      }
      debugger;
      if (studentData.length < 5) {
        setIsOpen(true);
        setStudentDetailsError(
          "A minimum of 5 rows is required. Please enter the student details"
        );
        const studentErrorsMin =
          "A minimum of 5 rows is required. Please enter the student details";
        setMinStudentDetailsError(studentErrorsMin);
      } 
      // else {
      //   setStudentDetailsError(""); // No errors found
      //   setMinStudentDetailsError("");
      // }
      if (
        isFormValid &&
        !topicsValidation &&
        !studentDetailsError &&
        studentData.length >= 5
      ) {
        setIsOpen(false);
        // Assuming your API expects a format like "YYYY-MM-DDTHH:mm:ss"
        const currentDate = new Date().toISOString().split("T")[0];

        if (updateScreenTitle)
          var topicsArrayString =
            selectedModules1.length > 0
              ? JSON.stringify(selectedModules1)
              : JSON.stringify(selectedModules);
        if (addScreenTitle) {
          const newValues = selectedModules.filter(
            (item) => !selectedModules2.includes(item)
          );
          var topicsArrayString = JSON.stringify(newValues);
        }
        topicsArrayString = topicsArrayString.slice(1, -1);
        topicsArrayString = topicsArrayString.replace(/\"/g, "");
        var sessionDate = "2023-10-22";
        var fromTimes = sessionDate + "T" + inSchoolSessionFields.startTime;
        var toTimes = sessionDate + "T" + inSchoolSessionFields.endTime;

        const data = {
          TxnInSchoolSessionId: sessionIdEdit ? sessionIdEdit : 0,
          session: parseInt(inSchoolSessionFields.session),
          MstSchoolId: inSchoolSessionFields.mstSchoolId,
          class: inSchoolSessionFields.cseClass
            ? parseInt(inSchoolSessionFields.cseClass)
            : 0,
          division: inSchoolSessionFields.division
            ? inSchoolSessionFields.division
            : "0",
          resourcePersonType: parseInt(
            inSchoolSessionFields.resourcePersonType
          ),
          resourcePersonName1: inSchoolSessionFields.resourcePersonName1,
          resourcePersonName2:
            inSchoolSessionFields.resourcePersonName2 != ""
              ? inSchoolSessionFields.resourcePersonName2
              : 0,
          date: inSchoolSessionFields.date,
          startTime: fromTimes,
          endTime: toTimes,
          modules: topicsArrayString,
          fileUpload: inSchoolSessionFields.showImage,
          remarks: inSchoolSessionFields.remarks,
          cseInSchoolStudents: studentDetails,
          mstBranchId: mstBranchId,
          createdBy: parseInt(createdBy),
          flag: flag,
        };
        const response = await IUDAPI([data], "CSEInSchoolAPI/IUDCSEInSchool");
        if (flag == 1) {
          if (response.data.Table[0].result === "S") {
            toast.success("In-School session data added successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to add In-School session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
        if (flag == 2) {
          if (response.data.Table[0].result === "U") {
            toast.success("In-School session data updated successfully 👍", {
              duration: 5000,
              position: "top-center",
            });
            setTimeout(cancel, 5000);
          } else {
            toast.error("Failed to update In-School session data 👎", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          }
        }
      } else {
        setSubmitBtnDisabled(false);
      }
    } else if (flag == 3 || flag == 4 || flag == 5) {
      const data = {
        TxnInSchoolSessionId: inSchoolId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI([data], "CSEInSchoolAPI/IUDCSEInSchool");
      if (flag == 3) {
        if (response.data.Table[0].result === "D") {
          toast.success("In-School session data deleted successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to delete In-School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].result === "B") {
          toast.success("In-School session data blocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to block In-School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].result === "UB") {
          toast.success("In-School session data unblocked successfully 👍", {
            duration: 5000,
            position: "top-center",
          });
          setTimeout(cancel, 5000);
        } else {
          toast.error("Failed to unblock In-School session data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    }
    // else{
    // setSubmitBtnDisabled(false);
    // }
  };
  const handleISSDetails = async (rowIndex, flag) => {
    setpopUpOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setRowIndex(rowIndex);
      setUnBlockDetails(false);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setRowIndex(rowIndex);
      setUnBlockDetails(true);
    }
    const sessionsDD = bindCSEDatas[rowIndex].Sessions.split(",").map(
      (value) => ({ value: parseInt(value), label: value })
    );
    const dataArray = bindCSEDatas[rowIndex].Sessions.split(",");
    const editSessionNumber = parseInt(dataArray[dataArray.length - 1]);
    setCurrentData2(editSessionNumber);
    const data = {
      year: parseInt(bindCSEDatas[rowIndex].Date.split("-")[0]),
      mstSchoolId: parseInt(bindCSEDatas[rowIndex].MstSchoolId),
      class: parseInt(bindCSEDatas[rowIndex].Class),
      division: bindCSEDatas[rowIndex].Division,
      sessionNumber: editSessionNumber,
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/GetCSEInSchoolData"
    );
    setCurrentData1(response.data.Table);
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );

    setCurrentData3(response.data.Table1);

    const drpdetailsData = [
      bindModules,
      bindCSEAgeDD,
      bindGenderDD,
      bindReferedTo,
      bindServicesName,
      bindResourcePersonType,
      bindSchoolDD,
      bindClass,
      sessionsDD,
    ];
    setCurrentData4({});
    setCurrentData4(drpdetailsData);
  };
  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
    if (tableData.length > 0) {
      setSubmitBtnDisabled(false);
      setStudentDetailsError("");
    }
  };
  const convertArrayToJSONString = (array) => {
    console.log("convertArrayToJSONString", array);
    try {
      const jsonString = JSON.stringify(array);
      setStudentDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const [tableData, setTableData] = useState([
    {
      studentName: "",
      studentId: "",
      gender: "",
      age: "",
      referralService: "",
      referredTo: "",
    },
  ]);

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          studentName: "",
          studentId: "",
          gender: "",
          age: "",
          referralService: "",
          referredTo: "",
        };
        updatedTableData.push(newRow); // Add a default row if the table is empty
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.studentName = ""; // Clear the values of the new row
    newRow.studentId = "";
    newRow.gender = "";
    newRow.age = "";
    newRow.referralService = "";
    newRow.referredTo = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  // const [bindResourcePersonType, setBindResourcePersonType] = useState([]);
  // useEffect(() => {
  //   BindCSEGridData();
  // }, []);
  const BindCSEGridData = async (userProfileDatas) => {
    if (userProfileDatas && userProfileDatas[0]) {
      if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
        setMstBranchId(userProfileDatas[0].MstLocationId);
      } else {
        // handle else case if needed
      }
      const data = {
        mstOrganizationStructureId: userProfileDatas[0].MstOrganizationId,
        mstBranchId: userProfileDatas[0].MstLocationId,
        mstSdpId: userProfileDatas[0].MstSDPId
          ? userProfileDatas[0].MstSDPId
          : 0,
        mstUserId: userProfileDatas[0].MstUserId,
        mstRoleId: userProfileDatas[0].MstRoleId,
      };
      const response = await BindPostAPI(
        [data],
        "CSEInSchoolAPI/BindCSEInSchoolData"
      );
      setBindCSEDatas(response.data.Table);
      console.log("bindCSEDatas", bindCSEDatas);
      setBindDBStudents(response.data.Table2);
    }
  };
  const headerData = [
    {
      title: "Student Name",
      key: "StudentName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Student ID.",
      key: "StudentId",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Gender",
      key: "Gender_en",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Age",
      key: "Age",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referral Service",
      key: "ReferralServices",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Referred To",
      key: "ReferredTo_en",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const handleSession = async (value) => {
    setVisibleAddPage(true);
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      session: parseInt(value),
    });
    const data = {
      year: parseInt(inSchoolSessionFields.date.split("-")[0]),
      mstSchoolId: parseInt(inSchoolSessionFields.mstSchoolId),
      class: parseInt(inSchoolSessionFields.cseClass),
      division: inSchoolSessionFields.division,
      sessionNumber: parseInt(value),
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/GetCSEInSchoolData"
    );
    const sessionId = response.data.Table[0].TxnInSchoolSessionId;
    setSessionIdEdit(sessionId);
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      session: parseInt(value),
      resourcePersonType: response.data.Table[0].ResourcePersonType,
      resourcePersonName1: response.data.Table[0].ResourcePersonName1,
      resourcePersonName2: response.data.Table[0].ResourcePersonName2,
      date: moment(response.data.Table[0].Date).format("YYYY-MM-DD"),
      startTime: moment(response.data.Table[0].StartTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      showImage: response.data.Table[0].FileUpload,
      remarks: response.data.Table[0].Remarks,
    });
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules1(modules);
    // setSelectedModules(modules);
    // const reset = {
    //   studentName: "",
    //   studentId: "",
    //   gender: "",
    //   age: "",
    //   referralService: "",
    //   referredTo: "",
    // };
    // setTableData([reset]);
    // setTableData([]);

    // setSelectedModules(bindCSEDatas[rowIndex].Modules)
    if (response.data.Table1.length === 0) {
      setTableData([
        {
          studentName: "",
          studentId: "",
          gender: "",
          age: "",
          referralService: "",
          referredTo: "",
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToStudentTable(JSON.stringify(response.data.Table1));
    }
    // appendDataToStudentTable(JSON.stringify(response.data.Table1));
  };
  const handleEdit = async (rowIndex) => {
    debugger;
    setSessionAddDisability(true);
    setVisibleAddPage(true);
    const sessionsDD = bindCSEDatas[rowIndex].Sessions.split(",").map(
      (value) => ({ value: parseInt(value), label: value })
    );

    setBindSession(sessionsDD);
    const dataArray = bindCSEDatas[rowIndex].Sessions.split(",");
    const editSessionNumber = parseInt(dataArray[dataArray.length - 1]);
    // setEditSessionNumber(editSessionNumber);

    // appendEditData(editSessionNumber)

    const data = {
      year: parseInt(bindCSEDatas[rowIndex].Date.split("-")[0]),
      mstSchoolId: parseInt(bindCSEDatas[rowIndex].MstSchoolId),
      class: parseInt(bindCSEDatas[rowIndex].Class),
      division: bindCSEDatas[rowIndex].Division,
      sessionNumber: editSessionNumber,
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/GetCSEInSchoolData"
    );

    setViewAction(false);
    const sessionId = bindCSEDatas[rowIndex].TxnInSchoolSessionId;
    setSessionIdEdit(sessionId);
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      session: editSessionNumber,
      mstSchoolId: bindCSEDatas[rowIndex].MstSchoolId,
      cseClass: bindCSEDatas[rowIndex].Class,
      division: bindCSEDatas[rowIndex].Division,
      resourcePersonType: response.data.Table[0].ResourcePersonType,
      resourcePersonName1: response.data.Table[0].ResourcePersonName1,
      resourcePersonName2: response.data.Table[0].ResourcePersonName2,
      date: moment(response.data.Table[0].Date).format("YYYY-MM-DD"),
      startTime: moment(response.data.Table[0].StartTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      endTime: moment(response.data.Table[0].EndTime, "HH:mm:ss").format(
        "HH:mm:ss"
      ),
      showImage: response.data.Table[0].FileUpload,
      remarks: response.data.Table[0].Remarks,
    });
    const modules = response.data.Table[0].Modules.split(",").map((value) =>
      parseInt(value.trim(), 10)
    );
    setSelectedModules1(modules);

    if (response.data.Table1.length === 0) {
      setTableData([
        {
          studentName: "",
          studentId: "",
          gender: "",
          age: "",
          referralService: "",
          referredTo: "",
        },
      ]);
    } else {
      // Append the filtered data to your state using appendDataToState function
      appendDataToStudentTable(JSON.stringify(response.data.Table1));
    }
    // appendDataToStudentTable(JSON.stringify(response.data.Table1));
    setVisibleAdd(true);
    setVisibleTable(false);
    setUpdateScreenTitle(true);
  };

  const appendEditData = async (sessionValue) => {
    const data = {
      year: parseInt(bindCSEDatas[rowIndex].Date.split("-")[0]),
      mstSchoolId: parseInt(bindCSEDatas[rowIndex].MstSchoolId),
      class: parseInt(bindCSEDatas[rowIndex].Class),
      division: bindCSEDatas[rowIndex].Division,
      sessionNumber: sessionValue,
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/GetCSEInSchoolData"
    );
  };

  const appendDataToStudentTable = (newDataString) => {
    console.log("newDataString", newDataString);
    const newDataArray = JSON.parse(newDataString);
    // handleDeleteRow(0);
    setTableData([]);
    const formattedDataArray = newDataArray.map((data) => ({
      studentName: data.StudentName,
      studentId: data.StudentId,
      age: data.MstAgeId,
      gender: data.Gender,
      referralService: data.MstReferralServicesId
        ? data.MstReferralServicesId
        : "",
      referredTo: data.ReferredToId ? data.ReferredToId : "",
    }));

    setTableData((prevData) => [...prevData, ...formattedDataArray]);
    convertArrayToJSONString(formattedDataArray);
  };

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setInSchoolSessionFields({
        ...inSchoolSessionFields,
        date: formattedDate,
      });
    };

    fetchData();
  }, []);
  const handleSchoolView = async (rowIndex) => {
    const filterSessionId1 = bindCSEDatas[rowIndex];
    const filterSessionId = bindDBStudents?.filter(
      (item) =>
        item.TxnInSchoolSessionId ===
        bindCSEDatas[rowIndex].TxnInSchoolSessionId
    );
    const classLabel = bindClass?.find(
      (x) => x.value == filterSessionId1.Class
    );
    setInSchoolSessionFields({
      ...inSchoolSessionFields,
      // sessionNo: filterSessionId[0].Sessions,
      mstSchoolId: filterSessionId1.SchoolName,
      cseClass: filterSessionId1.label,
      division: filterSessionId1.Division,
      date: filterSessionId1.Date,
      GridTableClass: filterSessionId1.GridTableClass,
    });
    setViewAction(true);
    const data = {
      year: parseInt(filterSessionId1.Date.split("-")[0]),
      mstSchoolId: parseInt(filterSessionId1.MstSchoolId),
      class: parseInt(filterSessionId1.Class),
      division: filterSessionId1.Division,
    };
    const response = await BindPostAPI(
      [data],
      "CSEInSchoolAPI/GetCSEStudentData"
    );
    // Assuming setStudentData accepts an array
    setStudentData(response.data.Table);
  };
  const handleShowImage = (showImage) => {
    showImage &&
      setInSchoolSessionFields({
        ...inSchoolSessionFields,
        showImage: showImage,
      });
    setInSchoolSessionFieldsError({
      ...inSchoolSessionFieldsError,
      showImageError: "",
    });
  };
  const userProfileDatas = userProfileState.userProfile;
  const getBranch = async (userProfileDatas) => {
    if (Array.isArray(userProfileDatas) && userProfileDatas.length > 0) {
      setMstBranchId(userProfileDatas[0].MstLocationId);

      console.log("branch", userProfileDatas[0].MstLocationId);
      // setLocation(userProfileDatas[0].MstLocationId);
    } else {
      // handle else case if needed
    }
  };
  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      getBranch(userProfileDatas);
    }
  }, [addScreenTitle]);
  useEffect(() => {
    bindAllDropdownData()
      .then(() => BindCSEGridData(userProfileDatas))
      .catch((error) => {
        // Handle errors if any function fails
        console.error("Error in useEffect:", error);
      });
  }, []);
  useEffect(() => {
    if (location.pathname == "/inschoolsession") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      BindCSEGridData(userProfileDatas);
    }
  }, [location, userProfileDatas]);

  const validateSchoolData = () => {
    const { mstSchoolId, cseClass, division, date } = inSchoolSessionFields;
    const newYear = new Date(date).getFullYear();
    let isDuplicate = false; // Initialize as false
    const isFormError = {
      ...inSchoolSessionFieldsError,

      mstSchoolIdError: inSchoolSessionFields.mstSchoolId
        ? ""
        : "Select School Name",
      cseClassError: inSchoolSessionFields.cseClass
        ? ""
        : "Select Class / Std.",
      divisionError: inSchoolSessionFields.division ? "" : "Enter Division",
      dateError: inSchoolSessionFields.date ? "" : "Select Date",
    };
    setInSchoolSessionFieldsError(isFormError);
    const isFormValid = Object.values(isFormError).every(
      (error) => error === ""
    );
    if (isFormValid) {
      // Loop through bindCSEDatas and check for duplicates
      bindCSEDatas.forEach((data) => {
        const existingYear = new Date(data.Date).getFullYear();
        if (
          data.MstSchoolId === mstSchoolId &&
          data.Class === parseInt(cseClass) &&
          data.Division.toLowerCase() === division.toLowerCase() && // case insensitive comparison
          existingYear === newYear
        ) {
          isDuplicate = true; // If match found, set to true
          // setSessionAddDisability(true); // Disable the session addition
        }
      });

      // If no duplicate found after loop, set disability to false
      if (!isDuplicate) {
        setSessionAddDisability(false);
        setVisibleAddPage(true);
        setInSchoolSessionFields({
          ...inSchoolSessionFields,
          startTime: getCurrentTime(),
          endTime: getCurrentTime(),
        });
      }
    }
    return isDuplicate; // Return the result of the check
  };

  const handleGo = async () => {
    if (validateSchoolData()) {
      setVisibleAddPage(false);
      alert(
        "A session for this school, class, division, and year already exists."
      );
    }
  };

  console.log("Selected data", selectedModules, selectedModules1);
  return (
    <MainLayout pageName="3">
      {visibleTable && (
        <GridPage
          title="In-School Session"
          header={cseInSchoolHeaders}
          records={bindCSEDatas}
          handleEdit={handleEdit}
          handleAction={handleISSDetails}
          showAdd={showAdd}
          handleSchoolView={handleSchoolView}
          tableHeight="h-56"
          onAdd={showAddSession}
          xScroll="overflow-x-auto max-md:w-fit w-full"
          tableScroll="relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full md:min-w-fit"
        />
      )}
      {viewAction && (
        <GridPage
          title={`In-School Session > ${inSchoolSessionFields.mstSchoolId} > ${inSchoolSessionFields.GridTableClass} > ${inSchoolSessionFields.division}`}
          header={inschoolsessionStudentHeader}
          records={studentData}
          tableHeight="h-56"
          addView={true}
        />
      )}
      {visibleAdd && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="In-School Session"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className={mainTable.formForClientMaster}>
                  <div className={mainTable.grid4}>
                    <AutoDropDown
                      Class="Custom-class"
                      disabled={sessionAddDisability}
                      title={`School Name`}
                      options={bindSchoolDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="mstSchoolId"
                      selectedOption={bindSchoolDD.find(
                        (x) => x.value == inSchoolSessionFields.mstSchoolId
                      )}
                      controlFunc={(value, e) =>
                        handleInSchoolFieldsChange(value.value, e.name)
                      }
                      controlFuncBlur={() => {
                        setInSchoolSessionFieldsError(() => ({
                          ...inSchoolSessionFieldsError,
                          mstSchoolIdError: inSchoolSessionFields.mstSchoolId
                            ? ""
                            : "Select School Name",
                        }));
                      }}
                      errorMessage={inSchoolSessionFieldsError.mstSchoolIdError}
                    />
                    {/* <div className={mainTable.gridInventory2}> */}
                    <div>
                      <DropDown
                        title="Class / Std."
                        classStar="text-red-500"
                        disabled={sessionAddDisability}
                        options={bindClass}
                        placeholder="Select"
                        name="cseClass"
                        selectedOption={inSchoolSessionFields.cseClass}
                        controlFunc={(e) => {
                          const selectedValue = e.target.value;
                          setInSchoolSessionFields({
                            ...inSchoolSessionFields,
                            cseClass: selectedValue,
                          });
                        }}
                        controlFuncBlur={(e) => {
                          const selectedValue = e.target.value;
                          setInSchoolSessionFields(() => ({
                            ...inSchoolSessionFields,
                            cseClass: selectedValue,
                          }));
                          if (selectedValue.length === 0) {
                            setInSchoolSessionFieldsError(() => ({
                              ...inSchoolSessionFieldsError,
                              cseClassError: "Select Class / Std.",
                            }));
                          } else {
                            setInSchoolSessionFieldsError(() => ({
                              ...inSchoolSessionFieldsError,
                              cseClassError: "",
                            }));
                          }
                        }}
                      />
                      {inSchoolSessionFieldsError.cseClassError && (
                        <div className="text-red-500 text-sm font-medium">
                          {inSchoolSessionFieldsError.cseClassError}
                        </div>
                      )}
                    </div>

                    <InputText
                      inputType="text"
                      disabled={sessionAddDisability}
                      maxLength={5}
                      title="Division"
                      classStar="text-red-500"
                      placeholder="A"
                      name="division"
                      content={inSchoolSessionFields.division}
                      controlFunc={(e) =>
                        setInSchoolSessionFields({
                          ...inSchoolSessionFields,
                          division: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        setInSchoolSessionFieldsError(() => ({
                          ...inSchoolSessionFieldsError,
                          divisionError: inSchoolSessionFields.division
                            ? ""
                            : "Enter Division",
                        }));
                      }}
                      errorMessage={inSchoolSessionFieldsError.divisionError}
                    />

                    <InputText
                      inputType="date"
                      title="Date"
                      // disabled={validateSchoolData()}
                      classStar="text-red-500"
                      name="date"
                      content={inSchoolSessionFields.date}
                      controlFunc={(e) =>
                        setInSchoolSessionFields({
                          ...inSchoolSessionFields,
                          date: e.target.value,
                        })
                      }
                      controlFuncBlur={() => {
                        setInSchoolSessionFieldsError(() => ({
                          ...inSchoolSessionFieldsError,
                          dateError: inSchoolSessionFields.date
                            ? ""
                            : "Select Date",
                        }));
                      }}
                      min={new Date().toISOString().split("T")[0]} // Set min attribute to today's date
                      errorMessage={inSchoolSessionFieldsError.dateError}
                    />
                  </div>
                  {!visibleAddPage && (
                    <div className="flex justify-center">
                      <input
                        type="button"
                        value="GO"
                        className={mainTable.addButton}
                        onClick={(e) => handleGo()}
                      />
                    </div>
                  )}
                  {visibleAddPage && (
                    <div>
                      <div class={mainTable.grid4}>
                        <div>
                          <DropDown
                            title="Type of Resource Person"
                            classStar="text-red-500"
                            options={bindResourcePersonType}
                            placeholder="Select"
                            name="resourcePersonType"
                            selectedOption={
                              inSchoolSessionFields.resourcePersonType
                            }
                            controlFunc={(e) =>
                              setInSchoolSessionFields({
                                ...inSchoolSessionFields,
                                resourcePersonType: e.target.value,
                              })
                            }
                            controlFuncBlur={(e) => {
                              const selectedValue = e.target.value;
                              setInSchoolSessionFields(() => ({
                                ...inSchoolSessionFields,
                                resourcePersonType: selectedValue,
                              }));
                              if (selectedValue.length === 0) {
                                setInSchoolSessionFieldsError(() => ({
                                  ...inSchoolSessionFieldsError,
                                  resourcePersonTypeError:
                                    "Select Type Of Resource Person",
                                }));
                              } else {
                                setInSchoolSessionFieldsError(() => ({
                                  ...inSchoolSessionFieldsError,
                                  resourcePersonTypeError: "",
                                }));
                              }
                            }}
                            // errorMessage={inSchoolSessionFieldsError.resourcePersonTypeError}
                          />
                          {inSchoolSessionFieldsError.resourcePersonTypeError && (
                            <div className="text-red-500 text-sm font-medium">
                              {
                                inSchoolSessionFieldsError.resourcePersonTypeError
                              }
                            </div>
                          )}
                        </div>
                        <AutoDropDown
                          Class="Custom-class"
                          title={`Resource Person Name 1`}
                          options={bindResourcePersonName}
                          classStar="text-red-500"
                          placeholder="Select"
                          name="resourcePersonName1"
                          selectedOption={bindResourcePersonName.find(
                            (x) =>
                              x.value ==
                              inSchoolSessionFields.resourcePersonName1
                          )}
                          controlFunc={(value, e) =>
                            handleInSchoolFieldsChange(value.value, e.name)
                          }
                          controlFuncBlur={() => {
                            setInSchoolSessionFieldsError(() => ({
                              ...inSchoolSessionFieldsError,
                              resourcePersonNameError:
                                inSchoolSessionFields.resourcePersonName1
                                  ? ""
                                  : "Select Resource Person Name 1",
                            }));
                          }}
                          errorMessage={
                            inSchoolSessionFieldsError.resourcePersonNameError
                          }
                        />
                        <AutoDropDown
                          Class="Custom-class"
                          title={`Resource Person Name 2`}
                          options={bindResourcePersonName}
                          placeholder="Select"
                          name="resourcePersonName2"
                          selectedOption={bindResourcePersonName.find(
                            (x) =>
                              x.value ==
                              inSchoolSessionFields.resourcePersonName2
                          )}
                          controlFunc={(value, e) =>
                            handleInSchoolFieldsChange(value.value, e.name)
                          }
                        />
                        <InputText
                          inputType="time"
                          title="Start Time"
                          classStar="text-red-500"
                          name="startTime"
                          content={inSchoolSessionFields.startTime}
                          controlFunc={(e) =>
                            setInSchoolSessionFields({
                              ...inSchoolSessionFields,
                              startTime: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            setInSchoolSessionFieldsError(() => ({
                              ...inSchoolSessionFieldsError,
                              startTimeError: inSchoolSessionFields.startTime
                                ? ""
                                : "Select Start Time",
                            }));
                          }}
                          errorMessage={
                            inSchoolSessionFieldsError.startTimeError
                          }
                        />

                        <InputText
                          inputType="time"
                          title="End Time"
                          classStar="text-red-500"
                          name="endTime"
                          content={inSchoolSessionFields.endTime}
                          controlFunc={(e) =>
                            setInSchoolSessionFields({
                              ...inSchoolSessionFields,
                              endTime: e.target.value,
                            })
                          }
                          controlFuncBlur={() => {
                            setInSchoolSessionFieldsError(() => ({
                              ...inSchoolSessionFieldsError,
                              endTimeError: inSchoolSessionFields.endTime
                                ? ""
                                : "Select End Time",
                            }));
                          }}
                          errorMessage={inSchoolSessionFieldsError.endTimeError}
                        />
                        {updateScreenTitle && (
                          <div>
                            <DropDown
                              title="Session"
                              classStar="text-red-500"
                              options={bindSession}
                              placeholder="Select"
                              name="session"
                              selectedOption={inSchoolSessionFields.session}
                              controlFunc={(e) => {
                                handleSession(e.target.value);
                              }}
                            />
                            {inSchoolSessionFieldsError.sessionError && (
                              <div className="text-red-500 text-sm font-medium">
                                {inSchoolSessionFieldsError.sessionError}
                              </div>
                            )}
                          </div>
                        )}
                        {addScreenTitle && (
                          <InputText
                            title="Session"
                            maxLength={1}
                            inputType="number"
                            disabled={true}
                            classStar="text-red-500"
                            placeholder="2023 / 5"
                            name="session"
                            content={inSchoolSessionFields.session}
                            controlFunc={(e) =>
                              setInSchoolSessionFields({
                                ...inSchoolSessionFields,
                                session: e.target.value,
                              })
                            }
                            controlFuncBlur={() => {
                              setInSchoolSessionFieldsError(() => ({
                                ...inSchoolSessionFieldsError,
                                sessionError:
                                  inSchoolSessionFields.session &&
                                  inSchoolSessionFields.session.length > 0
                                    ? ""
                                    : "Enter Session Number",
                              }));
                            }}
                            errorMessage={
                              inSchoolSessionFieldsError.sessionError
                            }
                          />
                        )}
                      </div>

                      <Checkbox
                        classDiv="col-span-4 mb-2"
                        title="Modules"
                        classStar="text-red-500"
                        type={"checkbox"}
                        setName={"module"}
                        selectedOptions={selectedModules1}
                        options={bindModules}
                        controlFunc={handleModulesChange}
                        controlFuncBlur={() => {
                          setTopicsError(
                            (addScreenTitle && selectedModules.length > 0) ||
                              (updateScreenTitle &&
                                (selectedModules1.length > 0 ||
                                  selectedModules.length > 0)) ||
                              (sessionAddDisability &&
                                selectedModules.length > 0)
                              ? ""
                              : "Select Modules"
                          );
                        }}
                        disabled={false}
                        tabIndex="0"
                        Class1="w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2"
                        Class="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg"
                        errorMessage={topicsError}
                      />

                      <FileUpload
                        title="File Upload"
                        // classStar="text-red-500"
                        apiCall="CSEInSchoolAPI/InSchoolFileUpload"
                        modalName="CSEInSchoolFileUpload"
                        showImage={inSchoolSessionFields.showImage}
                        showImageData={handleShowImage}
                        imageUploadedError={
                          inSchoolSessionFieldsError.showImageError
                        }
                        limitMassage={
                          "Image size limit: 2MB. Accepted Image types:jpg, png, jpeg."
                        }
                        MAX_FILE_SIZE_MB={2}
                        ALLOWED_FORMATS={[
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                        ]}
                      />
                      <div class="lg:col-span-4 sm:col-span-1">
                        <TextArea
                          inputType="text"
                          maxLength={500}
                          title="Remarks"
                          name="remarks"
                          rows="4"
                          content={inSchoolSessionFields.remarks}
                          placeholder="Remarks"
                          controlFunc={(e) =>
                            setInSchoolSessionFields({
                              ...inSchoolSessionFields,
                              remarks: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div class="block text-base capitalize font-bold mt-2 text-neutral-700">
                        List Of Participants
                      </div>
                      <div className="hidden md:block overflow-x-auto ">
                        <div className="table-container w-screen md:w-max xl:w-full max-h-96">
                          <table className="w-full border border-gray-300 mt-2">
                            <thead>
                              <tr>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  {`Roll ID.`}
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  {`Student Name`}
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Gender
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Age
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Referral Service
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Referred To
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 ">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((row, index) => (
                                <tr key={index}>
                                  <td className="border border-gray-300 p-2 w-24">
                                    <InputText
                                      type="text"
                                      content={row.studentId}
                                      maxLength={50}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "studentId"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-40">
                                    <InputText
                                      type="text"
                                      content={row.studentName}
                                      maxLength={100}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "studentName"
                                        );
                                      }}
                                    />
                                  </td>

                                  <td className="border border-gray-300 p-2 w-16">
                                    <DropDown
                                      options={bindGenderDD}
                                      placeholder="Select"
                                      name="categories"
                                      selectedOption={row.gender}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "gender"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-16">
                                    <DropDown
                                      options={bindCSEAgeDD}
                                      placeholder="Select"
                                      name="age"
                                      selectedOption={row.age}
                                      controlFunc={(e) =>
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "age"
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2 w-32">
                                    <DropDown
                                      options={bindServicesName}
                                      placeholder="Select"
                                      name="referralService"
                                      selectedOption={row.referralService}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "referralService"
                                        );
                                      }}
                                    />
                                  </td>

                                  <td className="border border-gray-300 p-2 cursor-not-allowed w-16">
                                    <DropDown
                                      options={bindReferedTo}
                                      placeholder="Select"
                                      name="categories"
                                      selectedOption={row.referredTo}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "referredTo"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 w-16">
                                    <div className="flex gap-4 align-baseline items-center justify-center">
                                      <svg
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        class="w-6 h-6 text-black cursor-pointer"
                                        onClick={() => handleDeleteRow(index)}
                                      >
                                        <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                      </svg>
                                      <svg
                                        viewBox="0 0 1024 1024"
                                        fill="currentColor"
                                        onClick={handleAddRow}
                                        class="w-6 h-6 text-black cursor-pointer"
                                      >
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                      </svg>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div className="hidden md:block">
                        {studentDetailsError && (
                          <div className="text-red-500">
                            {studentDetailsError}
                          </div>
                        )}
                      </div> */}
                      <div className={mainTable.tableMobile} role="table">
                        <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                          {tableData.length > 0 &&
                            tableData.map((row, index) => (
                              <div scope="col" class={mainTable.mobileTable}>
                                {headerData.map((keyData) => (
                                  <div
                                    scope="cell"
                                    class={mainTable.tableData}
                                    role="cell"
                                  >
                                    {keyData.key === "Action" ? (
                                      <div class={mainTable.tableData}>
                                        <div className="hidden md:block ">
                                          <button
                                            id="dropdownMenuIconButton"
                                            data-dropdown-toggle="dropdownDots"
                                            class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-50"
                                            type="button"
                                          >
                                            <svg
                                              class="w-6 h-6"
                                              aria-hidden="true"
                                              fill="currentColor"
                                              viewBox="0 0 20 20"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                        <div className="md:hidden flex justify-center">
                                          <input
                                            type="button"
                                            className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                            onClick={handleAddRow}
                                            value="Add"
                                          />
                                          <input
                                            type="button"
                                            className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                            onClick={() =>
                                              handleDeleteRow(index)
                                            }
                                            value="Delete"
                                          />
                                        </div>
                                      </div>
                                    ) : keyData.key === "StudentName" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <InputText
                                          type="text"
                                          content={row.studentName}
                                          maxLength={100}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "studentName"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "StudentId" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <InputText
                                          type="text"
                                          content={row.studentId}
                                          maxLength={50}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "studentId"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "Gender_en" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindGenderDD}
                                          placeholder="Select"
                                          name="categories"
                                          selectedOption={row.gender}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "gender"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "Age" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindCSEAgeDD}
                                          placeholder="Select"
                                          name="age"
                                          selectedOption={row.age}
                                          controlFunc={(e) =>
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "age"
                                            )
                                          }
                                        />
                                      </div>
                                    ) : keyData.key === "ReferralServices" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindServicesName}
                                          placeholder="Select"
                                          name="referralService"
                                          selectedOption={row.referralService}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "referralService"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : keyData.key === "ReferredTo_en" ? (
                                      <div className=" border p-1">
                                        <div className="flex items-center w-full whitespace-nowrap font-bold">
                                          {keyData.title}
                                        </div>
                                        <DropDown
                                          options={bindReferedTo}
                                          placeholder="Select"
                                          name="categories"
                                          selectedOption={row.referredTo}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "referredTo"
                                            );
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="flex border p-1">
                                        <div className="flex basis-1/2 font-bold">
                                          {keyData.title}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="hidden md:block">
                        {studentDetailsError && (
                          <div className="text-red-500">
                            {studentDetailsError}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </form>
                {visibleAddPage && (
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    disabledBtn={submitBtnDisabled ? true : false}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    viewSaveButton={true}
                  />
                )}
                {/* {submitServiceButton && (
                  <div className="border p-2 flex flex-col items-center justify-center ">
                    <label className="flex gap-1  mb-2 text-sm font-medium text-red-500 ">
                      Do you want to add client click the "Add Client" button or
                      click cancel button
                    </label>
                    <Link
                      to={"/clientmaster"}
                      state={{
                        // ...data,
                        isAddInSchool: true,
                        isvisible3: false,
                      }}
                      className={mainTable.addButton}
                    >
                      Add Client
                    </Link>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={() => setpopUpOpen(true)}
      >
        <div className=" flex flex-col gap-3">
          <PopUpScreenTitle
            title="In-School session"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <InSchoolPopup
            data={currentData1}
            data1={currentData2}
            data2={currentData3}
            data3={currentData4}
          />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          disabledBtn={submitBtnDisabled ? true : false}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
        />
      </MainPopup>
    </MainLayout>
  );
}
export default InSchoolSession;
