import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import RadioGroup from "../ReusableComponents/RadioButton";

const YouthPopup = ({ data, data1, data2 }) => {
  console.log("data2", data2);
  const [genderRadio, setGenderRadio] = useState([]);

  useEffect(() => {
    const Dropdowndetails = () => {
      binddrpGenderdata(data2[0]);
    };
    Dropdowndetails();
  }, [data2]);

  const binddrpGenderdata = async (Gendervalues) => {
    try {
      if (Array.isArray(Gendervalues)) {
        const gendersResultLabels = Gendervalues.map((item) => ({
          value: item.value,
          label: item.label,
        }));
        setGenderRadio(gendersResultLabels);
      } else {
        console.error("data2 is not an array");
      }
    } catch (error) {
      console.error("Error fetching setBindGenderRadioDD data:", error);
    }
  };

  return (
    <div class="w-full">
      <div class="text-xl mx-14 font-medium text-neutral-700 "></div>
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
              <div>
                <div class={mainTable.labelBold1}>Youth Group</div>
                <hr class={mainTable.horizontalLine}></hr>
                <div className={mainTable.grid3}>
                  <InputText
                    inputType="date"
                    title="Date"
                    classStar="text-red-500"
                    name="date"
                    content={data2[3]}
                  />
                  <InputText
                    inputType="text"
                    title="Youth Group Id"
                    classStar="text-red-500"
                    name="youthGroupId"
                    content={data.YouthGroupId}
                    disabled="disabled"
                  />
                  <InputText
                    inputType="text"
                    title="Youth Group Name"
                    name="schoolName"
                    classStar="text-red-500"
                    content={data.YouthGroupName}
                    disabled="disabled"
                  />
                  <DropDown
                    classDiv="hidden"
                    options={[]}
                    classStar="text-red-500"
                    title="Country"
                    placeholder="Select"
                    name="country"
                    content={data.SchoolId}
                  />
                  <div>
                    <RadioGroup
                      title="Type Of Group"
                      type={"radio"}
                      classStar="text-red-500"
                      setName={"gender"}
                      selectedOptions={data1[0]}
                      options={genderRadio}
                      disabled="disabled"
                      Class1="grid grid-cols-2 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 xl:grid xl:grid-cols-4 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
                    />
                  </div>
                  <AutoDropDown
                    title="State"
                    name="state"
                    options={[]}
                    classStar="text-red-500"
                    Class="custom-class"
                    disabled="false"
                    tabIndex="1"
                    selectedOption={data2[1]}
                  />
                  <AutoDropDown
                    title="Branch/Project"
                    placeholder="Select"
                    options={[]}
                    name="state"
                    classStar="text-red-500"
                    Class="custom-class"
                    disabled="false"
                    tabIndex="1"
                    selectedOption={data2[2]}
                  />
                  <InputText
                    inputType={"text"}
                    title={"Venue"}
                    name={"address"}
                    // ClassDiv="col-span-3"
                    classStar="text-red-500"
                    content={data.YouthGroupVenue}
                    disabled="disabled"
                  />
                  <InputText
                    inputType={"text"}
                    title={"Youth Volunteer Name"}
                    name={"contactPerson"}
                    content={data.YouthVolunteerName}
                    disabled="disabled"
                    classStar="text-red-500"
                  />
                  <InputText
                    inputType={"text"}
                    title={"Mobile Number"}
                    name={"phone"}
                    content={data.YouthGroupPhoneNumber}
                    classStar="text-red-500"
                    disabled="disabled"
                  />
                  <InputText
                    inputType={"text"}
                    title={"Email Id"}
                    name={"email"}
                    content={data.YouthGroupEmail}
                    disabled="disabled"
                  />
                  <div class="lg:col-span-3 sm:col-span-1">
                    <TextArea
                      inputType="text"
                      title="Remarks"
                      name="remarks"
                      rows="4"
                      content={data.YouthGroupRemarks}
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouthPopup;
