// Code review - In Progress
// Documentation - In Progress
import React, { useDebugValue, useEffect } from "react";
import { useState } from "react";
import { locationMasterHeader } from "../components/HeaderAttribute";
import MainLayout from "../components/MainLayout";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import Location from "../popup-containers/Location";
import MainPopup from "../popup-containers/MainPopup";
import toast, { Toaster } from "react-hot-toast";
import {
  BindAPI,
  IUDAPI,
  LanguageTranslatorAPI,
} from "../services/APIConnection";
import GridPage from "../components/GridPage";
import PopUpButtons from "../components/PopUpButtons";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import Buttons from "../components/Buttons";
import AddScreenTitle from "../components/AddScreenTitle";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const LocationMaster = () => {
  const location1 = useLocation();
  const [visibleAdd, setVisibleAdd] = React.useState(false);
  const [visibleTable, setVisibleTable] = React.useState(true);

  //Location Value State

  const [countryName, setCountryName] = useState("");
  const [location, setLocation] = useState("");
  const [locationCode, setLocationCode] = useState("");

  //Location Error State
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [stateError, setStateError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [locationCodeError, setLocationCodeError] = useState("");

  const [languageCodeKey, setLanguageCodekey] = useState([]);
  const [languageGetCode, setLanguageGetCode] = useState([]);
  const [translatedTa, setTranslatedTa] = useState("");

  const [bindCountriesDD, setBindCountriesDD] = useState([
    { value: "1", label: "India" },
    { value: "2", label: "Nepal" },
    { value: "3", label: "Srilanka" },
  ]);

  const [bindLocationMasterDatas, setBindLocationMasterDatas] = useState([]);
  const [state, setState] = useState("");

  const [popUpOpen, setpopUpOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [locationData, setLocationData] = useState("");
  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [mstLocationId, setMstLocationId] = useState("");
  const [bindStateDropDownData, setBindStateDropDownData] = useState([]);
  const [popViewDropdowns, setPopViewDropdowns] = useState({});

  const [viewDetails, setViewDetails] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const showAdd = () => {
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
  };

  useEffect(() => {
    getLocationTableDatas();
  }, []);

  const getLocationTableDatas = async () => {
    const response = await BindAPI("LocationAPI/BindLocation");
    setBindLocationMasterDatas(response.data.Table);
  };

  const cancel = () => {
    setpopUpOpen(false);
    setVisibleAdd(false);
    setVisibleTable(true);
    setSubmitBtnDisabled(false);
    setLocationCode("");
    setLocation("");
    setState("");
    setStateError("");
    setEmailError("");
    setEmail("");
    setLocationError("");
    setLocationCodeError("");
    getLocationTableDatas();
  };

  useEffect(() => {
    bindLocationMasterDatas && getLocationTableDatas();
  }, []);

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };
  const handleLocation = (rowIndex, flag) => {
    handleOpen(true);
    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }
    setLocationData(bindLocationMasterDatas[rowIndex]);

    const locationData = bindLocationMasterDatas[rowIndex];
    const checkDropdowns = bindStateDropDownData.find(
      (item) => item.value === locationData.MstStateId
    );

    const resultBindViewStatePopup = [
      {
        value: locationData.MstStateId,
        label: checkDropdowns ? checkDropdowns.label : "Unknown",
      },
    ];

    setPopViewDropdowns(resultBindViewStatePopup);
  };
  const handleEdit = (rowIndex) => {
    setUpdateScreenTitle(true);
    setVisibleAdd(true);
    setVisibleTable(false);
    setAddScreenTitle(false);
    setLocation(bindLocationMasterDatas[rowIndex].LocationName_en);
    setLocationCode(bindLocationMasterDatas[rowIndex].LocationCode);
    setMstLocationId(bindLocationMasterDatas[rowIndex].MstLocationId);
    setState(bindLocationMasterDatas[rowIndex].MstStateId);
    setEmail(bindLocationMasterDatas[rowIndex].Email);
  };

  useEffect(() => {
    const getStateDropdownValues = async () => {
      const response = await BindAPI("StateAPI/GetState");
      const bindState = response?.data?.Table?.filter(
        (x) => x.BlockedBy === null
      );
      const getMstStateId = bindState.map((item) => ({
        value: item.MstStateId,
        label: item.StateName_en,
      }));
      setBindStateDropDownData(getMstStateId);
    };

    getStateDropdownValues();
  }, []);

  useEffect(() => {
    const getLanguageCode = async () => {
      const response = await BindAPI("LanguageAPI/GetLanguage");
      setLanguageCodekey(response.data.Table);
    };
    getLanguageCode();
  }, []);

  useEffect(() => {
    var languageKeys = languageCodeKey.map((item) => item.LanguageKey);
    setLanguageGetCode(languageKeys);
  }, [languageCodeKey]);

  const validate = (flag) => {
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (state == "") {
        errors.state = "Select state";
      }

      if (!location.trim()) {
        errors.location = "Enter Branch/Project";
      } else if (!/^[A-Za-z ]+$/.test(location)) {
        errors.location = "Enter valid Branch/Project";
      }

      if (!locationCode.trim()) {
        errors.locationCode = "Enter Branch/Project Code";
      } else if (!/^[A-Z]{3}$/.test(locationCode)) {
        errors.locationCode =
          "Enter minimum 3 charactor and should be uppercase";
      }

      if (!email || !email.trim()) {
        errors.email = "Enter Email Id";
      } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)
      ) {
        errors.email = "Enter Valid Email Id";
      } else if (
        !updateScreenTitle &&
        bindLocationMasterDatas && // Ensure bindAreaMasterDatas is not null
        bindLocationMasterDatas.find(
          (x) => x.Email && x.Email.toLowerCase() === email.toLowerCase()
        )
      ) {
        errors.email = "Email already available";
      }
    }

    setStateError(errors.state || "");
    setLocationError(errors.location || "");
    setLocationCodeError(errors.locationCode || "");
    setEmailError(errors.email || "");
    return errors;
  };

  const handleSubmit = async (items, flag) => {
    debugger;
    setSubmitBtnDisabled(true);
    const validation = validate(flag);
    if(Object.keys(validation).length !== 0){
      setIsOpen(true);
    }
    if (Object.keys(validation).length === 0) {
      setIsOpen(false);
      var createdBy = sessionStorage.getItem("createdBy");
      if (flag == 1) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTexts = []; // Create an array to store translations

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(location, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const locationPayload = [
          {
            locationCode: locationCode,
            mstCountryId: 1,
            mstStateId: state,
            locationNameEn: location,
            locationNameTa: translatedTexts[0],
            locationNameHi: translatedTexts[1],
            locationNameMr: translatedTexts[2],
            locationNameGu: translatedTexts[3],
            locationNameKn: translatedTexts[4],
            locationNameMl: translatedTexts[5],
            locationNameTe: translatedTexts[6],
            locationNameBn: translatedTexts[7],
            locationNameOr: translatedTexts[8],
            email: email,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(
          locationPayload,
          "LocationAPI/IUDLocation"
        );
        if (response.data.Table[0].Result === "S") {
          toast.success("Branch / Project added successfully! 👍", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        } else {
          toast.error(
            " Branch / Project addition failed. Please review the details and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else if (flag == 2) {
        const languageKeys = [
          "ta", // Tamil
          "hi", // Hindi
          "mr", // Marathi
          "gu", // Gujarati
          "kn", // Kannada
          "ml", // Malayalam
          "te", // Telugu
          "bn", // Bengali
          "or", // Oriya
        ];

        const translatedTexts = []; // Create an array to store translations

        for (let i of languageKeys) {
          const translateText = await LanguageTranslatorAPI(location, i);
          translatedTexts.push(
            translateText.data.data.translations[0].translatedText
          );
        }

        const locationPayload = [
          {
            mstLocationId: mstLocationId,
            locationCode: locationCode,
            mstCountryId: 1,
            mstStateId: state,
            locationNameEn: location,
            locationNameTa: translatedTexts[0],
            locationNameHi: translatedTexts[1],
            locationNameMr: translatedTexts[2],
            locationNameGu: translatedTexts[3],
            locationNameKn: translatedTexts[4],
            locationNameMl: translatedTexts[5],
            locationNameTe: translatedTexts[6],
            locationNameBn: translatedTexts[7],
            locationNameOr: translatedTexts[8],
            email: email,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(
          locationPayload,
          "LocationAPI/IUDLocation"
        );
        if (response.data.Table[0].Result === "M") {
          toast.success("Branch / Project Modified successfully! 👍", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        } else {
          toast.error(
            "Branch / Project modify failed. Please review the details and try again. 👎",
            {
              duration: 2000,
              position: "top-center",
            }
          );
          setTimeout(cancel, 2000);
        }
      } else {
        const locationPayload = [
          {
            mstLocationId: bindLocationMasterDatas[items].MstLocationId,
            createdBy: createdBy,
            flag: flag,
          },
        ];
        const response = await IUDAPI(
          locationPayload,
          "LocationAPI/IUDLocation"
        );
        if (flag == 3) {
          if (response.data.Table[0].Result === "D") {
            toast.success("Deletion successful! 🗑️", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(
              "Branch / Project delete failed. Please review the details and try again. ❌",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        } else if (flag == 4) {
          if (response.data.Table[0].Result === "B") {
            toast.success("Branch / Project blocked successfully. 🔒", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(
              "Branch / Project block failed. Please review the details and try again.",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        } else if (flag == 5) {
          if (response.data.Table[0].Result === "UB") {
            toast.success("Branch / Project unblocked successfully. 🔓", {
              duration: 2000,
              position: "top-center",
            });
            setTimeout(cancel, 2000);
          } else {
            toast.error(
              "Branch / Project unblocked failed. Please review the details and try again.",
              {
                duration: 2000,
                position: "top-center",
              }
            );
            setTimeout(cancel, 2000);
          }
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };
  useEffect(() => {
    if (location1.pathname == "/locationmaster") {
      setAddScreenTitle(false);
      setVisibleTable(true);
      setVisibleAdd(false);
      getLocationTableDatas();
    }
  }, [location1]);
  return (
    <MainLayout pageName="2">
      {visibleTable && (
        <GridPage
          title={"Branch/Project Master"}
          header={locationMasterHeader}
          records={bindLocationMasterDatas}
          handleEdit={handleEdit}
          handleAction={handleLocation}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {visibleAdd && (
        <div class="w-full ">
          <div class="border-black  ">
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Branch/Project Master"
            />
          </div>
          <div class="mx-auto  md:w-2/5 w-full border-black">
            <div class="relative w-full h-full max-w-xl md:h-auto">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="px-6 py-6 lg:px-8">
                  <form class="space-y-6" action="#">
                    <DropDown
                      classDiv="hidden"
                      title={"Country"}
                      options={bindCountriesDD}
                      placeholder={"Select"}
                      name={"country"}
                      selectedOption={countryName}
                      controlFunc={(e) => setCountryName(e.target.value)}
                    />
                    <AutoDropDown
                      classStar="text-red-500"
                      title="State"
                      options={bindStateDropDownData}
                      placeholder="Select"
                      name="state"
                      selectedOption={bindStateDropDownData.find(
                        (x) => x.value == state
                      )}
                      controlFunc={(value) => {
                        setState(value.value);
                        setStateError(value ? "" : "Select State");
                      }}
                      controlFuncBlur={() => {
                        setStateError(state ? "" : "Select State");
                      }}
                      Class="custom-class"
                      tabIndex="1"
                      errorMessage={stateError}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Branch/Project"}
                      name={"location"}
                      content={location}
                      controlFunc={(e) => setLocation(e.target.value)}
                      controlFuncBlur={(e) => {
                        setLocation(e.target.value);
                        setLocationError(
                          location.length > 0
                            ? /^[A-Za-z ]+$/.test(location)
                              ? !updateScreenTitle &&
                                bindLocationMasterDatas.find(
                                  (x) =>
                                    x.LocationName_en.toLowerCase() ==
                                    location.toLowerCase()
                                )
                                ? "Branch/Project Name already available"
                                : ""
                              : "Only Alphabet and space allowed"
                            : "Enter Branch/Project"
                        );
                      }}
                      errorMessage={locationError}
                    />
                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Branch/Project Code"}
                      name={"location"}
                      content={locationCode}
                      controlFunc={(e) => setLocationCode(e.target.value)}
                      disabled={updateScreenTitle ? updateScreenTitle : false}
                      controlFuncBlur={(e) => {
                        setLocationCode(e.target.value);
                        setLocationCodeError(
                          locationCode.length > 0
                            ? /^[A-Z]{3}$/.test(locationCode)
                              ? !updateScreenTitle &&
                                bindLocationMasterDatas.find(
                                  (x) =>
                                    x.LocationCode.toLowerCase() ==
                                    locationCode.toLowerCase()
                                )
                                ? "Branch/Project Code already available"
                                : ""
                              : "Enter minimum 3 charactor and should be uppercase"
                            : "Enter Branch/Project code"
                        );
                      }}
                      errorMessage={locationCodeError}
                    />

                    <InputText
                      classStar="text-red-500"
                      inputType={"text"}
                      title={"Email Id"}
                      name={"area"}
                      content={email}
                      controlFunc={(e) => setEmail(e.target.value)}
                      controlFuncBlur={(e) => {
                        setEmail(e.target.value);
                        setEmailError(
                          email.length > 0
                            ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                                email
                              )
                              ? !updateScreenTitle &&
                                bindLocationMasterDatas.find(
                                  (x) =>
                                    x.Email.toLowerCase() == email.toLowerCase()
                                )
                                ? "Email already available"
                                : ""
                              : "Enter Valid Email Id"
                            : "Enter Email Id"
                        );
                      }}
                      errorMessage={emailError}
                    />
                  </form>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    cancel={cancel}
                    disabledBtn={submitBtnDisabled ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
       {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleLocation}
      >
        <div>
          <PopUpScreenTitle
            title="Branch/Project Master"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <Location data={locationData} data2={popViewDropdowns} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          cancel={cancel}
          disabledBtn={submitBtnDisabled ? true : false}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default LocationMaster;
