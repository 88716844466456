import React, { useState, useEffect } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import MultiSelect from "../ReusableComponents/MultiSelect";
import Checkbox from "../ReusableComponents/Checkbox";
import RadioGroup from "../ReusableComponents/RadioButton";
import FileUpload from "../ReusableComponents/FileUpload";
import download from "../image/download.png";

const SingleSeassionPopup = ({ data, data2 }) => {
    const [consultedDoctorDD, setConsultedDoctorDD] = useState([
        { value: "1", label: "Online" },
        { value: "2", label: "Offline" },
    ]);
    console.log("data2popup", data2);

    return (
        <div class="w-full">
            <div class=" flex flex-col w-full">
                <div class={mainTable.addShadowScreen}>
                    <div class={mainTable.mainContentScreen}>
                        <form class={mainTable.formForPopupcontainer}>
                            <div className={mainTable.grid3}>
                                <InputText
                                    inputType="text"
                                    title="Event no."
                                    classStar="text-red-500"
                                    name="pincode"
                                    placeholder="MUM/CSEOO/2024/001"
                                    content={data.EventNo}
                                    disabled="disabled"
                                />
                                <InputText
                                    inputType="date"
                                    title="Date"
                                    classStar="text-red-500"
                                    name="openingDate"
                                    content={[data2[0]]}
                                    disabled="disabled"
                                />
                                <InputText
                                    inputType="time"
                                    title="Start Time"
                                    classStar="text-red-500"
                                    name="openingDate"
                                    content={data.SEOStartTime}
                                    disabled="disabled"
                                />
                                <InputText
                                    inputType="time"
                                    title="End Time"
                                    classStar="text-red-500"
                                    name="openingDate"
                                    content={data.SEOEndTime}
                                    disabled="disabled"
                                />
                                <InputText
                                    title="Resource Person Name1"
                                    type="Place"
                                    classStar="text-red-500"
                                    name="Place"
                                    content={data.ResourcesName1}
                                    disabled="disabled"
                                />
                                 <InputText
                                    title="Resource Person Name2"
                                    type="Place"
                                    name="Place"
                                    content={data.ResourcesName2 ?? ""}
                                    disabled="disabled"
                                />
                                <InputText
                                    inputType="text"
                                    title="Total no.of Participants"
                                    classStar="text-red-500"
                                    name="pincode"
                                    placeholder="No.Of.Participants"
                                    content={data.SEONoOfParticipants}
                                    disabled="disabled"
                                />
                                <div className="col-span-1">
                                    <label for="client_name" class={mainTable.label}>
                                        Age (estimated)
                                        <span className="text-red-500">*</span>
                                    </label>
                                    <div className="grid gap-2 md:grid-cols-3 flex items-center bg-gray-50 p-1 border border-gray-300 rounded-lg dark:border-gray-500">
                                        <InputText
                                            inputType={"text"}
                                            ClassDiv="grid gap-2 grid-cols-2"
                                            ClassLabel={mainTable.labelBaseline}
                                            Class={mainTable.smallInput}
                                            title=" 10-14"
                                            name={"maleChild"}
                                            content={data.SEOMaleChild}
                                            disabled="disabled"
                                        />
                                        <InputText
                                            inputType={"text"}
                                            ClassDiv="grid gap-2 grid-cols-2"
                                            ClassLabel={mainTable.labelBaseline}
                                            Class={mainTable.smallInput}
                                            title="15-19"
                                            name={"femaleChild"}
                                            content={data.SEOFemaleChild}
                                            disabled="disabled"
                                        />
                                        <InputText
                                            inputType={"text"}
                                            ClassDiv="grid gap-2 grid-cols-2"
                                            ClassLabel={mainTable.labelBaseline}
                                            Class={mainTable.smallInput}
                                            title="20-24"
                                            name={"otherChild"}
                                            content={data.SEOOtherChild}
                                            disabled="disabled"
                                        />
                                    </div>
                                </div>
                                <RadioGroup
                                    classStar="text-red-500"
                                    title="Items Provided"
                                    type="radio"
                                    setName="itemsProvided"
                                    selectedOptions={data.SessionType}
                                    options={consultedDoctorDD}
                                    disabled={true}
                                />
                                {data.SessionType == "2" && (
                                    <InputText
                                        type="text"
                                        title="Venue"
                                        content={data.SEOVenue}
                                        disabled="disabled"
                                    />
                                )} 
 
                                    <div>
                                    <img
                  src={
                    data.SEOPhoto
                      ? data.SEOPhoto
                      : download
                  }
                  alt="Image"
                  className="rounded-full w-20 h-20"
                />
                                        {/* <input
                                            type="image"
                                            src={data.SEOPhoto}
                                            alt="Image"
                                            className="rounded-full w-20 h-20"
                                        />  */}
                                        
                                </div>

                            </div>

                            <div className="lg:col-span-3 sm:col-span-1">
                                <TextArea
                                    inputType="text"
                                    title="Description"
                                    name="description"
                                    rows="4"
                                    content={data.SEORemarks}
                                    placeholder="Description"
                                    disabled="disabled"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleSeassionPopup;
