import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import TextArea from "../ReusableComponents/TextArea";
import { BindAPI } from "../services/APIConnection";

const Indent = ({ data, data2, data3 }) => {
  console.log("data2", data3);
  const [bindCategoryNameDropDown, setBindCategoryNameDropDown] = useState([]);
  const [bindCategoryDropDown, setBindCategoryDropDown] = useState([]);
  const [iCParticipantDetails, setICParticipantDetails] = useState({});
  const [bindCommodities, setBindCommodities] = useState([]);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);

  const [commoditiesDD, setCommoditiesDD] = useState([]);

  const [tableData, setTableData] = useState([
    {
      indentCategoryType: "",
      indentCategory: "",
      indentName: "",
      indentQuantity: "",
      UOM: "",
      IndentAreaName: "",
      IndentFrom2: "",
      indentCategoryList: [],
      indentNameList: [],
    },
  ]);

  const headerData = [
    {
      title: "Category Type",
      key: "indentCategoryType",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Category",
      key: "indentCategory",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "indentName",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Qty.",
      key: "indentQuantity",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M.",
      key: "UOM",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  useEffect(() => {
    const indentDropDownDatas = async () => {
      try {
        const categoryResponse = await BindAPI(
          "CommoditiesAPI/GetCommoditiesCategory"
        );
        setBindCommoditiesCategory(categoryResponse.data.Table);
        const nameResponse = await BindAPI("CommoditiesAPI/BindCommodities");
        setBindCommodities(nameResponse.data.Table);
        const categoryTypeResponse = await BindAPI(
          "InventoryReceiptAPI/BindCommodityType"
        );

        const categoryData = categoryResponse.data.Table.map((item) => ({
          value: item.MstCommodityCategoryId,
          label: item.CommodityCategory,
        }));

        const nameData = nameResponse.data.Table.map((item) => ({
          value: item.MstCommoditiesId,
          label: item.CommodityName_en,
        }));

        const categoryTypeData = categoryTypeResponse.data.Table.map(
          (item) => ({
            value: item.MstCommodityTypeId,
            label: item.CommodityType_en,
          })
        );

        setBindCategoryDropDown(categoryData);
        setBindCategoryNameDropDown(nameData);
        setCommoditiesDD(categoryTypeData);
      } catch (error) {}
    };

    indentDropDownDatas();
  }, []);

  useEffect(() => {
    tableData.forEach((row) => {
      if (row?.indentCategoryType) {
        const getFilteredCategory = bindCommoditiesCategory
          .filter((item) => item.MstCommodityTypeId == row.indentCategoryType)
          .map((items) => ({
            value: items.MstCommodityCategoryId,
            label: items.CommodityCategory_en,
          }));
        row.indentCategoryList = getFilteredCategory;
        //setBindCategoryDropDown(getFilteredCategory);
      } else {
        setBindCategoryDropDown([]);
      }
      if (row?.indentCategory) {
        const selectedCommoditiesCategory = bindCommodities
          .filter((item) => item.MstCommodityCategoryId == row.indentCategory)
          .map((item) => ({
            value: item.MstCommoditiesId,
            label: item.CommodityName_en,
          }));

        setBindCategoryNameDropDown(selectedCommoditiesCategory);
        // Check if there's a selected commodity for the current row
        const selectedCommodity = selectedCommoditiesCategory.find(
          (item) => item.value === row.indentName
        );
      } else {
        setBindCategoryNameDropDown([]);
      }
    });
  }, [tableData]);

  const handleDeleteRow = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.indentCategoryType = "";
    newRow.indentCategory = ""; // Clear the values of the new row
    newRow.indentName = "";
    newRow.indentQuantity = "";
    newRow.UOM = "";

    setTableData((prevData) => [...prevData, newRow]); // Add the new row to the table data
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setICParticipantDetails(jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    let value = "";
    if (columnName == "indentCategoryType") {
      value = selectedOption
        ? selectedOption.label
        : selectedOption.value || "";
    } else {
      value = selectedOption
        ? selectedOption.value
        : selectedOption.label || "";
    }

    setTableData((prevData1) => {
      const updatedData = [...prevData1];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  useEffect(() => {
    const ParticipantDatas = JSON.stringify(data2);
    if (data2.length === 0) {
      setTableData([
        {
          indentCategoryType: "",
          indentCategory: "",
          indentName: "",
          indentQuantity: "",
          UOM: "",
          indentCategoryList: [],
          indentNameList: [],
        },
      ]);
    } else {
      const clearValues = () => {
        // Implement logic to clear values from the table
        // For example, if setTableData([]) is the function to clear the table data, use it here
        setTableData([]);
      };

      clearValues();

      if (ParticipantDatas && typeof ParticipantDatas === "string") {
        try {
          const newDataArray = JSON.parse(ParticipantDatas);
          // handleDeleteRow(0);
          const formattedDataArray = newDataArray.map((data) => {
            return {
              indentCategoryType: data.MstCommodityTypeId,
              indentCategory: data.MstCommodityCategoryId,
              indentName: data.MstCommoditiesId,
              indentQuantity: data.IndentQuantity,
              UOM: data.IndentUOM,
            };
          });

          setTableData((prevData) => [...prevData, ...formattedDataArray]);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle the error, e.g., show a message to the user or log it
        }
        //appendDataToState();
      } else {
        // Handle the case when data2 is not a string or is empty
      }
    }
  }, [data2]);

  return (
    <div class="w-full">
      <div class=" border-black  ">
        <div className={mainTable.addShadowScreen}>
          <div className={mainTable.mainContentScreen}>
            <form class={mainTable.formPopupcontainer}>
              <div>
                <div className={mainTable.grid3}>
                  <InputText
                    inputType="text"
                    title="Indent No."
                    name="IndentNo"
                    classStar="text-red-500"
                    content={data.IndentNo}
                    placeholder="Indent No"
                  />
                  <InputText
                    inputType="date"
                    title="Indent Date"
                    name="indentDate"
                    classStar="text-red-500"
                    content={data3[0]}
                  />
                  <InputText
                    inputType="text"
                    title="Branch"
                    name="branch"
                    content={data.LocationName_en}
                    placeholder=""
                    disabled="disabled"
                  />
                  <InputText
                    inputType="text"
                    title="S.D.P."
                    name="sdp"
                    content={data.SDP_en}
                    placeholder=""
                    disabled="disabled"
                  />
                  <InputText
                    inputType="text"
                    title="Area"
                    name="area"
                    content={data.AreaName1_en}
                    placeholder=""
                    disabled="disabled"
                  />
                  <InputText
                    inputType="text"
                    classStar="text-red-500"
                    title="Received From"
                    name="IndentNo"
                    content={data.IndentFrom1_en}
                    placeholder="Indent No"
                  />
                  <InputText
                    inputType="text"
                    title="Received (SDP ,Branch/Project ,ORS ,CBP)"
                    name="IndentNo"
                    content={data.IndentFrom2_en}
                    placeholder="Indent No"
                  />
                  {data.AreaName_en && (
                    <InputText
                      inputType="text"
                      title="Area Name"
                      name="IndentNo"
                      content={data.AreaName_en}
                    />
                  )}
                </div>

                <div className="hidden lg:block col-span-3">
                  <table className="w-full border border-gray-300 mt-6">
                    <thead>
                      <tr>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-40">
                          Category Type
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Category
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-36">
                          Name
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-8">
                          Qty.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-20">
                          U.O.M.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={commoditiesDD}
                              placeholder="Select"
                              disabled="disabled"
                              name="Category"
                              Class="p-0"
                              selectedOption={commoditiesDD.find(
                                (x) => x.value == row.indentCategoryType
                              )} // Use the entire object
                              controlFunc={(e) => {
                                handleInputChange(
                                  e,
                                  index,
                                  "indentCategoryType"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={row.indentCategoryList || []}
                              placeholder="Select"
                              name="Category"
                              Class="p-0"
                              disabled="disabled"
                              selectedOption={
                                row.indentCategoryList
                                  ? row.indentCategoryList.find(
                                      (x) => x.value === row.indentCategory
                                    )
                                  : null
                              }
                              // selectedOption={row.indentCategoryList.find(
                              //   (x) => x.value == row.indentCategory
                              // )} // Use the entire object
                              controlFunc={(e) => {
                                handleInputChange(e, index, "indentCategory");
                              }}
                            />
                            {/* <DropDown
                              options={bindCategoryDropDown}
                              placeholder="Select"
                              name="Category"
                              selectedOption={row.indentCategory}
                              controlFunc={(e) =>
                                handleInputChange(
                                  e.target,
                                  index,
                                  "indentCategory"
                                )
                              }
                            /> */}
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={bindCategoryNameDropDown}
                              name="name"
                              placeholder="Select"
                              disabled="disabled"
                              selectedOption={bindCategoryNameDropDown.find(
                                (x) => x.value == row.indentName
                              )}
                              Class="p-0"
                              controlFunc={(e) => {
                                handleInputChange(e, index, "indentName");
                              }}
                            />
                            {/* <DropDown
                              options={bindCategoryNameDropDown}
                              name="name"
                              placeholder="Select"
                              selectedOption={row.indentName}
                              controlFunc={(e) =>
                                handleInputChange(
                                  e.target,
                                  index,
                                  "indentName"
                                )
                              }
                            /> */}
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.indentQuantity}
                              controlFunc={(e) => {
                                handleInputChange(
                                  e.target,
                                  index,
                                  "indentQuantity"
                                );
                              }}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              type="text"
                              disabled="disabled"
                              content={row.UOM}
                              controlFunc={(e) => {
                                handleInputChange(e.target, index, "UOM");
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className={mainTable.tableMobile} role="table">
                  <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                    {tableData.length > 0 &&
                      tableData.map((row, index) => (
                        <div scope="col" class={mainTable.mobileTable}>
                          {headerData.map((keyData) => (
                            <div
                              scope="cell"
                              class={mainTable.tableData}
                              role="cell"
                            >
                              {keyData.key === "Action" ? (
                                <div class={mainTable.tableData}>
                                  <div className="md:block hidden">
                                    <button
                                      id="dropdownMenuIconButton"
                                      data-dropdown-toggle="dropdownDots"
                                      class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                      type="button"
                                    >
                                      <svg
                                        class="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                  {/* <div className="md:hidden flex justify-center">
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={handleAddRow}
                                      value="Add"
                                    />
                                    <input
                                      type="button"
                                      className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                      onClick={() => handleDeleteRow(index)}
                                      value="Delete"
                                    />
                                  </div> */}
                                </div>
                              ) : keyData.key === "indentCategoryType" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <AutoDropDown
                                    options={commoditiesDD}
                                    placeholder="Select"
                                    name="Category"
                                    disabled="disabled"
                                    Class="p-0"
                                    selectedOption={commoditiesDD.find(
                                      (x) => x.value == row.indentCategoryType
                                    )} // Use the entire object
                                    controlFunc={(e) => {
                                      handleInputChange(
                                        e,
                                        index,
                                        "indentCategoryType"
                                      );
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "indentCategory" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <AutoDropDown
                                     options={row.indentCategoryList || []}
                                    placeholder="Select"
                                    name="Category"
                                    disabled="disabled"
                                    Class="p-0"
                                    selectedOption={
                                      row.indentCategoryList
                                        ? row.indentCategoryList.find(
                                            (x) =>
                                              x.value === row.indentCategory
                                          )
                                        : null
                                    }
                                    controlFunc={(e) => {
                                      handleInputChange(
                                        e,
                                        index,
                                        "indentCategory"
                                      );
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "indentName" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full whitespace-nowrap font-bold">
                                    {keyData.title}
                                  </div>
                                  <AutoDropDown
                                    options={bindCategoryNameDropDown}
                                    disabled="disabled"
                                    name="name"
                                    placeholder="Select"
                                    selectedOption={bindCategoryNameDropDown.find(
                                      (x) => x.value == row.indentName
                                    )}
                                    Class="p-0"
                                    controlFunc={(e) => {
                                      handleInputChange(e, index, "indentName");
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "indentQuantity" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <InputText
                                    type="text"
                                    disabled="disabled"
                                    className={mainTable.input}
                                    content={row.indentQuantity}
                                    controlFunc={(e) => {
                                      handleInputChange(
                                        e.target,
                                        index,
                                        "indentQuantity"
                                      );
                                    }}
                                  />
                                </div>
                              ) : keyData.key === "UOM" ? (
                                <div className=" border p-1">
                                  <div className="flex items-center w-full font-bold">
                                    {keyData.title}
                                  </div>
                                  <InputText
                                    type="text"
                                    disabled="disabled"
                                    content={row.UOM}
                                    controlFunc={(e) => {
                                      handleInputChange(e.target, index, "UOM");
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="flex border p-1">
                                  <div className="flex basis-1/2 font-bold">
                                    {keyData.title}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
                <div class="lg:col-span-3 sm:col-span-1">
                  <TextArea
                    inputType="text"
                    title="Description"
                    name="Description"
                    rows="4"
                    content={data.Remarks_en}
                    placeholder="Description"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Indent };
