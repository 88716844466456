// Code review - In Progress

import Dashboard from "../containers/Dashboard";

// Documentation - In Progress
const topMenu = {
  menu: "flex items-center",
  menuList: "list-none ml-2 text-sm text-white font-bold pr-4 break-normal",
};

const mainTable = {
  /*....table design css...*/
  headerTitle:
    "max-lg:py-2 text-xl font-medium  items-center text-neutral-700 mt-5 md:mt-0",
  gridTableHeader:
    "my-2 mx-1 sm:my-2 max-lg:mt-4 sm:mx-12 md:my-2 md:mx-0 lg:mx-0 sm:ml-12",
  tableShadowWidth:
    "shadow-lg overflow-x-auto rounded-lg  w-full max-2xl:w-full ",
  searchAdd:
    "  flex-col-reverse md:flex-row max-md:w-full pb-2 mt-1 bg-white flex gap-2 ",
  addButton:
    "text-white max-md:z-10 bg-[#2d2d6e] cursor-pointer h-9 hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center",
  addClientButton:
    "text-white max-lg:mt-4 bg-[#2d2d6e] max-md:w-full whitespace-nowrap cursor-pointer h-9 hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  mr-2 mb-2 ",
  appointmentsButton:
    "text-white bg-[#2d2d6e] cursor-pointer h-9 flex justify-end hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2 ",
  tableVisibility: `md:block hidden `,
  table:
    "relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full",
  xScroll: "overflow-x-auto w-full",
  splSessionTable:
    "relative z-0 flex flex-col md:block h-40 overflow-y-auto hidden border text-sm text-left text-gray-500 w-full",
  tableRole:
    "relative z-0 flex flex-col md:block hidden border text-sm text-left text-gray-500 w-full overflow-y-auto h-96",
  tableRoleVsCapability:
    "relative z-0 flex flex-col border text-sm text-left text-gray-500 w-full  h-96",

  tableMobile:
    " z-0 flex flex-row md:flex-col block md:hidden text-sm text-left text-gray-500  h-[23rem] overflow-auto",
  childTableMobile:
    " z-0 flex flex-row md:flex-col h-fit block md:hidden text-sm text-left text-gray-500  h-[23rem] overflow-auto",
  tableHeader:
    "text-xs flex md:flex-row flex-col whitespace-nowrap bg-[#2d2d6e] text-white capitalize cursor-pointer w-full ",
  tableHeaderRoleVsCapability:
    "text-xs flex md:flex-row flex-col whitespace-nowrap bg-[#2d2d6e] text-white capitalize cursor-pointer w-full max-md:w-fit",
  tableHeaderColumn: "px-2 py-3",
  tableRow:
    "flex md:flex-row whitespace-nowrap relative flex-wrap md:flex-nowrap border-b border-gray-300 bg-white hover:bg-gray-50 w-full",
  tableIssueRow:
    "flex md:flex-row whitespace-nowrap relative flex-wrap md:flex-nowrap bg-white hover:bg-gray-50 w-full",
  tableRoleVsCapabilityRow:
    "flex w-full justify-between border md:border-0 md:flex-row whitespace-nowrap relative flex-wrap md:flex-nowrap bg-white hover:bg-gray-50 w-full",

  tableData: "px-2 py-2 md:py-3 flex-1 bg-white",
  searchIconPosition:
    "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none",
  searchIconSvg: "w-5 h-5 text-gray-500",
  searchInputBox:
    "block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ",
  dropDownRecords:
    "block px-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-20 h-9 bg-gray-50 focus:ring-blue-500 focus:border-blue-500",
  mobileTable:
    "  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mx-2 my-4 rounded-lg",
  mobileStudentTable:
    "  shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mx-2 my-4 rounded-lg border-black",
  /*.....Add screen Design...*/
  mainAddScreen: "border-black",
  formForClientMaster: "overflow-auto p-5 min-h-[60vh] max-h-[72vh]",
  formMaster: "overflow-auto min-h-[60vh] max-h-96 2xl:max-h-[72vh]",
  formForPopupcontainer: "overflow-auto p-5 lg:h-96 min-h-[60vh] max-h-[72vh]",
  formPopupcontainer:
    "overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit",
  formForPopup: "overflow-y-scroll p-5 max-h-[72vh]",
  horizontalLine: "h-px mb-2 bg-gray-200 border-0",
  servicesHorizontalLine: "h-px my-2  bg-red-600 border-0",
  topSpaceContent: "mt-3 py-4",
  // firstMainAddScreen: 'mx-auto w-2/5 border-black',
  firstMainAddScreen: "mx-auto flex justify-center md:w-2/5 border-black",
  secondBoxScreen: "relative h-full max-w-full md:h-auto mx-16",
  addShadowScreen: "relative bg-white rounded-lg shadow-gray-400",
  mainContentScreen: "py-2",
  addScreenTitle:
    "my-1 max-lg:mt-4 mx-2 lg:mx-0 text-xl font-medium text-neutral-700",
  formContentSpace: "space-y-6 mt-3 py-4",
  labelBold: "block mb-2 text-base capitalize font-bold text-neutral-700",
  labelBold1:
    "block mb-1.5 mt-2 text-sm capitalize font-medium text-neutral-700",
  label: "block mb-2 text-sm capitalize font-medium text-neutral-700",
  serviceLabel: "block mb-2 text-sm capitalize font-bold text-neutral-1000 text-blue-950",
  labelNoWrap:
    "block mb-2 text-sm capitalize font-medium md:whitespace-nowrap text-neutral-700",
  labelNfp:
    "block mb-2 mt-2 ml-3 text-sm flex gap-1  capitalize font-medium text-neutral-700",

  labelBaseline:
    "block mb-2 flex text-sm capitalize items-center font-medium text-neutral-700",
  input:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
  input2:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2",
  smallInput:
    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1",
  inputDiv4:
    "grid gap-2 md:grid-cols-4 items-center bg-gray-50 p-1 border border-gray-300 rounded-lg",
  inputDiv2:
    "grid gap-2 md:grid-cols-2 items-center bg-gray-50 p-1 border border-gray-300 rounded-lg",
  inputRadio:
    "w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2",
  // inputRadioLabel:"w-full whitespace-pre-line py-2.5 hover:cursor-pointer text-sm font-medium text-gray-900 ",
  // inputRadioDiv:"flex  items-center bg-gray-50   pl-4 border border-gray-300 rounded-lg ",
  // inputCheckBox:"w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 ",
  // inputCheckBoxLabel:"py-2.5 ml-2 whitespace-pre-line text-sm hover:cursor-pointer font-medium text-gray-900 ",
  inputRadioLabel:
    "w-full whitespace-pre-line py-2.5 hover:cursor-pointer text-sm font-medium text-gray-900",
  inputRadioDiv:
    "grid grid-cols-2 md:grid md:grid-cols-2 lg:flex lg:grid-cols-1 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg",
  inputCheckbxDiv:
    "grid grid-cols-2 md:grid md:grid-cols-6 lg:grid lg:grid-cols-6 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg",
  inputCheckBox:
    "w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2",
  inputCheckBoxLabel:
    "py-2.5 ml-2 whitespace-pre-line text-sm hover:cursor-pointer font-medium text-gray-900",
  inputGenderClient:
    "grid gap-2 md:grid-cols-4 flex items-center bg-gray-50 p-1 border border-gray-300 rounded-lg",

  inputDate:
    "bg-gray-50 border hover:cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
  radioFontWeight: "w-full text-lg font-semibold",
  textArea:
    "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500",
  submitCancelMainDiv:
    "flex justify-center p-3 space-x-2 items-baseline border-gray-200 rounded-b",
  previousNextMainDiv:
    "flex hidden md:flex sm:flex lg:flex justify-center p-3 space-x-2 items-baseline border-gray-200 rounded-b",
  submitButton:
    "text-white bg-[#2d2d6e] hover:bg-[#24292F]/90 focus:ring-4 cursor-pointer focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center",
  disabledSubmitButton:
    "text-white bg-[#2d2d6e] cursor-not-allowed opacity-50 hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center",
  goButton:
    "text-white w-20 text-center py-2 mt-7 md:mb-1 bg-[#2d2d6e] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  text-center",
  backButton:
    "text-gray-500 border border-gray-200 w-20 focus:outline-none text-center py-2 mt-7 md:mb-1 bg-white hover:bg-gray-100 cursor-pointer focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-sm  text-center",

  cancelButton:
    "text-gray-500 bg-white hover:bg-gray-100 cursor-pointer focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2 hover:text-gray-900 focus:z-10",
  roleHeadingLabel:
    "py-2.5 px-6 ml-2 whitespace-pre-line text-lg font-extrabold hover:cursor-pointer font-medium text-gray-900",
  optionsListClass:
    "py-2.5 ml-2 whitespace-pre-line text-sm hover:cursor-pointer font-medium text-gray-900",
  gridInventory2: "grid gap-4 md:grid-cols-2 sm:col-span-1",
  gridInventory3: "grid gap-4 mb-3 md:grid-cols-3 sm:col-span-1",
  grid2: "grid gap-4 mb-6 md:grid-cols-2 sm:col-span-1",
  grid3: "md:grid gap-4 mb-6 md:grid-cols-3 sm:col-span-1",
  grid4: "md:grid gap-4 mb-6 md:grid-cols-4 sm:col-span-1 w-full",
  grid5: "grid gap-4 mb-6 md:grid-cols-5 sm:col-span-1 w-full",
  grid8: "grid gap-4 mb-6 md:grid-cols-8 sm:col-span-1 w-full",
  grid7: "grid gap-4 mb-6 md:grid-cols-7 sm:col-span-1 w-full",
  grid9: "grid md:grid-cols-3",
  fileUpload:
    "block w-full p-2.5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none",
  roleCapLabel: "text-sm font-medium whitespace-pre-wrap text-black",
  largeCheckbox:
    "w-3 h-3 lg:w-4 lg:h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2",
  smallCheckBox:
    "w-3 h-3 lg:w-4 lg:h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500",
  smallCheckboxLabel: "w-full py-3 ml-2 text-sm font-medium text-gray-900",

  underlistRoleCap: "flex w-auto text-sm font-medium rounded-lg",

  userCapabilityTable:
    "relative z-0 flex flex-col block  border text-sm text-left text-gray-500 w-full",
  userCapabilityRow:
    "flex justify-between md:justify-normal md:flex-col whitespace-nowrap relative flex-wrap md:flex-nowrap bg-white hover:bg-gray-50",
  userCapabilityHeader:
    "text-xs flex flex-row justify-between md:justify-normal md:flex-row  whitespace-nowrap bg-[#2d2d6e] text-white capitalize cursor-pointer w-full",
  userCapabilityRowSubMenu:
    "flex justify-between md:justify-normal md:flex-col whitespace-nowrap relative flex-wrap md:flex-nowrap bg-white hover:bg-gray-50 w-full",
  //Mobile table css

  roleVsCapabiltyCss:
    "flex justify-between md:justify-normal md:flex-row whitespace-nowrap relative md:flex-wrap md:flex-nowrap bg-white hover:bg-gray-50",

  tableShadowWidthMobile:
    "shadow-lg overflow-x-auto rounded-lg  w-full max-2xl:w-full ",

  /*......Signup...*/

  signupBackground: "bg-blue-950",
  signMainContent:
    "flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen md:h-screen lg:py-0",
  signupShadow: "w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ",
  signupSpaceing: "p-6 space-y-4 md:space-y-6 sm:p-8",
  signupTitle:
    "text-xl font-bold leading-tight tracking-tight text-blue-950 md:text-lg",
  formSpace: "space-y-4 md:space-y-6",
  signupLabel: "block mb-2 text-sm font-medium text-blue-950",
  signupInput:
    "bg-slate-100 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5",

  /* Dashboard Comman CSS Start */

  DashboardShadowBox: `"w-full shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] rounded-md p-1 mx-2 my-3`,
  loginBtn: "w-full text-white bg-red-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ",
  loginBtnDisabled: "w-full text-white bg-red-600  cursor-not-allowed hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ",

};

const validationColor = {
  errorColor:
    "bg-gray-50 border-2 border-rose-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
  successColor:
    "bg-gray-50 border-2 border-green-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
};

export { topMenu, mainTable, validationColor };
