// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";

import { mainTable } from "../css/Common";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI } from "../services/APIConnection";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

function ViewBrochure() {
  const location = useLocation();
  const [viewBrochure, setViewBrochure] = useState(false);
  const [viewBrochureError, setViewBrochureError] = useState(false);
  const [language, setLanguage] = useState("");
  const [topic, setTopic] = useState("");
  const [bindTopic, setbindTopic] = useState([]);
  const [bindBrochureMasterDatas, setBindBrochureMasterDatas] = useState([]);
  const [bindLanguage, setBindLanguagesDD] = useState([]);
  const [bindBrochurefilteredData, setBindBrochurefilteredData] = useState([]);
  const [viewBrochureFields, setViewBrochureFields] = useState({
    topic: "",
    language: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  const [viewBrochureFieldsError, setViewBrochureFieldsError] = useState({
    topicError: "",
    languageError: "",
  });
  const handleUserFieldsChange = (value, name) => {
    setViewBrochureFields({
      ...viewBrochureFields,
      [name]: value,
    });
  };
  const showBrochure = () => {
    debugger
    
    setViewBrochureFieldsError({
      topicError: "",
      languageError: "",
    });

    // Initialize an object to store validation errors for each field
    const errors = {};

    // Check if topic is selected
    if (!viewBrochureFields.topic) {
      errors.topic = "Select Topic";
    }

    // Check if language is selected
    if (!viewBrochureFields.language) {
      errors.language = "Select Language";
    }

    // Set errors in state
    setViewBrochureFieldsError({
      topicError: errors.topic || "",
      languageError: errors.language || "",
    });
    // Given Languageid
    const Languageid = viewBrochureFields.language;

    // Filter data based on the condition
    const filteredData = Object.values(bindBrochureMasterDatas).filter(
      (item) =>
        item.BrochureLanguage === Languageid &&
        item.BrochureTopic == viewBrochureFields.topic
    );

    // Check if the filtered data is empty
    if (filteredData.length === 0) {
      setViewBrochureError(true);
      setViewBrochure(false);
      setIsOpen(true);
      // Optionally, you can set a state or perform any other action to handle the empty case
    } else {
      // Set the filtered data and show the brochure
      setBindBrochurefilteredData(filteredData);
      setViewBrochure(true);
      setViewBrochureError(false);
      setIsOpen(false);
      // Output the filtered data
    }
  };
  useEffect(() => {
    setViewBrochureFields({
      ...viewBrochureFields,
      language: "",
    });
    const uniqueLanguages = new Set();
    bindBrochureMasterDatas.forEach((item) => {
      if (item.BrochureTopic === viewBrochureFields.topic) {
        uniqueLanguages.add(item.BrochureLanguage);
      }
    });

    const filteredLanguages = Array.from(uniqueLanguages).map((language) => ({
      value: language,
      label: bindBrochureMasterDatas.find(
        (item) => item.BrochureLanguage === language
      )?.Language,
    }));

    setBindLanguagesDD(filteredLanguages);
  }, [viewBrochureFields.topic, bindBrochureMasterDatas]);
  const fetchData = async () => {
    try {
      const brochureResponse = await BindAPI("BrochuresAPI/BindBrochure");
      const brochureTopicDrpData = await BindAPI("MasterDataAPI/BindTopic");
      setBindBrochureMasterDatas(
        brochureResponse.data.Table.filter((item) => item.BlockedBy === null)
      );

      // const languageResponse = await BindAPI("LanguageAPI/GetLanguage");
      // const languageDD =
      //   languageResponse.data.Table.length > 0 &&
      //   languageResponse.data.Table.map((m) => ({
      //     value: m.MstLanguageId,
      //     label: m.Language,
      //   }));

      const brochureTopicDD =
        brochureTopicDrpData.data.Table.length > 0 &&
        brochureTopicDrpData.data.Table.filter(
          (item) => item.BlockedBy === null
        ).map((m) => ({
          value: m.MstIECTopicsId,
          label: m.IECTopic_en,
        }));
      // setBindLanguagesDD(languageDD);
      setbindTopic(brochureTopicDD);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const showNextPhoto = () => {
    setCurrentPhotoIndex(
      (prevIndex) => (prevIndex + 1) % bindBrochurefilteredData.length
    );
  };
  useEffect(() => {
    if (location.pathname == "/viewbrochure") {
      setViewBrochure(false);
      fetchData();
      setViewBrochureFields({
        topic: "",
        language: "",
      });
      setbindTopic([]);
      setBindLanguagesDD([]);
    }
  }, [location]);
  const showPreviousPhoto = () => {
    setCurrentPhotoIndex((prevIndex) =>
      prevIndex === 0 ? bindBrochurefilteredData.length - 1 : prevIndex - 1
    );
  };

  return (
    <MainLayout pageName="3">
      <div className="h-screen">
        <div className={mainTable.headerTitle}>
          <div className={mainTable.gridTableHeader}>{`View Brochures`}</div>
        </div>
        <div className="">
          <div className="md:grid gap-4 mb-6 md:grid-cols-3 sm:col-span-1">
            <AutoDropDown
              title="Topics"
              options={bindTopic}
              placeholder="Select"
              name="topic" // Change name to "topic"
              Class="p-0"
              classStar="text-red-500"
              selectedOption={
                viewBrochureFields.topic
                  ? bindTopic.find((x) => x.value == viewBrochureFields.topic)
                  : ""
              }
              controlFunc={(value, e) => {
                setViewBrochureFields({
                  ...viewBrochureFields,
                  language: "",
                });
                setViewBrochureFieldsError({
                  ...viewBrochureFieldsError,
                  topicError: value ? "" : "Select Topics",
                });
                handleUserFieldsChange(value.value, e.name); // Just pass the value directly
                setViewBrochureFields({
                  ...viewBrochureFields,
                  topic: value.value,
                  language: "",
                });
                setBindBrochurefilteredData([]);
                setViewBrochure(false);
                setCurrentPhotoIndex(0);
              }}
              controlFuncBlur={() => {
                setViewBrochureFieldsError({
                  ...viewBrochureFieldsError,
                  topicError: viewBrochureFields.topic ? "" : "Select Topics",
                });
              }}
              errorMessage={viewBrochureFieldsError.topicError}
              tabIndex="1"
            />
            <AutoDropDown
              title="Language"
              options={bindLanguage}
              placeholder="Select"
              name="language"
              Class="p-0"
              classStar="text-red-500"
              selectedOption={
                viewBrochureFields.language
                  ? bindLanguage.find(
                      (x) => x.value == viewBrochureFields.language
                    )
                  : ""
              }
              // selectedOption={bindLanguage.find(
              //   (x) => x.value === viewBrochureFields.language
              // )}
              controlFunc={(value, e) => {
                setViewBrochureFieldsError({
                  ...viewBrochureFieldsError,
                  languageError: value ? "" : "Select Language", // Set error message based on value
                });
                handleUserFieldsChange(value.value, e.name); // Pass value directly to handleUserFieldsChange

                setBindBrochurefilteredData([]);
                setViewBrochure(false);
              }}
              controlFuncBlur={() => {
                setViewBrochureFieldsError({
                  ...viewBrochureFieldsError,
                  languageError: viewBrochureFields.language
                    ? ""
                    : "Select Language", // Clear error if language is selected
                });
              }}
              errorMessage={viewBrochureFieldsError.languageError} // Display error message
              tabIndex="1"
            />

            <div className="flex md:justify-start md:items-center mt-6 justify-center">
              <button
                type="button"
                className="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-[#24292F]/90 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-[#2d2d6e] focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)]     "
                onClick={showBrochure}
              >
                GO
              </button>
            </div>
          </div>
        </div>
        {viewBrochure && (
          <div className="photo-container h-96 flex mt-10 items-center align-middle justify-center relative">
            <div
              className="md:absolute top-1/2 transform -translate-y-1/2 right-20 text-blue-950 px-4 py-2  cursor-pointer"
              onClick={showNextPhoto}
            >
              <div className="hidden md:block">
                <svg fill="none" viewBox="0 0 11 11" height="2em" width="2em">
                  <path
                    stroke="currentColor"
                    strokeLinecap="square"
                    d="M6.5 10.5l3-3-3-3"
                  />
                </svg>
              </div>
              <div className="md:hidden">
                <svg fill="none" viewBox="0 0 11 11" height="2em" width="2em">
                  <path
                    stroke="currentColor"
                    strokeLinecap="square"
                    d="M8.5 4.5l-3 3 3 3"
                  />
                </svg>
              </div>
            </div>

            <div className="flex justify-center m-auto w-96 h-96">
              {bindBrochurefilteredData.length > 0 &&
                currentPhotoIndex < bindBrochurefilteredData.length && (
                  <>
                    {bindBrochurefilteredData[currentPhotoIndex]
                      ?.BrochureType === 1 ? (
                      <img
                        src={
                          bindBrochurefilteredData[currentPhotoIndex]
                            ?.NewVariable
                        }
                        alt="brochureImage"
                        className="max-w-full max-h-70vh object-contain"
                      />
                    ) : (
                      <iframe
                        title="Video"
                        className="max-w-full max-h-70vh object-contain"
                        src={
                          bindBrochurefilteredData[currentPhotoIndex]
                            ?.NewVariable
                        }
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    )}
                  </>
                )}
            </div>

            <div
              className="prev-button md:absolute top-1/2 transform -translate-y-1/2 left-20 text-blue-950 px-4 py-2  cursor-pointer "
              onClick={showPreviousPhoto}
            >
              <div className="hidden md:block">
                <svg fill="none" viewBox="0 0 11 11" height="2em" width="2em">
                  <path
                    stroke="currentColor"
                    strokeLinecap="square"
                    d="M8.5 4.5l-3 3 3 3"
                  />
                </svg>
              </div>
              <div className="md:hidden">
                <svg fill="none" viewBox="0 0 11 11" height="2em" width="2em">
                  <path
                    stroke="currentColor"
                    strokeLinecap="square"
                    d="M6.5 10.5l3-3-3-3"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
        {viewBrochureError && (
          <div className="flex justify-center">No Brochures.</div>
        )}
      </div>
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
    </MainLayout>
  );
}

export default ViewBrochure;
