// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import InputText from "../ReusableComponents/InputText";
import DropDown from "../ReusableComponents/DropDown";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const CityVillagePopup = ({ data, data2 }) => {
  console.log("data", data);
  const [countryName, setCountryName] = useState("");

  return (
    <div class="w-full ">
      <div class=" border-black  "></div>
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <DropDown
                  classDiv="hidden"
                  title={"Country"}
                  options={[]}
                  placeholder={"Select"}
                  name={"country"}
                  selectedOption={countryName}
                  controlFunc={(e) => setCountryName(e.target.value)}
                />
                <AutoDropDown
                  title="State"
                  options={[]}
                  placeholder="Select"
                  classStar="text-red-500"
                  name="state"
                  selectedOption={data2[0]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="District"
                  options={[]}
                  placeholder="Select"
                  classStar="text-red-500"
                  name="state"
                  selectedOption={data2[1]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Block"
                  options={[]}
                  placeholder="Select"
                  classStar="text-red-500"
                  name="state"
                  selectedOption={data2[2]}
                  Class="custom-class"
                  disabled="false"
                  tabIndex="1"
                />

                <InputText
                  inputType={"text"}
                  title="City / Village"
                  classStar="text-red-500"
                  name={"location"}
                  content={data.City_en}
                  disabled="false"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityVillagePopup;
