// Code review - In Progress
// Documentation - In Progress
import React from "react";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const PartnerOrganizationPopup = ({ data, data1 }) => {

  return (
    <div class="w-full ">
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow  m-2">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <AutoDropDown
                  title="State"
                  options={[]}
                  placeholder="Select"
                  name="state"
                  selectedOption={data1[0]}
                  classStar="text-red-500"
                  disabled="disabled"
                  Class="custom-class"
                />
                <AutoDropDown
                  title="Branch/Project"
                  options={[]}
                  placeholder="Select"
                  name="location"
                  selectedOption={data1[1]}
                  disabled="disabled"
                  classStar="text-red-500"
                  Class="custom-class"
                />
                <InputText
                  classStar="text-red-500"
                  inputType={"text"}
                  title={"Partner Organization"}
                  name={"partnerorganization"}
                  content={data.PartnerOrganizationName}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerOrganizationPopup;
