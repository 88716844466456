// Code review - In Progress
// Documentation - In Progress
import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import "react-toastify/dist/ReactToastify.css";

import MainPopup from "../popup-containers/MainPopup";
import MainLayout from "../components/MainLayout";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { openingStockHeader } from "../components/HeaderAttribute";
import toast, { Toaster } from "react-hot-toast";
import GridPage from "../components/GridPage";
import AddScreenTitle from "../components/AddScreenTitle";
import Buttons from "../components/Buttons";
import { BindAPI, BindPostAPI, IUDAPI } from "../services/APIConnection";
import UsePrevious from "../Hook/UsePrevious";
import PopUpScreenTitle from "../components/PopUpScreenTitle";
import PopUpButtons from "../components/PopUpButtons";
import OpeningStockPopup from "../popup-containers/OpeningStockPopup";
import { useLocation } from "react-router-dom";
import DropDown from "../ReusableComponents/DropDown";
import { useUserCapability } from "../components/ConstantReducerProvider";
import ErrorPopup from "../ReusableComponents/ErrorPopup";

const OpeningStock = () => {
  const location = useLocation();
  const [tableView, setTableView] = useState(true);
  const [addView, setAddView] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(false);
  const [blockDetails, setBlockDetails] = useState(false);
  const [unBlockDetails, setUnBlockDetails] = useState(false);

  const [addScreenTitle, setAddScreenTitle] = useState(false);
  const [updateScreenTitle, setUpdateScreenTitle] = useState(false);
  const [bindCommoditiesCategory, setBindCommoditiesCategory] = useState([]);
  const [bindCommodities, setBindCommodities] = useState([]);
  const [commoditiesCategoryDD, setCommoditiesCategoryDD] = useState([]);
  const [commoditiesUOMDD, setCommoditiesUOMDD] = useState([]);
  const [rowIndex, setRowIndex] = useState("");
  const [commoditiesError, setCommoditiesError] = useState("");
  const [tableError, setTableError] = useState("");
  const [bindBrand, setBindBrand] = useState([]);
  const [popUpOpen, setpopUpOpen] = useState(false);

  const [viewDetails, setViewDetails] = useState(false);
  const [openingStocks, setOpeningStocks] = useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);

  const [commoditiesDD, setCommoditiesDD] = useState([]);
  const [commodities, setCommodities] = useState("");

  const [bindOpeningStockData, setBindOpeningStockData] = useState([]);
  const [mstOpeningStockId, setMstOpeningStockId] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [currentData1, setCurrentData1] = useState([]);

  const [bindEntityDD, setBindEntityDD] = useState([]);

  const [entity, setEntity] = useState("");
  const [entityLabel, setEntityLabel] = useState("");
  const [entityError, setEntityError] = useState("");
  const [branchProject, setBranchProject] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdp, setSDP] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [stockAutoId, setStockAutoId] = useState("");
  const [areaError, setAreaError] = useState("");
  const [outReachWorker, setOutReachWorker] = useState("");
  const [outReachWorkerError, setOutReachWorkerError] = useState("");

  const [bindBranchProject, setBindBranchProject] = useState([]);
  const [getLocationDatas, setGetLocationDatas] = useState([]);
  // const [getSDPDatas, setGetSDPDatas] = useState([]);
  // const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [bindSDPData, setBindSDPData] = useState([]);
  const [bindOutReachWorker, setBindOutReachWorker] = useState([]);
  const [bindOutReachWorkerData, setBindOutReachWorkerData] = useState([]);
  const [bindAreaDD, setBindAreaDD] = useState([]);
  const [bindAreaDatas, setBindAreaDatas] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;

  const [organizationsCodes, setoOrganizationsCodes] = useState({
    stateCode: "",
    branchProjectCode: "",
    SDPCode: "",
    areaCode: "",
  });
  const [commoditiesCode, setCommoditiesCode] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const [tableData, setTableData] = useState([
    {
      openingStockCategory: "",
      openingStockName: "",
      openingStockBrand: "",
      batch: "",
      expDate: new Date().toISOString().split("T")[0],
      openingStockOpeningQty: "",
      uom: "",
      uomText: "",
      Charges: "",
      openingStockDate: new Date().toISOString().split("T")[0],
      NameList: [],
      BrandList: [],
      currentStock: "",
    },
  ]);
  const headerData = [
    {
      title: "Category",
      key: "Category",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "Name",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "Brand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch",
      key: "Batch",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Exp. Dt",
      key: "ExpiryDate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Op. Qty",
      key: "OpQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M..",
      key: "uom",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Charges",
      key: "Charges",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date",
      key: "Date",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Action",
      key: "Action",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];
  const previousTableData = UsePrevious(tableData);
  const showAdd = () => {
    setAddView(true);
    setTableView(false);
    setAddScreenTitle(true);
    setUpdateScreenTitle(false);
    entityLabelValue();
    handleDeleteRow(0);
  };

  const cancel = () => {
    setAddView(false);
    setTableView(true);
    setAddScreenTitle(false);
    setUpdateScreenTitle(false);
    setSubmitBtnDisabled(false);
    setpopUpOpen(false);
    setCommodities("");
    setEntity("");
    setEntityError("");
    setBranchProject("");
    setBranchProjectError("");
    setSDP("");
    setSDPError("");
    setArea("");
    setAreaError("");
    setOutReachWorker("");
    setOutReachWorkerError("");
    setBindAreaDD([]);
    setBindSDP([]);
    setTableData([]);
    setTableData([
      {
        openingStockCategory: "",
        openingStockName: "",
        openingStockBrand: "",
        batch: "",
        expDate: new Date().toISOString().split("T")[0],
        openingStockOpeningQty: "",
        uom: "",
        uomText: "",
        Charges: "",
        openingStockDate: new Date().toISOString().split("T")[0],
        NameList: [],
        BrandList: [],
        currentStock: "",
      },
    ]);

    setCommoditiesError("");
    setTableError("");
    setEntityLabel("");
    setCommoditiesCategoryDD([]);
    getOpeningStockDatas();
  };

  const appendOpeningStockTableDatas = (rowData) => {
    handleDeleteRow(0);
    let converyArray = [];
    converyArray.push(rowData);
    setTableData([]);
    const formattedEditTableData = converyArray.map((item) => {
      return {
        openingStockCategory: item.MstCommodityCategoryId,
        openingStockName: item.MstCommoditiesId,
        openingStockBrand: item.MstBrandId,
        batch: item.Batch,
        expDate: item.ExpiryDate.split("T")[0],
        openingStockOpeningQty: item.OpeningQty,
        uom: item.MstCommodityUOMId,
        uomText: item.CommodityUOM,
        Charges: item.Charges,
        openingStockDate: item.Date,
      };
    });

    console.log("formattedEditTableData", formattedEditTableData);
    setTableData((prevData) => [...prevData, ...formattedEditTableData]);
    convertArrayToJSONString(formattedEditTableData);
  };

  const handleEdit = async (rowIndex) => {
    setUpdateScreenTitle(true);
    setAddScreenTitle(false);
    setAddView(true);
    setTableView(false);
    setCommodities(bindOpeningStockData[rowIndex].MstCommodityTypeId);
    appendOpeningStockTableDatas(bindOpeningStockData[rowIndex]);
    setMstOpeningStockId(bindOpeningStockData[rowIndex].MstOpeningStockId);
    setEntity(bindOpeningStockData[rowIndex].MstEntityId);
    setBranchProject(bindOpeningStockData[rowIndex].MstBranchProjectId);
    setSDP(bindOpeningStockData[rowIndex].MstSDPId);
    setArea(bindOpeningStockData[rowIndex].MstAreaId);
    setOutReachWorker(bindOpeningStockData[rowIndex].MstUserId);
    setStockAutoId(bindOpeningStockData[rowIndex].OpStkId);
    entityLabelValue();
  };

  // const dropDownDatas = async () => {
  //   try {
  //     const categoryTypeResponse = await BindAPI(
  //       "CommoditiesAPI/BindCommodityType"
  //     );
  //     const categoryResponse = await BindAPI(
  //       "CommoditiesAPI/BindCommodityCategory"
  //     );
  //     const commoditiesResponse = await BindAPI(
  //       "CommoditiesAPI/BindCommodities"
  //     );

  //     const entityResponse = await BindAPI("FPAIOutReachAPI/BindOrgStructure");
  //     const statesResponse = await BindAPI("StateAPI/GetState");

  //     const locationResponse = await BindAPI("LocationAPI/GetLocation");
  //     const sdpResponse = await BindAPI("SDPAPI/GetSDP");
  //     const commoditiesBrands = await BindAPI("BrandAPI/BindBrand");
  //     const areaResponse = await BindAPI("AreaAPI/GetArea");
  //     const orsNameResponse = await BindAPI("InventoryReceiptAPI/BindORSName");

  //     const commoditiesUOM = await BindAPI("CommoditiesAPI/BindCommodityUOM");

  //     const filteredData = categoryTypeResponse.data.Table;
  //     const filteredWithBlockedByNull = filteredData.filter(
  //       (item) => item.BlockedBy === null
  //     );

  //     const categoryTypeData = filteredWithBlockedByNull.map((item) => ({
  //       value: item.MstCommodityTypeId,
  //       label: item.CommodityType_en,
  //     }));

  //     const filteredData1 = categoryResponse.data.Table;
  //     const filteredWithBlockedByNull1 = filteredData1.filter(
  //       (item) => item.BlockedBy === null
  //     );

  //     const filteredData2 = commoditiesResponse.data.Table;
  //     const filteredWithBlockedByNull2 = filteredData2.filter(
  //       (item) => item.BlockedBy === null
  //     );

  //     const filteredData3 = commoditiesBrands.data.Table;
  //     const filteredWithBlockedByNull3 = filteredData3.filter(
  //       (item) => item.BlockedBy === null
  //     );

  //     const newCommoditiesUOMList = commoditiesUOM.data.Table.map((x) => ({
  //       value: x.MstCommodityUOMId,
  //       label: x.CommodityUOM,
  //     }));

  //     const entityData = entityResponse.data.Table1.map((item) => ({
  //       value: item.MstEntityId,
  //       label: item.EntityName,
  //     }));
  //     const locationData = locationResponse.data.Table.map((item) => ({
  //       value: item.MstLocationId,
  //       label: item.LocationName_en,
  //       code: item.LocationCode,
  //     }));

  //     const statesData = statesResponse.data.Table.map((item) => ({
  //       value: item.MstStateId,
  //       label: item.StateName_en,
  //       code: item?.StateCode,
  //     }));

  //     console.log("statesData", statesData);

  //     setStateDropDown(statesData);
  //     setBindOutReachWorkerData(orsNameResponse.data.Table);
  //     setBindAreaDatas(areaResponse.data.Table);
  //     setBindSDPData(sdpResponse.data.Table);
  //     setGetLocationDatas(locationResponse.data.Table);
  //     setBindBranchProject(locationData);
  //     setBindEntityDD(entityData);
  //     setCommoditiesDD(categoryTypeData);
  //     setBindCommoditiesCategory(filteredWithBlockedByNull1);
  //     setBindCommodities(filteredWithBlockedByNull2);
  //     setBindBrand(filteredWithBlockedByNull3);
  //     setCommoditiesUOMDD(newCommoditiesUOMList);
  //   } catch (error) {}
  // };

  // const getOpeningStockDatas = async () => {
  //   const response = await BindAPI(
  //     "CommoditiesOpeningStockAPI/BindOpeningStock"
  //   );
  //   setBindOpeningStockData(response.data.Table);
  // };

  // useEffect(() => {
  //   dropDownDatas()
  //     .then(() => getOpeningStockDatas())
  //     .catch((error) => {
  //       console.log(`Error on get datas ${error}`);
  //     });
  // }, []);
  const dropDownDatas = async () => {
    try {
      // Parallel API calls
      const [
        categoryTypeResponse,
        categoryResponse,
        commoditiesResponse,
        entityResponse,
        statesResponse,
        locationResponse,
        sdpResponse,
        commoditiesBrands,
        areaResponse,
        orsNameResponse,
        commoditiesUOM,
      ] = await Promise.all([
        BindAPI("CommoditiesAPI/BindCommodityType"),
        BindAPI("CommoditiesAPI/BindCommodityCategory"),
        BindAPI("CommoditiesAPI/BindCommodities"),
        BindAPI("FPAIOutReachAPI/BindOrgStructure"),
        BindAPI("StateAPI/GetState"),
        BindAPI("LocationAPI/GetLocation"),
        BindAPI("SDPAPI/GetSDP"),
        BindAPI("BrandAPI/BindBrand"),
        BindAPI("AreaAPI/GetArea"),
        BindAPI("InventoryReceiptAPI/BindORSName"),
        BindAPI("CommoditiesAPI/BindCommodityUOM"),
      ]);

      // Utility function for filtering and mapping
      const filterAndMap = (data, mapFn) =>
        data?.Table.filter((item) => item.BlockedBy === null).map(mapFn);

      // Data transformations
      const categoryTypeData = filterAndMap(
        categoryTypeResponse.data,
        (item) => ({
          value: item.MstCommodityTypeId,
          label: item.CommodityType_en,
        })
      );

      const entityData = entityResponse.data.Table1.map((item) => ({
        value: item.MstEntityId,
        label: item.EntityName,
      }));

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
        code: item.LocationCode,
      }));

      const statesData = statesResponse.data.Table.map((item) => ({
        value: item.MstStateId,
        label: item.StateName_en,
        code: item?.StateCode,
      }));

      const commoditiesUOMList = commoditiesUOM.data.Table.map((x) => ({
        value: x.MstCommodityUOMId,
        label: x.CommodityUOM,
      }));

      // Set state in a single batch
      setStateDropDown(statesData);
      setBindOutReachWorkerData(orsNameResponse.data.Table);
      setBindAreaDatas(areaResponse.data.Table);
      setBindSDPData(sdpResponse.data.Table);
      setGetLocationDatas(locationResponse.data.Table);
      setBindBranchProject(locationData);
      setBindEntityDD(entityData);
      setCommoditiesDD(categoryTypeData);
      setBindCommoditiesCategory(
        categoryResponse.data.Table.filter((item) => item.BlockedBy === null)
      );
      setBindCommodities(
        commoditiesResponse.data.Table.filter((item) => item.BlockedBy === null)
      );
      setBindBrand(
        commoditiesBrands.data.Table.filter((item) => item.BlockedBy === null)
      );
      setCommoditiesUOMDD(commoditiesUOMList);
    } catch (error) {
      console.error("Error in dropDownDatas:", error);
    }
  };

  const getOpeningStockDatas = async () => {
    try {
      const response = await BindAPI(
        "CommoditiesOpeningStockAPI/BindOpeningStock"
      );
      setBindOpeningStockData(response.data.Table);
    } catch (error) {
      console.error("Error fetching opening stock data:", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await dropDownDatas();
        await getOpeningStockDatas();
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    })();
  }, []);

  // useEffect(() => {}, []);

  function validateDataArray(dataArray) {
    let allValid = true;

    dataArray.forEach((data, index) => {
      let errorMessage = "";

      if (data.openingStockCategory === "") {
        errorMessage += `Object ${
          index + 1
        }: Opening Stock Category cannot be empty.\n`;
      }
      if (data.batch === "") {
        errorMessage += `Object ${index + 1}: Batch No. cannot be empty.\n`;
      }
      if (data.openingStockName === "") {
        errorMessage += `Object ${
          index + 1
        }: Opening Stock opening StockName cannot be empty.\n`;
      }

      if (data.openingStockOpeningQty === "") {
        errorMessage += `Object ${
          index + 1
        }: Opening Stock Opening Quantity cannot be empty.\n`;
      }

      if (data.uom === "") {
        errorMessage += `Object ${
          index + 1
        }: Unit of Measure (uom) cannot be empty.\n`;
      }

      if (data.openingStockBrand === "") {
        errorMessage += `Object ${
          index + 1
        }: Opening Stock Brand cannot be empty.\n`;
      }

      if (data.Charges === "") {
        errorMessage += `Object ${
          index + 1
        }: Opening Stock Charges cannot be empty.\n`;
      }

      if (errorMessage) {
        //console.error(errorMessage); // Log error message to the console
        //alert(errorMessage); // Display error message to the user
        allValid = false; // Set allValid to false indicating at least one validation failed
      }
    });

    return allValid; // Return true if all objects are valid, false otherwise
  }
  const validate = (flag) => {
    // Initialize an object to store validation errors for each field
    const errors = {};

    if (flag === 1 || flag === 2) {
      if (entity == "2" || entity == "3") {
        if (!sdp) {
          errors.sdpError = "Select S.D.P.";
        }
      }
      if (entity == "2" || entity == "3") {
        if (!area) {
          errors.areaError = "Select Area";
        }
      }
      if (entity == "3") {
        if (!outReachWorker) {
          errors.outReachWorkerError = "Select OutReach Worker";
        }
      }
    }
    setSDPError(errors.sdpError || "");
    setAreaError(errors.areaError || "");
    setOutReachWorkerError(errors.outReachWorkerError || "");

    return errors;
  };
  const handleSubmit = async (items, flag) => {
    setSubmitBtnDisabled(true);
    let outputString = "";

    const response = await BindAPI("StateAPI/BindState");
    let stateCode = response.data.Table.filter(
      (x) => x.MstStateId == userProfileDatas[0]?.MstStateId
    ).map((item) => item?.StateCode)[0];

    const branchProjectCode = bindBranchProject
      .filter((x) => x.value == branchProject)
      .map((item) => item?.code)[0];
    const sdpCode = bindSDP
      .filter((x) => x.value == sdp)
      .map((item) => item?.code)[0];
    const areaCode = bindAreaDD
      .filter((x) => x.value == area)
      .map((item) => item?.code)[0];
    const outReachWorkerName = bindOutReachWorker
      .filter((x) => x.value == outReachWorker)
      .map((item) => item?.label)[0];
    if (entity == 1) {
      outputString = `${stateCode}/${branchProjectCode}/${commoditiesCode}`;
    } else if (entity == 2) {
      outputString = `${stateCode}/${branchProjectCode}/${sdpCode}/${areaCode}/${commoditiesCode}`;
    } else if (entity == 3) {
      outputString = `${stateCode}/${branchProjectCode}/${sdpCode}/${areaCode}/${outReachWorkerName}/${commoditiesCode}`;
    }

    // setOpStkId(outputString);

    console.log("Opening Stocks", stateCode, outputString);
    var createdBy = sessionStorage.getItem("createdBy");
    const firstNameValidation = validate(flag);
    if (
      Object.keys(firstNameValidation).length !== 0 ||
      commodities == "" ||
      entity == "" ||
      openingStocks == "" ||
      branchProject == ""
    ) {
      setIsOpen(true);
    }
    if (flag == 1 || flag == 2) {
      if (
        commodities != "" &&
        entity != "" &&
        openingStocks != "" &&
        branchProject != "" &&
        Object.keys(firstNameValidation).length === 0
      ) {
        setIsOpen(false);
        const openingStocksDatas = JSON.parse(openingStocks);
        const isValid = validateDataArray(openingStocksDatas);

        const filteredWithBlockedByNull3 = bindEntityDD.filter(
          (item) => item.BlockedBy === null
        );

        if (isValid) {
          setTableError("");
          const openingStockPayload = {
            mstOpeningStockId: mstOpeningStockId ? mstOpeningStockId : 0,
            opStkId: flag == 1 ? outputString : stockAutoId,
            mstCommodityTypeId: commodities,
            entityName: entityLabel,
            mstentityId: parseInt(entity),
            mstBranchProjectId: branchProject,
            mstSDPId: sdp !== "" ? sdp : null,
            mstAreaId: area !== "" ? area : null,
            mstUserId: outReachWorker !== "" ? outReachWorker : null,
            openingStocks: openingStocks,
            createdBy: createdBy,
            flag: flag,
          };
          const response = await IUDAPI(
            openingStockPayload,
            "CommoditiesOpeningStockAPI/IUDOpeningStock"
          );
          if (flag == 1) {
            if (response.data.Table[0].Result === "S") {
              toast.success("Opening Stock Data Inserted Successfully👍", {
                duration: 3000,
                position: "top-center",
              });
              setTimeout(cancel, 3000);
            } else {
              toast.error("Not Insert Opening Stock 👎", {
                duration: 3000,
                position: "top-center",
              });
              setTimeout(cancel, 3000);
            }
          } else if (flag == 2) {
            if (response.data.Table[0].Result === "M") {
              toast.success("Opening Stock Data Updated Successfully👍", {
                duration: 3000,
                position: "top-center",
              });
              setTimeout(cancel, 3000);
            } else {
              toast.error("Not Updated Opening Stock 👎", {
                duration: 3000,
                position: "top-center",
              });
              setTimeout(cancel, 3000);
            }
          } else {
          }
        } else {
          setSubmitBtnDisabled(false);

          setTableError("Fill the above table");
        }
      } else {
        setSubmitBtnDisabled(false);
        if (commodities == "") {
          setCommoditiesError("Select Commodities");
        }
        if (entity == "") {
          setEntityError("Select Entity");
        }
        if (entity == "1" || entity == "2" || entity == "3") {
          if (branchProject == "") {
            setBranchProjectError("Select Branch/Project");
          }
        }

        if (openingStocks.length == 0) {
          setTableError("Fill the above table");
        }
      }
    } else if (flag == 3 || flag == 4 || flag == 5) {
      const openingStockPayload = {
        mstOpeningStockId: bindOpeningStockData[items].MstOpeningStockId,
        createdBy: createdBy,
        flag: flag,
      };
      const response = await IUDAPI(
        openingStockPayload,
        "CommoditiesOpeningStockAPI/IUDOpeningStock"
      );
      if (flag == 3) {
        if (response.data.Table[0].Result === "D") {
          toast.success(
            "Opening Stock  deleted successfully 🗑️",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to delete Opening Stock data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 4) {
        if (response.data.Table[0].Result === "B") {
          toast.success(
            "Opening Stock data blocked successfully 🚫",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to block Opening Stock data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      } else if (flag == 5) {
        if (response.data.Table[0].Result === "UB") {
          toast.success(
            "Opening Stock data unblocked successfully ✅",
            5000,
            cancel()
          );
          cancel();
        } else {
          toast.error("Failed to unblock Opening Stock data 👎", {
            duration: 2000,
            position: "top-center",
          });
          setTimeout(cancel, 2000);
        }
      }
    } else {
      setSubmitBtnDisabled(false);
    }
  };

  const handleAddRow = () => {
    const newRow = { ...tableData[tableData.length - 1] }; // Copy the last row
    newRow.openingStockCategory = "";
    newRow.openingStockName = "";
    newRow.openingStockBrand = "";
    newRow.batch = "";
    newRow.expDate = new Date().toISOString().split("T")[0];
    newRow.openingStockOpeningQty = "";
    newRow.uom = "";
    newRow.uomText = "";
    newRow.Charges = "";
    newRow.openingStockDate = new Date().toISOString().split("T")[0];
    newRow.NameList = [];
    newRow.BrandList = [];
    newRow.currentStock = "";
    setTableData((prevData) => [...prevData, newRow]);
  };

  // const handleDeleteRow = (index) => {
  //   const updatedTableData = [...tableData];
  //   updatedTableData.splice(index, 1);
  //   setTableData(updatedTableData);
  //   convertArrayToJSONString(updatedTableData);
  // };

  const handleDeleteRow = (index) => {
    setTableData((prevData) => {
      const updatedTableData = prevData.filter((_, i) => i !== index);

      // Ensure at least one row exists
      if (updatedTableData.length === 0) {
        const newRow = {
          openingStockCategory: "",
          openingStockName: "",
          openingStockBrand: "",
          batch: "",
          expDate: new Date().toISOString().split("T")[0],
          openingStockOpeningQty: "",
          uom: "",
          uomText: "",
          Charges: "",
          openingStockDate: new Date().toISOString().split("T")[0],
          NameList: [],
          BrandList: [],
          currentStock: "",
        };

        // Use spread to create a new array
        return [newRow];
      }

      convertArrayToJSONString(updatedTableData); // Call convertArrayToJSONString
      return updatedTableData;
    });
  };

  const convertArrayToJSONString = (array) => {
    try {
      const jsonString = JSON.stringify(array);
      setOpeningStocks(jsonString);
      console.log("jsonString", jsonString);
      return jsonString;
    } catch (error) {
      console.error("Error converting array to JSON string:", error);
      return null;
    }
  };

  const handleInputChange = (selectedOption, rowIndex, columnName) => {
    const value = selectedOption ? selectedOption.value : "";
    setTableData((prevTableData) => {
      const updatedData = [...prevTableData];
      updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
      convertArrayToJSONString(updatedData);
      return updatedData;
    });
  };

  const handleOpen = (isOpen) => {
    setpopUpOpen(isOpen);
  };

  const handleOpeningStockDetails = (rowIndex, flag) => {
    handleOpen(true);

    if (flag === 1) {
      setViewDetails(true);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 3) {
      setViewDetails(false);
      setDeleteDetails(true);
      setBlockDetails(false);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 4) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(true);
      setUnBlockDetails(false);
      setRowIndex(rowIndex);
    } else if (flag === 5) {
      setViewDetails(false);
      setDeleteDetails(false);
      setBlockDetails(false);
      setUnBlockDetails(true);
      setRowIndex(rowIndex);
    }

    const filterCommoditiesDD = commoditiesDD.filter(
      (x) => x.value == bindOpeningStockData[rowIndex].MstCommodityTypeId
    );

    const filerCommoditiesCategory = bindCommoditiesCategory
      .filter(
        (x) =>
          x.MstCommodityTypeId ==
          bindOpeningStockData[rowIndex].MstCommodityTypeId
      )
      .map((item) => ({
        value: item.MstCommodityCategoryId,
        label: item.CommodityCategory_en,
      }))
      .filter(
        (j) => j.value == bindOpeningStockData[rowIndex].MstCommodityCategoryId
      );

    const filerCommoditiesName = bindCommodities
      .filter(
        (x) =>
          x.MstCommodityCategoryId ==
          bindOpeningStockData[rowIndex].MstCommodityCategoryId
      )
      .map((item) => ({
        value: item.MstCommoditiesId,
        label: item.CommodityName_en,
      }))
      .filter(
        (j) => j.value == bindOpeningStockData[rowIndex].MstCommoditiesId
      );

    const filterBrand = bindBrand
      .filter(
        (item) =>
          item.MstCommoditiesId ==
          bindOpeningStockData[rowIndex].MstCommoditiesId
      )
      .map((x) => ({
        value: x.MstBrandId,
        label: x.Brand_en,
      }))
      .filter((i) => i.value == bindOpeningStockData[rowIndex].MstBrandId);

    const filterUOM = commoditiesUOMDD.filter(
      (x) => x.value == bindOpeningStockData[rowIndex].MstCommodityUOMId
    );

    const locationrowData = bindOpeningStockData[rowIndex];
    const LocationInfo = getLocationDatas.find(
      (L) => L.MstLocationId === locationrowData.MstBranchProjectId
    );
    const ResultLocation = {
      value: locationrowData.MstBranchProjectId,
      label: LocationInfo ? LocationInfo.LocationName_en : "DefaultStateLabel",
    };

    const SDProwData = bindOpeningStockData[rowIndex];
    const SDPInfo = bindSDPData.find(
      (SD) => SD.MstSDPId === SDProwData.MstSDPId
    );
    const ResultSDP = {
      value: SDProwData.MstSDPId,
      label: SDPInfo ? SDPInfo.SDP_en : "DefaultStateLabel",
    };

    const ArearowData = bindOpeningStockData[rowIndex];
    const ArearowDataInfo = bindAreaDatas.find(
      (AT) => AT.MstAreaId === ArearowData.MstAreaId
    );
    const ResultArea = {
      value: ArearowData.MstAreaId,
      label: ArearowDataInfo
        ? ArearowDataInfo.AreaName_en
        : "DefaultStateLabel",
    };

    const outReachWorkerDropdown = bindOpeningStockData[rowIndex];
    const outReachWorkerInfo = bindOutReachWorkerData.find(
      (SD) => SD.MstUserId === outReachWorkerDropdown.MstUserId
    );
    const ResultOutReachWorker = {
      value: outReachWorkerDropdown.MstUserId,
      label: outReachWorkerInfo
        ? outReachWorkerInfo.FirstName_en
        : "DefaultStateLabel",
    };

    const allDatas = [
      bindOpeningStockData[rowIndex].MstCommodityTypeId,
      filterCommoditiesDD,
      filerCommoditiesCategory,
      filerCommoditiesName,
      filterBrand,
      filterUOM,
      ResultLocation,
      ResultSDP,
      ResultArea,
      ResultOutReachWorker,
      bindOpeningStockData[rowIndex].MstEntityId,
    ];
    setCurrentData(allDatas);

    let converyArray = [];
    converyArray.push(bindOpeningStockData[rowIndex]);
    const formattedEditTableData = converyArray.map((item) => {
      return {
        openingStockCategory: item.MstCommodityCategoryId,
        openingStockName: item.MstCommoditiesId,
        openingStockBrand: item.MstBrandId,
        batch: item.Batch,
        expDate: item.ExpiryDate.split("T")[0],
        openingStockOpeningQty: item.OpeningQty,
        uom: item.MstCommodityUOMId,
        uomText: item.CommodityUOM,
        Charges: item.Charges,
        openingStockDate: item.Date.split("T")[0],
      };
    });
    setCurrentData1(formattedEditTableData);
  };

  const handleCategoryDropDown = (value) => {
    setCommodities(value.value);
    setCommoditiesError("");
  };

  useEffect(() => {
    if (commodities) {
      const getFilteredCategory = bindCommoditiesCategory
        .filter((item) => item.MstCommodityTypeId == commodities)
        .map((items) => ({
          value: items.MstCommodityCategoryId,
          label: items.CommodityCategory_en,
        }));

      setCommoditiesCategoryDD(getFilteredCategory);
      console.log("setCommoditiesCategoryDD", commoditiesCategoryDD);

      if (commodities == 1) {
        setCommoditiesCode("CPT");
      }

      if (commodities == 2) {
        setCommoditiesCode("MED");
      }

      if (commodities == 3) {
        setCommoditiesCode("SOC");
      }

      if (commodities == 4) {
        setCommoditiesCode("CON");
      }
    } else {
      //setcommoditiesCategoryDD([]);
      console.log("categoryDD");
    }
  }, [commodities]);

  useEffect(() => {
    const updateTableData = async () => {
      const currentTableData = [...tableData];

      for (const row of currentTableData) {
        // Update NameList based on openingStockCategory
        if (row?.openingStockCategory) {
          const selectedCommoditiesCategory = bindCommodities
            .filter(
              (item) => item.MstCommodityCategoryId === row.openingStockCategory
            )
            .map((item) => ({
              value: item.MstCommoditiesId,
              label: item.CommodityName_en,
              uom: item.MstCommodityUOMId,
              uomLabel: item.CommodityUOM,
            }));

          row.NameList = selectedCommoditiesCategory;

          const selectedCommodity = selectedCommoditiesCategory.find(
            (item) => item.value === row.openingStockName
          );

          if (selectedCommodity) {
            row.uomText = selectedCommodity.uomLabel;
            row.uom = selectedCommodity.uom;
          } else {
            row.uomText = "";
            row.uom = ""; // Reset if no selected commodity
          }
        }

        // Update BrandList based on openingStockName
        if (row?.openingStockName) {
          const selectedCommoditiesName = bindBrand
            .filter((item) => item.MstCommoditiesId === row.openingStockName)
            .map((x) => ({
              value: x.MstBrandId,
              label: x.Brand_en,
            }));
          row.BrandList = selectedCommoditiesName;
        }

        // Fetch current stock for openingStockBrand
        if (row?.openingStockBrand) {
          const data = {
            mstCommodityType: commodities,
            mstCategoryId: row.openingStockCategory,
            mstCommodityId: row.openingStockName,
            mstBrandId: row.openingStockBrand,
            mstOrganizationStructureId:
              userProfileDatas[0]?.MstOrganizationId || "",
            mstUserId: userProfileDatas[0]?.MstUserId || "",
            mstRoleId: outReachWorker !== "" ? 7 : 0,
            mstBranchProjectId: userProfileDatas[0]?.MstLocationId || "",
            mstSDPId: sdp !== "" ? sdp : 0,
            mstAreaId: area !== "" ? area : 0,
            mstORWId: outReachWorker !== "" ? outReachWorker : 0,
            txnTypeId: stockAutoId,
            flag: 2,
          };

          try {
            const response = await BindPostAPI(
              [data],
              "InventoryReceiptAPI/BindAvailableCurrentStockValidation"
            );

            const balanceQuantity =
              response?.data?.Table?.[0]?.BalanceQuantity || "";
            row.currentStock = balanceQuantity;
          } catch (error) {
            console.error("Error fetching current stock:", error);
            row.currentStock = ""; // Reset on error
          }
        }
      }

      // Update tableData with new values
      setTableData(currentTableData);
    };

    // Only trigger the update if data has changed
    if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
      updateTableData();
    }
  }, [tableData]);

  useEffect(() => {
    const branchProjectSelectedValue = bindSDPData.find(
      (item) => item.MstLocationId == branchProject // Replace with the desired MstStateId
    );

    if (branchProjectSelectedValue) {
      const branchProjectLabels = bindSDPData
        .filter(
          (item) =>
            item.MstLocationId === branchProjectSelectedValue.MstLocationId
        )
        .map((item) => ({
          MstSDPId: item.MstSDPId,
          SDP_en: item.SDP_en,
          SDPCode: item.SDPCode,
        }));

      const branchProjectSelected = branchProjectLabels.map((AR) => ({
        value: AR.MstSDPId, // Replace with the appropriate value
        label: AR.SDP_en,
        code: AR.SDPCode,
      }));

      setBindSDP(branchProjectSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [branchProject ? branchProject : null]);

  useEffect(() => {
    // Find the selected state in getLocationDatas
    const areaSelectedValue = bindAreaDatas.find(
      (item) => item.MstSDPId == sdp // Replace with the desired MstStateId
    );

    if (areaSelectedValue) {
      const areaLabels = bindAreaDatas
        .filter((item) => item.MstSDPId === areaSelectedValue.MstSDPId)
        .map((item) => ({
          MstAreaId: item.MstAreaId,
          AreaName_en: item.AreaName_en,
          AreaCode: item.AreaCode,
        }));

      const areaSelected = areaLabels.map((AR) => ({
        value: AR.MstAreaId, // Replace with the appropriate value
        label: AR.AreaName_en,
        code: AR.AreaCode,
      }));

      setBindAreaDD(areaSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [sdp ? sdp : null]);

  useEffect(() => {
    setBindOutReachWorker([]);
    const sdpSelectedValue = bindOutReachWorkerData.find(
      (item) => item.MstSDPId == sdp // Replace with the desired MstStateId
    );

    if (sdpSelectedValue) {
      const sdpLabels = bindOutReachWorkerData
        .filter((item) => item.MstSDPId === sdpSelectedValue.MstSDPId)
        .map((item) => ({
          MstUserId: item.MstUserId,
          FirstName_en: item.FirstName_en,
        }));

      const sdpSelected = sdpLabels.map((AR) => ({
        value: AR.MstUserId, // Replace with the appropriate value
        label: AR.FirstName_en,
      }));

      setBindOutReachWorker(sdpSelected);
    } else {
      console.log("stateSelectedValue is undefined or not found.");
    }
  }, [sdp ? sdp : null]);

  const entityLabelValue = () => {
    if (entity) {
      const entityDataDropDown = bindEntityDD.find(
        (item) => item.value == entity
      );

      const entityLabel = {
        value: entity,
        label: entityDataDropDown ? entityDataDropDown.label : "Unknown",
      };
      setEntityLabel(entityLabel.label);
    }
  };

  useEffect(() => {
    if (addScreenTitle || updateScreenTitle) {
      entityLabelValue();
    }
  }, [entity]);

  useEffect(() => {
    if (location.pathname == "/openingstock") {
      setAddScreenTitle(false);
      setTableView(true);
      getOpeningStockDatas();
    }
  }, [location]);

  // useEffect(() => {
  //   if (branchProject || commodities) {
  //     handleBranchOPId();
  //   }
  // }, [branchProject, commodities]);

  const getUserOrganizationDatas = () => {
    if (userProfileDatas && userProfileDatas[0]) {
      setBranchProject(userProfileDatas[0]?.MstLocationId);
      setSDP(userProfileDatas[0]?.MstSDPId);
    }
  };

  // const handleBranchOPId = () => {
  //   if (branchProject) {

  //     const branchProjectCode = bindBranchProject
  //       .filter((x) => x.value == branchProject)
  //       .map((item) => item?.code)[0];
  //     console.log("branchProjectCode", branchProjectCode);
  //     setoOrganizationsCodes((prev) => ({
  //       ...prev,
  //       branchProjectCode: branchProjectCode,
  //     }));
  //   }

  //   generateBranchOPId();
  // };

  // const generateBranchOPId = () => {
  //   if (organizationsCodes.branchProjectCode && commoditiesCode) {
  //     const outputString = `${organizationsCodes.branchProjectCode}/${commoditiesCode}`;
  //     console.log("outputString", outputString);
  //   } else {
  //     return;
  //   }
  // };

  return (
    <MainLayout pageName="2">
      {tableView && (
        <GridPage
          title={`Opening Stock`}
          header={openingStockHeader}
          records={bindOpeningStockData ?? []}
          handleEdit={handleEdit}
          handleAction={handleOpeningStockDetails}
          showAdd={showAdd}
          blockView={true}
        />
      )}
      {addView && (
        <div className="flex flex-col w-full">
          <div className={mainTable.mainAddScreen}>
            <AddScreenTitle
              addScreenTitle={addScreenTitle}
              updateScreenTitle={updateScreenTitle}
              title="Opening Stock"
            />
            <div className={mainTable.addShadowScreen}>
              <div className={mainTable.mainContentScreen}>
                <form className="p-5 min-h-[60vh] max-h-[72vh]">
                  <div className="sm:grid gap-4 mb-6 md:grid-cols-3">
                    {/* <form className={mainTable.grid3}> */}
                    <AutoDropDown
                      Class="Custom-class"
                      title="Commodities"
                      options={commoditiesDD}
                      classStar="text-red-500"
                      placeholder="Select"
                      name="commodities"
                      selectedOption={commoditiesDD.find(
                        (x) => x.value == commodities
                      )}
                      // controlFunc={(value) => setCommodities(value)}
                      controlFunc={handleCategoryDropDown}
                      errorMessage={commoditiesError}
                    />
                    <div>
                      <DropDown
                        title="Entity"
                        classStar="text-red-500"
                        options={bindEntityDD}
                        placeholder="Select"
                        name="entity"
                        selectedOption={entity}
                        controlFunc={(e) => {
                          setBranchProject("");
                          setSDP("");
                          setArea("");
                          setOutReachWorker("");
                          setEntity(e.target.value);
                          getUserOrganizationDatas();
                        }}
                        controlFuncBlur={(e) => {
                          setEntityError(e.target.value ? "" : "Select Entity");
                        }}
                      />
                      {entityError && (
                        <div className="text-red-500">{entityError}</div>
                      )}
                    </div>

                    {entity && (
                      <div>
                        <AutoDropDown
                          title="Branch/Project"
                          classStar="text-red-500"
                          options={
                            userProfileDatas[0]?.MstLocationId
                              ? bindBranchProject.filter(
                                  (x) =>
                                    x.value ==
                                    userProfileDatas[0]?.MstLocationId
                                )
                              : bindBranchProject
                          }
                          placeholder="Select"
                          name="branchProject"
                          selectedOption={
                            branchProject
                              ? bindBranchProject.find(
                                  (x) => x.value == branchProject
                                )
                              : ""
                          }
                          Class="custom-class"
                          tabIndex="1"
                          controlFunc={(value) => {
                            setSDP("");
                            setArea("");
                            setOutReachWorker("");
                            setBranchProject(value.value);
                            setBranchProjectError(
                              value ? "" : "Select Branch/Project"
                            );
                            //    handleBranchOPId();
                          }}
                          controlFuncBlur={() => {
                            setBranchProjectError(
                              branchProject ? "" : "Select Branch/Project"
                            );
                          }}
                        />
                        {branchProjectError && (
                          <span className="text-red-500">
                            {branchProjectError}
                          </span>
                        )}
                      </div>
                    )}
                    {(entity == "2" || entity == "3") && (
                      <div>
                        <AutoDropDown
                          title="S.D.P."
                          classStar="text-red-500"
                          options={bindSDP}
                          placeholder="Select"
                          name="sdp"
                          selectedOption={
                            sdp ? bindSDP.find((x) => x.value == sdp) : ""
                          }
                          Class="custom-class"
                          tabIndex="1"
                          controlFunc={(value) => {
                            setArea("");
                            setOutReachWorker("");
                            setSDP(value.value);
                            setSDPError(value ? "" : "Select S.P.D.");
                          }}
                          controlFuncBlur={() => {
                            setSDPError(sdp ? "" : "Select S.P.D.");
                          }}
                        />
                        {sdpError && (
                          <span className="text-red-500">{sdpError}</span>
                        )}
                      </div>
                    )}

                    {(entity == "2" || entity == "3") && (
                      <AutoDropDown
                        title="Area Name"
                        options={bindAreaDD}
                        name="area"
                        classStar="text-red-500"
                        placeholder="Select"
                        Class="custom-class"
                        selectedOption={
                          area ? bindAreaDD.find((x) => x.value == area) : ""
                        }
                        controlFunc={(value) => {
                          setArea(value.value);
                          setAreaError(value ? "" : "Select Area");
                        }}
                        controlFuncBlur={() => {
                          setAreaError(area ? "" : "Select Area");
                        }}
                        errorMessage={areaError}
                      />
                    )}

                    {entity == "3" && (
                      <AutoDropDown
                        title="Outreach staff"
                        options={bindOutReachWorker}
                        name="outReachWorker"
                        classStar="text-red-500"
                        placeholder="Select"
                        Class="custom-class"
                        selectedOption={
                          outReachWorker
                            ? bindOutReachWorker.find(
                                (x) => x.value == outReachWorker
                              )
                            : ""
                        }
                        controlFunc={(value) => {
                          setOutReachWorker(value.value);
                          setOutReachWorkerError(
                            value ? "" : "Select OutReach Worker"
                          );
                        }}
                        controlFuncBlur={() => {
                          setOutReachWorkerError(
                            outReachWorker ? "" : "Select OutReach Worker"
                          );
                        }}
                        errorMessage={outReachWorkerError}
                      />
                    )}

                    <div className=" col-span-3">
                      <div className="hidden md:block overflow-x-auto overflow-y-none max-h-max relative ">
                        <div className="overflow-y-none w-screen">
                          <table className="w-full border border-gray-300 mt-6">
                            <thead>
                              <tr>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                                  Category
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                                  Name
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                                  Brand
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Batch
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-12">
                                  Exp. Dt.
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                  Op. Qty
                                </th>

                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-52">
                                  U.O.M.
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-52">
                                  Value
                                </th>
                                <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-12">
                                  Date
                                </th>
                                {addScreenTitle && (
                                  <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                                    Action
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((row, index) => (
                                <tr key={index}>
                                  <td className="border border-gray-300 p-2">
                                    <AutoDropDown
                                      Class="Custom-class"
                                      options={commoditiesCategoryDD}
                                      placeholder="Select"
                                      name="Category"
                                      selectedOption={commoditiesCategoryDD.find(
                                        (x) =>
                                          x.value == row.openingStockCategory
                                      )}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "openingStockCategory"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <AutoDropDown
                                      Class="Custom-class"
                                      options={row.NameList ?? []}
                                      placeholder="Select"
                                      name="openingStockName"
                                      selectedOption={row.NameList?.find(
                                        (x) => x.value == row.openingStockName
                                      )}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "openingStockName"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <AutoDropDown
                                      options={row.BrandList ?? []}
                                      name="openingStockBrand"
                                      selectedOption={row.BrandList?.find(
                                        (x) => x.value == row.openingStockBrand
                                      )}
                                      placeholder="Select"
                                      Class="custom-class"
                                      //disabled="false"
                                      tabIndex="1"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e,
                                          index,
                                          "openingStockBrand"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <InputText
                                      inputType="text"
                                      name="batch"
                                      maxLength={40}
                                      content={row.batch}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "batch"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <InputText
                                      inputType="date"
                                      name="expDate"
                                      content={row.expDate}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "expDate"
                                        );
                                      }}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <div
                                      title={`Min Stock: ${
                                        row.currentStock || 0
                                      }`}
                                    >
                                      <InputText
                                        inputType="text"
                                        name="openingStockOpeningQty"
                                        content={row.openingStockOpeningQty}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "openingStockOpeningQty"
                                          );
                                        }}
                                        controlFuncBlur={(e) => {
                                          const inputValue = e.target.value;

                                          // Validate the value when the user leaves the field
                                          if (
                                            inputValue !== "" &&
                                            parseFloat(inputValue) <
                                              (row.currentStock || 0)
                                          ) {
                                            handleInputChange(
                                              { value: "" },
                                              index,
                                              "openingStockOpeningQty"
                                            ); // Clear the value if invalid
                                          }
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    {/* <AutoDropDown
                                      options={commoditiesUOMDD}
                                      name="uom"
                                      placeholder="Select"
                                      selectedOption={commoditiesUOMDD?.find(
                                        (x) => x.value == row.uom
                                      )}
                                      Class="custom-class"
                                      //disabled="false"
                                      tabIndex="1"
                                      controlFunc={(e) => {
                                        handleInputChange(e, index, "uom");
                                      }}
                                    /> */}
                                    <InputText
                                      type="text"
                                      content={row.uomText}
                                      disabled="disabled"
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "uomText"
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <InputText
                                      inputType="text"
                                      name="Charges"
                                      content={row.Charges}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "Charges"
                                        );
                                      }}
                                    />
                                  </td>

                                  <td className="border border-gray-300 p-2">
                                    <InputText
                                      inputType="date"
                                      content={
                                        row.openingStockDate.split("T")[0]
                                      }
                                      maxLength={5}
                                      controlFunc={(e) => {
                                        handleInputChange(
                                          e.target,
                                          index,
                                          "openingStockDate"
                                        );
                                      }}
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                    />
                                  </td>
                                  {addScreenTitle && (
                                    <td className="border border-gray-300">
                                      <div className="flex gap-4 align-baseline items-center justify-center">
                                        <svg
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          class="w-6 h-6 text-black cursor-pointer"
                                          onClick={() => handleDeleteRow(index)}
                                        >
                                          <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
                                        </svg>
                                        <svg
                                          viewBox="0 0 1024 1024"
                                          fill="currentColor"
                                          onClick={handleAddRow}
                                          class="w-6 h-6 text-black cursor-pointer"
                                        >
                                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z" />
                                        </svg>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="text-red-500">{tableError}</div>
                    </div>
                    <div className={mainTable.tableMobile} role="table">
                      <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                        {tableData.length > 0 &&
                          tableData.map((row, index) => (
                            <div scope="col" class={mainTable.mobileTable}>
                              {headerData.map((keyData) => (
                                <div
                                  scope="cell"
                                  class={mainTable.tableData}
                                  role="cell"
                                >
                                  {keyData.key === "Action" ? (
                                    <div class={mainTable.tableData}>
                                      <div className="md:block hidden">
                                        <button
                                          id="dropdownMenuIconButton"
                                          data-dropdown-toggle="dropdownDots"
                                          class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                          type="button"
                                        >
                                          <svg
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="md:hidden flex justify-center">
                                        <input
                                          type="button"
                                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                          onClick={handleAddRow}
                                          value="Add"
                                        />
                                        <input
                                          type="button"
                                          className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                          onClick={() => handleDeleteRow(index)}
                                          value="Delete"
                                        />
                                      </div>
                                    </div>
                                  ) : keyData.key === "Category" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        Class="Custom-class"
                                        options={commoditiesCategoryDD}
                                        placeholder="Select"
                                        name="Category"
                                        selectedOption={commoditiesCategoryDD.find(
                                          (x) =>
                                            x.value == row.openingStockCategory
                                        )}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "openingStockCategory"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "Name" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        Class="Custom-class"
                                        options={row.NameList ?? []}
                                        placeholder="Select"
                                        name="openingStockName"
                                        selectedOption={row.NameList?.find(
                                          (x) => x.value == row.openingStockName
                                        )}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "openingStockName"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "Brand" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <AutoDropDown
                                        options={row.BrandList ?? []}
                                        name="openingStockBrand"
                                        selectedOption={row.BrandList?.find(
                                          (x) =>
                                            x.value == row.openingStockBrand
                                        )}
                                        placeholder="Select"
                                        Class="custom-class"
                                        //disabled="false"
                                        tabIndex="1"
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e,
                                            index,
                                            "openingStockBrand"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "Batch" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <InputText
                                        inputType="text"
                                        name="batch"
                                        maxLength={40}
                                        content={row.batch}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "batch"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "ExpiryDate" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <InputText
                                        inputType="date"
                                        name="expDate"
                                        content={row.expDate}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "expDate"
                                          );
                                        }}
                                        min={
                                          new Date().toISOString().split("T")[0]
                                        }
                                      />
                                    </div>
                                  ) : keyData.key === "OpQty" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full font-bold">
                                        {keyData.title}
                                      </div>
                                      <div
                                        title={`Min Stock: ${
                                          row.currentStock || 0
                                        }`}
                                      >
                                        <InputText
                                          inputType="text"
                                          name="openingStockOpeningQty"
                                          content={row.openingStockOpeningQty}
                                          controlFunc={(e) => {
                                            handleInputChange(
                                              e.target,
                                              index,
                                              "openingStockOpeningQty"
                                            );
                                          }}
                                          controlFuncBlur={(e) => {
                                            const inputValue = e.target.value;

                                            // Validate the value when the user leaves the field
                                            if (
                                              inputValue !== "" &&
                                              parseFloat(inputValue) <
                                                (row.currentStock || 0)
                                            ) {
                                              handleInputChange(
                                                { value: "" },
                                                index,
                                                "openingStockOpeningQty"
                                              ); // Clear the value if invalid
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : keyData.key === "uom" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full whitespace-nowrap font-bold">
                                        {keyData.title}
                                      </div>
                                      <InputText
                                        type="text"
                                        content={row.uomText}
                                        disabled="disabled"
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "uomText"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "Charges" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full whitespace-nowrap font-bold">
                                        {keyData.title}
                                      </div>
                                      <InputText
                                        inputType="text"
                                        name="Charges"
                                        content={row.Charges}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "Charges"
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : keyData.key === "Date" ? (
                                    <div className=" border p-1">
                                      <div className="flex items-center w-full whitespace-nowrap font-bold">
                                        {keyData.title}
                                      </div>
                                      <InputText
                                        inputType="date"
                                        content={row.openingStockDate}
                                        maxLength={5}
                                        controlFunc={(e) => {
                                          handleInputChange(
                                            e.target,
                                            index,
                                            "openingStockDate"
                                          );
                                        }}
                                        min={
                                          new Date().toISOString().split("T")[0]
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex border p-1">
                                      <div className="flex basis-1/2 font-bold">
                                        {keyData.title}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="md:hidden">
                      {tableError && (
                        <div className="text-red-500">{tableError}</div>
                      )}
                    </div>
                  </div>
                  <Buttons
                    addScreenTitle={addScreenTitle}
                    updateScreenTitle={updateScreenTitle}
                    handleSubmit={handleSubmit}
                    disabledBtn={submitBtnDisabled ? true : false}
                    cancel={cancel}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && <ErrorPopup closeModal={() => setIsOpen(false)} />}
      <MainPopup
        popUpOpen={popUpOpen}
        setpopUpOpen={setpopUpOpen}
        Open={handleOpeningStockDetails}
      >
        <div>
          <PopUpScreenTitle
            title="Opening Stock"
            setpopUpOpen={setpopUpOpen}
            viewDetails={viewDetails}
            deleteDetails={deleteDetails}
            blockDetails={blockDetails}
            unBlockDetails={unBlockDetails}
          />
          <OpeningStockPopup data={currentData} data1={currentData1} />
        </div>
        <PopUpButtons
          deleteDetails={deleteDetails}
          blockDetails={blockDetails}
          unBlockDetails={unBlockDetails}
          rowIndex={rowIndex}
          handleSubmit={handleSubmit}
          disabledBtn={submitBtnDisabled ? true : false}
          cancel={cancel}
        />
      </MainPopup>
    </MainLayout>
  );
};

export default OpeningStock;
