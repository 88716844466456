

import React from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const RadioGroup = (props) => {
  const selectedOption = props.selectedOptions || '';
  const handleOptionChange = (value) => {
    props.controlFunc(value);
  };

  return (
    <div className={props.classDiv || "form-group"}>
      <label className={props.classLabel ? props.classLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>
      {/* <label className={props.ClassLabel || mainTable.label}>
        {props.title}
        <span className={props.classStar || "hidden"}>*</span>
      </label> */}
      <div className={props.Class1 || mainTable.inputRadioDiv}>
        {props.options.map((option) => (
          <label
            key={option.value}
            className={props.ClassLabel || mainTable.inputRadioLabel}
          >
            <input
              className={props.Class || mainTable.inputRadio}
              name={props.setName}
              onChange={() => handleOptionChange(option.value)}
              value={option.value}
              checked={selectedOption === option.value}
              type="radio"
              disabled={props.disabled}
              tabIndex={props.tabIndex}
              onBlur={props.controlFuncBlur}
            />
            {option.label}
            
          </label>
        ))}
      </div>
      {props.errorMessage && (
              <span class="text-red-500">{props.errorMessage}</span>
            )}
    </div>
  );
};

RadioGroup.propTypes = {
  title: PropTypes.string.isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  tabIndex: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default RadioGroup;
