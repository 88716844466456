// Code review - In Progress
// Documentation - In Progress
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import React, { useEffect, useState } from "react";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import DropDown from "../ReusableComponents/DropDown";
import { BindAPI } from "../services/APIConnection";

const OpeningStockPopup = ({ data, data1 }) => {
  
  const [bindEntityDD, setBindEntityDD] = useState([]);
  useEffect(() => {
    const dropDownDatas = async () => {
      try {
        const entityResponse = await BindAPI(
          "FPAIOutReachAPI/BindOrgStructure"
        );

        const entityData = entityResponse.data.Table1.map((item) => ({
          value: item.MstEntityId,
          label: item.EntityName,
        }));

        setBindEntityDD(entityData);
      } catch (error) {}
    };

    dropDownDatas();
  }, []);
  const headerData = [
    {
      title: "Category",
      key: "Category",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Name",
      key: "Name",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Brand",
      key: "Brand",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Batch",
      key: "Batch",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Exp. Dt",
      key: "ExpiryDate",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Op. Qty",
      key: "OpQty",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "U.O.M..",
      key: "uom",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Charges",
      key: "Charges",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
    {
      title: "Date",
      key: "Date",
      width: "md:w-80 2xl:w-96 truncate  px-2 py-3 m-auto",
    },
  ];

  const [tableData, setTableData] = useState([
    {
      receiptsCategoryType: "",
      receiptsCategory: "",
      receiptsName: "",
      receiptsBrand: "",
      receivedQuantity: "",
      unitOfMeasure: "",
      batchNo: "",
      dateOfExpiry: new Date(),
      rate: "",
      receiptsCategoryList: [],
      receiptsNameList: [],
      receiptsBrandList: [],
    },
  ]);

  return (
    <div class="w-full">
      <div className={mainTable.mainContentScreen}>
        <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
          <div className="sm:grid gap-4 mb-6 md:grid-cols-3">
            {/* <form className={mainTable.grid3}> */}
            <AutoDropDown
              Class="Custom-class"
              title="Commodities"
              options={data[1] ?? []}
              classStar="text-red-500"
              placeholder="Select"
              name="commodities"
              //selectedOption={commoditiesDD.find((x) => x.value == commodities)}
              selectedOption={data[1]?.find((x) => x.value == data[0])}
            />

            <div>
              <DropDown
                title="Entity"
                classStar="text-red-500"
                options={bindEntityDD}
                placeholder="Select"
                name="entity"
                selectedOption={data[10]}
              />
            </div>

            {data[6] && (
              <div>
                <AutoDropDown
                  title="Branch/Project"
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="branchProject"
                  selectedOption={data[6] ?? []}
                  Class="custom-class"
                  tabIndex="1"
                />
              </div>
            )}
            {(data[10] == "2" || data[10] == "3") && (
              <div>
                <AutoDropDown
                  title="S.D.P."
                  classStar="text-red-500"
                  options={[]}
                  placeholder="Select"
                  name="sdp"
                  selectedOption={data[7] ?? []}
                  Class="custom-class"
                  tabIndex="1"
                />
              </div>
            )}

            {(data[10] == "2" || data[10] == "3") && (
              <AutoDropDown
                title="Area Name"
                options={[]}
                name="area"
                classStar="text-red-500"
                placeholder="Select"
                Class="custom-class"
                selectedOption={data[8] ?? []}
              />
            )}

            {data[10] == "3" && (
              <AutoDropDown
                title="Outreach staff"
                options={[]}
                name="outReachWorker"
                classStar="text-red-500"
                placeholder="Select"
                Class="custom-class"
                selectedOption={data[9] ?? []}
              />
            )}

            <div className=" col-span-3">
              <div className="hidden md:block overflow-x-auto overflow-y-none max-h-max relative ">
                <div className="overflow-y-none w-screen">
                  <table className="w-full border border-gray-300 mt-6">
                    <thead>
                      <tr>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                          Category
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                          Name
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-96">
                          Brand
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Batch
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-12">
                          Exp. Dt.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-32">
                          Op. Qty
                        </th>

                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-52">
                          U.O.M.
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-52">
                          Charges
                        </th>
                        <th className="border text-white bg-[#2d2d6e] border-gray-300 p-2 w-12">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data1.map((row, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              Class="Custom-class"
                              options={data[2] ?? []}
                              placeholder="Select"
                              name="Category"
                              selectedOption={data[2]?.find(
                                (x) => x.value == row.openingStockCategory
                              )}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              Class="Custom-class"
                              options={data[3] ?? []}
                              placeholder="Select"
                              name="openingStockName"
                              selectedOption={data[3]?.find(
                                (x) => x.value == row.openingStockName
                              )}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            <AutoDropDown
                              options={data[4] ?? []}
                              name="openingStockBrand"
                              selectedOption={data[4]?.find(
                                (x) => x.value == row.openingStockBrand
                              )}
                              placeholder="Select"
                              Class="custom-class"
                              //disabled="false"
                              tabIndex="1"
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                                    <InputText
                                      inputType="text"
                                      name="batch"                                      
                                      content={row?.batch}                                     
                                    />
                                  </td>
                                  <td className="border border-gray-300 p-2">
                                    <InputText
                                      inputType="date"
                                      name="expDate"
                                      content={row?.expDate ?? ""}                                     
                                    />
                                  </td>
                          <td className="border border-gray-300 p-2">
                            <InputText
                              inputType="text"
                              name="openingStockOpeningQty"
                              content={row?.openingStockOpeningQty ?? ""}
                            />
                          </td>
                          <td className="border border-gray-300 p-2">
                            {/* <AutoDropDown
                              options={data[5]??[]}
                              name="uom"
                              placeholder="Select"
                              selectedOption={data[5]?.find((x)=>x.value == row.uom)}
                              Class="custom-class"
                              //disabled="false"
                              tabIndex="1"
                            /> */}
                            <InputText
                              type="text"
                              content={row.uomText}
                              disabled="disabled"
                              // controlFunc={(e) => {
                              //   handleInputChange(e.target, index, "uomText");
                              // }}
                            />
                          </td>
                          <td>
                            <InputText
                              inputType="text"
                              name="Charges"
                              content={row?.Charges ?? ""}
                            />
                          </td>

                          <td className="border border-gray-300 p-2">
                            <InputText
                              inputType="date"
                              name="openingStockDate"
                              content={row?.openingStockDate ?? ""}
                              maxLength={5}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className={mainTable.tableMobile} role="table">
              <div className="w-full md:overflow-y-auto lg:h-96 h-full">
                {tableData.length > 0 &&
                  tableData.map((row, index) => (
                    <div scope="col" class={mainTable.mobileTable}>
                      {headerData.map((keyData) => (
                        <div
                          scope="cell"
                          class={mainTable.tableData}
                          role="cell"
                        >
                          {keyData.key === "Action" ? (
                            <div class={mainTable.tableData}>
                              <div className="md:block hidden">
                                <button
                                  id="dropdownMenuIconButton"
                                  data-dropdown-toggle="dropdownDots"
                                  class="inline-flex items-center text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none  focus:ring-gray-50 "
                                  type="button"
                                >
                                  <svg
                                    class="w-6 h-6"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
                                  </svg>
                                </button>
                              </div>
                              {/* <div className="md:hidden flex justify-center">
                                <input
                                  type="button"
                                  className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                  onClick={handleAddRow}
                                  value="Add"
                                />
                                <input
                                  type="button"
                                  className="bg-[#2d2d6e] text-white mr-1 py-1 px-2 rounded-lg"
                                  onClick={() => handleDeleteRow(index)}
                                  value="Delete"
                                />
                              </div> */}
                            </div>
                          ) : keyData.key === "Category" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full font-bold">
                                {keyData.title}
                              </div>
                              <AutoDropDown
                                Class="Custom-class"
                                options={data[2] ?? []}
                                placeholder="Select"
                                name="Category"
                                selectedOption={data[2]?.find(
                                  (x) => x.value == row.openingStockCategory
                                )}
                              />
                            </div>
                          ) : keyData.key === "Name" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full font-bold">
                                {keyData.title}
                              </div>
                              <AutoDropDown
                                Class="Custom-class"
                                options={data[3] ?? []}
                                placeholder="Select"
                                name="openingStockName"
                                selectedOption={data[3]?.find(
                                  (x) => x.value == row.openingStockName
                                )}
                              />
                            </div>
                          ) : keyData.key === "Brand" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full font-bold">
                                {keyData.title}
                              </div>
                              <AutoDropDown
                                options={data[4] ?? []}
                                name="openingStockBrand"
                                selectedOption={data[4]?.find(
                                  (x) => x.value == row.openingStockBrand
                                )}
                                placeholder="Select"
                                Class="custom-class"
                                //disabled="false"
                                tabIndex="1"
                              />
                            </div>
                          ) : keyData.key === "OpQty" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full font-bold">
                                {keyData.title}
                              </div>
                              <InputText
                                inputType="text"
                                name="openingStockOpeningQty"
                                content={row?.openingStockOpeningQty ?? ""}
                              />
                            </div>
                          ) : keyData.key === "uom" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full whitespace-nowrap font-bold">
                                {keyData.title}
                              </div>
                              <InputText
                                type="text"
                                content={row.uomText}
                                disabled="disabled"
                                // controlFunc={(e) => {
                                //   handleInputChange(e.target, index, "uomText");
                                // }}
                              />
                            </div>
                          ) : keyData.key === "Charges" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full whitespace-nowrap font-bold">
                                {keyData.title}
                              </div>
                              <InputText
                                inputType="text"
                                name="Charges"
                                content={row?.Charges ?? ""}
                              />
                            </div>
                          ) : keyData.key === "Date" ? (
                            <div className=" border p-1">
                              <div className="flex items-center w-full whitespace-nowrap font-bold">
                                {keyData.title}
                              </div>
                              <InputText
                                inputType="date"
                                name="openingStockDate"
                                content={row?.openingStockDate ?? ""}
                                maxLength={5}
                                min={new Date().toISOString().split("T")[0]}
                              />
                            </div>
                          ) : (
                            <div className="flex border p-1">
                              <div className="flex basis-1/2 font-bold">
                                {keyData.title}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OpeningStockPopup;
