import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { SingleSessionReportHeader } from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import { useUserCapability } from "../components/ConstantReducerProvider";
import MultiSelect from "../ReusableComponents/MultiSelect";

const CSESingleSessionReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindReportSingleSessionData, setBindReportSingleSessionData] =
    useState([]);
  const [showTable, setShowTable] = useState(false);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      setBranchName(userProfileDatas[0]?.LocationName_en);
      setBranchProject([userProfileDatas[0]?.MstLocationId]);
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);
  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);
    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");
    if (!branchProjectValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];

      const response = await BindPostAPI(
        ReportData,
        "CSEInSchoolAPI/ReportCSESingleSession"
      );

      console.log("response", response.data.Table);
      setBindReportSingleSessionData(response.data.Table);
      setShowTable(true);
    } else {
      setBranchProjectError("Select Branch/Project");
    }
  };
  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
    };

    exportData(
      SingleSessionReportHeader.map((column) => column.title),
      bindReportSingleSessionData,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };
  //new 2 export data

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    // Title row configuration
    const titleRow = [
      {
        v: "CSE Single Session Report",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    // Add "Row Total" to headers
    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    // Initialize column totals
    const columnTotals = {
      SEOMaleChild: 0,
      SEOFemaleChild: 0,
      SEOOtherChild: 0,
      // SEONoOfParticipants: 0
    };

    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.SEOMaleChild || 0) +
        Number(rowData.SEOFemaleChild || 0) +
        Number(rowData.SEOOtherChild || 0);
      // +
      // Number(rowData.SEONoOfParticipants || 0)

      // Add to column totals
      columnTotals.SEOMaleChild += Number(rowData.SEOMaleChild || 0);
      columnTotals.SEOFemaleChild += Number(rowData.SEOFemaleChild || 0);
      columnTotals.SEOOtherChild += Number(rowData.SEOOtherChild || 0);
      // columnTotals.SEONoOfParticipants += Number(rowData.SEONoOfParticipants || 0);

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.EventNo,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SEODate,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SEOResourcesPerson,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SessionType,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SEOVenue,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.TimeDifference,
          s: {
            ...baseStyle,
            alignment: { horizontal: "right" },
            numFmt: "0.00",
          },
        },
        {
          v: Number(rowData.SEOMaleChild || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.SEOFemaleChild || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.SEOOtherChild || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.SEONoOfParticipants || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: rowData.SEORemarks,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.SEOMaleChild, t: "n", s: totalsRowStyle },
      { v: columnTotals.SEOFemaleChild, t: "n", s: totalsRowStyle },
      { v: columnTotals.SEOOtherChild, t: "n", s: totalsRowStyle },
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: totalsRowStyle },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows
    // const wsData = [titleRow, headerRow, ...dataRows, totalsRow];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Set custom column widths
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 18 }, // Branch
      { width: 30 }, // Event No
      { width: 12 }, // Date
      { width: 20 }, // Resources Person
      { width: 15 }, // Session Type
      { width: 20 }, // Venue
      { width: 15 }, // Time Difference
      { width: 12 }, // Male Child
      { width: 12 }, // Female Child
      { width: 12 }, // Other Child
      { width: 15 }, // No of Participants
      { width: 30 }, // Remarks
      { width: 15 }, // Row Total
    ];

    // // Border style for all cells
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // // Apply styles
    // // Title row
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 2,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create and save workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "CSE_SingleSession_Report.xlsx");
  };

  //new 1 export data
  // const exportData = (columns, rows) => {
  //   // Title row configuration
  //   const titleRow = [
  //     {
  //       v: "CSE Single Session Report",
  //       s: {
  //         font: { bold: true, sz: 16 },
  //         alignment: { horizontal: "left" },
  //       },
  //     },
  //   ];

  //   // Header row with styling
  //   const headerRow = columns.map((column) => ({
  //     v: column,
  //     s: {
  //       fill: { fgColor: { rgb: "2D2D6E" } },
  //       font: { bold: true, color: { rgb: "FFFFFF" } },
  //       alignment: { horizontal: "left" },
  //     },
  //   }));

  //   // Data rows with alternating colors
  //   const dataRows = rows.map((rowData, index) => {
  //     const isAlternateRow = index % 2 === 1;
  //     const baseStyle = isAlternateRow
  //       ? { fill: { fgColor: { rgb: "F0F0F0" } } }
  //       : {};

  //     return [
  //       {
  //         v: index + 1,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.EventNo,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.SEODate,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.SEOResourcesPerson,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.SessionType,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.SEOVenue,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //       {
  //         v: rowData.TimeDifference,
  //         s: {
  //           ...baseStyle,
  //           alignment: { horizontal: "right" },
  //           numFmt: "0.00",
  //         },
  //       },
  //       {
  //         v: Number(rowData.SEOMaleChild || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.SEOFemaleChild || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.SEOOtherChild || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: Number(rowData.SEONoOfParticipants || 0),
  //         t: "n",
  //         s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
  //       },
  //       {
  //         v: rowData.SEORemarks,
  //         s: { ...baseStyle, alignment: { horizontal: "left" } },
  //       },
  //     ];
  //   });

  //   // Combine all rows
  //   const wsData = [titleRow, headerRow, ...dataRows];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);

  //   // Set custom column widths
  //   ws["!cols"] = [
  //     { width: 8 }, // Index
  //     { width: 12 }, // Event No
  //     { width: 12 }, // Date
  //     { width: 20 }, // Resources Person
  //     { width: 15 }, // Session Type
  //     { width: 20 }, // Venue
  //     { width: 15 }, // Time Difference
  //     { width: 12 }, // Male Child
  //     { width: 12 }, // Female Child
  //     { width: 12 }, // Other Child
  //     { width: 15 }, // No of Participants
  //     { width: 30 }, // Remarks
  //   ];

  //   // Border style for all cells
  //   const borderStyle = {
  //     border: {
  //       top: { style: "thin", color: { rgb: "00000000" } },
  //       bottom: { style: "thin", color: { rgb: "00000000" } },
  //       left: { style: "thin", color: { rgb: "00000000" } },
  //       right: { style: "thin", color: { rgb: "00000000" } },
  //     },
  //   };

  //   // Apply styles
  //   // Title row
  //   const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
  //   ws[titleCell].s = titleRow[0].s;

  //   // Header row
  //   headerRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
  //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
  //   });

  //   // Data rows
  //   dataRows.forEach((row, rowIndex) => {
  //     row.forEach((cell, colIndex) => {
  //       const cellAddress = XLSX.utils.encode_cell({
  //         r: rowIndex + 2,
  //         c: colIndex,
  //       });
  //       ws[cellAddress] = {
  //         v: cell.v,
  //         t: cell.t,
  //         s: { ...cell.s, ...borderStyle },
  //       };
  //     });
  //   });

  //   // Create and save workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   XLSX.writeFile(wb, "CSE_SingleSession_Report.xlsx");
  // };

  //old export Data

  // const exportData = (columns, rows) => {

  //   const headerRow = columns.map((column) => ({
  //     v: column,
  //     s: {
  //       fill: {
  //         fgColor: { rgb: "2D2D6E" }, // Background color (dark blue)
  //       },
  //       font: {
  //         bold: true,
  //         color: { rgb: "FFFFFF" }, // Font color (white)
  //       },
  //     },
  //   }));

  //   const dataRows = rows.map((rowData, index) => {
  //     const isAlternateRow = index % 2 === 1;
  //     const rowStyle = isAlternateRow
  //       ? { fill: { fgColor: { rgb: "F0F0F0" } } }
  //       : {};

  //     return [
  //       { v: index + 1, s: rowStyle },
  //       { v: rowData.EventNo, s: rowStyle },
  //       { v: rowData.SEODate, s: rowStyle },
  //       { v: rowData.SEOResourcesPerson, s: rowStyle },
  //       { v: rowData.SessionType, s: rowStyle },
  //       { v: rowData.SEOVenue, s: rowStyle },
  //       { v: rowData.TimeDifference, s: rowStyle },
  //       { v: rowData.SEOMaleChild, s: rowStyle },
  //       { v: rowData.SEOFemaleChild, s: rowStyle },
  //       { v: rowData.SEOOtherChild, s: rowStyle },
  //       { v: rowData.SEONoOfParticipants, s: rowStyle },
  //       { v: rowData.SEORemarks, s: rowStyle },
  //     ];
  //   });

  //   const wsData = [headerRow, ...dataRows];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);
  //   const borderStyle = {
  //     border: {
  //       top: { style: "thin", color: { rgb: "00000000" } },
  //       bottom: { style: "thin", color: { rgb: "00000000" } },
  //       left: { style: "thin", color: { rgb: "00000000" } },
  //       right: { style: "thin", color: { rgb: "00000000" } },
  //     },
  //   };
  //   headerRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
  //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
  //   });

  //   dataRows.forEach((row, rowIndex) => {
  //     row.forEach((cell, colIndex) => {
  //       const cellAddress = XLSX.utils.encode_cell({
  //         r: rowIndex + 1,
  //         c: colIndex,
  //       });
  //       ws[cellAddress] = { v: cell.v, s: { ...cell.s, ...borderStyle } };
  //     });
  //   });

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   XLSX.writeFile(wb, "CSE_SingleSession_Report.xlsx");
  // };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    }); // Use A3 format
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure SingleSessionReportHeader is an array of objects with a title property
    const headers = SingleSessionReportHeader.map((column) => column.title);

    // Map bindReportSingleSessionData to the appropriate format for autoTable
    const data = bindReportSingleSessionData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.EventNo,
        rowData.SEODate,
        rowData.SEOResourcesPerson,
        rowData.SessionType,
        rowData.SEOVenue,
        rowData.TimeDifference,
        rowData.SEOMaleChild,
        rowData.SEOFemaleChild,
        rowData.SEOOtherChild,
        rowData.SEONoOfParticipants,
        rowData.SEORemarks,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: 15 }, // Age
        7: { cellWidth: 15 }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Auto adjust width
        17: { cellWidth: "auto" }, // Auto adjust width
        18: { cellWidth: "auto" }, // Auto adjust width
        19: { cellWidth: "auto" }, // Auto adjust width
        20: { cellWidth: "auto" }, // Auto adjust width
        21: { cellWidth: "auto" }, // Auto adjust width
        22: { cellWidth: "auto" }, // Auto adjust width
        23: { cellWidth: "auto" }, // Auto adjust width
        24: { cellWidth: "auto" }, // Auto adjust width
        25: { cellWidth: "auto" }, // Auto adjust width
        26: { cellWidth: "auto" }, // Auto adjust width
        27: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("CSE_SingleSession_Report.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };
  useEffect(() => {
    BranchProjectFetchData();
  }, []);
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns 0-indexed month and padStart to ensure two digits
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  const brangeChange = (updatedOptions) => {
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Single Session Report`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="BranchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out  hover:bg-[#24292F]/90 focus:ring-4 cursor-pointer focus:outline-none focus:ring-blue-300   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindReportSingleSessionData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={SingleSessionReportHeader}
                records={bindReportSingleSessionData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default CSESingleSessionReport;
