import React, { useState } from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const DropDown = (props) => {
  const [error, setError] = useState("");

  const handleBlur = (e) => {
    const { value } = e.target;
    let errorMessage = "";
    
    if (props.isRequired && !value) {
      errorMessage = `${props.title} is required.`;
    } else if (props.controlFuncBlur) {
      errorMessage = props.controlFuncBlur(e) || "";
    }

    setError(errorMessage);
  };

  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.ClassLabel ? props.ClassLabel : mainTable.label}>
        {props.title}
        {props.classStar && <span className={props.classStar ? props.classStar : "hidden"}>*</span>}
      </label>

      <select
        name={props.name}
        value={props.selectedOption}
        onChange={props.controlFunc}
        onBlur={handleBlur}
        className={props.Class ? props.Class : mainTable.input}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
      >
        <option value="">{props.placeholder}</option>
        {props.options.map((opt) => {
          return (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          );
        })}
      </select>
      {error && <span className="text-red-500">{error}</span>}
    </div>
  );
};

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  classDiv: PropTypes.string,
  ClassLabel: PropTypes.string,
  classStar: PropTypes.string,
  Class: PropTypes.string,
};

DropDown.defaultProps = {
  disabled: false,
  isRequired: false,
};

export default DropDown;
