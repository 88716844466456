import React from "react";
import PropTypes from "prop-types";
import { mainTable } from "../css/Common";

const Checkbox = (props) => {
  const selectedOptionsArray = Array.isArray(props.selectedOptions)
    ? props.selectedOptions.filter(Boolean) // Filter out falsy values, including NaN
    : [];

  const handleOptionChange = (value) => {
    const updatedOptions = selectedOptionsArray.includes(value)
      ? selectedOptionsArray.filter((option) => option !== value)
      : [...selectedOptionsArray, value];

    props.controlFunc(updatedOptions);
  };

  return (
    <div className={props.classDiv ? props.classDiv : "form-group"}>
      <label className={props.ClassLabel ? props.ClassLabel : mainTable.label}>
        {props.title}
        <span className={props.classStar ? props.classStar : "hidden"}>*</span>
      </label>
      <div className={props.Class ? props.Class : mainTable.inputCheckbxDiv}>
        {props.options.map((option) => (
          <label
            key={option.value}
            className={
              props.ClassLabel ? props.ClassLabel : mainTable.inputRadioLabel
            }
          >
            <input
              className={props.Class1 ? props.Class1 : mainTable.inputRadio}
              name={props.setName}
              onChange={() => handleOptionChange(option.value)}
              value={option.value}
              checked={selectedOptionsArray.includes(option.value)}
              type="checkbox"
              disabled={props.disabled}
              tabIndex={props.tabIndex}
              errorMessage={props.errorMessage}
              onBlur={props.controlFuncBlur}
            />
            {option.label}
          </label>
        ))}
      </div>
      {props.errorMessage && (
        <span class="text-red-500 text-sm font-medium">{props.errorMessage}</span>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string.isRequired,
  setName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  controlFunc: PropTypes.func.isRequired,
  controlFuncBlur: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  tabIndex: PropTypes.string.isRequired,
};

export default Checkbox;
