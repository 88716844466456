// Code review - In Progress
// Documentation - In Progress
import { mainTable } from "../css/Common";
import TextArea from "../ReusableComponents/TextArea";
import DropDown from "../ReusableComponents/DropDown";
import InputText from "../ReusableComponents/InputText";
import React, { useEffect, useState } from "react";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import RadioGroup from "../ReusableComponents/RadioButton";
import Checkbox from "../ReusableComponents/Checkbox";
import { clientChildrenDetailsHeaders } from "../components/HeaderAttribute";
import TableGrid from "../components/Table";

const ClientDetails = ({ data, data1, data2, data3 }) => {
  const [visibleNoOfLibvingChildrenTable, setVisibleNoOfLibvingChildrenTable] =
    useState(false);
  const [currentlyUsingAnyFPMethodDDShow, setCurrentlyUsingAnyFPMethodDDShow] =
    useState(false);
  const [genderOptions, setGenderOptions] = useState(false);
  const [bindGenderDD, setGenderDD] = useState([
    { value: 1, label: "M" },
    { value: 2, label: "F" },
    { value: 3, label: "O" },
  ]);
  const [paraDD, setParaDD] = useState([
    { value: 1, label: "0" },
    { value: 2, label: "1" },
    { value: 3, label: "2" },
    { value: 4, label: "3" },
    { value: 5, label: "4" },
    { value: 6, label: "5" },
    { value: 7, label: "6" },
    { value: 8, label: "7" },
    { value: 9, label: "8" },
    { value: 10, label: "9" },
    { value: 11, label: "10" },
  ]);

  useEffect(() => {
    if (data.ClientGender == 2 || data.ClientGender == 3) {
      setGenderOptions(true);
    } else {
      setGenderOptions(false);
    }

    if (data.ClientCurrentlyPregnant == 2 || data.ClientCurrentlyPregnant == 3) {
      setCurrentlyUsingAnyFPMethodDDShow(true);
    } else {
      setCurrentlyUsingAnyFPMethodDDShow(false);
    }
  }, [data ? data : null]);

  return (
    <div class="w-full">
      <div className={mainTable.mainContentScreen}>
        <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit ">
          <div class={mainTable.labelBold}>Client Details</div>
          <hr class={mainTable.horizontalLine}></hr>
          <div className={mainTable.grid3}>
            <div>
              <InputText
                inputType="text"
                title="First Name"
                name="firstName"
                placeholder={"xxxxx"}
                disabled={true}
                classStar="text-red-500"
                content={data.ClientFirstName_en ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="text"
                title="Middle Name"
                placeholder={"xxxxx"}
                disabled={true}
                name="middleName"
                content={data.ClientMiddleName_en ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="text"
                title="Last Name"
                disabled={true}
                name="lastName"
                // classStar="text-red-500"
                placeholder={"xxxxx"}
                maxLength={50}
                content={data.ClientLastName_en ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="text"
                title="Mother's Name"
                disabled={true}
                name="motherName"
                classStar="text-red-500"
                placeholder={"xxxxx"}
                maxLength={100}
                content={data.ClientMotherName_en ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="date"
                title="Date of Birth"
                disabled={true}
                classStar="text-red-500"
                name="dob"
                //content={dob}
                content={data.ClientDateOfBirth?.split("T")[0] ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="text"
                title="Age"
                classStar="text-red-500"
                disabled={true}
                name="age"
                placeholder={"xx"}
                //content={age}
                content={data.ClientAge ?? ""}
              />
            </div>
            <div>
              <RadioGroup
                title="Gender"
                classStar="text-red-500"
                type="radio"
                setName="gender"
                //selectedOptions={gender}
                selectedOptions={data.ClientGender ?? ""}
                options={data1[0] ?? []}
                disabled={true}
              />
            </div>
            <div>
              <DropDown
                title="Religion"
                classStar="text-red-500"
                options={data2[0] ?? []}
                placeholder="Select"
                disabled={true}
                name="religion"
                selectedOption={data.ClientReligion ?? ""}
              />
            </div>

            <div>
              <DropDown
                title="Marital Status"
                classStar="text-red-500"
                options={data2[1] ?? []}
                disabled={true}
                placeholder="Select"
                name="maritalStatus"
                selectedOption={data.ClientMaritalStatus}
              />
            </div>
            <div className="lg:col-span-3 md:col-span-3 col-span-1">
              <RadioGroup
                title="Education"
                classStar="text-red-500"
                type="radio"
                setName="education"
                //selectedOptions={education}
                selectedOptions={data.ClientEducation ?? ""}
                options={data1[1] ?? []}
                //controlFunc={handleEducationChange}

                disabled={true}
                Class1="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-4 xl:grid xl:grid-cols-6 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
              />
            </div>
            <div>
              <DropDown
                title="Occupation"
                classStar="text-red-500"
                options={data2[2] ?? []}
                placeholder="Select"
                disabled={true}
                name="Occupation"
                selectedOption={data.ClientOccupation ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType="date"
                title="Register Date"
                classStar="text-red-500"
                disabled={true}
                name="registerDate"
                content={data.ClientRegisterDate?.split("T")[0] ?? ""}
              />
            </div>
            <div className="lg:col-span-2 md:col-span-2 col-span-1 ">
              <Checkbox
                title="Have you attended"
                //classStar="text-red-500"
                type={"Checkbox"}
                setName={"HaveYouAttended"}
                //selectedOptions={awarness}
                selectedOptions={data3[0] ?? ""}
                options={data1[2] ?? []}
                tabIndex="0"
                disabled={true}
                Class="grid grid-cols-1 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
              />
            </div>
            <div>
              <AutoDropDown
                title="Referral Source"
                classStar="text-red-500"
                options={[]}
                disabled={true}
                placeholder="Select"
                name="clientReferralSource"
                selectedOption={data2[3] ?? ""}
                Class="custom-class"
                tabIndex="1"
              />
            </div>
            <InputText
              inputType="text"
              title="Existing Client Id."
              disabled={true}
              name="clientExistingClientId"
              content={data.ClientExistingClientId ?? ""}
            />
          </div>

          <div class={mainTable.labelBold}>Address Details</div>
          <hr class={mainTable.horizontalLine}></hr>
          <div className={mainTable.grid3}>
            <div>
              <InputText
                inputType={"text"}
                title={"Phone Number"}
                disabled={true}
                // classStar="text-red-500"
                name={"phone"}
                placeholder={"xxxxx"}
                content={data.ClientPhoneNo ?? ""}
                maxLength={15}
              />
            </div>

            <DropDown
              title="Mobile Belongs To"
              options={data2[4] ?? []}
              disabled={true}
              placeholder="Select"
              name="Mobile Belongs To"
              selectedOption={data.ClientMobilebelongs ?? ""}
            />

            <div>
              <DropDown
                title="Residence"
                disabled={true}
                options={data2[5] ?? []}
                classStar="text-red-500"
                placeholder="Select"
                name="residence"
                selectedOption={data.ClientResidence ?? ""}
              />
            </div>
            <DropDown
              classDiv="hidden"
              title="Country"
              options={[]}
              disabled={true}
              placeholder="Select"
              name="country"
              selectedOption={data.MstCountryId ?? ""}
            />
            <div>
              <AutoDropDown
                title="State"
                options={[]}
                classStar="text-red-500"
                disabled={true}
                Class="p-0"
                placeholder="Select"
                name="mstStateId"
                selectedOption={data2[5] ?? ""}
              />
            </div>
            <div>
              <AutoDropDown
                title="Branch/Project"
                disabled={true}
                options={[]}
                classStar="text-red-500"
                placeholder="Select"
                Class="custom-class"
                tabIndex="1"
                name="mstBranchProjectId"
                selectedOption={data2[6] ?? ""}
              />
            </div>
            <div>
              <AutoDropDown
                classStar="text-red-500"
                title="S.D.P."
                options={[]}
                disabled={true}
                placeholder="Select"
                Class="custom-class"
                tabIndex="1"
                name="mstSDPId"
                selectedOption={data2[7] ?? ""}
              />
            </div>
            <div>
              <AutoDropDown
                title="Area"
                options={[]}
                classStar="text-red-500"
                disabled={true}
                placeholder="Select"
                Class="custom-class"
                tabIndex="1"
                name="mstAreaId"
                selectedOption={data2[8] ?? ""}
              />
            </div>
            <div>
              <InputText
                inputType={"text"}
                title={"Address"}
                name={"address"}
                disabled={true}
                classStar="text-red-500"
                content={data.ClientAddress_en ?? ""}
                maxLength={500}
                placeholder="7/33,first street"
              />
            </div>

            <div>
              <InputText
                inputType={"text"}
                title={"Zip/Pincode"}
                classStar="text-red-500"
                disabled={true}
                name={"zipcode"}
                content={data.ClientPinCode ?? ""}
                maxLength={10}
                placeholder="xxxxxx"
              />
            </div>
          </div>
          {/* <div class={mainTable.labelBold}>Medical Details</div>
                <hr class={mainTable.horizontalLine}></hr> */}
          <div className={mainTable.grid3}>
            <div className="col-span-3">
              <Checkbox
                title="Disability"
                type="Checkbox"
                setName="disability"
                selectedOptions={data3[1] ?? ""}
                options={data1[3] ?? []}
                disabled={true}
                tabIndex="0"
              />
            </div>
            <div className="col-span-3">
              <Checkbox
                title="Marginalized And Excluded Clients"
                type="Checkbox"
                setName="marginalize"
                selectedOptions={data3[2] ?? ""}
                options={data1[4] ?? []}
                disabled={true}
                tabIndex="0"
                Class1="w-3 h-3 mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                Class="grid grid-cols-2 md:grid md:grid-cols-6 lg:grid lg:grid-cols-6 items-center bg-gray-50 pl-4 border border-gray-300 rounded-lg "
              />
            </div>
          </div>
          <div class={mainTable.grid3}>
            <div>
              <RadioGroup
                title="BPL cardholder"
                // classStar="text-red-500"
                type="radio"
                setName="clientBPLCardholder"
                selectedOptions={data.ClientBPLCardholder ?? ""}
                options={data1[5] ?? []}
                tabIndex="0"
                disabled={true}
              />
            </div>
            <div>
              <DropDown
                title="Blood Group"
                // classStar="text-red-500"
                options={data2[10] ?? []}
                placeholder="Select"
                name="bloodGroup"
                disabled={true}
                selectedOption={data.ClientBloodGroup ?? ""}
              />
            </div>
            <div>
              <RadioGroup
                title="Using Any Form Of Tobacco Product"
                // classStar="text-red-500"
                type="radio"
                setName="clientUsingAnyFormOfTobaccoProduct"
                selectedOptions={data.ClientUsingAnyFormOfTobaccoProduct ?? ""}
                options={data1[5] ?? []}
                tabIndex="0"
                disabled={true}
              />
            </div>
            <div>
              <RadioGroup
                title="Alcohol Use"
                // classStar="text-red-500"
                type="radio"
                setName="clientAlcoholUse"
                selectedOptions={data.ClientAlcoholUse ?? ""}
                options={data1[5] ?? []}
                tabIndex="0"
                disabled={true}
              />
            </div>
            <div>
              <label for="client_name" class={mainTable.label}>
                Physique
                {/* <span className="text-red-500">*</span> */}
              </label>

              <div className={mainTable.inputDiv2}>
                <div>
                  <InputText
                    inputType="text"
                    ClassDiv="grid gap-2 grid-cols-2"
                    classLabel={
                      "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                    }
                    Class={mainTable.smallInput}
                    disabled={true}
                    title="Height (in cm)"
                    name="height"
                    placeholder="175"
                    content={
                      data?.ClientHeight == 0 ? "" : data?.ClientHeight ?? ""
                    }
                  />
                </div>
                <div>
                  <InputText
                    inputType="text"
                    disabled={true}
                    ClassDiv="grid gap-2 grid-cols-2"
                    classLabel={
                      "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                    }
                    Class={mainTable.smallInput}
                    title="Weight (in kg)"
                    name="weight"
                    placeholder="160"
                    content={
                      data?.ClientWeight == 0 ? "" : data?.ClientWeight ?? ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div></div>
          {genderOptions && (
            <div className="block mb-2 text-base capitalize font-medium text-neutral-700 ">
              Menstrual History
            </div>
          )}
          {genderOptions && (
            <div class={mainTable.grid4}>
              <div>
                <InputText
                  inputType="text"
                  //classStar="text-red-500"
                  title="Cycle (in days)"
                  disabled={true}
                  classLabel={
                    "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                  }
                  name="Cycle"
                  placeholder="00"
                  maxLength={50}
                  content={
                    data?.ClientCycleInDays == 0
                      ? ""
                      : data?.ClientCycleInDays ?? ""
                  }
                />
              </div>
              <div>
                <InputText
                  inputType="text"
                  //classStar="text-red-500"
                  title="Duration (in days)"
                  disabled={true}
                  classLabel={
                    "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                  }
                  name="Duration"
                  maxLength={3}
                  placeholder={"00"}
                  content={
                    data?.ClientDurationInDays == 0
                      ? ""
                      : data?.ClientDurationInDays ?? ""
                  }
                />
              </div>
              <div>
                <RadioGroup
                  //classStar="text-red-500"
                  title="Regularity"
                  disabled={true}
                  type="radio"
                  setName="clientRegularity"
                  selectedOptions={data.ClientRegularity ?? ""}
                  options={data1[6] ?? []}
                  //controlFunc={handleRegularIrregular}

                  tabIndex="0"
                />
              </div>
              <div>
                <InputText
                  inputType="date"
                  title="Last menstrual period (Date)"
                  disabled={true}
                  //classStar="text-red-500"
                  classLabel={
                    "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                  }
                  name="clientLastMenstrualPeriodDate"
                  content={
                    data.ClientLastMenstrualPeriodDate?.split("T")[0] ?? ""
                  }
                />
              </div>
            </div>
          )}
          {genderOptions && (
            <div className="block mb-2 text-base capitalize font-medium text-neutral-700 ">
              Obstetric History
            </div>
          )}
          {genderOptions && (
            <div class={mainTable.grid3}>
              <DropDown
                title={"Gravida"}
                //classStar="text-red-500"
                options={paraDD ?? []}
                disabled={true}
                placeholder={"Select"}
                name={"Gravida"}
                selectedOption={data.ClientGravida ?? ""}
              />
              <DropDown
                title={"Para"}
                //classStar="text-red-500"
                options={paraDD ?? []}
                disabled={true}
                placeholder={"Select"}
                name={"Para"}
                selectedOption={data.ClientPara ?? ""}
              />
              <InputText
                inputType="text"
                //classStar="text-red-500"
                title="No. of neonatal deaths (28days)"
                name="Noofneonataldeaths"
                disabled={true}
                classLabel={
                  "block mb-2 text-sm  font-medium text-neutral-700 dark:text-white"
                }
                content={
                  data?.ClientNoOfNeonatalDeaths == 0
                    ? ""
                    : data?.ClientNoOfNeonatalDeaths ?? ""
                }
              />

              <div>
                <RadioGroup
                  title="Currently pregnant"
                  //classStar="text-red-500"disabled={true}
                  type="radio"
                  setName="clientCurrentlyPregnant"
                  selectedOptions={data.ClientCurrentlyPregnant ?? ""}
                  options={data1[5] ?? []}
                  //controlFunc={handleCurrentlyPregnant}

                  tabIndex="0"
                  disabled={true}
                />
              </div>

              <div>
                <InputText
                  inputType="text"
                  //classStar="text-red-500"
                  title="Abortions"
                  name="clientAbortions"
                  disabled={true}
                  maxLength={50}
                  placeholder={"0"}
                  content={
                    data?.ClientAbortions == 0
                      ? ""
                      : data?.ClientAbortions ?? ""
                  }
                />
              </div>
              <div>
                <InputText
                  inputType="text"
                  //classStar="text-red-500"
                  title="Still Birth"
                  disabled={true}
                  name="Stillbirth"
                  maxLength={50}
                  placeholder={"0"}
                  content={
                    data?.ClientStillBirth == 0
                      ? ""
                      : data?.ClientStillBirth ?? ""
                  }
                />
              </div>
              {currentlyUsingAnyFPMethodDDShow && (
                <div>
                  <DropDown
                    title={"Currently Using any FP method"}
                    classStar="text-red-500"
                    options={data2[11] ?? []}
                    placeholder={"Select"}
                    disabled={true}
                    name={"Currently Using any FP method"}
                    selectedOption={data.ClientCurrentlyUsingAnyFPMethod ?? ""}
                  />
                </div>
              )}
              <RadioGroup
                title="Ever used a FP method"
                //classStar="text-red-500"
                type="radio"
                setName="clientEverUsedAFPMethod"
                selectedOptions={data.ClientEverUsedAFPMethod ?? ""}
                options={data1[5] ?? []}
                tabIndex="0"
                disabled={true}
              />
              <InputText
                inputType="number"
                //classStar="text-red-500"
                title="No. Of Living Children"
                name="NoOfLivingChildren"
                placeholder={"00"}
                disabled={true}
                content={
                  data?.ClientNoOfLivingChildren == 0
                    ? ""
                    : data?.ClientNoOfLivingChildren ?? ""
                }
              />
            </div>
          )}
          {data?.ClientNoOfLivingChildren != 0 &&
            data.ClientNoOfLivingChildren !== "0" &&
            data?.ClientNoOfLivingChildren !== null && (
              <div class="w-7/12">
                <div className={"grid gap-4 mb-6"}>
                  <TableGrid
                    className={mainTable.table}
                    headerData={clientChildrenDetailsHeaders}
                    tableData={data1[7] ?? []}
                    tableHeight="h-36"
                    bindClientGenderDD={bindGenderDD}
                    currentPage={1}
                    itemsPerPage={100}
                    onDisabled={true}
                  />
                </div>
              </div>
            )}

          <div className="block mb-2 text-base capitalize font-medium text-neutral-700 dark:text-white">
            Medical History
          </div>
          <div class={mainTable.grid3}>
            <RadioGroup
              title="Diabetes"
              // classStar="text-red-500"
              type="radio"
              setName="clientDiabetes"
              selectedOptions={data.ClientDiabetes ?? ""}
              options={data1[5] ?? []}
              tabIndex="0"
              disabled={true}
            />

            <RadioGroup
              title="Hypertension"
              // classStar="text-red-500"
              type="radio"
              setName="clientHypertension"
              selectedOptions={data.ClientHypertension ?? ""}
              options={data1[5] ?? []}
              tabIndex="0"
              disabled={true}
            />

            <RadioGroup
              title="Asthma"
              // classStar="text-red-500"
              type="radio"
              setName="clientAsthma"
              selectedOptions={data.ClientAsthma ?? ""}
              options={data1[5] ?? []}
              tabIndex="0"
              disabled={true}
            />

            <RadioGroup
              title="Tuberculosis"
              // classStar="text-red-500"
              type="radio"
              setName="clientTuberculosis"
              selectedOptions={data.ClientTuberculosis ?? ""}
              options={data1[5] ?? []}
              tabIndex="0"
              disabled={true}
            />

            <InputText
              inputType={"text"}
              ClassDiv="grid gap-2 grid-cols-2"
              title={"Cancer (specify)"}
              disabled={true}
              name={"Cancer(specify)"}
              content={data.ClientCancerSpecify_en ?? ""}
            />
            <RadioGroup
              title="Drug allergies"
              // classStar="text-red-500"
              type="radio"
              setName="clientDrugAllergies"
              selectedOptions={data.ClientDrugAllergies ?? ""}
              options={data1[5] ?? []}
              tabIndex="0"
              disabled={true}
            />
            <InputText
              inputType={"text"}
              ClassDiv="grid gap-2 grid-cols-2"
              title={"Other (specify)"}
              disabled={true}
              name={"Other (specify)"}
              content={data.ClientOtherSpecify_en ?? ""}
            />
          </div>
          <TextArea
            inputType="text"
            disabled={true}
            title="Additional Notes"
            name="additionalnotes"
            maxLength={500}
            rows="4"
            content={data.ClientAdditionalNotes_en ?? ""}
          />
        </form>
      </div>
    </div>
  );
};

export default ClientDetails;
