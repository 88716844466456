// Code review - In Progress
// Documentation - In Progress
import React from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import DropDown from "../ReusableComponents/DropDown";
import download from "../image/download.png";

function HealthCareProviderPopup({ data, data2 }) {
  return (
    <div class="w-full">
      <div className={mainTable.addShadowScreen}>
        <div className={mainTable.mainContentScreen}>
        <form class="overflow-auto p-5 lg:h-96 md:min-h-[60vh] md:max-h-[60vh] min-h-fit max-h-fit sm:min-h-fit  sm:max-h-fit">
            <div className={mainTable.grid3}>
              <InputText
                inputType="date"
                title="Date"
                name="date"
                content={data2[5]}
                disabled="disabled"
                classStar="text-red-500"
              />
              <InputText
                inputType="text"
                title="Healthcare Provider Name"
                name="hcpName"
                content={data.HealthcareProviderName_en}
                placeholder="Manogar"
                disabled="disabled"
                classStar="text-red-500"
              />

              <AutoDropDown
                title="Speciality"
                options={[]}
                placeholder="Select"
                name="Speciality"
                selectedOption={data2[0]}
                Class="custom-class"
                tabIndex="1"
                disabled="disabled"
                classStar="text-red-500"
              />
              <InputText
                inputType="text"
                title="Email"
                name="email"
                content={data.HealthcareProviderEmail}
                placeholder="Manogar@gmail.com"
                disabled="disabled"
                classStar="text-red-500"
              />
              <InputText
                inputType="text"
                title="Phone Number"
                name="phoneNumber"
                content={data.HealthcareProviderPhoneNo}
                placeholder="XXXXX XXXXX"
                disabled="disabled"
                classStar="text-red-500"
              />
              <DropDown
                classDiv="hidden"
                title="Country"
                options={[]}
                placeholder="Select"
                name="country"
                value={"healthCareProviderCountry"}
                classStar="text-red-500"
              />
              <AutoDropDown
                title="State"
                options={[]}
                placeholder="Select"
                name="state"
                selectedOption={data2[1]}
                Class="custom-class"
                disabled="false"
                tabIndex="1"
                classStar="text-red-500"
              />
              <AutoDropDown
                title="Branch/Project"
                options={[]}
                placeholder="Select"
                name="branchProject"
                selectedOption={data2[2]}
                Class="custom-class"
                disabled="false"
                tabIndex="1"
                classStar="text-red-500"
              />
              <AutoDropDown
                title="S.D.P."
                options={[]}
                placeholder="Select"
                name="state"
                selectedOption={data2[3]}
                Class="custom-class"
                disabled="false"
                tabIndex="1"
                classStar="text-red-500"
              />
              <AutoDropDown
                title="Area"
                options={[]}
                placeholder="Select"
                name="state"
                selectedOption={data2[4]}
                Class="custom-class"
                disabled="false"
                tabIndex="1"
                classStar="text-red-500"
              />
              <InputText
                inputType="text"
                title="Zip/Pincode"
                name="pincode"
                content={data.HealthCarePinCode}
                placeholder="xxxxxx"
                disabled="disabled"
                classStar="text-red-500"
              />
              <TextArea
                inputType="text"
                title="Address"
                name="address"
                content={data.HealthCareProviderAddress_en}
                placeholder="7/33,first street"
                disabled="disabled"
                classStar="text-red-500"
              />

              <div className="flex justify-center mt-3">
                <div>
                  <input
                    type="image"
                    src={data.HealthCareProviderPhoto}
                    alt="Image"
                    className="rounded-full w-20 h-20"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HealthCareProviderPopup;
