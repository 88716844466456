// Code review - In Progress
// Documentation - In Progress
import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import download from "../image/download.png";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const ResourceMasterPopup = ({ data, data1 }) => {
  const [bindState, setBindStateDD] = useState([]);
  const [bindLocation, setBindLocationDD] = useState([]);
  return (
    <div class="w-full ">
      <div class=" border-black  ">
        <div className={mainTable.addShadowScreen}>
          <div className={mainTable.mainContentScreen}>
          <form class="overflow-auto p-5 min-h-fit max-h-fit ">
              <div className={mainTable.grid2}>
                <InputText
                  inputType="text"
                  title="Resources Name"
                  name="resourcesName"
                  content={data.ResourcesName}
                  placeholder="Resources Name"
                  disabled="disabled"
                  classStar="text-red-500"
                />
                <InputText
                  inputType="text"
                  title="Position"
                  name="resourcesName"
                  content={data.ResourcesPosition}
                  placeholder="Position"
                  disabled="disabled"
                  classStar="text-red-500"
                />
                <AutoDropDown
                  classDiv="hidden"
                  title="Country"
                  options={bindLocation}
                  placeholder="Select"
                  name="country"
                  Class="custom-class"
                  disabled="true"
                  tabIndex="1"
                  classStar="text-red-500"
                />
                <AutoDropDown
                  title="State"
                  options={bindState}
                  placeholder="Select"
                  name="state"
                  selectedOption={data1[0]}
                  Class="custom-class"
                  tabIndex="1"
                  disabled="disabled"
                  classStar="text-red-500"
                />
                <AutoDropDown
                  title="Branch/Project"
                  options={bindLocation}
                  placeholder="Select"
                  name="branchProject"
                  selectedOption={data1[1]}
                  Class="custom-class"
                  tabIndex="1"
                  disabled="disabled"
                  classStar="text-red-500"
                />
               <div className="flex">
                <div>
                  <img
                    src={data.ResourcesPhoto ? data.ResourcesPhoto : download}
                    alt="Image"
                    className="rounded-full w-20 h-20"
                  />
                </div>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceMasterPopup;
