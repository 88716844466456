import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { ageDonorSRHHeader } from "../components/HeaderAttribute";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import MultiSelect from "../ReusableComponents/MultiSelect";
import * as XLSX from "sheetjs-style";
import { useUserCapability } from "../components/ConstantReducerProvider";

const ReportAgeDonorSRH = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [branchName, setBranchName] = useState("");

  const [ageGenderFields, setAgeGenderFields] = useState({
    branchProject: "",
    sdp: "",
    area: "",
    serviceCategory: "",
    serviceSubCategory: "",
    servicesName: "",
  });
  const [ageGenderFieldsError, setAgeGenderFieldsError] = useState({
    sdpError: "",
    areaError: "",
    branchProjectError: "",

    serviceCategoryError: "",
    serviceSubCategoryError: "",
    servicesNameError: "",
  });

  const [showTable, setShowTable] = useState(false);

  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);
  const [getServicesData, setGetServicesDatas] = useState([]);
  const [getServiceSubCDatas, setGetServiceSubCDatas] = useState([]);
  const [bindServicesName, setBindServicesName] = useState([]);
  const [bindServiceSubCategory, setBindServiceSubCategory] = useState([]);
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindAgeDonorSRH, setBindAgeDonorSRH] = useState([]);
  const [bindServiceCategory, setBindServiceCategory] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);
  const [serviceCategoryNameList, setServiceCategoryNameList] = useState([]);
  const [serviceSubCategoryNameList, setServiceSubCategoryNameList] = useState(
    []
  );
  const [serviceNameList, setServiceNameList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      setBranchName(userProfileDatas[0]?.LocationName_en);
      if (userProfileDatas[0]?.MstSDPId == null) {
        setAgeGenderFields({
          ...ageGenderFields,
          branchProject: [userProfileDatas[0]?.MstLocationId],
          sdp: "",
        });
      } else {
        setAgeGenderFields({
          ...ageGenderFields,
          branchProject: [userProfileDatas[0]?.MstLocationId],
          sdp: [userProfileDatas[0]?.MstSDPId],
        });
      }
    }
    BranchProjectFetchData();
    serviceCategoryDD();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = ageGenderFields.branchProject
      ? ""
      : "Select Branch/Project";
    const sdpValidate = ageGenderFields.sdp ? "" : "Select SDP";

    const areaValidate = ageGenderFields.area ? "" : "Select Area";

    const serviceCategoryValidate = ageGenderFields.serviceCategory
      ? ""
      : "Select Service Category";
    const serviceSubCategoryValidate = ageGenderFields.serviceSubCategory
      ? ""
      : "Select Service Sub-Category";
    const servicesNameValidate = ageGenderFields.servicesName
      ? ""
      : "Select  Services";
    setAgeGenderFieldsError({
      ...ageGenderFieldsError,
      branchProjectError: branchProjectValidate,
      sdpError: sdpValidate,
      areaError: areaValidate,
      serviceCategoryError: serviceCategoryValidate,
      serviceSubCategoryError: serviceSubCategoryValidate,
      servicesNameError: servicesNameValidate,
    });

    let branchProjectArray = Array.from(
      { length: ageGenderFields.branchProject.length },
      (v, i) => ageGenderFields.branchProject[i]
    );
    let branchProjectResult = branchProjectArray.join(",");

    let sdpArray = Array.from(
      { length: ageGenderFields.sdp.length },
      (v, i) => ageGenderFields.sdp[i]
    );
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from(
      { length: ageGenderFields.area.length },
      (v, i) => ageGenderFields.area[i]
    );
    let areaResult = areaArray.join(",");

    let serviceCategoryArray = Array.from(
      { length: ageGenderFields.serviceCategory.length },
      (v, i) => ageGenderFields.serviceCategory[i]
    );
    let serviceCategoryResult = serviceCategoryArray.join(",");

    let serviceSubCategoryArray = Array.from(
      { length: ageGenderFields.serviceSubCategory.length },
      (v, i) => ageGenderFields.serviceSubCategory[i]
    );
    let serviceSubCategoryResult = serviceSubCategoryArray.join(",");

    let servicesArray = Array.from(
      { length: ageGenderFields.servicesName.length },
      (v, i) => ageGenderFields.servicesName[i]
    );
    let servicesResult = servicesArray.join(",");

    if (
      !branchProjectValidate &&
      !sdpValidate &&
      !areaValidate &&
      !serviceCategoryValidate &&
      !serviceSubCategoryValidate &&
      !servicesNameValidate
    ) {
      const ReportData = [
        {
          mstBranchId: branchProjectResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          mstServicesCategoryId: serviceCategoryResult,
          mstServicesSubCategoryId: serviceSubCategoryResult,
          mstServicesId: servicesResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(
        ReportData,
        "TXNServicesAPI/ReportAgeDonorSRH"
      );

      console.log("response", response.data.Table);
      setBindAgeDonorSRH(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
      serviceCategoryData: serviceCategoryNameList.toString(),
      serviceSubCategoryData: serviceSubCategoryNameList.toString(),
      servicesData: serviceNameList.toString(),
    };

    exportData(
      ageDonorSRHHeader.map((column) => column.title),
      bindAgeDonorSRH,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  useEffect(() => {
    if (ageGenderFields.branchProject) {
      const NameList = ageGenderFields.branchProject
        .map(
          (option) =>
            bindBranchProjectDD?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setBranchProjectNamesList(NameList);
    }

    if (ageGenderFields.sdp) {
      const NameList = ageGenderFields.sdp
        .map((option) => bindSDP?.find((item) => item.value === option)?.label)
        .filter(Boolean); // Remove undefined values if any
      setSDPNamesList(NameList);
    }

    if (ageGenderFields.area) {
      const NameList = ageGenderFields.area
        .map((option) => bindArea?.find((item) => item.value === option)?.label)
        .filter(Boolean); // Remove undefined values if any
      setAreaNamesList(NameList);
    }

    if (ageGenderFields.serviceCategory) {
      const NameList = ageGenderFields.serviceCategory
        .map(
          (option) =>
            bindServiceCategory?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setServiceCategoryNameList(NameList);
    }

    if (ageGenderFields.serviceSubCategory) {
      const NameList = ageGenderFields.serviceSubCategory
        .map(
          (option) =>
            bindServiceSubCategory?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setServiceSubCategoryNameList(NameList);
    }

    if (ageGenderFields.servicesName) {
      const NameList = ageGenderFields.servicesName
        .map(
          (option) =>
            bindServicesName?.find((item) => item.value === option)?.label
        )
        .filter(Boolean); // Remove undefined values if any
      setServiceNameList(NameList);
    }
  }, [ageGenderFields]);

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "Age - Donor - SRH",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];
    // Branch information row with merged cells
    const branchRow = [
      { v: "" },

      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    //Services information row with merged cells
    const servicesRow = [
      { v: "" },
      {
        v: "Service Category",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.serviceCategoryData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Service Sub-Category",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.serviceSubCategoryData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Services",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.servicesData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow = [
      ...columns.map((column) => ({
        v: column,
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "left" },
        },
      })),

      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "2D2D6E" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];
    const columnTotals = {
      Age0to9: 0,
      Age10to14: 0,
      Age15to18: 0,
      Age19: 0,
      Age20to24: 0,
      Age25to29: 0,
      Age30to34: 0,
      Age35to39: 0,
      Age40to44: 0,
      Age45to49: 0,
      Age50to59: 0,
      Age60AndAbove: 0,
    };
    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};
      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.Age0to9 || 0) +
        Number(rowData.Age10to14 || 0) +
        Number(rowData.Age15to18 || 0) +
        Number(rowData.Age19 || 0) +
        Number(rowData.Age20to24 || 0) +
        Number(rowData.Age25to29 || 0) +
        Number(rowData.Age30to34 || 0) +
        Number(rowData.Age35to39 || 0) +
        Number(rowData.Age40to44 || 0) +
        Number(rowData.Age45to49 || 0) +
        Number(rowData.Age50to59 || 0) +
        Number(rowData.Age60AndAbove || 0);

      // Add to column totals
      columnTotals.Age0to9 += Number(rowData.Age0to9 || 0);
      columnTotals.Age10to14 += Number(rowData.Age10to14 || 0);
      columnTotals.Age15to18 += Number(rowData.Age15to18 || 0);
      columnTotals.Age19 += Number(rowData.Age19 || 0);
      columnTotals.Age20to24 += Number(rowData.Age20to24 || 0);
      columnTotals.Age25to29 += Number(rowData.Age25to29 || 0);
      columnTotals.Age30to34 += Number(rowData.Age30to34 || 0);
      columnTotals.Age35to39 += Number(rowData.Age35to39 || 0);
      columnTotals.Age40to44 += Number(rowData.Age40to44 || 0);
      columnTotals.Age45to49 += Number(rowData.Age45to49 || 0);
      columnTotals.Age50to59 += Number(rowData.Age50to59 || 0);
      columnTotals.Age60AndAbove += Number(rowData.Age60AndAbove || 0);
      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: rowData.Services_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.Age0to9 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age10to14 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age15to18 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age19 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age20to24 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age25to29 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age30to34 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age35to39 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age40to44 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age45to49 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age50to59 || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Age60AndAbove || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });
    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };
    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: {} },
      { v: columnTotals.Age0to9, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age10to14, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age15to18, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age19, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age20to24, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age25to29, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age30to34, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age35to39, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age40to44, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age45to49, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age50to59, t: "n", s: totalsRowStyle },
      { v: columnTotals.Age60AndAbove, t: "n", s: totalsRowStyle },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];
    // const wsData = [titleRow, headerRow, ...dataRows, totalsRow];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      servicesRow,
      spacingRow,
      headerRow,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Set custom column widths
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 20 }, // Services
      { width: 30 }, // Branch
      { width: 30 }, // Branch
      { width: 30 }, // Branch
      { width: 10 }, // 0-9 yrs
      { width: 10 }, // 10-14 Yrs
      { width: 10 }, // 15-18 Yrs
      { width: 10 }, // 19 Yrs
      { width: 10 }, // 20-24 Yrs
      { width: 10 }, // 25-29 Yrs
      { width: 10 }, // 30-34 Yrs
      { width: 10 }, // 35-39 Yrs
      { width: 10 }, // 40-44 Yrs
      { width: 10 }, // 45-49 Yrs
      { width: 10 }, // 50-59 Yrs
      { width: 16 }, // 50 Yrs and Above
      { width: 10 }, // Row Total
    ];
    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };
    // // Apply styles
    // // Title row
    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header row
    // headerRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({ r: 1, c: colIndex });
    //   ws[cellAddress].s = { ...cell.s, ...borderStyle };
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 2,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 2,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    // Apply borders and styles
    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "Age - Donor - SRH.xlsx");
  };
  // const exportData = (columns, rows) => {
  //   const headerRow = columns.map((column) => ({
  //     v: column,
  //     s: {
  //       fill: {
  //         fgColor: { rgb: "2D2D6E" }, // Background color (dark blue)
  //       },
  //       font: {
  //         bold: true,
  //         color: { rgb: "FFFFFF" }, // Font color (white)
  //       },
  //     },
  //   }));

  //   const dataRows = rows.map((rowData, index) => {
  //     const isAlternateRow = index % 2 === 1;
  //     const rowStyle = isAlternateRow
  //       ? { fill: { fgColor: { rgb: "F0F0F0" } } }
  //       : {};

  //     return [
  //       { v: index + 1, s: rowStyle },
  //       { v: rowData.Branch, s: rowStyle },
  //       { v: rowData.Services_en, s: rowStyle },
  //       { v: rowData.Age0to9, s: rowStyle },
  //       { v: rowData.Age10to14, s: rowStyle },
  //       { v: rowData.Age15to18, s: rowStyle },
  //       { v: rowData.Age19, s: rowStyle },
  //       { v: rowData.Age20to24, s: rowStyle },
  //       { v: rowData.Age25to29, s: rowStyle },
  //       { v: rowData.Age30to34, s: rowStyle },
  //       { v: rowData.Age35to39, s: rowStyle },
  //       { v: rowData.Age40to44, s: rowStyle },
  //       { v: rowData.Age45to49, s: rowStyle },
  //       { v: rowData.Age50to59, s: rowStyle },
  //       { v: rowData.Age60AndAbove, s: rowStyle },
  //     ];
  //   });

  //   const wsData = [headerRow, ...dataRows];
  //   const ws = XLSX.utils.aoa_to_sheet(wsData);

  //   headerRow.forEach((cell, colIndex) => {
  //     const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
  //     ws[cellAddress].s = cell.s;
  //   });

  //   dataRows.forEach((row, rowIndex) => {
  //     row.forEach((cell, colIndex) => {
  //       const cellAddress = XLSX.utils.encode_cell({
  //         r: rowIndex + 1,
  //         c: colIndex,
  //       });
  //       ws[cellAddress] = { v: cell.v, s: cell.s };
  //     });
  //   });

  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  //   XLSX.writeFile(wb, "Age - Donor-SRH.xlsx");
  // };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    const headers = ageDonorSRHHeader.map((column) => column.title);

    const data = bindAgeDonorSRH.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.Services_en,
        rowData.Age0to9,
        rowData.Age10to14,
        rowData.Age15to18,
        rowData.Age19,
        rowData.Age20to24,
        rowData.Age25to29,
        rowData.Age30to34,
        rowData.Age35to39,
        rowData.Age40to44,
        rowData.Age45to49,
        rowData.Age50to59,
        rowData.Age60AndAbove,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Auto adjust width
        7: { cellWidth: "auto" }, // Auto adjust width
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Age
        11: { cellWidth: "auto" }, // Gender
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("Age - Donor-SRH.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  useEffect(() => {
    const getSelectedServiceSubCategory = async () => {
      const serviceSubCategoryResponse = await BindAPI(
        "ServicesSubCategoryAPI/BindServicesSubCategory"
      );
      setGetServiceSubCDatas(serviceSubCategoryResponse.data.Table);
    };
    // Bind service Dropdown datas
    const getSelectedServices = async () => {
      const serviceSubCategoryResponse = await BindAPI(
        "ServicesMasterAPI/BindServices"
      );
      setGetServicesDatas(serviceSubCategoryResponse.data.Table);
    };

    getSelectedServices();

    getSelectedServiceSubCategory();
  }, []);

  const serviceCategoryDD = async () => {
    try {
      const serviceCategoryResponse = await BindAPI(
        "ServicesCategoryAPI/BindServicesCategory"
      );

      const serviceCategoryData = serviceCategoryResponse.data.Table.map(
        (item) => ({
          value: item.MstServicesCategoryId,
          label: item.ServicesCategory_en,
        })
      );

      setBindServiceCategory(serviceCategoryData);
    } catch (error) {
      // Handle API call errors (e.g., show an error message)
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const ageGenderFieldsChange = (value, name) => {
    setAgeGenderFields({
      ...ageGenderFields,
      [name]: value,
    });
  };

  useEffect(() => {
    if (ageGenderFields.branchProject) {
      try {
        const getSelectedSDP = async () => {
          const locationResponse = await BindAPI("SDPAPI/GetSDP");
          setGetSDPDatas(locationResponse.data.Table);

          if (userProfileDatas && userProfileDatas[0]) {
            const sdpLabels = locationResponse.data.Table.filter((item) =>
              ageGenderFields.branchProject.includes(
                parseInt(item.MstLocationId)
              )
            ).map((item) => ({
              MstSDPId: item.MstSDPId,
              SDP_en: item.SDP_en,
            }));

            const sdpSelected = sdpLabels.map((SD) => ({
              value: SD.MstSDPId,
              label: SD.SDP_en,
            }));

            if (
              userProfileDatas[0].MstOrganizationId == 1 ||
              userProfileDatas[0].MstOrganizationId == 2
            ) {
              setBindSDP(sdpSelected);
            } else {
              const allSDPIds = userProfileDatas[0]?.AllSDP;
              const sdpId = userProfileDatas[0]?.MstSDPId;
              console.log("sdpId", sdpId);
              if (allSDPIds && Array.isArray(allSDPIds)) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  allSDPIds.includes(sdp.value)
                );

                setBindSDP(filteredSdpSelected);
              }
              if (sdpId) {
                const filteredSdpSelected = sdpSelected.filter((sdp) =>
                  Array.isArray(sdpId)
                    ? sdpId.includes(sdp.value)
                    : sdpId === sdp.value
                );

                setBindSDP(filteredSdpSelected);
              }
            }
          }
        };

        getSelectedSDP();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [
    ageGenderFields.branchProject ? ageGenderFields.branchProject : undefined,
    userProfileDatas ? userProfileDatas : undefined,
  ]);

  useEffect(() => {
    if (ageGenderFields.sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            ageGenderFields.sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [ageGenderFields.sdp ? ageGenderFields.sdp : undefined]);

  useEffect(() => {
    // Bind Services Sub Category Dropdown datas

    const serviceSubCategorySelectedValue = ageGenderFields.serviceCategory;
    setBindServiceSubCategory([]);
    if (serviceSubCategorySelectedValue) {
      const serviceSubCategoryLabels = getServiceSubCDatas
        .filter((item) =>
          serviceSubCategorySelectedValue.includes(item.MstServicesCategoryId)
        )
        .map((item) => ({
          MstServicesSubCategoryId: item.MstServicesSubCategoryId,
          ServicesSubCategory_en: item.ServicesSubCategory_en,
        }));

      const servicesSubCategorySelected = serviceSubCategoryLabels.map(
        (AR) => ({
          value: AR.MstServicesSubCategoryId,
          label: AR.ServicesSubCategory_en,
        })
      );
      setBindServiceSubCategory(servicesSubCategorySelected);
    } else {
    }
  }, [ageGenderFields.serviceCategory]);

  useEffect(() => {
    // Bind Services Dropdown datas

    const selectedSubCategories = ageGenderFields.serviceSubCategory;
    setBindServicesName([]);
    if (selectedSubCategories.length > 0) {
      const servicesLabels = getServicesData
        .filter((item) =>
          selectedSubCategories.includes(item.MstServicesSubCategoryId)
        )
        .map((item) => ({
          MstServicesId: item.MstServicesId,
          Services_en: item.Services_en,
        }));

      const servicessSelected = servicesLabels.map((AR) => ({
        value: AR.MstServicesId,
        label: AR.Services_en,
      }));
      setBindServicesName(servicessSelected);
    } else {
      // Handle the case when no sub-categories are selected
      setBindServicesName([]);
    }
  }, [ageGenderFields.serviceSubCategory]);

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Age - Donor-SRH`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      ageGenderFields.branchProject?.length > 0
                        ? "More Selected"
                        : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={ageGenderFields.branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={(e) => {
                      ageGenderFieldsChange(e, "branchProject");
                    }}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {ageGenderFieldsError.branchProjectError && (
                    <span className="text-red-500">
                      {ageGenderFieldsError.branchProjectError}
                    </span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    ageGenderFields.sdp?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="sdp"
                  selectedOptions={ageGenderFields.sdp}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    ageGenderFieldsChange(e, "sdp");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {ageGenderFieldsError.sdpError && (
                  <span className="text-red-500">
                    {ageGenderFieldsError.sdpError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    ageGenderFields.area?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="area"
                  selectedOptions={ageGenderFields.area}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    ageGenderFieldsChange(e, "area");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {ageGenderFieldsError.areaError && (
                  <span className="text-red-500">
                    {ageGenderFieldsError.areaError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Service Category"
                  filteredOptions={bindServiceCategory}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    ageGenderFields.serviceCategory?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="serviceCategory"
                  selectedOptions={ageGenderFields.serviceCategory}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    ageGenderFieldsChange(e, "serviceCategory");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {ageGenderFieldsError.serviceCategoryError && (
                  <span className="text-red-500">
                    {ageGenderFieldsError.serviceCategoryError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Service Sub-Category"
                  filteredOptions={bindServiceSubCategory}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    ageGenderFields.serviceSubCategory?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="serviceSubCategory"
                  selectedOptions={ageGenderFields.serviceSubCategory}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    ageGenderFieldsChange(e, "serviceSubCategory");
                  }}
                  classLabel={mainTable.labelNoWrap}
                />
                {ageGenderFieldsError.serviceSubCategoryError && (
                  <span className="text-red-500">
                    {ageGenderFieldsError.serviceSubCategoryError}
                  </span>
                )}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Services"
                  filteredOptions={bindServicesName}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={
                    ageGenderFields.servicesName?.length > 0
                      ? "More Selected"
                      : "Select..."
                  }
                  name="servicesName"
                  selectedOptions={ageGenderFields.servicesName}
                  classDiv="relative w-full inline-block"
                  onChange={(e) => {
                    ageGenderFieldsChange(e, "servicesName");
                  }}
                />
                {ageGenderFieldsError.servicesNameError && (
                  <span className="text-red-500">
                    {ageGenderFieldsError.servicesNameError}
                  </span>
                )}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindAgeDonorSRH.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={ageDonorSRHHeader}
                records={bindAgeDonorSRH}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default ReportAgeDonorSRH;
