import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import TextArea from "../ReusableComponents/TextArea";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const TrainingMonthlyPopup = ({ data, data2 }) => {
  console.log("data2", data2);
  const [visibleSchoolsEnrolled, setVisibleSchoolsEnrolled] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [servicesNameDatas, setServicesNameDatas] = useState([]);

  useEffect(() => {
    if (data) {
      schoolsEnrolled(data.TxnMonthId);
      handleInputChange(data.NoOfNGOCBOsPartnered);
    }
    if (data2[2]) {
      setInputValues(new Array(parseInt(data?.NoOfNGOCBOsPartnered) || 0).fill(data2[2]));
    } else {
      setInputValues([]);
    }
  }, [data, data2]);

  const schoolsEnrolled = (value) => {
    if (value === 1) {
      setVisibleSchoolsEnrolled(true);
    } else {
      setVisibleSchoolsEnrolled(false);
    }
  };

  const handleInputChange = (value) => {
    const numInputs = parseInt(value);
    if (!isNaN(numInputs)) {
      setInputValues(new Array(numInputs).fill(servicesNameDatas));
    } else {
      setInputValues([]);
    }
  };
  console.log("inputValues", inputValues);
  return (
    <div class="w-full">
      <div class=" flex flex-col w-full">
        <div class={mainTable.clientMasterMainAdd}>
          <div class={mainTable.mainContentScreen}>
            <form className={mainTable.formForPopupcontainer}>
             
              <div className={mainTable.grid3}>
                <AutoDropDown
                  classStar="text-red-500"
                  title="Month"
                  options={[]}
                  placeholder="Select"
                  name="month"
                  selectedOption={data2[1]}
                  Class="custom-class"
                  tabIndex="1"
                  disabled={true}
                />

                <InputText
                  inputType="number"
                  title="Year"
                  classStar="text-red-500"
                  name="year"
                  content={data.YearId}
                  disabled={true}
                />
              </div>
              <div class={mainTable.labelBold1}>
                Training by FPAI / Volunteer (no. of participants)
              </div>
              <hr class={mainTable.horizontalLine}></hr>
              <div className={mainTable.grid3}>
                <InputText
                  inputType="number"
                  title="Teacher Training"
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.TeacherTraining}
                  disabled={true}
                />
                <InputText
                  inputType="number"
                  title="NGO/CBO Staff Training"
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.NGOCBOStaffTraining}
                  disabled={true}
                />
                <InputText
                  inputType="number"
                  title="Peer Educator Trained (Directly / ToT)"
                  name="schoolName"
                  classStar="text-red-500"
                  content={data.PeerEducatorTrained}
                  disabled={true}
                />
                <InputText
                  inputType="number"
                  classStar="text-red-500"
                  title="Peer-to-Peer Training "
                  name="schoolName"
                  content={data.PeerToPeerTraining}
                  disabled={true}
                />
              </div>
              <div class={mainTable.labelBold1}>
                Sensitization Meeting with (no. of participants)
              </div>
              <hr class={mainTable.horizontalLine}></hr>
              <div className={mainTable.grid3}>
                <InputText
                  inputType="number"
                  title="Parents "
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.Parents}
                  disabled={true}
                />
                <InputText
                  inputType="number"
                  title="Teachers"
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.Teachers}
                  disabled={true}
                />
              </div>
              <div class={mainTable.labelBold1}>NGO/CBOs Partner</div>
              <hr class={mainTable.horizontalLine}></hr>
              <div className={mainTable.grid3}>
                <InputText
                  inputType="text"
                  title="No. Of NGO/CBOs Partnered"
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.NoOfNGOCBOsPartnered}
                  controlFunc={(e) =>
                    handleInputChange(data.NoOfNGOCBOsPartnered)
                  }
                  disabled={true}
                />
                {inputValues.map((value, index) => (
                  <InputText
                    key={index}
                    inputType="text"
                    title="Name of the NGO/CBO "
                    maxLength={100}
                    classStar="text-red-500"
                    name="schoolName"
                    content={value[index].NameOfTheNGOCBO}
                    disabled={true}
                  />
                ))}
              </div>

              <div className={mainTable.grid3}>
                {visibleSchoolsEnrolled && (
                  <InputText
                    inputType="number"
                    title="No. Of Schools Enrolled (Yearly In Jan.)"
                    classStar="text-red-500"
                    name="schoolName"
                    content={data.SchoolsEnrolled}
                    disabled={true}
                  />
                )}
                <InputText
                  inputType="number"
                  title="No. of Schools Completed  "
                  classStar="text-red-500"
                  name="schoolName"
                  content={data.SchoolsCompleted}
                  disabled={true}
                />
                <div className="col-span-1">
                  <label for="client_name" class={mainTable.label}>
                    No. Of Youth Groups Formed
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="grid gap-2 md:grid-cols-5 flex items-center bg-gray-50 p-1 border border-gray-300 rounded-lg">
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title="Boys"
                      name="boys"
                      content={data.YouthGroupsFormedBoys}
                      disabled={true}
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Girls"}
                      name={"girls"}
                      content={data.YouthGroupsFormedGirls}
                      disabled={true}
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Others"}
                      name="other"
                      content={data.YouthGroupsFormedOthers}
                      disabled={true}
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"Mixed"}
                      name="mixed"
                      content={data.YouthGroupsFormedMixed}
                      disabled={true}
                    />
                    <InputText
                      inputType={"text"}
                      ClassDiv="grid gap-2 grid-cols-2"
                      ClassLabel={mainTable.labelBaseline}
                      Class={mainTable.smallInput}
                      title={"total"}
                      name={"total"}
                      content={data.YouthGroupsFormedTotal}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div class="lg:col-span-4 sm:col-span-1">
                <TextArea
                  inputType="text"
                  title="Remarks"
                  name="remarks"
                  rows="4"
                  placeholder="Remarks"
                  content={data.Remarks}
                  disabled={true}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TrainingMonthlyPopup };
