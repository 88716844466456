// Code review - In Progress
// Documentation - In Progress
import React from "react";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";

const SDP = ({ data, data2 }) => {
  return (
    <div class="w-full ">
      <div class=" border-black  "></div>
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <AutoDropDown
                  title="State"
                  placeholder="Select"
                  options={[]}
                  name="state"
                  selectedOption={data2[0]}
                  Class="custom-class"
                  classStar="text-red-500"
                  disabled={true}
                  tabIndex="1"
                />
                <AutoDropDown
                  title="Branch/Project"
                  placeholder="Select"
                  name="branchProject"
                  options={[]}
                  selectedOption={data2[1]}
                  Class="custom-class"
                  classStar="text-red-500"
                  disabled={true}
                  tabIndex="1"
                />
                <InputText
                  inputType="text"
                  title="S.D.P."
                  name="sdp"
                  content={data.SDP_en}
                  classStar="text-red-500"
                  disabled={true}
                />
                <InputText
                  inputType="text"
                  title="S.D.P. Code"
                  name="sdpCode"
                  content={data.SDPCode}
                  classStar="text-red-500"
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SDP;
