// Code review - In Progress
// Documentation - In Progress

import React, { useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import toast, { Toaster } from "react-hot-toast";
import { IUDAPI, authenticationAPI } from "../services/APIConnection";

const ForgetPassword = ({ closePopup }) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginEmailError, setLoginEmailError] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);

  const cancel = () => {
    closePopup(true);

    setLoginEmail("");
    setLoginEmailError("");
    setDisabledBtn(false);
  };

  const handleForgotPassword = async () => {
    if (!loginEmail) {
      setLoginEmailError("Enter your email address");
    } else if (loginEmail) {
      setDisabledBtn(true);
      const forgotPayload = [
        {
          userName: loginEmail,
        },
      ];
      const response = await authenticationAPI(
        forgotPayload,
        "LoginAPI/ForgotPassword"
      );
      if (response.data.Table[0].Result === "E") {
        toast.success("Your temporary password as been send to your mail", {
          duration: 4000,
          position: "top-center",
        });
        setTimeout(cancel, 3000);
      } else if (response.data.Table[0].Result === "NE") {
        toast.error("Enter registred email", {
          duration: 4000,
          position: "top-center",
        });
        setTimeout(cancel, 3000);
      } else {
        return false;
      }
    } else {
      setLoginEmailError("Enter your email address");
    }
  };

  return (
    <div class="w-full">
      <div class="text-xl mx-4 font-medium text-neutral-700  ">
        {"Forgot Your Password"}
      </div>
      <div class={`text-center text-base font-medium  my-3 text-neutral-700`}>
        {
          "Enter your email and we will send you instructions to reset your password"
        }
      </div>
      <form class={"mx-4"}>
        <div class=" flex flex-col w-full">
          <div class="grid gap-4 mb-6">
            <InputText
              title="Email"
              type="email"
              name="email"
              placeholder="abcd@gmail.com"
              content={loginEmail}
              controlFunc={(e) => setLoginEmail(e.target.value)}
              controlFuncBlur={(e) => {
                setLoginEmail(e.target.value);
                setLoginEmailError(
                  loginEmail.length > 0
                    ? /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
                        loginEmail
                      )
                      ? ""
                      : "Enter a valid email address "
                    : "Enter Email"
                );
              }}
            />
            {loginEmailError && (
              <span class="text-red-500">{loginEmailError}</span>
            )}
            <div class={mainTable.submitCancelMainDiv}>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={
                  disabledBtn
                    ? mainTable.disabledSubmitButton
                    : mainTable.submitButton
                }
                onClick={() => handleForgotPassword()}
                disabled={disabledBtn}
              >
                Request password reset
              </button>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.cancelButton}
                onClick={cancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
