// Code review - In Progress
// Documentation - In Progress

import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import { resetPassWord } from "../services/LoginAPIServices";
import InputPassword from "../ReusableComponents/InputPassword";
import toast, { Toaster } from "react-hot-toast";
import { IUDAPI } from "../services/APIConnection";

const ResetPassword = ({ closePopup, data }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [reEnterPasswordError, setReEnterPasswordError] = useState("");
  const [userName, setUserName] = useState("");
  const [disabledBtn, setDisabledBtn] = useState(false);
  //var loginEmail = data.UserEmail || data.UserMobile;

  const cancel = () => {
    setTimeout(() => {
      closePopup(true);
    }, 1000);
    setDisabledBtn(false);
    setPassword("");
    setReEnterPassword("");
    setPasswordError("");
    setReEnterPasswordError("");
  };

  useEffect(() => {
    if (data) {
      setUserName(data.UserEmail || data.UserMobile);
    } else {
      setUserName(data.UserEmail || data.UserMobile);
    }
  }, [data]);

  const ResetSubmit = async () => {
    if (password && reEnterPassword && passwordMatch) {
      setDisabledBtn(true);
      const resetPayload = [
        {
          userName: userName,
          password: password,
          tempPassword: reEnterPassword,
        },
      ];
      const response = await IUDAPI(
        resetPayload,
        "FPAIOutReachAPI/ResetPassword"
      );
      if (response.data.Table[0].Result === "R") {
        toast.success(
          "Password Reset Completed. You can now use your new password.",
          {
            duration: 1000,
            position: "top-center",
          }
        );
        setTimeout(cancel, 2000);
      } else {
        toast.error("Password Reset Not Completed", {
          duration: 2000,
          position: "top-center",
        });
        setTimeout(cancel, 2000);
      }
    } else {
      setPasswordError(password ? "" : "Enter new password");
      setReEnterPasswordError(reEnterPassword ? "" : "Re-enter password");
      setPasswordMatch(
        password && reEnterPassword ? "" : "Passwords do not match"
      );
      setDisabledBtn(false);
    }
  };

  return (
    <div class="w-full">
      <div class="text-xl mx-4 font-medium text-neutral-700 mb-4 ">
        {"Reset Your Password"}
      </div>

      <form class={"mx-4"}>
        <div class=" flex flex-col w-full">
          <div class="grid gap-4 mb-6">
            <InputPassword
              title="New password"
              classStar={"text-red-400"}
              name="password"
              placeholder="Enter new password"
              content={password}
              controlFunc={(e) => setPassword(e.target.value)}
              controlFuncBlur={(e) => {
                setPassword(e.target.value);
                setPasswordError(password.length > 0 ? "" : "Enter Password");
              }}
            />
            {passwordError && <span class="text-red-500">{passwordError}</span>}

            <InputPassword
              title="Re-enter new password"
              classStar={"text-red-400"}
              name="password"
              placeholder="Re-enter new password"
              content={reEnterPassword}
              controlFunc={(e) => setReEnterPassword(e.target.value)}
              controlFuncBlur={(e) => {
                setReEnterPassword(e.target.value);
                setReEnterPasswordError(
                  reEnterPassword.length > 0 ? "" : "Re-enter password"
                );
                setPasswordMatch(password === e.target.value);
              }}
            />
            {reEnterPasswordError && (
              <span class="text-red-500">{reEnterPasswordError}</span>
            )}
            {!passwordMatch && (
              <span class="text-red-500">Passwords do not match</span>
            )}

            <div class={mainTable.submitCancelMainDiv}>
              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={
                  disabledBtn
                    ? mainTable.disabledSubmitButton
                    : mainTable.submitButton
                }
                onClick={() => ResetSubmit()}
                disabled={disabledBtn}
              >
                Save New Password
              </button>

              <button
                data-modal-hide="bottom-right-modal"
                type="button"
                class={mainTable.cancelButton}
                onClick={cancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
