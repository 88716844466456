import React, { useEffect, useState } from "react";
import { mainTable } from "../css/Common";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserCapability } from "./ConstantReducerProvider";
import DropDown from "../ReusableComponents/DropDown";
import { BindAPI } from "../services/APIConnection";
import ReportTableRow from "./ReportTableRow";

const ReportRowRowPage = ({
  title,
  tableHeight,
  handleExport,
  handlePDF,
  header,
  records,
  handleAction,
  handleSchoolView,
  isGenderBased,
}) => {
  //search state start
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const location = useLocation();
  //search state end
  useEffect(() => {
    if (records) {
      setSearchData(records);
    }
  }, [records]);

  //next page state start
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("");
  //next page state end

  const [bindDDValues, setBindDDValues] = useState([]);

  const systemSettingsData = async () => {
    const response = await BindAPI("SystemSettingsAPI/BindSystemSettings");
    const rowsPerPageFromApi = response.data.Table[0].NoofRowsPerPage;
    setItemsPerPage(rowsPerPageFromApi);
  };

  const getDDValues = async () => {
    try {
      const response = await BindAPI("MasterDataAPI/BindDDValues");
      const resultdata = response.data.Table;

      if (resultdata.length > 0) {
        const religionDD = resultdata.map((m) => ({
          value: m.NoOfRecords,
          label: m.NoOfRecords,
        }));
        setBindDDValues(religionDD);
      } else {
        setBindDDValues([]);
      }
    } catch (error) {
      console.error("Error fetching dropdown values:", error);
    }
  };

  useEffect(() => {
    systemSettingsData();
    getDDValues();
  }, []);

  const handleDropDown = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    setCurrentPage(1);
  };

  const { userProfileState } = useUserCapability();
  const userVsCapability = userProfileState.userVsCapability;
  const navigate = useNavigate();
  const [viewBag, setViewBag] = useState(0);

  const location1 = useLocation();
  const userSideMenu = location1?.state?.menuId;
  useEffect(() => {
    var createdBy = sessionStorage.getItem("createdBy");
    if (createdBy == null || createdBy == "") {
      navigate("/");
    }
    if (Array.isArray(userVsCapability) && userVsCapability.length > 0) {
      let menuFilter = userVsCapability.filter(
        (menu) => menu.MstMenuId == userSideMenu
      );
      setViewBag(menuFilter[0]?.ViewPermission);
    }
  }, [navigate]);

  const handleSearchInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    handleSearch(inputValue);
    setCurrentPage(1);
  };
  console.log("Search1", searchData);
  const handleSearch = (input) => {
    console.log("Search", input);
    const filtered = records.filter((item) => {
      const matchFound = header.some((data) => {
        const userDataKey = data.key;
        const userDataValue = item[userDataKey];

        // // Check if the property exists before calling toLowerCase
        // if (userDataValue !== undefined && userDataValue !== null) {
        //   return userDataValue.toLowerCase().includes(input.toLowerCase());
        // }

        if (
          typeof userDataValue === "string" &&
          userDataValue.toLowerCase().includes(input.toLowerCase())
        ) {
          return true;
        }
        return false; // Property doesn't exist, consider it a non-match
      });

      return matchFound;
    });

    setSearchData(filtered);
  };

  return (
    <div>
      <div className={mainTable.headerTitle}>
        <div className={mainTable.gridTableHeader}>{title}</div>
      </div>
      <div className="relative mt-1 md:hidden">
        <div className={mainTable.searchIconPosition}>
          <svg
            className={mainTable.searchIconSvg}
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          className={mainTable.searchInputBox}
          placeholder="Search for items"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="justify-between flex items-center">
        <div className={mainTable.searchAdd}>
          {/* <div className="md:mt-1 mt-0 gap-2 flex items-center justify-between md:items-baseline"> */}
          <DropDown
            classDiv="md:mt-1 mt-0 gap-2 flex items-center justify-between md:items-baseline"
            Class={mainTable.dropDownRecords}
            ClassLabel="hidden md:block"
            title="Rows Per Page:"
            options={bindDDValues}
            placeholder="Select"
            name="ddValues"
            selectedOption={itemsPerPage}
            controlFunc={handleDropDown}
          />
          <div>
            <label for="table-search" className="sr-only">
              Search
            </label>
          </div>
          <div className="hidden md:block relative mt-1">
            <div className={mainTable.searchIconPosition}>
              <svg
                className={mainTable.searchIconSvg}
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              className={mainTable.searchInputBox}
              placeholder="Search for items"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
        <div className="flex gap-1.5 self-end m-1.5">
          {/* <button
            type="button"
            className={mainTable.addButton}
            onClick={handlePDF}
          >
            <svg fill="currentColor" viewBox="0 0 16 16" width="25" height="18">
              <path d="M14 14V4.5L9.5 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2zM9.5 3A1.5 1.5 0 0011 4.5h2V14a1 1 0 01-1 1H4a1 1 0 01-1-1V2a1 1 0 011-1h5.5v2z" />
              <path d="M4.603 14.087a.81.81 0 01-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 011.482-.645 19.697 19.697 0 001.062-2.227 7.269 7.269 0 01-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 01.477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 00.98 1.686 5.753 5.753 0 011.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 01-.354.416.856.856 0 01-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 01-.911-.95 11.651 11.651 0 00-1.997.406 11.307 11.307 0 01-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 01-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 00.035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 00.45-.606zm1.64-1.33a12.71 12.71 0 011.01-.193 11.744 11.744 0 01-.51-.858 20.801 20.801 0 01-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 00.07-.015.307.307 0 00.094-.125.436.436 0 00.059-.2.095.095 0 00-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 00-.612-.053zM8.078 7.8a6.7 6.7 0 00.2-.828c.031-.188.043-.343.038-.465a.613.613 0 00-.032-.198.517.517 0 00-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
            </svg>
            PDF
          </button> */}

          <button
            type="button"
            className={mainTable.addButton}
            onClick={handleExport} // Assuming you have a function named handleExport for exporting
          >
            <svg viewBox="0 0 24 24" fill="currentColor" width="25" height="20">
              <path d="M21.17 3.25q.33 0 .59.25.24.24.24.58v15.84q0 .34-.24.58-.26.25-.59.25H7.83q-.33 0-.59-.25-.24-.24-.24-.58V17H2.83q-.33 0-.59-.24Q2 16.5 2 16.17V7.83q0-.33.24-.59Q2.5 7 2.83 7H7V4.08q0-.34.24-.58.26-.25.59-.25M7 13.06l1.18 2.22h1.79L8 12.06l1.93-3.17H8.22L7.13 10.9l-.04.06-.03.07q-.26-.53-.56-1.07-.25-.53-.53-1.07H4.16l1.89 3.19L4 15.28h1.78m8.1 4.22V17H8.25v2.5m5.63-3.75v-3.12H12v3.12m1.88-4.37V8.25H12v3.13M13.88 7V4.5H8.25V7m12.5 12.5V17h-5.62v2.5m5.62-3.75v-3.12h-5.62v3.12m5.62-4.37V8.25h-5.62v3.13M20.75 7V4.5h-5.62V7z" />
            </svg>
            Excel
          </button>
        </div>
      </div>
      <ReportTableRow
        className={mainTable.table}
        isGenderBased={isGenderBased}
        tableHeight={tableHeight}
        headerData={header}
        tableData={searchData.length > 0 ? searchData : records}
        onViewGrid={handleAction ? handleAction : ""}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        viewBag={viewBag}
        onSchoolView={handleSchoolView ? handleSchoolView : ""}
      />

      <div className={mainTable.submitCancelMainDiv}>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className={mainTable.submitButton}
        >
          Previous Page
        </button>
        <label>{currentPage}</label>
        <button
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
          disabled={currentPage >= records.length / itemsPerPage}
          className={mainTable.cancelButton}
        >
          Next Page
        </button>
      </div>
    </div>
  );
};

export default ReportRowRowPage;
