import React from "react";
import InputText from "../ReusableComponents/InputText";

const Statepopup = ({ data }) => {
  return (
    <div class="w-full ">
      <div class="mx-auto flex justify-center lg:w-2/5 md:w-full w-full border-black">
        <div class="relative w-full h-full max-w-xl md:h-auto">
          <div class="relative bg-white rounded-lg shadow ">
            <div class="px-6 py-6 lg:px-8">
              <form class="space-y-6" action="#">
                <InputText
                  inputType="text"
                  classStar="text-red-500"
                  title="State"
                  name="State"
                  content={data.StateName_en}
                  disabled={true}
                />
                <InputText
                  inputType="text"
                  classStar="text-red-500"
                  title="State Code"
                  name="State Code"
                  content={data.StateCode}
                  disabled={true}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Statepopup;
