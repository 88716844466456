import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { iecGMMReportHeader } from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import { useUserCapability } from "../components/ConstantReducerProvider";
import MultiSelect from "../ReusableComponents/MultiSelect";

const IECGMMReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [sdp, setSDP] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [sdpError, setSDPError] = useState("");
  const [area, setArea] = useState("");
  const [areaError, setAreaError] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindIECGMMData, setBindIECGMMData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [getSDPDatas, setGetSDPDatas] = useState([]);
  const [bindSDP, setBindSDP] = useState([]);

  const [getAreaDatas, setGetAreaDatas] = useState([]);
  const [bindArea, setBindAreaDD] = useState([]);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);
  const [sdpNamesList, setSDPNamesList] = useState([]);
  const [areaNamesList, setAreaNamesList] = useState([]);

  useEffect(
    () => {
      if (branchProject) {
        try {
          const getSelectedSDP = async () => {
            const locationResponse = await BindAPI("SDPAPI/GetSDP");
            setGetSDPDatas(locationResponse.data.Table);

            if (userProfileDatas && userProfileDatas[0]) {
              const sdpLabels = locationResponse.data.Table.filter((item) =>
                branchProject.includes(parseInt(item.MstLocationId))
              ).map((item) => ({
                MstSDPId: item.MstSDPId,
                SDP_en: item.SDP_en,
              }));

              const sdpSelected = sdpLabels.map((SD) => ({
                value: SD.MstSDPId,
                label: SD.SDP_en,
              }));

              if (
                userProfileDatas[0].MstOrganizationId == 1 ||
                userProfileDatas[0].MstOrganizationId == 2
              ) {
                setBindSDP(sdpSelected);
              } else {
                const allSDPIds = userProfileDatas[0]?.AllSDP;
                const sdpId = userProfileDatas[0]?.MstSDPId;

                if (allSDPIds && Array.isArray(allSDPIds)) {
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    allSDPIds.includes(sdp.value)
                  );
                  setBindSDP(filteredSdpSelected);
                }
                if (sdpId) {
                  console.warn("allSDPIds is undefined or not an array");
                  setBindSDP([]);
                  const filteredSdpSelected = sdpSelected.filter((sdp) =>
                    Array.isArray(sdpId)
                      ? sdpId.includes(sdp.value)
                      : sdpId === sdp.value
                  );
                  setBindSDP(filteredSdpSelected);
                }
              }
            }
          };

          getSelectedSDP();
        } catch (e) {
          console.log(`error fetch data ${e}`);
        }
      }
    },
    [branchProject ? branchProject : undefined],
    userProfileDatas ? userProfileDatas : undefined
  );
  useEffect(() => {
    if (sdp) {
      try {
        const getSelectedArea = async () => {
          const locationResponse = await BindAPI("AreaAPI/GetArea");
          setGetAreaDatas(locationResponse.data.Table);

          const areaLabels = locationResponse.data.Table.filter((item) =>
            sdp.includes(item.MstSDPId)
          ).map((item) => ({
            MstAreaId: item.MstAreaId,
            AreaName_en: item.AreaName_en,
          }));

          const areaSelected = areaLabels.map((AR) => ({
            value: AR.MstAreaId,
            label: AR.AreaName_en,
          }));

          setBindAreaDD(areaSelected);
        };

        getSelectedArea();
      } catch (e) {
        console.log(`error fetch data ${e}`);
      }
    }
  }, [sdp ? sdp : undefined]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      if (userProfileDatas[0]?.MstSDPId == null) {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP("");
      } else {
        setBranchName(userProfileDatas[0]?.LocationName_en);
        setBranchProject([userProfileDatas[0]?.MstLocationId]);
        setSDP([userProfileDatas[0]?.MstSDPId]);
      }
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);

  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);

    const sdpValidate = sdp ? "" : "Select SDP";
    setSDPError(sdpValidate);

    const areaValidate = area ? "" : "Select Area";
    setAreaError(areaValidate);

    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");

    let sdpArray = Array.from({ length: sdp.length }, (v, i) => sdp[i]);
    let sdpResult = sdpArray.join(",");

    let areaArray = Array.from({ length: area.length }, (v, i) => area[i]);
    let areaResult = areaArray.join(",");

    if (!branchProjectValidate && !sdpValidate && !areaValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          mstSDPId: sdpResult,
          mstAreaId: areaResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];
      const response = await BindPostAPI(ReportData, "IECAPI/ReportIECGMM");

      console.log("response", response.data.Table);
      setBindIECGMMData(response.data.Table);
      setShowTable(true);
    } else {
    }
  };
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
      sdpData: sdpNamesList.toString(),
      areaData: areaNamesList.toString(),
    };
    exportData(
      iecGMMReportHeader.map((column) => column.title),
      bindIECGMMData,
      dateRange,
      selectedOptionsData
    );
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "IEC - GMM",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },

      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "S.D.P.:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.sdpData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },

      {
        v: "Area:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.areaData,
        s: {
          alignment: { horizontal: "left" },
          alignment: {
            horizontal: "center",
            vertical: "center",
            wrapText: true, // Enable text wrapping for headers
          }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow1 = [
      {
        v: "S.No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Date",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Branch",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "SDP",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Area",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "GMM no.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "District",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Block",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "City/Village",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Venue",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Channel",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Staff Name",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T1: FP/MCH",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T2: Abortion",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T3: STI & HIV",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T4: Adolescent Health",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T5: Infertility",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T6: SRHR",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T7: GBV/RC",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T8: Oral Cancer",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T9: Breast Cancer",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T10: Cervical cancer",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T11: HPV vaccination",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T12: MISP",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T13: Non-Communicable Disease (NCD)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T14: Gender Identity & Sexual Orientation",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T15: Climate change & environment",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "T16: Other",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Age",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Gender",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Total no. of Participants",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "3A3A3A" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    const headerRow2 = [
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "10-14yrs",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "15-24yrs",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "25+yrs",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Male",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Female",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Other",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
    ];

    const columnTotals = {
      ["T1: FP/MCH"]: 0,
      ["T2: Abortion"]: 0,
      ["T3: STI & HIV"]: 0,
      ["T4: Adolescent Health"]: 0,
      ["T5: Infertility"]: 0,
      ["T6: SRHR"]: 0,
      ["T7: GBV/RC"]: 0,
      ["T8: Oral Cancer"]: 0,
      ["T9: Breast Cancer"]: 0,
      ["T10: Cervical cancer"]: 0,
      ["T11: HPV vaccination"]: 0,
      ["T12: MISP"]: 0,
      ["T13: Non-Communicable Disease (NCD)"]: 0,
      ["T14: Gender Identity & Sexual Orientation"]: 0,
      ["T15: Climate change & environment"]: 0,
      ["T16: Other"]: 0,
      ["10-14yrs"]: 0,
      ["15-24yrs"]: 0,
      ["25+yrs"]: 0,
      male: 0,
      Female: 0,
      Others: 0,
      TotalParticipants: 0,
    };

    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData["T1: FP/MCH"] || 0) +
        Number(rowData["T2: Abortion"] || 0) +
        Number(rowData["T3: STI & HIV"] || 0) +
        Number(rowData["T4: Adolescent Health"] || 0) +
        Number(rowData["T5: Infertility"] || 0) +
        Number(rowData["T6: SRHR"] || 0) +
        Number(rowData["T7: GBV/RC"] || 0) +
        Number(rowData["T8: Oral Cancer"] || 0) +
        Number(rowData["T9: Breast Cancer"] || 0) +
        Number(rowData["T10: Cervical cancer"] || 0) +
        Number(rowData["T11: HPV vaccination"] || 0) +
        Number(rowData["T12: MISP"] || 0) +
        Number(rowData["T13: Non-Communicable Disease (NCD)"] || 0) +
        Number(rowData["T14: Gender Identity & Sexual Orientation"] || 0) +
        Number(rowData["T15: Climate change & environment"] || 0) +
        Number(rowData["T16: Other"] || 0) +
        Number(rowData["10-14yrs"] || 0) +
        Number(rowData["15-24yrs"] || 0) +
        Number(rowData["25+yrs"] || 0) +
        Number(rowData.male || 0) +
        Number(rowData.Female || 0) +
        Number(rowData.Others || 0) +
        Number(rowData.TotalParticipants || 0);

      // Add to column totals
      columnTotals["T1: FP/MCH"] += Number(rowData["T1: FP/MCH"] || 0);
      columnTotals["T2: Abortion"] += Number(rowData["T2: Abortion"] || 0);
      columnTotals["T3: STI & HIV"] += Number(rowData["T3: STI & HIV"] || 0);
      columnTotals["T4: Adolescent Health"] += Number(
        rowData["T4: Adolescent Health"] || 0
      );
      columnTotals["T5: Infertility"] += Number(
        rowData["T5: Infertility"] || 0
      );
      columnTotals["T6: SRHR"] += Number(rowData["T6: SRHR"] || 0);
      columnTotals["T7: GBV/RC"] += Number(rowData["T7: GBV/RC"] || 0);
      columnTotals["T8: Oral Cancer"] += Number(
        rowData["T8: Oral Cancer"] || 0
      );
      columnTotals["T9: Breast Cancer"] += Number(
        rowData["T9: Breast Cancer"] || 0
      );
      columnTotals["T10: Cervical cancer"] += Number(
        rowData["T10: Cervical cancer"] || 0
      );
      columnTotals["T11: HPV vaccination"] += Number(
        rowData["T11: HPV vaccination"] || 0
      );
      columnTotals["T12: MISP"] += Number(rowData["T12: MISP"] || 0);
      columnTotals["T13: Non-Communicable Disease (NCD)"] += Number(
        rowData["T13: Non-Communicable Disease (NCD)"] || 0
      );
      columnTotals["T14: Gender Identity & Sexual Orientation"] += Number(
        rowData["T14: Gender Identity & Sexual Orientation"] || 0
      );
      columnTotals["T15: Climate change & environment"] += Number(
        rowData["T15: Climate change & environment"] || 0
      );
      columnTotals["T16: Other"] += Number(rowData["T16: Other"] || 0);
      columnTotals["10-14yrs"] += Number(rowData["10-14yrs"] || 0);
      columnTotals["15-24yrs"] += Number(rowData["15-24yrs"] || 0);
      columnTotals["25+yrs"] += Number(rowData["25+yrs"] || 0);
      columnTotals.male += Number(rowData.male || 0);
      columnTotals.Female += Number(rowData.Female || 0);
      columnTotals.Others += Number(rowData.Others || 0);
      columnTotals.Others += Number(rowData.TotalParticipants || 0);

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.OpeningDate,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.SDP_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.AreaName_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.GMMno,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.District_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Block_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.City_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Venue_en,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Channel,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.staffname,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData["T1: FP/MCH"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T2: Abortion"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T3: STI & HIV"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T4: Adolescent Health"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T5: Infertility"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T6: SRHR"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T7: GBV/RC"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T8: Oral Cancer"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T9: Breast Cancer"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T10: Cervical cancer"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T11: HPV vaccination"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T12: MISP"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T13: Non-Communicable Disease (NCD)"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T14: Gender Identity & Sexual Orientation"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T15: Climate change & environment"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["T16: Other"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["10-14yrs"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["15-24yrs"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData["25+yrs"] || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.male || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Female || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Others || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.TotalParticipants || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },
      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },

      { v: columnTotals["T1: FP/MCH"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T2: Abortion"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T3: STI & HIV"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T4: Adolescent Health"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T5: Infertility"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T6: SRHR"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T7: GBV/RC"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T8: Oral Cancer"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T9: Breast Cancer"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T10: Cervical cancer"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T11: HPV vaccination"], t: "n", s: totalsRowStyle },
      { v: columnTotals["T12: MISP"], t: "n", s: totalsRowStyle },
      {
        v: columnTotals["T13: Non-Communicable Disease (NCD)"],
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals["T14: Gender Identity & Sexual Orientation"],
        t: "n",
        s: totalsRowStyle,
      },
      {
        v: columnTotals["T15: Climate change & environment"],
        t: "n",
        s: totalsRowStyle,
      },
      { v: columnTotals["T16: Other"], t: "n", s: totalsRowStyle },
      { v: columnTotals["10-14yrs"], t: "n", s: totalsRowStyle },
      { v: columnTotals["15-24yrs"], t: "n", s: totalsRowStyle },
      { v: columnTotals["25+yrs"], t: "n", s: totalsRowStyle },
      { v: columnTotals.male, t: "n", s: totalsRowStyle },
      { v: columnTotals.Female, t: "n", s: totalsRowStyle },
      { v: columnTotals.Others, t: "n", s: totalsRowStyle },
      { v: columnTotals.TotalParticipants, t: "n", s: totalsRowStyle },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows
    // const wsData = [titleRow, headerRow1, headerRow2, ...dataRows, totalsRow];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow1,
      headerRow2,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Add merge cells configuration
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
      { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
      { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 5, c: 3 } },
      { s: { r: 4, c: 4 }, e: { r: 5, c: 4 } },
      { s: { r: 4, c: 5 }, e: { r: 5, c: 5 } },
      { s: { r: 4, c: 6 }, e: { r: 5, c: 6 } },
      { s: { r: 4, c: 7 }, e: { r: 5, c: 7 } },
      { s: { r: 4, c: 8 }, e: { r: 5, c: 8 } },
      { s: { r: 4, c: 9 }, e: { r: 5, c: 9 } },
      { s: { r: 4, c: 10 }, e: { r: 5, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 5, c: 11 } },
      { s: { r: 4, c: 12 }, e: { r: 5, c: 12 } },
      { s: { r: 4, c: 13 }, e: { r: 5, c: 13 } },
      { s: { r: 4, c: 14 }, e: { r: 5, c: 14 } },
      { s: { r: 4, c: 15 }, e: { r: 5, c: 15 } },
      { s: { r: 4, c: 16 }, e: { r: 5, c: 16 } },
      { s: { r: 4, c: 17 }, e: { r: 5, c: 17 } },
      { s: { r: 4, c: 18 }, e: { r: 5, c: 18 } },
      { s: { r: 4, c: 19 }, e: { r: 5, c: 19 } },
      { s: { r: 4, c: 20 }, e: { r: 5, c: 20 } },
      { s: { r: 4, c: 21 }, e: { r: 5, c: 21 } },
      { s: { r: 4, c: 22 }, e: { r: 5, c: 22 } },
      { s: { r: 4, c: 23 }, e: { r: 5, c: 23 } },
      { s: { r: 4, c: 24 }, e: { r: 5, c: 24 } },
      { s: { r: 4, c: 25 }, e: { r: 5, c: 25 } },
      { s: { r: 4, c: 26 }, e: { r: 5, c: 26 } },
      { s: { r: 4, c: 27 }, e: { r: 5, c: 27 } },
      { s: { r: 4, c: 28 }, e: { r: 4, c: 30 } },
      { s: { r: 4, c: 31 }, e: { r: 4, c: 33 } },
      { s: { r: 4, c: 34 }, e: { r: 5, c: 34 } },
      { s: { r: 4, c: 35 }, e: { r: 5, c: 35 } },
    ];
    ws["!cols"] = [
      { width: 8 }, // Index
      { width: 15 }, // Branch
      { width: 15 }, // IPC  No
      { width: 25 }, // Date
      { width: 30 }, // SDP
      { width: 20 }, // Area
      { width: 20 }, // District
      { width: 15 }, // Block
      { width: 12 }, // City/Village
      { width: 12 }, // Channel
      { width: 12 }, // Staff Name
      { width: 15 }, // ["T1: FP/MCH"]
      { width: 15 }, // ["T2: Abortion"]
      { width: 15 }, // ["T3: STI & HIV"]
      { width: 20 }, // ["T4: Adolescent Health"]
      { width: 15 }, // ["T5: Infertility"]
      { width: 10 }, // ["T6: SRHR"]
      { width: 15 }, // ["T7: GBV/RC"]
      { width: 15 }, // ["T8: Oral Cancer"]
      { width: 20 }, // ["T9: Breast Cancer"]
      { width: 20 }, // ["T10: Cervical cancer"]
      { width: 20 }, // ["T11: HPV vaccination"]
      { width: 10 }, // ["T12: MISP"]
      { width: 35 }, // ["T13: Non-Communicable Disease (NCD)"]
      { width: 37 }, // ["T14: Gender Identity & Sexual Orientation"]
      { width: 34 }, // ["T15: Climate change & environment"]
      { width: 15 }, // ["T16: Other"]
      { width: 15 }, // Age columns
      { width: 15 },
      { width: 15 },
      { width: 15 }, // Gender columns
      { width: 15 },
      { width: 15 },
      { width: 20 }, // Total Participants
      { width: 15 }, // Row Total
    ];

    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // // Header rows
    // [headerRow1, headerRow2].forEach((headerRow, headerIndex) => {
    //   headerRow.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: headerIndex + 1,
    //       c: colIndex,
    //     });
    //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
    //   });
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 3,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 3,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];

    const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "GMM Data");

    XLSX.writeFile(wb, "IEC GMM.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a2",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure iecGDReportHeader is an array of objects with a title property
    // const headers = iecGDReportHeader.map((column) => column.title);
    const headers = [
      { content: "S.No.", rowSpan: 2 },
      { content: "Branch", rowSpan: 2 },
      { content: "GMM no.", rowSpan: 2 },
      { content: "Date", rowSpan: 2 },
      { content: "SDP", rowSpan: 2 },
      { content: "Area", rowSpan: 2 },
      { content: "District", rowSpan: 2 },
      { content: "Block", rowSpan: 2 },
      { content: "City/Village", rowSpan: 2 },
      { content: "Venue", rowSpan: 2 },
      { content: "Channel", rowSpan: 2 },
      { content: "Staff Name", rowSpan: 2 },
      { content: "T1: FP/MCH", rowSpan: 2 },
      { content: "T2: Abortion", rowSpan: 2 },
      { content: "T3: STI & HIV", rowSpan: 2 },
      { content: "T4: Adolescent Health", rowSpan: 2 },
      { content: "T5: Infertility", rowSpan: 2 },
      { content: "T6: SRHR", rowSpan: 2 },
      { content: "T7: GBV/RC", rowSpan: 2 },
      { content: "T8: Oral Cancer", rowSpan: 2 },
      { content: "T9: Breast Cancer", rowSpan: 2 },
      { content: "T10: Cervical cancer", rowSpan: 2 },
      { content: "T11: HPV vaccination", rowSpan: 2 },
      { content: "T12: MISP", rowSpan: 2 },
      { content: "T13: Non-Communicable Disease (NCD)", rowSpan: 2 },
      { content: "T14: Gender Identity & Sexual Orientation", rowSpan: 2 },
      { content: "T15: Climate change & environment", rowSpan: 2 },
      { content: "T16: Other", rowSpan: 2 },
      { content: "Age", colSpan: 3 },
      { content: "Gender", colSpan: 3 },
      { content: "Total no. of Participants", rowSpan: 2 },
    ];
    const subHeaders = [
      "10-14yrs",
      "15-24yrs",
      "25+yrs",
      "Male",
      "Female",
      "Other",
      "Total no. of Participants",
    ];
    // Map bindIECGDData to the appropriate format for autoTable
    const data = bindIECGMMData.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.GMMno,
        rowData.OpeningDate,
        rowData.SDP_en,
        rowData.AreaName_en,
        rowData.District_en,
        rowData.Block_en,
        rowData.City_en,
        rowData.Venue_en,
        rowData.Channel,
        rowData.staffname,
        rowData["T1: FP/MCH"],
        rowData["T2: Abortion"],
        rowData["T3: STI & HIV"],
        rowData["T4: Adolescent Health"],
        rowData["T5: Infertility  "],
        rowData["T6: SRHR"],
        rowData["T7: GBV/RC"],
        rowData["T8: Oral Cancer"],
        rowData["T9: Breast Cancer"],
        rowData["T10: Cervical cancer"],
        rowData["T11: HPV vaccination"],
        rowData["T12: MISP"],
        rowData["T13: Non-Communicable Disease (NCD)"],
        rowData["T14: Gender Identity & Sexual Orientation"],
        rowData["T15: Climate change & environment"],
        rowData["T16: Other"],
        rowData["10-14yrs"],
        rowData["15-24yrs"],
        rowData["25+yrs"],
        rowData.male,
        rowData.Female,
        rowData.Others,
        rowData.NoOfPaticipantsTotal,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers, subHeaders],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: 15 }, // Age
        7: { cellWidth: 15 }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Auto adjust width
        17: { cellWidth: "auto" }, // Auto adjust width
        18: { cellWidth: "auto" }, // Auto adjust width
        19: { cellWidth: "auto" }, // Auto adjust width
        20: { cellWidth: "auto" }, // Auto adjust width
        21: { cellWidth: "auto" }, // Auto adjust width
        22: { cellWidth: "auto" }, // Auto adjust width
        23: { cellWidth: "auto" }, // Auto adjust width
        24: { cellWidth: "auto" }, // Auto adjust width
        25: { cellWidth: "auto" }, // Auto adjust width
        26: { cellWidth: "auto" }, // Auto adjust width
        27: { cellWidth: "auto" }, // Auto adjust width
        28: { cellWidth: "auto" }, // Auto adjust width
        29: { cellWidth: "auto" }, // Auto adjust width
        30: { cellWidth: "auto" }, // Auto adjust width
        31: { cellWidth: "auto" }, // Auto adjust width
        32: { cellWidth: "auto" }, // Auto adjust width
        33: { cellWidth: "auto" }, // Auto adjust width
        34: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("IEC GMM.pdf");
  };

  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };

  const sdpChange = (updatedOptions) => {
    setArea("");

    setSDP(updatedOptions);
    setBindAreaDD([]);
    const SDPlabels = getSDPNames(updatedOptions);
    console.log("SDPlabels", SDPlabels);
    setSDPNamesList(SDPlabels);
  };

  const getSDPNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindSDP?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const brangeChange = (updatedOptions) => {
    setSDP("");
    setArea("");
    setBindSDP([]);
    setBindAreaDD([]);
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  const areaChange = (updatedOptions) => {
    setArea("");
    setArea(updatedOptions);
    const arealabels = getAreaNames(updatedOptions);
    console.log("arealabels", arealabels);
    setAreaNamesList(arealabels);
  };

  const getAreaNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map((option) => bindArea?.find((item) => item.value === option)?.label)
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`IEC - GMM`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="branchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="SDP"
                  filteredOptions={bindSDP}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={sdp?.length > 0 ? "More Selected" : "Select..."}
                  name="sdp"
                  selectedOptions={sdp}
                  classDiv="relative w-full inline-block"
                  onChange={sdpChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {sdpError && <span className="text-red-500">{sdpError}</span>}
              </div>
              <div>
                <MultiSelect
                  classStar="text-red-500"
                  label="Area"
                  filteredOptions={bindArea}
                  classFilterLabel="p-1 flex gap-1"
                  class="custom-class"
                  placeholder={area?.length > 0 ? "More Selected" : "Select..."}
                  name="area"
                  selectedOptions={area}
                  classDiv="relative w-full inline-block"
                  onChange={areaChange}
                  classLabel={mainTable.labelNoWrap}
                />
                {areaError && <span className="text-red-500">{areaError}</span>}
              </div>
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out  hover:bg-[#24292F]/90 focus:ring-4 cursor-pointer focus:outline-none focus:ring-blue-300   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindIECGMMData.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={iecGMMReportHeader}
                records={bindIECGMMData}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default IECGMMReport;
