import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import { mainTable } from "../css/Common";
import InputText from "../ReusableComponents/InputText";
import AutoDropDown from "../ReusableComponents/AutoCompleteDropDown";
import { BindAPI, BindPostAPI } from "../services/APIConnection";
import ReportGridPage from "../components/ReportGridPage";
import { MonthlyEventsReportHeader } from "../components/HeaderAttribute";
import * as XLSX from "sheetjs-style";
import jsPDF from "jspdf";
import "jspdf-autotable";
import fpaiLogo from "../image/FPAILogo.jpg";
import { useUserCapability } from "../components/ConstantReducerProvider";
import MultiSelect from "../ReusableComponents/MultiSelect";

const MonthlyEventsReport = () => {
  const { userProfileState } = useUserCapability();
  const userProfileDatas = userProfileState.userProfile;
  const [branchProject, setBranchProject] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchProjectError, setBranchProjectError] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [bindBranchProjectDD, setBindBranchProjectDD] = useState([]);
  const [bindReportMonthlyEvents, setBindReportMonthlyEvents] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [branchProjectNamesList, setBranchProjectNamesList] = useState([]);

  useEffect(() => {
    if (
      userProfileDatas[0]?.MstOrganizationId == 2 ||
      userProfileDatas[0]?.MstOrganizationId == 3
    ) {
      setBranchName(userProfileDatas[0]?.LocationName_en);
      setBranchProject([userProfileDatas[0]?.MstLocationId]);
    }
    BranchProjectFetchData();
  }, [userProfileDatas ? userProfileDatas : undefined]);
  const GoButton = async () => {
    const branchProjectValidate = branchProject ? "" : "Select Branch/Project";
    setBranchProjectError(branchProjectValidate);
    let branchArray = Array.from(
      { length: branchProject.length },
      (v, i) => branchProject[i]
    );
    let branchResult = branchArray.join(",");
    if (!branchProjectValidate) {
      const ReportData = [
        {
          mstBranchId: branchResult,
          fromDate: fromDate,
          toDate: toDate,
        },
      ];

      const response = await BindPostAPI(
        ReportData,
        "CSEInSchoolAPI/ReportMonthlyEvents"
      );

      console.log("response", response.data.Table);
      setBindReportMonthlyEvents(response.data.Table);
      setShowTable(true);
    } else {
      setBranchProjectError("Select Branch/Project");
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  };

  const handleExport = () => {
    const dateRange = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const selectedOptionsData = {
      branchProjectData:
        branchProjectNamesList.length > 0
          ? branchProjectNamesList.toString()
          : branchName,
    };
    exportData(
      MonthlyEventsReportHeader.map((column) => column.title),
      bindReportMonthlyEvents,
      dateRange,
      selectedOptionsData
    );
  };

  const exportData = (columns, rows, dateRange, selectedDetails) => {
    const titleRow = [
      {
        v: "Monthly Events Report",
        s: {
          font: { bold: true, sz: 16 },
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Date range row with merged cells
    const dateRangeRow = [
      { v: "" },
      {
        v: "From Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: formatDate(dateRange.fromDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
      {
        v: "To Date:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "left" },
        },
      },
      {
        v: formatDate(dateRange.toDate),
        s: {
          alignment: { horizontal: "left" },
        },
      },
    ];

    // Branch information row with merged cells
    const branchRow = [
      { v: "" },
      {
        v: "Branch/Project:",
        s: {
          font: { bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        v: selectedDetails.branchProjectData,
        s: {
          alignment: { horizontal: "left" },
          alignment: { wrapText: true }, // Enable text wrapping
        },
      },
    ];

    // Add empty row for spacing
    const spacingRow = [{ v: "" }];

    const headerRow1 = [
      {
        v: "S.No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Branch",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Month",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Training by FPAI/Volunteers(No. of participants)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Sensitization meeting (No. of participants)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      {
        v: "NGO/CBOs partner",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      {
        v: "No. of schools enrolled (year)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "No. of schools completed",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Type of youth groups",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },

      {
        v: "No. of schools enrolled (year)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "No. of schools completed",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "No. Of Youth Groups Formed",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Remarks",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Total",
        s: {
          fill: { fgColor: { rgb: "3A3A3A" } },
          font: { bold: true, color: { rgb: "FFFFFF" } },
          alignment: { horizontal: "right" },
        },
      },
    ];

    const headerRow2 = [
      { v: "", s: {} },
      { v: "", s: {} },
      { v: "", s: {} },
      {
        v: "Teacher",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "NGO/CBO staff",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Peer Educator (direct/ToT)",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Peer-to-Peer ",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Parents",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Teachers",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Name",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "No.",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },

      {
        v: "Male",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Female",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Other",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      {
        v: "Mixed",
        s: {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: { fgColor: { rgb: "2D2D6E" } },
        },
      },
      { v: "", s: {} },
      { v: "", s: {} },
    ];

    const columnTotals = {
      TeacherTraining: 0,
      NGOCBOStaffTraining: 0,
      PeerEducatorTrained: 0,
      PeerToPeerTraining: 0,
      Parents: 0,
      Teachers: 0,
      NoOfNGOCBOsPartnered: 0,
      SchoolsEnrolled: 0,
      SchoolsCompleted: 0,
      YouthGroupsFormedBoys: 0,
      YouthGroupsFormedGirls: 0,
      YouthGroupsFormedOthers: 0,
      YouthGroupsFormedMixed: 0,
      YouthGroupsFormedTotal: 0,
    };

    // Data rows with row totals
    const dataRows = rows.map((rowData, index) => {
      const isAlternateRow = index % 2 === 1;
      const baseStyle = isAlternateRow
        ? { fill: { fgColor: { rgb: "F0F0F0" } } }
        : {};

      // Calculate row total for numeric fields
      const rowTotal =
        Number(rowData.TeacherTraining || 0) +
        Number(rowData.NGOCBOStaffTraining || 0) +
        Number(rowData.PeerEducatorTrained || 0) +
        Number(rowData.PeerToPeerTraining || 0) +
        Number(rowData.Parents || 0) +
        Number(rowData.Teachers || 0) +
        Number(rowData.NoOfNGOCBOsPartnered || 0) +
        Number(rowData.SchoolsEnrolled || 0) +
        Number(rowData.SchoolsCompleted || 0) +
        Number(rowData.YouthGroupsFormedBoys || 0) +
        Number(rowData.YouthGroupsFormedGirls || 0) +
        Number(rowData.YouthGroupsFormedOthers || 0) +
        Number(rowData.YouthGroupsFormedMixed || 0) +
        Number(rowData.YouthGroupsFormedTotal || 0);

      // Add to column totals
      columnTotals.TeacherTraining += Number(rowData.TeacherTraining || 0);
      columnTotals.NGOCBOStaffTraining += Number(
        rowData.NGOCBOStaffTraining || 0
      );
      columnTotals.PeerEducatorTrained += Number(
        rowData.PeerEducatorTrained || 0
      );
      columnTotals.PeerToPeerTraining += Number(
        rowData.PeerToPeerTraining || 0
      );
      columnTotals.Parents += Number(rowData.Parents || 0);
      columnTotals.Teachers += Number(rowData.Teachers || 0);
      columnTotals.NoOfNGOCBOsPartnered += Number(
        rowData.NoOfNGOCBOsPartnered || 0
      );
      columnTotals.SchoolsEnrolled += Number(rowData.SchoolsEnrolled || 0);
      columnTotals.SchoolsCompleted += Number(rowData.SchoolsCompleted || 0);
      columnTotals.YouthGroupsFormedBoys += Number(
        rowData.YouthGroupsFormedBoys || 0
      );
      columnTotals.YouthGroupsFormedGirls += Number(
        rowData.YouthGroupsFormedGirls || 0
      );
      columnTotals.YouthGroupsFormedOthers += Number(
        rowData.YouthGroupsFormedOthers || 0
      );
      columnTotals.YouthGroupsFormedMixed += Number(
        rowData.YouthGroupsFormedMixed || 0
      );
      columnTotals.YouthGroupsFormedTotal += Number(
        rowData.YouthGroupsFormedTotal || 0
      );

      return [
        {
          v: index + 1,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.Branch,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: rowData.MonthName,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },

        {
          v: Number(rowData.TeacherTraining || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.NGOCBOStaffTraining || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.PeerEducatorTrained || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.PeerToPeerTraining || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Parents || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.Teachers || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: rowData.NameOfTheNGOCBO,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        {
          v: Number(rowData.NoOfNGOCBOsPartnered || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },

        {
          v: Number(rowData.SchoolsEnrolled || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.SchoolsCompleted || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.YouthGroupsFormedBoys || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.YouthGroupsFormedGirls || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.YouthGroupsFormedOthers || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.YouthGroupsFormedMixed || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: Number(rowData.YouthGroupsFormedTotal || 0),
          t: "n",
          s: { ...baseStyle, alignment: { horizontal: "right" }, numFmt: "0" },
        },
        {
          v: rowData.Remarks,
          s: { ...baseStyle, alignment: { horizontal: "left" } },
        },
        // Row total
        {
          v: rowTotal,
          t: "n",
          s: {
            ...baseStyle,
            font: { bold: true },
            alignment: { horizontal: "right" },
            numFmt: "0",
          },
        },
      ];
    });

    // Create totals row
    const totalsRowStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: "E0E0E0" } },
      alignment: { horizontal: "right" },
      numFmt: "0",
    };

    const grandTotal = Object.values(columnTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    const totalsRow = [
      { v: "", s: totalsRowStyle },

      {
        v: "Total",
        s: { ...totalsRowStyle, alignment: { horizontal: "left" } },
      },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.TeacherTraining, t: "n", s: totalsRowStyle },
      { v: columnTotals.NGOCBOStaffTraining, t: "n", s: totalsRowStyle },
      { v: columnTotals.PeerEducatorTrained, t: "n", s: totalsRowStyle },
      { v: columnTotals.PeerToPeerTraining, t: "n", s: totalsRowStyle },
      { v: columnTotals.Parents, t: "n", s: totalsRowStyle },
      { v: columnTotals.Teachers, t: "n", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      { v: columnTotals.NoOfNGOCBOsPartnered, t: "n", s: totalsRowStyle },
      { v: columnTotals.SchoolsEnrolled, t: "n", s: totalsRowStyle },
      { v: columnTotals.SchoolsCompleted, t: "n", s: totalsRowStyle },
      { v: columnTotals.YouthGroupsFormedBoys, t: "n", s: totalsRowStyle },
      { v: columnTotals.YouthGroupsFormedGirls, t: "n", s: totalsRowStyle },
      { v: columnTotals.YouthGroupsFormedOthers, t: "n", s: totalsRowStyle },
      { v: columnTotals.YouthGroupsFormedMixed, t: "n", s: totalsRowStyle },

      { v: columnTotals.YouthGroupsFormedTotal, t: "n", s: totalsRowStyle },
      { v: "", s: totalsRowStyle },
      // Grand total
      {
        v: grandTotal,
        t: "n",
        s: {
          ...totalsRowStyle,
          font: { bold: true, color: { rgb: "2D2D6E" } },
        },
      },
    ];

    // Combine all rows
    // const wsData = [titleRow, headerRow1, headerRow2, ...dataRows, totalsRow];
    const wsData = [
      titleRow,
      dateRangeRow,
      branchRow,
      spacingRow,
      headerRow1,
      headerRow2,
      ...dataRows,
      totalsRow,
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Add merge cells configuration
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
      { s: { r: 4, c: 0 }, e: { r: 5, c: 0 } },
      { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } },
      { s: { r: 4, c: 2 }, e: { r: 5, c: 2 } },
      { s: { r: 4, c: 3 }, e: { r: 4, c: 6 } },
      { s: { r: 4, c: 7 }, e: { r: 4, c: 8 } },
      { s: { r: 4, c: 9 }, e: { r: 4, c: 10 } },
      { s: { r: 4, c: 11 }, e: { r: 4, c: 14 } },
      { s: { r: 4, c: 15 }, e: { r: 5, c: 15 } },
      { s: { r: 4, c: 16 }, e: { r: 5, c: 16 } },
      { s: { r: 4, c: 17 }, e: { r: 5, c: 17 } },
      { s: { r: 4, c: 18 }, e: { r: 5, c: 18 } },
      { s: { r: 4, c: 19 }, e: { r: 5, c: 19 } },
    ];
    // ws["!merges"] = [
    //   // Merge Age columns
    //   { s: { r: 1, c: 3 }, e: { r: 1, c: 6 } },
    //   // Merge Gender columns
    //   { s: { r: 1, c: 7 }, e: { r: 1, c: 8 } },
    //   { s: { r: 1, c: 9 }, e: { r: 1, c: 10 } },
    //   { s: { r: 1, c: 11 }, e: { r: 1, c: 14 } },

    //   // Merge title cell if needed
    //   { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
    //   // Merge all single-row headers
    //   ...Array.from({ length: 27 }, (_, i) => ({
    //     s: { r: 1, c: i },
    //     e: { r: 2, c: i },
    //   })),

    //   // Merge Row Total
    //   { s: { r: 1, c: 16 }, e: { r: 2, c: 16 } },
    // ];
    // Define consistent column widths for all columns (adjust as needed)
    ws["!cols"] = [
      { width: 8 },
      { width: 30 },
      { width: 10 },
      { width: 16 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
      { width: 30 },
    ];

    // const borderStyle = {
    //   border: {
    //     top: { style: "thin", color: { rgb: "00000000" } },
    //     bottom: { style: "thin", color: { rgb: "00000000" } },
    //     left: { style: "thin", color: { rgb: "00000000" } },
    //     right: { style: "thin", color: { rgb: "00000000" } },
    //   },
    // };

    // const titleCell = XLSX.utils.encode_cell({ r: 0, c: 0 });
    // ws[titleCell].s = titleRow[0].s;

    // Header rows
    // [headerRow1, headerRow2].forEach((headerRow, headerIndex) => {
    //   headerRow.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: headerIndex + 1,
    //       c: colIndex,
    //     });
    //     ws[cellAddress].s = { ...cell.s, ...borderStyle };
    //   });
    // });

    // // Data rows
    // dataRows.forEach((row, rowIndex) => {
    //   row.forEach((cell, colIndex) => {
    //     const cellAddress = XLSX.utils.encode_cell({
    //       r: rowIndex + 3,
    //       c: colIndex,
    //     });
    //     ws[cellAddress] = {
    //       v: cell.v,
    //       t: cell.t,
    //       s: { ...cell.s, ...borderStyle },
    //     };
    //   });
    // });

    // // Totals row
    // totalsRow.forEach((cell, colIndex) => {
    //   const cellAddress = XLSX.utils.encode_cell({
    //     r: dataRows.length + 3,
    //     c: colIndex,
    //   });
    //   ws[cellAddress] = {
    //     v: cell.v,
    //     t: cell.t,
    //     s: { ...cell.s, ...borderStyle },
    //   };
    // });

    // Set row heights for wrapped content
    ws["!rows"] = [
      { hpt: 30 }, // Title row height
      { hpt: 15 }, // Date range row height
      { hpt: 15 }, // Branch row height (taller for wrapped content)
      { hpt: 15 }, // Spacing row height
      { hpt: 15 }, // Header row height (taller for wrapped content)
      ...Array(rows.length).fill({ hpt: 15 }), // Data rows height
      { hpt: 15 }, // Totals row height
    ];


     // Apply borders and styles
     const applyStyles = (ws) => {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
          if (!ws[cellRef]) continue;

          // Add borders to all cells
          ws[cellRef].s = {
            ...ws[cellRef].s,
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          };
        }
      }
    };

    applyStyles(ws);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export the Excel file
    XLSX.writeFile(wb, "Monthly_Event_Report.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });
    const logoWidth = 30;
    const logoHeight = 14;
    doc.addImage(fpaiLogo, "JPEG", 10, 10, logoWidth, logoHeight);

    // Ensure iecGDReportHeader is an array of objects with a title property
    // const headers = iecGDReportHeader.map((column) => column.title);
    const headers = [
      { content: "S.No.", rowSpan: 2 },
      { content: "Branch", rowSpan: 2 },
      { content: "Month", rowSpan: 2 },
      {
        content: "Training by FPAI/Volunteers(No. of participants)",
        colSpan: 4,
      },
      { content: "Sensitization meeting (No. of participants)", colSpan: 2 },
      { content: "NGO/CBOs partner", colSpan: 2 },
      { content: "No. of schools enrolled (year)", rowSpan: 2 },
      { content: "BloNo. of schools completedck", rowSpan: 2 },
      { content: "Type of youth groups", colSpan: 4 },
      { content: "No. Of Youth Groups Formed", rowSpan: 2 },
      { content: "Remarks", rowSpan: 2 },
    ];
    const subHeaders = [
      "Teacher",
      "NGO/CBO staff",
      "Peer Educator (direct/ToT)",
      "Peer-to-Peer",
      "Parents",
      "Teachers",
      "Name",
      "No.",
      "Male",
      "Female",
      "Other",
      "Mixed",
    ];
    // Map bindReportMonthlyEvents to the appropriate format for autoTable
    const data = bindReportMonthlyEvents.map((rowData, index) => {
      return [
        index + 1,
        rowData.Branch,
        rowData.MonthName,
        rowData.TeacherTraining,
        rowData.NGOCBOStaffTraining,
        rowData.PeerEducatorTrained,
        rowData.PeerToPeerTraining,
        rowData.Parents,
        rowData.Teachers,
        rowData.NameOfTheNGOCBO,
        rowData.NoOfNGOCBOsPartnered,
        rowData.SchoolsEnrolled,
        rowData.SchoolsCompleted,
        rowData.YouthGroupsFormedBoys,
        rowData.YouthGroupsFormedGirls,
        rowData.YouthGroupsFormedOthers,
        rowData.YouthGroupsFormedMixed,
        rowData.YouthGroupsFormedTotal,
        rowData.Remarks,
      ];
    });

    // Create the table with headers and data
    doc.autoTable({
      head: [headers, subHeaders],
      body: data,
      startY: 30,
      styles: {
        fontSize: 7, // Reduced font size
        lineColor: "black",
        lineWidth: 0.2,
        halign: "center", // Align text to center
        valign: "middle", // Align text vertically in the middle
        cellPadding: 2, // Add padding to cells
        overflow: "linebreak", // Allow text to wrap within cells
      },
      headStyles: {
        fillColor: "#2d2d6e",
        textColor: [255, 255, 255],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray color for alternate rows
      },
      columnStyles: {
        0: { cellWidth: 10 }, // Index column
        1: { cellWidth: "auto" }, // Auto adjust width
        2: { cellWidth: "auto" }, // Auto adjust width
        3: { cellWidth: "auto" }, // Auto adjust width
        4: { cellWidth: "auto" }, // Auto adjust width
        5: { cellWidth: "auto" }, // Auto adjust width
        6: { cellWidth: "auto" }, // Age
        7: { cellWidth: "auto" }, // Gender
        8: { cellWidth: "auto" }, // Auto adjust width
        9: { cellWidth: "auto" }, // Auto adjust width
        10: { cellWidth: "auto" }, // Auto adjust width
        11: { cellWidth: "auto" }, // Auto adjust width
        12: { cellWidth: "auto" }, // Auto adjust width
        13: { cellWidth: "auto" }, // Auto adjust width
        14: { cellWidth: "auto" }, // Auto adjust width
        15: { cellWidth: "auto" }, // Auto adjust width
        16: { cellWidth: "auto" }, // Auto adjust width
        17: { cellWidth: "auto" }, // Auto adjust width
        18: { cellWidth: "auto" }, // Auto adjust width
        19: { cellWidth: "auto" }, // Auto adjust width
        20: { cellWidth: "auto" }, // Auto adjust width
        21: { cellWidth: "auto" }, // Auto adjust width
        22: { cellWidth: "auto" }, // Auto adjust width
        23: { cellWidth: "auto" }, // Auto adjust width
        24: { cellWidth: "auto" }, // Auto adjust width
        25: { cellWidth: "auto" }, // Auto adjust width
        26: { cellWidth: "auto" }, // Auto adjust width
        27: { cellWidth: "auto" }, // Auto adjust width
        28: { cellWidth: "auto" }, // Auto adjust width
        29: { cellWidth: "auto" }, // Auto adjust width
        30: { cellWidth: "auto" }, // Auto adjust width
        31: { cellWidth: "auto" }, // Auto adjust width
      },
    });

    doc.save("IEC GMM.pdf");
  };
  const BranchProjectFetchData = async () => {
    try {
      const locationResponse = await BindAPI("LocationAPI/GetLocation");

      const locationData = locationResponse.data.Table.map((item) => ({
        value: item.MstLocationId,
        label: item.LocationName_en,
      }));

      setBindBranchProjectDD(locationData);
    } catch (error) {}
  };
  useEffect(() => {
    BranchProjectFetchData();
  }, []);
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns 0-indexed month and padStart to ensure two digits
    const automaticFromDate = `${currentYear}-01-01`;
    setFromDate(automaticFromDate);

    const formattedToDate = today.toISOString().split("T")[0];
    setToDate(formattedToDate);
  }, []);
  const brangeChange = (updatedOptions) => {
    setBranchProject(updatedOptions);
    const branchProjectlabels = getBranchProjectsNames(updatedOptions);
    console.log("branchProjectlabels", branchProjectlabels);
    setBranchProjectNamesList(branchProjectlabels);
  };

  const getBranchProjectsNames = (updatedOptions) => {
    const NameList = updatedOptions
      .map(
        (option) =>
          bindBranchProjectDD?.find((item) => item.value === option)?.label
      )
      .filter(Boolean); // Remove undefined values if any

    return NameList;
  };

  return (
    <MainLayout pageName="4">
      <div>
        <div class="relative m-3 bg-white rounded-lg ">
          <div class="mt-3 py-4">
            <div className={mainTable.headerTitle}>
              <div className="my-2 mx-12 lg:mx-0">{`Monthly Events Report`}</div>
            </div>
            <div className={mainTable.grid3}>
              {userProfileDatas[0]?.MstOrganizationId == 1 && (
                <div>
                  <MultiSelect
                    classStar="text-red-500"
                    label="Branch/Project"
                    filteredOptions={bindBranchProjectDD}
                    classFilterLabel="p-1 flex gap-1"
                    class="custom-class"
                    placeholder={
                      branchProject?.length > 0 ? "More Selected" : "Select..."
                    }
                    name="branchProject"
                    selectedOptions={branchProject}
                    classDiv="relative w-full inline-block"
                    onChange={brangeChange}
                    classLabel={mainTable.labelNoWrap}
                  />
                  {branchProjectError && (
                    <span className="text-red-500">{branchProjectError}</span>
                  )}
                </div>
              )}
              {(userProfileDatas[0]?.MstOrganizationId == 2 ||
                userProfileDatas[0]?.MstOrganizationId == 3) && (
                <InputText
                  inputType="text"
                  title="Branch/Project"
                  classStar="text-red-500"
                  name="BranchName"
                  disabled="true"
                  content={branchName}
                  controlFunc={(e) => setBranchName(e.target.value)}
                />
              )}
            </div>
            <div class={mainTable.grid3}>
              <InputText
                inputType="date"
                title="From"
                name="fromDate"
                content={fromDate}
                controlFunc={(e) => setFromDate(e.target.value)}
              />
              <InputText
                inputType="date"
                title="To"
                name="toDate"
                content={toDate}
                controlFunc={(e) => setToDate(e.target.value)}
              />
              <div class="flex justify-start items-center mt-6">
                <button
                  type="button"
                  onClick={() => GoButton()}
                  className={mainTable.addButton}
                  // class="inline-block rounded bg-[#2d2d6e] px-6 pb-2 pt-2.5  text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out  hover:bg-[#24292F]/90 focus:ring-4 cursor-pointer focus:outline-none focus:ring-blue-300   "
                >
                  GO
                </button>
              </div>
            </div>
          </div>
        </div>
        {showTable &&
          (bindReportMonthlyEvents.length > 0 ? (
            <div className=" ">
              <ReportGridPage
                title=""
                header={MonthlyEventsReportHeader}
                records={bindReportMonthlyEvents}
                handleExport={handleExport}
                handlePDF={downloadPDF}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No record found
            </div>
          ))}
      </div>
    </MainLayout>
  );
};

export default MonthlyEventsReport;
